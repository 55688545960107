import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'

import { ContextMenu } from '../../components/commom';
import { CallAction } from '../../models/callAction';
import {
    sendGroupCallAction, sendIndividualCallAction,
    masterPTT, addMastPTT, remMastPTT,
    setCallasCurrent,
    initiatePatchCall, addCallToPatch, addAckEmergencyCallStatus, initiateMergeCall, addCallToMerge,
} from '../../modules/communication';
import { getCallAction, getCallActionState, getCallName, getPriority, getCallieIdToShow } from '../../utils/lib';

let pttOwner = '';

const ActiveCallCard = ({
    data, contactList, user, dial,
    isAmb, isBrd, isEmg, initMptt,
    sendGroupCallAction, sendIndividualCallAction,
    masterPTT, addMastPTT, remMastPTT,
    setCallasCurrent, currentCall,
    initiatePatchCall, addCallToPatch, patchCallType, initPatch, addAckEmergencyCallStatus, mergeCallType, initMerge, initiateMergeCall, addCallToMerge, disableCallPatch, disableCallMerge
}) => {

    //Speaking party / call action
    const [callAction, setCallAction] = useState('');
    const [colorReqd, setColor] = useState(false);
    const [isMptt, setifMptt] = useState(false);
    const [isPatchable, setPatchable] = useState(false);
    const [isPatching, setPatching] = useState(false);
    const [isMergable, setMergable] = useState(false);
    const [isMerging, setMerging] = useState(false);
    const [isEmgCall, setEmgCall] = useState(isEmg);
    const [msg, setMsg] = useState('Trying...');
    const [actionItem, setAction] = useState({
        hold: false,
        mic: true,
        speaker: true,
        ptt: false,
        ispttTick: false,
        disconnectDis: false,
        holdDis: false,
        micDis: false,
        speakerDis: false,
        pttDis: false,
        pttReq: false,
    });
    const videoRef = useRef();
    const [videocall, setVideoCall] = useState(false);

    const getCallieName = (id) => {
        const callerId = getCallieIdToShow(id);
        const subDetails = contactList.filter(cont =>
            getCallieIdToShow(cont.mcptt_id) === callerId
        );
        if (subDetails.length > 0) return subDetails[0].contactName;
        else return getCallieIdToShow(id);
    }

    useEffect(() => {
        console.log('active call card .... effect', data, data.talkingPartyId);
        const callState = getCallAction(data.stateType);
        let isCallInitialState = false;
        
        setAction(data.actionItem);
        if (dial) {
            if (data.stateType === 'RINGING' || data.stateType === 'TRYING' || data.stateType === 'WAITING') {
                isCallInitialState = true;
            } else {
                isCallInitialState = false;
            }
            if (data.stateType === 'RINGING') setMsg('Ringing...')
            if (data.stateType === 'TRYING') setMsg('Trying...')
            if (data.stateType === 'WAITING') setMsg('Waiting...')
        }
        if (!dial && data.actionItem['highlight']) {
            setTimeout(() => {
                const call = new CallAction(data, "DIS_HIGH");
                const stateup = getCallActionState("DIS_HIGH", data.actionItem);
                call.actionItem = stateup;
                if (data.callType.includes('GROUP')) sendGroupCallAction(user, call)
                else sendIndividualCallAction(user, call)
            }, 100);
        }
        // console.log('TALKING',data,actionItem)
        if (callState) {
            setCallAction(callState)
            setColor(true)
        } else {
            if (data.stateType == "UNHOLD") {
                setCallAction('')
            }
        }

        if (!dial && !isCallInitialState && data.talkingPartyId && data.talkingPartyId != '0') {
            if (getCallieIdToShow(data.talkingPartyId) == getCallieIdToShow(user.profile.mcptt_id)) {
                setCallAction('You are Speaking');
                pttOwner = 'self';
                setColor(false);
                if (!data.actionItem['ptt']) {
                    updateCallAction("PTT_GRANTED");
                }
            }
            else {
                console.log("call on other speaking block");
                const callie = '(' + getCallieName(data.talkingPartyId) + ') is speaking!';
                setCallAction(callie);
                pttOwner = 'other';
                setColor(false);
                if (data.actionItem['ptt']) {
                    updateCallAction("PTT_IGNORED");
                }
            }
        } else if (!isCallInitialState && data.talkingPartyId && data.talkingPartyId == '0') {
            console.log("call on other 0 block");
            if (data.stateType == "HOLD") {
                setCallAction(callState);
            } else {
                setCallAction('');
                pttOwner = '';
            }
            if (data.actionItem['pttReq'] || data.actionItem['ptt']) {
                updateCallAction("PTT_IGNORED");
            }
        }
        
        //PATCHING
        if (data.stateType === "PATCH_CALL" && !isPatching) setPatching(true);
        if (data.stateType !== "PATCH_CALL" && isPatching) setPatching(false);

        if (data.stateType === "MERGE_CALL" && !isMerging) setMerging(true);
        if (data.stateType !== "MERGE_CALL" && isMerging) setMerging(false);

        if (data.stateType === "CALL_UPGRADE") {
            console.log("CALL_UPGRADE UI ", data);
            if (data.callPriority && parseInt(data.callPriority) === 15) {
                setEmgCall(true);
            }
        }

        const isVideo = data.isVideo ? JSON.parse(data.isVideo) : false;
        const isVideoconfig = global.config.video_call_config ? JSON.parse(global.config.video_call_config) : false;
        
        if (isVideo && isVideoconfig) {
            const videoElement = videoRef.current;
            if (videoElement) {
                if (data.stateType == "HOLD" || isCallInitialState) {
                    videoRef.current.srcObject = null;
                }
                else if (pttOwner == 'self' && data.localAudio) {
                    videoRef.current.srcObject = data.localAudio;
                } else { 
                    if (pttOwner == 'other' && data.track) {
                        let remoteStrm = new MediaStream();
                        remoteStrm.addTrack(data.track);
                        videoRef.current.srcObject = remoteStrm;
                    } else {
                        videoRef.current.srcObject = null;
                    }
                }
            } 
            setVideoCall(true);
        } else {
            setVideoCall(false);
        }
    }, [data])

    useEffect(() => {
        if (data.callType.includes('GROUP')) {
            if (initMptt && !isMptt) setifMptt(true);
            else if (!initMptt && isMptt) {
                setifMptt(false);
            }
        }
    }, [initMptt])

    useEffect(() => {
        if (initPatch && patchCallType === data.callType) {
            setPatchable(true)
        }
        if (!initPatch && isPatchable) setPatchable(false)
    }, [initPatch])

    useEffect(() => {
        if (initMerge && mergeCallType === data.callType) {
            setMergable(true)
        }
        if (!initMerge && isMergable) setMergable(false)
    }, [initMerge])

    const updateCallAction = (type) => {
        const call = new CallAction(data, type);
        const stateup = getCallActionState(type, data.actionItem);
        call.actionItem = stateup;
        console.log('Active Call updateCallAction..', type, call)
        if (type === 'DISCONNECTED') {
            if (user.profile.mcptt_id === data.toId) {
                if (Number(data.callPriority) === 15) {
                    addAckEmergencyCallStatus(call)
                }
            }
        }

        if (type === 'RELEASE_PUSH_TO_TALK' || type === 'ACQUIRE_PUSH_TO_TALK') {
            call.fromId = user && user.profile.mcptt_id
        }
        if (type !== 'ACQUIRE_PUSH_TO_TALK') {
            if (call.callType.includes('GROUP')) sendGroupCallAction(user, call)
            else sendIndividualCallAction(user, call);
        }
        else if (data.stateType !== 'RELEASE_PUSH_TO_TALK') {
            if (call.callType.includes('GROUP')) sendGroupCallAction(user, call)
            else sendIndividualCallAction(user, call);
        }
    };

    const getBgStyle = () => {
        try {
            if (actionItem['ptt']) {
                if (!isEmgCall) return { backgroundColor: 'rgb(186, 255, 184)' }
                else return { backgroundColor: '#fbc6c6' }
            }
            else if (actionItem['hold']) return { backgroundColor: '#dddddd' }
            else if (!actionItem['speaker']) return { backgroundColor: '#e3f1ff' }
            else if (isEmgCall) return { backgroundColor: '#fff' }
            else return { color: '#666' }
        }
        catch (e) {
            console.log('CRASH : getting Card BgClr', e)
            return {}
        }
    }

    const initMasterPTT = () => {
        masterPTT(true)
    }

    const checkMpttSelcn = (check) => {
        if (check) {
            addMastPTT(data);
            updateCallAction("PTT_TICK")
        }
        else {
            remMastPTT(data);
            updateCallAction("ENABLE")
        }
        if (check === 'enable') {
            setAction(getCallActionState("ENABLE", actionItem))
        }
    }

    const checkVideoCallStarted = () => {
        if (videocall) {
            if (onProcessState) {
                return false;
            } else if(pttOwner == '') {
                return false;
            } else if (actionItem['hold']) {
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    const Priority = getPriority(parseInt(data.callPriority));
    //const callieId = data.fromId === (user && user.profile.mcptt_id) ? data.toId : (data.groupId || data.fromId);
    //let isCalled = data.fromId == (user && user.profile.mcptt_id) ? true : false;

    let callieId = data.isFACall ? (data.fromId == global.config.activatedFA ? data.toId : data.fromId) : data.fromId === (user && user.profile.mcptt_id) ? data.toId : (data.groupId || data.fromId);
    let isCalled = data.isFACall ? (data.fromId === global.config.activatedFA) : (data.fromId === (user && user.profile.mcptt_id));
    
    let Name = data.isFACall ? callieId : getCallieName(callieId);
    const showGroupId = data.groupId ? (getCallieIdToShow(data.groupId) == Name) ? '' : getCallieIdToShow(data.groupId) : '';
    
    let callTypeName = getCallName(data.callType);
    const isCurrent = (currentCall && currentCall.length) ? currentCall[0].callId.includes(data.callId) : false;
    const isPatched = isPatchable && actionItem['isPatchTick'];
    const isMerged = isMergable && actionItem['isMergeTick'];
    const divMain = videocall === true ? "act-grp-card-video-background" : "act-grp-card-audio-background"
    if (global.config.activatedFA && global.config.activatedFA.length > 0) {
        if (data.fromId === global.config.activatedFA) {
            isCalled = true
            Name = data.toId
        }
    }
    if (data.forwardedId && data.forwardedId.length > 0) {
        Name = Name + ' -> ' + getCallieIdToShow(data.forwardedId);
    }

    let onProcessState = (data.stateType === 'RINGING' || data.stateType === 'TRYING' || data.stateType === 'WAITING') ? true : false;
    let isVideocallStarted = checkVideoCallStarted();
    
    return (
        <div ref={videoRef} className={divMain + (actionItem['highlight'] || isCurrent ? " highlight" : "") + (isPatched || isMerged ? " highlightPatch" : "")}
            style={actionItem['highlight'] || isCurrent ? { backgroundColor: '#baffb7' } : { backgroundColor: '' }}
        >
            {
                videocall === true ?
                    <div className='act-grp-card-video' style={{ backgroundColor: '#baffb7', position: 'absolute' }}>
                        <video ref={videoRef} autoPlay className='act-grp-video' />
                    </div>
                    : null
            }
            <div style={videocall == true ? { backgroundColor: '#00000000', zIndex: 2 } : getBgStyle()}
            >
                <ContextMenu
                    id={data.indexId + Name}
                    subr={callieId} type={'AC'}
                    setMptt={initMasterPTT}
                    setCurrent={() => setCallasCurrent(data, true)}
                    pttOpt={true}
                    callType={data.callType}
                    initPatch={() => initiatePatchCall(data)}
                    addToPatch={() => addCallToPatch(data)}
                    initMerge={() => initiateMergeCall(data)}
                    addToMerge={() => addCallToMerge(data)}
                    disableCallPatch={disableCallPatch ? true : false}
                    disableCallMerge={disableCallMerge ? true : false}
                    onProcessState={onProcessState}
                    style={isVideocallStarted ? { backgroundColor: '#00000000' } : onProcessState ?  { backgroundColor: '#baffb7' } : { backgroundColor: '#fff' }}
                >
                    <div className={isEmgCall ? "act-card-grid-emg" : showGroupId ? "act-card-grid" : "act-card-grid-indvl"}
                        style={actionItem['ispttTick'] && !actionItem['ptt'] && initMptt ? { cursor: 'pointer', opacity: '0.6' }
                            : (initPatch && !isPatchable) ? { pointerEvents: 'none', opacity: '0.6' } : videocall == true ? { backgroundColor: '#00000000' } : getBgStyle()}
                    >
                        {isMptt && !actionItem['ptt'] ? <input className="act-checkbox" type="checkbox" checked={actionItem['ispttTick']} onChange={(e) => checkMpttSelcn(e.target.checked)} /> : null}
                        <span className={isVideocallStarted ? "act-name f-subs-name white textShadow" : "act-name f-subs-name dark"}>{Name}</span>
                        {
                            showGroupId && showGroupId.length > 0 ?
                                <div class="act-id">
                                    <p class="f-subs-id">{showGroupId}</p>
                                </div>
                                : null
                        }

                        <div className="act-status">
                            <div className="in-blc mr-4 lime-5">
                                <svg
                                    width="8"
                                    height="8"
                                    viewBox="0 0 6 6"
                                    class="blinking"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx="3" cy="3" r="3" fill="currentColor" />
                                </svg>
                            </div>
                            <p class="f-10 f-mid roboto in-blc mrg-0">
                                <span className={isVideocallStarted ? "f-status white textShadow" : "f-status"} style={colorReqd || isPatching ? { color: '#078041', fontWeight: 'bold' } : {}}>
                                    {!isPatching ? callAction : 'Patching...'}
                                </span>
                            </p>
                        </div>
                        <div className="act-icon">
                            <p className={isVideocallStarted ? "f-status in-blc mr-8 white textShadow" : "f-status in-blc mr-8"}>
                                <strong>{Priority}</strong>
                            </p>
                            <img
                                className="in-blc x10"
                                src={`/assets/images/${isCalled ? 'outgoing' : 'incoming'}.svg`}
                                alt=''
                            />
                        </div>

                        <div className="act-call">
                            <p className={isVideocallStarted ? "f-status in-blc m-l-15 white textShadow" : "f-status in-blc m-l-15"}>
                                <strong>{callTypeName + (isCalled ? ' (OUT)' : ' (IN)')}</strong>
                            </p>
                        </div>

                        <div className="act-ptt">
                            <button
                                type="button"
                                onClick={() => actionItem['ptt'] ? updateCallAction("RELEASE_PUSH_TO_TALK") : updateCallAction("ACQUIRE_PUSH_TO_TALK")}
                                className={("btn w100 h100 " + (!isEmgCall ? "ptt " : "emergency-ptt ")) + (isAmb || isBrd ? 'amb' : !actionItem['pttReq'] ? (actionItem['ptt'] ? 'active' : actionItem['pttDis'] ? 'inactive' : '') : 'req')}
                                data-toggle="button"
                                aria-pressed="false"
                                style={actionItem['pttDis'] || dial || isAmb || isBrd ? { pointerEvents: 'none' } : {}}
                            >
                                {!(isAmb || isBrd) ?
                                    <React.Fragment>
                                        <img src="/assets/images/Vector-4.svg" alt='' />
                                        <p className="white m-t-4"><strong>PTT</strong></p>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <img src={`/assets/images/${isAmb ? 'listening' : 'broadcast'}.svg`} alt='' />
                                        <p className="white m-t-4"><strong>{isAmb ? 'AMB' : 'BRD'}</strong></p>
                                    </React.Fragment>}
                            </button>
                        </div>
                        
                        <div className="act-discon">
                            <button
                                className="discon w100 h100"
                                onClick={() => updateCallAction("DISCONNECTED")}
                                type="button"
                                name=""
                                style={actionItem['disconnectDis'] ? { pointerEvents: 'none' } : {}}
                            >
                                <img
                                    src={`/assets/images/${actionItem['disconnectDis'] ? 'dis_disconnect' : 'Vector-3'}.svg`}
                                    id="w-node-5470f4d863f4-c405450c"
                                    alt=""
                                />
                            </button>
                        </div>

                        <div className="act-hld f-12 f-mid h_center v_center">
                            <button
                                className="btn hld_btn"
                                data-toggle="button"
                                aria-pressed="false"
                                onClick={() => actionItem['hold'] ? updateCallAction("UNHOLD") : updateCallAction("HOLD")}
                                style={dial ? { pointerEvents: 'none', color: '#078041' } : actionItem['holdDis'] ? { pointerEvents: 'none', color: '#757575' } : {}}
                            >
                                {dial ? msg
                                    : <img src={`/assets/images/${actionItem['holdDis'] ? 'hold_disabled' : actionItem['hold'] ? 'unhold' : 'hold'}.svg`} alt='' />}
                            </button>
                        </div>
                        {!dial ?
                            <div className="act-mut">
                                <button
                                    onClick={() => !actionItem['speaker'] ? updateCallAction("UNMUTE_SPEAKER") : updateCallAction("MUTE_SPEAKER")}
                                    data-toggle="button"
                                    aria-pressed="false"
                                    className="btn mut_btn w100 h100 brd_0"
                                    style={actionItem['speakerDis'] ? { pointerEvents: 'none' } : {}}
                                >
                                    <img src={`/assets/images/${actionItem['speakerDis'] ? 'muted' : !actionItem['speaker'] ? 'mutePressed' : 'Group'}.svg`} alt='' />
                                </button>
                            </div>
                            : null}
                    </div>
                </ContextMenu>
            </div>
        </div>

    )
}

const mapStateToProps = ({ auth, communication }) => {
    const { user } = auth;
    const { contactList, initMptt, currentCall, patchCallType, initPatch, mergeCallType, initMerge } = communication;
    return {
        user, contactList, initMptt, currentCall, patchCallType, initPatch, mergeCallType, initMerge
    };
};

export default connect(mapStateToProps, {
    sendGroupCallAction, sendIndividualCallAction,
    masterPTT, addMastPTT, remMastPTT,
    setCallasCurrent,
    initiatePatchCall, addCallToPatch, addAckEmergencyCallStatus,
    initiateMergeCall, addCallToMerge
})(ActiveCallCard)
