import React, { useEffect } from "react";
import SearchCollapseTab from "./searchCollapseTab";

export const TitleTab = (props) => {
  return (
    <div className={props.class ? props.class : props.className ? props.className : ""}>
      {props.type === "none" ? (
        <div className="title-grid-1">
          <div className="title">
            <div className="pt-6 pb-6 pr-6 pl-6 f-18 f-reg white muli">
              {props.title}
            </div>
          </div>
        </div>
      ) : null}

      {props.type !== "none" ? (
        <div className="title-grid-2">
          <div className="title">
            <div className="headerTitle">
              {props.title}
            </div>
          </div>
          <div className="search">
            {props.type === "PartnerListTab" ? (
              <SearchCollapseTab
                search={props.search}
                indvGrp={props.filtr}
                dataList={true}
                mtmOpen={props.mtmOpen}
              />
            ) : null}
            {props.type === "TenantListTab" ? (
              <SearchCollapseTab
                search={props.search}
                indvGrp={props.filtr}
                dataList={true}
                mtmOpen={props.mtmOpen}
              />
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  )
};

export default TitleTab;