(function (exports) {
  const Events = require("events").EventEmitter;
  const OutgoingRequest = require("../sipMethods/OutgoingRequest").OutgoingRequest;
  const RequestSender = require("../jssip/RequestSender");
  const Transactions = require("../jssip/Transactions");
  const DOMParser = require("xmldom").DOMParser;
  const JsSIP = require("../jssip/JsSIP");
  const URI = require("../jssip/URI");

  class NodeStatus extends Events {
    constructor(mcptt_id, UA) {
      super();

      this.UA = UA;
      this.subscriber = mcptt_id;
      this.contact = this.UA._registrator._contact;
      this.expires = "3600";
      this.subscribe();
      this.parser = new DOMParser();

      this.subscriptions = new Map();
      this.dialogStateMap = new Map([
        ["terminated", "AVAILABLE"],
        ["early", "RINGING"],
        ["confirmed", "BUSY"],
        ["trying", "BUSY"],
      ]);
    }

    subscribe() {
      const extraHeaders = [
        `Contact: ${this.contact.toString()}; expires=${this.expires}`,
        `Expires: ${this.expires}`,
        "Event: node-status",
        `Accept: application/json`,
      ];
      const to = new URI("sip", "mcdx", `mcptt.org`, null, null, null);
      const ruri = new URI("sip", this.subscriber, "mcptt.org", null, null, null);
      const options = {
        to_uri: to,
        from_uri: ruri,
        call_id: JsSIP.Utils.createRandomToken(22),
        cseq: 1,
        from_tag: "",
      };
      console.log("ExtraHeaders: ", extraHeaders.length);

      const request = new OutgoingRequest(
        JsSIP.C.SUBSCRIBE,
        ruri,
        this.UA,
        options,
        extraHeaders,
        ""
      );
      console.log("Request: ", request.headers);

      const eventHandlers = {
        onReceiveResponse: (response) => {
          //this.subscribeResponseHandler(response);
          //console.log('Response :',response);
          this.emit('nodestatusres', true);
        },
        onRequestTimeout: () => {
          console.log("webRTC.sendSubscribeRequest.onRequestTimeout");
          this.emit('nodestatusres', false);
        },
        onTransportError: () => {
          console.log("webRTC.sendSubscribeRequest.onTransportError NodeStatus");
          this.emit('nodestatusres', false);
        },
        onAuthenticated: () => {
          console.log("webRTC.sendSubscribeRequest.onAuthenticated");
          this.emit('nodestatusres', false);
        },
      };

      const requestSender = new RequestSender(this.UA, request, eventHandlers);
      requestSender.send();
      console.log(request.headers);
    }

    handleNotify(request) {
      //console.log(request);
      if (Transactions.checkTransaction(this.UA, request)) return;

      new Transactions.NonInviteServerTransaction(
        this.UA,
        this.UA.transport,
        request
      );

      try {
        const subscriptionStateHeader =
          request.headers["Subscription-State"][0].raw;
        if (subscriptionStateHeader) {
          const subscriptionState = subscriptionStateHeader.split(";")[0];

          this.updateSubscriptionState(request);

          if (subscriptionState === "terminated") return;
        }
      } catch (err) {
        // console.debug('err: ', err);
      }

      request.reply(200);
      if (!request.body) return;

      //const parsedBody = parse(request.body);

      try {
        // Grab the state and version from the parsed body
        //const state = parsedBody.root.children[0].children[0].content;
        //const version = parseInt(parsedBody.root.attributes.version, 10);
        const version = 1;
        const state = "active";

        /* const content = `{
                  nodeStatusUpdate:${request.body}
              }`
              const parsedBody = JSON.parse(content); */

        if (request.body) {
          console.log("Parsed body node-status is", JSON.parse(request.body));
          this.emit("NODE_SATUS", JSON.parse(request.body));
        }
        console.log("Sending OK");
        

        const subscription = this.getSubscription(request);

        if (subscription) {
          if (version > subscription.version) {
            this.updateVersion(request, "1");
            this.emitUserStateChanged(subscription.toUser, state);
          }
          // Reply OK
          console.log("Sending OK");
          //request.reply(200);
          //return request.body;
        }
      } catch (err) {
        console.debug("Coul not process notify: ", err);
      }
    }

    getSubscription(message) {
      return this.subscriptions.get(message.call_id);
    }
  }

  exports.NodeStatus = NodeStatus

})(typeof exports === 'undefined' ? this['NodeStatus'] = {} : exports);

//module.exports = exports.default = NodeStatus;
