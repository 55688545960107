(function (exports) {
  const EndPoints = require("../endpoints").EndPoints;
  const EventEmitter = require("events").EventEmitter;
  const RequestHandler = require("./requestHandler").requestHandler;
  const js2xmlparser = require("js2xmlparser");
  const axios = require("axios").default;
  const Organisation = require("../CMC/organisationProfile").Organisation;
  const GroupXMLProcessor = require("../CMC/group").GroupXMLProcessor;
  const UserProfile = require("./../CMC/userProfile").UserProfile;


  class Admin extends EventEmitter {
    constructor(ua) {
      super();
      this.headers = {
        "Content-Type":
          'application/org.3gpp.mcptt.user-profile+xml; charset="utf-8" ',
      };
      this.ua = ua;
      this.groupRoot = "/gms/consort";
      this.orgRoot = "/organisation";
      this.iwfRoot = "/iwfMapping";
      this.root = "/cms/consort/mcptt/";
      this.msterUEConfig = null;
      this.fetchMasterUEConfig();
    }

    getUsers() {
      return RequestHandler.get(`${EndPoints.getConfig().idmsHost}?tenantId=${global.config.userConfig.tenantId}`).then((list) => {
        if (list && list.length) {
          console.log("User_LIST", list);
          this.emit("IDMS_USER_LIST", list);
          return list;
        } else {
          return [];
        }
      });
    }

    getPartnerList() {
      return RequestHandler.get(EndPoints.getConfig().getPartnerList).then((list) => {
        if (list && list.length) {
          return list
        } else {
          return [];
        }
      })
    }
    getMasterConfigServiceConfig() {
      return RequestHandler.get(EndPoints.getConfig().getMasterConfigServiceConfig).then((data) => {
        if (data && data.isError === false) {
          return data
        } else {
          return [];
        }
      })
    }

    getUeTypeList(data) {
      return RequestHandler.get(`${EndPoints.getConfig().getUeTypeList}?mtmId=${data}`).then((data) => {
        if (data && data.isError == false) {
          return data.result
        } else {
          return [];
        }
      })
    }

    getAvailableServices(data) {
      const path = `${EndPoints.getConfig().getAvailableServices}`
      return axios.post(path, data).then(
        (resp) => {
          if (resp.status === 200) return resp.data;
          else return resp;
        },
        (err) => {
          console.log("error on getAvailableServices" + err);
          return false;
        }
      )
        .catch((e) => {
          console.log("getAvailableServices catch error")
          return false;
        });
    }

    createPartner(partner) {
      const path = `${EndPoints.getConfig().createPartner}`
      const params = partner[0].data

      return axios.put(path, params).then(
        (resp) => {
          if (resp.status === 200) return resp;
          else return resp;
        },
        (err) => {
          console.log("error on CreatePartner" + err);
          let res = { "isError": true, "result": "Same Domain already exists in partner/tenant" }
          return res;
        }
      )
        .catch((e) => {
          console.log("create Partner catch error")
          return false;
        });
    }
    updatePartner(partner) {
      const path = `${EndPoints.getConfig().createPartner}`
      const params = partner[0].data

      return axios.put(path, params).then(
        (resp) => {
          if (resp.status === 200) return resp;
          else return resp;
        },
        (err) => {
          console.log("error on UpdatePartner" + err);
          return false;
        }
      )
        .catch((e) => {
          console.log("update Partner catch error")
          return false;
        });
    }

    deletePartner(data) {
      const path = `${EndPoints.getConfig().deletePartner}`
      return axios.delete(`${path}?partnerId=${data}`).then((resp) => {
        if (resp.status === 200) return true;
        else return false;
      },
        (err) => {
          console.log("error on delete Partner" + err);
          return false;
        }
      )
        .catch((err) => {
          console.log("error on delete Partner" + err);
          return false;
        });
    }


    getTenantList() {
      return RequestHandler.get(`${EndPoints.getConfig().getTenantList}?partnerId=${global.config.userConfig.partnerId}`).then((list) => {
        console.log("getTenantList() ", list);
        if (list && list.length > 0) {
          return list
        } else {
          return [];
        }
      })
    }

    getAllSubDomains(domainName) {
      const domain = { "domainName": domainName }
      const path = `${EndPoints.getConfig().getAllSubDomains}`
      return axios.post(path, domain).then(
        (resp) => {
          if (resp.status === 200) return resp.data;
          else return resp;
        },
        (err) => {
          console.log("error on getAllSubDomains" + err);
          return false;
        }
      )
        .catch((e) => {
          console.log("getAllSubDomains catch error")
          return false;
        });
    }
    getAdminProfile(data) {
      return RequestHandler.get(`${EndPoints.getConfig().getAdminProfile}?userId=${data}`).then((profile) => {
        return profile;
      })
    }

    getAllSwitchProfiles(data) {
      return RequestHandler.get(`${EndPoints.getConfig().getAllSwitchProfiles}?partnerId=${data}`).then((profile) => {
        return profile;
      })
    }

    getUsersForDeletetion(id) {
      return RequestHandler.get(`${EndPoints.getConfig().idmsHost}?tenantId=${id}`).then((list) => {
        if (list && list.length) {
          console.log("getUsersForDeletetion: User_LIST", list);
          return list;
        } else {
          return [];
        }
      });
    }

    deleteMtmUserOnIdms(mtmId) {
      return new Promise((resolve, reject) => {
        this.getUsersForDeletetion(mtmId).then(list => {
          console.log("deleteMtmUserOnIdms--->>>", list)
          if (list && list.length > 0) {
            const promisesArray = [];
            for (const userObj of list) {
              promisesArray.push(this.deleteUser(userObj.id))
            }
            Promise.all(promisesArray).then((results) => {
              console.log("deleteMtmUserOnIdms: Results", results)
              resolve(true);
            }).catch((e) => {
              console.log("deleteMtmUserOnIdms: Error in deleteIdmsAndServer", e)
              reject(false);
            });
          }
        })
      })
    }


    deleteMtmUser(data) {
      console.log("deleteMtmUser: Incoming ", data);
      return new Promise((resolve, reject) => {
        if (data.userType.type === "partner") {
          this.deleteMtmUserOnServer(data.data, "partner").then(resp => {
            if (resp) {
              this.deleteMtmUserOnIdms(data.data).then(value => {
                if (value) {
                  resolve(true);
                }
              }).catch((e) => {
                console.log("deleteMtmUser: Error in deleteMtmUserOnIdms PARTNER", e)
                reject(false);
              });
            }
          }).catch((e) => {
            console.log("deleteMtmUser: Error in deleteMtmUserOnServer PARTNER", e)
            reject(false);
          });
        } else {
          this.deleteMtmUserOnServer(data.data, "tenant").then(resp => {
            if (resp) {
              this.deleteMtmUserOnIdms(data.data).then(value => {
                if (value) {
                  resolve(true);
                }
              }).catch((e) => {
                console.log("deleteMtmUser: Error in deleteMtmUserOnIdms TENANT", e)
                reject(false);
              });
            }
          }).catch((e) => {
            console.log("deleteMtmUser: Error in deleteMtmUserOnServer TENANT", e)
            reject(false);
          });
        }
      })
    }

    deleteMtmUserOnServer(id, type) {
      console.log("deleteMtmUserOnServer: Incoming ", id, type);
      return new Promise((resolve, reject) => {
        if (type === "partner") {
          this.deletePartner(id).then(value => {
            if (value) {
              resolve(true);
            } else {
              console.log("deleteMtmUserOnServer: deletePartner Error!");
              reject("deleteMtmUserOnServer: deletePartner Error!");
            }
          })
        } else {
          this.deleteTenant(id).then(value => {
            if (value) {
              resolve(true);
            } else {
              console.log("deleteMtmUserOnServer: deleteTenant Error!");
              reject("deleteMtmUserOnServer: deleteTenant Error!");
            }
          })
        }
      })

    }

    createTenant(tenant) {
      const path = `${EndPoints.getConfig().createTenant}`
      const params = tenant[0].data

      return axios.post(path, params).then(
        (resp) => {
          if (resp.status === 200) return resp;
          else return resp;
        },
        (err) => {
          console.log("error on CreateTenant" + err);
          let res = { "isError": true, "result": "Same Domain already exists in partner/tenant" }
          return res;
        }
      )
        .catch((e) => {
          console.log("create Tenant catch error")
          return false;
        });
    }

    updateTenant(tenant) {
      const path = `${EndPoints.getConfig().updateTenant}`
      let params = tenant[0].data
      return axios.put(path, params).then(
        (resp) => {
          if (resp.status === 200) return resp;
          else return resp;
        },
        (err) => {
          console.log("error on UpdateTenant" + err);
          return false;
        }
      )
        .catch((e) => {
          console.log("update Tenant catch error")
          return false;
        });
    }

    deleteTenant(data) {
      const path = `${EndPoints.getConfig().deleteTenant}`
      return axios.delete(`${path}?tenantId=${data}`).then((resp) => {
        if (resp.status === 200) return true;
        else return false;
      },
        (err) => {
          console.log("error on delete Tenant" + err);
          return false;
        }
      )
        .catch((err) => {
          console.log("error on delete Tenant" + err);
          return false;
        });
    }

    createUserOnIdmsAndServer(user) {
      console.log("createUserOnIdmsAndServer: Incoming ", user);
      let adminData = {
        "profileName": "admin",
        "userName": "admin" + `@${user.domainName}`,
        "password": "12345678",
        "Role": "Admin",
        "TetraUser": false,
        "Email": "admin" + `@${user.domainName}`,
        "phoneNumber": "",
        "orgId": "0",
        "mcpttId": "admin",
        "CallerDescr": "admin",
        "orgId": "0",
        "subscriberType": "Admin",
        "faList": [],
        "tetra": false,
        "VideoEnable": false,
        "mcxVideoID": false,
        "mcxDataID": false,
        "mcxDataIdText": "",
        "mcxVideoIdText": "",
        "broadCastCall": false,
        "priority": "1",
        "tenantId": user.tenantId,
        "UEInfo": [
          {
            "text": "DISPATCHER",
            "value": "Dispatcher",
            "noOfReg": "1",
            "primary": false
          }
        ],
        "userType": "Dispatcher",
        "callForwardingData": {
          "allowCallForwarding": false,
          "allowCallForwardManualInput": false,
          "callForwardingTarget": "",
          "callForwardingOn": false,
          "callForwardingCondition": "",
          "callForwardingNoAnswerTimeout": "",
          "allowCallForwardManualInputTarget": ""
        },
        "cadCallData": {
          "allowPrivateCallParticipation": true,
          "incomingAuthorizationRequired": false,
          "allowPrivateCallToAnyUser": false,
          "outgoingAuthorizationRequired": false,
          "defaultAuthorizer": ""
        },
        "permissions": {
          "trainTab": true,
          "communicationTab": true,
          "alertTab": true,
          "baseStation": true,
          "locationTab": true,
          "recordingsTab": true,
          "grabAllowed": "default",
          "trainMovement": "default"
        },
        "domain": user.domainName
      }
      adminData.profileName = user.domainName && user.domainName !== "" ? `${adminData.profileName}@${user.domainName}` : adminData.profileName
      const data = new UserProfile(adminData);
      const xml = js2xmlparser.parse("mcptt-user-profile", data.userProfile);
      //console.log("addUserProfile Created xml is", xml.toString());
      const _this = this;
      return new Promise(function (resolve, reject) {
        axios
          .put(`${EndPoints.getConfig().CMSURI}${_this.root}`, xml.toString(), {
            headers: _this.headers,
          })
          .then(function (resp) {
            console.log("createUserOnIdmsAndServer: response 1", resp);
            if (resp.status === 200) {
              const path = `${EndPoints.getConfig().idmsHost}/Create`;
              console.log("createUser api", user, adminData, path);
              const params = new URLSearchParams();
              params.append("UserName", adminData.userName);
              params.append("password", adminData.password);
              params.append("mcptt_id", user.domainName && user.domainName !== "" ? `${adminData.mcpttId}@${user.domainName}` : adminData.mcpttId);
              params.append("Role", adminData.Role);
              params.append("TetraUser", adminData.TetraUser);
              params.append("Email", adminData.Email);
              params.append("Phone", adminData.phoneNumber);
              params.append("orgId", adminData.orgId);
              params.append("callForwardingData", adminData.callForwardingData ? adminData.callForwardingData : "");
              params.append("mcdata_id", adminData.mcxDataIdText ? adminData.mcxDataIdText : adminData.mcpttId);
              params.append("mcvideo_id", adminData.mcxVideoIdText ? adminData.mcxVideoIdText : adminData.mcpttId);
              params.append("isMcDataIdConfig", adminData.mcxDataID ? adminData.mcxDataID : false);
              params.append("isMcVideoIdConfig", adminData.mcxVideoID ? adminData.mcxVideoID : false);
              params.append("tenantId", user.tenantId);
              console.log("createUserOnIdmsAndServer: response 2", params.toString());

              RequestHandler.post(path, params).then((res) => {
                console.log("createUserOnIdmsAndServer: createUser api res 22", res);
                resolve(res)
              });
            } else {
              resolve(false);
            }
          })
          .catch(function (err) {
            //logger.info.log("info",'Error in IWF CMS PUT: ',err);
            console.log("createUserOnIdmsAndServer: Error in CMS PUT: ", err);
            resolve(false);
          });
      });

    }
    createPartnerUserOnIdmsAndServer(user) {
      console.log("createUserOnIdmsAndServer: Incoming ", user);
      let adminData = {
        "profileName": "admin",
        "userName": "admin" + `@${user.domainName}`,
        "password": "12345678",
        "Role": "Admin",
        "TetraUser": false,
        "Email": "admin" + `@${user.domainName}`,
        "phoneNumber": "",
        "orgId": "0",
        "mcpttId": "admin",
        "CallerDescr": "admin",
        "orgId": "0",
        "subscriberType": "Admin",
        "faList": [],
        "tetra": false,
        "VideoEnable": false,
        "mcxVideoID": false,
        "mcxDataID": false,
        "mcxDataIdText": "",
        "mcxVideoIdText": "",
        "broadCastCall": false,
        "priority": "1",
        "tenantId": user.partnerId,
        "UEInfo": [
          {
            "text": "DISPATCHER",
            "value": "Dispatcher",
            "noOfReg": "1",
            "primary": false
          }
        ],
        "userType": "Dispatcher",
        "callForwardingData": {
          "allowCallForwarding": false,
          "allowCallForwardManualInput": false,
          "callForwardingTarget": "",
          "callForwardingOn": false,
          "callForwardingCondition": "",
          "callForwardingNoAnswerTimeout": "",
          "allowCallForwardManualInputTarget": ""
        },
        "cadCallData": {
          "allowPrivateCallParticipation": true,
          "incomingAuthorizationRequired": false,
          "allowPrivateCallToAnyUser": false,
          "outgoingAuthorizationRequired": false,
          "defaultAuthorizer": ""
        },
        "permissions": {
          "trainTab": true,
          "communicationTab": true,
          "alertTab": true,
          "baseStation": true,
          "locationTab": true,
          "recordingsTab": true,
          "grabAllowed": "default",
          "trainMovement": "default"
        },
        "domain": user.domainName
      }
      adminData.profileName = user.domainName && user.domainName !== "" ? `${adminData.profileName}@${user.domainName}` : adminData.profileName
      const data = new UserProfile(adminData);
      const xml = js2xmlparser.parse("mcptt-user-profile", data.userProfile);
      //console.log("addUserProfile Created xml is", xml.toString());
      const _this = this;
      return new Promise(function (resolve, reject) {
        const path = `${EndPoints.getConfig().idmsHost}/Create`;
        console.log("createUser api", user, adminData, path);
        const params = new URLSearchParams();
        params.append("UserName", adminData.userName);
        params.append("password", adminData.password);
        params.append("mcptt_id", user.domainName && user.domainName !== "" ? `${adminData.mcpttId}@${user.domainName}` : adminData.mcpttId);
        params.append("Role", adminData.Role);
        params.append("TetraUser", adminData.TetraUser);
        params.append("Email", adminData.Email);
        params.append("Phone", adminData.phoneNumber);
        params.append("orgId", adminData.orgId);
        params.append("callForwardingData", adminData.callForwardingData ? adminData.callForwardingData : "");
        params.append("mcdata_id", adminData.mcxDataIdText ? adminData.mcxDataIdText : adminData.mcpttId);
        params.append("mcvideo_id", adminData.mcxVideoIdText ? adminData.mcxVideoIdText : adminData.mcpttId);
        params.append("isMcDataIdConfig", adminData.mcxDataID ? adminData.mcxDataID : false);
        params.append("isMcVideoIdConfig", adminData.mcxVideoID ? adminData.mcxVideoID : false);
        params.append("tenantId", user.partnerId);
        console.log("createUserOnIdmsAndServer: response 2", params.toString());
        RequestHandler.post(path, params).then((res) => {
          console.log("createUserOnIdmsAndServer: createUser api res 22", res);
          resolve(res)
        });
      })
    }

    deleteUserOnIdmsAndServer(data) {
      console.log("deleteUserOnIdmsAndServer: Incoming ", data);
      return new Promise((resolve, reject) => {
        const promisesArray = [];
        for (const userObj of data) {
          promisesArray.push(this.deleteIdmsAndServer(userObj.mcptt_id, userObj.id));
        }
        Promise.all(promisesArray).then((results) => {
          console.log("deleteUserOnIdmsAndServer: Results", results)
          resolve(true);
        }).catch((e) => {
          console.log("deleteUserOnIdmsAndServer: Error in deleteIdmsAndServer", e)
          reject(false);
        });
      })
    }

    deleteIdmsAndServer(profileName, id) {
      console.log("deleteIdmsAndServer: received profilename and id", profileName, id);
      const _this = this;
      return new Promise(function (resolve, reject) {
        axios
          .delete(
            `${EndPoints.getConfig().CMSURI}${_this.root}${profileName}.xml`
          )
          .then(function (res) {
            if (res.status === 200) {
              const path = `${EndPoints.getConfig().idmsHost}/Delete`;
              return RequestHandler.deleteData(path, id).then((res) => {
                console.log("deleteIdmsAndServer: resp from IDMS", res)
                if (res) {
                  resolve(true);
                }
              });
            } else resolve(false);
          })
          .catch(function (err) {
            console.log("deleteIdmsAndServer: Error in CMS DELETE: ", err);
            resolve(false);
          });
      });
    }

    deletePartnerOnIdms(profileName, id) {
      console.log("deleteIdmsAndServer: received profilename and id", profileName, id);
      const _this = this;
      return new Promise(function (resolve, reject) {
        const path = `${EndPoints.getConfig().idmsHost}/Delete`;
        return RequestHandler.deleteData(path, id).then((res) => {
          console.log("deleteIdmsAndServer: resp from IDMS", res)
          if (res) {
            resolve(true);
          }
        });
      })
    }

    // deletePartnerIdmsAndServer(profileName, id) {
    //   console.log("deleteIdmsAndServer: received profilename and id", profileName, id);
    //   const _this = this;
    //   return new Promise(function (resolve, reject) {
    //     const path = `${EndPoints.getConfig().idmsHost}/Delete`;
    //     return RequestHandler.deleteData(path, id).then((res) => {
    //       console.log("deleteIdmsAndServer: resp from IDMS", res)
    //       if (res) {
    //         resolve(true);
    //       }
    //     });
    //   });
    // }

    createUser(user) {
      const path = `${EndPoints.getConfig().idmsHost}/Create`;
      console.log("createUser api", user, path);
      const params = new URLSearchParams();
      params.append("UserName", user.userName);
      params.append("password", user.password);
      params.append("mcptt_id", global.config.userConfig.multiTenantFeature ? (user.domain && user.domain !== "" ? `${user.mcpttId}@${user.domain}` : user.mcpttId) : user.mcpttId);
      params.append("Role", user.Role);
      params.append("TetraUser", user.TetraUser);
      params.append("Email", user.Email);
      params.append("Phone", user.phoneNumber);
      params.append("orgId", user.orgId);
      params.append("callForwardingData", user.callForwardingData ? user.callForwardingData : "");
      params.append("mcdata_id", user.mcxDataIdText ? user.mcxDataIdText : user.mcpttId);
      params.append("mcvideo_id", user.mcxVideoIdText ? user.mcxVideoIdText : user.mcpttId);
      params.append("isMcDataIdConfig", user.mcxDataID ? user.mcxDataID : false);
      params.append("isMcVideoIdConfig", user.mcxVideoID ? user.mcxVideoID : false);
      params.append("tenantId", user.tenantId)

      return RequestHandler.post(path, params).then((res) => {
        console.log("createUser api res", res, params);
        return res;
      });
    }

    updateUser(user) {
      const path = `${EndPoints.getConfig().idmsHost}/Update`;
      const data = {
        UserName: user.userName ? user.userName : "",
        password: user.password ? user.password : "",
        mcptt_id: user.mcpttId ? user.mcpttId : "",
        Role: user.Role ? user.Role : "",
        TetraUser: user.TetraUser ? user.TetraUser : "",
        Email: user.Email ? user.Email : "",
        id: user.id,
        orgId: user.orgId ? user.orgId : '0',
      };

      const params = new URLSearchParams();
      params.append("UserName", data.UserName);
      params.append("password", data.password);
      params.append("mcptt_id", data.mcptt_id);
      params.append("Role", data.Role);
      params.append("TetraUser", data.TetraUser);
      params.append("Email", data.Email);
      params.append("id", data.id);
      params.append("orgId", data.orgId);

      params.append("mcdata_id", user.mcxDataIdText ? user.mcxDataIdText : user.mcpttId);
      params.append("mcvideo_id", user.mcxVideoIdText ? user.mcxVideoIdText : user.mcpttId);
      params.append("isMcDataIdConfig", user.mcxDataID ? user.mcxDataID : false);
      params.append("isMcVideoIdConfig", user.mcxVideoID ? user.mcxVideoID : false);
      params.append("callForwardingData", user.callForwardingData ? user.callForwardingData : "");
      params.append("tenantId", user.tenantId)
      return RequestHandler.put(path, params).then((res) => {
        return res;
      });
    }

    deleteUser(id) {
      const path = `${EndPoints.getConfig().idmsHost}/Delete`;
      return RequestHandler.deleteData(path, id).then((res) => {
        return res;
      });
    }
    createOrg(org) {
      const path = `${EndPoints.getConfig().CMSURI}${this.orgRoot}/add`;
      //console.log("createOrg api " + path, org);
      const data = new Organisation(org);
      const xml = js2xmlparser.parse("organisation", data.organisationProfile);
      return axios
        .post(`${path}`, xml.toString(), { headers: this.headers })
        .then(
          (resp) => {
            if (resp.status === 200) return true;
            else return false;
          },
          (err) => {
            console.log("error on org" + err);
            return false;
          }
        )
        .catch((e) => {
          return false;
        });
    }

    deleteOrg(org) {
      const path = `${EndPoints.getConfig().CMSURI}${this.orgRoot}/delete/${org.orgId
        }`;
      return axios
        .delete(`${path}`)
        .then(
          (resp) => {
            if (resp.status === 200) return true;
            else return false;
          },
          (err) => {
            console.log("error on delete org" + err);
            return false;
          }
        )
        .catch((err) => {
          console.log("error on delete org" + err);
          return false;
        });
    }
    updateOrg(org) {
      const path = `${EndPoints.getConfig().CMSURI}${this.orgRoot}/update`;
      //console.log("updateOrg api", org);
      const data = new Organisation(org);
      const xml = js2xmlparser.parse("organisation", data.organisationProfile);
      //console.log("updateOrg api " + xml.toString());
      return axios
        .put(`${path}`, xml.toString(), { headers: this.headers })
        .then(
          (resp) => {
            if (resp.status === 200) return true;
            else return false;
          },
          (err) => {
            console.log("error on update org" + err);
            return false;
          }
        )
        .catch((err) => {
          console.log("error on update org" + err);
          return false;
        });
    }

    getGroup(token) {
      const path = `${EndPoints.getConfig().GMSURI}${this.groupRoot}/getAllGroups?tenantId=${global.config.userConfig.tenantId}`;
      return RequestHandler.get(path, { headers: { authorization: `Bearer ${token}` } }).then(
        (list) => {
          if (list && list.length) {
            return list;
          } else {
            console.log("empty " + list);
            return [];
          }
        },
        (err) => {
          console.log("In the get group err" + err);
        }
      );
    }
    getOrg(token) {
      const path = `${EndPoints.getConfig().CMSURI}${this.orgRoot}/all?tenantId=${global.config.userConfig.tenantId}`;
      //console.log("In the get org " + path);
      return RequestHandler.get(path, { headers: { authorization: `Bearer ${token}` } }).then(
        (list) => {
          if (list && list.length) {
            console.log("ORG LIST ", list);
            return list;
          } else {
            //console.log("empty " + list);
            return [];
          }
        },
        (err) => {
          console.log("In the get org err" + err);
        }
      );
    }
    getOrgById(orgId) {
      const path = `${EndPoints.getConfig().CMSURI}${this.orgRoot}/${orgId}`;
      return RequestHandler.get(path).then((item) => {
        if (item) {
          console.log("ORG By Id ", item);
          return item;
        } else {
          return [];
        }
      });
    }

    createGroup(group) {
      //console.log("group----------------------------------");
      //console.log(group);
      //const path = `${EndPoints.getConfig().GMSURI}${this.groupRoot}/createGroup`;
      const basicPath = `https://${EndPoints.getConfig().host}:${EndPoints.getConfig().gmcPort
        }/gms/consort/createGroup`;
      const path = `${basicPath}/admin-consort`;
      let doc = GroupXMLProcessor.createXML(group);
      console.log("Created Document is : ", doc);

      return axios
        .put(`${path}`, doc.toString(), { headers: this.headers })
        .then(
          (resp) => {
            if (resp.status === 200) return true;
            else return false;
          },
          (err) => {
            console.log("error on group" + err);
            return false;
          }
        )
        .catch((e) => {
          return false;
        });
      return true;
    }

    deleteGroup(group) {
      const basicPath = `https://${EndPoints.getConfig().host}:${EndPoints.getConfig().gmcPort
        }/gms/consort/deleteGroup`;
      const path = `${basicPath}/${group}/admin-consort`;
      //const path = `${EndPoints.getConfig().GMSURI}${this.groupRoot}/deleteGroup/${group}`;
      return axios
        .delete(`${path}`)
        .then(
          (resp) => {
            if (resp.status === 200) return true;
            else return false;
          },
          (err) => {
            console.log("error on delete group" + err);
            return false;
          }
        )
        .catch((err) => {
          console.log("error on delete group" + err);
          return false;
        });
    }
    updateGroup(group) {
      const basicPath = `https://${EndPoints.getConfig().host}:${EndPoints.getConfig().gmcPort
        }/gms/consort/updateGroup`;
      const path = `${basicPath}/admin-consort`;
      //const path = `${EndPoints.getConfig().GMSURI}${this.groupRoot}/updateGroup`;
      console.log("updateGroup api", group);
      let doc = GroupXMLProcessor.updateXML(group);
      console.log("updateGroup api doc " + doc.toString());
      return axios
        .put(`${path}`, doc.toString(), { headers: this.headers })
        .then(
          (resp) => {
            if (resp.status === 200) return true;
            else return false;
          },
          (err) => {
            console.log("error on update group" + err);
            return false;
          }
        )
        .catch((err) => {
          console.log("error on update group" + err);
          return false;
        });
    }
    affiliateGroupToUser(group) {
      return true;
    }
    userDefaultGroup(data) {
      return true;
    }

    ////////////IWFMAP////////////////////////
    createIWFMap(iwfMap) {
      const path = `${EndPoints.getConfig().IWFURI}${this.iwfRoot}/add`;
      console.log("createiwfMap api " + path, iwfMap);
      // const data = new iwfMapanisation(iwfMap);
      // const xml = js2xmlparser.parse('iwfMapanisation',data.iwfMapanisationProfile);
      return axios
        .post(`${path}`, iwfMap)
        .then(
          (resp) => {
            console.log("IWF mapping check", resp)
            if (resp.status === 200 && !resp.data.isError) return true;
            else return false;
          },
          (err) => {
            console.log("error on iwfMap" + err);
            return false;
          }
        )
        .catch((e) => {
          return false;
        });
    }

    deleteIWFMap(iwfMap) {
      const path = `${EndPoints.getConfig().IWFURI}${this.iwfRoot}/delete`;
      return axios
        .delete(`${path}`, iwfMap)
        .then(
          (resp) => {
            if (resp.status === 200) return true;
            else return false;
          },
          (err) => {
            console.log("error on delete iwfMap" + err);
            return false;
          }
        )
        .catch((err) => {
          console.log("error on delete iwfMap" + err);
          return false;
        });
    }
    updateIWFMap(iwfMap) {
      const path = `${EndPoints.getConfig().IWFURI}${this.iwfRoot}/update`;
      console.log("updateIWF api", iwfMap);
      // const data = new Organisation(org);
      // const xml = js2xmlparser.parse('Organisation',data.organisationProfile);
      // console.log('updateOrg api '+xml.toString())
      return axios
        .put(`${path}`, iwfMap, { timeout: this.UA.getHttpClientTimeout() })
        .then(
          (resp) => {
            if (resp.status === 200) return true;
            else return false;
          },
          (err) => {
            console.log("error on update iwfMap " + err);
            return false;
          }
        )
        .catch((err) => {
          console.log("error on update iwfMap " + err);
          return false;
        });
    }

    getIWFMap() {
      const path = `${EndPoints.getConfig().IWFURI}${this.iwfRoot}/getAll`;
      console.log("In the get org " + path);
      return RequestHandler.get(path).then(
        (list) => {
          if (list && list.length) {
            console.log("IWF LIST ", list);
            return list;
          } else {
            console.log("empty " + list);
            return [];
          }
        },
        (err) => {
          console.log("In the get iwf err" + err);
        }
      );
    }

    getIWFMapByFa(data) {
      const path = `${EndPoints.getConfig().IWFURI}${this.iwfRoot
        }/getMappingByFa/${data}`;
      console.log('getIWFMapByFa path..', path);
      return RequestHandler.get(path).then(
        (res) => {
          if (!res.isError) {
            console.log("IWF getIWFMapByFa ", res);
            return res.response;
          } else {
            console.log("empty " + res);
            return false;
          }
        },
        (err) => {
          console.log("In the get iwf err" + err);
          return false;
        }
      );
    }

    getIWFMapByMCPTTID(data) {
      const path = `${EndPoints.getConfig().IWFURI}${this.iwfRoot
        }/getMappingByMCPTTID/${data}`;
      console.log('getIWFMapByMCPTTID path..', path)
      return RequestHandler.get(path).then(
        (res) => {
          if (!res.isError) {
            console.log("IWF getIWFMapByMCPTTID", res);
            return res.response;
          } else {
            console.log("empty " + res);
            return [];
          }
        },
        (err) => {
          console.log("In the get iwf err" + err);
        }
      );
    }

    getOrgById(orgId) {
      const path = `${EndPoints.getConfig().CMSURI}${this.orgRoot}/${orgId}`;
      return RequestHandler.get(path).then((item) => {
        if (item) {
          console.log("ORG By Id ", item);
          return item;
        } else {
          return [];
        }
      });
    }

    getIWFType() {
      const path = `${EndPoints.getConfig().IWFURI}${this.iwfRoot
        }/getMappingTypes`;
      return RequestHandler.get(path).then((item) => {
        if (item && !item.isError && item.response) {
          console.log("iwf type list ", item);
          return item.response;
        } else {
          return [];
        }
      });
    }

    getGroupType() {
      const path = `${EndPoints.getConfig().GMSURI}${this.groupRoot
        }/groupTypes`;
      console.log("API HIT", path);
      return RequestHandler.get(path).then((item) => {
        if (item && !item.isError && item.response) {
          console.log("group type list ", item);
          return item.response;
        } else {
          return [];
        }
      });
    }

    getGroupDetail(groupId) {
      const path = `${EndPoints.getConfig().GMSURI}${this.groupRoot
        }/getGroup/${groupId}`;
      console.log("API HIT", path);
      return RequestHandler.get(path).then((item) => {
        if (item && !item.isError) {
          console.log("group type list ", item);
          return item;
        } else {
          return [];
        }
      });
    }

    getMembersForOrgGroup(orgId) {
      const path = `${EndPoints.getConfig().GMSURI}${this.groupRoot
        }/getMembersForOrgGroup/${orgId}`;
      return RequestHandler.get(path).then((list) => {
        if (list && list.length) {
          console.log("getMembersForOrgGroup LIST ", list);
          return list;
        } else {
          console.log("empty " + list);
          return [];
        }
      });
    }

    getAllAlerts() {
      return axios
        .post(EndPoints.getConfig().getAlertAllList)
        .then((res) => {
          console.log("getAllAlerts", res.data);
          return res.data;
        })
        .catch((e) => {
          console.log("🥴 FAILED");
          return null;
        });
    }

    getAlertTypes() {
      return axios
        .get(EndPoints.getConfig().getAlertTypes, { timeout: this.UA.getHttpClientTimeout() })
        .then((res) => {
          console.log("in getAlertTypes res", res.data.response);
          return res.data.response;
        })
        .catch((e) => {
          console.log("🥴 FAILED");
          return null;
        });
    }

    editAlert(data) {
      return axios
        .put(EndPoints.getConfig().updateAlert, { data: data.data }, { timeout: this.UA.getHttpClientTimeout() })
        .then((res) => {
          return res;
        })
        .catch((e) => {
          console.log("🥴 FAILED");
          return null;
        });
    }

    deleteAlert(code) {
      return axios
        .delete(EndPoints.getConfig().deleteAlertByCode, { data: code.data })
        .then((res) => {
          console.log("deleteAlert", res, !res.data.error);
          return res;
        })
        .catch((e) => {
          console.log("🥴 FAILED");
          return null;
        });
    }

    addAlert(data) {
      return axios
        .post(EndPoints.getConfig().addAlert, { data: data.data }, { timeout: this.UA.getHttpClientTimeout() })
        .then((res) => {
          return res;
        })
        .catch((e) => {
          console.log("🥴 FAILED");
          return null;
        });
    }

    getActiveGroupMembers(groupID) {
      const _this = this;
      console.log('getActiveGroupMembers url..', EndPoints.getConfig().activeGroupMembersAPI)
      return new Promise(function (resolve, reject) {
        axios
          .get(EndPoints.getConfig().activeGroupMembersAPI, { timeout: this.UA.getHttpClientTimeout() }, { groupID: groupID })
          .then(function (resp) {
            console.log("getActiveGroupMembers response : ", resp);
            if (resp.status === 200) {
              resolve(resp);
            } else resolve(null);
          })
          .catch(function (err) {
            console.log("getActiveGroupMembers err", err);
            resolve(null);
          });
      });
    }

    getActiveGroupsForUser(userMcpttId) {
      console.log('getActiveGroupsForUser url..', EndPoints.getConfig().activeGroupsForUserAPI);
      return new Promise(function (resolve, reject) {
        axios
          .get(EndPoints.getConfig().activeGroupsForUserAPI, { timeout: this.UA.getHttpClientTimeout() }, { userMcpttId: userMcpttId })
          .then(function (resp) {
            console.log("getActiveGroupsForUser response : ", resp);
            if (resp.status === 200) {
              resolve(resp);
            } else resolve(null);
          })
          .catch(function (err) {
            console.log("getActiveGroupsForUser err", err);
            resolve(null);
          });
      });
    }

    addProductCatalog(data) {
      const path = `${EndPoints.getConfig().createProduct}`;
      console.log("addProductCatalog api ", data);
      let token = this.ua.getActiveToken();
      return axios
        .post(`${path}`, data, {
          headers: { authorization: `Bearer ${token}`, "Content-Type": "application/json" }
        })
        .then(
          (resp) => {
            console.log("addProductCatalog resp", resp)
            return resp;
          },
          (err) => {
            console.log("addProductCatalog error", err);
            return false;
          }
        )
        .catch((e) => {
          console.log("addProductCatalog error catch", e);
          return false;
        });
    }

    editProductCatalog(data) {
      const path = `${EndPoints.getConfig().updateProduct}` + "/" + `${data.id}` + "?creatorPartnerId=" + `${data.creatorPartnerId}`;
      console.log("editProductCatalog api ", data);
      let token = this.ua.getActiveToken();
      return axios
        .put(`${path}`, data, {
          headers: { authorization: `Bearer ${token}`, "Content-Type": "application/json" }
        })
        .then(
          (resp) => {
            console.log("editProductCatalog resp", resp)
            return resp;
          },
          (err) => {
            console.log("editProductCatalog error", err);
            return false;
          }
        )
        .catch((e) => {
          console.log("editProductCatalog error catch", e);
          return false;
        });
    }

    getProductCatalog(data) {
      let path = `${EndPoints.getConfig().listProducts}?creatorPartnerId=${data.creatorPartnerId}&page=${data.paginationModel.page + 1}&limit=${data.paginationModel.pageSize}`;
      if (data && data.apiType === "buy") {
        path = `${EndPoints.getConfig().listBuyProducts}?creatorPartnerId=${data.creatorPartnerId}&page=${data.paginationModel.page + 1}&limit=${data.paginationModel.pageSize}`;
        if (data.filter) {
          for(let key in data.filter) {
            path = path + `&${key}=${data.filter[key]}`
          }
        }
      }
      console.log("getProductCatalog api ", path, data);
      let token = this.ua.getActiveToken();
      return RequestHandler.get(path, { headers: { authorization: `Bearer ${token}` } }).then(
        (list) => {
          if (list) {
            console.log("getProductCatalog resp ", list);
            return list;
          } else {
            console.log("getProductCatalog else ", list);
            return false;
          }
        },
        (err) => {
          console.log("getProductCatalog err", err);
        }
      );
    }

    createOrderForProduct(data) {
      const path = `${EndPoints.getConfig().createOrder}`;
      console.log("createOrderForProduct api ", data);
      let token = this.ua.getActiveToken();
      return axios
        .post(`${path}`, data, {
          headers: { authorization: `Bearer ${token}`, "Content-Type": "application/json" }
        })
        .then(
          (resp) => {
            console.log("createOrderForProduct resp", resp)
            return resp;
          },
          (err) => {
            console.log("createOrderForProduct error", err);
            return false;
          }
        )
        .catch((e) => {
          console.log("createOrderForProduct error catch", e);
          return false;
        });
    }

    updateOrderForProduct(data) {
      const path = `${EndPoints.getConfig().updateOrder}/${data.orderId}`;
      console.log("updateOrderForProduct api ", data);
      let token = this.ua.getActiveToken();
      return axios
        .put(`${path}`, data, {
          headers: { authorization: `Bearer ${token}`, "Content-Type": "application/json" }
        })
        .then(
          (resp) => {
            let res = {
              ...resp,
              success: true
            }
            console.log("updateOrderForProduct resp", res);
            return res;
          },
          (err) => {
            let errorTuples = [];
            let errorMsg = "Order is rejected!!"
            if (err && err.response && err.response.data) {
              if (err.response.data.response && err.response.data.response.data) {
                if (err.response.data.response.data.errorTuples) {
                  errorTuples = err.response.data.response.data.errorTuples;
                }
                if (err.response.data.response.message) {
                  errorMsg = err.response.data.response.message;
                }
              }
            }
            let res = {
              errorTuples: errorTuples,
              errorMsg: errorMsg,
              success: false
            }
            console.log("updateOrderForProduct error", res);
            return res;
          }
        )
        .catch((e) => {
          console.log("updateOrderForProduct error catch", e);
          return {
            success: false
          };
        });
    }

    getOrderList(data) {
      let path = `${EndPoints.getConfig().listOrders}`;
      if (data && data.apiType === "purchase") {
        path = `${path}?buyerMTMId=${data.buyerMTMId}&page=${data.paginationModel.page + 1}&limit=${data.paginationModel.pageSize}`;
      } else {
        path = `${path}?sellerMTMId=${data.sellerMTMId}&page=${data.paginationModel.page + 1}&limit=${data.paginationModel.pageSize}`;
      }
      console.log("getOrderList api1 ", path, data);
      let token = this.ua.getActiveToken();
      return RequestHandler.get(path, { headers: { authorization: `Bearer ${token}` } }).then(
        (list) => {
          if (list) {
            console.log("getOrderList resp ", list);
            return list;
          } else {
            console.log("getOrderList else ", list);
            return false;
          }
        },
        (err) => {
          console.log("getOrderList err" + err);
        }
      );
    }

    getCurrentActiveSubscriptionsForUser(data) {
      let path = `${EndPoints.getConfig().getCurrentActiveSubscriptionsForUser}?userId=${data.userId}`;
      console.log("getCurrentActiveSubscriptionsForUser api ", path, data);
      let token = this.ua.getActiveToken();
      return RequestHandler.get(path, { headers: { authorization: `Bearer ${token}` } }).then(
        (list) => {
          if (list) {
            console.log("getCurrentActiveSubscriptionsForUser resp ", list);
            return list;
          } else {
            console.log("getCurrentActiveSubscriptionsForUser else ", list);
            return false;
          }
        },
        (err) => {
          console.log("getCurrentActiveSubscriptionsForUser err", err);
        }
      );
    }
    getCurrentActiveSubscriptions(data) {
      let path = `${EndPoints.getConfig().getCurrentActiveSubscriptions}?tenantId=${data.tenantId}`;
      console.log("getCurrentActiveSubscriptions api ", path, data);
      let token = this.ua.getActiveToken();
      return RequestHandler.get(path, { headers: { authorization: `Bearer ${token}` } }).then(
        (list) => {
          if (list) {
            console.log("getCurrentActiveSubscriptions resp ", list);
            return list;
          } else {
            console.log("getCurrentActiveSubscriptions else ", list);
            return false;
          }
        },
        (err) => {
          console.log("getCurrentActiveSubscriptions err", err);
          return false;
        }
      );
    }

    fetchSubscriptions(data) {
      let path = `${EndPoints.getConfig().listPartnerSubscriptions}`;
      if (data && data.apiType === "tenant") {
        path = `${EndPoints.getConfig().listTenantSubscriptions}`;
      }
      console.log("fetchSubscriptions api ", path, data);
      let token = this.ua.getActiveToken();
      return axios
        .post(`${path}`, data, {
          headers: { authorization: `Bearer ${token}`, "Content-Type": "application/json" }
        })
        .then(
          (resp) => {
            console.log("fetchSubscriptions resp", resp)
            return resp;
          },
          (err) => {
            console.log("fetchSubscriptions error", err);
            return false;
          }
        )
        .catch((e) => {
          console.log("fetchSubscriptions error catch", e);
          return false;
        });
    }

    allocateDeallocateUserFromTenantSubscription(subscriptionData) {
      const _this = this;
      console.log("allocateDeallocateUserFromTenantSubscription api ", subscriptionData);
      let subscriptions = subscriptionData && subscriptionData.subs ? subscriptionData.subs : [];
      let userId = subscriptionData ? subscriptionData.userId : "";
      return Promise.all(subscriptions.map((sub) => {
        if (sub.state === "allocate") {
          return _this.allocateUserFromTenantSubscription(sub.id, userId);
        } else {
          return _this.deAllocateUserFromTenantSubscription(sub.id, userId);
        }
      }))
    }

    allocateUserFromTenantSubscription(subId, userId) {
      let path = `${EndPoints.getConfig().allocateUserFromTenantSubscription}`;
      console.log("allocateUserFromTenantSubscription api ", path, subId, userId);
      let token = this.ua.getActiveToken();
      let finalData = {
        "tenantSubscriptionId": subId,
        "userId": userId
      }
      return axios
        .post(`${path}`, finalData, {
          headers: { authorization: `Bearer ${token}`, "Content-Type": "application/json" }
        })
        .then(
          (resp) => {
            console.log("allocateUserFromTenantSubscription resp", resp)
            return {
              "tenantSubscriptionId": subId,
              "success": true
            };
          },
          (err) => {
            console.log("allocateUserFromTenantSubscription error", err);
            return {
              "tenantSubscriptionId": subId,
              "success": false
            };
          }
        )
        .catch((e) => {
          console.log("allocateUserFromTenantSubscription error catch", e);
          return {
            "tenantSubscriptionId": subId,
            "success": false
          };
        });
    }

    deAllocateUserFromTenantSubscription(subId, userId) {
      let path = `${EndPoints.getConfig().deAllocateUserFromTenantSubscription}`;
      console.log("deAllocateUserFromTenantSubscription api ", path, subId, userId);
      let token = this.ua.getActiveToken();
      let finalData = {
        "tenantSubscriptionId": subId,
        "userId": userId
      }
      return axios
        .post(`${path}`, finalData, {
          headers: { authorization: `Bearer ${token}`, "Content-Type": "application/json" }
        })
        .then(
          (resp) => {
            console.log("deAllocateUserFromTenantSubscription resp", resp)
            return {
              "tenantSubscriptionId": subId,
              "success": true
            };
          },
          (err) => {
            console.log("deAllocateUserFromTenantSubscription error", err);
            return {
              "tenantSubscriptionId": subId,
              "success": false
            };
          }
        )
        .catch((e) => {
          console.log("deAllocateUserFromTenantSubscription error catch", e);
          return {
            "tenantSubscriptionId": subId,
            "success": false
          };
        });
    }

    fetchMasterUEConfig() {
      const _this = this;
      const path = `${EndPoints.getConfig().masterUeTypeConfig}`;
      let token = this.ua.getActiveToken();
      RequestHandler.get(path, { headers: { authorization: `Bearer ${token}` } }).then(
        (list) => {
          if (list && list.result && list.result.serviceUeTypeConfig) {
            _this.msterUEConfig = list.result.serviceUeTypeConfig;
          }
        },
        (err) => {
          console.log("fetchMasterUEConfig err", err);
        }
      );
    }

  }


  exports.Admin = Admin;
})(typeof exports === "undefined" ? (this["Admin"] = {}) : exports);
