import React, { useEffect } from "react";
import { Modal } from 'react-bootstrap'
import { connect } from "react-redux";
import mtmstate from "../../modules/mtmstate";
import { createPartnerListMtm, createTenantListMtm } from "../../modules/mtmstate";
import AddParent from "./addPartner";
import AddTenant from "./addTenant";

const AddModal = React.memo((props) => {
  const { partnerList, tenantList, mtmOpen, mtmHandleClose, hideModal, subscribeType, createPartnerListMtm, createTenantListMtm } = props;

  const createPartnerData = (data) => {
    createPartnerListMtm(data)
  }
  const createTenantData = (data) => {
    createTenantListMtm(data)
  }

  useEffect(() => () => {
    props.purpose !== 'edit' && hideModal()
  }, [])

  return (
    <div style={{ width: "100%" }}>
      {/* {subscribeType === "partner" ? (
        <div>ADD Partner</div>
      ) : (
        <div>ADD Tenant</div>
      )} */}
      {subscribeType === "partner" ? <AddParent createPartnerData={createPartnerData} partnerData={props.mtmData} hideModal={hideModal}></AddParent> : <AddTenant partnerData={props.partnerData} tenantData={props.mtmData} createTenantData={createTenantData} hideModal={hideModal}></AddTenant>}
    </div>
  );

})

const mapStateToProps = ({ adminstate }) => {
  const { partnerList, tenantList } = mtmstate;
  return {
    partnerList, tenantList
  };
};

export default connect(mapStateToProps, {
  createPartnerListMtm,
  createTenantListMtm
})(AddModal);