(function (exports) {
  const EndPoints = require("../endpoints").EndPoints;
  const axios = require("axios").default;
  const Emmiter = require("events").EventEmitter;
  const jwt_decode = require("jwt-decode").default;
  class IDMC extends Emmiter {
    constructor() {
      super();
      this.name = "";
      this.password = "";
    }

    login(name, password) {
      this.name = name;
      this.password = password;
      return this.loginAuth();
    }

    loginAuth() {
      //console.log('config host idmc', this.idmsHost, EndPoints.getConfig().cmcPort)
      let axiosConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        auth: {
          username: "js",
          password: "",
        },
         timeout: 30000,
      };

      const _this = this;
      return new Promise(function (resolve, reject) {
        try {
          const psw = _this.password;
          const userId = _this.name;
          const params = new URLSearchParams();
          params.append("grant_type", "password");
          params.append("ClientId", "js");
          params.append("username", userId);
          params.append("password", psw);
          params.append(
            "scope",
            "3gpp:IWF:Tetra 3gpp:mcptt:ptt_server 3gpp:mcptt:key_management_server"
          );
          console.log("Login Credential: ", EndPoints.getConfig().idmsURI, psw, userId);
          axios
            .post(
              `https://${EndPoints.getConfig().idmsURI}/connect/token`,
              params,
              axiosConfig
            )
            .then(function (response) {
              // console.log("LOGIN AUTH", response);
              if (response.status === 200) {
                if (response.data && response.data.access_token) {
                  const decoded = jwt_decode(response.data.access_token);
                  const userData = {
                    access_token: response.data.access_token,
                    expires_at: decoded.exp,
                    profile: decoded,
                  };
                  resolve(userData);
                } else {
                  resolve(null);
                }
              } else resolve(null);
            })
            .catch(function (error) {
              console.log("Login Idms ERR: ", error, error.response, error.code);
              if (error.code == 400 || (error.response && error.response.status == 400)) {
                resolve(null);
              } else {
                reject(true);
              }
            });
        } catch (e) {
          reject(false);
        }
      });
    }
  }
  exports.IDMC = IDMC;
})(typeof exports === "undefined" ? (this["IDMC"] = {}) : exports);
