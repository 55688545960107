import React, { useState, useEffect, Fragment, useRef } from "react";
import { createTheme } from "@material-ui/core/styles";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { connect } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { fetchOrgListAdmin } from "../../../../modules/adminstate";
import { getDeviceIdList, updateDeviceByDevice, getDeviceId, setDeviceId, updateDeviceByUser } from "../../../../modules/actions/mdmAction";
import { getAvailableServices } from "../../../../modules/mtmstate";
import UserPermissions from "./UserPermissions";
import MCXServices from "../../../../planManagement/mcxservices";
import { Modal } from 'react-bootstrap'
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
  fetchUserProfileWithAttachedFAS,
  updateUserProfileWithAttachedFAS,
  getIWFMapByMCPTTID,
  fetchMcpttIdAndUri,
  getIWFMapByFa,
} from "../../../../modules/adminstate";
import { DefaultBasicInfoData } from "../../../basicinfodata";
import IwFMapView from "../AddIwfMap/IwFMapView";
import CADCallConfig from "./CADCallConfig";
import CallForwardingConfig from "./CallForwardingConfig";
import UserLocation from "./UserLocation";
import AllocateSubsciptionUser from "./AllocateSubsciptionUser";
import {
  allocateUserFromTenantSubscription,
  getCurrentActiveSubscriptionsForUser
} from "../../../../modules/productcatalogstate";
import AllocateSubscriptionToUser from "../../AllocateSubscriptionToUser";
import { Box, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';


const CustomFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    color: 'white !important',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'white !important',
  },
  '& .MuiInputBase-root': {
    color: 'white',

  },
  '& .MuiFilledInput-underline:before': {
    borderBottomColor: 'white',
  },
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: 'white',
  },
  '&:hover .MuiFilledInput-underline:before': {
    borderBottomColor: 'white',
  },
}));

const BasicInfo = (props) => {
  const {
    falist,
    orglist,
    userProfileWithAttachedFA,
    fetchUserProfileWithAttachedFAS,
    updateUserProfileWithAttachedFAS,
    fetchOrgListAdmin,
    purpose,
    configDomain,
    configCadCall,
    configCallForwarding,
    getIWFMapByMCPTTID,
    getIWFMapByFa,
    mcpttidIWFMap,
    iwfMaplist,
    mcpttidAndUri,
    fetchMcpttIdAndUri,
    getDeviceIdList,
    deviceIdOption,
    updateDeviceByDevice,
    updateDeviceByUser,
    deviceId,
    getDeviceId,
    setDeviceId,
    checkUserIsAlreadyExist,
    serviceUeTypeConfig,
    getAvailableServices,
    availableServices,
    ueTypeList,
    allocateUserFromTenantSubscription,
    getCurrentActiveSubscriptionsForUser,
    currentActiveSubscriptionsForUser,
    allocateDeallocateSubscriptionsuccessIds,
    userProfileCreatedId,
    closeModal,
  } = props;

  let basicInfoObj = useRef(null);
  const [addedFAs, setaddedFAs] = useState([]);
  const [vlFunctionalAlias, setvlFunctionalAlias] = useState("");
  const [selectedUserType, setSelectedUserType] = useState("Dispatcher");
  const [selectedPriority, setSelectedPriority] = useState("1");
  const [emptyCallForwardTarget, setEmptyCallForwardTarget] = useState(false);
  const [selectedRoleType, setSelectedRoleType] = useState("Dispatcher");
  const [emptyMCXId, setEmptyMCXId] = useState(false);
  const [emptyPassword, setEmptyPassword] = useState(false);
  const [passwordMitchMatch, setPasswordMitchMatch] = useState(false);
  const [basicdetails, setbasicdetails] = useState(DefaultBasicInfoData);
  const [newOrUpdateProfile, setNewOrUpdateProfile] = useState(true);
  const [tetraDisable, settetraDisable] = useState(false);
  const [fullyIWF, setFullyIWF] = useState(false);
  //------------------------------------------------------
  const [selectedUEType, setSelectedUEType] = useState("");
  const [cadConfigUEType, setCadConfigUEType] = useState("");
  const [addedUEInfo, setaddedInfo] = useState([]);

  const [isMCXDataIdDisabled, setIsMCXDataIdDisabled] = useState(true);
  const [isMCXVideoIdDisabled, setIsMCXVideoIdDisabled] = useState(true);
  const [emptyDefaultAuthorizer, setEmptyDefaultAuthorizer] = useState(false);
  const [mcpttidIWFMapState, setMcpttidIWFMap] = useState(mcpttidIWFMap);
  const [isDmChecked, setIsDmChecked] = useState(false)
  const [deviceIdDropdown, setDeviceIdDropdown] = useState(false)
  //------------------------------------------------------
  const [isUserNameExist, setIsUserNameExist] = useState(false);
  const [showSubmitBtn, setShowSubmitBtn] = useState(true);
  const [allSelectedServices, setAllSelectedServices] = useState([]);
  const [enableCallForwarding, setEnableCallForwarding] = useState(false);
  const [enableCadCall, setEnableCadCall] = useState(false);
  const [enableFA, setEnableFA] = useState(false);
  const [enablePermission, setEnablePermission] = useState(false);
  const [UETypeOptions, setUETypeOptions] = useState([]);
  const [locService, setLocService] = useState(false);
  const [deviceID, setDeviceID] = useState([])
  const [selectedDeviceId, setSelectedDeviceId] = useState('')
  const [selectedDeviceID, setSelectedDeviceID] = useState('');
  const [profileState, setProfileState] = useState("");
  const [subscriptionForAllocation, setSubscriptionForAllocation] = useState([]);
  const [openModal, setopenModal] = useState(false);
  const [subscriptionModified, setSubscriptionModified] = useState(false);

  const RoleTypeOptions = [
    { text: "ADMIN", value: "Admin" },
    { text: "DISPATCHER", value: "Dispatcher" },
  ];

  const UserTypeOptions = [
    { text: "DISPATCHER", value: "Dispatcher" },
    { text: "STATION MASTER", value: "stationMaster" },
    { text: "CONTROLLLER", value: "controller" },
    { text: "DRIVER", value: "Driver" },
    { text: "GUARD", value: "Guard" },
    { text: "CABINUSER-POST PoC", value: "cabinUser" },
    { text: "CROSSINGUSER-POST PoC", value: "crossingUser" },
  ];
  const UserTypeIwfOptions = [
    { text: "DISPATCHER", value: "Dispatcher" },
  ];

  const columns = [
    {
      field: 'text', headerName: 'UE Type', width: 350, sortable: false,
      headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
    },
    {
      field: 'noOfReg', headerName: 'No. Of Registration', width: 280, sortable: false,
      headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
      renderCell: (cellValue) => {
        return (
          <input
            type="text"
            className="input-control-flat"
            id="noOfReg"
            // value={ue.noOfReg}
            defaultValue={cellValue.row.noOfReg}
            onChange={(e) => noOfRegChangeHandler(e, cellValue.row)}
            readOnly={global.config.isEcommerce}
          />
        )
      }
    },
    {
      field: 'primary', headerName: 'Primary', width: 160, sortable: false,
      headerClassName: "list-head-Val", cellClassName: "list-row-Val",
      align: "center", headerAlign: "center",
      renderCell: (cellValue) => {
        return (
          <Checkbox
            edge="start"
            style={{ color: "white" }}
            onChange={(e) => changeUEPrimary(e, cellValue.row)}
            checked={cellValue.row.primary}
          />
        )
      }
    },
    {
      field: 'DeviceId',
      headerName: 'DeviceId',
      width: 400,
      sortable: false,
      headerClassName: "list-head-Val",
      cellClassName: "list-row-Val",
      align: "center",
      headerAlign: "center",
      valueGetter: (value, row) => `${filterDeviceId(row.text)}`,
    },
    {
      field: 'edit',
      headerName: '',
      width: 340,
      height: 50,
      sortable: false,
      headerClassName: "list-head-Val",
      align: "center",
      headerAlign: "center",
      renderCell: (cellValue) => {
        return (
          <button
            className="editBtn"
            onClick={() => {
              deleteUEInfo(cellValue.row)
            }}
            type="button"
            name=""
            disabled={global.config.isEcommerce}
          >
            <img
              src="/assets/images/deleteimg.svg"
              class="delete-user-img-fa"
              alt=""
            />
          </button>
        )
      }
    },
  ];

  const faColumns = [
    {
      field: 'CallerDescr', headerName: 'FA', width: 370, sortable: false,
      headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
    },
    {
      field: 'noOfReg', headerName: 'UE Type', width: 350, sortable: false,
      headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
      renderCell: (cellValue) => {
        return (
          <FormControl variant="filled" className={classes.formControlFAUE}>
            <InputLabel
              id="demo-simple-select-filled-label"
              className={classes.listItemFA}
              focused={false}
            >
              UE
            </InputLabel>
            <Select
              className={classes.listItemFA}
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={cellValue.row.ueType ? cellValue.row.ueType : ""}
              onChange={(e) =>
                //  console.log("cellValue.row", cellValue.row, e.target.value) //fullyIWF
                handleFAUETypeChangeHandler(e, cellValue.row)
              }
              disableUnderline={true}
            >
              {UETypeOptions &&
                UETypeOptions.map((uetype) => {
                  return (
                    <MenuItem value={uetype.value}>
                      {uetype.text}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        )
      }
    },
    {
      field: 'primary', headerName: 'Primary', width: 200, sortable: false,
      headerClassName: "list-head-Val", cellClassName: "list-row-Val",
      align: "center", headerAlign: "center",
      renderCell: (cellValue) => {
        return (
          <Checkbox
            edge="start"
            style={{ color: "white" }}
            onChange={() => makeDefaultFA(cellValue.row)}
            checked={cellValue.row.default}
          />
        )
      }
    },
    {
      field: 'edit',
      headerName: '',
      width: 200,
      height: 50,
      sortable: false,
      headerClassName: "list-head-Val",
      align: "center",
      headerAlign: "center",
      renderCell: (cellValue) => {
        return (
          <button
            className="editBtn"
            onClick={() => {
              deleteFAlias(cellValue.row)
            }}
            type="button"
            name=""
          >
            <img
              src="/assets/images/deleteimg.svg"
              class="delete-user-img-fa"
              alt=""
            />
          </button>
        )
      }
    },
  ];

  const isEmptyObject = (obj) => {
    return (
      obj &&
      Object.keys(obj).length === 0 &&
      Object.getPrototypeOf(obj) === Object.prototype
    );
  };

  useEffect(() => {
    fetchMcpttIdAndUri();

    if (props.basicInfoData) {
      console.log("basicInfoData", props.basicInfoData);
      if (purpose === 'edit') {
        getDeviceId({ tenantId: global.config.userConfig.tenantId, userId: props.basicInfoData.userName })

        if (global.config.isEcommerce) {
          let dataa = {
            "userId": props.basicInfoData.userName
          }
          getCurrentActiveSubscriptionsForUser(dataa);
        }
      }

      basicInfoObj.current = props.basicInfoData;
      setbasicdetails(props.basicInfoData);
      if (props.basicInfoData.id && props.basicInfoData.id.length !== 0) {
        setNewOrUpdateProfile(false);
      } else {
        setNewOrUpdateProfile(true);
      }
      if (
        props.basicInfoData.mcpttId &&
        props.basicInfoData.mcpttId.length !== 0
      ) {
        setTimeout(() => {
          fetchUserProfileWithAttachedFAS(props.basicInfoData.mcpttId);
        }, 500);
        getIWFMapByMCPTTID(props.basicInfoData.mcpttId);
        //getIWFMapByFa(props.basicInfoData.mcpttId);
        // setMcpttidIWFMap(mcpttidIWFMap);
      }
    } else {
      setSelectedRoleType("Dispatcher");
      setNewOrUpdateProfile(true);
      let ttUser = false;
      if (
        configDomain &&
        configDomain.iwfs &&
        configDomain.iwfs.length > 0 &&
        configDomain.mcxDomain &&
        configDomain.mcxDomain !== ""
      ) {
        // Enable tetra in this case
        console.log("tetra domain check running ", " COND 1");
      } else {
        console.log("tetra domain check running ", " else");
        settetraDisable(true);
        if (configDomain && configDomain.iwfs && configDomain.iwfs.length > 0) {
          ttUser = true;
          console.log("tetra domain check running ", " COND 2");
        } else if (configDomain.mcxDomain && configDomain.mcxDomain !== "") {
          console.log("tetra domain check running ", " COND 3");
          ttUser = false;
        }
      }
      let defDetail = {
        ...DefaultBasicInfoData,
        TetraUser: ttUser,
      };
      basicInfoObj.current = defDetail;
      setbasicdetails(defDetail);
    }
  }, []);

  useEffect(() => {
    console.log("iwfmap basicInfo...", mcpttidIWFMap);
    if (purpose === "edit" && mcpttidIWFMap && basicdetails) {
      if (!isEmptyObject(mcpttidIWFMap)) {
        console.log("iwfmap basicInfo inside...", mcpttidIWFMap, mcpttidIWFMapState);
        setbasicdetails({
          ...basicInfoObj.current,
          iwf: mcpttidIWFMap,
        });
        basicInfoObj.current.iwf = mcpttidIWFMap;
        setMcpttidIWFMap(mcpttidIWFMap);
        if (configDomain && configDomain.iwfs && configDomain.iwfs.length > 0) {
          for (let index = 0; index < configDomain.iwfs.length; index++) {
            const element = configDomain.iwfs[index];
            if (
              element.type &&
              element.type.toLocaleUpperCase() ==
              mcpttidIWFMap.type.toLocaleUpperCase()
            ) {
              if (
                element.type &&
                element.type.toLocaleUpperCase().includes("PBX")
              ) {
                setFullyIWF(false);
              } else {
                setFullyIWF(true);
              }
              break;
            }
          }
        } else {
          setFullyIWF(false);
        }
      }
    } else {
      setMcpttidIWFMap();
    }
  }, [mcpttidIWFMap]);

  useEffect(() => {
    console.log(
      "userProfileWithAttachedFA useeffect...",
      userProfileWithAttachedFA
    );
    if (userProfileWithAttachedFA && isEmptyObject(userProfileWithAttachedFA)) {
      console.log("userProfileWithAttachedFA useeffect empty");
      return;
    }

    if (props.basicInfoData) {
      if (
        userProfileWithAttachedFA.faList &&
        userProfileWithAttachedFA.faList.length > 0
      ) {
        let updatedFAs = [];
        userProfileWithAttachedFA.faList.forEach((element) => {
          let ueType = element.ueType ? element.ueType : "Dispatcher";
          let autoActivate = element.default
            ? JSON.parse(element.default)
            : false;
          let faDefalut = { ...element, default: autoActivate, ueType: ueType };
          updatedFAs = [...updatedFAs, faDefalut];
        });
        setaddedFAs(updatedFAs);
      }
      let orgId = props.basicInfoData.orgId ? props.basicInfoData.orgId : "0";
      let VideoEnable = props.basicInfoData.VideoEnable
        ? props.basicInfoData.VideoEnable
        : false;
      let userType = props.basicInfoData.userType
        ? props.basicInfoData.userType
        : "Dispatcher";
      let broadCastCall = props.basicInfoData.broadCastCall
        ? props.basicInfoData.broadCastCall
        : false;
      let priority = props.basicInfoData.priority
        ? props.basicInfoData.priority
        : "1";
      let mcxDataIdText = props.basicInfoData.mcxDataIdText
        ? props.basicInfoData.mcxDataIdText
        : "";
      let mcxDataID = props.basicInfoData.mcxDataID
        ? props.basicInfoData.mcxDataID
        : false;
      let mcxVideoIdText = props.basicInfoData.mcxVideoIdText
        ? props.basicInfoData.mcxVideoIdText
        : "";
      let mcxVideoID = props.basicInfoData.mcxVideoID
        ? props.basicInfoData.mcxVideoID
        : false;
      let userName = props.basicInfoData.userName
        ? props.basicInfoData.userName
        : "";
      let displayName = props.basicInfoData.displayName
        ? props.basicInfoData.displayName
        : "";
      let TetraUser = props.basicInfoData.tetraUser
        ? props.basicInfoData.tetraUser
        : false;
      let phoneNumber = props.basicInfoData.phoneNumber
        ? props.basicInfoData.phoneNumber
        : "";
      let mcpttId = props.basicInfoData.mcpttId
        ? props.basicInfoData.mcpttId
        : "";
      let Email = props.basicInfoData.Email ? props.basicInfoData.Email : "";
      let Role = "Dispatcher";
      let cadCallData = props.basicInfoData.cadCallData
        ? props.basicInfoData.cadCallData
        : {
          allowPrivateCallParticipation: true,
          incomingAuthorizationRequired: false,
          allowPrivateCallToAnyUser: false,
          outgoingAuthorizationRequired: false,
          defaultAuthorizer: "",
        };
      let callForwardingData = props.basicInfoData.callForwardingData
        ? props.basicInfoData.callForwardingData
        : {
          allowCallForwarding: false,
          allowCallForwardManualInput: false,
          callForwardingTarget: "",
          callForwardingOn: false,
          callForwardingCondition: "",
          callForwardingNoAnswerTimeout: "",
          allowCallForwardManualInputTarget: "",
        };
      let permissions = {
        trainTab: true,
        communicationTab: true,
        alertTab: true,
        baseStation: true,
        locationTab: true,
        recordingsTab: true,
        grabAllowed: "default",
        trainMovement: "default",
      };
      if (props.basicInfoData.Role.count > 0) {
        setSelectedRoleType(props.basicInfoData.Role[0]);
        Role = props.basicInfoData.Role[0];
      } else {
        setSelectedRoleType("Dispatcher");
      }
      if (userProfileWithAttachedFA.orgId) {
        orgId = userProfileWithAttachedFA.orgId;
      }
      if (userProfileWithAttachedFA.hasOwnProperty("CallerDescr")) {
        displayName = userProfileWithAttachedFA.CallerDescr;
      }
      if (userProfileWithAttachedFA.hasOwnProperty("VideoEnable")) {
        VideoEnable = userProfileWithAttachedFA.VideoEnable;
      }
      if (userProfileWithAttachedFA.hasOwnProperty("broadCastCall")) {
        broadCastCall = userProfileWithAttachedFA.broadCastCall;
      }
      if (userProfileWithAttachedFA.hasOwnProperty("priority")) {
        priority = userProfileWithAttachedFA.priority;
        setSelectedPriority(priority);
      }
      if (userProfileWithAttachedFA.hasOwnProperty("userType")) {
        userType = userProfileWithAttachedFA.userType;
        setSelectedUserType(userType);
      }
      if (userProfileWithAttachedFA.hasOwnProperty("mcDataId")) {
        mcxDataIdText = userProfileWithAttachedFA.mcDataId;
        if (userProfileWithAttachedFA.mcDataId.length > 0) {
          mcxDataID = true;
          setIsMCXDataIdDisabled(false);
        } else {
          mcxDataID = false;
          setIsMCXDataIdDisabled(true);
        }
      }
      if (userProfileWithAttachedFA.hasOwnProperty("mcVideoId")) {
        mcxVideoIdText = userProfileWithAttachedFA.mcVideoId;
        if (userProfileWithAttachedFA.mcVideoId.length > 0) {
          mcxVideoID = true;
          setIsMCXVideoIdDisabled(false);
        } else {
          mcxVideoID = false;
          setIsMCXVideoIdDisabled(true);
        }
      }
      if (userProfileWithAttachedFA.hasOwnProperty("cadCallData")) {
        cadCallData = { ...userProfileWithAttachedFA.cadCallData };
      }
      if (userProfileWithAttachedFA.hasOwnProperty("callForwardingData")) {
        callForwardingData = {
          ...userProfileWithAttachedFA.callForwardingData,
        };
      }
      if (userProfileWithAttachedFA.hasOwnProperty("permissions")) {
        permissions = { ...userProfileWithAttachedFA.permissions };
      }

      if (userProfileWithAttachedFA.hasOwnProperty("tetra")) {
        TetraUser = userProfileWithAttachedFA.tetra;
      }

      if (userProfileWithAttachedFA.hasOwnProperty("UEInfo")) {
        if (userProfileWithAttachedFA.UEInfo.length > 0) {
          let ueTypeList = [];
          userProfileWithAttachedFA.UEInfo.forEach((element) => {
            let UEInfoData = UETypeOptions.filter(
              (ues) => ues.value === element.value
            );
            if (UEInfoData.length > 0) {
              let elm = UEInfoData[0];
              let isPrimary = false;
              if (element.primary && JSON.parse(element.primary)) {
                isPrimary = JSON.parse(element.primary);
              }
              let UEInfoObj = {
                ...elm,
                noOfReg: element.noOfReg ? element.noOfReg : "1",
                primary: isPrimary,
              };
              ueTypeList = [...ueTypeList, UEInfoObj];
            }
          });
          if (ueTypeList.length > 0) {
            setaddedInfo(ueTypeList);
            if (!global.config.isEcommerce) {
              fetchAvailableServices(ueTypeList);
            }
          }
          console.log("basicdetails ueTypeList...", ueTypeList);
          getCadConfigUETypeIfAny(ueTypeList);
        }
      }

      if (userProfileWithAttachedFA.hasOwnProperty("selectedServiceList")) {
        setAllSelectedServices(userProfileWithAttachedFA.selectedServiceList);
        openConfigOnBaseOfSelectedServices(userProfileWithAttachedFA.selectedServiceList);
      }

      let location = userProfileWithAttachedFA.locationEnable ? userProfileWithAttachedFA.locationEnable : false;
      let hour = userProfileWithAttachedFA.locationHour ? userProfileWithAttachedFA.locationHour : "0";
      let min = userProfileWithAttachedFA.locationMin ? userProfileWithAttachedFA.locationMin : "0";
      let sec = userProfileWithAttachedFA.locationSec ? userProfileWithAttachedFA.locationSec : "0";
      //console.log('basicdetails tetra out...',userProfileWithAttachedFA, TetraUser) props.basicInfoData

      setbasicdetails({
        ...basicInfoObj.current,
        orgId: orgId,
        VideoEnable: VideoEnable,
        userType: userType,
        priority: priority,
        broadCastCall: broadCastCall,
        mcxDataIdText: mcxDataIdText,
        mcxDataID: mcxDataID,
        mcxVideoIdText: mcxVideoIdText,
        mcxVideoID: mcxVideoID,
        Role: Role,
        TetraUser: TetraUser,
        userName: userName,
        displayName: displayName,
        phoneNumber: phoneNumber,
        mcpttId: mcpttId,
        Email: Email,
        cadCallData: cadCallData,
        callForwardingData: callForwardingData,
        permissions: permissions,
        location: location,
        hour: hour,
        min: min,
        sec: sec,
      });

      basicInfoObj.current = {
        ...basicInfoObj.current,
        orgId: orgId,
        VideoEnable: VideoEnable,
        userType: userType,
        mcxDataIdText: mcxDataIdText,
        mcxDataID: mcxDataID,
        mcxVideoIdText: mcxVideoIdText,
        mcxVideoID: mcxVideoID,
        Role: Role,
        TetraUser: TetraUser,
        userName: userName,
        displayName: displayName,
        phoneNumber: phoneNumber,
        mcpttId: mcpttId,
        Email: Email,
        cadCallData: cadCallData,
        callForwardingData: callForwardingData,
        permissions: permissions,
        location: location,
        hour: hour,
        min: min,
        sec: sec,
      };

      console.log("basicInfoObjcurrent ", basicInfoObj.current);
    }
  }, [userProfileWithAttachedFA]);

  useEffect(() => {
    console.log("availableServices userType ", availableServices);
  }, [availableServices])

  useEffect(() => {
    console.log("currentActiveSubscriptionsForUser ", currentActiveSubscriptionsForUser);
    let serviceList = [];
    let ueTypeList = [];
    let ueTypeListF = [];
    if (currentActiveSubscriptionsForUser && currentActiveSubscriptionsForUser.length > 0) {
      let allocatedSubscriptions = [];
      for (let curActiveSub of currentActiveSubscriptionsForUser) {
        if (curActiveSub && curActiveSub.tenantsubscriptions) {
          let tenantSub = curActiveSub.tenantsubscriptions;
          if (tenantSub && tenantSub.serviceIdList) {
            serviceList = [...new Set([...serviceList, ...tenantSub.serviceIdList])];
          }
          if (tenantSub && tenantSub.ueTypeList) {
            ueTypeList = [...new Set([...ueTypeList, ...tenantSub.ueTypeList])];
          }
          if (tenantSub) {
            allocatedSubscriptions = [...allocatedSubscriptions, tenantSub];
          }
        }
      }
      if (allocatedSubscriptions && allocatedSubscriptions.length > 0) {
        setSubscriptionForAllocation(allocatedSubscriptions);
      }

      if (ueTypeList && ueTypeList.length > 0) {
        for (let ue of ueTypeList) {
          let ueInfo = {
            "noOfReg": "1",
            "primary": false,
            "text": ue,
            "value": ue
          }
          ueTypeListF = [...ueTypeListF, ueInfo];
          setUETypeOptions(ueTypeListF);
          setaddedInfo(ueTypeListF);
        }
      }
      if (serviceList && serviceList.length > 0) {
        setAllSelectedServices(serviceList);
        openConfigOnBaseOfSelectedServices(serviceList);
      }
      console.log("currentActiveSubscriptionsForUser1 ", serviceList, ueTypeList, allocateDeallocateSubscriptionsuccessIds);
      if (allocateDeallocateSubscriptionsuccessIds && allocateDeallocateSubscriptionsuccessIds.length > 0) {
        setProfileState("profile");
        let serviceWithPermisson = updatedTabAndServiceConfig(checkPermissionsServiceConfig(serviceList, ueTypeList));
        updateMcxUserProfile(serviceList, ueTypeListF, serviceWithPermisson);
        closeModal();
      }
    } else {
      if (allocateDeallocateSubscriptionsuccessIds && allocateDeallocateSubscriptionsuccessIds.length > 0) {
        if (subscriptionModified) {
          setProfileState("profile");
          closeModal();
        }
      }
    }
  }, [currentActiveSubscriptionsForUser])

  useEffect(() => {
    if (global.config.isEcommerce) {
      console.log("userProfileCreatedId ", userProfileCreatedId);
      if (userProfileCreatedId && userProfileCreatedId.success) {
        allocateUserSubscription(userProfileCreatedId.userName);
      }
    }
  }, [userProfileCreatedId])

  useEffect(() => {
    if (!global.config.isEcommerce) {
      let uetypes = [];
      for (let ueType of ueTypeList) {
        let ueObj = {
          text: ueType,
          value: ueType //serviceUeTypeConfig && serviceUeTypeConfig[ueType] ? serviceUeTypeConfig[ueType] : ueType
        }
        uetypes = [...uetypes, ueObj];
      }
      setUETypeOptions(uetypes);
    }
  }, [ueTypeList])

  useEffect(() => {
    if (deviceIdOption && deviceIdOption.length > 0 && selectedUEType) {
      setDeviceIdDropdown(true)
    }
    else {
      setDeviceIdDropdown(false);
    }
  }, [deviceIdOption])

  useEffect(() => {
    if (deviceId && Array.isArray(deviceId)) {
      setDeviceID(deviceId)
    }
    else {
      setDeviceID([]);
    }
  }, [deviceId])

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "94%",
      // maxWidth: 500,
      height: 750,
      overflowY: "scroll",
      backgroundColor: "#252524",
      paddingTop: 1,
      paddingLeft: 20,
      paddingRight: 20,
      display: 'flex',
      flexDirection: 'column',
    },
    formControl: {
      width: "96%",
      marginTop: "5px",
      fontSize: 17,
      fontWeight: 600,
      backgroundColor: "#383736",
      fontFamily: "Muli",
    },
    formControlFA: {
      width: "80%",
      height: "65px",
      color: "#D0D3D4",
      fontSize: 17,
      fontWeight: 600,
      backgroundColor: "#383736",
      fontFamily: "Muli",
    },
    listItemFA: {
      padding: "5px",
      fontFamily: "Muli",
      color: "#D0D3D4",
      fontSize: 17,
      fontWeight: 600,
    },
    listItemFAUE: {
      width: 100,
      fontFamily: "Muli",
      color: "#D0D3D4",
      fontSize: 17,
      fontWeight: 600,
    },
    selectFAUE: {
      width: "100%",
      fontFamily: "Muli",
      color: "#D0D3D4",
      fontSize: 17,
      fontWeight: 600,
    },
    formControlFAUE: {
      marginLeft: 10,
      width: "60%",
      height: "55px",
      color: "#D0D3D4",
      fontSize: 17,
      fontWeight: 600,
      backgroundColor: "#383736",
      fontFamily: "Muli",
      marginRight: 50
    },
    listMember_all: {
      // width: 250,
      maxHeight: 200,
    },
    noOfRegTextField: {
      width: "40%",
      height: "25px",
    },
    ueInfoList: {
      padding: "5px",
      fontFamily: "Muli",
    },
    formControlUEType: {
      width: "40%",
      height: "60px",
      marginTop: "5px",
      marginInline: "5px",
      fontFamily: "Muli",
    },
    mcxDataIdInput: {
      backgroundColor: "rgb(232, 232, 232) !important",
    },
    tickSize: {
      transform: "scale(1.2)",
      borderColor: "white"
    },
    ml0: {
      marginTop: "10px",
    },
    mleft0: {
      marginLeft: "0px",
    },
    errorIndicator: {
      background: "#ffeded",
    },
    paper: {
      position: 'relative',
      // background: '#e8e8e8',
      backgroundColor: "#383736",
      color: "#D0D3D4",
      overflow: 'hidden',
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#383736"
      },
      fontFamily: "Muli",
    },
    formControlIwfType: {
      width: "100%",
      height: "10px",
    },
    iwfInfoList: {
      height: '30px',
      padding: "0px 5px 17.5px 0px",
      fontFamily: "Muli",
      color: '#000'
    },
    productTypeClass: {
      width: "94%",
      display: 'flex',
      flexDirection: 'column',
      justifyContent: "center",
      alignItems: "center",
      border: "solid",
      borderRadius: 10,
      borderWidth: "2px",
      borderColor: "#4B4A4A",
      marginTop: 5,
      marginBottom: 10
    },
    divList: {
      width: '100%',
      marginBottom: '13px',
    },
    lblStyle: {
      width: '100%',
      color: "#D0D3D4",
      fontSize: 18,
      fontWeight: 600,
      fontFamily: "Muli"
    },
    lblHeader: {
      width: '100%',
      color: "#D0D3D4",
      fontSize: 20,
      fontWeight: 700,
      fontFamily: "Muli",
    },
    tetraSwitch: {
      fontFamily: "Muli",
      marginTop: "2px",
      marginRight: "1px",
      marginLeft: "0px",
      color: "#D0D3D4",
      fontSize: 17,
      fontWeight: 600,
    },
  }));

  const theme = createTheme({
    // palette: {
    //   primary: {
    //     main: "#006400",
    //   },
    //   secondary: {
    //     main: "#fdce2a",
    //   },
    // },
    overrides: {
      MuiSwitch: {
        switchBase: {
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.65
          },
          "&.Mui-checked + .MuiSwitch-track": {
            opacity: 0.65
          },
        },
        track: {
          backgroundColor: "gray",
        },
        colorPrimary: {
          "&.Mui-disabled + .MuiSwitch-track": {
            backgroundColor: "#4CAF50",
          },
          "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#4CAF50",
          }
        }
      },
    }
  });

  const updatedServices = (selectedServices) => {
    console.log("selectedServices ", selectedServices);
    setAllSelectedServices(selectedServices);
  }

  const userNameChangeHandler = (e) => {
    e.target.value = e.target.value.replace(/[@\s]/g, '')
    setbasicdetails({
      ...basicdetails,
      userName: e.target.value,
      mcpttId: e.target.value,
    });

    let foundUser = false;
    if (purpose !== "edit") {
      foundUser = checkUserIsAlreadyExist(e.target.value);
      setIsUserNameExist(foundUser)
      setShowSubmitBtn(!foundUser)
    }

    if (e.target.value.length > 0) {
      setEmptyMCXId(false);
    } else {
      setEmptyMCXId(true);
    }
  };

  const displayNameChangeHandler = (e) => {
    setbasicdetails({
      ...basicdetails,
      displayName: e.target.value,
    });
  };

  const userPwdChangeHandler = (e) => {
    setbasicdetails({
      ...basicdetails,
      password: e.target.value,
    });
    if (e.target.value.length > 7) {
      setEmptyPassword(false);
    } else {
      setEmptyPassword(true);
    }
  };

  const userCnfPwdChangeHandler = (e) => {
    setbasicdetails({
      ...basicdetails,
      confirmpassword: e.target.value,
    });
    if (e.target.value.length > 0 && e.target.value === basicdetails.password) {
      setPasswordMitchMatch(false);
    } else {
      setPasswordMitchMatch(true);
    }
  };

  const tetraUserChangeHandler = (e) => {
    setbasicdetails({
      ...basicdetails,
      TetraUser: e.target.checked,
    });

    if (e.target.checked == false) {
      setFullyIWF(false);
    } else {
      if (configDomain && configDomain.iwfs && configDomain.iwfs.length > 0) {
        for (let index = 0; index < configDomain.iwfs.length; index++) {
          const element = configDomain.iwfs[index];
          if (basicdetails && basicdetails.iwf && basicdetails.iwf.type) {
            if (
              element.type &&
              element.type.toLocaleUpperCase() ==
              basicdetails.iwf.type.toLocaleUpperCase()
            ) {
              if (
                element.type &&
                element.type.toLocaleUpperCase().includes("PBX")
              ) {
                setFullyIWF(false);
              } else {
                setFullyIWF(true);
              }
              break;
            }
          } else {
            setFullyIWF(false);
          }
        }
      } else {
        setFullyIWF(false);
      }
    }
  };

  const videoEnableChangeHandler = (e) => {
    setbasicdetails({
      ...basicdetails,
      VideoEnable: e.target.checked,
    });
  };

  const broadCastCallChangeHandler = (e) => {
    setbasicdetails({
      ...basicdetails,
      broadCastCall: e.target.checked,
    });
  };

  const handlePriorityChange = (e) => {
    setSelectedPriority(e.target.value);
    setbasicdetails({
      ...basicdetails,
      priority: e.target.value,
    });
  };

  const userContactNumberChangeHandler = (e) => {
    setbasicdetails({
      ...basicdetails,
      phoneNumber: e.target.value,
    });
  };

  const userMacxidChangeHandler = (e) => {
    setbasicdetails({
      ...basicdetails,
      mcpttId: e.target.value,
    });
    if (e.target.value.length > 0) {
      setEmptyMCXId(false);
    } else {
      setEmptyMCXId(true);
    }
  };

  const handleRoleTypeChange = (e) => {
    setSelectedRoleType(e.target.value);
    setbasicdetails({
      ...basicdetails,
      Role: e.target.value,
    });
  };

  const handleUserTypeChange = (e) => {
    setSelectedUserType(e.target.value);
    setbasicdetails({
      ...basicdetails,
      userType: e.target.value,
    });
  };

  // ---------------------------------------UE TYPE------------------

  const handleUETypeChangeHandler = (e) => {
    setSelectedUEType(e.target.value);
    getDeviceIdList({ tenantId: global.config.userConfig.tenantId, "ueType": e.target.value })
    setSelectedDeviceID('')
    setSelectedDeviceId('')
  };

  const handleUETypeChange = (e) => {
    let checkAlreadyAddedUEInfo = addedUEInfo.filter(
      (ues) => ues.value === selectedUEType
    );
    if (checkAlreadyAddedUEInfo.length === 0) {
      let UEInfoData = UETypeOptions.filter(
        (ues) => ues.value === selectedUEType
      );
      if (UEInfoData.length > 0) {
        let element = UEInfoData[0];
        let UEInfoObj = { ...element, noOfReg: "1", primary: false };
        if (deviceIdDropdown) {
          UEInfoObj.deviceId = selectedDeviceId || basicdetails.deviceId;
        }
        let tempUEInfoArr = [...addedUEInfo, UEInfoObj];
        setaddedInfo(tempUEInfoArr);
        getCadConfigUETypeIfAny(tempUEInfoArr);
        if (!global.config.isEcommerce) {
          fetchAvailableServices(tempUEInfoArr);
        }
        let tempArr = { "deviceId": selectedDeviceId && selectedDeviceId.length > 0 ? selectedDeviceId : 'NA', "ueType": selectedUEType }
        setDeviceID(prevData => Array.isArray(prevData) ? [...prevData, tempArr] : [tempArr])
        setSelectedDeviceID('')
        setSelectedDeviceId('')
        setDeviceIdDropdown(false)
      }
    } else {
      getCadConfigUETypeIfAny(addedUEInfo);
      let devices = [];
      if (deviceID && deviceID.length > 0) {
        console.log("deviceIDDD ", deviceID, selectedUEType);
        for (let device of deviceID) {
          if (device.ueType === selectedUEType) {
            console.log("deviceIDDD selectedUEType", device, selectedUEType);
            let newDevice = {
              "deviceId": selectedDeviceId && selectedDeviceId.length > 0 ? selectedDeviceId : 'NA',
              "ueType": selectedUEType
            }
            devices = [...devices, newDevice];
          } else {
            devices = [...devices, device];
          }
        }
      } else {
        let newDevice = {
          "deviceId": selectedDeviceId && selectedDeviceId.length > 0 ? selectedDeviceId : 'NA',
          "ueType": selectedUEType
        }
        devices = [...devices, newDevice];
      }
      setDeviceID(devices);
      setSelectedDeviceID('')
      setSelectedDeviceId('')
      setaddedInfo(addedUEInfo);
    }
  };

  const getCadConfigUETypeIfAny = (ueTypes) => {
    let UEInfoData = ueTypes.filter(
      (ues) => ues.value == 'Dispatcher'
    );
    if (UEInfoData && UEInfoData.length) {
      setCadConfigUEType("Dispatcher");
    } else {
      setCadConfigUEType("");
    }
  }

  const deleteUEInfo = (ue) => {
    let deleteUEs = addedUEInfo.filter((ues) => ues.text !== ue.text);
    setaddedInfo(deleteUEs);
    if (deleteUEs && deleteUEs.length === 0) {
      setbasicdetails({
        ...basicdetails,
        TetraUser: false,
      });
      setFullyIWF(false);
    }
    getCadConfigUETypeIfAny(deleteUEs);
    if (!global.config.isEcommerce) {
      fetchAvailableServices(deleteUEs);
    }
    let deleteDeviceId = deviceID && deviceID.length > 0 && deviceID.filter((ues) => ues.ueType !== ue.text)
    setDeviceID(deleteDeviceId);
  };

  const fetchAvailableServices = (tempUEInfoArr) => {
    let ueTypeVal = new Set([]);
    for (let ueInfo of tempUEInfoArr) {
      if (serviceUeTypeConfig && serviceUeTypeConfig[ueInfo.value]) {
        if (!ueTypeVal.has(serviceUeTypeConfig[ueInfo.value])) {
          ueTypeVal.add(serviceUeTypeConfig[ueInfo.value])
        }
      }
    }
    let ueTypeValArr = Array.from(ueTypeVal);
    getAvailableServices(({ ueType: ueTypeValArr, mtmId: global.config.userConfig.tenantId }))
  }

  const modifyAddedUEInfo = () => {
    let onleUETypeVal = [];
    for (let ueInfo of addedUEInfo) {
      onleUETypeVal = [...onleUETypeVal, ueInfo.value];
    }
    return onleUETypeVal;
  }

  const changeUEPrimary = (e, ue) => {
    let updatedUEInfo = [];
    addedUEInfo.forEach((element) => {
      if (ue.text === element.text) {
        let updatedElement = { ...element };
        updatedElement.primary = !ue.primary;
        updatedUEInfo = [...updatedUEInfo, updatedElement];
      } else {
        let updatedElement = { ...element };
        updatedElement.primary = false;
        updatedUEInfo = [...updatedUEInfo, updatedElement];
      }
    });
    setaddedInfo(updatedUEInfo);
  };

  const onChangeDeviceId = (e, value) => {
    if (value && value.deviceId) {
      setSelectedDeviceId(value.deviceId)
      setSelectedDeviceID(value)
      setbasicdetails({ ...basicdetails, deviceId: value.deviceId })
    }
  }

  const changeUEDM = (e, ue) => {
    setIsDmChecked(!isDmChecked)
    // let updatedUEInfo = [];
    // addedUEInfo.forEach((element) => {
    //   if (ue.text === element.text) {
    //     let updatedElement = { ...element };
    //     updatedElement.primary = !ue.primary;
    //     updatedUEInfo = [...updatedUEInfo, updatedElement];
    //   } else {
    //     let updatedElement = { ...element };
    //     updatedElement.primary = false;
    //     updatedUEInfo = [...updatedUEInfo, updatedElement];
    //   }
    // });
    // setaddedInfo(updatedUEInfo);
  };

  const noOfRegChangeHandler = (e, ue) => {
    let updatedUEInfo = [];
    addedUEInfo.forEach((element) => {
      if (ue.text === element.text) {
        let updatedElement = { ...element };
        updatedElement.noOfReg = e.target.value;
        updatedUEInfo = [...updatedUEInfo, updatedElement];
      } else {
        updatedUEInfo = [...updatedUEInfo, element];
      }
    });
    setaddedInfo(updatedUEInfo);
  };

  //  --------------------MCXVIDEO & MCXDATA-------------------------
  const mcxDataIDEnableChangeHandler = (e) => {
    setbasicdetails({
      ...basicdetails,
      mcxDataID: e.target.checked,
    });
    setIsMCXDataIdDisabled((prev) => !prev);
  };

  const mcxDataIDChangeHandler = (e) => {
    setbasicdetails({
      ...basicdetails,
      mcxDataIdText: e.target.value,
    });
  };

  const mcxVideoIDEnableChangeHandler = (e) => {
    setbasicdetails({
      ...basicdetails,
      mcxVideoID: e.target.checked,
    });
    setIsMCXVideoIdDisabled((prev) => !prev);
  };

  const mcxVideoIDChangeHandler = (e) => {
    setbasicdetails({
      ...basicdetails,
      mcxVideoIdText: e.target.value,
    });
  };

  const allowPrivateCallChangeHandler = (e) => {
    //console.log('allowPrivateCallChangeHandler..', e.target.checked, cadCallData);
    let allowPrivateCallParticipation = e.target.checked;
    let incomingAuthorizationRequired = basicdetails.cadCallData && basicdetails.cadCallData.incomingAuthorizationRequired;
    let defaultAuthorizer = basicdetails.cadCallData && basicdetails.cadCallData.defaultAuthorizer;
    let outgoingAuthorizationRequired = basicdetails.cadCallData && basicdetails.cadCallData.outgoingAuthorizationRequired;

    if (e.target.checked == false) {
      incomingAuthorizationRequired = false;
      if (!outgoingAuthorizationRequired) {
        defaultAuthorizer = "";
      }
    }

    let cadCallDataInfo = {
      allowPrivateCallParticipation: allowPrivateCallParticipation,
      incomingAuthorizationRequired: incomingAuthorizationRequired,
      defaultAuthorizer: defaultAuthorizer,
      outgoingAuthorizationRequired: outgoingAuthorizationRequired
    }

    setbasicdetails({
      ...basicdetails,
      cadCallData: cadCallDataInfo,
    });

  };

  // ---------------------------------------------------------------------------

  const userEmailChangeHandler = (e) => {
    setbasicdetails({
      ...basicdetails,
      Email: e.target.value,
    });
  };

  const userOrgIdChangeHandler = (orgId) => {
    setbasicdetails({
      ...basicdetails,
      orgId: orgId,
    });
  };

  const functionalAliasChangeHandler = (e) => {
    setvlFunctionalAlias(e.target.value);
  };

  const OnAddFunctionalAlias = () => {
    let checkAlreadyAddedFA = addedFAs.filter(
      (fas) => fas.name === vlFunctionalAlias
    );
    if (checkAlreadyAddedFA.length === 0) {
      // get object from falist
      let faData = falist.filter((fas) => fas.name === vlFunctionalAlias);
      if (faData.length > 0) {
        let element = faData[0];
        console.log("OnAddFunctionalAlias", element);
        let faDefalut = { ...element, default: false };
        let addedFAsC = [...addedFAs, faDefalut];
        setaddedFAs(addedFAsC);
      }
    }
  };

  const deleteFAlias = (fa) => {
    let deleteFAs = addedFAs.filter((fas) => fas.name !== fa.name);
    setaddedFAs(deleteFAs);
  };

  const makeDefaultFA = (fa) => {
    let updatedFAs = [];
    addedFAs.forEach((element) => {
      if (fa.name === element.name) {
        let updatedElement = { ...element };
        updatedElement.default = !fa.default;
        updatedFAs = [...updatedFAs, updatedElement];
      } else {
        updatedFAs = [...updatedFAs, element];
      }
    });
    setaddedFAs(updatedFAs);
  };

  const handleFAUETypeChangeHandler = (e, fa) => {
    let updatedFAs = [];
    if (addedFAs && addedFAs.length > 0) {
      addedFAs.forEach((element) => {
        if (fa.name === element.name) {
          let updatedElement = { ...element };
          updatedElement.ueType = e.target.value;
          updatedFAs = [...updatedFAs, updatedElement];
        } else {
          updatedFAs = [...updatedFAs, element];
        }
      });
    }
    setaddedFAs(updatedFAs);
  };

  let errors = {};

  const UpdateBasicDetails = (e) => {
    //console.log('basicInfo basicdetail s', basicdetails)
    e.preventDefault();
    console.log("basicInfo basicdetail s 00", basicdetails);

    if (basicdetails.cadCallData.allowPrivateCallParticipation === false) {
      basicdetails.cadCallData.incomingAuthorizationRequired = false;
      setEmptyDefaultAuthorizer(false);
    }

    if (basicdetails.callForwardingData.allowCallForwarding === false) {
      basicdetails.callForwardingData = {
        ...basicdetails.callForwardingData,
        allowCallForwarding: false,
        allowCallForwardManualInput: false,
        callForwardingTarget: "",
      };
      setEmptyCallForwardTarget(false);
    }
    if (basicdetails.mcpttId.length === 0) {
      setEmptyMCXId(true);
    } else if (emptyDefaultAuthorizer) {
      return;
    } else if (emptyCallForwardTarget) {
      return;
    } else if (basicdetails.password.length === 0) {
      setEmptyPassword(true);
    } else if (basicdetails.password !== basicdetails.confirmpassword) {
      setPasswordMitchMatch(true);
    }
    else if (allSelectedServices.length === 0 && !global.config.isEcommerce) {
      console.log("Please select atleaset one reg service");
      return;
    }
    else if (fullyIWF && !basicdetails.iwf.id && addedFA.length == 0) {
      console.log("basicInfo basicdetail s 1");
      errors["id"] = "Please enter iwf id";
      setbasicdetails({
        ...basicdetails,
        iwf: { ...basicdetails.iwf, errors: errors },
      });
    } else if (
      fullyIWF &&
      purpose !== "edit" &&
      iwfMaplist.some((obj) => obj.id === basicdetails.iwf.id) &&
      addedFA.length == 0
    ) {
      console.log("basicInfo basicdetail s 2");
      errors["id"] = "SSI already exits";
      setbasicdetails({
        ...basicdetails,
        iwf: { ...basicdetails.iwf, errors: errors },
      });
    } else if (
      fullyIWF &&
      (!basicdetails.iwf.type ||
        (basicdetails.iwf.type && basicdetails.iwf.type.length === 0)) &&
      addedFA.length == 0
    ) {
      // e.preventDefault()
      console.log("basicInfo basicdetail s 3");
      errors["id"] = "Please select iwf type";
      setbasicdetails({
        ...basicdetails,
        iwf: { ...basicdetails.iwf, errors: errors },
      });
    } else if (
      basicdetails &&
      basicdetails.iwf.type &&
      basicdetails.iwf.type.toLocaleUpperCase().includes("PBX") &&
      basicdetails.iwf.id &&
      basicdetails.iwf.id.length == 0
    ) {
      console.log('"Please add pbx mapping iwf id"');
    } else {
      console.log("userProfile before", userProfileWithAttachedFA);
      let fullDetail = { ...basicdetails };
      if (
        basicdetails &&
        basicdetails.iwf.type &&
        basicdetails.iwf.type.toLocaleUpperCase().includes("PBX")
      ) {
        fullDetail.TetraUser = false;
      } else {
        if (basicdetails.TetraUser) {
          fullDetail.TetraUser = true;
        } else {
          fullDetail.TetraUser = false;
        }
      }
      updateMcxUserProfile();

      if (deviceID && !isObjectEmpty(deviceID)) {
        let tempUserName = basicdetails.userName
        if (global.config.userConfig.domainName.length > 0) {
          tempUserName = basicdetails.userName.includes('@') ? basicdetails.userName : basicdetails.userName + "@" + global.config.userConfig.domainName
        }
        let deviceData = deviceID.map(data => {
          const { ueType, ...rest } = data;
          return { ...rest, password: basicdetails.password };
        });
        if (props.purpose != "edit") {
          if (basicdetails.deviceId) {
            updateDeviceByDevice({ userId: tempUserName, password: basicdetails.password, devices: deviceData })
          }
        } else {
          updateDeviceByUser({ userId: tempUserName, password: basicdetails.password, devices: deviceData })
        }
      }

      if (fullDetail.TetraUser) {
        if (global.config.userConfig.iwfs && global.config.userConfig.iwfs.filter((type) => type.type == fullDetail.iwf.type)) {
          let filteredDomain = global.config.userConfig.iwfs.filter((type) => type.type == fullDetail.iwf.type)
          fullDetail.domain = filteredDomain[0].mappedDomain
        }
      } else {
        fullDetail.domain = global.config.userConfig.domains.mcxDomain
      }
      console.log("basicInfo update basicdetails", fullDetail);
      props.onBasicInfoUpdate(fullDetail);
      basicInfoObj.current = null;

      if (props.basicInfoData && props.basicInfoData.userName) {
        allocateUserSubscription(props.basicInfoData.userName);
      }
    }
  };

  const updateMcxUserProfile = (selServices = null, ueInfo = null, serviceWithPerm = null) => {
    let userProfile = { ...userProfileWithAttachedFA };
    let tetra = false;
    if (
      basicdetails &&
      basicdetails.iwf.type &&
      basicdetails.iwf.type.toLocaleUpperCase().includes("PBX")
    ) {
      tetra = false;
    } else {
      if (basicdetails.TetraUser) {
        tetra = basicdetails.TetraUser;
      }
    }
    let permissonss = serviceWithPerm && serviceWithPerm.permissions ? serviceWithPerm.permissions : basicdetails.permissions;
    let locationService = serviceWithPerm && serviceWithPerm.locService ? serviceWithPerm.locService : locService;
    let locSec = basicdetails.sec && basicdetails.sec.length > 0 ? basicdetails.sec : 0;
    if (locationService && locSec === 0) {
      locSec = 20;
    }
    let selectedServices = selServices ? selServices : allSelectedServices;

    userProfile = {
      ...userProfile,
      profileName: basicdetails.mcpttId,
      mcpttId: basicdetails.mcpttId,
      CallerDescr: basicdetails.displayName && basicdetails.displayName.length > 0 ? basicdetails.displayName : basicdetails.userName,
      orgId: basicdetails.orgId,
      subscriberType: basicdetails.Role,
      faList: [...addedFA],
      tetra: tetra,
      VideoEnable: basicdetails.VideoEnable,
      mcxDataIdText: basicdetails.mcxDataIdText,
      mcxVideoIdText: basicdetails.mcxVideoIdText,
      broadCastCall: basicdetails.broadCastCall,
      priority: basicdetails.priority,
      UEInfo: ueInfo ? ueInfo : [...addedUEInfo],
      userType: basicdetails.userType,
      callForwardingData: basicdetails.callForwardingData,
      cadCallData: basicdetails.cadCallData,
      permissions: permissonss,
      selectedServices: selectedServices,
      locationEnable: locationService,
      hour: 0,
      min: 0,
      sec: locSec
    };

    if (configDomain && configDomain.iwfs && configDomain.iwfs.length > 0) {
      let mappedDomain = null;
      for (let index = 0; index < configDomain.iwfs.length; index++) {
        const element = configDomain.iwfs[index];
        if (basicdetails && basicdetails.iwf && basicdetails.iwf.type) {
          if (
            element.type &&
            element.type.toLocaleUpperCase() ==
            basicdetails.iwf.type.toLocaleUpperCase()
          ) {
            if (
              element.type &&
              element.type.toLocaleUpperCase().includes("PBX")
            ) {
              mappedDomain = configDomain.mcxDomain;
            } else {
              mappedDomain = element.mappedDomain;
            }
          }
        }
      }
      if (mappedDomain) {
        userProfile = { ...userProfile, domain: mappedDomain };
      } else {
        userProfile = {
          ...userProfile,
          domain: configDomain.mcxDomain ? configDomain.mcxDomain : "",
        };
      }
    } else {
      userProfile = {
        ...userProfile,
        domain: configDomain.mcxDomain ? configDomain.mcxDomain : "",
      };
    }
    if (
      basicdetails &&
      basicdetails.iwf.type &&
      basicdetails.iwf.type.toLocaleUpperCase().includes("PBX")
    ) {
      userProfile = {
        ...userProfile,
        iwf: {
          ...basicdetails.iwf,
          mcpttId: basicdetails.mcpttId,
          fa: "",
          iwfMapPurpose,
        },
      };
    } else {
      if (basicdetails.TetraUser && addedFA.length == 0) {
        //delete basicdetails.iwf.errors
        userProfile = {
          ...userProfile,
          iwf: {
            ...basicdetails.iwf,
            mcpttId: basicdetails.mcpttId,
            fa: "",
            iwfMapPurpose,
          },
        };
      }
    }
    console.log("basicInfo update userProfile ", userProfile);
    updateUserProfileWithAttachedFAS(userProfile);
  }

  const allocateSubscription = (subscriptionData) => {
    console.log("allocateSubscription final", subscriptionData);
    setSubscriptionForAllocation(subscriptionData);
    showServicesOnSubscriptionSelection(subscriptionData);
    setSubscriptionModified(true);
  }

  const allocateUserSubscription = (userName) => {
    console.log("allocateUserSubscription for userName ", userName);
    if (subscriptionModified || (subscriptionForAllocation && subscriptionForAllocation.length > 0)) {
      setProfileState("allocate");
      let finalAllocationDeallocationArr = [];
      for (let subs of currentActiveSubscriptionsForUser) {
        let tenantSub = subs.tenantsubscriptions;
        if (tenantSub) {
          let found = false;
          for (let allocatedsubs of subscriptionForAllocation) {
            if (allocatedsubs.id === tenantSub.id) {
              found = true;
              break;
            }
          }
          if (!found) {
            let allocate = {
              state: "deallocate",
              id: tenantSub.id
            }
            finalAllocationDeallocationArr = [...finalAllocationDeallocationArr, allocate];
          }
        }
      }
      for (let subs of subscriptionForAllocation) {
        let found = false;
        for (let allocatedsubs of currentActiveSubscriptionsForUser) {
          let tenantSub = allocatedsubs.tenantsubscriptions;
          if (tenantSub) {
            if (tenantSub.id === subs.id) {
              found = true;
              break;
            }
          }
        }
        if (!found) {
          let allocate = {
            state: "allocate",
            id: subs.id
          }
          finalAllocationDeallocationArr = [...finalAllocationDeallocationArr, allocate];
        }
      }
      console.log("finalAllocationDeallocationArr ", finalAllocationDeallocationArr);
      if (finalAllocationDeallocationArr && finalAllocationDeallocationArr.length > 0) {
        let finalData = {
          "subs": finalAllocationDeallocationArr,
          "userId": userName
        }
        allocateUserFromTenantSubscription(finalData);
      } else {
        console.log("no data found for allocation!!");
        if (global.config.isEcommerce) {
          closeModal();
        }
      }
    } else {
      console.log("no data found for allocation ", subscriptionForAllocation);
      if (global.config.isEcommerce) {
        closeModal();
      }
    }
  }

  const showServicesOnSubscriptionSelection = (subscriptionData) => {
    console.log("showServicesOnSubscriptionSelection ", subscriptionData);
    if (subscriptionData && subscriptionData.length > 0) {
      let serviceList = [];
      let ueTypeList = [];
      let ueTypeListF = [];
      for (let tenantSub of subscriptionData) {
        if (tenantSub && tenantSub.serviceIdList) {
          serviceList = [...new Set([...serviceList, ...tenantSub.serviceIdList])];
        }
        if (tenantSub && tenantSub.ueTypeList) {
          ueTypeList = [...new Set([...ueTypeList, ...tenantSub.ueTypeList])];
        }
      }

      if (ueTypeList && ueTypeList.length > 0) {
        for (let ue of ueTypeList) {
          let ueInfo = {
            "noOfReg": "1",
            "primary": false,
            "text": ue,
            "value": ue
          }
          ueTypeListF = [...ueTypeListF, ueInfo];
          setUETypeOptions(ueTypeListF);
          setaddedInfo(ueTypeListF);
        }
      }
      console.log("showServicesOnSubscriptionSelection1 ", ueTypeList, serviceList);
      if (serviceList && serviceList.length > 0) {
        setAllSelectedServices(serviceList);
        openConfigOnBaseOfSelectedServices(serviceList);
      }
    } else {
      setUETypeOptions([]);
      setaddedInfo([]);
      setAllSelectedServices([]);
      setLocService(false);
      setEnableCallForwarding(false);
      setEnableCadCall(false);
      setEnableFA(false);
    }
  }

  const isObjectEmpty = (objectName) => {
    return (
      objectName &&
      Object.keys(objectName).length === 0 &&
      objectName.constructor === Object
    );
  };

  const iwfMapHandler = (value) => {
    console.log(
      "faSupportArray basicInfo iwfMapHandler...",
      basicdetails,
      value
    );
    setbasicdetails({
      ...basicdetails,
      iwf: value,
    });
    setaddedFAs([]);
    setvlFunctionalAlias("");
    if (configDomain && configDomain.iwfs && configDomain.iwfs.length > 0) {
      for (let index = 0; index < configDomain.iwfs.length; index++) {
        const element = configDomain.iwfs[index];
        if (value && value.type) {
          if (
            element.type &&
            element.type.toLocaleUpperCase() == value.type.toLocaleUpperCase()
          ) {
            if (
              element.type &&
              element.type.toLocaleUpperCase().includes("PBX")
            ) {
              setFullyIWF(false);
            } else {
              setFullyIWF(true);
            }
            break;
          }
        } else {
          setFullyIWF(false);
        }
      }
    } else {
      setFullyIWF(false);
    }
  };

  const updateUserPermissions = (permission) => {
    let permissonss = { ...basicdetails.permissions, grabAllowed: permission.grabAllowed, trainMovement: permission.trainMovement };
    console.log('permission update...', permission, permissonss);
    setbasicdetails({
      ...basicdetails,
      permissions: permissonss,
    });
  };

  const updatedTabAndServiceConfig = (serviceConfig) => {
    console.log("updatedTabAndServiceConfig()");
    let alertTab = false;
    let baseStation = basicdetails.permissions["baseStation"];
    let communicationTab = false;
    let locationTab = false;
    let recordingsTab = false;
    let trainTab = false;
    let VideoEnableL = false;
    let broadCastCallL = false;
    let enableCallForwardingL = false;
    let enableCadCallL = false;
    let enableFAL = false;
    let locServiceL = false;
    let enablePermissionL = false;
    if (serviceConfig) {
      if (serviceConfig["alertTab"]) {
        alertTab = serviceConfig["alertTab"];
      }
      if (serviceConfig["baseStation"]) {
        baseStation = serviceConfig["baseStation"];
      }
      if (serviceConfig["communicationTab"]) {
        communicationTab = serviceConfig["communicationTab"];
      }
      if (serviceConfig["locationTab"]) {
        locationTab = serviceConfig["locationTab"];
      }
      if (serviceConfig["locService"]) {
        locServiceL = serviceConfig["locService"];
      }
      if (serviceConfig["recordingsTab"]) {
        recordingsTab = serviceConfig["recordingsTab"];
      }
      if (serviceConfig["trainTab"]) {
        trainTab = serviceConfig["trainTab"];
        enablePermissionL = serviceConfig["trainTab"];
      }
      if (serviceConfig["VideoEnable"]) {
        VideoEnableL = serviceConfig["VideoEnable"];
      }
      if (serviceConfig["broadCastCall"]) {
        broadCastCallL = serviceConfig["broadCastCall"];
      }
      if (serviceConfig["enableCallForwarding"]) {
        enableCallForwardingL = serviceConfig["enableCallForwarding"];
      }
      if (serviceConfig["enableCadCall"]) {
        enableCadCallL = serviceConfig["enableCadCall"];
      }
      if (serviceConfig["enableFA"]) {
        enableFAL = serviceConfig["enableFA"];
      }
    }
    let permissonss = {
      ...basicdetails.permissions, alertTab: alertTab, baseStation: baseStation,
      communicationTab: communicationTab, locationTab: locationTab, recordingsTab: recordingsTab, trainTab: trainTab
    };
    setbasicdetails({
      ...basicdetails,
      permissions: permissonss,
      VideoEnable: VideoEnableL,
      broadCastCallL: broadCastCallL
    });
    if (enableCallForwarding !== enableCallForwardingL) {
      setEnableCallForwarding(enableCallForwardingL);
    }
    if (enableCadCall !== enableCadCallL) {
      setEnableCadCall(enableCadCallL);
    }
    if (enableFA !== enableFAL) {
      setEnableFA(enableFAL);
    }
    if (locServiceL !== locService) {
      setLocService(locServiceL);
    }
    if (enablePermissionL !== enablePermission) {
      setEnablePermission(enablePermissionL);
    }

    let finalServices = {
      "permissions": permissonss,
      "callForwad": enableCallForwardingL,
      "cadcall": enableCadCallL,
      "enablefa": enableFAL,
      "locService": locServiceL
    }
    console.log("updatedTabAndServiceConfig ", serviceConfig, finalServices);
    return finalServices;
  }

  const checkPermissionsServiceConfig = (services, selectedUeTypes) => {
    let updateServiceConfig = {};
    for (let service of services) {
      if (service.toLowerCase() === "forwardingcallfeature") {
        updateServiceConfig = { ...updateServiceConfig, enableCallForwarding: true };
      }
      if (service.toLowerCase() === "cadcallfeature") {
        updateServiceConfig = { ...updateServiceConfig, enableCadCall: true };
      }
      if (service.toLowerCase() === "mcptt-fa") {
        updateServiceConfig = { ...updateServiceConfig, enableFA: true };
      }
      if (service.toLowerCase() === "mcdata-location") {
        updateServiceConfig = { ...updateServiceConfig, locService: true };
        for (let ueType of selectedUeTypes) {
          if (ueType.toLowerCase() === "dispatcher") {
            updateServiceConfig = { ...updateServiceConfig, locationTab: true };
            break;
          }
        }
      }
      if (service.toLowerCase() === "train-tab") {
        updateServiceConfig = { ...updateServiceConfig, trainTab: true };
      }
      if (service.toLowerCase() === "alerts") {
        updateServiceConfig = { ...updateServiceConfig, alertTab: true };
      }
      if (service.toLowerCase() === "radio-base-station") {
        updateServiceConfig = { ...updateServiceConfig, baseStation: true };
      }
      if (service.toLowerCase() === "recording-tab") {
        updateServiceConfig = { ...updateServiceConfig, recordingsTab: true };
      }
      if (service.toLowerCase() === "mcptt-hubreg" || service.toLowerCase() === "mcdata-hubreg" || service.toLowerCase() === "mcvideo-hubreg"
        || service.toLowerCase() === "mcptt-edgereg" || service.toLowerCase() === "mcdata-edgereg" || service.toLowerCase() === "mcvideo-edgereg") {
        updateServiceConfig = { ...updateServiceConfig, communicationTab: true };
      }
      if (service.toLowerCase() === "mcvideo-hubreg" || service.toLowerCase() === "mcvideo-edgereg") {
        updateServiceConfig = { ...updateServiceConfig, VideoEnable: true };
      }
      if (service.toLowerCase().includes("broadcast")) {
        updateServiceConfig = { ...updateServiceConfig, broadCastCall: true };
      }
    }
    return updateServiceConfig;
  }

  const UpdatedLoc = (timerOnOFF, hour, min, sec) => {
    setbasicdetails({
      ...basicdetails,
      location: timerOnOFF,
      hour: hour,
      min: min,
      sec: sec
    });
  }

  const openConfigOnBaseOfSelectedServices = (services) => {
    console.log("openConfigOnBaseOfSelectedServices()");
    for (let service of services) {
      if (service.toLowerCase() === "mcdata-location") {
        setLocService(true);
      }
      if (service.toLowerCase() === "forwardingcallfeature") {
        if (!enableCallForwarding) {
          setEnableCallForwarding(true);
        }
      }
      if (service.toLowerCase() === "cadcallfeature") {
        if (!enableCadCall) {
          setEnableCadCall(true);
        }
      }
      if (service.toLowerCase() === "mcptt-fa") {
        setEnableFA(true);
      }
      if (service.toLowerCase() === "train-tab") {
        setEnablePermission(true);
      }
    }
  }

  const updateUserCadCallData = (cadCallData) => {
    setbasicdetails({
      ...basicdetails,
      cadCallData: cadCallData,
    });
  };

  const updateCallForwardingData = (callForwardingData) => {
    console.log("callForwardingData ", callForwardingData);
    setbasicdetails({
      ...basicdetails,
      callForwardingData: callForwardingData,
    });
  };

  const updateDefaultAuthorizerErr = (val) => {
    setEmptyDefaultAuthorizer(val);
  };

  const updateCallForwardTargetErr = (val) => {
    setEmptyCallForwardTarget(val);
  };

  const classes = useStyles();
  let domain = "";
  if (configDomain && configDomain.iwfs && configDomain.iwfs.length > 0) {
    for (let index = 0; index < configDomain.iwfs.length; index++) {
      const element = configDomain.iwfs[index];
      if (basicdetails && basicdetails.iwf && basicdetails.iwf.type) {
        if (
          element.type &&
          element.type.toLocaleUpperCase() ==
          basicdetails.iwf.type.toLocaleUpperCase()
        ) {
          if (
            element.type &&
            element.type.toLocaleUpperCase().includes("PBX")
          ) {
            domain = configDomain.mcxDomain;
          } else {
            domain = element.mappedDomain;
          }
          break;
        }
      } else {
        if (basicdetails && basicdetails.TetraUser) {
          if (element.type.toLocaleUpperCase() == 'TETRA') {
            domain = element.mappedDomain;
            break;
          }
        } else {
          domain = configDomain.mcxDomain;
        }
      }
    }
  } else {
    domain = configDomain.mcxDomain;
  }
  let faList = falist
    ? falist.filter((obj) => (obj.uri ? obj.uri.includes(domain) : false))
    : falist;

  let addedFA =
    addedFAs && basicdetails && basicdetails.TetraUser === true
      ? addedFAs.filter((obj) =>
        faList.some((faObj) => faObj.name === obj.name)
      )
      : addedFAs;

  let iwfMapPurpose =
    props.basicInfoData &&
      props.basicInfoData.TetraUser ===
      (basicdetails ? basicdetails.TetraUser : false)
      ? "edit"
      : "create";
  let orgList =
    basicdetails && basicdetails.TetraUser === true
      ? orglist.filter((obj) => obj.orgId !== "0")
      : orglist;

  const getIWfSwitchEnable = () => {
    let iwfSwitchEnable = false;
    if (basicdetails && basicdetails.TetraUser) {
      iwfSwitchEnable = true;
    } else {
      if (
        basicdetails &&
        basicdetails.iwf &&
        basicdetails.iwf.type &&
        basicdetails.iwf.type.toLocaleUpperCase().includes("PBX")
      ) {
        iwfSwitchEnable = true;
      }
    }
    return iwfSwitchEnable;
  };

  let isIwfSwitchEnable = getIWfSwitchEnable();
  //console.log('isIwfSwitchEnable..',isIwfSwitchEnable)

  const filterDeviceId = (ue) => {
    let filterData = []
    filterData = deviceID && deviceID.length > 0 && deviceID.filter(item => item.ueType === ue)
    return filterData.length > 0 ? filterData[0].deviceId : "NA"
  }

  const hideModal = () => {
    setopenModal(false);
  }

  const openSubscriptionForUser = () => {
    setopenModal(true);
  }

  const allocateSubscriptionsForUser = (subscriptions) => {
    let addedSubscriptions = [...subscriptionForAllocation, ...subscriptions];
    setSubscriptionForAllocation(addedSubscriptions);
    setopenModal(false);
    showServicesOnSubscriptionSelection(addedSubscriptions);
  }
  return (
    <div>
      <div className={classes.root}>
        <ThemeProvider theme={theme}>
          <Grid container width="100%" spacing={2}>
            <Grid item xs={12}>
              <Box display={"flex"} marginTop={0.2} marginBottom={1}>
                <label className={classes.lblHeader}>Account</label>
              </Box>
              <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={1} marginTop={1} flexDirection={"column"}>
                <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                  <label className="lblRequired">User Name</label>
                </Box>
                <Box flex={1} mr="0.5em">
                  <input
                    type="text"
                    placeholder="User Name"
                    className="input-control-flat"
                    id="firstName"
                    value={basicdetails && basicdetails.userName}
                    onChange={userNameChangeHandler}
                  />
                  {isUserNameExist === true ? (
                    <label className="error-handling-lbl">Username Already Exist</label>
                  ) : null}
                </Box>
              </Box>
              <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={1} marginTop={1} flexDirection={"column"}>
                <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                  <label className="lblRequired">Display Name</label>
                </Box>
                <Box flex={1} mr="0.5em">
                  <input
                    type="text"
                    placeholder="Display Name"
                    className="input-control-flat"
                    id="displayName"
                    value={basicdetails && basicdetails.displayName}
                    onChange={displayNameChangeHandler}
                  />
                </Box>
              </Box>
              <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={1} marginTop={1} flexDirection={"column"}>
                <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                  <label className="lblRequired">Email Id</label>
                </Box>
                <Box flex={1} mr="0.5em">
                  <input
                    type="text"
                    placeholder="Email Id"
                    className="input-control-flat"
                    id="EmailId"
                    value={basicdetails && basicdetails.Email}
                    onChange={userEmailChangeHandler}
                  />
                </Box>
              </Box>
              <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={1} marginTop={1} flexDirection={"column"}>
                <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                  <label className="lblRequired">Password</label>
                </Box>
                <Box flex={1} mr="0.5em">
                  <input
                    type="password"
                    placeholder="Password"
                    className="input-control-flat"
                    id="password"
                    value={basicdetails && basicdetails.password}
                    onChange={userPwdChangeHandler}
                    disabled={false}
                  />
                  {emptyPassword === true ? (
                    <label className="error-handling-lbl">
                      Password should be atleast 8 characters
                    </label>
                  ) : null}
                </Box>
              </Box>
              <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={3} marginTop={1} flexDirection={"column"}>
                <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                  <label className="lblRequired">Confirm Password</label>
                </Box>
                <Box flex={1} mr="0.5em">
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    className="input-control-flat"
                    id="confirmPassword"
                    value={basicdetails && basicdetails.confirmpassword}
                    onChange={userCnfPwdChangeHandler}
                    disabled={false}
                  />
                  {passwordMitchMatch === true ? (
                    <label class="error-handling-lbl">Password mismatching</label>
                  ) : null}
                </Box>
              </Box>

              {global.config.isEcommerce ?
                <div className={classes.productTypeClass}>
                  <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={1} marginTop={1} flexDirection={"column"}>
                    <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1} marginLeft={1}>
                      <label className={classes.lblStyle}>Subscriptions</label>
                    </Box>
                    <Box flex={1} mr="0.5em" marginLeft={3}>
                      <AllocateSubsciptionUser currentActiveSubscriptions={subscriptionForAllocation} allocateSubscription={allocateSubscription} />
                    </Box>
                    <Box flex={1} mr="0.5em" display={"flex"} flexDirection={"row-reverse"} height={60} marginRight={2}>
                      <button
                        className="add-btn-subscription"
                        type="button"
                        onClick={openSubscriptionForUser}
                      >
                        {"Add Subscriptions"}
                      </button>
                    </Box>
                    <Box flex={1} mr="0.5em" display={"flex"} flexDirection={"row"} marginLeft={5} width={"96%"}>
                      {
                        allSelectedServices && allSelectedServices.length > 0 ?
                          <MCXServices selectedUeTypes={modifyAddedUEInfo()} selectedServices={allSelectedServices} viewMode={true}></MCXServices>
                          : null
                      }
                    </Box>
                    <Modal
                      show={openModal}
                      onHide={hideModal}
                      scrollable={false}
                      size={"lg"}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      style={{ backgroundColor: " rgba(0,0,0,0.5)" }}
                    >
                      <Modal.Header
                        closeButton
                        style={{ border: "0px", backgroundColor: "#282828" }}
                      >
                        <Modal.Title>ADD Subscription</Modal.Title>
                      </Modal.Header>
                      <Modal.Body style={{ padding: "0.2px", backgroundColor: "#252524" }}>
                        <AllocateSubscriptionToUser allocateSubscriptionsForUser={allocateSubscriptionsForUser} allocatedSubscriptions={subscriptionForAllocation} />
                      </Modal.Body>
                    </Modal>
                  </Box>
                </div>
                : null
              }

              {/* ------------------------------------UE TYPE------------------------------- */}

              {(global.config.isEcommerce === false) || (global.config.isEcommerce === true && addedUEInfo.length > 0) ?
                <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={3} marginTop={1} flexDirection={"column"}>
                  <Box flex={1} mr="1.0em">
                    <CustomFormControl
                      variant="filled"
                      className={classes.formControlFA}
                    >
                      <InputLabel id="ueTypeID" className={classes.listItemFA} focused={false}>
                        UE Type
                      </InputLabel>
                      <Select
                        className={classes.listItemFA}
                        id="ueType-select-filled"
                        value={selectedUEType}
                        onChange={handleUETypeChangeHandler}
                        disableUnderline={true}
                      // readOnly={global.config.isEcommerce}
                      >
                        {UETypeOptions &&
                          UETypeOptions.map((uetype) => {
                            return (
                              <MenuItem value={uetype.value}>{uetype.text}</MenuItem>
                            );
                          })}
                      </Select>
                    </CustomFormControl>
                    {deviceIdDropdown ?
                      <FormControl className={classes.formControlFA} variant="filled" >
                        <Autocomplete
                          name="deviceId"
                          className={classes.paper}
                          options={deviceIdOption}
                          getOptionLabel={(option) => option ? `${option.deviceId} ${option.brand} ${option.model}` : ''}
                          style={{ backgroundColor: '#383736', marginTop: 3 }}
                          renderOption={
                            (option) => {
                              return (
                                <div style={{ borderWidth: 2, borderColor: '#000' }}>
                                  <text>{option.deviceId}</text><br></br>
                                  <text>{`${option.brand} ${option.model}`}</text>
                                </div>
                              )
                            }
                          }
                          onChange={onChangeDeviceId}
                          renderInput={(params) => <TextField {...params} label="Device Id" variant="filled" InputLabelProps={{ className: "textFieldAutoComplete" }} style={{ fontFamily: "Muli" }} />}
                          value={selectedDeviceID || ""}
                          sx={{
                            color: "white"
                          }}
                        />
                      </FormControl>
                      : null}
                    <button
                      className="add-btn-device"
                      type="button"
                      onClick={handleUETypeChange}
                    >
                      Add UE
                    </button>
                  </Box>

                  <Box flex={1} mr="1.0em" width={"100%"} marginTop={2}>
                    {addedUEInfo.length > 0 ? (
                      <DataGrid
                        rows={addedUEInfo}
                        columns={columns}
                        rowHeight={55}
                        getRowId={(row) => row.text}
                        hideFooter
                        hideFooterPagination
                        hideFooterSelectedRowCount
                        disableColumnMenu
                        localeText={{ noRowsLabel: "" }}
                        getRowSpacing={params => ({
                          top: params.isFirstVisible ? 0 : 0,
                          bottom: params.isLastVisible ? 0 : 0
                        })}

                        sx={{
                          boxShadow: 2,
                          border: "none",
                          borderBottom: "none",
                          // display: "block",
                          '& .MuiDataGrid-cell:hover': {
                            color: '#ffb01f',
                          },
                          '& .MuiDataGrid': {
                            border: 'none',
                          },
                          '& .MuiDataGrid-cell': {
                            borderColor: "#404040",
                          },
                          '& .MuiDataGrid-columnHeader': {
                            borderColor: "gray",
                          },
                          "& .MuiDataGrid-sortIcon": {
                            opacity: 1,
                            color: "white",
                          },
                          "& .MuiDataGrid-menuIconButton": {
                            opacity: 1,
                            color: "white"
                          },
                          '& .MuiDataGrid-filler': {
                            backgroundColor: "#262626",
                          },
                        }}
                      />
                    ) : null}
                  </Box>
                </Box>
                : null
              }
              {
                global.config.isEcommerce === false && availableServices && availableServices.length > 0 && addedUEInfo && addedUEInfo.length > 0 ?
                  <Box flex={1} mr="1.0em" width={"100%"}>
                    <MCXServices selectedUeTypes={modifyAddedUEInfo()} selectedServices={allSelectedServices} allowedServices={availableServices} updatedServices={updatedServices} userMode={true} updatedTabAndServiceConfig={updatedTabAndServiceConfig}></MCXServices>
                  </Box>
                  : null
              }

              {configDomain &&
                configDomain.iwfs &&
                configDomain.iwfs.length > 0 && addedUEInfo && addedUEInfo.length > 0 ? (
                <Box display={"flex"} width={"10%"} marginLeft={1} marginBottom={1} marginTop={1}>
                  <Box flex={0.15} mr="0.05em" marginTop={1.75} marginBottom={1}>
                    <label className={classes.lblStyle}>{"Iwf"}</label>
                  </Box>
                  <Box flex={1} mr="0.05em" marginTop={1} marginBottom={1}>
                    <FormControlLabel
                      value="start"
                      control={
                        <Switch
                          color="primary"
                          checked={isIwfSwitchEnable}
                          onChange={tetraUserChangeHandler}
                        />
                      }
                      labelPlacement="start"
                      onChange={tetraUserChangeHandler}
                      disabled={purpose === "edit" ? purpose : tetraDisable}
                    />
                  </Box>
                </Box>
              ) : null}

              {basicdetails && basicdetails.iwf &&
                basicdetails.iwf.type &&
                basicdetails.iwf.type.toLocaleUpperCase().includes("PBX") ? (
                <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={1} flexDirection={"column"}>
                  <IwFMapView
                    class="input-control"
                    fromFA={false}
                    iwfMapHandler={iwfMapHandler}
                    infoData={mcpttidIWFMapState ? mcpttidIWFMapState : {}}
                    falist={faList}
                    purpose={iwfMapPurpose}
                    mappedFilter={false}
                    hideIwfIdView={false}
                  ></IwFMapView>
                </Box>
              ) : basicdetails &&
                basicdetails.TetraUser === true &&
                addedFA.length == 0 ? (
                <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={1} flexDirection={"column"}>
                  <IwFMapView
                    className="input-control"
                    fromFA={false}
                    iwfMapHandler={iwfMapHandler}
                    infoData={mcpttidIWFMapState ? mcpttidIWFMapState : {}}
                    falist={faList}
                    purpose={iwfMapPurpose}
                    mappedFilter={false}
                    hideIwfIdView={false}
                  ></IwFMapView>
                </Box>
              ) : basicdetails &&
                basicdetails.TetraUser === true &&
                addedFA.length != 0 ? (
                <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={1} marginTop={1} flexDirection={"column"}>
                  <IwFMapView
                    className="input-control"
                    fromFA={false}
                    iwfMapHandler={iwfMapHandler}
                    infoData={mcpttidIWFMapState ? mcpttidIWFMapState : {}}
                    falist={faList}
                    purpose={iwfMapPurpose}
                    mappedFilter={false}
                    hideIwfIdView={true}
                  ></IwFMapView>
                </Box>
              ) : null}

              {basicdetails && basicdetails.iwf && basicdetails.iwf.errors &&
                basicdetails.iwf.errors.id ? (
                <label className="error-handling-lbl">
                  {basicdetails.iwf.errors.id}
                </label>
              ) : null}

              {/* ---------------------------------------------MCPTT-ID------------------------------- */}
              <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={1} marginTop={1} flexDirection={"column"}>
                <Box flex={0.15} mr="0.5em" marginTop={1} marginBottom={1}>
                  <label className="lblRequired">Mcptt Id</label>
                </Box>
                <Box flex={1} mr="0.5em">
                  <input
                    type="text"
                    placeholder="Mcptt Id"
                    className="input-control-flat"
                    id="mcxid"
                    value={basicdetails && basicdetails.userName}
                    onChange={userMacxidChangeHandler}
                    disabled={true}
                  />
                  {emptyMCXId === true ? (
                    <p class="error-handling-lbl">Please enter the MCXId</p>
                  ) : null}
                </Box>
              </Box>

              {/* ------------------------MCX-DATA-ID----------------------------- */}
              {fullyIWF ? null : (
                <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={1} marginTop={1} flexDirection={"column"}>
                  <Box display={"flex"} flex={1} mr="0.5em" marginTop={2} marginBottom={1} flexDirection={"row"}>
                    <Box flex={0.12} mr="0.10em">
                      <label className={classes.lblStyle}>MCXData Id</label>
                    </Box>
                    <Box flex={1} mr="1.0em" marginTop={-1}>
                      <Checkbox
                        style={{ color: "white" }}
                        size="medium"
                        checked={basicdetails && basicdetails.mcxDataID}
                        onChange={mcxDataIDEnableChangeHandler}
                      />
                    </Box>
                  </Box>
                  <Box flex={1} mr="0.5em">
                    <input
                      type="text"
                      placeholder="MCXData Id"
                      className="input-control-flat"
                      id="mcxDataID"
                      value={basicdetails && basicdetails.mcxDataIdText}
                      onChange={mcxDataIDChangeHandler}
                      disabled={isMCXDataIdDisabled}
                    />
                  </Box>
                </Box>
              )}

              {/* ------------------------MCX-VIDEO-ID----------------------------- */}
              {fullyIWF ? null : (
                <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={1} marginTop={1} flexDirection={"column"}>
                  <Box display={"flex"} flex={1} mr="0.5em" marginTop={2} marginBottom={1}>
                    <Box flex={0.12} mr="0.10em">
                      <label className={classes.lblStyle}>MCXVideo Id</label>
                    </Box>
                    <Box flex={1} mr="1.0em" marginTop={-1}>
                      <Checkbox
                        style={{ color: "white" }}
                        size="medium"
                        checked={basicdetails && basicdetails.mcxVideoID}
                        onChange={mcxVideoIDEnableChangeHandler}
                      />
                    </Box>
                  </Box>
                  <Box flex={1} mr="0.5em">
                    <input
                      type="text"
                      placeholder="MCXData Id"
                      className="input-control-flat"
                      id="mcxVideoID"
                      value={basicdetails && basicdetails.mcxVideoIdText}
                      onChange={mcxVideoIDChangeHandler}
                      disabled={isMCXVideoIdDisabled}
                    />
                  </Box>
                </Box>
              )}

              {/* ---------------------------------ROLE TYPE & USER TYPE----------------------------------- */}
              <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={2} marginTop={1}>
                <Box flex={0.65} mr="0.5em">
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      className={classes.listItemFA}
                      focused={false}
                    >
                      Role Type
                    </InputLabel>
                    <Select
                      className={classes.listItemFA}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={selectedRoleType}
                      onChange={handleRoleTypeChange}
                      disableUnderline={true}
                    >
                      {RoleTypeOptions &&
                        RoleTypeOptions.map((roletype) => {
                          return (
                            <MenuItem value={roletype.value}>
                              {roletype.text}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Box>
                <Box flex={0.65} mr="0.5em" >
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      className={classes.listItemFA}
                      focused={false}
                    >
                      User Type
                    </InputLabel>
                    <Select
                      className={classes.listItemFA}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={selectedUserType}
                      onChange={handleUserTypeChange}
                      disableUnderline={true}
                    >
                      {basicdetails.TetraUser === true ? (UserTypeIwfOptions &&
                        UserTypeIwfOptions.map((usertype) => {
                          return (
                            <MenuItem value={usertype.value}>
                              {usertype.text}
                            </MenuItem>
                          );
                        })
                      ) : (UserTypeOptions &&
                        UserTypeOptions.map((usertype) => {
                          return (
                            <MenuItem value={usertype.value}>
                              {usertype.text}
                            </MenuItem>
                          );
                        }))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>

              {/* -----------------------------FA DROP DOWN--------------------------------------- */}
              {enableFA == true ?
                <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={2} marginTop={1} flexDirection={"column"}>
                  <Box flex={1.0} mr="1.0em" flexDirection={"row"}>
                    <FormControl variant="filled" className={classes.formControlFA}>
                      <InputLabel
                        id="demo-simple-select-filled-label"
                        className={classes.listItemFA}
                        focused={false}
                      >
                        Functional Alias
                      </InputLabel>
                      <Select
                        className={classes.listItemFA}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={vlFunctionalAlias}
                        onChange={functionalAliasChangeHandler}
                        disableUnderline={true}
                      >
                        {faList &&
                          faList.map((fatype) => {
                            return (
                              <MenuItem value={fatype.name}>
                                {fatype.CallerDescr}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                    <button
                      className="add-btn-device"
                      type="button"
                      onClick={OnAddFunctionalAlias}
                    >
                      Add FA
                    </button>
                  </Box>
                  <Box flex={1.0} mr="1.0em" marginTop={2}>
                    {/* {addedFA.length > 0 ? (
                      <DataGrid
                        rows={addedFA}
                        columns={faColumns}
                        rowHeight={65}
                        getRowId={(row) => row.CallerDescr}
                        hideFooter
                        hideFooterPagination
                        hideFooterSelectedRowCount
                        disableColumnMenu
                        getRowSpacing={params => ({
                          top: params.isFirstVisible ? 0 : 0,
                          bottom: params.isLastVisible ? 0 : 0
                        })}

                        sx={{
                          boxShadow: 2,
                          border: "none",
                          borderBottom: "none",
                          // display: "block",
                          '& .MuiDataGrid-cell:hover': {
                            color: '#ffb01f',
                          },
                          '& .MuiDataGrid': {
                            border: 'none',
                          },
                          '& .MuiDataGrid-cell': {
                            borderColor: "#404040",
                          },
                          '& .MuiDataGrid-columnHeader': {
                            borderColor: "gray",
                          },
                          "& .MuiDataGrid-sortIcon": {
                            opacity: 1,
                            color: "white",
                          },
                          "& .MuiDataGrid-menuIconButton": {
                            opacity: 1,
                            color: "white"
                          },
                          '& .MuiDataGrid-filler': {
                            backgroundColor: "#262626",
                          },
                        }}
                      />
                    ) : null} */}
                    <List style={{ width: "100%" }}>
                      {addedFA.map((fa) => {
                        return (
                          <ListItem className="add-fa-list" key={fa.profileName}>
                            <ListItemText
                              className={classes.listItemFAUE}
                              primary={fa.CallerDescr}
                            />
                            {fullyIWF ? null : (
                              <FormControl variant="filled" className={classes.formControlFAUE}>
                                <InputLabel
                                  id="demo-simple-select-filled-label"
                                  className={classes.listItemFA}
                                  focused={false}
                                >
                                  UE
                                </InputLabel>
                                <Select
                                  className={classes.selectFAUE}
                                  labelId="demo-simple-select-filled-label"
                                  id="demo-simple-select-filled"
                                  value={fa.ueType}
                                  onChange={(e) =>
                                    handleFAUETypeChangeHandler(e, fa)
                                  }
                                  disableUnderline={true}
                                >
                                  {UETypeOptions &&
                                    UETypeOptions.map((uetype) => {
                                      return (
                                        <MenuItem value={uetype.value}>
                                          {uetype.text}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            )}
                            <Checkbox
                              color="primary"
                              edge="start"
                              style={{ color: "white" }}
                              onChange={() => makeDefaultFA(fa)}
                              checked={fa.default}
                            />
                            <button
                              class="editBtn"
                              onClick={() => deleteFAlias(fa)}
                              type="button"
                              name=""
                            >
                              <img
                                src="/assets/images/deleteimg.svg"
                                class="delete-user-img-fa"
                                alt=""
                              />
                            </button>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                </Box>
                : null}

              {/* -----------------------------CAD CALL--------------------------------------- */}
              <Box display={"flex"} width={"70%"} marginLeft={1} marginBottom={1} marginTop={1}>
                  <Box flex={0.75} mr="0.05em" marginTop={1.75} marginBottom={1}>
                    <label className={classes.lblStyle}>{"Allow Private Call Participation"}</label>
                  </Box>
                  <Box flex={1} mr="0.05em" marginTop={1} marginBottom={1}>
                    <FormControlLabel
                      value="start"
                      control={
                        <Switch
                          color="primary"
                          checked={basicdetails.cadCallData.allowPrivateCallParticipation}
                          onChange={allowPrivateCallChangeHandler}
                        />
                      }
                      labelPlacement="start"
                      onChange={allowPrivateCallChangeHandler}
                      // disabled={purpose === "edit" ? purpose : tetraDisable}
                    />
                  </Box>
                </Box>

              {enableCadCall === true ?
                <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={2} marginTop={1} flexDirection={"column"}>
                  <fieldset className="form-group call-forwading-container">
                    <legend>CAD CALL</legend>
                    <CADCallConfig
                      cadCall={
                        basicdetails && basicdetails.cadCallData
                          ? basicdetails.cadCallData
                          : null
                      }
                      updateDefaultAuthorizerErr={updateDefaultAuthorizerErr}
                      updateUserCadCallData={updateUserCadCallData}
                      faList={faList}
                      mcpttidAndUri={mcpttidAndUri}
                      emptyDefaultAuthorizer={emptyDefaultAuthorizer}
                      ueType={cadConfigUEType}
                    />
                  </fieldset>
                </Box>
                : null}

              {/* -----------------------------Call Forwarding--------------------------------------- */}

              {basicdetails
                &&
                enableCallForwarding === true
                ? (
                  <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={2} marginTop={1} flexDirection={"column"}>
                    <CallForwardingConfig
                      callForwardData={basicdetails.callForwardingData}
                      updateCallForwardingData={updateCallForwardingData}
                      newOrUpdateProfile={newOrUpdateProfile}
                      userName={basicdetails.userName}
                      mcpttidAndUri={mcpttidAndUri}
                      updateCallForwardTargetErr={updateCallForwardTargetErr}
                    />
                  </Box>
                ) : null}

              {/* ---------------------------- Permissions --------------------------------------- */}
              {
                enablePermission ?
                  <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={1} marginTop={1} flexDirection={"column"}>
                    <Box flex={0.15} mr="0.5em" marginTop={1} marginBottom={1}>
                      <label className={classes.lblStyle}>Permissions</label>
                    </Box>
                    <Box flex={1} mr="0.5em">
                      <UserPermissions
                        permissions={
                          basicdetails && basicdetails.permissions
                            ? basicdetails.permissions
                            : null
                        }
                        updateUserPermissions={updateUserPermissions}
                      ></UserPermissions>
                    </Box>
                  </Box>
                  : null
              }

              {/* ---------------------------- Location --------------------------------------- */}
              {locService ?
                <Box display={"flex"} width={"50%"} marginLeft={1} marginBottom={2} marginTop={2} flexDirection={"column"}>
                  <UserLocation locData={{
                    "locationEnable": basicdetails.location, "locationHour": basicdetails.hour,
                    "locationMin": basicdetails.min, "locationSec": basicdetails.sec
                  }}
                    uplodatedLoc={UpdatedLoc}></UserLocation>
                </Box> : null}

              {/* ---------------------------- Org --------------------------------------- */}
              <Box display={"flex"} width={"50%"} marginLeft={1} marginBottom={2} marginTop={2} flexDirection={"column"}>
                <React.Fragment>
                  {/* <label className={classes.lblStyle}>Org</label> */}
                  <Autocomplete
                    id="auto-highlight"
                    autoHighlight={false}
                    // style={{ paddingLeft: 5}}
                    options={orgList ? orgList : []}
                    className={classes.paper}
                    PopperComponent={"top-start"}
                    value={
                      basicdetails && orgList
                        ? orgList.find((v) => v.orgId === basicdetails.orgId) || ""
                        : ""
                    }
                    renderOption={(option) => (
                      <Fragment>
                        {option.hasOwnProperty("orgName") ? option.orgName : ""}
                      </Fragment>
                    )}
                    getOptionLabel={(option) =>
                      option.hasOwnProperty("orgName") ? option.orgName : ""
                    }
                    renderInput={(params) => (
                      // <TextField {...params} label=" " className="input-control-flat" />
                      <TextField {...params} label="Org" variant="filled" InputLabelProps={{ className: "textFieldAutoComplete" }} style={{ fontFamily: "Muli" }} />
                    )}
                    onChange={(e, v) => {
                      if (v && v.hasOwnProperty("orgId") && v.orgId)
                        userOrgIdChangeHandler(v.orgId);
                    }}
                  />
                </React.Fragment>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      </div>
      {showSubmitBtn &&
        <Box display={"flex"} flexDirection={"row-reverse"} width={"100%"} marginRight={50}>
          <button
            className="update-btn-profile"
            type="button"
            onClick={UpdateBasicDetails}
            disabled={profileState.length > 0 ? true : false}
          >
            {profileState === "profile" ? "Updating" : profileState === "allocate" ? "Allocating" :
              newOrUpdateProfile ? "SUBMIT" : "UPDATE"}
          </button>
        </Box>
      }
    </div >
  );
};

const mapStateToProps = ({ adminstate, domains, enableCadCall, enableCallForwarding, mdm, mtmstate, productcatalogstate }) => {
  const { falist } = adminstate;
  const {
    userProfileWithAttachedFA,
    mcpttidIWFMap,
    iwfMaplist,
    mcpttidAndUri,
    userProfileCreatedId
  } = adminstate;
  const { serviceUeTypeConfig, availableServices, ueTypeList } = mtmstate;
  const { orglist } = adminstate;
  const { configDomain } = domains;
  const { configCadCall } = enableCadCall;
  const { configCallForwarding } = enableCallForwarding;
  const { currentActiveSubscriptionsForUser,
    allocateDeallocateSubscriptionsuccessIds } = productcatalogstate;
  //console.log('userlist reducer', userlist)
  return {
    falist,
    userProfileWithAttachedFA,
    orglist,
    configDomain,
    configCadCall,
    configCallForwarding,
    mcpttidIWFMap,
    iwfMaplist,
    mcpttidAndUri,
    deviceIdOption: mdm.deviceIdOption,
    deviceId: mdm.deviceId,
    serviceUeTypeConfig,
    availableServices,
    ueTypeList,
    currentActiveSubscriptionsForUser,
    allocateDeallocateSubscriptionsuccessIds,
    userProfileCreatedId
  };
};

export default connect(mapStateToProps, {
  fetchUserProfileWithAttachedFAS,
  updateUserProfileWithAttachedFAS,
  fetchOrgListAdmin,
  getIWFMapByMCPTTID,
  fetchMcpttIdAndUri,
  getIWFMapByFa,
  getDeviceIdList,
  updateDeviceByDevice,
  updateDeviceByUser,
  getDeviceId,
  setDeviceId,
  getAvailableServices,
  allocateUserFromTenantSubscription,
  getCurrentActiveSubscriptionsForUser,
})(BasicInfo);
