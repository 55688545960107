// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login .half {
  height: 100vh;
  width: 50%;
  float: left;
}
.login .left {
  background: #232322;
  text-align: center;
}
.login .left img {
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.login .right .ui.form {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 50%;
  margin: 0 auto;
}
.login .right .ui.form .heading {
  color: #4b9a4a;
  font-size: 24px;
  margin-bottom: 25px;
  font-weight: 700;
  font-style: italic;
}
.login .right .ui.form .field label {
  font-weight: initial;
  color: #888;
}
.login .right .ui.form button {
  background: #f09922;
  color: white;
}
.login .right .ui.form .forgot {
  text-align: center;
  display: block;
  margin: 15px;
}`, "",{"version":3,"sources":["webpack://./src/styles/login.scss","webpack://./src/styles/theme.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,UAAA;EACA,WAAA;AAFJ;AAIE;EACE,mBAAA;EACA,kBAAA;AAFJ;AAGI;EACE,cAAA;EACA,kBAAA;EACA,QAAA;EACA,2BAAA;AADN;AAKI;EACE,kBAAA;EACA,QAAA;EACA,2BAAA;EACA,UAAA;EACA,cAAA;AAHN;AAIM;EACE,cCvBQ;EDwBR,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;AAFR;AAKQ;EACE,oBAAA;EACA,WAAA;AAHV;AAMM;EACE,mBCjCC;EDkCD,YAAA;AAJR;AAMM;EACE,kBAAA;EACA,cAAA;EACA,YAAA;AAJR","sourcesContent":["@import \"theme\";\n\n.login{\n  .half{\n    height: 100vh;\n    width: 50%;\n    float: left;    \n  }\n  .left{\n    background: #232322;\n    text-align: center;\n    img{\n      margin: 0 auto;\n      position: relative;\n      top: 50%;\n      transform: translateY(-50%);\n    }\n  }\n  .right{\n    .ui.form{\n      position: relative;\n      top: 50%;\n      transform: translateY(-50%);\n      width: 50%;\n      margin: 0 auto;\n      .heading{\n        color: $heading-green;\n        font-size: 24px;\n        margin-bottom: 25px;\n        font-weight: 700;\n        font-style: italic;\n      }\n      .field{\n        label{\n          font-weight: initial;\n          color: #888;\n        }  \n      }\n      button{\n        background: $orange;\n        color: white;\n      }\n      .forgot{\n        text-align: center;\n        display: block;\n        margin: 15px;\n      }\n    }\n  }\n}","$active-primary: #4a90e2;\n$orange: #f1992b;\n$marker-color: #68b128;\n$heading-green: #4b9a4a;\n$dark-grey: #484848;\n\n$orange: #f09922;\n$green: #56af22;\n$red: #ea4f4b;\n$blue: #4a90e2;\n$light-black: #1b1c1d;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
