import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid } from '@mui/material';

const ProductType = (props) => {
    const { productJsonHandler, openMode } = props;
    let planDetailData = {
        fromDays: 0,
        toDays: 0,
        validDays: 0,
        ueTypes: [],
        services: []
    }

    const [plandetails, setplandetails] = useState(planDetailData);

    useEffect(() => {
        if (props.planJsonData) {
            console.log("planJsonData productType ", props.planJsonData);
            setplandetails(props.planJsonData);
        }
    }, [])

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '92%',
            paddingTop: 10,
        },
        formControlFA: {
            width: "80%",
            height: "65px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600,
            backgroundColor: "#383736",
        },
        listItemFA: {
            padding: "5px",
            fontFamily: "Muli",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        tetraSwitch: {
            fontFamily: "Muli",
            marginTop: "2px",
            marginRight: "1px",
            marginLeft: "0px",
        },
        divList: {
            width: '100%',
            marginBottom: '1px',
        },
        lblStyle: {
            width: '100%',
            color: "#D0D3D4",
            fontSize: 18,
            fontWeight: 600
        },

    }));

    const fromDaysChangeHandler = (e) => {
        setplandetails({
            ...plandetails,
            fromDays: e.target.value,
        });
        let plData = { ...plandetails, fromDays: e.target.value };
        productJsonHandler(plData);
    };

    const toDaysChangeHandler = (e) => {
        setplandetails({
            ...plandetails,
            toDays: e.target.value,
        });
        let plData = { ...plandetails, toDays: e.target.value };
        productJsonHandler(plData);
    };

    const validDaysChangeHandler = (e) => {
        setplandetails({
            ...plandetails,
            validDays: e.target.value,
        });
        let plData = { ...plandetails, validDays: e.target.value };
        productJsonHandler(plData);
        console.log("productJsonHandler plData validDays", plData);
    };

    const getInputClassName = () => {
        if (openMode === "view") {
            return "input-control-flat-read";
        }
        return "input-control-flat";
    }

    const isViewMode = () => {
        if (openMode === "view") {
            return true;
        }
        return false;
    }

    let classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container width="100%" spacing={2}>
                <Grid item xs={12}>
                    {/* ---------------- From & To Days --------------------- */}
                    <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={3}>
                        <Box flex={1} mr="0.5em">
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className="lblRequired">From Days</label>
                            </Box>
                            <Box flex={1} mr="0.5em">
                                <input
                                    type="number"
                                    className={getInputClassName()}
                                    id="price"
                                    value={plandetails && plandetails.fromDays}
                                    onChange={fromDaysChangeHandler}
                                    readOnly={isViewMode()}
                                />
                            </Box>
                        </Box>
                        <Box flex={1} mr="0.5em">
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className="lblRequired">To Days</label>
                            </Box>
                            <Box flex={1} mr="0.5em">
                                <input
                                    type="number"
                                    className={getInputClassName()}
                                    id="toDays"
                                    value={plandetails && plandetails.toDays}
                                    onChange={toDaysChangeHandler}
                                    readOnly={isViewMode()}
                                />
                            </Box>
                        </Box>
                        <Box flex={1} mr="0.5em">
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className="lblRequired">Valid Days</label>
                            </Box>
                            <Box flex={1} mr="0.5em">
                                <input
                                    type="number"
                                    className={getInputClassName()}
                                    id="validDays"
                                    value={plandetails && plandetails.validDays}
                                    onChange={validDaysChangeHandler}
                                    readOnly={isViewMode()}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}

export default ProductType;

/*
{ name: “”, numSubscribers: “”. maxConnectionsPerSubscriber: “”. planType: “”, numDays: “”, 
    validUntil: “”,  deviceTypes: [...], services: [..] }

*/