import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { connect } from "react-redux";
import { getAvailableServices } from "../modules/mtmstate";
import MCXServices from "./mcxservices";
import { Box, Grid } from '@mui/material';

const UETypeAndServices = (props) => {
    const { ueTypeList, getAvailableServices, availableServices, serviceUeTypeConfig, openMode } = props;
    let planDetailData = {
        fromDays: 0,
        toDays: 0,
        validDays: 0,
        ueTypes: [],
        services: []
    }

    const [plandetails, setplandetails] = useState(planDetailData);
    const [deviceTypeList, setDeviceTypeList] = useState([]);
    const [addedDevices, setAddedDevices] = useState([]);
    const [vlDeviceType, setvlDeviceType] = useState("");
    const [allSelectedServices, setAllSelectedServices] = useState([]);

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '92%',
            paddingTop: 10,
        },
        formControlFA: {
            width: "80%",
            height: "65px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600,
            backgroundColor: "#383736",
        },
        listItemFA: {
            padding: "5px",
            fontFamily: "Muli",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        tetraSwitch: {
            fontFamily: "Muli",
            marginTop: "2px",
            marginRight: "1px",
            marginLeft: "0px",
        },
        divList: {
            width: '100%',
            marginBottom: '1px',
        },
        lblStyle: {
            width: '100%',
            color: "#D0D3D4",
            fontSize: 18,
            fontWeight: 600
        },

    }));

    useEffect(() => {
        if (props.planJsonData) {
            console.log("planJsonData ", props.planJsonData);
            setplandetails(props.planJsonData);
            if (props.planJsonData && props.planJsonData.services) {
                setAllSelectedServices(props.planJsonData.services);
            }
            if (props.planJsonData.ueTypes) {
                setAddedDevices(props.planJsonData.ueTypes);
            }
        }
    }, [])

    useEffect(() => {
        console.log("ueTypeList ", ueTypeList);
        setDeviceTypeList(ueTypeList);
        fetchAvailableServices(ueTypeList);
    }, [ueTypeList])

    useEffect(() => {
        console.log("availableServices ", availableServices);
    }, [availableServices])

    const deviceTypesChangeHandler = (e) => {
        setvlDeviceType(e.target.value);
    };

    const OnAddDeviceType = () => {
        let checkAlreadyAddedDevice = addedDevices.filter(
            (device) => device === vlDeviceType
        );
        if (checkAlreadyAddedDevice.length === 0) {
            let deviceData = deviceTypeList.filter((device) => device === vlDeviceType);
            if (deviceData && deviceData.length > 0) {
                let element = deviceData[0];
                let devices = [...addedDevices, element];
                setAddedDevices(devices);
                fetchAvailableServices(devices);
                setplandetails({
                    ...plandetails,
                    ueTypes: devices,
                });
                let plData = { ...plandetails, ueTypes: devices };
                props.productJsonHandler(plData);
            }
        }
    };

    const deleteDeviceType = (deviceType) => {
        let deleteDevices = addedDevices.filter((device) => device !== deviceType);
        setAddedDevices(deleteDevices);
        fetchAvailableServices(deleteDevices);
    };

    const updatedServices = (services) => {
        setplandetails({
            ...plandetails,
            services: services,
        });
        let plData = { ...plandetails, services: services };
        props.productJsonHandler(plData);
    }

    const fetchAvailableServices = (devices) => {
        let ueTypeVal = new Set([]);
        for (let device of devices) {
            if (serviceUeTypeConfig && serviceUeTypeConfig[device]) {
                if (!ueTypeVal.has(serviceUeTypeConfig[device])) {
                    ueTypeVal.add(serviceUeTypeConfig[device])
                }
            }
        }
        let ueTypeValArr = Array.from(ueTypeVal);
        getAvailableServices({ ueType: ueTypeValArr, mtmId: global.config.userConfig.partnerId > 0 ? global.config.userConfig.partnerId : null })
    }

    const isViewMode = () => {
        if (openMode === "view") {
            return true;
        }
        return false;
    }

    let classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container width="100%" spacing={2}>
                <Grid item xs={12}>

                    <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={3}>
                        <Box flex={1} mr="0.5em" marginTop={4}>
                            <FormControl variant="filled" className={classes.formControlFA}>
                                <InputLabel
                                    id="demo-simple-select-filled-label"
                                    className={classes.listItemFA}
                                    focused={false}
                                >
                                    UE Types
                                </InputLabel>
                                <Select
                                    className={classes.listItemFA}
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={vlDeviceType}
                                    onChange={deviceTypesChangeHandler}
                                    readOnly={isViewMode()}
                                    disableUnderline={true}
                                >
                                    {deviceTypeList &&
                                        deviceTypeList.map((devicetype) => {
                                            return (
                                                <MenuItem value={devicetype} key={devicetype}>
                                                    {devicetype}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                            <button
                                className="add-btn-device"
                                type="button"
                                onClick={OnAddDeviceType}
                                disabled={isViewMode()}
                            >
                                Add UE
                            </button>
                        </Box>
                    </Box>

                    {/* ---------------- Devices --------------------- */}
                    <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={3}>
                        <List style={{ width: "100%" }}>
                            {addedDevices.map((device) => {
                                return (
                                    <ListItem className="add-product-list" key={device}>
                                        <ListItemText
                                            className={classes.listItemFA}
                                            primary={device}
                                        />
                                        <button
                                            class="editBtn"
                                            onClick={() => deleteDeviceType(device)}
                                            type="button"
                                            name=""
                                            disabled={isViewMode()}
                                        >
                                            <img
                                                src="/assets/images/deleteimg.svg"
                                                class="delete-user-img-fa"
                                                alt=""
                                            />
                                        </button>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Box>

                    {/* ---------------- Service Tree --------------------- */}
                    {
                        availableServices && availableServices.length > 0 && addedDevices && addedDevices.length > 0 ?
                            <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={3} flexDirection={"column"}>
                                <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                    <label className="lblRequired">Service</label>
                                </Box>
                                <Box flex={1} mr="0.5em">
                                    <MCXServices selectedUeTypes={addedDevices} selectedServices={allSelectedServices} allowedServices={availableServices} updatedServices={updatedServices} viewMode={isViewMode()}></MCXServices>
                                </Box>
                            </Box>
                            : null
                    }
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = ({ mtmstate }) => {
    const { ueTypeList, availableServices, serviceUeTypeConfig } = mtmstate;
    return {
        ueTypeList,
        availableServices,
        serviceUeTypeConfig
    };
};

export default connect(mapStateToProps, {
    getAvailableServices
})(UETypeAndServices);