(function (exports) {
  const DOMParser = require("xmldom").DOMParser;
  const Events = require("events").EventEmitter;
  const RequestSender = require("../jssip/RequestSender");
  const JsSIP = require("../jssip/JsSIP");
  const OutgoingRequest = require("../sipMethods/OutgoingRequest")
    .OutgoingRequest;
  const Transactions = require("../jssip/Transactions");
  const instance = require("axios").default;
  const js2xmlparser = require("js2xmlparser");
  const stateType = require("../StateType");

  class CMCsubscriber extends Events {
    constructor(userAgent) {
      super();

      this.UA = userAgent;
      this.instance_id = this.UA.instance_id;
      this.contact = this.UA._registrator._contact;
      this.expires = "4294967295";

      this.parser = new DOMParser();

      this.subscriptions = new Map();
      this.dialogStateMap = new Map([
        ["terminated", "AVAILABLE"],
        ["early", "RINGING"],
        ["confirmed", "BUSY"],
        ["trying", "BUSY"],
      ]);
      this.root = "";
      this.cmsPath = "";
    }

    handleNotify(request) {
      console.log("XCAPDIFF Notify ======> ");
      if (Transactions.checkTransaction(this.UA, request)) {
        console.log('xcap-diff transaction issue....')
        request.reply(200);
        return;
      }

      new Transactions.NonInviteServerTransaction(
        this.UA,
        this.UA.transport,
        request
      );

      if (!request.body) {
        console.log('xcap-diff not found body..')
        request.reply(200);
        return;
      }

      try {
        // Grab the state and version from the parsed body
        console.log('xcap-diff try body..');
        const version = 1;
        const state = "active";
        let xml = this.parser.parseFromString(request.body);
        let root = xml.getElementsByTagName("xcap-diff").item(0).attributes[1]
          .value;
        //let path = xml.getElementsByTagName("document").item(0).attributes[0].value;
        let cmsPath = xml.getElementsByTagName("document").item(1).attributes[0]
          .value;
        // let cmsServiceConfig = xml.getElementsByTagName("document").item(2)
        //   .attributes[0].value;

        //console.log("subscriber_userProfile...", path, "/n/n", path1, "/n/n", path2);
        console.log("subscriber userProfile", root + "/" + cmsPath);
        //console.log("subscriber service Config ", root + "/" + cmsServiceConfig);
        this.root = root;
        this.cmsPath = cmsPath;
        request.reply(200);
        this.UA.updateRegistrationProcess("XCAP", stateType.XCAP_STATE.Notified);

        // Find the subscription
        const subscription = this.getSubscription(request);
        if (subscription) {
          if (version > subscription.version) {
            this.updateVersion(request, "1");
            this.emitUserStateChanged(subscription.toUser, state);
          }
          return request.body;
        }
      } catch (err) {
        request.reply(200);
        console.log("xcap-diff Error parsing xml: ", request);
        this.UA.updateRegistrationProcess("XCAP", stateType.XCAP_STATE.None, {
          cause: JsSIP.C.causes.CONNECTION_ERROR,
        });
      }
    }

    fetchCmsProfile() {
      this.UA.updateRegistrationProcess("XCAP", stateType.XCAP_STATE.Fetching);
      const _this = this;
      instance
        .get(`${this.root}/${this.cmsPath}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            "version": this.UA.getVersion(),
            "authorization": `Bearer ${_this.UA.getActiveToken()}`
          },
          timeout: this.UA.getHttpClientTimeout()
        })
        .then((res) => {
          console.log("got userProfile");
          _this.UA.updateRegistrationProcess("XCAP", stateType.XCAP_STATE.Fetched);
          _this.emit("userProfileXCAP", res);
        })
        .catch((err) => {
          console.log("Could not get Profile", err);
          if (err) {
            _this.UA.updateRegistrationProcess("XCAP", stateType.XCAP_STATE.None, {
              cause: JsSIP.C.causes.CONNECTION_ERROR,
            });
          } else {
            console.log("profile is not found");
            _this.UA.updateRegistrationProcess("XCAP", stateType.XCAP_STATE.None, {
              cause: JsSIP.C.causes.CONNECTION_ERROR,
            });
          }
        });
    }

    subscribe() {
      try {
        console.log("xcap-diff event subscription started..");
        this.UA.updateRegistrationProcess("XCAP", stateType.XCAP_STATE.Subscribing);
        let ruri = this.UA.config.cmsURI ? this.UA.config.cmsURI : 'sip:cms-consort.ims.mnc001.mcc001.3gppnetwork.org'
        ruri = ruri.includes('sip') ? ruri : `sip:${ruri}`
        const from = this.UA._registrator._from_uri;
        const extraHeaders = [
          `Contact: ${this.contact}`,
          `Expires: ${this.expires}`,
          "Event: xcap-diff",
          "P-Preferred-Service: urn:urn-7:3gpp-service.ims.icsi.mcptt",
          `P-Preferred-Identity: <${from}>`,
          "content-type: multipart/mixed; boundary=boundaryMCPTT",
        ];
        const options = {
          to_uri: ruri,
          from_uri: from,
          call_id: JsSIP.Utils.createRandomToken(22),
          cseq: 1,
          from_tag: "",
        };
        // console.log("ExtraHeaders:  ", extraHeaders.length);
        let subBody = this.getSubscriberBody();
        // console.log("xcap-diff body:  ", subBody);
        const request = new OutgoingRequest(
          JsSIP.C.SUBSCRIBE,
          ruri,
          this.UA,
          options,
          extraHeaders,
          subBody
        );

        const eventHandlers = {
          onReceiveResponse: (response) => {
            console.log('Response XCAPDIFF ==== :');
            if (response && response.status_code >= 400) {
              console.log("Response XCAPDIFF ==== : ", response.status_code);
              this.UA.updateRegistrationProcess("XCAP", stateType.XCAP_STATE.None, {
                cause: JsSIP.C.causes.SIP_FAILURE_CODE,
                response: response.status_code
              });
            } else {
              this.UA.updateRegistrationProcess("XCAP", stateType.XCAP_STATE.Subscribed);
            }
          },
          onRequestTimeout: () => {
            console.log("xcap-diff Timeout");
            this.UA.updateRegistrationProcess("XCAP", stateType.XCAP_STATE.None, {
              cause: JsSIP.C.causes.REQUEST_TIMEOUT,
            });
          },
          onTransportError: () => {
            console.log("xcap-diff TransportError");
            this.UA.updateRegistrationProcess("XCAP", stateType.XCAP_STATE.None, {
              cause: JsSIP.C.causes.CONNECTION_ERROR,
            });
          }
        };
        const requestSender = new RequestSender(
          this.UA,
          request,
          eventHandlers
        );
        requestSender.send();
      } catch (error) {
        console.log("xcap-diff subscribee error.............", error);
        this.UA.updateRegistrationProcess("XCAP", stateType.XCAP_STATE.None, {
          cause: JsSIP.C.causes.CONNECTION_ERROR,
        });
      }
    }

    updateSubscriptionState(request) {
      if (!request.headers["Subscription-State"]) return;

      const subscriptionStateHeader =
        request.headers["Subscription-State"][0].raw;
      const splitHeaders = subscriptionStateHeader.split(";");

      const state = splitHeaders[0];
      const secToExpire = splitHeaders
        .find((x) => x.includes("expires"))
        .split("=")[1];

      const msToExpire = secToExpire * 1000;

      const subscription = this.getSubscription(request);
      if (subscription) {
        clearTimeout(subscription.expireTimeout);

        this.subscriptions.set(request.call_id, {
          ...subscription,
          subscriptionState: state,
          expires:
            secToExpire === undefined
              ? subscription.expires
              : Date.now() + msToExpire,
          expireTimeout: setTimeout(() => {
            this.expireSubscription(request);
          }, msToExpire),
        });
      }
    }

    getSubscription(message) {
      return this.subscriptions.get(message.call_id);
    }

    expireSubscription(response) {
      const callId = response.call_id;
      const subscription = this.getSubscription(response);
      if (subscription) {
        this.subscriptions.set(callId, {
          ...subscription,
          subscriptionState: "expired",
        });
      }
    }

    updateVersion(request, version) {
      const subscription = this.getSubscription(request);
      if (subscription) {
        this.subscriptions.set(request.call_id, {
          ...subscription,
          version,
        });
      }
    }

    emitUserStateChanged(user, state) {
      const normalizedState = state.toLowerCase();

      // Get a more readable state
      const readableState =
        this.dialogStateMap.get(normalizedState) || "UNKNOWN";

      this.emit("userStateChanged", user, readableState);
    }

    getSubscriberBody() {
      const registerBody = this.createMcpttInfoXml(this.UA.getActiveToken());
      const resourceListBody = `${this.getResourceListBody()}`;
      const body = `--boundaryMCPTT\r\nContent-Type: application/vnd.3gpp.mcptt-info+xml\r\n\r\n${registerBody}\r\n\r\n--boundaryMCPTT\r\nContent-Type: application/resource-lists+xml\r\n\r\n${resourceListBody}\r\n--boundaryMCPTT--`;
      return body;
    }

    getResourceListBody() {
      return `<?xml version="1.0" encoding="UTF-8"?>
              <resource-lists xmlns="urn:ietf:params:xml:ns:resource-lists">
                <list>
                <entry uri="org.3gpp.mcptt.ue-config/users/${this.UA.mcptt_uri}/"/>
                <entry uri="org.3gpp.mcptt.user-profile/users/${this.UA.mcptt_uri}/"/>
                <entry uri="org.3gpp.mcptt.service-config/global/"/>
                </list>
              </resource-lists>`;
    }

    createMcpttInfoXml(token) {
      var info = mcpttInfo;
      info["mcptt-Params"]["mcptt-access-token"]["@"].type = "Normal";
      info["mcptt-Params"]["mcptt-access-token"].mcpttString = token;
      return js2xmlparser.parse("mcpttinfo", info);
    }
  }

  const mcpttInfo = {
    "@": {
      xmlns: "urn:3gpp:ns:mcpttInfo:1.0",
    },
    "mcptt-Params": {
      "mcptt-access-token": {
        "@": {
          type: "",
        },
        mcpttString: "",
      },
    },
  };

  exports.CMCsubscriber = CMCsubscriber;
})(typeof exports === "undefined" ? (this["CMCsubscriber"] = {}) : exports);
