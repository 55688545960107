import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles, createTheme } from "@material-ui/core/styles";
import { getAvailableServices } from "../../modules/mtmstate";
import MCXServices from "../../planManagement/mcxservices";
import { Box, Grid, Typography } from '@mui/material';

const AddParent = (props) => {
    const { ueTypeList, getAvailableServices, availableServices, serviceUeTypeConfig } = props

    const [partnerName, setPartnerName] = useState('');
    const [partnerDomain, setPartnerDomain] = useState('');
    const [partnerAddress, setPartnerAddress] = useState('');
    const [parentPartnerId, setParentPartnerId] = useState(global.config.userConfig.partnerId);
    const [partnerEmail, setPartnerEmail] = useState('');
    const [partnerContactNumber, setPartnerContactNumber] = useState('');
    const [addedDevices, setAddedDevices] = useState([]);
    const [vlDeviceType, setvlDeviceType] = useState([]);
    const [deviceTypeList, setDeviceTypeList] = useState([]);
    const [allSelectedServices, setAllSelectedServices] = useState([]);
    const [disableDelete, setDisableDelete] = useState(false);
    const [error, setError] = useState(false)


    const useStyles = makeStyles((theme) => ({
        // root: {
        //     backgroundColor: theme.palette.background.paper,
        //     width: 800,
        // },
        // formControlIwfType: {
        //     width: "80%",
        //     height: "100px",
        //     marginTop: "0px",
        // },
        // iwfInfoList: {
        //     padding: "5px",
        //     fontFamily: "Muli",
        // },
        // listItemFA: {
        //     padding: "2px",
        //     fontFamily: "Muli",
        // },
        // divList: {
        //     width: '100%',
        //     marginBottom: '1px',
        // },
        // formControlFA: {
        //     width: "80%",
        //     height: "60px",
        //     marginTop: "2px",
        // },
        root: {
            backgroundColor: "#252524",
            //backgroundColor: theme.palette.background.default,
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            width: "100%",
            //height: "70%",
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
        },
        formControlFA: {
            width: "60%",
            height: "65px",
            marginTop: "2px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600,
            backgroundColor: "#383736"
        },
        listItemFA: {
            padding: "5px",
            fontFamily: "Muli",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        productTypeClass: {
            width: "90%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            border: "solid",
            borderRadius: 10,
            borderWidth: "2px",
            borderColor: "#4B4A4A",
            marginTop: 5,
            marginBottom: 10
        },
        divList: {
            width: '100%',
            marginBottom: '13px',
        },
        lblStyle: {
            width: '100%',
            color: "#D0D3D4",
            fontSize: 18,
            fontWeight: 600
        },
        tetraSwitch: {
            fontFamily: "Muli",
            marginTop: "2px",
            marginRight: "1px",
            marginLeft: "0px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        datePickerStyle: {
            color: "black",
            padding: "10px",
            fontFamily: "Sans-Serif",
            width: '60%',
            border: "solid",
            borderRadius: 20,
            borderWidth: "2px",
            borderColor: "#D3D3D3",
        }

        // box {
        //         display: flex;
        //         flex-wrap: wrap; /* Optional. only if you want the items to wrap */
        //         justify-content: center; /* For horizontal alignment */
        //         align-items: center; /* For vertical alignment */
        // }

    }));
    const classes = useStyles();

    const theme = createTheme({
        palette: {
            primary: {
                main: "#006400",
            },
            secondary: {
                main: "#fdce2a",
            },
        },
    });

    useEffect(() => {
        console.log("Partner List---->>>>", props.partnerData)
        if (props.purpose === "edit") {
            setPartnerName(props.partnerData.partnerName);
            setPartnerDomain(props.partnerData.domainName);
            setPartnerAddress(props.partnerData.partnerAddress);
            setPartnerEmail(props.partnerData.partnerEmail);
            setPartnerContactNumber(props.partnerData.partnerContactNumber);
            setParentPartnerId(props.partnerData.parentPartnerId);
            if (props.partnerData.selectedUeTypes && props.partnerData.selectedUeTypes.length > 0) {
                setAddedDevices(props.partnerData.selectedUeTypes);
            } else {
                setAddedDevices([]);
            }
            setAllSelectedServices(props.partnerData.serviceLeafNodeList && props.partnerData.serviceLeafNodeList.length > 0 ? props.partnerData.serviceLeafNodeList : [])
        }
    }, [])

    useEffect(() => {
        console.log("ueTypeList partner ", ueTypeList);
        setDeviceTypeList(ueTypeList);
        fetchAvailableServices(ueTypeList);
    }, [ueTypeList])

    useEffect(() => {
        console.log("addPartner availableServices ", availableServices);
    }, [availableServices])

    const partnerNameChangeHandler = (e) => {
        setPartnerName(e.target.value)
    }
    const partnerDomainChangeHandler = (e) => {
        let regexResult = e.target.value.replace(/[@#\/\\& ]/g, "")
        setPartnerDomain(regexResult)
    }
    const partnerEmailChangeHandler = (e) => {
        setError(false)
        setPartnerEmail(e.target.value)
    }
    const partnerAddressChangeHandler = (e) => {
        setPartnerAddress(e.target.value)
    }
    const partnerContactChangeHandler = (e) => {
        setPartnerContactNumber(e.target.value)
    }
    const parentParentIdChangeHandler = (e) => {
        setParentPartnerId(e.target.value)
    }

    const deviceTypesChangeHandler = (e) => {
        setvlDeviceType(e.target.value);
    };

    const OnAddDeviceType = () => {
        let checkAlreadyAddedDevice = addedDevices.filter(
            (device) => device === vlDeviceType
        );
        if (checkAlreadyAddedDevice.length === 0) {
            let deviceData = deviceTypeList.filter((device) => device === vlDeviceType);
            if (deviceData && deviceData.length > 0) {
                // let element = Object.keys(deviceData[0])[0];
                let element = deviceData[0];
                let devices = [...addedDevices, element];
                setAddedDevices(devices);
                fetchAvailableServices(devices);
            }
        }
    };

    const deleteDeviceType = (deviceType) => {
        let deleteDevices = addedDevices.filter((device) => device !== deviceType);
        setAddedDevices(deleteDevices);
        fetchAvailableServices(deleteDevices);
    };

    const onSubmit = () => {
        if (partnerEmail.includes("@")) {
            props.createPartnerData([{
                data: {
                    partnerName: partnerName,
                    domainName: partnerDomain.toLowerCase(),
                    partnerEmail: partnerEmail,
                    partnerAddress: partnerAddress,
                    partnerContactNumber: partnerContactNumber,
                    parentPartnerId: parentPartnerId,
                    selectedUeTypes: addedDevices,
                    serviceLeafNodeList: allSelectedServices
                }
            }])
            props.hideModal()
        } else {
            setError(true)
        }
    }
    const onUpdate = () => {
        if (partnerEmail.includes("@")) {
            if (allSelectedServices && allSelectedServices.length > 0) {
                props.updatedPartnerData([{
                    data: {
                        partnerName: partnerName,
                        domainName: partnerDomain,
                        partnerEmail: partnerEmail,
                        partnerAddress: partnerAddress,
                        partnerContactNumber: partnerContactNumber,
                        parentPartnerId: parentPartnerId,
                        partnerId: props.partnerData.partnerId,
                        selectedUeTypes: addedDevices,
                        serviceLeafNodeList: allSelectedServices
                    }
                }])
                props.hideModal();
            } else {
                console.log("allSelectedServices is empty", allSelectedServices);
            }
        } else {
            setError(true)
        }
    }

    const updatedServices = (selectedServices) => {
        console.log("selectedServices ", selectedServices);
        setAllSelectedServices(selectedServices)
    }

    const fetchAvailableServices = (devices) => {
        let ueTypeVal = new Set([]);
        for (let device of devices) {
            if (serviceUeTypeConfig && serviceUeTypeConfig[device]) {
                if (!ueTypeVal.has(serviceUeTypeConfig[device])) {
                    ueTypeVal.add(serviceUeTypeConfig[device])
                }
            }
        }
        let ueTypeValArr = Array.from(ueTypeVal);
        if (props.partnerData && props.purpose === "edit") {
            getAvailableServices({ ueType: ueTypeValArr, mtmId: props.partnerData.parentPartnerId })
        } else {
            getAvailableServices({ ueType: ueTypeValArr, mtmId: parentPartnerId })
        }
    }

    return (
        <div className={classes.root}>
            <Grid container width="100%" spacing={2}>
                <Grid item xs={12}>
                    <Box className="tab1-account" width={'100%'} marginLeft={0}>
                        <Box class="form-group" paddingX={10} paddingY={0}>
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className={classes.lblStyle} >Name *</label>
                            </Box>
                            <input
                                type="text"
                                className="input-control-flat"
                                placeholder="Name"
                                id="firstName"
                                value={partnerName}
                                onChange={props.purpose === "edit" ? () => { } : partnerNameChangeHandler}
                            />
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0}>
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className={classes.lblStyle}>Domain *</label>
                            </Box>
                            <input
                                type="text"
                                className="input-control-flat"
                                placeholder="Domain"
                                id="firstName"
                                value={partnerDomain}
                                onChange={props.purpose === "edit" ? () => { } : partnerDomainChangeHandler}
                            />
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0}>
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className={classes.lblStyle}>Email</label>
                            </Box>
                            <input
                                type="email"
                                className="input-control-flat"
                                placeholder="Email"
                                id="firstName"
                                value={partnerEmail}
                                onChange={partnerEmailChangeHandler}
                            />
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0}>
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className={classes.lblStyle}>Contact Number</label>
                            </Box>
                            <input
                                type="number"
                                className="input-control-flat"
                                placeholder="Contact Number"
                                id="firstName"
                                value={partnerContactNumber}
                                onChange={partnerContactChangeHandler}
                            />
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0}>
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className={classes.lblStyle}>Address</label>
                            </Box>
                            <input
                                type="text"
                                className="input-control-flat"
                                placeholder="Address"
                                id="firstName"
                                value={partnerAddress}
                                onChange={partnerAddressChangeHandler}
                            />
                        </Box>
                        <Box className={classes.divList}>
                            <Box flexDirection={'row'} display={'flex'}>
                                <FormControl variant="filled" className={classes.formControlFA}>
                                    <InputLabel
                                        id="demo-simple-select-filled-label"
                                        className={classes.listItemFA}
                                        focused={false}
                                    >
                                        Device Types
                                    </InputLabel>
                                    <Select
                                        className={classes.listItemFA}
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={vlDeviceType}
                                        onChange={deviceTypesChangeHandler}
                                        disableUnderline={true}
                                    >
                                        {deviceTypeList &&
                                            deviceTypeList.map((devicetype, i) => {
                                                return (
                                                    <MenuItem value={devicetype} key={devicetype}>
                                                        {devicetype}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                                <button
                                    className="add-btn-device"
                                    type="button"
                                    onClick={OnAddDeviceType}
                                >
                                    Add Device
                                </button>
                            </Box>
                            <Box width={'93%'}>
                                <List style={{ width: "100%" }}>
                                    {addedDevices.length > 0 && addedDevices.map((device, i) => {
                                        return (
                                            <ListItem className="add-product-list" key={i}>
                                                <ListItemText
                                                    className={classes.listItemFA}
                                                    primary={device}
                                                />
                                                <button
                                                    disabled={disableDelete}
                                                    class="editBtn"
                                                    onClick={() => deleteDeviceType(device)}
                                                    type="button"
                                                    name=""
                                                >
                                                    <img
                                                        src="/assets/images/deleteimg.svg"
                                                        class="delete-user-img-fa"
                                                        alt=""
                                                    />
                                                </button>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Box>
                        </Box>
                        {
                            availableServices && availableServices.length > 0 ?
                                <MCXServices selectedUeTypes={addedDevices} selectedServices={allSelectedServices} allowedServices={availableServices} updatedServices={updatedServices}></MCXServices>
                                : null
                        }
                    </Box>
                    {error &&
                        <p class="error-handling-lbl">Email should contain @</p>
                    }
                    <Box display={"flex"} flexDirection={"row-reverse"} width={"100%"}>
                        {partnerName && partnerDomain &&
                            <div className="form-group">
                                <button
                                    class="update-btn-profile"
                                    type="button"
                                    onClick={props.purpose === "edit" ? onUpdate : onSubmit}
                                >
                                    {props.purpose === "edit" ? "UPDATE" : "SUBMIT"}
                                </button>
                            </div>}
                    </Box>
                </Grid>
            </Grid>
        </div>
    )

}

const mapStateToProps = ({ mtmstate }) => {
    const { ueTypeList, availableServices, serviceUeTypeConfig } = mtmstate;
    return {
        ueTypeList,
        availableServices,
        serviceUeTypeConfig
    };
};

export default connect(mapStateToProps, {
    getAvailableServices
})(AddParent);