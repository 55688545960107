import React, { useState, useEffect } from 'react'
import { Title } from "../commom/Title";
import CallRecordTableRow from './CallRecordTableRow';
import { Modal as ModalBootStrap } from "react-bootstrap";
import { Grid, makeStyles, Typography, Button } from '@material-ui/core';
import CallRecordInfoTab from './CallRecordInfoTab';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { DataGrid } from "@mui/x-data-grid";
import { changeBoolValue, showCallType, showDuration, showTime } from './definedFunction';
import './player.css'
// import ArchiveRecordings from './ArchiveRecordings';
import InfiniteScroll from "react-infinite-scroll-component";
import {
    getCallRecord,
    setPage,
    // setTotalPage, setHasMore, setLoader, setShowingNextData 
} from "../../modules/actions/playerAction";
import ArchiveRecordings from './ArchiveRecordings';

const CallRecordTable = (props) => {
    const { callRecord, pagination, callRecordReqObj,
        // currentPage, 
        // totalPage, hasMore, loading, showingNextData
    } = props;
    const [filtered, setFiltered] = useState({});
    const [lastfiltered, setLastFiltered] = useState();
    const [isSearch, setSearched] = useState(false);
    const [isCallInfoModalOpen, setIsCallInfoModalOpen] = useState(false);
    const [isCallRecordSelected, setCallRecordSelected] = useState();

    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
    const [currentPage, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);

    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoader] = useState(false);
    const [showingNextData, setShowingNextData] = useState(false);
    const totalPageSize = 20;

    const useStyles = makeStyles((theme) => ({
        root: {
            //backgroundColor: theme.palette.background.paper,
            // paddingTop: 10,
            // paddingLeft: 20,
            // paddingRight: 20,
            width: "100%",
            //height: "70%",
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
        },
        mtmTypeClass: {
            width: "70%",
            height: 800,
            // display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            border: "solid",
            borderRadius: 10,
            borderWidth: "1px",
            borderColor: "#4B4A4A",
            marginLeft: 50,
            //marginTop: 5,
            marginBottom: 10,
            marginTop: 20,
            overflowY: "scroll",
        },
        divList: {
            width: '100%',
            marginBottom: '13px',
        },

    }));

    const classes = useStyles();

    const columns = [
        {
            field: 'callerName', headerName: 'Caller', width: 162, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
            valueGetter: (value, row) => `${getCallerName(row)}`,
        },
        {
            field: 'calleeName', headerName: 'Callee', width: 162, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
            valueGetter: (value, row) => `${getCalleeName(row)}`,
        },
        {
            field: 'groupName', headerName: 'Group', width: 162, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center",
            valueGetter: (value, row) => `${getGroupName(row)}`,
        },
        {
            field: 'startTime', headerName: 'Start Time', width: 162, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center", valueGetter: (value, row) => `${showTime(row.recordStartTime)}`,
        },
        {
            field: 'endTime', headerName: 'End Time', width: 162, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center", valueGetter: (value, row) => `${showTime(row.recordEndTime)}`,
        },
        {
            field: 'callDuration', headerName: 'Call Duration', width: 162, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center", valueGetter: (value, row) => `${showDuration(row.recordStartTime, row.recordEndTime)}`
        },
        {
            field: 'callType', headerName: 'Call Type', width: 182, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center", valueGetter: (value, row) => `${showCallType(row.callType)}`
        },
        {
            field: 'answerMode', headerName: 'Answer Mode', width: 162, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center",
        },
        {
            field: 'floorControl', headerName: 'Floor Control', width: 162, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center", valueGetter: (value, row) => `${changeBoolValue(row.floorControl)}`
        },
    ];

    useEffect(() => {
        if (!isSearch) {
            setFiltered(callRecord);
            setLastFiltered(callRecord);
            setLoader(false)
            if (pagination && pagination.totalPages && pagination.currentPage) {
                setTotalPage(pagination.totalPages)
                if (pagination.totalPages != pagination.currentPage)
                    setHasMore(true);
            }
            setPage(pagination.currentPage);
            setShowingNextData(false);
        }
        console.log("call record data :", currentPage);
        console.log("Search Call Record", callRecord)
    }, [callRecord])


    const searchRecord = (searchRecord) => {
        let filterRecord;
        const search = searchRecord.toLowerCase();
        if (search) {
            filterRecord = lastfiltered.filter(record =>
                (record.callID && record.callID.toLowerCase().includes(search)) ||
                (record.calleeDisplayName && record.calleeDisplayName.toString().toLowerCase().includes(search)) ||
                (record.callerDisplayName && record.callerDisplayName.toString().toLowerCase().includes(search)) ||
                (record.displayGroupName && record.displayGroupName.toLowerCase().includes(search))
            )
            setFiltered(filterRecord);
            setSearched(true);
        }
        else {
            setFiltered(lastfiltered);
            setSearched(false);
        }
    }

    const userFilterModalhandleClose = () => {
        setIsCallInfoModalOpen(false);
        setCallRecordSelected(null);
    };

    const handleShowModal = (data) => {
        console.log("handle show modal----", data.row);
        setIsCallInfoModalOpen(true);
        setCallRecordSelected(data.row);
    }


    const archiveModalhandleClose = () => {
        setIsArchiveModalOpen(false);
    };
    const handleShowArchiveButton = () => {
        setIsArchiveModalOpen(true);
    }

    const getCallRecordList = (filterData) => {
        let params = callRecordReqObj;
        params.page = filterData.page;
        params.limit = totalPageSize;
        console.log("show button is clicked", params);
        props.getCallRecord(params);
    }

    const fetchPage = (page) => {
        let filterData = {
            page: page
        }
        console.log('fetchPage -->', page);
        setPage(page)
        setLoader(true);
        getCallRecordList(filterData)
    }

    const getMoreData = () => {
        console.log('getMoreData scroll..', currentPage, totalPage)
        if (currentPage === totalPage) {
            setHasMore(false);
            return;
        }
        setLoader(true)
        setShowingNextData(true)
        fetchPage(currentPage + 1)
    }

    const getCallerName = (data) => {
        if (data) {
            if (data.callerDisplayName)
                return data.callerDisplayName;
            if (data.callerMcpttId)
                return data.callerMcpttId;
        }
        return ""
    }
    const getCalleeName = (data) => {
        if (data) {
            if (data.calleeDisplayName)
                return data.calleeDisplayName;
            if (data.calleeMcpttIdURI)
                return data.calleeMcpttIdURI;
        }
        return ""
    }
    const getGroupName = (data) => {
        if (data) {
            if (data.displayGroupName)
                return data.displayGroupName.split('@')[0];
            if (data.groupIdUri)
                return data.groupIdUri.split('@')[0];
        }
        return ""
    }

    return (
        <div>
            <Grid container direction="row"
            >
                <Grid item xs={10}>
                    <Title title="Call Recordings" type="TD" search={searchRecord} />
                </Grid>
                <Grid item xs={2} style={{ display: "flex", justifyContent: "end" }} >
                    <Button style={{ backgroundColor: "#ffb01f", color: "black", width: "80%" }} variant="container" onClick={handleShowArchiveButton}>Show Archives</Button>
                </Grid>
            </Grid>
            <div className={classes.recordingTypeClass}>
                <DataGrid
                    rows={filtered ? filtered : []}
                    getRowId={(row) => row.id}
                    onRowClick={handleShowModal}
                    columns={columns}
                    rowHeight={68}
                    hideFooter
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    disableColumnMenu
                    localeText={{ noRowsLabel: "" }}
                    getRowSpacing={params => ({
                        top: params.isFirstVisible ? 0 : 0,
                        bottom: params.isLastVisible ? 0 : 0
                    })}
                    sx={{
                        boxShadow: 2,
                        border: "none",
                        borderBottom: "none",
                        '& .MuiDataGrid-cell:hover': {
                            color: '#ffb01f',
                        },
                        '& .MuiDataGrid': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-cell': {
                            borderColor: "#404040",
                        },
                        '& .MuiDataGrid-columnHeader': {
                            borderColor: "gray",
                        },
                        '& .MuiDataGrid-filler': {
                            backgroundColor: "#262626",
                        },
                    }}

                />
            </div>

            <ModalBootStrap
                show={isCallInfoModalOpen}
                onHide={userFilterModalhandleClose}
                scrollable={true}
                // size={"xl"}
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="modal-65w"
                centered
                style={{ backgroundColor: " rgba(0,0,0,0.5)" }}
                className="special_modal"
            >
                <ModalBootStrap.Header
                    closeButton
                    style={{ border: "0px", backgroundColor: "#282828" }}
                >
                    <ModalBootStrap.Title>Call Record Info</ModalBootStrap.Title>
                </ModalBootStrap.Header>
                <ModalBootStrap.Body
                    style={{
                        bgcolor: "background.paper",
                        boxShadow: 1,
                        border: 2,
                        p: 1,
                        padding: "8px",
                        margin: "2px",
                        marginLeft: "20px",
                        marginRight: "20px",
                        backgroundColor: "#121212",
                        borderRadius: "16px"
                    }}
                >
                    {isCallRecordSelected ? <CallRecordInfoTab data={isCallRecordSelected} /> : ""}
                </ModalBootStrap.Body>
            </ModalBootStrap>

            <ModalBootStrap
                show={isArchiveModalOpen}
                onHide={archiveModalhandleClose}
                scrollable={true}
                size={"lg"}
                aria-labelledby="contained-modal-title-vcenter"
                // dialogClassName="modal-65w"
                centered
                style={{ backgroundColor: " rgba(0,0,0,0.5)" }}
                className="special_modal"
            >
                <ModalBootStrap.Header
                    closeButton
                    style={{ border: "0px", backgroundColor: "#282828" }}
                >
                    <ModalBootStrap.Title>Archival Management</ModalBootStrap.Title>
                </ModalBootStrap.Header>
                <ModalBootStrap.Body
                    style={{
                        bgcolor: "background.paper",
                        minHeight: "45vh",
                        boxShadow: 1,
                        border: 2,
                        margin: "8px",
                        backgroundColor: "#121212",
                        borderRadius: "16px",
                        padding: "0px"
                    }}
                >
                    <ArchiveRecordings />
                </ModalBootStrap.Body>
            </ModalBootStrap>
        </div>
    )
}

const mapStateToProps = ({ player }) => {
    return {
        callRecord: player.callRecord,
        pagination: player.pagination,
        callRecordReqObj: player.callRecordReqObj,
        currentPage: player.currentPage,
        totalPage: player.totalPage,
        hasMore: player.hasMore,
        loading: player.loading,
        showingNextData: player.showingNextData
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getCallRecord,
            setPage,
            // setTotalPage, setHasMore, setLoader, setShowingNextData
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CallRecordTable);