import React, { useEffect } from "react";
import SearchCollapseTab from "./SearchCollapseTab";
import { connect } from "react-redux";
import { fetchDeviceDashboard } from "../../modules/actions/mdmAction";


export const TitleTab = (props) => {
  const {fetchDeviceDashboard,deviceDashBoard,tenantId,currentTab}=props;
    useEffect(()=>{
fetchDeviceDashboard({ tenantId,mtmId:global.config.userConfig.partnerId });
  },[])
  
  return (
    <div class={props.class ? props.class : ""}>
        <div class="title-grid-3">
          <div class="title" style={{display:"flex"}}>
            <div class="pt-6 pb-6 pr-6 pl-6 f-18 f-reg white">
              {props.title}
            </div>
            
          </div>
          {currentTab == "Device" && <div class="pt-6 pb-6 pr-6 pl-6 f-18 f-reg white" style={{display:"flex",marginLeft:"20px"}}>
        <span style={{marginLeft:"30px"}}>Total Devices:</span>
        <span style={{marginLeft:"10px"}}>{deviceDashBoard.totalDevices? deviceDashBoard.totalDevices:"0"}</span>
        <span style={{marginLeft:"30px"}}>Allocated:</span>
        <span style={{marginLeft:"10px"}}>{deviceDashBoard.allocatedDevice? deviceDashBoard.allocatedDevice:"0"}</span>
        <span style={{marginLeft:"30px"}}>UnAllocated:</span>
        <span style={{marginLeft:"10px"}}>{deviceDashBoard.unallocatedDevice?deviceDashBoard.unallocatedDevice:"0"}</span>
            </div>}
          <div class="search">
                <SearchCollapseTab
                  indvGrp={props.filtr}
                  deviceMngr={true}
            />
          </div>
        </div>
    </div>
  );
};
const mapStateToProps = ({ mdm }) => {
  return {
    currentTab: mdm.currentTab,
    deviceDashBoard:mdm.deviceDashBoard,
    tenantId: mdm.tenantId,
   
  };
};

export default connect(mapStateToProps,{fetchDeviceDashboard})(TitleTab);


