import React, { Component } from "react";
import { push } from "react-router-redux";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { SplashScreen } from '../../components/commom';
import { errorLogin, successLogin, fetchDialerPadOptions } from '../../modules/actions'
import { fetchContacts } from '../../modules/communication'
import { systemStatusReceived } from '../../modules/connection'
import { network_config } from "../../constants/constants";
import MainPage from "../home/mainPage";
import './login.css';
import { updateDomain } from '../../modules/domains';
import { updateSystemDgnaSSI } from '../../modules/systemdgnassi';
import { updateDGNA } from '../../modules/enableDgna';
import { updateAmbienceListening } from '../../modules/enableAmbienceListening'
import { updatePatchCall } from '../../modules/enablePatchCall';
import { updateMergeCall } from "../../modules/enableMergeCall";
import { updateCadCall } from '../../modules/enableCadCall'
import { updateCallForward } from "../../modules/enableCallForwarding";

let tabConfiguration = {
  Trains: true,
  Communication: true,
  Alerts: true,
  Admin: false,
  "Device Manager": false,
  Recordings: true,
  RadioBaseStation: false,
  TrainMovement: "none",
  Grab: "default",
}

class Login_page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company_name: "",
      name: "",
      displayName: '',
      password: "",
      auth: [
        { id: "ConsortDigital@India", password: "ConsortDigital" },
        { id: "Alstom@India", password: "AlstomIndia" },
        { id: "DMRC@India", password: "DMRCIndia" }
      ],

      seen: false,
      status: "",
      forgotPsd: false,
      loading: true,
      navigateToMain: false,
      selectedId: ""
    };
    this.requestRef = React.createRef();
    this.optionRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false })
    }, 2000);
    localStorage.setItem('deviceSet', false)
    localStorage.setItem('userDetails', JSON.stringify(''))
    this.props.mcxClient.on('LOGIN_COMPLETE', async data => {
      console.log('user login successfully', data)
      if (data.userData) {
        data.userData = { ...data.userData, UserName: this.state.name }
      }
      this.props.successLogin(data.userData, data.userProfile);
      if (data.userData && data.userData.config) {
        let configResp = data.userData.config;
        global.config.userConfig.tenantId = configResp.tenantId;
        global.config.userConfig.domains = configResp.domains;
        global.config.userConfig.domainName = data.userData.tenantDomain ? data.userData.tenantDomain : "";
        console.log("configResp... ", configResp, global.config.enableFACall, global.config.userConfig.domainName);
        this.props.updateDomain(configResp.domains);
        if (configResp.systemDgnaSSIs) {
          this.props.updateSystemDgnaSSI(configResp.systemDgnaSSIs);
        }
        this.props.updateDGNA(configResp.dgnaFeature);
        this.props.updateAmbienceListening(configResp.ambienceListeningFeature);
        this.props.updateCadCall(configResp.cadCallFeature);
        this.props.updatePatchCall(configResp.patchCallFeature);
        this.props.updateMergeCall(configResp.mergeCallFeature);
        this.props.updateCallForward(configResp.forwardingCallFeature);
        if (configResp.faCallFeature) {
          global.config.enableFACall = configResp.faCallFeature;
        } else {
          global.config.enableFACall = false;
        }
        if (configResp.autoCallPickConfig && configResp.autoCallPickConfig.Dispatcher) {
          let isActive = configResp.autoCallPickConfig.Dispatcher.isActive;
          let callPickTimer = configResp.autoCallPickConfig.Dispatcher.callPickTimer;
          global.config.auto_manual_call_enabled = isActive;
          global.config.auto_manual_call_timer_val = callPickTimer ? callPickTimer : 10;
        }
        //console.log("autoCallPickConfig..", global.config.auto_manual_call_enabled, global.config.auto_manual_call_timer_val);
      }

      if (data.userData.profile.Role) {
        if (data.userData.profile.Role === 'Admin') {
          //tabConfiguration = { ...tabConfiguration, Admin: true }
          tabConfiguration = {
            ...tabConfiguration, Trains: false,
            Communication: false,
            Alerts: false,
            Recordings: false,
            RadioBaseStation: false,
            TrainMovement: "none",
            Grab: "default",
          }
        } else {
          tabConfiguration = { ...tabConfiguration, Admin: false }
          let favConts = await this.props.mcxClient.getData('FAVS', data.userData.profile.mcptt_id)
          favConts = (favConts && favConts.length) ? favConts : []
          const contList = (data.contactlist && data.contactlist.length) ? data.contactlist : []
          this.props.fetchContacts({ favConts, contList });
        }
      }

      if (data.faConfig && data.faConfig.length > 0) {
        let faConfigure = data.faConfig[0]
        console.log('faObj faConfiguree ', faConfigure)
        let baseStation = faConfigure.permissions.baseStation
        let communicationTab = faConfigure.permissions.communicationTab
        let trainTab = faConfigure.permissions.trainTab
        let alertTab = faConfigure.permissions.alertTab
        let recordingsTab = faConfigure.permissions.recordingsTab
        let trainMovement = faConfigure.permissions.trainMovement
        let grabAllowed = faConfigure.permissions.grabAllowed

        for (let index = 1; index < data.faConfig.length; index++) {
          const element = data.faConfig[index];
          console.log('faObj faConfiguree element', element)
          baseStation = baseStation || element.permissions.baseStation
          communicationTab = communicationTab || element.permissions.communicationTab
          trainTab = trainTab || element.permissions.trainTab
          alertTab = alertTab || element.permissions.alertTab
          recordingsTab = recordingsTab || element.permissions.recordingsTab
          //all/default/none
          if (grabAllowed === 'none') {
            grabAllowed = element.permissions.grabAllowed
          } else if (grabAllowed === 'default') {
            if (element.grabAllowed === 'all') {
              grabAllowed = element.permissions.grabAllowed
            }
          }

          if (trainMovement === 'none') {
            trainMovement = element.permissions.trainMovement
          } else if (trainMovement === 'default') {
            if (element.trainMovement === 'all') {
              trainMovement = element.permissions.trainMovement
            }
          }
        }

        tabConfiguration = {
          ...tabConfiguration, Trains: trainTab,
          Communication: communicationTab,
          Alerts: alertTab,
          RadioBaseStation: baseStation,
          TrainMovement: trainMovement,
          Recordings: recordingsTab,
          Grab: grabAllowed,
        }
        let currentLA = ""
        if (tabConfiguration.Grab === "all") {
          currentLA = "ALLLA"
        } else if (faConfigure.description.length > 0) {
          currentLA = faConfigure.description.replace(/\s+/g, '').toLocaleUpperCase()
        }
        if (faConfigure.description.length > 0) {
          let faID = faConfigure.description.replace(/\s+/g, '').toLocaleUpperCase()
          global.config.faID = faID
        }
        global.config.currentLAId = currentLA
        console.log("Current LA", global.config.faID)
        if (data.activatedFA) {
          global.config.activatedFA = data.activatedFA;
          console.log('activatedFA...', global.config.activatedFA)
        }
        if (faConfigure) {
          /*
            alertTab: false
            baseStation: true
            communicationTab: false
            description: "Chief Controller"
            grabAllowed: "all"
            trainMovement: "all"
            trainTab: true
 
            Trains: true,
            Communication: true,
            Alerts: true,
            Admin: true,
            RadioBaseStation: true,
            TrainMovement: "none",
            Grab: "default",
          */
        }
      }

      if (data.userProfile && data.userProfile.permissions) {
        let baseStation = data.userProfile.permissions.baseStation
        let communicationTab = data.userProfile.permissions.communicationTab
        let trainTab = data.userProfile.permissions.trainTab
        let alertTab = data.userProfile.permissions.alertTab
        let trainMovement = data.userProfile.permissions.trainMovement
        let grabAllowed = data.userProfile.permissions.grabAllowed
        let locationTab = data.userProfile.permissions.locationTab
        let recordingsTab = data.userProfile.permissions.recordingsTab
        tabConfiguration = {
          ...tabConfiguration, Trains: trainTab,
          Communication: communicationTab,
          Alerts: alertTab,
          RadioBaseStation: baseStation,
          TrainMovement: trainMovement,
          Grab: grabAllowed,
          Location: locationTab,
          Recordings: recordingsTab
        }
        console.log('faObj faConfiguree permissions ', data.userProfile.permissions)
      }

      if (data.faList && data.faList.length > 0) {
        let firstFA = data.faList[0]
        let currentLA = ""
        if (tabConfiguration.Grab === "all") {
          currentLA = "ALLLA"
        } else {
          if (firstFA.CallerDescr) {
            currentLA = firstFA.CallerDescr.replace(/\s+/g, '').toLocaleUpperCase()
          }
        }
        global.config.currentLAId = currentLA
        global.config.faID = currentLA
        console.log("Current FA LA", global.config.faID, currentLA, firstFA, data.faList)
      }
      if (data.activatedFA) {
        global.config.activatedFA = data.activatedFA;
        console.log('activatedFA...', global.config.activatedFA)
      }
      global.config.userConfig = {
        ...global.config.userConfig, ...tabConfiguration
      }

      if (data.userData && data.userData.adminProfile) {
        let resp = data.userData.adminProfile;
        console.log("GET_ADMIN_PROFILE login page", resp);
        if (resp) {
          global.config.userConfig.mcxManagement = resp.mcxManagement;
          global.config.userConfig.partnerManagement = resp.partnerManagement;
          global.config.userConfig.tenantManagement = resp.tenantManagement;
          global.config.userConfig.PM = global.config.isEcommerce ? true : false; //resp.planManagement;
          global.config.userConfig.SM = global.config.isEcommerce ? true : false;//resp.subscriptionManagement;
          global.config.userConfig.DM = resp.deviceManagement;
          global.config.userConfig.partnerUserManagement = resp.partnerUserManagement;
          global.config.userConfig.adminUserSwitching = resp.adminUserSwitching;
          global.config.userConfig.parentId = resp.parentId;
          global.config.userConfig.partnerId = resp.partnerId;
          global.config.userConfig.tenantId = resp.tenantId;
          global.config.userConfig.systemTenant = resp.systemTenant;
          global.config.userConfig.systemPartner = resp.systemPartner;
          global.config.userConfig.Admin = global.config.userConfig.mcxManagement && global.config.userConfig.mcxManagement;
          global.config.userConfig.Mtm = global.config.userConfig.partnerManagement && global.config.userConfig.partnerManagement || global.config.userConfig.tenantManagement && global.config.userConfig.tenantManagement;
        }
        console.log("GET_ADMIN_PROFILE login admin", global.config.userConfig.Admin)
      }

      console.log("Current FA LA configration..", global.config.userConfig, tabConfiguration);
      this.setState({ loading: false, navigateToMain: true })
    })

    this.props.mcxClient.on('DIALER_OPTION', data => {
      console.log('mappedOption login...', data);
      this.props.fetchDialerPadOptions(data);
    })

    this.props.mcxClient.on('USER_LOGIN_COMPLETE', data => {
      console.log('user login complete', data)
      // if (data.userData.profile.Role) {
      //   if (data.userData.profile.Role === 'Admin') {
      //     tabConfiguration = { ...tabConfiguration, Admin: true }
      //   } else {
      //     tabConfiguration = { ...tabConfiguration, Admin: false }
      //   }
      //   global.config.userConfig = {
      //     ...global.config.userConfig, ...tabConfiguration
      //   }
      // }
    })

    this.props.mcxClient.on('DOMAIN_UPDATED', data => {
      global.config.userConfig.domains = data
      this.props.updateDomain(data);
    })

    this.props.mcxClient.on('FA_LOGIN_FAILED', data => {
      if (tabConfiguration.Admin === true) {
        console.log('FA login failed Admin Role', data)
        this.props.successLogin(data.userData, null)
        this.setState({ loading: false, navigateToMain: true })
      } else {
        this.setState({ status: 'FA is not attached.Please try again!', seen: true })
        //this.props.errorLogin()
        this.setState({ loading: false, navigateToMain: false })
      }
    })

    this.props.mcxClient.on('LOGIN_FAILED', data => {
      console.log('login failed', data)
      //this.setState({ status: 'Invalid credentials.Please try again!', seen: true });
      this.setState({ status: data, seen: true })
      this.setState({ loading: false, navigateToMain: false })
    })

    this.props.mcxClient.on('onRegister', (sock) => {
      console.log('mcxclient connected onRegister login', sock)
      this.props.systemStatusReceived({
        applicationInstanceType: network_config,
        systemStateType: "UP",
        systemType: "MCX",
      })
    })

    if (this.props.tokenId) {
      console.log('login access_token', this.props.tokenId)
      this.setState({ loading: true })
      this.props.mcxClient.loginWithToken(this.props.tokenId)
    } else {
      this.setState({ loading: false })
    }
  }

  login() {
    const { name, password } = this.state;
    if (!name || !password) {
      if (!password) this.setState({ status: "Please enter password", seen: true });
      if (!name) this.setState({ status: "Please enter username", seen: true });
    }
    if (name && password) {
      this.setState({ loading: true });
      this.props.mcxClient.login(name, password);
    }
  }

  loginFailed = () => {
    this.setState({ status: 'Invalid credentials.Please try again!', seen: true })
    //this.props.errorLogin()
  }

  onSubmit = async (e) => {
    e.preventDefault();
  }

  onSelectUserName = (i) => {
    this.setState({ selectedId: i },
      () => {
        this.updateUserName()
      }
    )
  }

  updateUserName = (e) => {
    if (this.state.selectedId !== "") {
      this.setState({ name: global.config.userConfig.userNames[this.state.selectedId].userName, displayName: global.config.userConfig.userNames[this.state.selectedId].display, status: '' })
    } else {
      this.setState({ displayName: e.target.value, status: '', name: e.target.value, status: '' })
    }
  }

  // render() {
  //   if (this.state.navigateToMain) {
  //     return <MainPage mcxClient={this.props.mcxClient} />;
  //   }
  //   return (
  //     <div className="backg" style={{  
  //       backgroundImage: "url(" + "/assets/images/splashscreen.jpg" + ")",
  //       backgroundPosition: 'center',
  //       backgroundSize: 'cover',
  //       backgroundRepeat: 'no-repeat'
  //     }} >
  //       {this.state.loading ? <SplashScreen /> : null}
  //       {/* <Header page={'alerts'}/> */}
  //       <div className="row login_input" style={{ width: "100vw", height: "100vh" }}>
  //         <div className="col-xl-7"></div>
  //         <div className="col-xl-5">
  //           <div className="auth">
  //             <center>
  //               <div className="card" style={{ width: "420px" }}>
  //                 <div
  //                   className="card-body"
  //                   id="login"
  //                   style={{ padding: "60px 75px" }}
  //                 >
  //                   {
  //                     global.config.project === "pro1" ? (
  //                       <img className="imgLogo" src="/assets/images/PROONELogo.png"/>
  //                     )
  //                       : null
  //                   }

  //                   <h1 className="white mb-12 f-40" id="title">
  //                     Welcome
  //                   </h1>
  //                   <h4 className="f-reg lime-4 f-12 m-b-30" id="title">
  //                     Press Login to continue
  //                   </h4>

  //                   {/* <div className="login_input m-b-16">
  //                     <input
  //                       type="text"
  //                       className="textinput-white blue5 f-semi rounded form-control"
  //                       name="name"
  //                       placeholder="Company Name"
  //                       id="username"
  //                       required=""
  //                       v-model="company_name"
  //                     />
  //                   </div> */}
  //                   <div className="login_input m-b-16">
  //                     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
  //                       <input
  //                         type="text"
  //                         className="textinput-white blue5 f-semi rounded form-control"
  //                         name="name"
  //                         placeholder="Username"
  //                         id="username"
  //                         required=""
  //                         v-model="name"
  //                         value={this.state.displayName}
  //                         onFocus={() => { this.state.selectedId !== "" && this.setState({ displayName: '', selectedId: '' }) }}
  //                         onChange={(e) => { this.updateUserName(e) }}
  //                       />
  //                       {global.config.userConfig.userNames.length !== 0 &&
  //                         <div class="dropdown" style={{ position: 'absolute', marginLeft: '86.5%' }}>
  //                           <button class="dropdown-toggle" style={{ backgroundColor: 'transparent', borderWidth: 0, color: '#1d405c', fontWeight: 700, fontSize: 18, }} type="button" id="dropdownMenuButtonLight" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  //                           </button>
  //                           <div className="dropdown-menu" aria-labelledby="dropdownMenuButtonLight">
  //                             {global.config.userConfig.userNames &&
  //                               global.config.userConfig.userNames.map((item) => {
  //                                 return (
  //                                   <button onClick={() => { this.onSelectUserName(item.id) }} class={item.id === this.state.selectedId ? "dropdown-item active dropdown-menu-bg" : "dropdown-item dropdown-menu-bg"} type="button">
  //                                     {item.display}
  //                                   </button>
  //                                 )
  //                               })
  //                             }
  //                           </div>
  //                         </div>
  //                       }
  //                     </div>
  //                   </div>

  //                   <div className="login_input m-b-16">
  //                     <input
  //                       type="password"
  //                       className="textinput-white blue5 f-semi rounded form-control"
  //                       name="password"
  //                       placeholder="Password"
  //                       id="password"
  //                       required=""
  //                       v-model="password"
  //                       value={this.state.password}
  //                       onChange={(e) => this.setState({ password: e.target.value, status: '' })}
  //                     />
  //                   </div>

  //                   <div className="dropdown-divider"></div>
  //                   <button
  //                     type="submit"
  //                     id="login_btn"
  //                     name="login_btn"
  //                     class="btn btn-warning rounded f-semi blue5 btn-lg btn-block font-14 m-t-12 m-b-10"
  //                     onClick={() => this.login()}
  //                   >Log in</button>
  //                   {this.state.seen ? (
  //                     <div id="status" className="m-t-5 m-b-30">
  //                       <h6 style={{ color: 'black' }}>{this.state.status}</h6>
  //                     </div>
  //                   ) : null}
  //                 </div>
  //               </div>
  //             </center>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  render() {
    if (this.state.navigateToMain) {
      return <MainPage mcxClient={this.props.mcxClient} />;
    }
    return (
      <div className="backg" style={{
        backgroundImage: "url(" + "/assets/images/splashscreen.jpg" + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        display: "flex", flexDirection: "row-reverse"
      }} >
        {this.state.loading ? <SplashScreen /> : null}

        <div className="row login_input" style={{ width: 650, height: "100vh" }}>
          <div className="auth">
            {
              global.config.project === "pro1" ? (
                <img className="imgLogo" src="/assets/images/PROONELogo.png" />
              )
                : null
            }
            <center>
              <div className="card" style={{ width: "650px", marginTop: "100px" }}>
                <div
                  className="card-body"
                  id="login"
                  style={{ padding: "60px 75px" }}
                >
                  <div className="welcomeText">
                    Welcome
                  </div>
                  <div className="loginToText">
                    Press Login to continue
                  </div>

                  <div className="lineOrange" />

                  <div className="login_input m-b-16">
                    <div style={{ display: 'flex', flexDirection: 'column', width: "80%", marginTop: "50px" }}>
                      <label className="lblUser">User name</label>
                      <input
                        type="text"
                        className="input-login-file"
                        name="name"
                        placeholder="Username"
                        id="username"
                        required=""
                        v-model="name"
                        value={this.state.displayName}
                        onFocus={() => { this.state.selectedId !== "" && this.setState({ displayName: '', selectedId: '' }) }}
                        onChange={(e) => { this.updateUserName(e) }}
                      />
                      {global.config.userConfig.userNames.length !== 0 &&
                        <div className="dropdown" style={{ position: 'absolute', marginLeft: '86.5%' }}>
                          <button className="dropdown-toggle" style={{ backgroundColor: 'transparent', borderWidth: 0, color: '#1d405c', fontWeight: 700, fontSize: 18, fontFamily: "Muli"}} type="button" id="dropdownMenuButtonLight" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          </button>
                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButtonLight">
                            {global.config.userConfig.userNames &&
                              global.config.userConfig.userNames.map((item) => {
                                return (
                                  <button onClick={() => { this.onSelectUserName(item.id) }} class={item.id === this.state.selectedId ? "dropdown-item active dropdown-menu-bg" : "dropdown-item dropdown-menu-bg"} type="button">
                                    {item.display}
                                  </button>
                                )
                              })
                            }
                          </div>
                        </div>
                      }
                    </div>
                  </div>

                  <div className="login_input m-b-16" style={{ width: "80%", display: 'flex', flexDirection: 'column' }}>
                    <label className="lblUser">Password</label>
                    <input
                      type="password"
                      className="input-login-file"
                      name="password"
                      placeholder="Password"
                      id="password"
                      required=""
                      v-model="password"
                      value={this.state.password}
                      onChange={(e) => this.setState({ password: e.target.value, status: '' })}
                    />
                  </div>

                  {/* <div className="dropdown-divider"></div> */}
                  <button
                    type="submit"
                    id="login_btn"
                    name="login_btn"
                    className="loginBtn"
                    onClick={() => this.login()}
                  >Log in</button>
                  {this.state.seen ? (
                    <div id="status" className="m-t-5 m-b-30">
                      <h6 style={{ color: 'white' }}>{this.state.status}</h6>
                    </div>
                  ) : null}
                </div>
              </div>
            </center>
            <div className="mcxLogo">
              <img src="/assets/images/mcxlogo.png" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  successLogin,
  errorLogin,
  fetchContacts,
  systemStatusReceived,
  fetchDialerPadOptions,
  navigateToLogin: () => push('/login'),
  navigateToHome: () => push('/'),
  updateDomain,
  updateSystemDgnaSSI,
  updateDGNA,
  updateAmbienceListening,
  updateCadCall,
  updatePatchCall,
  updateMergeCall,
  updateCallForward
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Login_page);