import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles, createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { Box, Grid, Typography } from '@mui/material';

const TenantDetails = (props) => {

    const [tenantName, setTenantName] = useState('')
    const [tenantAddress, setTenantAddress] = useState('')
    const [partnerId, setPartnerId] = useState(global.config.userConfig.partnerId)
    const [tenantId, setTenantId] = useState('')
    const [tenantContactNumber, setTenantContactNumber] = useState('')
    const [domainName, setDomainName] = useState('')
    const [mcxDomain, setMcxDomain] = useState('')
    const [conferenceDomain, setConferenceDomain] = useState('')
    const [isDomainNameEmpty, setIsDomainNameEmpty] = useState('')

    const useStyles = makeStyles((theme) => ({
        // root: {
        //     backgroundColor: theme.palette.background.paper,
        //     width: "100%",
        //     left: 0
        //     // height: 600
        // },
        // formControlIwfType: {
        //     width: "90%",
        //     height: "100px",
        //     marginTop: "0px",
        // },
        // iwfInfoList: {
        //     padding: "5px",
        //     fontFamily: "Muli",
        // },
        // listItemFA: {
        //     padding: "2px",
        //     fontFamily: "Muli",
        // },
        // divList: {
        //     width: '100%',
        //     marginBottom: '1px',
        // },
        // formControlFA: {
        //     width: "80%",
        //     height: "60px",
        //     marginTop: "2px",
        // },
        root: {
            backgroundColor: "#252524",
            //backgroundColor: theme.palette.background.default,
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            width: "100%",
            //height: "70%",
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
        },
        formControlFA: {
            width: "60%",
            height: "65px",
            marginTop: "2px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600,
            backgroundColor: "#383736"
        },
        listItemFA: {
            padding: "5px",
            fontFamily: "Muli",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        productTypeClass: {
            width: "90%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            border: "solid",
            borderRadius: 10,
            borderWidth: "2px",
            borderColor: "#4B4A4A",
            marginTop: 5,
            marginBottom: 10
        },
        divList: {
            width: '100%',
            marginBottom: '13px',
        },
        lblStyle: {
            width: '100%',
            color: "#D0D3D4",
            fontSize: 18,
            fontWeight: 600
        },
        tetraSwitch: {
            fontFamily: "Muli",
            marginTop: "2px",
            marginRight: "1px",
            marginLeft: "0px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        datePickerStyle: {
            color: "black",
            padding: "10px",
            fontFamily: "Sans-Serif",
            width: '60%',
            border: "solid",
            borderRadius: 20,
            borderWidth: "2px",
            borderColor: "#D3D3D3",
        }

        // box {
        //         display: flex;
        //         flex-wrap: wrap; /* Optional. only if you want the items to wrap */
        //         justify-content: center; /* For horizontal alignment */
        //         align-items: center; /* For vertical alignment */
        // }
    }));
    const classes = useStyles();

    const theme = createTheme({
        palette: {
            primary: {
                main: "#006400",
            },
            secondary: {
                main: "#fdce2a",
            },
        },
    });

    const tenantNameChangeHandler = (e) => {
        setTenantName(e.target.value)
    }
    const tenantAddressChangeHandler = (e) => {
        setTenantAddress(e.target.value)
    }
    const tenantContactChangeHandler = (e) => {
        setTenantContactNumber(e.target.value)
    }

    const domainNameChangeHandler = (e) => {
        let regexResult = e.target.value.replace(/[@#\/\\& ]/g, "")
        setDomainName(regexResult)
    }
    const conferenceDomainChangeHandler = (e) => {
        setConferenceDomain(e.target.value)
    }
    const mcxDomainChangeHandler = (e) => {
        setMcxDomain(e.target.value)
    }

    const onNext = () => {
        if (props.purpose === "edit") {
            props.createTenantData([{
                data: {
                    tenantName: tenantName,
                    tenantAddress: tenantAddress,
                    tenantContactNumber: tenantContactNumber,
                    partnerId: partnerId,
                    domainName: domainName.toLowerCase(),
                    domains: {
                        conferenceDomain: conferenceDomain.toLowerCase(),
                        mcxDomain: mcxDomain.toLowerCase(),
                        // iwfs: addedIwfInfo
                    },
                }
            }])
        } else {
            if (domainName.length > 0) {
                setIsDomainNameEmpty(false)
                props.createTenantData([{
                    data: {
                        tenantName: tenantName,
                        tenantAddress: tenantAddress,
                        tenantContactNumber: tenantContactNumber,
                        partnerId: partnerId,
                        domainName: domainName.toLowerCase(),
                        domains: {
                            conferenceDomain: conferenceDomain.toLowerCase(),
                            mcxDomain: mcxDomain.toLowerCase(),
                            // iwfs: addedIwfInfo
                        },
                    }
                }])
            } else {
                setIsDomainNameEmpty(true)
            }
        }
    }

    useEffect(() => {
        console.log("Tenant List----->>>>", props.tenantData)
        if (props.purpose === "edit" && props.tenantData) {
            if (props.tenantData.tenantName) {
                setTenantName(props.tenantData.tenantName);
            }
            if (props.tenantData.tenantAddress) {
                setTenantAddress(props.tenantData.tenantAddress);
            }
            if (props.tenantData.tenantContactNumber) {
                setTenantContactNumber(props.tenantData.tenantContactNumber);
            }
            if (props.tenantData.tenantId) {
                setTenantId(props.tenantData.tenantId);
            }
            if (props.tenantData.partnerId) {
                setPartnerId(props.tenantData.partnerId);
            }
            if (props.tenantData.domainName) {
                setDomainName(props.tenantData.domainName);
            }
            if (props.tenantData.domains && props.tenantData.domains.conferenceDomain) {
                setConferenceDomain(props.tenantData.domains.conferenceDomain);
            }
            if (props.tenantData.domains && props.tenantData.domains.mcxDomain) {
                setMcxDomain(props.tenantData.domains.mcxDomain);
            }
        }
    }, [])

    useEffect(() => {
        if (props.purpose !== "edit") {
            setMcxDomain(`mcx.${domainName}`)
            setConferenceDomain(`conference.${domainName}`)
        }
    }, [domainName])

    return (
        <div className={classes.root}>
            <Grid container width="100%" spacing={2}>
                <Grid item xs={12}>
                    <Box class="tab1-account" width={'100%'} marginLeft={0}>
                        <Box class="form-group" paddingX={10} paddingY={0}>
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className={classes.lblStyle}>Name *</label>
                            </Box>
                            <input
                                type="text"
                                className="input-control-flat"
                                placeholder="Name"
                                id="firstName"
                                value={tenantName}
                                onChange={props.purpose === "edit" ? () => { } : tenantNameChangeHandler}
                            />
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0}>
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className={classes.lblStyle}>Contact Number</label>
                            </Box>
                            <input
                                type="number"
                                className="input-control-flat"
                                placeholder="Contact Number"
                                id="firstName"
                                value={tenantContactNumber}
                                onChange={tenantContactChangeHandler}
                            />
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0}>
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className={classes.lblStyle}>Address</label>
                            </Box>
                            <input
                                type="text"
                                className="input-control-flat"
                                placeholder="Address"
                                id="firstName"
                                value={tenantAddress}
                                onChange={tenantAddressChangeHandler}
                            />
                        </Box>
                        {
                            props.purpose === "edit" &&
                            (
                                <Box class="form-group" paddingX={10} paddingY={0}>
                                    <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                        <label className={classes.lblStyle}>Tenant-ID *</label>
                                    </Box>
                                    <input
                                        type="text"
                                        className="input-control-flat"
                                        placeholder="Tenant Id"
                                        id="firstName"
                                        value={tenantId}
                                        onChange={() => { }}
                                    />
                                </Box>
                            )
                        }
                        <Box class="form-group" paddingX={10} paddingY={0}>
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className={classes.lblStyle}>Domain Name *</label>
                            </Box>
                            <input
                                type="text"
                                className="input-control-flat"
                                id="firstName"
                                placeholder="Domain Name"
                                value={domainName}
                                onChange={props.purpose === "edit" ? () => { } : domainNameChangeHandler}
                            />
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0}>
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className={classes.lblStyle}>MCX Domain *</label>
                            </Box>
                            <input
                                type="text"
                                className="input-control-flat"
                                placeholder="MCX Domain"
                                id="firstName"
                                value={mcxDomain}
                                onChange={props.purpose === "edit" ? () => { } : mcxDomainChangeHandler}
                            />
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0}>
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className={classes.lblStyle}>Conference Domain *</label>
                            </Box>
                            <input
                                type="text"
                                className="input-control-flat"
                                placeholder="Conference Domain"
                                id="firstName"
                                value={conferenceDomain}
                                onChange={props.purpose === "edit" ? () => { } : conferenceDomainChangeHandler}
                            />
                        </Box>
                    </Box>
                    <Box display={"flex"} flexDirection={"row-reverse"} width={"100%"}>
                        {isDomainNameEmpty === true ? (
                            <p class="error-handling-lbl">Domain Name can't be empty</p>
                        ) : null}
                        {mcxDomain !== '' && conferenceDomain !== "" && tenantName !== "" &&
                            <div className="form-group">
                                <button
                                    class="update-btn-profile"
                                    type="button"
                                    onClick={onNext}
                                >
                                    NEXT
                                </button>
                            </div>
                        }
                    </Box>
                </Grid>
            </Grid>
        </div >
    )

}

export default connect(null, {
})(TenantDetails);