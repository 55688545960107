
import TitleTab from "../Admin/components/TitleTab";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchSubscriptions } from "../modules/productcatalogstate";
import { DataGrid } from '@mui/x-data-grid';
import dayjs from "dayjs";

const SubscriptionList = (props) => {
    const { fetchSubscriptions, subscriptionList } = props;
    const [filterName, setFilterName] = useState('Subscriptions List');

    const columns = [
        {
            field: 'productId', headerName: 'Product Name', width: 450, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
            valueGetter: (value, row) => `${getProductName(row)}`,
        },
        {
            field: 'numDays', headerName: 'No. Of Days', width: 215, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
        },
        {
            field: 'numSubscribers', headerName: 'No. Of Subscribers', width: 240, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center",
        },
        {
            field: 'ActiveAllocation',
            headerName: 'Active Allocation',
            width: 250,
            sortable: false,
            headerClassName: "list-head-Val",
            cellClassName: "list-row-Val",
            align: "center",
            headerAlign: "center",
            valueGetter: (value, row) => `${getCurrentActiveAllocation(row)}`,
        },
        {
            field: 'purchaseDate',
            headerName: 'Purchase Date',
            width: 240,
            valueGetter: (value, row) => `${dayjs(row.purchaseDate).format("DD-MM-YYYY")}`,
            sortable: false,
            headerClassName: "list-head-Val",
            cellClassName: "list-row-Val",
            align: "center",
            headerAlign: "center",
        },
        {
            field: 'startDate',
            headerName: 'Start Date',
            width: 240,
            valueGetter: (value, row) => `${dayjs(row.startDate).format("DD-MM-YYYY")}`,
            sortable: false,
            headerClassName: "list-head-Val",
            cellClassName: "list-row-Val",
            align: "center",
            headerAlign: "center",
        },
        {
            field: 'expiryDate',
            headerName: 'Expiry',
            width: 240,
            valueGetter: (value, row) => `${dayjs(row.expiryDate).format("DD-MM-YYYY")}`,
            sortable: false,
            headerClassName: "list-head-Val",
            cellClassName: "list-row-Val",
            align: "center",
            headerAlign: "center",
        },

    ];

    useEffect(() => {
        let ptId = getPartnerOrtenantSubTypeAndId();
        console.log("getPartnerOrtenantSubTypeAndId() ", ptId);
        let apiType = "partner";
        if (ptId && (ptId.userType === "tn" || ptId.userType === "stn")) {
            apiType = "tenant";
        }
        if (ptId && ptId.pId) {
            const filterData = {
                "mtmId": ptId.pId,
                "apiType": apiType
            }
            fetchSubscriptions(filterData);
        }
    }, [])

    useEffect(() => {
        console.log("subscriptionList... ", subscriptionList);
    }, [subscriptionList])

    const getPartnerOrtenantSubTypeAndId = () => {
        let pId = null;
        let userType = "spr";
        if (global.config.userConfig.parentId) {
            pId = global.config.userConfig.tenantId;
            if (pId) {
                if (global.config.userConfig.systemTenant) {
                    userType = "stn";
                } else {
                    userType = "tn";
                }
                pId = global.config.userConfig.tenantId;
            } else {
                pId = global.config.userConfig.partnerId;
                userType = "pr";
            }
        } else {
            if (global.config.userConfig.systemPartner) {
                pId = global.config.userConfig.partnerId;
            }
        }
        return {
            "pId": pId,
            "userType": userType
        };
    }

    const filterData = (type) => {
        /*let ptId = getPartnerOrtenantSubTypeAndId();
        console.log("getPartnerOrtenantSubTypeAndId() filter ", ptId);
        if (type === "partner") {
            setFilterName("Subscriptions List");
        } else {
            setFilterName("Subscriptions List");
        }

        if (ptId && ptId.pId) {
            const filterData = {
                "mtmId": ptId.pId,
                "apiType": type
            }
            fetchSubscriptions(filterData);
        }*/
    }

    const closeAfterCallApi = () => {

    }

    const getProductName = (product) => {
        let productName = "";
        if (product && product.products && product.products.shortDescription) {
            productName = product.products.shortDescription;
        }
        return productName;
    }

    const getCurrentActiveAllocation = (subscription) => {
        let activeSub = 0;
        if (subscription && subscription.currentActiveAllocation) {
            activeSub = subscription.currentActiveAllocation;
        }
        return activeSub;
    }

    return (
        <div style={{ height: 900, width: "100%", overflowY: "scroll", display: "flex", flexDirection: "column" }}>
            <TitleTab
                title={filterName}
                type={"subscrTemplate"}
                search={(txt) => {
                    //console.log("search text", txt);
                    //searchedData(txt)
                }}
                filtr={(x) => {
                    console.log("PM Clicked", x);
                    filterData(x)
                }}
            />
            <DataGrid
                rows={subscriptionList}
                columns={columns}
                //filterMode="server"
                //onFilterModelChange={onFilterChange}
                rowHeight={68}
                hideFooter
                hideFooterPagination
                hideFooterSelectedRowCount
                localeText={{ noRowsLabel: "" }}
                //disableColumnMenu
                getRowSpacing={params => ({
                    top: params.isFirstVisible ? 0 : 0,
                    bottom: params.isLastVisible ? 0 : 0
                })}

                sx={{
                    boxShadow: 2,
                    border: "none",
                    borderBottom: "none",
                    // display: "block",
                    '& .MuiDataGrid-cell:hover': {
                        color: '#ffb01f',
                    },
                    '& .MuiDataGrid': {
                        border: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        borderColor: "#404040",
                    },
                    '& .MuiDataGrid-columnHeader': {
                        borderColor: "gray",
                    },
                    "& .MuiDataGrid-sortIcon": {
                        opacity: 1,
                        color: "white",
                    },
                    "& .MuiDataGrid-menuIconButton": {
                        opacity: 1,
                        color: "white"
                    },
                    '& .MuiDataGrid-filler': {
                        backgroundColor: "#262626",
                    },
                }}
            />
        </div>
    )
}

const mapStateToProps = ({ productcatalogstate }) => {
    const { subscriptionList } = productcatalogstate;
    return {
        subscriptionList
    };
};

export default connect(mapStateToProps, {
    fetchSubscriptions
})(SubscriptionList);
