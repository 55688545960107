import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import TitleTab from "./titleTab";
import { updatePartnerListMtm, updateTenantListMtm, fetchPartnerListMtm, fetchTenantListMtm, createUserOnIdmsAndServer, fetchUeTypeListMtm, fetchMasterConfigServiceConfig, deletePartnerListMtm, deleteTenantListMtm, } from "../../modules/mtmstate";
import MtmListRow from "./mtmListRow";
import AddModal from "./addModal";
import { DataGrid } from "@mui/x-data-grid";
import AlertDialog from "../../Admin/components/DeleteUserDialog";
import FadeInOut from "../../Admin/components/FadeInOut";
import { makeStyles } from "@material-ui/core/styles";
import AddParent from "./addPartner";
import AddTenant from "./addTenant";

const MTMList = (props) => {
  const { updatePartnerListMtm, updateTenantListMtm, fetchPartnerListMtm, fetchTenantListMtm, partnerList, tenantList, createUserOnIdmsAndServer, tenantAdmin,
    partnerAdmin, mtmListRefresh, fetchUeTypeListMtm, fetchMasterConfigServiceConfig, deletePartnerListMtm, deleteTenantListMtm, } = props
  const [filterName, setFilterName] = useState('')
  const [subscribeType, setSubscribeType] = useState('')
  const [mtmList, setMtmList] = useState([])
  const [mtmOpen, setMtmOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userName, setUserName] = useState('')
  const [userId, setUserId] = useState("")
  const [selectedPartner, setSelectedPartner] = useState('')
  const [selectedTenant, setSelectedTenant] = useState('')
  const [show, setShow] = useState(false);
  const [openUserEditMode, setOpenUserEditMode] = useState(false);

  useEffect(() => {
    if (global.config.userConfig.tenantManagement) {
      setFilterName('Tenant List')
    }
    if (global.config.userConfig.tenantManagement) {
      setSubscribeType('tenant')
    }

    if (global.config.userConfig.partnerManagement) {
      setSubscribeType('partner')
    }
    if (global.config.userConfig.partnerManagement) {
      setFilterName('Partners List')
    }

    fetchPartnerListMtm();
    fetchTenantListMtm();
    fetchUeTypeListMtm(global.config.userConfig.partnerId ? global.config.userConfig.partnerId : null);
    fetchMasterConfigServiceConfig();

  }, [mtmListRefresh])

  const setDataByType = (type) => {
    switch (type) {
      case "partner":
        setMtmList(partnerList);
        break;
      case "tenant":
        setMtmList(tenantList);
        break;
    }
  };

  const columns = [
    {
      field: 'userIcon',
      headerName: 'Icon',
      width: 119.5,
      height: 50,
      sortable: false,
      headerClassName: "list-head-Val",
      align: "center",
      headerAlign: "center",
      renderCell: (cellValue) => {
        return (
          <div class="tr-tb-icon">
            <img
              src='/assets/images/Vector-7.svg'
              class="single-user-img"
              alt=""
            />
          </div>
        )
      }
    },
    {
      field: subscribeType === "partner" ? 'partnerName' : 'tenantName', headerName: subscribeType === "partner" ? 'Partner Name' : 'Tenant Name', width: 531, sortable: false,
      headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
      // valueGetter: (value, row) => `${getProductName(row)}`,
    },
    {
      field: subscribeType === "partner" ? 'partnerEmail' : 'tenantAddress', headerName: subscribeType === "partner" ? 'Partner Email' : 'Tenant Address', width: 531, sortable: false,
      headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
    },
    {
      field: subscribeType === "partner" ? 'partnerContactNumber' : 'tenantContactNumber', headerName: subscribeType === "partner" ? 'Partner Contact Number' : 'Tenant Contact Number', width: 531, sortable: false,
      headerClassName: "list-head-Val", cellClassName: "list-row-Val",
      align: "center", headerAlign: "center",
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 71,
      height: 50,
      sortable: false,
      headerClassName: "list-head-Val",
      align: "center",
      headerAlign: "center",
      renderCell: (cellValue) => {
        return (
          <button
            class="editBtn"
            onClick={() => { openUserEditPage(cellValue.row) }}
            type="button"
            name=""
          >
            <img
              src="/assets/images/editimg.svg"
              class="edit-user-img"
              alt=""
            />
          </button>
        )
      }
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 91,
      height: 50,
      sortable: false,
      headerClassName: "list-head-Val",
      align: "center",
      headerAlign: "center",
      renderCell: (cellValue) => {
        return (
          <>
            {global.config.userConfig.multiTenantFeature == true &&
              <button
                class="editBtn"
                onClick={() => { deleteButtonClick(cellValue.row) }}
                type="button"
                name=""
              >
                <img
                  src="/assets/images/deleteimg.svg"
                  class="delete-user-img"
                  alt=""
                />
              </button>
            }
          </>
        )
      },
    },
  ];

  const useStyles = makeStyles((theme) => ({
    root: {
      //backgroundColor: theme.palette.background.paper,
      // paddingTop: 10,
      // paddingLeft: 20,
      // paddingRight: 20,
      width: "100%",
      //height: "70%",
      overflowY: "scroll",
      display: 'flex',
      flexDirection: 'column',
      // justifyContent: 'center',
    },
    mtmTypeClass: {
      width: "70%",
      height: 800,
      // display: 'flex',
      flexDirection: 'column',
      justifyContent: "center",
      alignItems: "center",
      border: "solid",
      borderRadius: 10,
      borderWidth: "1px",
      borderColor: "#4B4A4A",
      marginLeft: 50,
      //marginTop: 5,
      marginBottom: 10,
      marginTop: 20,
      overflowY: "scroll",
    },
    divList: {
      width: '100%',
      marginBottom: '13px',
    },

  }));

  const classes = useStyles();

  // Update List when data is fetched
  useEffect(() => {
    console.log("-------------------------DATA LIST-----------------------------");
    switch (subscribeType) {
      case "partner":
        setDataByType("partner");
        break;
      case "tenant":
        setDataByType("tenant");
        break;
    }
    if (tenantAdmin && tenantList && tenantList.length > 0) {
      createUserOnIdmsAndServer(tenantList[(tenantList.length - 1)], { userType: 'tenant' })
    }
    if (partnerAdmin && partnerList && partnerList.length > 0) {
      createUserOnIdmsAndServer(partnerList[(partnerList.length - 1)], { userType: 'partner' })
    }
  }, [partnerList, tenantList]);

  const mtmHandleClose = useCallback(() => {
    setShow(false);
    setMtmOpen(false);
    setOpenUserEditMode(false);
  }, []);

  const filterData = (type) => {
    setOpenUserEditMode(false);
    setMtmOpen(false)
    if (type === 'partner') {
      setFilterName('Partners List')
      setSubscribeType('partner')
      setMtmList(partnerList)
      fetchPartnerListMtm()
      setShow(false);
    } else if (type === "tenant") {
      setFilterName('Tenant List')
      setSubscribeType('tenant')
      setMtmList(tenantList);
      fetchTenantListMtm();
      setShow(false);
    }
    else {
      setMtmOpen(true)
      setShow((prev) => !prev);
    }

  }

  const searchedData = (searchCont) => {
    let filterCont;
    if (searchCont.length > 0) {
      if (subscribeType === "partner") {
        filterCont = partnerList.filter(cont =>
          cont.partnerName && cont.partnerName.includes(searchCont))
      }
      else if (subscribeType === "tenant") {
        filterCont = tenantList.filter(cont =>
          cont.tenantName && cont.tenantName.includes(searchCont))
      }
      setMtmList(filterCont)
    } else {
      if (subscribeType === "partner") {
        setMtmList(partnerList)
      }
      else if (subscribeType === "tenant") {
        setMtmList(tenantList)
      }
    }
  }

  const hideModal = useCallback(() => {
    setMtmOpen(false);
    setShow(false);
    setOpenUserEditMode(false);
  }, [])

  const deleteButtonClick = (val) => {
    if (subscribeType === "partner") {
      if (val && val.partnerId) {
        setDialogOpen(true)
        setUserName(val.partnerName)
        setUserId(val.partnerId)
      }
    }
    else if (subscribeType === "tenant") {
      if (val && val.tenantId) {
        setDialogOpen(true)
        setUserName(val.tenantName)
        setUserId(val.tenantId)
      }
    }

  }

  const deleteDailogHandler = (val) => {
    setDialogOpen(false)
    if (val) {
      if (subscribeType === "partner") {
        deletePartnerListMtm(userId, { type: subscribeType })

      } else if (subscribeType === "tenant") {
        deleteTenantListMtm(userId, { type: subscribeType })
      }
    }

  }

  const openUserEditPage = (val) => {
    subscribeType === "partner" ? setSelectedPartner(val) : setSelectedTenant(val)
    setOpenUserEditMode(true);
    setMtmOpen(true)
    setShow(true);
  }

  const updatedPartnerData = (data) => {
    updatePartnerListMtm(data)
  }
  const updatedTenantData = (data) => {
    updateTenantListMtm(data)
  }

  useEffect(() => {
    mtmOpen == true && (!openUserEditMode ? (subscribeType === "partner" ? setFilterName('Add Partner') : setFilterName('Add Tenant')) : subscribeType === "partner" ? setFilterName('Edit Partner') : setFilterName('Edit Tenant'))
  }, [openUserEditMode, mtmOpen, subscribeType])

  return (
    <div className={classes.root}>
      <TitleTab
        title={filterName}
        type={subscribeType === "partner" ? "PartnerListTab" : 'TenantListTab'}
        search={(txt) => {
          console.log("search text", txt);
          searchedData(txt)
        }}
        filtr={(x) => {
          console.log("TYPE", x);
          filterData(x)
        }}
        mtmOpen={mtmOpen}
      />
      {/*<div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          {subscribeType === "partner" ?
            (
              <div class="single-user-row-grid-head">
                <div class="tr-tb-icon"></div>
                <div class="tr-tb-uname">
                  <span>PARTNER NAME</span>
                </div>
                <div class="tr-tb-uid" >
                  <span>PARTNER EMAIL ADDRESS</span>
                </div>
                <div class="tr-tb-mxid">
                  <span>PARTNER CONTACT NUMBER</span>
                </div>
                <div class="tr-tb-edit">
                  <span></span>
                </div>
                <div class="tr-tb-delete">
                  <span></span>
                </div>
              </div>
            ) : (
              <div class="group-user-row-grid-head">
                <div class="tr-tb-icon"></div>
                <div class="tr-tb-gname">
                  <span>TENANT NAME</span>
                </div>
                <div class="tr-tb-gid">
                  <span>TENANT ADDRESS</span>
                </div>
                <div class="tr-tb-members">
                  <span>TENANT CONTACT NUMBER</span>
                </div>
                <div class="tr-tb-edit">
                  <span></span>
                </div>
                <div class="tr-tb-delete">
                  <span></span>
                </div>
              </div>
            )
          }

          <div style={{ height: '880px', marginTop: '10px', overflowY: 'scroll' }}>
            {mtmList &&
              mtmList.map((data, i) => {
                return (
                  <MtmListRow
                    key={i}
                    mtmData={data}
                    tetraUser={props.tetraUser}
                    SubscribeType={subscribeType}
                  />
                );
              })}
          </div>
        </div>
      </div>
      <AddModal mtmHandleClose={mtmHandleClose} hideModal={hideModal} mtmOpen={mtmOpen} subscribeType={subscribeType} mtmData={mtmList} partnerData={partnerList}></AddModal> */}


      {show ?
        <div className={classes.mtmTypeClass}>
          <FadeInOut show={show} duration={500}>
            {openUserEditMode === false ?
              <AddModal mtmHandleClose={mtmHandleClose} hideModal={hideModal} mtmOpen={mtmOpen} subscribeType={subscribeType} mtmData={mtmList} partnerData={partnerList}></AddModal>
              :
              (subscribeType === "partner" ? <AddParent purpose="edit" tetraUser={props.tetraUser} hideModal={hideModal} updatedPartnerData={updatedPartnerData} partnerData={selectedPartner}></AddParent>
                :
                <AddTenant purpose="edit" tetraUser={props.tetraUser} hideModal={hideModal} updatedTenantData={updatedTenantData} tenantData={selectedTenant}></AddTenant>)
            }
          </FadeInOut>
        </div>
        : <DataGrid
          rows={mtmList}
          getRowId={(row) => subscribeType === "partner" ? row.partnerName : row.tenantName}
          columns={columns}
          rowHeight={68}
          hideFooter
          hideFooterPagination
          hideFooterSelectedRowCount
          disableColumnMenu
          localeText={{ noRowsLabel: "" }}
          getRowSpacing={params => ({
            top: params.isFirstVisible ? 0 : 0,
            bottom: params.isLastVisible ? 0 : 0
          })}
          sx={{
            boxShadow: 2,
            border: "none",
            borderBottom: "none",
            '& .MuiDataGrid-cell:hover': {
              color: '#ffb01f',
            },
            '& .MuiDataGrid': {
              border: 'none',
            },
            '& .MuiDataGrid-cell': {
              borderColor: "#404040",
            },
            '& .MuiDataGrid-columnHeader': {
              borderColor: "gray",
            },
            '& .MuiDataGrid-filler': {
              backgroundColor: "#262626",
            },
          }}

        />
      }
      <AlertDialog open={dialogOpen} deleteDailogHandler={deleteDailogHandler} userName={userName} />
    </div>
  )
}

const mapStateToProps = ({ mtmstate }) => {
  const { partnerList, tenantList, tenantAdmin, partnerAdmin } = mtmstate;
  return {
    partnerList, tenantList, tenantAdmin, partnerAdmin
  };
};

export default connect(mapStateToProps, {
  fetchMasterConfigServiceConfig,
  fetchPartnerListMtm,
  fetchTenantListMtm,
  createUserOnIdmsAndServer,
  fetchUeTypeListMtm,
  deletePartnerListMtm,
  deleteTenantListMtm,
  updatePartnerListMtm,
  updateTenantListMtm
})(MTMList);