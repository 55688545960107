(function (exports) {
    const axios = require('axios').default;
    const requestHandler = {
        put(path, data) {
            const headers = {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*'
            }
            return new Promise(function (resolve, reject) {
                axios.put(path, data, { headers: headers, timeout: 30000 }).then(function (res) {
                    console.log('PUT RES', res)
                    if (res.status === 200) {
                        resolve({ status: res.status, success: true })
                    } //return ;
                    else resolve({ status: res.status, success: false })
                }).catch(function (err) {
                    reject({ status: 400, success: false })
                })
            })
        },

        get(path, header = null) {
            return new Promise(function (resolve, reject) {
                if (header !== null) {
                    axios.get(path, header).then(function (res) {
                        if (res.status === 200) resolve(res.data);
                        else resolve([])
                    })
                        .catch(function (err) {
                            reject(err)
                        })
                } else {
                    axios.get(path).then(function (res) {
                        if (res.status === 200) resolve(res.data);
                        else resolve([])
                    })
                        .catch(function (err) {
                            reject(err)
                        })
                }

            })
        },

        getbyId(path, id) {
            return new Promise(function (resolve, reject) {
                axios.get(path + id, { timeout: 30000 }).then(function (res) {
                    // console.log('API GET',path,res.data);
                    if (res && res.status === 200) resolve(res.data);
                    else resolve([])
                })
                    .catch(function (err) {
                        reject([])
                    })
            })
        },

        deleteData(path, data) {
            return new Promise(function (resolve, reject) {
                axios.delete(`${path}?id=${data}`, { timeout: 30000 }).then(function (res) {
                    resolve(res);
                })
                    .catch(function (err) {
                        reject([])
                    })
            })
        },

        post(path, data) {
            return new Promise(function (resolve, reject) {
                axios.post(path, data, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Host': 'cms1.example.com'
                    },
                    timeout: 30000
                }).then(function (res) {
                    console.log("POST API RES ", res)
                    if (res.status === 200) {
                        //console.log("POST API RES ",res)
                        resolve({ status: res.status, success: true, data: res.data })
                    }
                    else {
                        resolve({ status: 400, success: false })
                    }
                })
                    .catch(function (err) {
                        reject(err)
                    })
            })
        }
    }
    exports.requestHandler = requestHandler;

})(typeof exports === 'undefined' ? this['requestHandler'] = {} : exports);
