/** @format */

import React, { useState, useEffect } from "react";
import { createTheme } from "@material-ui/core/styles";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import { InputLabel, MenuItem, FormControl, Select, Table, TableBody, TableHead, TableRow, TableCell, TableContainer, Paper, TextField, Box, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { fetchAppList, setConfigApp, fetchConfigApp, appReln } from "../../../modules/actions/mdmAction";
import { getMethod, postMethod } from "../utils"
import { EndPoints } from "../../../MCXclient/endpoints";
const ConfigDesignSetting = (props) => {
    const {
        nextButtonHandler,
        appList,
        user,
        data,
        setConfigApp,
        applicationList,
        fetchConfigApp,
        appReln
    } = props;
    const [appOption, setAppOption] = useState([]);
    const [appVersionOption, setAppVersionOption] = useState([])
    // const [applicationList, setApplicationList] = useState([])
    const [selectedApp, setSelectedApp] = useState("")
    const [selectedAppVersion, setSelectedAppVersion] = useState("")
    const appActionOption = [
        { text: "Install", value: 0 },
        { text: "Do not Install", value: 1 },
        { text: "Delete", value: 2 }
    ]
    const buttonHandler = () => {
        appReln(applicationList)
        nextButtonHandler(2, {})
    }
    const iconOption = [
        { text: "Show", value: 1 },
        { text: "Hide", value: 0 }
    ]
    const isEmptyObject = (obj) => {
        return (
            obj &&
            Object.keys(obj).length === 0 &&
            Object.getPrototypeOf(obj) === Object.prototype
        );
    };
    
        useEffect(() => {
        let option = appList.filter((r)=>{
return (r.deviceType==data.deviceType)
        }).map(r => {
            return { text: r.name, value: { applicationid: r.id, name: r.name, packageId: r.packageId, version: r.version, configurationid: data.id } }
        })
        setAppOption([...option])
    }, [appList])
    const useStyles = makeStyles((theme) => ({
        formControlUEType: {
            width: "90%",
            // height: "60px",
            margin: "5px",
            color: "#D0D3D4",
            backgroundColor: "#383736"
        },

        root: {
            backgroundColor: "#252524",
            //backgroundColor: theme.palette.background.default,
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            width: "100%",
            //height: "70%",
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
        },
        formControlFA: {
            width: "60%",
            height: "65px",
            marginTop: "2px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600,
            backgroundColor: "#383736",
            fontFamily: "Muli"
        },
        listItemFA: {
            padding: "5px",
            fontFamily: "Muli",
            color: "#D0D3D4",
            fontSize: 14,
            fontWeight: 600
        },
        productTypeClass: {
            width: "90%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            border: "solid",
            borderRadius: 10,
            borderWidth: "2px",
            borderColor: "#4B4A4A",
            marginTop: 5,
            marginBottom: 10
        },
        divList: {
            width: '100%',
            marginBottom: '13px',
        },
        lblStyle: {
            width: '100%',
            color: "#D0D3D4",
            fontSize: 18,
            fontWeight: 600
        },
        tetraSwitch: {
            fontFamily: "Muli",
            marginTop: "2px",
            marginRight: "1px",
            marginLeft: "0px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        datePickerStyle: {
            color: "black",
            padding: "10px",
            fontFamily: "Sans-Serif",
            width: '60%',
            border: "solid",
            borderRadius: 20,
            borderWidth: "2px",
            borderColor: "#D3D3D3",
        }

    }));
    useEffect(() => {
        // loadApplicationList()
        fetchConfigApp({ id: data.id })
    }, [])
    const theme = createTheme({
        palette: {
            primary: {
                main: "#006400",
            },
            secondary: {
                main: "#fdce2a",
            },
        },
    });
    const classes = useStyles();
    const onSelectHandler = (e, idx) => {
        let data = [...applicationList];
        data[idx][e.target.name] = e.target.value;
        setConfigApp([...data])
        // setApplicationList([...data])
    }
    const onChangeAppSelectHandler = async (e) => {
        let url = `${EndPoints.getConfig().appVersion}?applicationId=${e.target.value.applicationid}`
        const token = user ? user.access_token ? user.access_token : "" : ""
        let data = await getMethod(url, token)
        if (Array.isArray(data) && data.length > 0) {
            let option = data.map(r => {
                return { text: r.version, value: r }
            })
            setAppVersionOption([...option])
        }
        setSelectedApp(e.target.value)
    }
    const onChangeAppVersionHandler = (e) => {
        setSelectedAppVersion(e.target.value)
    }
    const addApplication = () => {
        let obj = { ...selectedApp, currenetVersion: selectedAppVersion.version, currenetVersionId: selectedAppVersion.id }
        if (!isEmptyObject(selectedApp) && !isEmptyObject(selectedAppVersion)) {
            let found = false
            for (const app of applicationList) {
                if (app.applicationid == selectedApp.applicationid) {
                    found = true;
                    break
                }
            }
            if (!found)
                setConfigApp([...applicationList, { ...selectedApp, applicationVersionId: selectedAppVersion.id, currentVersion: selectedAppVersion.version, action: 0, showicon: 1, screenorder: 1 }])
            // setApplicationList([...applicationList, { ...selectedApp, applicationVersionId: selectedAppVersion.id, currentVersion: selectedAppVersion.version, action: 0, showIcon: true }])
            setSelectedApp({})
            setSelectedAppVersion({})
        }
    }
    const isUpgradable = (version, currentVersion) => {
        return version > currentVersion ? true : false
    }
    const updateApp = async (index, applicationId, version, currentVersion) => {
        let url = `${EndPoints.getConfig().appVersion}?applicationId=${applicationId}`
        const token = user ? user.access_token ? user.access_token : "" : ""
        let data = await getMethod(url, token)
        if (data && data.length) {
            for (const appVersion of data) {
                if (appVersion.id == version) {
                    let data = [...applicationList];
                    data[index]["applicationVersionId"] = version;
                    data[index]["currentVersion"] = appVersion.version
                    setConfigApp([...data])
                    // setApplicationList([...data])
                }
            }
        }
    }
    return (
        <div className={classes.root}>
            <Grid container width="100%" spacing={2}>
                <Grid item xs={12}>
                    <Box className="tab1-account" width={'100%'} marginLeft={0}>
                        <Box class="form-group" paddingX={10} paddingY={0} >
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Application</label>
                            </Box>
                            <Box className="add-application">
                                <Box style={{ width: "600px" }}>
                                    <FormControl
                                        variant="filled"
                                        className={classes.formControlFA}
                                    >
                                        <InputLabel id="ueTypeID" className={classes.listItemFA} style={{ color: 'whitesmoke' }} focused={false}>Select Application</InputLabel>
                                        <Select
                                            className={classes.listItemFA}
                                            id="ueType-select-filled"
                                            onChange={onChangeAppSelectHandler}
                                            name="application"
                                            value={selectedApp}
                                            disableUnderline={true}
                                        >
                                            {appOption &&
                                                appOption.map((uetype) => {
                                                    return (
                                                        <MenuItem value={uetype.value}>{uetype.text}</MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box style={{ width: "300px" }}>
                                    <FormControl
                                        variant="filled"
                                        className={classes.formControlFA}
                                    >
                                        <InputLabel id="ueTypeID" style={{ color: 'whitesmoke' }} className={classes.listItemFA} focused={false}>Version</InputLabel>
                                        <Select
                                            className={classes.listItemFA}
                                            id="ueType-select-filled"
                                            label="Select Application"
                                            onChange={onChangeAppVersionHandler}
                                            value={selectedAppVersion}
                                            disableUnderline={true}
                                        >
                                            {appVersionOption &&
                                                appVersionOption.map((uetype) => {
                                                    return (
                                                        <MenuItem value={uetype.value}>{uetype.text}</MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <button className="upload-btn" onClick={addApplication}>Add</button>
                            </Box>
                        </Box>
                        <TableContainer component={Paper} style={{ backgroundColor: '#4B4A4A' }}>
                            <Table className="" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ color: 'whitesmoke', fontFamily: "Muli" }}>Application Name</TableCell>
                                        <TableCell style={{ color: 'whitesmoke', fontFamily: "Muli" }}>Version</TableCell>
                                        <TableCell style={{ color: 'whitesmoke', fontFamily: "Muli" }}>Actions</TableCell>
                                        <TableCell style={{ color: 'whitesmoke', fontFamily: "Muli" }}>Icon</TableCell>
                                        <TableCell style={{ color: 'whitesmoke', fontFamily: "Muli" }}>Order</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {applicationList.map((apps, idx) => {
                                        return <TableRow>
                                            <TableCell>
                                                <Box className="tcl-flex-col">
                                                    <label style={{ color: 'whitesmoke' }} className="attribute-heading">{apps.name}</label>
                                                    <label style={{ color: 'whitesmoke' }} className="staus-barlabel">{apps.packageId}</label>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box className="tcl-flex-col">
                                                    <label style={{ color: 'whitesmoke' }} className="attribute-heading">{apps.currentVersion}</label>
                                                    {isUpgradable(apps.latestversion, apps.applicationVersionId) ?
                                                        <button  onClick={() => updateApp(idx, apps.applicationid, apps.latestversion, apps.applicationVersionId)}  class="update-btn-upgrade"
                                type="button">Upgrade</button>
                                                         : null} 
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box style={{ width: "170px" }}>
                                                    <FormControl
                                                        variant="filled"
                                                        className={classes.formControlUEType}
                                                    >
                                                        <InputLabel id="Action" className={classes.listItemFA} focused={false}>
                                                            Action
                                                        </InputLabel>
                                                        <Select
                                                            className={classes.listItemFA}
                                                            onChange={(e) => onSelectHandler(e, idx)}
                                                            name="action"
                                                            value={apps.action}
                                                            disableUnderline={true}
                                                        >
                                                            {appActionOption &&
                                                                appActionOption.map((uetype) => {
                                                                    return (
                                                                        <MenuItem value={uetype.value}>{uetype.text}</MenuItem>
                                                                    );
                                                                })}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box style={{ width: "120px" }}>
                                                    <FormControl
                                                        variant="filled"
                                                        className={classes.formControlUEType}
                                                    >
                                                        <InputLabel id="Icon" className={classes.listItemFA} focused={false}>
                                                            Icon
                                                        </InputLabel>
                                                        <Select
                                                            className={classes.listItemFA}
                                                            onChange={(e) => onSelectHandler(e, idx)}
                                                            name="showicon"
                                                            value={apps.showicon}
                                                            disableUnderline={true}
                                                        >
                                                            {iconOption &&
                                                                iconOption.map((uetype) => {
                                                                    return (
                                                                        <MenuItem value={uetype.value}>{uetype.text}</MenuItem>
                                                                    );
                                                                })}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <TextField type="number" style={{ width: "50px" }} name="screenorder" value={apps.screenorder} onChange={(e) => onSelectHandler(e, idx)} />
                                            </TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <label class="staus-barlabel">Hint: to unlink the app from the configuration, select the action "Block" or "Do not install".</label>
                    </Box>
                    <Box display={"flex"} flexDirection={"row-reverse"} width={"100%"}>
                        <div className="form-group">
                            <button
                                class="update-btn-profile"
                                type="button"
                                onClick={buttonHandler}
                            >
                                Next
                            </button>
                        </div>
                    </Box>
                </Grid>
            </Grid>

        </div >
    );
};

const mapStateToProps = ({ mdm, auth }) => {
    return {
        appList: mdm.appList,
        user: auth.user,
        applicationList: mdm.configApplicationList
    };
};

export default connect(mapStateToProps, {
    fetchAppList,
    setConfigApp,
    fetchConfigApp,
    appReln,
})(ConfigDesignSetting);
