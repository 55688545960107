(function (exports) {
  const js2xmlparser = require("js2xmlparser");
  const DOMParser = require("xmldom").DOMParser;
  const xmlToJSON = require("xmltojson");
  const base64 = require("base64-arraybuffer");
  const SDPTransform = require("../PttMessage/sdptransform").SDPTransform;
  xmlToJSON.stringToXML = (string) =>
    new DOMParser().parseFromString(string, "text/xml");

  var uuid = require("node-uuid");
  const CRLF = "\r\n";
  const ANS_MODE_MANUAL = "Manual";
  const ANS_MODE_AUTO = "Auto";

  const SESSION_TYPE_PRIVATE = "Private";
  const SESSION_TYPE_PREARRANGED = "Prearranged";
  const SESSION_TYPE_FIRST_TO_ANSWER = "first-to-answer";

  const DUPLEX_INDIVIDUAL_CALL = "DUPLEX_INDIVIDUAL_CALL";
  const DUPLEX_INDIVIDUAL_DIRECT_CALL = "DUPLEX_INDIVIDUAL_DIRECT_CALL";
  const SIMPLEX_INDIVIDUAL_HOOK_CALL = "SIMPLEX_INDIVIDUAL_HOOK_CALL";
  const SIMPLEX_INDIVIDUAL_DIRECT_CALL = "SIMPLEX_INDIVIDUAL_DIRECT_CALL";
  const SIMPLEX_GROUP_CALL = "SIMPLEX_GROUP_CALL";
  const SIMPLEX_BROADCAST_GROUP_CALL = "SIMPLEX_BROADCAST_GROUP_CALL";

  const GROUP_CALL = "SIMPLEX_BROADCAST_GROUP_CALL";

  const GROUP_HOOK_CALL = "GROUP_HOOK_CALL";
  const CALL_TYPE_ERROR = "CALL_TYPE_ERROR";
  class Util {

    getMultipartBoundary(request) {
      try {
        let boundary = "";
        if (request.headers.hasOwnProperty("Content-Type")) {
          boundary = request.getHeader("Content-Type");
        } else if (request.headers.hasOwnProperty("content-type")) {
          boundary = request.getHeader("content-type");
          if (!boundary) {
            boundary = request.headers["content-type"];
          }
        }
        // getMultipartBoundary boundary [{"parsed": "multipart/mixed;boundary=consort-boundary", "raw": "multipart/mixed;boundary=consort-boundary"}]
        //  LOG  getMultipartBoundary [TypeError: boundary.substring is not a function. (In 'boundary.substring(boundary.indexOf("=") + 1)', 'boundary.substring' is undefined)]
        // console.log(
        //   "getMultipartBoundary ",
        //   request.headers,
        //   request.getHeader("Content-Type")
        // );
        //console.log("getMultipartBoundary", "boundary", boundary);
        if (!boundary) {
          return null;
        }
        boundary = boundary.substring(boundary.search("=") + 1);
        return CRLF + `--${boundary}`;
      } catch (e) {
        console.log("getMultipartBoundary", e);
        return null;
      }
    }

    mcpttinfoJsonToXml(mcpttInfoJson, parsekey = "mcdata-Params") {
      try {
        return js2xmlparser.parse(parsekey, mcpttInfoJson);
      } catch (e) {
        console.log("mcpttinfoJsonToXml", e);
      }
    }

    mcpttinfoJsonToXml(mcpttInfoJson, sample, mimeType) {
      var options = {
        declaration: {
          encoding: "UTF-8",
        },
      };
      let root = "mcpttinfo";
      if (mimeType) {
        root = mimeType + "info";
      }
      try {
        return js2xmlparser.parse(root, mcpttInfoJson, options);
      } catch (e) {
        console.log("mcpttinfoJsonToXml", e);
      }
    }

    resourceListJsonToXml(resourceListJson) {
      var options = {
        declaration: {
          encoding: "UTF-8",
        },
      };
      try {
        return js2xmlparser.parse("resource-lists", resourceListJson, options);
      } catch (e) {
        console.log("resourceListJsonToXml", e);
      }
    }

    resourceListJsonToXml(resourceListJson, option) {
      var options = {};
      if (option) {
        options = {
          declaration: {
            encoding: "UTF-8",
          },
        };
      }
      // console.log(
      //   "resourceListJsonToXml........1",
      //   option,
      //   options,
      //   resourceListJson
      // );
      try {
        let parsedRList = js2xmlparser.parse(
          "resource-lists",
          resourceListJson,
          options
        );
        //console.log("resourceListJsonToXml........2", parsedRList);
        return parsedRList;
      } catch (e) {
        console.log("resourceListJsonToXml", e);
      }
    }

    mcpttinfoXmlToJson(request) {
      try {
        let mcpttInfo;
        let boundary = this.getMultipartBoundary(request);
        if (boundary == null) {
          // console.log("mcpttinfoXmlToJson boundary", boundary);
          //headers: { "content-type": boundary } 
          if (request && request.headers && request.headers["content-type"]) {
            boundary = request.headers["content-type"];
          } else {
            console.log("mcpttinfoXmlToJson boundaryy ", request.headers, request.headers["content-type"]);
          }
        }
        // console.log("mcpttinfoXmlToJson boundary1..", boundary);
        let multipartBody = [];
        if (request.body) {
          multipartBody = request.body.split(boundary);
        } else if (request.content) {
          multipartBody = request.content.split(boundary);
        }
        multipartBody.forEach((part) => {
          if (part.includes("vnd.3gpp.mcptt-info+xml")) {
            mcpttInfo = part;
          } else if (part.includes("vnd.3gpp.mcvideo-info+xml")) {
            mcpttInfo = part;
          }
        });
        if (mcpttInfo) {
          mcpttInfo = mcpttInfo
            ? mcpttInfo.substring(mcpttInfo.search("<"))
            : false;
          // var myOptions = {
          //   mergeCDATA: false,
          //   xmlns: false,
          //   attrsAsObject: false,
          // };
          mcpttInfo = xmlToJSON.parseString(mcpttInfo);
        }
        return mcpttInfo;
      } catch (e) {
        console.log("mcpttinfoXmlToJson", e);
      }
    }

    resourceListXmlToJson(request) {
      try {
        let resourceList;
        let boundary = this.getMultipartBoundary(request);
        let multipartBody = [];
        if (request.body) {
          multipartBody = request.body.split(boundary);
        } else if (request.content) {
          multipartBody = request.content.split(boundary);
        }
        multipartBody.forEach((part) => {
          if (part.includes("resource-lists+xml")) {
            resourceList = part;
          }
        });
        if (resourceList) {
          resourceList = resourceList
            ? resourceList.substring(resourceList.search("<"))
            : false;
          var myOptions = {
            mergeCDATA: false,
            xmlns: false,
            attrsAsObject: false,
          };
          console.log(resourceList);

          resourceList = xmlToJSON.parseString(resourceList, myOptions);
          console.log(resourceList);
        }
        return resourceList;
      } catch (e) {
        console.log("resourceListXmlToJson", e);
      }
    }

    resourceListXmlToJsonWithoutBoundary(request) {
      try {
        let resourceList = request.body;
        resourceList = resourceList
          ? resourceList.substring(resourceList.search("<"))
          : false;
        var myOptions = {
          mergeCDATA: false,
          xmlns: false,
          attrsAsObject: false,
        };
        resourceList = xmlToJSON.parseString(resourceList, myOptions);
        console.log(resourceList);
        return resourceList;
      } catch (e) {
        console.log("resourceListXmlToJsonWithoutBoundary", e);
        throw e;
      }
    }

    xmlJsonToKeyJson(mcpttInfo) {
      try {
        let mcpttParamsObject = {};
        if (mcpttInfo.hasOwnProperty("mcpttinfo")) {
          let mcpptParams = mcpttInfo["mcpttinfo"][0]["mcptt-Params"];
          this.recursionJson(mcpptParams, "mcptt-Params", mcpttParamsObject);
          return mcpttParamsObject["mcptt-Params"];
        } else {
          let mcpptParams = mcpttInfo["mcvideoinfo"][0]["mcvideo-Params"];
          this.recursionJson(mcpptParams, "mcvideo-Params", mcpttParamsObject);
          return mcpttParamsObject["mcvideo-Params"];
        }
      } catch (e) {
        throw e;
      }
    }

    recursionJson(mcpttParams, key, mcpttParamsObject) {
      try {
        if (!mcpttParams[0] && typeof mcpttParams !== "object") {
          return;
        }
        for (let param of mcpttParams) {
          if (param.hasOwnProperty("_text")) {
            mcpttParamsObject[key] = param["_text"];
            return;
          }
          if (typeof param === "object") {
            for (let objkey of Object.keys(param)) {
              if (objkey !== 0 || objkey !== "0") {
                if (!mcpttParamsObject.hasOwnProperty(key)) {
                  mcpttParamsObject[key] = {}
                }
                this.recursionJson(param[objkey], objkey, mcpttParamsObject[key]);
              }
            }
          }
        }
        return;
      } catch (e) {
        return;
      }
    }

    getLocalSdp(request) {
      try {
        let contentType = "application/sdp";
        let sdpContent;
        let boundary = this.getMultipartBoundary(request);
        let multipartBody = [];
        if (request.body) {
          multipartBody = request.body.split(boundary);
        } else if (request.content) {
          multipartBody = request.content.split(boundary);
        }
        multipartBody.forEach((part) => {
          if (part.includes(contentType)) {
            sdpContent = part;
          }
        });
        //console.log("getLocalSdp ", sdpContent, boundary, multipartBody);
        // sdpContent = sdpContent.trimRight() + CRLF;
        if (!sdpContent) {
          return null;
        }
        sdpContent = sdpContent.substring(
          sdpContent.indexOf("v=0"),
          sdpContent.length
        );
        //console.log("getLocalSdp  2 ", sdpContent, " test");
        return sdpContent;
      } catch (e) {
        console.log("getLocalSdp error ", e);
        return null;
      }
    }

    getLocalSdpWithBody(body, boundary) {
      try {
        let contentType = "application/sdp";
        let sdpContent;
        let multipartBody = body.split(boundary);

        multipartBody.forEach((part) => {
          if (part.includes(contentType)) {
            sdpContent = part;
          }
        });
        //console.log("getLocalSdp ", sdpContent, boundary, multipartBody);
        // sdpContent = sdpContent.trimRight() + CRLF;
        if (!sdpContent) {
          return null;
        }
        sdpContent = sdpContent.substring(
          sdpContent.indexOf("v=0"),
          sdpContent.length
        );
        //console.log("getLocalSdp  2 ", sdpContent, " test");
        return sdpContent;
      } catch (e) {
        console.log("getLocalSdp error ", e);
        return null;
      }
    }

    getReferBodyParsedObject(req) {
      console.log("getReferBodyParsedObject()");
      let sessionSipUri = "";
      if (req.headers.hasOwnProperty("To")) {
        sessionSipUri = req.getHeader("To").replace("<", "").replace(">", "");
      } else if (req.headers.hasOwnProperty("to")) {
        sessionSipUri = req.getHeader("to").replace("<", "").replace(">", "");
      } else if (req.headers.hasOwnProperty("TO")) {
        sessionSipUri = req.getHeader("TO").replace("<", "").replace(">", "");
      }
      // console.log("sessionSipUri ", sessionSipUri);
      let preResourceList = this.resourceListXmlToJsonWithoutBoundary(req);
      //console.log("getReferBodyParsedObject preResourceList ", preResourceList);
      let preResourceListObj = {};
      if (preResourceList) {
        if (
          preResourceList.hasOwnProperty("resource-list") && preResourceList["resource-list"]
          && preResourceList["resource-list"].length > 0) {
          let resourceListEntries = preResourceList["resource-list"][0]["list"][0]["entry"];
          for (let entry of resourceListEntries) {
            preResourceListObj["uri"] = entry["_attruri"]["_value"];
          }
        } else {
          let resourceListEntries = preResourceList["resource-lists"][0]["list"][0]["entry"];
          for (let entry of resourceListEntries) {
            preResourceListObj["uri"] = entry["_attruri"]["_value"];
          }
        }
      }
      //console.log("getReferBodyParsedObject preResourceListObj ", preResourceListObj);
      let referCallBody = preResourceListObj["uri"].split("?")[1];
      let pageHref = `?${referCallBody}`;
      //let searchParams = Object.fromEntries(new URLSearchParams(pageHref));
      //console.log("searchParams ============", pageHref);
      let searchParams = this.getURlSearchParams(pageHref);
      //console.log("searchParams ============1", searchParams, searchParams["Answer-Mode"]);
      // Construct a new object and pass the page href to URLSearchParams
      let ansMode = searchParams["Answer-Mode"];
      let mcpttParamsObject = {};
      let boundary = searchParams["Content-Type"];
      //"Content-Type":"multipart/mixed;boundary=consort-boundary"
      if (boundary) {
        let boundaries = boundary.split("=");
        if (boundaries && boundaries.length > 0) {
          boundary = boundaries[1];
        }
      }
      // decode uri component in body
      let decodedBodyComponents = [];
      for (let bodyItem of searchParams["body"].split("--" + boundary)) {
        decodedBodyComponents.push(decodeURIComponent(bodyItem))
      }
      let parsedBody = decodedBodyComponents.join("--" + boundary);
      let mcpttInfo = this.mcpttinfoXmlToJson(
        { body: parsedBody, headers: { "content-type": boundary } });

      //console.log("getReferBodyParsedObject mcpttInfo ", mcpttInfo);

      if (mcpttInfo) {
        mcpttParamsObject = this.xmlJsonToKeyJson(mcpttInfo);
      }
      let dataToReturn = { mcpttParamsObject, resourceListObject: preResourceListObj, sessionSipUri, ansMode, parsedBody, boundary };
      //console.log("getReferBodyParsedObject parsed ", dataToReturn);
      return dataToReturn;
    }

    getURlSearchParams(url) {
      let regex = /[?&]([^=#]+)=([^&#]*)/g,
        params = {},
        match;
      while (match = regex.exec(url)) {
        params[match[1]] = match[2];
      }
      return params;
    }

    arrayBodyToMultipartBody(arrayBody, boundary) {
      try {
        //return arrayBody.join(`--${boundary}  `);
        //console.log("arrayBodyToMultipartBody..", arrayBody);
        return this.generateMultipartBody(arrayBody, { boundary });
      } catch (e) {
        console.log("arrayBodyToMultipartBody", e);
      }
    }

    arrayBodyToMultipartBody(arrayBody, boundary, stringfy) {
      try {
        //return arrayBody.join(`--${boundary}  `);
        //console.log("arrayBodyToMultipartBody..", arrayBody);
        return this.generateMultipartBody(arrayBody, { boundary, stringfy });
      } catch (e) {
        console.log("arrayBodyToMultipartBody", e);
      }
    }

    multipartBodyToArrayBody(request) {
      try {
        let boundary = this.getMultipartBoundary(request);
        return request.body.split(boundary);
      } catch (e) {
        console.log("multipartBodyToArrayBody", e);
      }
    }

    generateMultipartBody(tuples, options) {
      try {
        if (tuples.length === 0) {
          // according to rfc1341 there should be at least one encapsulation
          throw new Error(
            "Missing argument. At least one part to generate is required"
          );
        }

        options = options || {};
        var from = options.from;
        var preamble = options.preamble;
        var epilogue = options.epilogue;
        var stringfy = options.stringfy;
        var boundary = options.boundary || uuid();

        if (boundary.length < 1 || boundary.length > 70) {
          throw new Error(
            "Boundary should be between 1 and 70 characters long"
          );
        }
        // var headers = [
        //   "From: " + (from || "nobody " + Date()),
        //   "MIME-Version: 1.0",
        //   'Content-Type: multipart/mixed; boundary="' + boundary + '"',
        // ];

        var delimiter = CRLF + "--" + boundary;
        var closeDelimiter = delimiter + "--";

        var encapsulations = tuples.map(function (tuple, i) {
          var mimetype = tuple.mime;
          var encoding = tuple.encoding;
          var encodingType = "base64";
          var headers = [
            "Content-Type: " + mimetype,

            // + encoding
            //   ? '; charset="' + encoding + '"'
            //   : "",
          ];
          var bodyPart;
          if (
            tuple.mime.includes("mcdata-signalling") ||
            tuple.mime.includes("mcdata-payload")
          ) {
            headers = [
              "Content-Type: " + mimetype,
              "Content-Transfer-Encoding: " + encodingType,
            ];
            bodyPart =
              headers.join(CRLF) + CRLF + CRLF + base64.encode(tuple.content);
          } else {
            bodyPart = headers.join(CRLF) + CRLF + CRLF + tuple.content;
          }

          // if (stringfy) {
          //   let xmlSerializer = new XMLSerializer();

          //   let content = encodeURIComponent(delimiter + CRLF + bodyPart);
          //   // xml2js.parseString(content, function (err, result) {
          //   //   console.dir("parse result", result);
          //   // });
          //   console.log(
          //     "bodyPart stringfy ",
          //     tuple.content.length,
          //     bodyPart.length,
          //     xmlSerializer.serializeToString(delimiter + CRLF + bodyPart),
          //     xmlSerializer.serializeToString(bodyPart),
          //     XML.stringify(delimiter + CRLF + bodyPart),
          //     content
          //   );
          //   return content;
          // }
          // console.log(
          //   "bodyPart ",
          //   tuple.content.length,
          //   bodyPart.length,
          //   bodyPart
          // );
          return delimiter + CRLF + bodyPart;
        });

        var multipartBody = [
          preamble ? CRLF + preamble : undefined,
          encapsulations.join(""),
          closeDelimiter,
          epilogue ? CRLF + epilogue : undefined,
        ].filter(function (element) {
          return !!element;
        });
        let multi = multipartBody.join("");
        console.log("multi.length ", multi.length);
        return multi;
      } catch (e) {
        throw e;
      }
    }

    getPreEstByeHeaders(callObject) {
      let headers = [];
      let isVideo = callObject.isVideo;
      let mimeType = isVideo ? "mcvideo" : "mcptt";
      let preferredIdentity = callObject.preferredIdentity;
      headers = ["Refer-To: " + "<sip:" + preferredIdentity + ";method=BYE>"];
      return headers;
    }

    getPreEstCallHeaders(callObject) {
      // callObject,
      let answerMode = callObject.answerMode;
      let contact = callObject.contact;
      let preferredIdentity = callObject.preferredIdentity;
      let callPriority = callObject.callPriority;
      let from = callObject.fromId;
      let toId = callObject.toId;
      let method = callObject.method ? callObject.method : "";
      let contentId = callObject.contentId;
      let contentIdWithoutSip = callObject.contentId;
      if (contentId && contentId.toLowerCase().includes("<sip")) {
        contentIdWithoutSip = contentId.replace("<sip:", "").replace(">", "");
      }

      callObject.contentId.toLowerCase().includes("<sip");
      let headers = [];
      let isVideo = callObject.isVideo;
      let mimeType = isVideo ? "mcvideo" : "mcptt";

      headers = [
        // "Call-ID: " + callObject.callId,
        "From: " + "<sip:" + preferredIdentity + ">",
        "To: " + "<sip:" + toId + ">",
        // "Answer-Mode: " + answerMode,
        "Contact: " + contact,
        `Accept-Contact: g.3gpp.${mimeType};require;explicit`,
        "Accept-Contact: " +
        `g.3gpp.icsi-ref="urn:urn-7:3gpp-service.ims.icsi.${mimeType}";require;explicit`,

        `P-Preferred-Service: urn:urn-7:3gpp-service.ims.icsi.${mimeType}`,

        "P-Preferred-Identity: " + "<sip:" + preferredIdentity + ">", //</sip:IP>
        "Supported: norefersub",
        "Refer-Sub: false",
        "Require: multiple-refer",
        // "Content-Type: " + "application/resource-lists+xml",
        `Resource-Priority: ${mimeType}p.${callPriority}`,
        "Refer-To: " +
        "<cid:" +
        contentIdWithoutSip +
        "> " +
        "Content-ID: " +
        contentIdWithoutSip,
        // "Refer-To: " + "<cid:" + contentIdWithoutSip + method != ""
        //   ? ";method=" + method
        //   : "" + "> " + "Content-ID: " + contentIdWithoutSip,
      ];
      //console.log("PreEstCallHeaders ", "PreEstCallHeaders", headers);

      return headers;
    }

    getCallHeaders(
      callObject,
      answerMode,
      contact,
      preferredIdentity,
      callPriority
    ) {
      // call-id :uq0rli1c0tcvcagdv1b8ib52ibf59j,
      // Answer-Mode: Manual,
      // Contact: sip:mcx.cons-437.nmrp@ims.mnc001.mcc001.3gppnetwork.org;+g.3gpp.icsi-ref="urn%3Aurn-7%3A3gpp-service.ims.icsi.mcptt";+g.3gpp.mcptt,
      // Accept-Contact: g.3gpp.mcptt;require;explicit,
      // Accept-Contact: g.3gpp.icsi-ref="urn:urn-7:3gpp-service.ims.icsi.mcptt";require;explicit,
      // P-Preferred-Service: urn:urn-7:3gpp-service.ims.icsi.mcptt,P-Preferred-Identity: ,
      // Content-Type: multipart/mixed; boundary=[boundary]
      // console.log(
      //   "callObject, answerMode, contact, preferredIdentity ",
      //   callObject,
      //   answerMode,
      //   contact,
      //   preferredIdentity
      // );
      let headers = [];
      let isVideo = callObject.isVideo;
      let mimeType = isVideo ? "mcvideo" : "mcptt";
      headers = [
        // "Call-ID: " + callObject.callId,

        "Answer-Mode: " + answerMode,

        "Contact: " + contact,

        `Accept-Contact: g.3gpp.${mimeType};require;explicit`,
        "Accept-Contact: " +
        `g.3gpp.icsi-ref="urn:urn-7:3gpp-service.ims.icsi.${mimeType}";require;explicit`,

        `P-Preferred-Service: urn:urn-7:3gpp-service.ims.icsi.${mimeType}`,

        "P-Preferred-Identity: " + "<sip:" + preferredIdentity + ">", //</sip:IP>

        `Resource-Priority: ${mimeType}p.${callPriority}`,
        "Content-Type: " + "multipart/mixed; boundary=consort-boundary",
      ];
      console.log("headers ", "headers", headers);
      // "Contact: <sip:mcx.cons-437.nmrp@ims.mnc001.mcc001.3gppnetwork.org;ob>",
      //                 Contact: <sip:IP:PORT>;+g.3gpp.icsi-ref="urn%3Aurn-7%3A3gpp-service.ims.icsi.mcptt";+g.3gpp.mcptt
      //                 Accept-Contact: *;+g.3gpp.mcptt;require;explicit
      // Accept-Contact: *;+g.3gpp.icsi-ref="urn%3Aurn-7%3A3gpp-service.ims.icsi.mcptt";require;explicit
      // P-Preferred-Service: urn:urn-7:3gpp-service.ims.icsi.mcptt
      // P-Preferred-Identity: <sip:mcptt-clientA@example.com>//</sip:IP>
      // Answer-Mode: Manual
      // Resource-Priority: mcpttp.5
      // Content-Type: multipart/mixed; boundary=[boundary] //

      return headers;
    }

    getCallObjFromResponse(response) {
      // console.log(
      //   "getCallObjFromResponse.....",
      //   response["headers"]["Contact"][0]["parsed"]["_uri"],
      //   response.getHeaders("Contact")
      // );
      let mcpttID = response.getHeaders("Contact"); //response.getHeaders("Contact")[0].uri;
      //let body = response["body"]; //response.getHeaders("Contact")[0].uri;

      let preEstSessionUri = mcpttID[0]
        ? mcpttID[0].replace("<", "").replace(">", "").replace("sip:", "")
        : mcpttID[0];
      //console.log("getCallObjFromResponse..... 1", preEstSessionUri);
      return { preEstSessionUri: preEstSessionUri }; // body: body
    }

    getCallObjFromReferReq(req, mcpttId, isMcxUser, ongoingCalls) {
      console.log("getCallObjFromReferReq() ");
      let callObj = {};
      let toId = "";
      let fromId = "";
      let callType = "";
      let callPriority = "6";
      let isVideo = false;
      let callId = "";
      let indexId = "";
      let sessionType = "";
      let ansMode = "Auto";
      let groupId = null;
      let mimeType = isVideo ? "mcvideo" : "mcptt";
      let dataObj = this.getReferBodyParsedObject(req);
      //let dataToReturn = { mcpttParamsObject, resourceListObject: preResourceListObj, sessionSipUri, ansMode, parsedBody, boundary };
      let mcpttParamsObject = dataObj.mcpttParamsObject;
      // let resourceListObject = dataObj.resourceListObject;
      // let sessionSipUri = dataObj.sessionSipUri;
      let requestSdp = this.getLocalSdpWithBody(dataObj.parsedBody, dataObj.boundary);
      ansMode = dataObj.ansMode;

      if (req.headers.hasOwnProperty("call-id")) {
        callId = req.getHeader("call-id");
      } else if (req.headers.hasOwnProperty("CALL-ID")) {
        callId = req.getHeader("CALL-ID");
      } else if (req.headers.hasOwnProperty("Call-Id")) {
        callId = req.getHeader("Call-Id");
      } else {
        callId = req.getHeader("call-id");
      }

      console.log('mcpttParamsObject', mcpttParamsObject, callId);

      sessionType = mcpttParamsObject["session-type"];
      callPriority = mcpttParamsObject["emergency-ind"] ? mcpttParamsObject["emergency-ind"][`${mimeType}Boolean`] ? 15 : 11 : 11;

      let ptt = mcpttParamsObject["anyExt"] ? mcpttParamsObject["anyExt"]["ptt"] ? mcpttParamsObject["anyExt"]["ptt"] : false : false;
      let broadcastcall = mcpttParamsObject["broadcast-ind"] ? mcpttParamsObject["broadcast-ind"][`${mimeType}Boolean`] ? true : false : false;

      callType = this.getCallType(
        ansMode,
        sessionType,
        requestSdp,
        isMcxUser,
        ptt,
        broadcastcall
      );

      console.log("getCallType ", ansMode, sessionType, requestSdp);

      if (
        callType === SIMPLEX_GROUP_CALL ||
        callType === SIMPLEX_BROADCAST_GROUP_CALL
      ) {
        toId = mcpttParamsObject[`${mimeType}-request-uri`][`${mimeType}URI`];
        groupId = mcpttParamsObject[`${mimeType}-request-uri`][`${mimeType}URI`]; //mcptt info request uri
        fromId = mcpttParamsObject[`${mimeType}-calling-user-id`][`${mimeType}URI`];
        if (!fromId || fromId === "") {
          fromId = req.getHeader("From");
        }
      } else {
        toId = mcpttId; //resourceListObject["uri"];//registered user MCPTT id
        fromId = mcpttParamsObject[`${mimeType}-calling-user-id`][`${mimeType}URI`];
        if (!fromId || fromId === "") {
          fromId = req.getHeader("From"); //.uri; //calling user id, if not available then from
        }
        if (
          sessionType.toLowerCase() ==
          SESSION_TYPE_FIRST_TO_ANSWER.toLowerCase()
        ) {
          if (mcpttParamsObject["functional-alias-URI"])
            fromId = mcpttParamsObject["functional-alias-URI"][`${mimeType}URI`];
        }

        fromId = fromId.includes("sip:") ? fromId.replace("sip:", "") : fromId;
        toId = toId
          ? toId.includes("sip:")
            ? toId.replace("sip:", "")
            : toId
          : "";
      }

      if (Object.keys(ongoingCalls).length === 0) {
        indexId = 1;
      } else {
        let max = 0;
        //Get Unused indexId
        var unused = [];

        const usedIndexId = Object.keys(ongoingCalls).map((call) => {
          if (call.callObj) return call.callObj.indexId;
        });
        console.log("info", `used index Id: ${usedIndexId}`);
        usedIndexId.forEach((callId, i) => {
          if (!usedIndexId.includes(i + 1)) {
            max = callId;
            unused.push(i + 1);
          }
          if (callId > max) max = callId;
        });
        indexId = unused.length && unused[0] > 0 ? unused[0] : max + 1;
      }

      callObj = {
        communicationType: "CALL",
        toId: toId,
        fromId: fromId,
        callType: callType,
        callPriority: callPriority ? callPriority : 11,
        isVideo: isVideo == true ? true : false,
        indexId: indexId,
        callId: callId,
        isFACall:
          sessionType.toLowerCase() ==
          SESSION_TYPE_FIRST_TO_ANSWER.toLowerCase(),
        answerMode: ansMode,
      };

      if (groupId) {
        callObj.groupId = groupId;
      }

      return { callObj: callObj, sdp: requestSdp };
    }

    getCallObjFromRequest(
      req,
      domainsConfig,
      ongoingCalls,
      mcpttId,
      isMcxUser
    ) {
      try {
        let mcpttBodyUtils = this;
        let callObj = {};
        let mcpttID = "";
        let mcpttParamsObject = {};
        //let resourceListObject = {};
        let ptt = false;
        let broadcastcall = false;
        let isVideo = false;
        if (req && req.body && req.body.indexOf("mcvideo") > -1) {
          isVideo = true;
        }

        if (!req["headers"]["request-tetraid"]) {
          //Working flow
          let mcpttInfo = mcpttBodyUtils.mcpttinfoXmlToJson(req);
          //let resourceList = mcpttBodyUtils.resourceListXmlToJson(req);

          if (mcpttInfo) {
            if (mcpttInfo["mcvideoinfo"]) {
              isVideo = true;
            }
            let mimeType = isVideo ? "mcvideo" : "mcptt";
            let mcpptParams =
              mcpttInfo[`${mimeType}info`][0][`${mimeType}-Params`][0];
            for (let params of Object.keys(mcpptParams)) {
              mcpttParamsObject[params] = mcpptParams[params][0]["_text"];
              if (mcpptParams[params][0][`${mimeType}URI`]) {
                mcpttParamsObject[params] =
                  mcpptParams[params][0][`${mimeType}URI`][0]["_text"];
              }
              if (mcpptParams[params][0][`${mimeType}Boolean`]) {
                mcpttParamsObject[params] =
                  mcpptParams[params][0][`${mimeType}Boolean`][0]["_text"];
              }
              if (mcpptParams[params][0]["ptt"]) {
                mcpttParamsObject["ptt"] =
                  mcpptParams[params][0]["ptt"][0]["_text"];
              }
              if (mcpptParams[params][0]["functional-alias-URI"]) {
                let parsedAlias =
                  mcpptParams[params][0]["functional-alias-URI"];
                if (parsedAlias && parsedAlias[0][`${mimeType}URI`]) {
                  mcpttParamsObject["functional-alias-URI"] =
                    parsedAlias[0][`${mimeType}URI`][0]["_text"];
                }
              }
            }
          }
        } else {
          mcpttID = req.getHeaders("contact")[0].uri;
        }

        let fromId = "";
        let callId = req.getHeader("call-id");
        let ansMode = req.getHeader("answer-mode");
        let userAgent = "";
        if (req.headers.hasOwnProperty("user-agent")) {
          userAgent = req.getHeader("user-agent");
        }

        let toId = "";
        let callType = "";
        let sessionType = "";
        let indexId;
        let callPriority;
        let groupId = null;
        console.log('mcpttParamsObject', mcpttParamsObject);
        if (!req["headers"]["request-tetraid"]) {

          sessionType = mcpttParamsObject["session-type"];
          callPriority = mcpttParamsObject["emergency-ind"] ? 15 : 11;

          try {
            let mimeType = isVideo ? "mcvideo" : "mcptt";
            if (callPriority < 15)
              callPriority = parseInt(
                req.getHeader("resource-priority").replace(mimeType + "p.", "")
              );
          } catch (err) {
            console.log("callPriority logs", err);
          }

          ptt = mcpttParamsObject["ptt"] ? mcpttParamsObject["ptt"] : false;
          broadcastcall = mcpttParamsObject["broadcast-ind"]
            ? mcpttParamsObject["broadcast-ind"]
            : false;
          let requestSdp = mcpttBodyUtils.getLocalSdp(req);
          console.log("getCallType ", ansMode, sessionType, requestSdp);
          callType = this.getCallType(
            ansMode,
            sessionType,
            requestSdp,
            isMcxUser,
            ptt,
            broadcastcall
          );
          let mimeType = isVideo ? "mcvideo" : "mcptt";

          if (
            callType === SIMPLEX_GROUP_CALL ||
            callType === SIMPLEX_BROADCAST_GROUP_CALL
          ) {
            toId = mcpttParamsObject[`${mimeType}-request-uri`];
            groupId = mcpttParamsObject[`${mimeType}-request-uri`]; //mcptt info request uri
            fromId = mcpttParamsObject[`${mimeType}-calling-user-id`];
            if (!fromId || fromId === "") {
              fromId = req.getHeader("From"); //.uri; //calling user id, if not available then from
            }
          } else {
            toId = mcpttId; //resourceListObject["uri"];//registered user MCPTT id
            fromId = mcpttParamsObject[`${mimeType}-calling-user-id`];
            if (!fromId || fromId === "") {
              fromId = req.getHeader("From"); //.uri; //calling user id, if not available then from
            }
            if (
              sessionType.toLowerCase() ==
              SESSION_TYPE_FIRST_TO_ANSWER.toLowerCase()
            ) {
              if (mcpttParamsObject["functional-alias-URI"])
                fromId = mcpttParamsObject["functional-alias-URI"];
            }
          }
        } else {
          const from = req.getHeaders("contact")[0].uri;
          fromId = from.substring(0, from.search(";"));

          isVideo = req.getHeader("is-video");
          let mimeType = isVideo ? "mcvideo" : "mcptt";
          //let toUri = req.getHeader("request-uri");
          toId = req.getHeader("request-uri");
          //Resource-Priority: mcpttp.14
          indexId = parseInt(req.getHeader("Call-Index"));
          callPriority = parseInt(
            req.getHeader("resource-priority").replace(mimeType + "p.", "")
          );
          sessionType = req.getHeader("session-type");
          if (ansMode === "Manual") {
            if (sessionType === "private") {
              if (req.getHeader("X-MCX-Floor")) {
                callType = "SIMPLEX_INDIVIDUAL_DIRECT_CALL";
              } else {
                callType = "DUPLEX_INDIVIDUAL_CALL";
              }
            }
          } else if (ansMode === "Auto") {
            if (sessionType === "private") {
              callType = "SIMPLEX_INDIVIDUAL_HOOK_CALL";
            } else if (sessionType === "prearranged") {
              callType = "SIMPLEX_GROUP_CALL";
            }
          }
          if (callType.includes("GROUP")) {
            groupId = toId;
          }
          console.log("callTypecallTypecallType", callType);
        }

        if (Object.keys(ongoingCalls).length === 0) {
          indexId = 1;
        } else {
          let max = 0;
          //Get Unused indexId
          var unused = [];

          const usedIndexId = Object.keys(ongoingCalls).map((call) => {
            if (call.callObj) return call.callObj.indexId;
          });
          console.log("info", `used index Id: ${usedIndexId}`);
          usedIndexId.forEach((callId, i) => {
            if (!usedIndexId.includes(i + 1)) {
              max = callId;
              unused.push(i + 1);
              //console.log("info", `Not used ${i + 1}`);
              //return (i+1); //[4,2,5]
            }
            //max = callId;
            if (callId > max) max = callId;
          });
          // unused //[1] max//3

          indexId = unused.length && unused[0] > 0 ? unused[0] : max + 1;
          //console.log("info", `Setting Index: ${unused}, max : ${max}`); // [1,3]
        }
        fromId = fromId.includes("sip:") ? fromId.replace("sip:", "") : fromId;
        toId = toId
          ? toId.includes("sip:")
            ? toId.replace("sip:", "")
            : toId
          : "";

        callObj = {
          communicationType: "CALL",
          toId: toId,
          fromId: fromId,
          callType: callType,
          callPriority: callPriority ? callPriority : 11,
          isVideo: isVideo == true ? true : false,
          indexId: indexId,
          callId: callId,
          isFACall:
            sessionType.toLowerCase() ==
            SESSION_TYPE_FIRST_TO_ANSWER.toLowerCase(),
          answerMode: ansMode,
        };

        if (groupId) {
          callObj.groupId = groupId;
        }
        return callObj;
      } catch (e) {
        console.log("getCallObjFromRequest", e);
      }
    }

    getCallType(ansMode, sessionType, sdp, isMcxUser, ptt, broadcastcall) {
      try {
        let isIndividualCall =
          sessionType.toLowerCase() === SESSION_TYPE_PRIVATE.toLowerCase() ||
          sessionType.toLowerCase() ===
          SESSION_TYPE_FIRST_TO_ANSWER.toLowerCase();
        let callType = "";
        let mlineExists = ptt;
        if (isMcxUser) {
          let sdptransform = new SDPTransform();
          mlineExists = sdptransform.isMLineExistsApplication(sdp);
        }
        // console.log(
        //   "getCallType mlineExists ...",
        //   mlineExists,
        //   ansMode,
        //   sessionType,
        //   ansMode.toLowerCase() === ANS_MODE_MANUAL.toLowerCase() &&
        //     isIndividualCall &&
        //     !mlineExists,
        //   !mlineExists,
        //   ansMode.toLowerCase() === ANS_MODE_MANUAL.toLowerCase(),
        //   isIndividualCall
        // );

        if (
          ansMode.toLowerCase() === ANS_MODE_MANUAL.toLowerCase() &&
          isIndividualCall &&
          !mlineExists
        ) {
          callType = DUPLEX_INDIVIDUAL_CALL;
        } else if (
          ansMode.toLowerCase() === ANS_MODE_AUTO.toLowerCase() &&
          isIndividualCall &&
          !mlineExists
        ) {
          callType = DUPLEX_INDIVIDUAL_DIRECT_CALL;
        } else if (
          ansMode.toLowerCase() === ANS_MODE_MANUAL.toLowerCase() &&
          isIndividualCall &&
          mlineExists
        ) {
          callType = SIMPLEX_INDIVIDUAL_HOOK_CALL;
        } else if (
          ansMode.toLowerCase() === ANS_MODE_AUTO.toLowerCase() &&
          isIndividualCall &&
          mlineExists
        ) {
          callType = SIMPLEX_INDIVIDUAL_DIRECT_CALL;
        } else if (
          ansMode.toLowerCase() === ANS_MODE_AUTO.toLowerCase() &&
          sessionType.toLowerCase() ===
          SESSION_TYPE_PREARRANGED.toLowerCase() &&
          mlineExists
        ) {
          if (broadcastcall) {
            callType = SIMPLEX_BROADCAST_GROUP_CALL;
          } else {
            callType = SIMPLEX_GROUP_CALL;
          }
        } else if (
          ansMode.toLowerCase() === ANS_MODE_AUTO.toLowerCase() &&
          sessionType.toLowerCase() ===
          SESSION_TYPE_PREARRANGED.toLowerCase() &&
          mlineExists
        ) {
          callType = GROUP_HOOK_CALL;
        } else if (sessionType === SESSION_TYPE_PREARRANGED && !mlineExists) {
          callType = CALL_TYPE_ERROR;
        }
        //console.log("getCallType mlineExists", callType);
        return callType;
      } catch (e) {
        console.log("getCallType err ", e);
        throw e;
      }
    }
  }

  exports.Util = Util;
})(typeof exports === "undefined" ? (this["Util"] = {}) : exports);
