import React,{ useEffect,useState } from "react";
import { connect } from "react-redux";
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Box } from '@mui/material'
import { fetchDeviceInfo } from "../../modules/actions/mdmAction";
import { setZoomLevel, setMapCenterPosition } from '../../modules/actions/locationAction'

import './style.css'
 import MapContainer from "../../containers/location/MapContainer";
 import axios from "axios";
import { EndPoints } from "../../MCXclient/endpoints";
 const DefaultAlertRuleObj = {
    form: {
      type: "all",
      userList: [],
    },
    detectionType: "both",
    alertId: "",
    receiverId: "",
  };
const DefaultPOIIbject = {
    // id:1,
    title: "",
    leafletId: 0,
    description: "",
    layerType: "POI",
    presenceRadius: 10,
    radiusUnit: "m",
    markerIcon: "",
    layerID: "",
    createdBy: "",
    alert: false,
    alertRule: DefaultAlertRuleObj,
  };
  const DefaultFenceObject = {
    title: "",
    leafletId: 0,
    description: "",
    layerType: "fence",
    markerIcon: "",
    layerID: "",
    createdBy: "",
    alert: false,
    alertRule: DefaultAlertRuleObj,
  };
const DeviceLocation = (props)=>{
    
  const {switchlocationWindow ,selectedDeviceLocID,deviceInfo,tenantId,fetchDeviceInfo,setZoomLevel,setMapCenterPosition} = props;
  const [POIrenderingList, setPOIrenderingList] = useState(null);
  const [addedLayerType, setAddedLayerType] = useState("");
  const [fencesCoords, setFencesCoords] = useState(null);
const [filteredUsersList, setFilteredUsersList] = useState([]);

const [newFenceObj, setNewFenceObj] = useState(DefaultFenceObject);
const [isAskDGNAPopup, setisAskDGNAPopup] = useState(false);
const [isAddPopupOpen, setisAddPopupOpen] = useState(false);
let [fenceList, setFenceList] = useState([]);
const [newPOIObj, setNewPOIObj] = useState(DefaultPOIIbject);
  const [allUsersList, setAllUsersList] = useState([]);

const getAllUsers = () => {
  console.log("Get Current Location URL ", EndPoints.getConfig().getCurrenLocationMdm);
  var reqObject = {
    "userName":selectedDeviceLocID.userId,
    "ueID":"",
    "deviceId":selectedDeviceLocID.deviceId    
    
    }

  const res = axios
    .post(EndPoints.getConfig().getCurrenLocationMdm, reqObject)
    .then(
      (res) => {
      console.log("ALL USERS RES ", JSON.stringify(res.data));
        var data = res.data;
        data = data.map((item) => {
          if (item.message !== "") {
            const updatedItem = {
              ...item,
              message: JSON.parse(item.message),
            };

            return updatedItem;
          }

          return item;
        });

        //console.log("NOW USERS  ", data);
        if (data && data.length >= 0) {
         
          setAllUsersList(data);
        }
      },
      (err) => {
        console.error("Err in Fetching current location", err);
      }
    )
    .catch((err) => {
      console.error("Err in Fetching current location", err);
    });
};
const userFilterHandler = () => {
  let time = new Date();
  if (allUsersList && Array.isArray(allUsersList)) {
    if(allUsersList.length>0){
    let filteredUser = allUsersList[allUsersList.length-1];
    
    

    
    setFilteredUsersList([filteredUser]);
    console.log("Filtered Userr:", [filteredUser]);
    }
    else{
      setFilteredUsersList([]);
    }
    setTimeout(() => {
      if ((new Date() - time) >= 60000)
        getAllUsers();
    }, 60000)
  }
  }

useEffect(() => {
  console.log("userfilter calling");
  userFilterHandler();
}, [allUsersList]);
useEffect(() => {
  getAllUsers();
}, []);



    const handleclick=()=>{
        switchlocationWindow();
    }
    
    const startGroupCall = (dgnaGroupId) => {
        console.log("GROUP CALL ID", dgnaGroupId);
      };
   
        
    return (
      <>
        <Box
          sx={{
            // boxShadow: '0 3px 10px rgb(0 0 0 / 20%)',
            borderRadius: "12px",
            padding: "8px",
            overflowWrap: "break-word",
            paddingLeft: "2%",

            backgroundColor: "rgba(255,255,255,0.07)",
            marginTop: "2px",
            color: "white",
            
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <i
                class="fa fa-arrow-left"
                style={{ cursor: "pointer" }}
                onClick={handleclick}
              ></i>
            </Grid>
            <Grid item>
              <i className={"feather icon-smartphone f-32 my-2"} />
            </Grid>
            <Grid item>
              <Typography variant="h6" className="color-white-info">
                {selectedDeviceLocID.deviceId}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            
            marginTop: "5px",
            overflowY: 'clip',
            height: "950px",
          }}
        >
          <MapContainer
            POIrenderingList={POIrenderingList}
            setPOIrenderingList={setPOIrenderingList}
            //    zoomLevel={zoomLevel}
            //     mapCenterPosition={mapCenterPosition}
            //     setZoomLevel={setZoomLevel}
            //     setMapCenterPosition={setMapCenterPosition}
            setAddedLayerType={setAddedLayerType}
            newFenceObj={newFenceObj}
            setNewFenceObj={setNewFenceObj}
            setisAskDGNAPopup={setisAskDGNAPopup}
            setisAddPopupOpen={setisAddPopupOpen}
            fenceList={fenceList}
            startGroupCall={startGroupCall}
            //  sendIndividualCall={sendIndividualCall}
            //  sendTextMessage={sendTextMessage}
            //  navigateToCom={navigateToCom}
            fencesCoords={fencesCoords}
            //  fenceHighlight={fenceHighlight}
            filteredUsersList={filteredUsersList}
            newPOIObj={newPOIObj}
            setNewPOIObj={setNewPOIObj}
            //   user={user}
            isCallandSDSrequire={false}
          />
        </Box>
      </>
    );

}

const mapStateToProps = ({ mdm }) => {
    return {
        tenantId: mdm.tenantId,
        deviceInfo: mdm.deviceInfo
    }
}
export default connect(mapStateToProps, { fetchDeviceInfo,setMapCenterPosition,
  setZoomLevel, })(DeviceLocation)

