(function (exports) {
  const EndPoints = require('../endpoints').EndPoints;
  const Emmiter = require("events").EventEmitter;
  const httpHandler = require("./httpHandler").HTTPHandler;
  const XMLProcessor = require("./xmlProcessor").XMLProcessor;

  class GMC extends Emmiter {
    constructor(mcpttClient) {
      super();
      this.mcpttClient = mcpttClient;
      this.defaultPath = `https://${EndPoints.getConfig().host}:${EndPoints.getConfig().gmcPort}/gms/consort/groups/`;
      this.HTTPHandler = new httpHandler();
    }

    handleNotify() { }

    createGroup(groupInfo) {
      let doc = XMLProcessor.createXML(groupInfo);
      console.log("Created Group Document is : ", doc);
      const basicPath = `https://${EndPoints.getConfig().host}:${EndPoints.getConfig().gmcPort}/gms/consort/createGroup`;
      const path = `${basicPath}/${groupInfo.fromId}`;
      const groupId = `${groupInfo.name}@${groupInfo.domain}`
      const _this = this;
      return new Promise(function (resolve, reject) {
        _this.HTTPHandler.postDocument(path, doc).then(res => {
          let response = {
            dgnaGroupId: groupId,
            data: res.data,
            status: res.status,
            stateType: 'SUCCESS'
          }
          resolve(response)
        })
      })
    }

    getGroup(groupInfo) {
      const basicPath = `https://${EndPoints.getConfig().host}:${EndPoints.getConfig().gmcPort}/gms/consort/getGroup`;
      let path = `${basicPath}/${groupInfo.fromId}`;
      this.HTTPHandler.getDocument(path);
    }

    deleteGroup(groupInfo) {
      const basicPath = `https://${EndPoints.getConfig().host}:${EndPoints.getConfig().gmcPort}/gms/consort/deleteGroup`;
      let path = `${basicPath}/${groupInfo.groupId}/${groupInfo.fromId}`; //groupId and subscriberId
      console.log("Deleting DGNA path: ", path);
      return this.HTTPHandler.deleteDocument(path);
    }

    updateGroup(groupInfo) {
      let doc = XMLProcessor.updateRISXML(groupInfo, this.mcpttClient.globalContactList);
      console.log("Created Document RIS is : ", doc);
      const basicPath = `https://${EndPoints.getConfig().host}:${EndPoints.getConfig().gmcPort}/gms/consort/updateGroup`;
      const path = `${basicPath}/${groupInfo.dgnaGroupId}/${groupInfo.fromId}`;
      this.HTTPHandler.updateDocument(path, doc);
    }

    editDGNAGroup(groupInfo) {
      let doc = XMLProcessor.updateXML(groupInfo);
      const basicPath = `https://${EndPoints.getConfig().host}:${EndPoints.getConfig().gmcPort}/gms/consort/updateGroup`;
      const path = `${basicPath}/${groupInfo.mcptt_id}/${groupInfo.fromId}`;
      console.log("updated Document is : ", doc, path);
      this.HTTPHandler.updateDocument(path, doc);
    }

    mergeDGNA(groupInfo, domain, fromId) {
      /*
      https://192.168.1.4:30555/consort/getUsersByGroup/Test2@mcx.consort.org
      [
        "9005-consort",
        "6001-consort"
      ]
      */
      if (groupInfo && groupInfo.dgnaGroupId) {
        let urlToGetExistingGrpMem = `${EndPoints.getConfig().CMSGrp}/${groupInfo.dgnaGroupId}`
        let filteredGrpMembers = [];
        let updatedGrpMembers = [];
        const _this = this;

        this.HTTPHandler.getGroupMembersofDgna(urlToGetExistingGrpMem).then((presentGroupMembers) => {
          if (presentGroupMembers) {
            console.log("Filtered groups before-----------------", filteredGrpMembers, presentGroupMembers);
            if (groupInfo.grpMembersToDel && groupInfo.grpMembersToDel.length) {
              filteredGrpMembers = presentGroupMembers.filter(val => !groupInfo.grpMembersToDel.includes(val));
            } else {
              filteredGrpMembers = [...presentGroupMembers];
            }
            console.log("Filtered groups after-----------------", filteredGrpMembers, presentGroupMembers);
            if (groupInfo.grpMembersToAdd && groupInfo.grpMembersToAdd.length) {
              filteredGrpMembers = [...groupInfo.grpMembersToAdd, ...filteredGrpMembers];
            }
            console.log("Filtered groups much after-----------------", filteredGrpMembers, presentGroupMembers);
            if (filteredGrpMembers && filteredGrpMembers.length) {
              filteredGrpMembers.forEach(element => {
                let tempObj = {
                  contactName: element,
                  mcptt_id: element,
                  mcptt_uri: element + "@" + domain
                }
                updatedGrpMembers.push(tempObj);
              });
            }
            let data = { ...groupInfo };
            data.domain = domain;
            data.grpMembers = updatedGrpMembers;
            data.fromId = fromId;
            console.log("Updating DGNA params with add delete functionality:", data);
            _this.editDGNAGroup(data)
          }
        })
      }
    }

  }
  exports.GMC = GMC

})(typeof exports === 'undefined' ? this['GMC'] = {} : exports);
