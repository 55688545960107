(function (exports) {
  const Store = require("../Store").Store;
  const utf8 = require("utf8");

  class SDSFormat {
    constructor(mcpttBodyUtils) {
      this.store = new Store();
      this.mcpttBodyUtils = mcpttBodyUtils;
    }

    getHeadersAndBody(userInfo, mcDataPayload, mcDataSignaling) {
      try {
        let headersBody = {};
        let headers = [];
        let bodyMcptt = {};
        let boundary = "consort-boundary"; //config.get("settings.sipBoundary");
        console.log("get headers and body", userInfo);
        if (userInfo.isStandardSDS) {
          headers = [
            "Accept-Contact: " + "g.3gpp.mcdata.sds;require;explicit",
            "Accept-Contact: " +
            'g.3gpp.icsi-ref="urn:urn-7:3gpp-service.ims.icsi.mcdata.sds";require;explicit',
            "P-Preferred-Service: " +
            "urn:urn-7:3gpp-service.ims.icsi.mcdata.sds",
          ];

          let resourceListJson = {
            "resource-lists": {
              list: {
                entry: [
                  {
                    "@": {
                      type: "uri",
                    },
                    "#": `${userInfo.toId}`,
                  },
                ],
              },
            },
          };

          let mcdataInfo;
          if (userInfo.isGroupSds) {
            mcdataInfo = {
              mcdatainfo: {
                "mcdata-Params": {
                  "request-type": "group-sds",
                  "mcdata-request-uri": {
                    mcdataURI: userInfo.groupId.includes("sip")
                      ? `${userInfo.groupId}`
                      : `sip:${userInfo.groupId}`, //Need to fill values here!
                  },
                  "mcdata-client-id": {
                    "@": {
                      type: "Normal",
                    },
                    mcdataString: userInfo.mcdataString,
                  },
                  anyExt: {
                    "@": {
                      type: "Normal",
                    },
                    "status": userInfo.status ? userInfo.status : false,
                    "fileId": userInfo.fileId ? userInfo.fileId : '',
                    "messageType": userInfo.messageType ? userInfo.messageType : 'text'
                  },
                },
              },
            };
          } else if (userInfo.isFAMessage) {
            let callerUriFa = userInfo.fromId;
            mcdataInfo = {
              mcdatainfo: {
                "mcdata-Params": {
                  "request-type": "one-to-one-sds",
                  anyExt: {
                    "@": {
                      type: "Normal",
                    },
                    "call-to-functional-alias-ind": true,
                    "functional-alias-URI": {
                      "@": {
                        type: "Normal",
                      },
                      "mcdataURI": callerUriFa.includes("sip")
                        ? `${callerUriFa}`
                        : `sip:${callerUriFa}`,
                    },
                    "status": userInfo.status ? userInfo.status : false,
                    "fileId": userInfo.fileId ? userInfo.fileId : '',
                    "messageType": userInfo.messageType ? userInfo.messageType : 'text'
                  },
                },
              },
            };
          } else {
            mcdataInfo = {
              mcdatainfo: {
                "mcdata-Params": {
                  "request-type": "one-to-one-sds",
                  anyExt: {
                    "@": {
                      type: "Normal",
                    },
                    "status": userInfo.status ? userInfo.status : false,
                    "fileId": userInfo.fileId ? userInfo.fileId : '',
                    "messageType": userInfo.messageType ? userInfo.messageType : 'text'
                  },
                },
              },
            };
          }

          var contents;
          if (userInfo.isGroupSds) {
            contents = [
              {
                mime: "application/vnd.3gpp.mcdata-info+xml",
                content: this.mcpttBodyUtils.mcpttinfoJsonToXml(
                  mcdataInfo.mcdatainfo
                ),
              },
              {
                content: mcDataSignaling,
                mime: "application/vnd.3gpp.mcdata-signalling",
              },
              {
                content: mcDataPayload,
                mime: "application/vnd.3gpp.mcdata-payload",
              },
            ];
          } else {
            contents = [
              {
                mime: "application/vnd.3gpp.mcdata-info+xml",
                content: this.mcpttBodyUtils.mcpttinfoJsonToXml(
                  mcdataInfo.mcdatainfo
                ),
              },
              {
                content: this.mcpttBodyUtils.resourceListJsonToXml(
                  resourceListJson["resource-lists"]
                ),
                mime: "application/resource-lists+xml",
              },
              {
                content: mcDataSignaling,
                mime: "application/vnd.3gpp.mcdata-signalling",
              },
              {
                content: mcDataPayload,
                mime: "application/vnd.3gpp.mcdata-payload",
              },
            ];
          }
          bodyMcptt = this.mcpttBodyUtils.arrayBodyToMultipartBody(
            contents,
            boundary
          );
          headersBody["headers"] = headers;
          headersBody["body"] = bodyMcptt;
        } else {
          headers = {
            "request-type": userInfo.communicationType,
            "mcdata-calling-user-id": userInfo.toId,
            "mcdata-request-uri": userInfo.fromId,
            "mcdata-client-id": userInfo.toId,
            "sds-type": userInfo.sdsType,
            "message-id": userInfo.indexId,
            payload: utf8.encode(userInfo.message),
            immediate: userInfo.immediate,
            "consumed-report": userInfo.consumedReportNeeded,
            "delivery-report": userInfo.deliveryReportNeeded,
            messageType: userInfo.messageType,
            fileType: userInfo.fileType ? userInfo.fileType : "none",
            fileId: userInfo.fileId ? userInfo.fileId : "",
          };
          headersBody["headers"] = headers;
          headersBody["mcdata-payload"] = mcDataPayload;
          headersBody["mcdata-signalling"] = mcDataSignaling;
        }
        return headersBody;
      } catch (e) {
        console.log("getUACHeaders", e);
      }
    }

    getSDSNotifHeadersAndBody(userInfo, mcDataNotification, partServerURI) {
      try {
        let headersBody = {};
        let headers = [];
        let bodyMcptt = {};
        let boundary = "consort-boundary";
        console.log("notif header", userInfo);

        headers = [
          "Accept-Contact: " + "g.3gpp.mcdata.sds;require;explicit",
          "Accept-Contact: " +
          'g.3gpp.icsi-ref="urn:urn-7:3gpp-service.ims.icsi.mcdata.sds";require;explicit',
          "P-Preferred-Service: " +
          "urn:urn-7:3gpp-service.ims.icsi.mcdata.sds",
        ];

        let resourceListJson = {
          "resource-lists": {
            list: {
              entry: [
                {
                  "@": {
                    type: "uri",
                  },
                  "#": `${userInfo.toId}`,
                },
              ],
            },
          },
        };

        let mcdataInfo = {
          mcdatainfo: {
            "mcdata-Params": {
              "request-type": "one-to-one-sds",
              "mcdata-controller-psi": {
                "mcdataURI": partServerURI
              }
            },
          },
        };
        /*
        <mcdata-Params>
<mcdata-controller-psi> <mcdataURI>sip:mcdata-controlling-server-psi@example.com</mcdataURI> </mcdata-controller-psi>
</mcdata-Params>
        */

        let contents = [
          {
            mime: "application/vnd.3gpp.mcdata-info+xml",
            content: this.mcpttBodyUtils.mcpttinfoJsonToXml(
              mcdataInfo.mcdatainfo
            ),
          },
          {
            content: this.mcpttBodyUtils.resourceListJsonToXml(
              resourceListJson["resource-lists"]
            ),
            mime: "application/resource-lists+xml",
          },
          {
            content: mcDataNotification,
            mime: "application/vnd.3gpp.mcdata-notification",
          },
          
        ];
        //MCDATA-SIGNALLING
        //SDS-NOTIFICATION 
        bodyMcptt = this.mcpttBodyUtils.arrayBodyToMultipartBody(
          contents,
          boundary
        );
        headersBody["headers"] = headers;
        headersBody["body"] = bodyMcptt;

        return headersBody;
      } catch (e) {
        console.log("getUACHeaders", e);
      }
    }
  }
  exports.SDSFormat = SDSFormat;
})(typeof exports === "undefined" ? (this["SDSFormat"] = {}) : exports);
