/** @format */

import React, { useState, useEffect, Fragment } from "react";
import { createTheme } from "@material-ui/core/styles";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import { Checkbox, FormControlLabel, InputLabel, MenuItem, FormControl, Select, TextField, Box, Grid } from "@material-ui/core";
import { connect } from "react-redux";
const ConfigMDMSetting = (props) => {
    const {
        submitButtonHandler,
        appList,
        data
    } = props;
    const [mdmConfig, setMdmConfig] = useState({ ...data })
    const [mainAppOption, setMainAppOption] = useState([])
    const wifiSecurityOption = [
        { text: "WPA", value: 'wpa' },
        { text: "WEP", value: 'wep' },
        { text: "EAP", value: 'eap' },
        { text: "None", value: 'none' },
    ]
    const isEmptyObject = (obj) => {
        return (
            obj &&
            Object.keys(obj).length === 0 &&
            Object.getPrototypeOf(obj) === Object.prototype
        );
    }; 
    useEffect(() => { 
        let option = appList.filter(r=>{
            return (r.packageId!="com.hmdm.launcher")
        }).map(r => {
            return { text: r.name, value: r.id }
        })
        setMainAppOption([...option])
    }, [appList])
    const useStyles = makeStyles((theme) => ({
        // root: {
        //     width: "100%",
        //     maxWidth: 360,
        // },
        // formControl: {
        //     width: "100%",
        //     marginTop: "5px",
        // },
        // formControlFA: {
        //     width: "80%",
        //     height: "60px",
        //     marginTop: "5px",
        // },
        // listMember_all: {
        //     // width: 250,
        //     maxHeight: 200,
        // },
        // listItemFA: {
        //     padding: "5px",
        //     fontFamily: "Muli",
        // },
        // tetraSwitch: {
        //     fontFamily: "Muli",
        //     marginTop: "2px",
        //     marginRight: "1px",
        //     marginLeft: "0px",
        // },
        // noOfRegTextField: {
        //     width: "40%",
        //     height: "25px",
        // },
        // ueInfoList: {
        //     padding: "5px",
        //     fontFamily: "Muli",
        // },
        // formControlUEType: {
        //     width: "80%",
        //     height: "60px",
        //     marginTop: "5px",
        // },
        // mcxDataIdInput: {
        //     backgroundColor: "rgb(232, 232, 232) !important",
        // },
        // tickSize: {
        //     transform: "scale(1.3)",
        //     marginLeft: "20px",
        // },
        // ml0: {
        //     marginTop: "10px",
        // },
        // mleft0: {
        //     marginLeft: "0px",
        // },
        // errorIndicator: {
        //     background: "#ffeded",
        // },
        // paper: {
        //     position: 'relative',
        //     background: '#e8e8e8',
        //     overflow: 'hidden'
        // }

        root: {
            backgroundColor: "#252524",
            //backgroundColor: theme.palette.background.default,
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            width: "100%",
            //height: "70%",
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
        },
        formControlFA: {
            width: "98%",
            height: "65px",
            marginTop: "2px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600,
            backgroundColor: "#383736"
        },
        listItemFA: {
            padding: "5px",
            fontFamily: "Muli",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        productTypeClass: {
            width: "90%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            border: "solid",
            borderRadius: 10,
            borderWidth: "2px",
            borderColor: "#4B4A4A",
            marginTop: 5,
            marginBottom: 10
        },
        divList: {
            width: '100%',
            marginBottom: '13px',
        },
        lblStyle: {
            width: '100%',
            color: "#D0D3D4",
            fontSize: 18,
            fontWeight: 600
        },
        tetraSwitch: {
            fontFamily: "Muli",
            marginTop: "2px",
            marginRight: "1px",
            marginLeft: "0px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        datePickerStyle: {
            color: "black",
            padding: "10px",
            fontFamily: "Sans-Serif",
            width: '60%',
            border: "solid",
            borderRadius: 20,
            borderWidth: "2px",
            borderColor: "#D3D3D3",
        }


    }));

    const theme = createTheme({
        palette: {
            primary: {
                main: "#006400",
            },
            secondary: {
                main: "#fdce2a",
            },
        },
    });
    const classes = useStyles();
    const onSelectHandler = (e) => {
        let data = { ...mdmConfig }
        data[e.target.name] = e.target.value
        setMdmConfig({ ...data })
    }
    const checkBoxHandler = (e) => {
        let data = { ...mdmConfig }
        data[e.target.name] = !data[e.target.name]
        setMdmConfig({ ...data })
    }
    return (
        <div class={classes.root}>
            <Grid container width="100%" spacing={2}>
                <Grid item xs={12}>
                    <Box className="tab1-account" width={'100%'} marginLeft={0}>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }} >
                            <Box flex={0.5} mr="0.5em" marginTop={1} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Kiosk Mode</label>
                            </Box>
                            <FormControlLabel
                                className={classes.tickSize}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={mdmConfig.kioskmode ? true : false}
                                        onChange={checkBoxHandler}
                                        name="kioskmode"
                                    />
                                }
                            />
                        </Box>
                        <Box class="form-group" style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <Box flex={1} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Content Application</label></Box> */}
                            <FormControl
                                variant="filled"
                                className={classes.formControlFA}
                                disabled={mdmConfig.kioskmode ? false : false}
                                // style={{ width: '65%', left: "-5%" }}
                            >
                                <InputLabel id="ueTypeID" className={classes.listItemFA} focused={false}>
                                    Content Application
                                </InputLabel>
                                <Select
                                    className={classes.listItemFA}
                                    id="contentappid"
                                    value={mdmConfig.contentappid}
                                    name="contentappid"
                                    onChange={onSelectHandler}
                                    disableUnderline={true}
                                >
                                    {mainAppOption &&
                                        mainAppOption.map((uetype) => {
                                            return (
                                                <MenuItem value={uetype.value}>{uetype.text}</MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }} >
                            <Box flex={0.5} mr="0.5em" marginTop={1} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Enable Home Button</label>
                            </Box>
                            <FormControlLabel
                                className={classes.tickSize}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={mdmConfig.kioskhome ? true : false}
                                        name="kioskhome"
                                        onChange={checkBoxHandler}
                                    />
                                }
                            />
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }} >
                            <Box flex={0.5} mr="0.5em" marginTop={1} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Enable Recents Button</label>
                            </Box>
                            <FormControlLabel
                                className={classes.tickSize}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={mdmConfig.kioskrecents ? true : false}
                                        name="kioskrecents"
                                        onChange={checkBoxHandler}
                                    />
                                }
                            />
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }} >
                            <Box flex={0.5} mr="0.5em" marginTop={1} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Enable Notifications</label>
                            </Box>
                            <FormControlLabel
                                className={classes.tickSize}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={mdmConfig.kiosknotifications ? true : false}
                                        name="kiosknotifications"
                                        onChange={checkBoxHandler}
                                    />
                                }
                            />
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={0.5} mr="0.5em" marginTop={1} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Enable Status Bar Info</label>
                            </Box>
                            <FormControlLabel
                                className={classes.tickSize}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={mdmConfig.kiosksysteminfo ? true : false}
                                        name="kiosksysteminfo"
                                        onChange={checkBoxHandler}
                                    />
                                }
                            />
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={0.5} mr="0.5em" marginTop={1} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Enable Screen lock</label>
                            </Box>
                            <FormControlLabel
                                value="mcxDataIDCheckBox"
                                className={classes.tickSize}
                                control={
                                    <Checkbox
                                        color="primary"
                                        name="kioskkeyguard"
                                        checked={mdmConfig.kioskkeyguard ? true : false}
                                        onChange={checkBoxHandler}
                                    />
                                }
                            />
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }} >
                            <Box flex={0.5} mr="0.5em" marginTop={1} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Lock the power button</label>
                            </Box>
                            <FormControlLabel
                                value="mcxDataIDCheckBox"
                                className={classes.tickSize}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={mdmConfig.kiosklockbuttons ? true : false}
                                        name="kiosklockbuttons"
                                        onChange={checkBoxHandler}
                                    />
                                }
                            />
                        </Box>
                        <Box className="form-group" style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={1} mr="0.5em" marginTop={1} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>WiFi SSID</label>
                            </Box>
                            <Box style={{ width: '70%' }}>
                                <input
                                    type="text"
                                    class="input-control-flat"
                                    id="wifissid"
                                    name="wifissid"
                                    value={mdmConfig.wifissid || ""}
                                    onChange={onSelectHandler}
                                />
                            </Box>
                        </Box>
                        <Box className="form-group" style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                            <Box flex={1} mr="0.5em" marginTop={1} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>WiFi password</label>
                            </Box>
                            <Box style={{ width: '70%' }}>
                                <input
                                    type="text"
                                    class="input-control-flat"
                                    id="wifipassword"
                                    name="wifipassword"
                                    value={mdmConfig.wifipassword || ""}
                                    onChange={onSelectHandler}
                                />
                            </Box>
                        </Box>
                        <Box className="form-group" style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <Box flex={1} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>WiFi security type</label>
                            </Box> */}
                            <FormControl
                                variant="filled"
                                className={classes.formControlFA}
                            >
                                <InputLabel id="ueTypeID" className={classes.listItemFA} focused={false}>
                                    WiFi security type
                                </InputLabel>
                                <Select
                                    className={classes.listItemFA}
                                    id="wifisecuritytype"
                                    value={mdmConfig.wifisecuritytype}
                                    name="wifisecuritytype"
                                    onChange={onSelectHandler}
                                    disableUnderline={true}
                                >
                                    {wifiSecurityOption &&
                                        wifiSecurityOption.map((uetype) => {
                                            return (
                                                <MenuItem value={uetype.value}>{uetype.text}</MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={0.5} mr="0.5em" marginTop={1} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Enroll using mobile data</label>
                            </Box>
                            <FormControlLabel
                                value="mcxDataIDCheckBox"
                                className={classes.tickSize}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={mdmConfig.mobileenrollment ? true : false}
                                        name="mobileenrollment"
                                        onChange={checkBoxHandler}
                                    />
                                }
                            />
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={0.5} mr="0.5em" marginTop={1} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Lock safe settings (WiFi, GPS, etc)</label>
                            </Box>
                            <FormControlLabel
                                value="mcxDataIDCheckBox"
                                className={classes.tickSize}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={mdmConfig.locksafesettings}
                                        name="locksafesettings"
                                        onChange={checkBoxHandler}
                                    />
                                }
                            />
                        </Box>
                        <Box className="form-group" style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={1} mr="0.5em" marginTop={1} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Allowed activities</label>'
                            </Box>
                            <Box style={{ width: '70%' }}>
                                <input
                                    type="text"
                                    class="input-control-flat"
                                    id="allowedclasses"
                                    name="allowedclasses"
                                    value={mdmConfig.allowedclasses || ""}
                                    onChange={onSelectHandler}
                                />
                            </Box>
                        </Box>
                        <Box className="form-group" style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={1} mr="0.5em" marginTop={1} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Restrictions</label>
                            </Box>
                            <Box style={{ width: '70%' }}>
                                <input
                                    type="text"
                                    class="input-control-flat"
                                    id="restrictions"
                                    name="restrictions"
                                    value={mdmConfig.restrictions || ""}
                                    onChange={onSelectHandler}
                                />
                            </Box>
                        </Box>
                        <Box className="form-group" style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={1} mr="0.5em" marginTop={1} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>New server URL</label>
                            </Box>
                            <Box style={{ width: '70%' }}>
                                <input
                                    type="text"
                                    class="input-control-flat"
                                    id="newserverurl"
                                    name="newserverurl"
                                    value={mdmConfig.newserverurl || ""}
                                    onChange={onSelectHandler}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box className="submit-btns">
                        <button
                            class="update-btn-profile"
                            type="button"
                            onClick={() => submitButtonHandler(mdmConfig)}
                        >
                            Submit
                        </button>
                    </Box>
                </Grid>
            </Grid>
        </div >
    );
};

const mapStateToProps = ({ mdm }) => {
    return {
        appList: mdm.appList
    };
};

export default connect(mapStateToProps, {})(ConfigMDMSetting);
