import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid } from '@mui/material';

const PartnerProductType = (props) => {
    const { productJsonHandler, openMode } = props;

    let planDetailData = {
        minNumSubscribers: 1,
        maxNumSubscribers: 1,
        maxConnectionsPerSubscriber: 0,
        numDays: "",
        ueTypes: [],
        services: []
    }

    const [plandetails, setplandetails] = useState(planDetailData);

    useEffect(() => {
        if (props.planJsonData) {
            console.log("planJsonData pproductType ", props.planJsonData);
            setplandetails(props.planJsonData);
        }
    }, [])

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '92%',
            paddingTop: 10,
        },
        divList: {
            width: '100%',
            marginBottom: '1px',
        },
        lblStyle: {
            width: '100%',
            color: "#D0D3D4",
            fontSize: 18,
            fontWeight: 600
        },
    }));

    const numDaysChangeHandler = (e) => {
        setplandetails({
            ...plandetails,
            numDays: e.target.value,
        });
        let plData = { ...plandetails, numDays: e.target.value };
        productJsonHandler(plData);
    };

    const minNumSubscribersChangeHandler = (e) => {
        setplandetails({
            ...plandetails,
            minNumSubscribers: e.target.value,
        });
        let plData = { ...plandetails, minNumSubscribers: e.target.value };
        productJsonHandler(plData);
    };

    const maxNumSubscribersChangeHandler = (e) => {
        setplandetails({
            ...plandetails,
            maxNumSubscribers: e.target.value,
        });
        let plData = { ...plandetails, maxNumSubscribers: e.target.value };
        productJsonHandler(plData);
    };

    const maxConnectionsPerSubscriberChangeHandler = (e) => {
        setplandetails({
            ...plandetails,
            maxConnectionsPerSubscriber: e.target.value,
        });
        let plData = { ...plandetails, maxConnectionsPerSubscriber: e.target.value };
        productJsonHandler(plData);
    };

    const getInputClassName = () => {
        if (openMode === "view") {
            return "input-control-flat-read";
        }
        return "input-control-flat";
    }

    const isViewMode = () => {
        if (openMode === "view") {
            return true;
        }
        return false;
    }

    let classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container width="100%" spacing={2}>
                <Grid item xs={12}>
                    {/* ---------------- numDays & minNumSubscribers --------------------- */}
                    <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={3}>
                        <Box flex={1} mr="0.5em">
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className="lblRequired">No. of days</label>
                            </Box>
                            <Box flex={1} mr="0.5em">
                                <input
                                    type="number"
                                    className={getInputClassName()}
                                    id="numDays"
                                    value={plandetails && plandetails.numDays}
                                    onChange={numDaysChangeHandler}
                                    readOnly={isViewMode()}
                                />
                            </Box>
                        </Box>
                        <Box flex={1} mr="0.5em">
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className="lblRequired">Min Subscribers</label>
                            </Box>
                            <Box flex={1} mr="0.5em">
                                <input
                                    type="number"
                                    className={getInputClassName()}
                                    iid="minNumSubscribers"
                                    value={plandetails && plandetails.minNumSubscribers}
                                    onChange={minNumSubscribersChangeHandler}
                                    readOnly={isViewMode()}
                                />
                            </Box>
                        </Box>
                    </Box>

                    {/* ---------------- Max Subscribers & maxConnectionsPerSubscriber --------------------- */}

                    <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={3}>
                        <Box flex={0.5} mr="0.5em">
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className="lblRequired">Max Subscribers</label>
                            </Box>
                            <Box flex={1} mr="0.5em">
                                <input
                                    type="number"
                                    className={getInputClassName()}
                                    id="maxNumSubscribers"
                                    value={plandetails && plandetails.maxNumSubscribers}
                                    onChange={maxNumSubscribersChangeHandler}
                                    readOnly={isViewMode()}
                                />
                            </Box>
                        </Box>
                        <Box flex={1} mr="0.5em">
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className={classes.lblStyle}>Max Connection Subscribers</label>
                            </Box>
                            <Box flex={1} mr="0.5em">
                                <input
                                    type="number"
                                    className={getInputClassName()}
                                    id="maxConnectionsPerSubscriber"
                                    value={plandetails && plandetails.maxConnectionsPerSubscriber}
                                    onChange={maxConnectionsPerSubscriberChangeHandler}
                                    readOnly={isViewMode()}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}

export default PartnerProductType;
