import React, { useEffect } from "react";
import AddUser from './AdminWidget/Screens/AddUsers'
import AddGroup from "./AdminWidget/AddGroup/AddGroup";
import AddOrg from "./AdminWidget/AddOrg/AddOrg";
import AddIwfMap from "./AdminWidget/AddIwfMap/AddIwfMap";
// import { getAllAlerts,getAlertTypes } from "../../modules/actions";
import AddAlert from "./AdminWidget/Screens/AddAlert"
import { connect } from "react-redux";

const AddModal = React.memo((props) => {
  const { userlist, userhandleClose, hideModal, userOpen, grouplist, orglist, subscribeType, checkUserIsAlreadyExist } = props;

  useEffect(() => () => {
    props.purpose !== 'edit' && hideModal()
  }, [])
  return (
    <div style={{ width: "100%" }}>
      {/* {subscribeType === "single" ? (
        <div>ADD USER</div>
      ) : subscribeType === "org" ? (
        <div>ADD ORG</div>
      ) : subscribeType === "iwf_map" ? (
        <div>ADD IWF</div>
      ) : (
        <div>ADD GROUP</div>
      )} */}
      {subscribeType === "single" ? <AddUser grouplist={grouplist} userlist={userlist} hideModal={hideModal} checkUserIsAlreadyExist={checkUserIsAlreadyExist}></AddUser> : subscribeType === "org" ? <AddOrg hideModal={hideModal} /> : subscribeType === "iwf_map" ? <AddIwfMap userlist={userlist} hideModal={hideModal} /> : /* subscribeType === "alerts" ? <AddAlert hideModal={hideModal}></AddAlert> : */ <AddGroup orglist={orglist} userlist={userlist} hideModal={hideModal} />}
    </div>
  );
});

const mapStateToProps = ({ adminstate }) => {
  const { userlist, grouplist, orglist, iwfMaplist } = adminstate;
  const { allAlertList, allAlertListType } = adminstate
  //console.log('userlist reducer', userlist)
  return {
    userlist, grouplist, orglist, iwfMaplist, allAlertList, allAlertListType
  };
};

export default connect(mapStateToProps, {})(AddModal);