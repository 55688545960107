import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
// import { Header, Icon, Dropdown, Grid } from 'semantic-ui-react'
import { Modal, Button } from 'react-bootstrap'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles, createTheme } from "@material-ui/core/styles";
import { getAdminProfile } from '../modules/adminstate'
const SwitchUser = (props) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.background.paper,
            width: 800,
        },
        formControlFA: {
            width: "80%",
            height: "65px",
            marginTop: "2px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600,
            backgroundColor: "#383736"
        },
        listItemFA: {
            padding: "2.5px",
            fontFamily: "Muli",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
    }));
    const classes = useStyles();

    const theme = createTheme({
        palette: {
            primary: {
                main: "#006400",
            },
            secondary: {
                main: "#fdce2a",
            },
        },
    });

    const { open, closeModal, allSwitchProfiles } = props;

    const [selectedUser, setSelectedUser] = useState('');
    const [enableSave, setEnableSave] = useState(false);
    const [userList, setUserList] = useState(allSwitchProfiles)

    const handleSelectedUser = (e) => {
        if (e.target.value == 0) {
            setEnableSave(true)
        } else {
            setEnableSave(false)
        }
        setSelectedUser(e.target.value)
    }

    useEffect(() => {
        setUserList(allSwitchProfiles)
    }, [allSwitchProfiles])


    const saveBtnHandler = async () => {
        props.getAdminProfile(selectedUser)
        global.config.userConfig.switchedUserName = selectedUser
        props.closeModal(false)
    }

    const renderSettings = () => {
        return (
            (
                <div class="form-group" style={{ padding: "15px 0px" }}>
                    <label class="attribute-heading" style={{ paddingTop: '10px', marginRight: '20px', marginLeft: '20px', color: 'whitesmoke' }}>User List</label>
                    <FormControl
                        variant="filled"
                        className={classes.formControlFA}
                    >
                        <InputLabel id="ueTypeID" className={classes.listItemFA} focused={false}>
                            Selected User
                        </InputLabel>
                        <Select
                            className={classes.iwfInfoList}
                            id="ueType-select-filled"
                            value={selectedUser}
                            onChange={handleSelectedUser}
                            disableUnderline={true}
                        >
                            {
                                (userList &&
                                    userList.map((id) => {
                                        return (
                                            <MenuItem value={id.value}>{id.type} - {id.value}</MenuItem>
                                        );
                                    })
                                )
                            }

                        </Select>
                    </FormControl>
                </div>
            )
        )
    }

    return (
        <Modal
            show={open}
            onHide={() => closeModal(false)}
            size={props.size ? props.size : "lg"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ backgroundColor: ' rgba(0,0,0,0.5)' }}
        >
            <Modal.Header closeButton style={{ border: '0px', backgroundColor: '#282828' }}>
                <Modal.Title style={{ color: 'whitesmoke' }}>Switch User</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '5px', backgroundColor: '#252524' }} scrollable={true}>
                {renderSettings()}
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: '#252524' }}>
                <Button variant="success" style={{ color: 'whitesmoke' }} disabled={enableSave} onClick={saveBtnHandler}>Save & Close</Button>
                <Button variant="light" style={{ color: '#000' }} onClick={() => props.closeModal(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = ({ adminstate }) => {
    const { allSwitchProfiles } = adminstate;
    return {
        allSwitchProfiles
    };
};

export default connect(mapStateToProps, {
    getAdminProfile
})(SwitchUser);