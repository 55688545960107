import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid } from '@mui/material';

const MobileDeviceProductType = (props) => {
    const { openMode } = props;
    let planDetailData = {
        minNumUnits: 1,
        maxNumUnits: 1,
    }

    const [plandetails, setplandetails] = useState(planDetailData);

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '92%',
            paddingTop: 10,
        },
        divList: {
            width: '100%',
            marginBottom: '1px',
        },
        lblStyle: {
            width: '100%',
            color: "#D0D3D4",
            fontSize: 18,
            fontWeight: 600
        },
    }));

    useEffect(() => {
        if (props.planJsonData) {
            console.log("mobile planJsonData pproductType ", props.planJsonData);
            setplandetails(props.planJsonData);
        }
    }, [])

    const minNumUnitsChangeHandler = (e) => {
        setplandetails({
            ...plandetails,
            minNumUnits: e.target.value,
        });
        let plData = { ...plandetails, minNumUnits: e.target.value };
        props.productJsonHandler(plData);
    };

    const maxNumUnitsChangeHandler = (e) => {
        setplandetails({
            ...plandetails,
            maxNumUnits: e.target.value,
        });
        let plData = { ...plandetails, maxNumUnits: e.target.value };
        props.productJsonHandler(plData);
    };

    const getInputClassName = () => {
        if (openMode === "view") {
            return "input-control-flat-read";
        }
        return "input-control-flat";
    }

    const isViewMode = () => {
        if (openMode === "view") {
            return true;
        }
        return false;
    }

    let classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container width="100%" spacing={2}>
                <Grid item xs={12}>
                    {/* ---------------- Min Units & Max Units --------------------- */}
                    <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={3}>
                        <Box flex={1} mr="0.5em">
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className="lblRequired">Min Units</label>
                            </Box>
                            <Box flex={1} mr="0.5em">
                                <input
                                    type="number"
                                    className={getInputClassName()}
                                    id="minNumUnits"
                                    value={plandetails && plandetails.minNumUnits}
                                    onChange={minNumUnitsChangeHandler}
                                    readOnly={isViewMode()}
                                />
                            </Box>
                        </Box>
                        <Box flex={1} mr="0.5em">
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className="lblRequired">Max Units</label>
                            </Box>
                            <Box flex={1} mr="0.5em">
                                <input
                                    type="number"
                                    className={getInputClassName()}
                                    id="maxNumUnits"
                                    value={plandetails && plandetails.maxNumUnits}
                                    onChange={maxNumUnitsChangeHandler}
                                    readOnly={isViewMode()}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}

export default MobileDeviceProductType;