/**
 *  Model: 'Call'
 */

export class IndividualCall { 
  constructor(type, toId, priority, isFACall = false, isVideo = false) {
    this.toId = toId || ""
    this.callPriority = priority || "MEDIUM"
    this.communicationType = "CALL"
    this.callType = type
    this.isVideo = isVideo
    if (isFACall) {
      this.isFACall = isFACall
      if (global.config.activatedFA && global.config.activatedFA.length > 0) {
        this.fromId = global.config.activatedFA
      }
    }
  }
}

export class GroupCall { 
  constructor(type, toId, priority, isVideo = false) {
    this.toId = toId || ""
    this.groupId = toId || ""
    this.callPriority = priority || "MEDIUM"
    this.communicationType = "CALL"
    this.callType = type
    this.isVideo = isVideo
  }
}

export class AdhocGroupCall { 
  constructor(type, grpMembers, priority, isVideo = false) {
    this.toId = "Adhoc Call"
    this.groupId = "Adhoc Call"
    this.callPriority = priority || "MEDIUM"
    this.communicationType = "CALL"
    this.callType = type
    this.isVideo = isVideo
    this.isAdhocGroupCall = true
    this.members = grpMembers || []
  }
}

export class MergeCall { 
  constructor(userId, callId1, callId2) {
    this.fromId = userId.toString()
    this.toId = userId.toString()
    this.communicationType = "CALL"
    this.callPriority = "0"
    this.callType = "MERGE_CALL"
    this.callId1 = callId1.toString()
    this.callId2 = callId2.toString()
  }
}