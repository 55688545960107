import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import { makeStyles, createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { getAvailableServices } from "../../modules/mtmstate";
import MCXServices from "../../planManagement/mcxservices";
import { Box, Grid, Typography } from '@mui/material';

const ConfigDetails = (props) => {
    const { ueTypeList, getAvailableServices, availableServices, serviceUeTypeConfig, iwfMappedDomainConfig } = props

    const [registerExpires, setRegisterExpires] = useState(0)
    const [autoCallconfig, setAutoCallconfig] = useState([])
    const [preEstConfigCount, setPreEstConfigCount] = useState([])
    const [isError, setIsError] = useState(false)
    const [selectedIwfTypeName, setSelectedIwfTypeName] = useState('')
    const [selectedIwfTypeType, setSelectedIwfTypeType] = useState('')
    const [addedIwfInfo, setAddedIwfInfo] = useState([])
    const [connectionList, setConnectionList] = useState([])
    const [hostData, setHostData] = useState('')
    const [portData, setPortData] = useState('')
    const [tthData, setTTHData] = useState('')
    const [ttpData, setTTPData] = useState('')
    const [ttepData, setTTEPData] = useState('')
    const [masterSsi, setMasterSsi] = useState('')
    const [timer, setTimer] = useState('')
    const [domain, setDomain] = useState('')
    const [mappedDomain, setMappedDomain] = useState('')
    const [addedDevices, setAddedDevices] = useState([]);
    const [vlDeviceType, setvlDeviceType] = useState([]);
    const [deviceTypeList, setDeviceTypeList] = useState([])
    const [allSelectedServices, setAllSelectedServices] = useState([])
    const [allowedServices, setAllowedServices] = useState([])
    const [enableIwfService, setEnableIwfService] = useState(false);
    const [errorMsg, setErrorMsg] = useState("")

    const useStyles = makeStyles((theme) => ({
        // root: {
        //     backgroundColor: theme.palette.background.paper,
        //     width: "100%",
        //     left: 0
        //     // height: 600
        // },
        // formControlIwfType: {
        //     width: "90%",
        //     height: "100px",
        //     marginTop: "0px",
        // },
        // iwfInfoList: {
        //     padding: "5px",
        //     fontFamily: "Muli",
        // },
        // listItemFA: {
        //     padding: "2px",
        //     fontFamily: "Muli",
        // },
        // divList: {
        //     width: '100%',
        //     marginBottom: '1px',
        // },
        // formControlFA: {
        //     width: "80%",
        //     height: "60px",
        //     marginTop: "2px",
        // },

        root: {
            backgroundColor: "#252524",
            //backgroundColor: theme.palette.background.default,
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            width: "100%",
            //height: "70%",
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
        },
        formControlFA1: {
            color: "#D0D3D4",
        },
        formControlFA: {
            width: "60%",
            height: "65px",
            marginTop: "2px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600,
            backgroundColor: "#383736"
        },
        listItemFA: {
            padding: "5px",
            fontFamily: "Muli",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        productTypeClass: {
            width: "90%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            border: "solid",
            borderRadius: 10,
            borderWidth: "2px",
            borderColor: "#4B4A4A",
            marginTop: 5,
            marginBottom: 10
        },
        divList: {
            width: '100%',
            marginBottom: '13px',
        },
        lblStyle: {
            width: '100%',
            color: "#D0D3D4",
            fontSize: 18,
            fontWeight: 600
        },
        tetraSwitch: {
            fontFamily: "Muli",
            marginTop: "2px",
            marginRight: "1px",
            marginLeft: "0px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        datePickerStyle: {
            color: "black",
            padding: "10px",
            fontFamily: "Sans-Serif",
            width: '60%',
            border: "solid",
            borderRadius: 20,
            borderWidth: "2px",
            borderColor: "#D3D3D3",
        }
    }));
    const classes = useStyles();

    const theme = createTheme({
        palette: {
            primary: {
                main: "#006400",
            },
            secondary: {
                main: "#fdce2a",
            },
        },
    });

    useEffect(() => {
        if (selectedIwfTypeName === "Tetra Integration Server" || selectedIwfTypeName === "Terminal Gateway" || selectedIwfTypeName === "Extended-Terminal Gateway") {
            setDomain(`tetra.${props.tenantDetails.data.domainName}`)
            setMappedDomain(`mapped.${props.tenantDetails.data.domainName}`)
        } else {
            setDomain('')
            setMappedDomain('')
        }
    }, [selectedIwfTypeName])

    useEffect(() => {
        console.log("Tenant List----->>>>", props.tenantData)
        if (props.purpose === "edit" && props.tenantData.config) {
            setRegisterExpires(props.tenantData.config.register_expires)
            setAutoCallconfig(props.tenantData.config.autoCallPickConfig)
            setPreEstConfigCount(props.tenantData.config.preEstablishConfig)
            if (props.tenantData.selectedUeTypes && props.tenantData.selectedUeTypes.length > 0) {
                setAddedDevices(props.tenantData.selectedUeTypes);
            } else {
                setAddedDevices([])
            }
            setAllSelectedServices(props.tenantData.serviceLeafNodeList && props.tenantData.serviceLeafNodeList.length > 0 ? props.tenantData.serviceLeafNodeList : []);
            filterAddedIwfInfo(props.tenantData.serviceLeafNodeList && props.tenantData.serviceLeafNodeList.length > 0 ? props.tenantData.serviceLeafNodeList : []);
            let res = checkIwfServiceAvailable(props.tenantData.serviceLeafNodeList && props.tenantData.serviceLeafNodeList.length > 0 ? props.tenantData.serviceLeafNodeList : []);
            setEnableIwfService(res);
        }
    }, [])

    useEffect(() => {
        console.log("ueTypeList tenant config ", ueTypeList);
        if (ueTypeList) {
            setDeviceTypeList(ueTypeList);
            fetchAvailableServices(ueTypeList);
        }
    }, [ueTypeList])

    useEffect(() => {
        console.log("availableServices ", availableServices);
        if (availableServices) {
            setAllowedServices(availableServices);
        }
    }, [availableServices])


    const registerExpiresChangeHandler = (e) => {
        setIsError(false);
        setRegisterExpires(e.target.value)
    }

    const autoCallPickConfigHandler = (e) => {
        let data = { ...autoCallconfig };
        let isActive = e.target.checked;
        let configData = {
            "isActive": isActive,
            "callPickTimer": data[e.target.name] ? data[e.target.name]["callPickTimer"] ? data[e.target.name]["callPickTimer"] : "1" : "1"
        }
        data[e.target.name] = configData;
        setAutoCallconfig(data);
    }
    const autoCallPickConfigCallTimerHandler = (e) => {
        if (e.target.value >= 0) {
            let data = { ...autoCallconfig };
            let configData = {
                "isActive": data[e.target.name] ? data[e.target.name]["isActive"] ? data[e.target.name]["isActive"] : false : false,
                "callPickTimer": e.target.value
            }
            data[e.target.name] = configData
            setAutoCallconfig(data);
        }
    }
    const preEstConfigCountHandler = (e) => {
        if (e.target.value >= 0) {
            let preEstConfigCountL = [...preEstConfigCount];
            for (let configData of preEstConfigCountL) {
                if (configData["ueType"] === e.target.name) {
                    configData["CountOfPreSessions"] = e.target.value
                    break;
                }
            }
            setPreEstConfigCount(preEstConfigCountL);
        }
    }

    const addConnectionTable = () => {
        connectionList.push({ host: hostData, port: portData })
        setConnectionList(connectionList)
        setHostData('')
        setPortData('')
    }
    const hostChangeHandler = (e) => {
        setHostData(e.target.value)
    }
    const portChangeHandler = (e) => {
        setPortData(e.target.value)
    }
    const domainChangeHandler = (e) => {
        setDomain(e.target.value)
    }

    const tthChangeHandler = (e) => {
        setTTHData(e.target.value)
    }
    const ttpChangeHandler = (e) => {
        setTTPData(e.target.value)
    }
    const ttepChangeHandler = (e) => {
        setTTEPData(e.target.value)
    }

    const masterSsiChangeHandler = (e) => {
        setMasterSsi(e.target.value)
    }

    const timerChangeHandler = (e) => {
        setTimer(e.target.value)
    }

    const mappedDomainChangeHandler = (e) => {
        setMappedDomain(e.target.value)
    }

    const onAddIwf = () => {
        connectionList.push({ host: hostData, port: portData })
        setConnectionList(connectionList)
        if (selectedIwfTypeName === "Terminal Gateway") {
            addedIwfInfo.push({ name: selectedIwfTypeName, domain: domain.toLowerCase(), mappedDomain: mappedDomain.toLowerCase(), type: selectedIwfTypeType, config: { tetraIwfHost: tthData, tetraIwfPort: ttpData, tetraIwfExtPort: ttepData }, connections: connectionList })
        } else if (selectedIwfTypeName === "Extended-Terminal Gateway") {
            addedIwfInfo.push({ name: selectedIwfTypeName, domain: domain.toLowerCase(), mappedDomain: mappedDomain.toLowerCase(), type: selectedIwfTypeType, masterConfig: { masterSsi: masterSsi, timer: timer, tetraIwfExtPort: ttepData }, connections: connectionList })
        } else {
            addedIwfInfo.push({ name: selectedIwfTypeName, domain: domain.toLowerCase(), mappedDomain: mappedDomain.toLowerCase(), type: selectedIwfTypeType })
        }
        setAddedIwfInfo(addedIwfInfo)
        setDomain('')
        setMappedDomain('')
        setSelectedIwfTypeName('')
        setConnectionList([])
        setTTEPData('')
        setTTHData('')
        setTTPData('')
        setPortData('')
        setHostData('')
        setMasterSsi('')
        setTimer('')
    }

    const onDeleteConnectionList = (id) => {
        let connectionInfo = connectionList.filter((item) => item.host !== id.host)
        setConnectionList(connectionInfo)
    }
    const onResetConfigList = () => {
        setTTHData('')
        setTTEPData('')
        setTTPData('')
    }

    const onResetMasterConfigList = () => {
        setTimer('')
        setMasterSsi('')
    }

    const onResetConnectionList = () => {
        setHostData('')
        setPortData('')
    }

    const deviceTypesChangeHandler = (e) => {
        setvlDeviceType(e.target.value);
    };

    const OnAddDeviceType = () => {
        let checkAlreadyAddedDevice = addedDevices.filter(
            (device) => device === vlDeviceType
        );
        if (checkAlreadyAddedDevice.length === 0) {
            let deviceData = deviceTypeList.filter((device) => device === vlDeviceType);
            if (deviceData && deviceData.length > 0) {
                let element = deviceData[0];
                let devices = [...addedDevices, element];
                setAddedDevices(devices);

                let data = { ...autoCallconfig };
                let isActive = false;
                let configData = {
                    "isActive": isActive,
                    "callPickTimer": "1"
                }
                data[element] = configData;
                setAutoCallconfig(data);

                let preConfigData = {
                    "ueType": element,
                    "CountOfPreSessions": 0
                }
                let preEstConfigCountL = [...preEstConfigCount, preConfigData];
                setPreEstConfigCount(preEstConfigCountL);

                fetchAvailableServices(devices);
            }
        }
    };

    const deleteDeviceType = (deviceType) => {
        let deleteDevices = addedDevices.filter((device) => device !== deviceType);
        setAddedDevices(deleteDevices);

        let data = { ...autoCallconfig };
        if (data[deviceType]) {
            delete data[deviceType];
        }
        setAutoCallconfig(data);
        let preEstConfigCountL = preEstConfigCount.filter((preConfig) => preConfig["ueType"] !== deviceType);
        setPreEstConfigCount(preEstConfigCountL);
        fetchAvailableServices(deleteDevices);
    };

    const fetchAvailableServices = (devices) => {
        let ueTypeVal = new Set([]);
        for (let device of devices) {
            if (serviceUeTypeConfig && serviceUeTypeConfig[device]) {
                if (!ueTypeVal.has(serviceUeTypeConfig[device])) {
                    ueTypeVal.add(serviceUeTypeConfig[device])
                }
            }
        }
        let ueTypeValArr = Array.from(ueTypeVal);
        getAvailableServices({ ueType: ueTypeValArr, mtmId: global.config.userConfig.partnerId > 0 ? global.config.userConfig.partnerId : null })
    }

    const updatedServices = (selectedServices) => {
        setIsError(false);
        console.log("selectedServices ", selectedServices);
        setAllSelectedServices(selectedServices);
        filterAddedIwfInfo(selectedServices);
        let res = checkIwfServiceAvailable(selectedServices);
        setEnableIwfService(res);
    }

    const updateSelectedServicesOnDeviceChanges = () => {
        let selectedSericesData = [];
        let allowedService = new Set(availableServices);
        for (let selService of allSelectedServices) {
            if (allowedService.has(selService)) {
                selectedSericesData = [...selectedSericesData, selService];
            }
        }
        return selectedSericesData;
    }

    const checkIwfServiceAvailable = (availServices) => {
        let found = false;
        if (availServices && availServices.length > 0) {
            for (let serviceData of availServices) {
                let serv = serviceData.toLowerCase();
                if (serv === "tis" || serv === "term" || serv === "ex-term") {
                    found = true;
                    break;
                }
            }
        }
        return found;
    }

    const filterAddedIwfInfo = (availServices) => {
        console.log("filterAddedIwfInfo ", availServices, iwfMappedDomainConfig);
        let setAvailService = new Set(availServices);
        if (iwfMappedDomainConfig) {
            let arrIwfs = iwfMappedDomainConfig.filter(domn => setAvailService.has(domn.type));
            console.log("addediwfInfo filter ", arrIwfs);
            setAddedIwfInfo(arrIwfs);
        }
    }

    const onSubmit = () => {
        if (allSelectedServices.length > 0) {
            if (registerExpires >= 20) {
                setIsError(false);
                if (enableIwfService) {
                    props.tenantDetails.data.domains['iwfs'] = addedIwfInfo;
                } else {
                    props.tenantDetails.data.domains['iwfs'] = [];
                }
                props.createTenantData([{
                    data: {
                        ...props.tenantDetails.data,
                        config: {
                            register_expires: registerExpires,
                            autoCallPickConfig: autoCallconfig,
                            preEstablishConfig: preEstConfigCount
                        },
                        selectedUeTypes: addedDevices,
                        serviceLeafNodeList: allSelectedServices
                    }
                }])
                //console.log("tenantDetails on Submit ", filteredServices);
            } else {
                setIsError(true)
                setErrorMsg("Please enter the Register Expire Value or the value should be greater than 20 ")
            }
        }
        else {
            setIsError(true);
            setErrorMsg("Please select the MCX Services")
            console.log("allSelectedServices is empty or reg expire val is zero", allSelectedServices);
        }
    }

    return (
        <div class={classes.root}>
            <Grid container width="100%" spacing={2}>
                <Grid item xs={12}>
                    <Box class="tab1-account" width={'100%'} marginLeft={0}>
                        <Box className={classes.divList}>
                            <FormControl variant="filled" className={classes.formControlFA}>
                                <InputLabel
                                    id="demo-simple-select-filled-label"
                                    className={classes.listItemFA}
                                    focused={false}
                                >
                                    Device Types
                                </InputLabel>
                                <Select
                                    className={classes.listItemFA}
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={vlDeviceType}
                                    onChange={deviceTypesChangeHandler}
                                    disableUnderline={true}
                                >
                                    {deviceTypeList &&
                                        deviceTypeList.map((devicetype, i) => {
                                            return (
                                                <MenuItem value={devicetype} key={devicetype}>
                                                    {devicetype}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                            <button
                                className="add-btn-device"
                                type="button"
                                onClick={OnAddDeviceType}
                            >
                                Add Device
                            </button>
                            <Box style={{ width: "93%" }}>
                                <List style={{ width: "100%" }}>
                                    {addedDevices.length > 0 && addedDevices.map((device, i) => {
                                        return (
                                            <ListItem className="add-product-list" key={i}>
                                                <ListItemText
                                                    className={classes.listItemFA}
                                                    primary={device}
                                                />
                                                <button
                                                    disabled={false}
                                                    class="editBtn"
                                                    onClick={() => deleteDeviceType(device)}
                                                    type="button"
                                                    name=""
                                                >
                                                    <img
                                                        src="/assets/images/deleteimg.svg"
                                                        class="delete-user-img-fa"
                                                        alt=""
                                                    />
                                                </button>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Box>
                        </Box>
                        {
                            allowedServices && allowedServices.length > 0 ?
                                <MCXServices selectedUeTypes={addedDevices} selectedServices={allSelectedServices} allowedServices={allowedServices} updatedServices={updatedServices}></MCXServices>
                                : null
                        }
                        <Box class="form-group" paddingX={10} paddingY={0}>
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className={classes.lblStyle}>Register Expires *</label>
                            </Box>
                            <input
                                type="number"
                                className="input-control-flat"
                                id="firstName"
                                value={registerExpires}
                                onChange={registerExpiresChangeHandler}
                            />
                        </Box>
                        <fieldset className="form-group call-forwading-container" style={{ borderColor: '#4B4A4A' }}>
                            <legend className={classes.lblStyle}>Auto Call Pick Config</legend>
                            {(Object.keys(autoCallconfig)).map((item) => {
                                return (
                                    <Box>
                                        <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                            <label className={classes.lblStyle} style={{ padding: "2.5px 0px" }}>{item}</label>
                                        </Box>
                                        <Box flexDirection={'row'} display={'flex'} width={'80%'} justifyContent={'space-between'}>
                                            <FormControlLabel
                                                className={classes.formControlFA1}
                                                value="start"
                                                name={item}
                                                control={
                                                    <Switch
                                                        color="default"
                                                        checked={autoCallconfig ? autoCallconfig[item] ? autoCallconfig[item].isActive : false : false}
                                                        onChange={autoCallPickConfigHandler}
                                                    />
                                                }
                                                label="Active"
                                                labelPlacement="start"
                                                onChange={autoCallPickConfigHandler}
                                            />
                                            <Box class="form-group" paddingX={10} paddingY={0}>
                                                <Box flexDirection={'row'} display={'flex'} alignItems={'center'}>
                                                    <label class="attribute-heading" style={{ marginRight: '10px', color: 'whitesmoke' }}>Call Pick Timer</label>
                                                    <input
                                                        type="number"
                                                        className="input-control-flat"
                                                        id="firstName"
                                                        name={item}
                                                        value={autoCallconfig ? autoCallconfig[item] ? autoCallconfig[item].callPickTimer : "" : ""}
                                                        onChange={autoCallPickConfigCallTimerHandler}
                                                        style={{ width: '20%' }}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                )
                            })}
                        </fieldset>
                        <fieldset className="form-group call-forwading-container" style={{ borderColor: '#4B4A4A' }}>
                            <legend className={classes.lblStyle}>Pre Establish Config</legend>
                            {(preEstConfigCount).map((item) => {
                                return (
                                    <Box flexDirection={'row'} display={'flex'} width={'80%'} justifyContent={'space-between'}>
                                        <Box class="form-group" style={{ padding: "10px 0px" }}>
                                            <Box flexDirection={'row'} display={'flex'} alignItems={'center'}>
                                                <label class="attribute-heading" style={{ marginRight: '10px', color: 'whitesmoke' }}>UE Type</label>
                                                <input
                                                    type="text"
                                                    className="input-control-flat"
                                                    id="firstName"
                                                    value={item.ueType}
                                                    onChange={() => { }}
                                                    style={{ width: '70%' }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box class="form-group" paddingX={10} paddingY={0}>
                                            <Box style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                                <label class="attribute-heading" style={{ marginRight: '10px', color: 'whitesmoke' }}>CountOfPreSessions</label>
                                                <input
                                                    type="number"
                                                    className="input-control-flat"
                                                    id="sessiontName"
                                                    name={item.ueType}
                                                    value={item.CountOfPreSessions}
                                                    onChange={preEstConfigCountHandler}
                                                    style={{ width: '20%' }}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                )
                            })}
                        </fieldset>
                        {enableIwfService ?
                            (
                                <Box>
                                    {/* {iwfData.length !== 0 &&
                                    (
                                        <FormControl
                                            variant="filled"
                                            className={classes.formControlIwfType}
                                        >
                                            <InputLabel id="ueTypeID" className={classes.iwfInfoList}>
                                                IWF Type
                                            </InputLabel>
                                            <Select
                                                className={classes.iwfInfoList}
                                                id="ueType-select-filled"
                                                value={selectedIwfTypeName}
                                                onChange={iwfTypeChangeHandler}
                                            >
                                                {
                                                    props.purpose === "edit" ?
                                                        (iwfData &&
                                                            iwfData.map((iwfType) => {
                                                                return (
                                                                    <MenuItem value={iwfType.name}>{iwfType.name}</MenuItem>
                                                                );
                                                            })
                                                        )
                                                        :
                                                        (iwfData &&
                                                            iwfData.map((iwfType, index) => {
                                                                return (
                                                                    <MenuItem value={iwfType.name}>{iwfType.name}</MenuItem>
                                                                );
                                                            })
                                                        )
                                                }

                                            </Select>
                                        </FormControl>
                                    )
                                } */}
                                    {addedIwfInfo && addedIwfInfo.length > 0 &&
                                        (<Box marginTop={'2.5%'}>
                                            {addedIwfInfo.map((data) => {
                                                return (
                                                    <Box style={{ backgroundColor: '#4B4A4A', marginRight: '27.5px', paddingLeft: '15px', paddingRight: '5px', paddingBottom: '5px', marginBottom: '10px', marginTop: '10px', borderRadius: 7.5 }}>
                                                        <label class="attribute-heading" style={{ paddingBottom: '5px', paddingTop: '5px', marginTop: '5px', fontWeight: '600', color: 'whitesmoke' }}>Iwf Type : {data.name}</label>
                                                        <Box class="form-group" style={{ padding: "10px 0px" }}>
                                                            <label class="attribute-heading" style={{ paddingBottom: '5px', color: 'whitesmoke' }}>Domain *</label>
                                                            <input
                                                                type="text"
                                                                className="input-control-flat"
                                                                style={{ width: '60%', marginLeft: '75px' }}
                                                                id="firstName"
                                                                value={data.domain}
                                                                onChange={() => { }}
                                                            />
                                                        </Box>
                                                        <Box class="form-group" style={{ padding: "10px 0px" }}>
                                                            <label class="attribute-heading" style={{ paddingBottom: '5px', color: 'whitesmoke' }}>Mapped Domain</label>
                                                            <input
                                                                type="text"
                                                                className="input-control-flat"
                                                                style={{ width: '60%', marginLeft: '20px' }}
                                                                id="firstName"
                                                                value={data.mappedDomain}
                                                                onChange={() => { }}
                                                            />
                                                        </Box>
                                                        {(data.name === "Terminal Gateway" || data.name === "Extended-Terminal Gateway") && (data.name === "Terminal Gateway" ? data.config : data.masterConfig) &&
                                                            <Box class="form-group" style={{ padding: "10px 0px" }}>
                                                                <label class="attribute-heading" style={{ paddingBottom: '5px', color: 'whitesmoke' }}>{data.name === "Terminal Gateway" ? "Config" : "Master Config"}</label>
                                                                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', borderRadius: 4, backgroundColor: "#4B4A4A", paddingTop: '5px', paddingBottom: '5px' }}>
                                                                    <Box>
                                                                        <label class="attribute-heading" style={{ paddingBottom: '2.5px', color: 'whitesmoke' }}>{data.name === "Terminal Gateway" ? "TIH" : "Master SSI"}</label>
                                                                        <input
                                                                            type="text"
                                                                            className="input-control-flat"
                                                                            id="firstName"
                                                                            value={data.name === "Terminal Gateway" ? data.config.tetraIwfHost : data.masterConfig.masterSsi}
                                                                            onChange={() => { }}
                                                                        />
                                                                    </Box>
                                                                    <Box>
                                                                        <label class="attribute-heading" style={{ paddingBottom: '2.5px', color: 'whitesmoke' }}>{data.name === "Terminal Gateway" ? "TIP" : "Timer"}</label>
                                                                        <input
                                                                            type="text"
                                                                            className="input-control-flat"
                                                                            id="firstName"
                                                                            value={data.name === "Terminal Gateway" ? data.config.tetraIwfPort : data.masterConfig.timer}
                                                                            onChange={() => { }}
                                                                        />
                                                                    </Box>
                                                                    {data.name === "Terminal Gateway" &&
                                                                        <Box>
                                                                            <label class="attribute-heading" style={{ paddingBottom: '2.5px', color: 'whitesmoke' }}>TIEP</label>
                                                                            <input
                                                                                type="text"
                                                                                className="input-control-flat"
                                                                                id="firstName"
                                                                                value={data.config.tetraIwfExtPort}
                                                                                onChange={() => { }}
                                                                            />
                                                                        </Box>}
                                                                </Box>
                                                                <Box class="form-group" style={{ paddingTop: '10px' }}>
                                                                    <label class="attribute-heading" style={{ paddingBottom: '5px', color: 'whitesmoke' }}>Connections<span style={{ marginLeft: '7.5px' }}><button onClick={addConnectionTable}>+</button></span></label>
                                                                    <Box style={{ width: '100%', alignSelf: 'center' }}>
                                                                        {data.connections && data.connections.map((data) => {
                                                                            return (
                                                                                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', backgroundColor: '#4B4A4A', borderRadius: 4, paddingTop: '5px', paddingBottom: '5px' }}>
                                                                                    <Box>
                                                                                        <label class="attribute-heading" style={{ paddingBottom: '2.5px', color: 'whitesmoke' }}>Host</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="input-control-flat"
                                                                                            id="firstName"
                                                                                            value={data.host}
                                                                                            onChange={() => { }}
                                                                                        />
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <label class="attribute-heading" style={{ paddingBottom: '2.5px', color: 'whitesmoke' }}>Port</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="input-control-flat"
                                                                                            id="firstName"
                                                                                            value={data.port}
                                                                                            onChange={() => { }}
                                                                                        />
                                                                                    </Box>
                                                                                </Box>
                                                                            )
                                                                        })}
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        }
                                                        {/* <div style={{ justifyContent: 'flex-end', flexDirection: 'row', display: 'flex' }}>
                                                        <div>
                                                            {
                                                                <button
                                                                    class="add-btn-fa"
                                                                    type="button"
                                                                    onClick={() => { onDeleteIwfInfo(data) }}
                                                                >
                                                                    Delete IWF
                                                                </button>
                                                            }
                                                        </div>
                                                    </div> */}
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                        )}
                                    <Box >
                                        {
                                            selectedIwfTypeName &&
                                            <Box>
                                                <Box class="form-group" style={{ padding: "10px 0px" }}>
                                                    <label class="attribute-heading" style={{ paddingBottom: '5px', color: 'whitesmoke' }}>Domain *</label>
                                                    <input
                                                        type="text"
                                                        className="input-control-flat"
                                                        id="firstName"
                                                        value={domain}
                                                        onChange={domainChangeHandler}
                                                    />
                                                </Box>
                                                <Box class="form-group" style={{ padding: "10px 0px" }}>
                                                    <label class="attribute-heading" style={{ paddingBottom: '5px', color: 'whitesmoke' }}>Mapped Domain</label>
                                                    <input
                                                        type="text"
                                                        className="input-control-flat"
                                                        id="firstName"
                                                        value={mappedDomain}
                                                        onChange={mappedDomainChangeHandler}
                                                    />
                                                </Box>
                                                {(selectedIwfTypeName === "Terminal Gateway" || selectedIwfTypeName === "Extended-Terminal Gateway") &&
                                                    <Box>
                                                        <Box class="form-group" style={{ padding: "10px 0px" }}>
                                                            <label class="attribute-heading" style={{ paddingBottom: '5px', color: 'whitesmoke' }}>{selectedIwfTypeName === "Terminal Gateway" ? "Config *" : "Master Config *"}</label>
                                                            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', borderRadius: 4, backgroundColor: "#b2beb5", paddingTop: '5px', paddingBottom: '5px' }}>
                                                                <Box>
                                                                    <label class="attribute-heading" style={{ paddingBottom: '2.5px', color: 'whitesmoke' }}>{selectedIwfTypeName === "Terminal Gateway" ? "TIH *" : "Master SSI *"}</label>
                                                                    <input
                                                                        type="text"
                                                                        className="input-control-flat"
                                                                        id="firstName"
                                                                        value={selectedIwfTypeName === "Terminal Gateway" ? tthData : masterSsi}
                                                                        onChange={selectedIwfTypeName === "Terminal Gateway" ? tthChangeHandler : masterSsiChangeHandler}
                                                                    />
                                                                </Box>
                                                                <Box>
                                                                    <label class="attribute-heading" style={{ paddingBottom: '2.5px', color: 'whitesmoke' }}>{selectedIwfTypeName === "Terminal Gateway" ? "TIP *" : "Timer *"}</label>
                                                                    <input
                                                                        type="text"
                                                                        className="input-control-flat"
                                                                        id="firstName"
                                                                        value={selectedIwfTypeName === "Terminal Gateway" ? ttpData : timer}
                                                                        onChange={selectedIwfTypeName === "Terminal Gateway" ? ttpChangeHandler : timerChangeHandler}
                                                                    />
                                                                </Box>

                                                                {selectedIwfTypeName === "Terminal Gateway" &&
                                                                    <Box>
                                                                        <label class="attribute-heading" style={{ paddingBottom: '2.5px', color: 'whitesmoke' }}>TIEP *</label>
                                                                        <input
                                                                            type="text"
                                                                            className="input-control-flat"
                                                                            id="firstName"
                                                                            value={ttepData}
                                                                            onChange={ttepChangeHandler}
                                                                        />
                                                                    </Box>}
                                                                <Box style={{ justifyContent: 'center', flexDirection: 'column', display: 'flex' }}>
                                                                    <label class="attribute-heading" style={{ paddingBottom: '2.5px', color: 'whitesmoke' }}>Reset</label>
                                                                    <button
                                                                        class="editBtn"
                                                                        onClick={() => { selectedIwfTypeName === "Terminal Gateway" ? onResetConfigList() : onResetMasterConfigList() }}
                                                                        type="button"
                                                                        name=""
                                                                    >
                                                                        <img
                                                                            src="/assets/images/deleteimg.svg"
                                                                            class="delete-user-img-fa"
                                                                            alt=""
                                                                        />
                                                                    </button>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box class="form-group" style={{ paddingTop: '10px', width: '100%', alignItems: 'center' }}>
                                                            <label class="attribute-heading" style={{ paddingBottom: '5px', color: 'whitesmoke' }}>Connections{hostData !== "" && portData !== "" ? <span style={{ marginLeft: '7.5px' }}><button onClick={addConnectionTable}>+</button></span> : <span> *</span>}</label>
                                                            {connectionList && connectionList.map((data) => {
                                                                return (
                                                                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', backgroundColor: '#b2beb5', borderRadius: 4, paddingTop: '5px', paddingBottom: '5px', marginBottom: '10px' }}>
                                                                        <Box>
                                                                            <label class="attribute-heading" style={{ paddingBottom: '2.5px', color: 'whitesmoke' }}>Host</label>
                                                                            <input
                                                                                type="text"
                                                                                className="input-control-flat"
                                                                                id="firstName"
                                                                                value={data.host}
                                                                                onChange={() => { }}
                                                                            />
                                                                        </Box>
                                                                        <Box>
                                                                            <label class="attribute-heading" style={{ paddingBottom: '2.5px', color: 'whitesmoke' }}>Port</label>
                                                                            <input
                                                                                type="text"
                                                                                className="input-control-flat"
                                                                                id="firstName"
                                                                                value={data.port}
                                                                                onChange={() => { }}
                                                                            />
                                                                        </Box>
                                                                        <Box style={{ justifyContent: 'center', flexDirection: 'column', display: 'flex' }}>
                                                                            <label class="attribute-heading" style={{ paddingBottom: '2.5px', color: 'whitesmoke' }}>Delete</label>
                                                                            <button
                                                                                class="editBtn"
                                                                                onClick={() => { onDeleteConnectionList(data) }}
                                                                                type="button"
                                                                                name=""
                                                                            >
                                                                                <img
                                                                                    src="/assets/images/deleteimg.svg"
                                                                                    class="delete-user-img-fa"
                                                                                    alt=""
                                                                                />
                                                                            </button>
                                                                        </Box>
                                                                    </Box>
                                                                )
                                                            })}
                                                            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', backgroundColor: '#b2beb5', borderRadius: 4, paddingTop: '5px', paddingBottom: '5px' }}>
                                                                <Box>
                                                                    <label class="attribute-heading" style={{ paddingBottom: '2.5px', color: 'whitesmoke' }}>Host *</label>
                                                                    <input
                                                                        type="text"
                                                                        className="input-control-flat"
                                                                        id="firstName"
                                                                        value={hostData}
                                                                        onChange={hostChangeHandler}
                                                                    />
                                                                </Box>
                                                                <Box>
                                                                    <label class="attribute-heading" style={{ paddingBottom: '2.5px', color: 'whitesmoke' }}>Port *</label>
                                                                    <input
                                                                        type="text"
                                                                        className="input-control-flat"
                                                                        id="firstName"
                                                                        value={portData}
                                                                        onChange={portChangeHandler}
                                                                    />
                                                                </Box>
                                                                <Box style={{ justifyContent: 'center', flexDirection: 'column', display: 'flex' }}>
                                                                    <label class="attribute-heading" style={{ paddingBottom: '2.5px', color: 'whitesmoke' }}>Reset</label>
                                                                    <button
                                                                        class="editBtn"
                                                                        onClick={() => { onResetConnectionList() }}
                                                                        type="button"
                                                                        name=""
                                                                    >
                                                                        <img
                                                                            src="/assets/images/deleteimg.svg"
                                                                            class="delete-user-img-fa"
                                                                            alt=""
                                                                        />
                                                                    </button>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                }
                                                {/* <div style={{ justifyContent: 'flex-end', flexDirection: 'row', display: 'flex' }}>
                                                <div>
                                                    {
                                                        <button
                                                            class="add-btn-fa"
                                                            type="button"
                                                            onClick={onAddIwf}
                                                        >
                                                            Add IWF
                                                        </button>
                                                    }
                                                </div>
                                            </div> */}
                                            </Box>
                                        }
                                    </Box>
                                </Box>
                            ) : null}
                    </Box>
                </Grid >
            </Grid >
            <Box style={{ marginRight: '25px' }}>
                {isError === true ? (
                    <p class="error-handling-lbl">{errorMsg}</p>
                ) : null}
                <button
                    class="update-btn-profile"
                    type="button"
                    onClick={onSubmit}
                >
                    {props.purpose === "edit" ? "UPDATE" : "SUBMIT"}
                </button>
            </Box>
        </div >
        // <div class={classes.root}>
        //     <ThemeProvider theme={theme}>
        //         <div class="tab1-account" style={{ width: "90%", marginLeft: "25px" }}>
        //             <label class="tab1-heading" style={{ padding: "10px 0px" }}>Config Details</label>
        //             <div className={classes.divList}>
        //                 <FormControl variant="filled" className={classes.formControlFA}>
        //                     <InputLabel
        //                         id="demo-simple-select-filled-label"
        //                         className={classes.listItemFA}
        //                     >
        //                         Device Types
        //                     </InputLabel>
        //                     <Select
        //                         className={classes.listItemFA}
        //                         labelId="demo-simple-select-filled-label"
        //                         id="demo-simple-select-filled"
        //                         value={vlDeviceType}
        //                         onChange={deviceTypesChangeHandler}
        //                     >
        //                         {deviceTypeList &&
        //                             deviceTypeList.map((devicetype, i) => {
        //                                 return (
        //                                     <MenuItem value={devicetype} key={devicetype}>
        //                                         {devicetype}
        //                                     </MenuItem>
        //                                 );
        //                             })}
        //                     </Select>
        //                 </FormControl>
        //                 <button
        //                     className="add-btn-device"
        //                     type="button"
        //                     onClick={OnAddDeviceType}
        //                 >
        //                     Add Device
        //                 </button>
        //                 <div style={{ width: "93%" }}>
        //                     <List style={{ width: "100%" }}>
        //                         {addedDevices.length > 0 && addedDevices.map((device, i) => {
        //                             return (
        //                                 <ListItem className="add-product-list" key={i}>
        //                                     <ListItemText
        //                                         className={classes.listItemFA}
        //                                         primary={device}
        //                                     />
        //                                     <button
        //                                         disabled={false}
        //                                         class="editBtn"
        //                                         onClick={() => deleteDeviceType(device)}
        //                                         type="button"
        //                                         name=""
        //                                     >
        //                                         <img
        //                                             src="/assets/images/deleteimg.svg"
        //                                             class="delete-user-img-fa"
        //                                             alt=""
        //                                         />
        //                                     </button>
        //                                 </ListItem>
        //                             );
        //                         })}
        //                     </List>
        //                 </div>
        //             </div>
        //             {
        //                 allowedServices && allowedServices.length > 0 ?
        //                     <MCXServices selectedUeTypes={addedDevices} selectedServices={allSelectedServices} allowedServices={allowedServices} updatedServices={updatedServices}></MCXServices>
        //                     : null
        //             }
        //             <div class="form-group" style={{ padding: "10px 0px" }}>
        //                 <label class="attribute-heading" style={{ paddingBottom: '5px' }}>Register Expires *</label>
        //                 <input
        //                     type="number"
        //                     class="input-control"
        //                     id="firstName"
        //                     value={registerExpires}
        //                     onChange={registerExpiresChangeHandler}
        //                 />
        //             </div>
        //             <fieldset className="form-group call-forwading-container">
        //                 <legend>Auto Call Pick Config</legend>
        //                 {(Object.keys(autoCallconfig)).map((item) => {
        //                     return (
        //                         <div>
        //                             <label class="tab2-heading" style={{ padding: "2.5px 0px" }}>{item}</label>
        //                             <div style={{ flexDirection: 'row', display: 'flex', width: '80%', justifyContent: 'space-between' }}>
        //                                 <FormControlLabel
        //                                     className={classes.mleft0}
        //                                     value="start"
        //                                     name={item}
        //                                     control={
        //                                         <Switch
        //                                             color="primary"
        //                                             checked={autoCallconfig ? autoCallconfig[item] ? autoCallconfig[item].isActive : false : false}
        //                                             onChange={autoCallPickConfigHandler}
        //                                         />
        //                                     }
        //                                     label="Active"
        //                                     labelPlacement="start"
        //                                     onChange={autoCallPickConfigHandler}
        //                                 />
        //                                 <div class="form-group" style={{ padding: "10px 0px" }}>
        //                                     <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
        //                                         <label class="attribute-heading" style={{ marginRight: '10px' }}>Call Pick Timer</label>
        //                                         <input
        //                                             type="number"
        //                                             class="input-control"
        //                                             id="firstName"
        //                                             name={item}
        //                                             value={autoCallconfig ? autoCallconfig[item] ? autoCallconfig[item].callPickTimer : "" : ""}
        //                                             onChange={autoCallPickConfigCallTimerHandler}
        //                                             style={{ width: '20%' }}
        //                                         />
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     )
        //                 })}
        //             </fieldset>
        //             <fieldset className="form-group call-forwading-container">
        //                 <legend>Pre Establish Config</legend>
        //                 {(preEstConfigCount).map((item) => {
        //                     return (
        //                         <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
        //                             <div class="form-group" style={{ padding: "10px 0px" }}>
        //                                 <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
        //                                     <label class="attribute-heading" style={{ marginRight: '10px' }}>UE Type</label>
        //                                     <input
        //                                         type="text"
        //                                         class="input-control"
        //                                         id="firstName"
        //                                         value={item.ueType}
        //                                         onChange={() => { }}
        //                                         style={{ width: '70%' }}
        //                                     />
        //                                 </div>
        //                             </div>
        //                             <div class="form-group" style={{ padding: "10px 0px" }}>
        //                                 <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
        //                                     <label class="attribute-heading" style={{ marginRight: '10px' }}>CountOfPreSessions</label>
        //                                     <input
        //                                         type="number"
        //                                         class="input-control"
        //                                         id="sessiontName"
        //                                         name={item.ueType}
        //                                         value={item.CountOfPreSessions}
        //                                         onChange={preEstConfigCountHandler}
        //                                         style={{ width: '20%' }}
        //                                     />
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     )
        //                 })}
        //             </fieldset>
        //             {enableIwfService ?
        //                 (
        //                     <div>
        //                         {/* {iwfData.length !== 0 &&
        //                             (
        //                                 <FormControl
        //                                     variant="filled"
        //                                     className={classes.formControlIwfType}
        //                                 >
        //                                     <InputLabel id="ueTypeID" className={classes.iwfInfoList}>
        //                                         IWF Type
        //                                     </InputLabel>
        //                                     <Select
        //                                         className={classes.iwfInfoList}
        //                                         id="ueType-select-filled"
        //                                         value={selectedIwfTypeName}
        //                                         onChange={iwfTypeChangeHandler}
        //                                     >
        //                                         {
        //                                             props.purpose === "edit" ?
        //                                                 (iwfData &&
        //                                                     iwfData.map((iwfType) => {
        //                                                         return (
        //                                                             <MenuItem value={iwfType.name}>{iwfType.name}</MenuItem>
        //                                                         );
        //                                                     })
        //                                                 )
        //                                                 :
        //                                                 (iwfData &&
        //                                                     iwfData.map((iwfType, index) => {
        //                                                         return (
        //                                                             <MenuItem value={iwfType.name}>{iwfType.name}</MenuItem>
        //                                                         );
        //                                                     })
        //                                                 )
        //                                         }

        //                                     </Select>
        //                                 </FormControl>
        //                             )
        //                         } */}
        //                         {addedIwfInfo && addedIwfInfo.length > 0 &&
        //                             (<div>
        //                                 {addedIwfInfo.map((data) => {
        //                                     return (
        //                                         <div style={{ backgroundColor: '#c5c6d0', marginRight: '27.5px', paddingLeft: '5px', paddingRight: '5px', paddingBottom: '5px', borderRadius: 4, marginBottom: '10px' }}>
        //                                             <label class="attribute-heading" style={{ paddingBottom: '5px', paddingTop: '5px', marginTop: '5px', fontWeight: '600' }}>Iwf Type : {data.name}</label>
        //                                             <div class="form-group" style={{ padding: "10px 0px" }}>
        //                                                 <label class="attribute-heading" style={{ paddingBottom: '5px' }}>Domain *</label>
        //                                                 <input
        //                                                     type="text"
        //                                                     class="input-control"
        //                                                     id="firstName"
        //                                                     value={data.domain}
        //                                                     onChange={() => { }}
        //                                                 />
        //                                             </div>
        //                                             <div class="form-group" style={{ padding: "10px 0px" }}>
        //                                                 <label class="attribute-heading" style={{ paddingBottom: '5px' }}>Mapped Domain</label>
        //                                                 <input
        //                                                     type="text"
        //                                                     class="input-control"
        //                                                     id="firstName"
        //                                                     value={data.mappedDomain}
        //                                                     onChange={() => { }}
        //                                                 />
        //                                             </div>
        //                                             {(data.name === "Terminal Gateway" || data.name === "Extended-Terminal Gateway") && (data.name === "Terminal Gateway" ? data.config : data.masterConfig) &&
        //                                                 <div class="form-group" style={{ padding: "10px 0px" }}>
        //                                                     <label class="attribute-heading" style={{ paddingBottom: '5px' }}>{data.name === "Terminal Gateway" ? "Config" : "Master Config"}</label>
        //                                                     <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', borderRadius: 4, backgroundColor: "#b2beb5", paddingTop: '5px', paddingBottom: '5px' }}>
        //                                                         <div>
        //                                                             <label class="attribute-heading" style={{ paddingBottom: '2.5px' }}>{data.name === "Terminal Gateway" ? "TIH" : "Master SSI"}</label>
        //                                                             <input
        //                                                                 type="text"
        //                                                                 class="input-control"
        //                                                                 id="firstName"
        //                                                                 value={data.name === "Terminal Gateway" ? data.config.tetraIwfHost : data.masterConfig.masterSsi}
        //                                                                 onChange={() => { }}
        //                                                             />
        //                                                         </div>
        //                                                         <div>
        //                                                             <label class="attribute-heading" style={{ paddingBottom: '2.5px' }}>{data.name === "Terminal Gateway" ? "TIP" : "Timer"}</label>
        //                                                             <input
        //                                                                 type="text"
        //                                                                 class="input-control"
        //                                                                 id="firstName"
        //                                                                 value={data.name === "Terminal Gateway" ? data.config.tetraIwfPort : data.masterConfig.timer}
        //                                                                 onChange={() => { }}
        //                                                             />
        //                                                         </div>
        //                                                         {data.name === "Terminal Gateway" &&
        //                                                             <div>
        //                                                                 <label class="attribute-heading" style={{ paddingBottom: '2.5px' }}>TIEP</label>
        //                                                                 <input
        //                                                                     type="text"
        //                                                                     class="input-control"
        //                                                                     id="firstName"
        //                                                                     value={data.config.tetraIwfExtPort}
        //                                                                     onChange={() => { }}
        //                                                                 />
        //                                                             </div>}
        //                                                     </div>
        //                                                     <div class="form-group" style={{ paddingTop: '10px' }}>
        //                                                         <label class="attribute-heading" style={{ paddingBottom: '5px' }}>Connections<span style={{ marginLeft: '7.5px' }}><button onClick={addConnectionTable}>+</button></span></label>
        //                                                         <div style={{ width: '100%', alignSelf: 'center' }}>
        //                                                             {data.connections && data.connections.map((data) => {
        //                                                                 return (
        //                                                                     <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', backgroundColor: '#b2beb5', borderRadius: 4, paddingTop: '5px', paddingBottom: '5px' }}>
        //                                                                         <div>
        //                                                                             <label class="attribute-heading" style={{ paddingBottom: '2.5px' }}>Host</label>
        //                                                                             <input
        //                                                                                 type="text"
        //                                                                                 class="input-control"
        //                                                                                 id="firstName"
        //                                                                                 value={data.host}
        //                                                                                 onChange={() => { }}
        //                                                                             />
        //                                                                         </div>
        //                                                                         <div>
        //                                                                             <label class="attribute-heading" style={{ paddingBottom: '2.5px' }}>Port</label>
        //                                                                             <input
        //                                                                                 type="text"
        //                                                                                 class="input-control"
        //                                                                                 id="firstName"
        //                                                                                 value={data.port}
        //                                                                                 onChange={() => { }}
        //                                                                             />
        //                                                                         </div>
        //                                                                     </div>
        //                                                                 )
        //                                                             })}
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>
        //                                             }
        //                                             {/* <div style={{ justifyContent: 'flex-end', flexDirection: 'row', display: 'flex' }}>
        //                                                 <div>
        //                                                     {
        //                                                         <button
        //                                                             class="add-btn-fa"
        //                                                             type="button"
        //                                                             onClick={() => { onDeleteIwfInfo(data) }}
        //                                                         >
        //                                                             Delete IWF
        //                                                         </button>
        //                                                     }
        //                                                 </div>
        //                                             </div> */}
        //                                         </div>
        //                                     )
        //                                 })}
        //                             </div>
        //                             )}
        //                         <div style={{ backgroundColor: '#f6e8b1', marginRight: '27.5px', paddingLeft: '5px', paddingRight: '5px', paddingBottom: '5px', borderRadius: 4 }}>
        //                             {
        //                                 selectedIwfTypeName &&
        //                                 <div>
        //                                     <div class="form-group" style={{ padding: "10px 0px" }}>
        //                                         <label class="attribute-heading" style={{ paddingBottom: '5px' }}>Domain *</label>
        //                                         <input
        //                                             type="text"
        //                                             class="input-control"
        //                                             id="firstName"
        //                                             value={domain}
        //                                             onChange={domainChangeHandler}
        //                                         />
        //                                     </div>
        //                                     <div class="form-group" style={{ padding: "10px 0px" }}>
        //                                         <label class="attribute-heading" style={{ paddingBottom: '5px' }}>Mapped Domain</label>
        //                                         <input
        //                                             type="text"
        //                                             class="input-control"
        //                                             id="firstName"
        //                                             value={mappedDomain}
        //                                             onChange={mappedDomainChangeHandler}
        //                                         />
        //                                     </div>
        //                                     {(selectedIwfTypeName === "Terminal Gateway" || selectedIwfTypeName === "Extended-Terminal Gateway") &&
        //                                         <div>
        //                                             <div class="form-group" style={{ padding: "10px 0px" }}>
        //                                                 <label class="attribute-heading" style={{ paddingBottom: '5px' }}>{selectedIwfTypeName === "Terminal Gateway" ? "Config *" : "Master Config *"}</label>
        //                                                 <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', borderRadius: 4, backgroundColor: "#b2beb5", paddingTop: '5px', paddingBottom: '5px' }}>
        //                                                     <div>
        //                                                         <label class="attribute-heading" style={{ paddingBottom: '2.5px' }}>{selectedIwfTypeName === "Terminal Gateway" ? "TIH *" : "Master SSI *"}</label>
        //                                                         <input
        //                                                             type="text"
        //                                                             class="input-control"
        //                                                             id="firstName"
        //                                                             value={selectedIwfTypeName === "Terminal Gateway" ? tthData : masterSsi}
        //                                                             onChange={selectedIwfTypeName === "Terminal Gateway" ? tthChangeHandler : masterSsiChangeHandler}
        //                                                         />
        //                                                     </div>
        //                                                     <div>
        //                                                         <label class="attribute-heading" style={{ paddingBottom: '2.5px' }}>{selectedIwfTypeName === "Terminal Gateway" ? "TIP *" : "Timer *"}</label>
        //                                                         <input
        //                                                             type="text"
        //                                                             class="input-control"
        //                                                             id="firstName"
        //                                                             value={selectedIwfTypeName === "Terminal Gateway" ? ttpData : timer}
        //                                                             onChange={selectedIwfTypeName === "Terminal Gateway" ? ttpChangeHandler : timerChangeHandler}
        //                                                         />
        //                                                     </div>

        //                                                     {selectedIwfTypeName === "Terminal Gateway" &&
        //                                                         <div>
        //                                                             <label class="attribute-heading" style={{ paddingBottom: '2.5px' }}>TIEP *</label>
        //                                                             <input
        //                                                                 type="text"
        //                                                                 class="input-control"
        //                                                                 id="firstName"
        //                                                                 value={ttepData}
        //                                                                 onChange={ttepChangeHandler}
        //                                                             />
        //                                                         </div>}
        //                                                     <div style={{ justifyContent: 'center', flexDirection: 'column', display: 'flex' }}>
        //                                                         <label class="attribute-heading" style={{ paddingBottom: '2.5px' }}>Reset</label>
        //                                                         <button
        //                                                             class="editBtn"
        //                                                             onClick={() => { selectedIwfTypeName === "Terminal Gateway" ? onResetConfigList() : onResetMasterConfigList() }}
        //                                                             type="button"
        //                                                             name=""
        //                                                         >
        //                                                             <img
        //                                                                 src="/assets/images/deleteimg.svg"
        //                                                                 class="delete-user-img-fa"
        //                                                                 alt=""
        //                                                             />
        //                                                         </button>
        //                                                     </div>
        //                                                 </div>
        //                                             </div>
        //                                             <div class="form-group" style={{ paddingTop: '10px', width: '100%', alignItems: 'center' }}>
        //                                                 <label class="attribute-heading" style={{ paddingBottom: '5px' }}>Connections{hostData !== "" && portData !== "" ? <span style={{ marginLeft: '7.5px' }}><button onClick={addConnectionTable}>+</button></span> : <span> *</span>}</label>
        //                                                 {connectionList && connectionList.map((data) => {
        //                                                     return (
        //                                                         <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', backgroundColor: '#b2beb5', borderRadius: 4, paddingTop: '5px', paddingBottom: '5px', marginBottom: '10px' }}>
        //                                                             <div>
        //                                                                 <label class="attribute-heading" style={{ paddingBottom: '2.5px' }}>Host</label>
        //                                                                 <input
        //                                                                     type="text"
        //                                                                     class="input-control"
        //                                                                     id="firstName"
        //                                                                     value={data.host}
        //                                                                     onChange={() => { }}
        //                                                                 />
        //                                                             </div>
        //                                                             <div>
        //                                                                 <label class="attribute-heading" style={{ paddingBottom: '2.5px' }}>Port</label>
        //                                                                 <input
        //                                                                     type="text"
        //                                                                     class="input-control"
        //                                                                     id="firstName"
        //                                                                     value={data.port}
        //                                                                     onChange={() => { }}
        //                                                                 />
        //                                                             </div>
        //                                                             <div style={{ justifyContent: 'center', flexDirection: 'column', display: 'flex' }}>
        //                                                                 <label class="attribute-heading" style={{ paddingBottom: '2.5px' }}>Delete</label>
        //                                                                 <button
        //                                                                     class="editBtn"
        //                                                                     onClick={() => { onDeleteConnectionList(data) }}
        //                                                                     type="button"
        //                                                                     name=""
        //                                                                 >
        //                                                                     <img
        //                                                                         src="/assets/images/deleteimg.svg"
        //                                                                         class="delete-user-img-fa"
        //                                                                         alt=""
        //                                                                     />
        //                                                                 </button>
        //                                                             </div>
        //                                                         </div>
        //                                                     )
        //                                                 })}
        //                                                 <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', backgroundColor: '#b2beb5', borderRadius: 4, paddingTop: '5px', paddingBottom: '5px' }}>
        //                                                     <div>
        //                                                         <label class="attribute-heading" style={{ paddingBottom: '2.5px' }}>Host *</label>
        //                                                         <input
        //                                                             type="text"
        //                                                             class="input-control"
        //                                                             id="firstName"
        //                                                             value={hostData}
        //                                                             onChange={hostChangeHandler}
        //                                                         />
        //                                                     </div>
        //                                                     <div>
        //                                                         <label class="attribute-heading" style={{ paddingBottom: '2.5px' }}>Port *</label>
        //                                                         <input
        //                                                             type="text"
        //                                                             class="input-control"
        //                                                             id="firstName"
        //                                                             value={portData}
        //                                                             onChange={portChangeHandler}
        //                                                         />
        //                                                     </div>
        //                                                     <div style={{ justifyContent: 'center', flexDirection: 'column', display: 'flex' }}>
        //                                                         <label class="attribute-heading" style={{ paddingBottom: '2.5px' }}>Reset</label>
        //                                                         <button
        //                                                             class="editBtn"
        //                                                             onClick={() => { onResetConnectionList() }}
        //                                                             type="button"
        //                                                             name=""
        //                                                         >
        //                                                             <img
        //                                                                 src="/assets/images/deleteimg.svg"
        //                                                                 class="delete-user-img-fa"
        //                                                                 alt=""
        //                                                             />
        //                                                         </button>
        //                                                     </div>
        //                                                 </div>
        //                                             </div>
        //                                         </div>
        //                                     }
        //                                     {/* <div style={{ justifyContent: 'flex-end', flexDirection: 'row', display: 'flex' }}>
        //                                         <div>
        //                                             {
        //                                                 <button
        //                                                     class="add-btn-fa"
        //                                                     type="button"
        //                                                     onClick={onAddIwf}
        //                                                 >
        //                                                     Add IWF
        //                                                 </button>
        //                                             }
        //                                         </div>
        //                                     </div> */}
        //                                 </div>
        //                             }
        //                         </div>
        //                     </div>
        //                 ) : null}
        //         </div>
        //     </ThemeProvider >
        //     <div style={{ marginRight: '25px' }}>
        //         {isRegisterExpiresNull === true ? (
        //             <p class="error-handling-lbl">Please enter the Register Expire Value or the value should be greater than 20 </p>
        //         ) : null}
        //         <button
        //             class="update-btn-profile"
        //             type="button"
        //             onClick={onSubmit}
        //         >
        //             {props.purpose === "edit" ? "UPDATE" : "SUBMIT"}
        //         </button>
        //     </div>
        // </div >
    )

}

const mapStateToProps = ({ mtmstate }) => {
    const { ueTypeList, availableServices, serviceUeTypeConfig, iwfMappedDomainConfig } = mtmstate;
    return {
        ueTypeList,
        availableServices,
        serviceUeTypeConfig,
        iwfMappedDomainConfig
    };
};


export default connect(mapStateToProps, {
    getAvailableServices
})(ConfigDetails)