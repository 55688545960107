import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import { DataGrid } from '@mui/x-data-grid';
import dayjs from "dayjs";

const AllocateSubsciptionUser = (props) => {
    const { currentActiveSubscriptions, allocateSubscription } = props;

    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: "#252524",
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            width: "98%",
            height: 200,
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
    }));

    const columns = [
        {
            field: 'productSku', headerName: 'ProductSku', width: 250, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
            valueGetter: (value, row) => `${row.products ? row.products.productSku : ""}`,
        },
        {
            field: 'productName', headerName: 'Product Name', width: 300, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
            valueGetter: (value, row) => `${row.products ? row.products.shortDescription : ""}`,
        },
        {
            field: 'numDays', headerName: 'NumDays', width: 130, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
        },
        {
            field: 'subscriptionType', headerName: 'SubscriptionType', width: 200, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
        },
        {
            field: 'purchaseDate',
            headerName: 'Purchase Date',
            width: 200,
            valueGetter: (value, row) => `${dayjs(row.purchaseDate).format("DD-MM-YYYY")}`,
            sortable: false,
            headerClassName: "list-head-Val",
            cellClassName: "list-row-Val",
            align: "center",
            headerAlign: "center",
        },
        {
            field: 'startDate',
            headerName: 'Start Date',
            width: 200,
            valueGetter: (value, row) => `${dayjs(row.startDate).format("DD-MM-YYYY")}`,
            sortable: false,
            headerClassName: "list-head-Val",
            cellClassName: "list-row-Val",
            align: "center",
            headerAlign: "center",
        },
        {
            field: 'expiryDate',
            headerName: 'Expiry',
            width: 200,
            valueGetter: (value, row) => `${dayjs(row.expiryDate).format("DD-MM-YYYY")}`,
            sortable: false,
            headerClassName: "list-head-Val",
            cellClassName: "list-row-Val",
            align: "center",
            headerAlign: "center",
        },
        {
            field: 'delete',
            headerName: '',
            width: 160,
            height: 50,
            sortable: false,
            align: "center",
            headerAlign: "center",
            headerClassName: "list-head-Val",
            cellClassName: "list-row-Val",
            renderCell: (cellValue) => {
                return (
                    <button
                        className="delete-btn-profile"
                        type="button"
                        onClick={() => {
                            addedSubsForAllocation(cellValue)
                        }}
                    >
                        Delete
                    </button>
                )
            }
        },
    ];



    const addedSubsForAllocation = (data) => {
        console.log("addedSubForAllocation ", data, currentActiveSubscriptions);
        let allocate = [];
        for (let sub of currentActiveSubscriptions) {
            if (data && data.id !== sub.id) {
                allocate = [...allocate, sub];
            }
        }
        allocateSubscription(allocate);
    }
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <DataGrid
                rows={currentActiveSubscriptions}
                columns={columns}
                hideFooter
                hideFooterPagination
                hideFooterSelectedRowCount
                disableColumnMenu
                localeText={{ noRowsLabel: "" }}
                getRowSpacing={params => ({
                    top: params.isFirstVisible ? 0 : 0,
                    bottom: params.isLastVisible ? 0 : 0
                })}

                sx={{
                    boxShadow: 2,
                    border: "none",
                    borderBottom: "none",
                    // display: "block",
                    '& .MuiDataGrid-cell:hover': {
                        color: '#ffb01f',
                    },
                    '& .MuiDataGrid': {
                        border: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        borderColor: "#404040",
                    },
                    '& .MuiDataGrid-columnHeader': {
                        borderColor: "gray",
                    },
                    "& .MuiDataGrid-sortIcon": {
                        opacity: 1,
                        color: "white",
                    },
                    "& .MuiDataGrid-menuIconButton": {
                        opacity: 1,
                        color: "white"
                    },
                    '& .MuiDataGrid-filler': {
                        backgroundColor: "#262626",
                    },
                }}
            />
        </div>
    )
}

export default AllocateSubsciptionUser;