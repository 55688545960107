(function (exports) {
  const Emmiter = require("events").EventEmitter;
  const FASubscriber = require("./subsriber").FASubscriber;
  class FAManager extends Emmiter {
    constructor(UA, callback = null) {
      super();
      this.UA = UA;
      this.subscriber = new FASubscriber(UA);
      this.subscriber.on('FASubscribe', (data) => {
        if (callback) {
          callback(data)
        } else {
          this.emit("FASubscribe", data);
        }
      })
    }

    subscribe() {
      this.subscriber.subscribe();
    }

    mergeRegstatusWithContact(globalContactList) {
      console.log("mergeRegstatusWithContact FAMC()");
      this.subscriber.mergeRegstatusWithContact(globalContactList);
    }

    handleNotify(request) {
      console.log('Incoming notify to FAMC');
      this.subscriber.handleNotify(request, this.UA.globalContactList);
    }

    mergeRegstatusWithContact(globalContactList) {
      console.log("mergeRegstatusWithContact FAMC()");
      this.subscriber.mergeRegstatusWithContact(globalContactList);
    }

    unsubscribe() {
      this.subscriber.expires = '0'
      this.subscriber.subscribe();
    }
  }

  exports.FAManager = FAManager

})(typeof exports === 'undefined' ? this['FAManager'] = {} : exports);
