import React, { useEffect,useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from "react-redux";

const MyPurchases = (props) => {
    const { orders, deviceTypeList,paginationFunc } = props;
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    })

    useEffect(() => {
        paginationFunc(paginationModel, "purchase")
    }, [paginationModel])

    const columns = [
        {
            field: 'productId', headerName: 'Product Id', width: 150, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "left", headerAlign: "left",
        },
        {
            field: 'products', headerName: 'Product Name', width: 400, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "left", headerAlign: "left",
            valueGetter: (value, row) => `${row.products ? row.products.shortDescription : ""}`,
        },
        {
            field: 'productType', headerName: 'Product Type', width: 200, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "left", headerAlign: "left",
            valueGetter: (value, row) => `${row.products ? row.products.productType : ""}`,
        },
        {
            field: 'productSubType', headerName: 'Product SubType', width: 250, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "left", headerAlign: "left",
            valueGetter: (value, row) => `${row.products ? getProductSubTypeName(row.products.productType, row.products.productSubType) : ""}`,
        },
        {
            field: 'orderValue',
            headerName: 'Order Value',
            width: 300,
            sortable: false,
            headerClassName: "list-head-Val",
            cellClassName: "list-row-Val",
            align: "left",
            headerAlign: "left",
        },
        {
            field: 'orderInfo',
            headerName: 'Order Info',
            width: 300,
            valueGetter: (value, row) => `${getOrderInfo(row)}`,
            sortable: false,
            headerClassName: "list-head-Val",
            cellClassName: "list-row-Val",
            align: "left",
            headerAlign: "left",
        },
        {
            field: 'orderStatus',
            headerName: 'Order Status',
            width: 280,
            sortable: false,
            headerClassName: "list-head-Val",
            cellClassName: "list-row-Val",
            align: "left",
            headerAlign: "left",
        },
    ];

    const getOrderInfo = (order) => {
        let orderInfo = "";
        if (order && order.orderingInfo) {
            let orderInfoJson = order.orderingInfo;
            if (orderInfoJson && orderInfoJson.noOfSubscribers) {
                if (order.products && order.products.productJson && order.products.productJson.numDays) {
                    orderInfo = "Subscribers: " + orderInfoJson.noOfSubscribers + "  Days: " + order.products.productJson.numDays;
                } else {
                    orderInfo = "Subscribers: " + orderInfoJson.noOfSubscribers;
                }
            } else if (orderInfoJson && orderInfoJson.noOfDays) {
                orderInfo = "Days: " + orderInfoJson.noOfDays;
            } else if (orderInfoJson && orderInfoJson.noOfUnits) {
                orderInfo = "Quantity: " + orderInfoJson.noOfUnits;
            }
        }
        return orderInfo;
    }

    const getProductSubTypeName = (productType, productSubType) => {
        if (productType === "MobileDevice") {
            if (deviceTypeList && deviceTypeList.length > 0) {
                let devices = deviceTypeList.filter(device => device.id == productSubType);
                if (devices && devices.length > 0) {
                    return devices[0].brand + " " + devices[0].manufacturer
                }
            }

        }
        return productSubType;
    }

    return (
        <div style={{ height: 900 }}>
            <DataGrid
                rows={orders.data ? orders.data : []}
                columns={columns}
                rowHeight={68}
                rowCount={orders.totalItems}
                pagination
                pageSizeOptions={[10, 20, 25]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                disableColumnMenu
                paginationMode="server"
                localeText={{ noRowsLabel: "" }}
                getRowSpacing={params => ({
                    top: params.isFirstVisible ? 0 : 0,
                    bottom: params.isLastVisible ? 0 : 0
                })}
                sx={{
                    boxShadow: 2,
                    // border: 2,
                    // borderColor: 'primary.light',
                    border: "none",
                    borderBottom: "none",
                    '& .MuiDataGrid-cell:hover': {
                        color: '#ffb01f',
                    },
                    '& .MuiDataGrid': {
                        border: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        borderColor: "#404040",
                    },
                    '& .MuiDataGrid-columnHeader': {
                        borderColor: "gray",
                    },
                    '& .MuiDataGrid-filler': {
                        backgroundColor: "#262626",
                    },
                    "& .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel": {
                        color: "white"
                    },
                    "& svg:not(:root)": {
                        color: 'white'
                    },
                    "& .css-levciy-MuiTablePagination-displayedRows": {
                        color: 'white'
                    }
                }}

            />
        </div>
    )
}

const mapStateToProps = ({ mdm }) => {
    const { deviceTypeList } = mdm;
    return {
        deviceTypeList,
    };
};
export default connect(mapStateToProps, null)(MyPurchases);