import React, { useState } from "react";
import { Modal } from 'react-bootstrap'
import { connect } from "react-redux";
import AddDevice from "./Modal/AddDevice"
import { deleteDevice, pullLogs } from "../../modules/actions/mdmAction";
import AlertDialog from "../../Admin/components/DeleteUserDialog";
const DeviceListRow = (props) => {
  const { setModalOpen, data, tenantId, deleteDevice, pullLogs } = props;
  const [userOpen, setUserOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const editButtonClick = () => {
    setUserOpen(true)
  }
  const pullButtonClick = () => {
    pullLogs({ id: data.deviceId })
  }
  const userhandleClose = () => {
    setUserOpen(false)
  }
  const viewQrCode = () => {
    setModalOpen(true)
  }
  const deleteButtonClick = () => {
    setDialogOpen(true)
  }
  const deleteDailogHandler = (val) => {
    setDialogOpen(false)
    if (val) {
      deleteDevice({ id: data.id, tenantId })
    }

  }
  return (
    /* This div returns the jsx to create single user list row elements*/
    <div style={{ width: "100%", margin: "5px" }}>
      <div class="device-row-grid">
        <div class="tr-tb-icon">
          <i className={"feather icon-smartphone f-32"} style={{ lineHeight: "50%" }} />
        </div>
        <div class="tr-tb-deviceid">
          <span>{data.deviceId} </span>
        </div>
        <div class="tr-tb-username">
          <span>{data.userId}</span>
        </div>
        <div class="tr-tb-status">
          <span>{data.cmsURI}</span>
        </div>
        <div class="tr-tb-config">
          <span>{data.name}</span>
        </div>
        <div className="tr-tb-qr">
          <button
            class="qrcode"
            onClick={viewQrCode}
            type="button"
            name=""
          >
            <img
              src="/assets/images/qr-code.png"
              class="qrcode-img"
              alt=""
            />
          </button>
        </div>
        <div class="tr-tb-logs">
          <button
            class="editBtn"
            onClick={pullButtonClick}
            type="button"
            name=""
          >
            <i className={"feather icon-download-cloud f-28 sq-icon-btn in-blc wx32"} style={{ lineHeight: "50%" }} />
          </button>
        </div>
        <div class="tr-tb-config">
          <span>{props.data.manufacturer} {props.data.brand}</span>
        </div>
        <div class="tr-tb-edit">
          <button
            class="editBtn"
            onClick={editButtonClick}
            type="button"
            name=""
          >
            <img
              src="/assets/images/editimg.svg"
              class="edit-user-img"
              alt=""
            />
          </button>
        </div>
        <div class="tr-tb-delete">
          <button
            class="editBtn"
            onClick={deleteButtonClick}
            type="button"
            name=""
          >
            <img
              src="/assets/images/deleteimg.svg"
              class="delete-user-img"
              alt=""
            />
          </button>
        </div>
      </div>
      <Modal
        show={userOpen}
        onHide={userhandleClose}
        size={"lg"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ backgroundColor: ' rgba(0,0,0,0.5)' }}
      >
        <Modal.Header closeButton style={{ border: '0px', backgroundColor: '#282828' }}>
          <Modal.Title>UPDATE DEVICE</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '0.2px' }}>
          {
            userOpen ?
              <AddDevice purpose={"edit"} data={data} setModalOpen={setUserOpen} />
              : null
          }
        </Modal.Body>
      </Modal>
      <AlertDialog open={dialogOpen} deleteDailogHandler={deleteDailogHandler} userName={props.data.deviceId} />
    </div>
  );
}
const mapStateToProps = ({ mdm }) => {
  return {
    tenantId: mdm.tenantId
  }
}
export default connect(mapStateToProps, { deleteDevice, pullLogs })(DeviceListRow);
