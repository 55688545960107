const stations = [
   ///////////////////////////Orange Line//////////////////////////
   {
       name : 'Sitabuldi',
       abbr : 'SIB',
       upLTE : 'T08J1',
       downLTE : 'T07J1',
   },
   {
       name : 'Congress Nagar',
       abbr : 'CON',
       upLTE : 'T04C1',
       downLTE : 'T03C1',
   },
   {
       name : 'Rahate Colony',
       abbr : 'RAC',
       upLTE : 'T08C1',
       downLTE : 'T07C1',
   },
   {
       name : 'Ajni Square',
       abbr : 'AJS',
       upLTE : 'T16C1',
       downLTE : 'T19C1',
   },
   {
       name : 'Chatrapati Station',
       abbr : 'CHS',
       upLTE : 'T20C1',
       downLTE : 'T23C1',
   },
   {
       name : 'Jay Prakash Nagar',
       abbr : 'JPN',
       upLTE : 'T24C1',
       downLTE : 'T27C1',
   },
   {
       name : 'Ujwal Nagar',
       abbr : 'UJN',
       upLTE : 'T02D1',
       downLTE : 'T01D1',
   },
   {
       name : 'Airport',
       abbr : 'APT',
       upLTE : 'T06D1',
       downLTE : 'T05D1',
   },
   {
       name : 'Airport Station St.',
       abbr : 'ASS',
       upLTE : 'T12D1',
       downLTE : 'T11D1',
   },
   {
       name : 'New Airport Station',
       abbr : 'NAP',
       upLTE : 'T02E1',
       downLTE : 'T01E1',
   },
   {
       name : 'Khapri Station',
       abbr : 'KPR',
       upLTE : 'T08E1',
       downLTE : 'T07E1',
   },
   //////////////////////////////////Aqua Line///////////////////////////
   {
       name : 'Sitabuldi',
       abbr : 'STB',
       upLTE : 'T06S1',
       downLTE : 'T05S1',
   },
   {
       name : 'Jhansi Rani Square',
       abbr : 'JRS',
       upLTE : 'T04T1',
       downLTE : 'T03T1',
   },
   {
       name : 'Institute Of Engineering',
       abbr : 'IOE',
       upLTE : 'T10T1',
       downLTE : 'T09T1',
   },
   {
       name : 'Shankar Nagar Square',
       abbr : 'SNS',
       upLTE : 'T16T1',
       downLTE : 'T15T1',
   },
   {
       name : 'Lad Chowk',
       abbr : 'LAD',
       upLTE : 'T20T1',
       downLTE : 'T19T1',
   },
   {
       name : 'Ambazari Lake View',
       abbr : 'ALV',
       upLTE : 'T02U1',
       downLTE : 'T01U1',
   },
   {
       name : 'Shubhash Nagar',
       abbr : 'SBS',
       upLTE : 'T06U1',
       downLTE : 'T05U1',
   },
   {
       name : 'Rachna Ring Road',
       abbr : 'RRP',
       upLTE : 'T14U1',
       downLTE : 'T17U1',
   },
   {
       name : 'Vasudev Nagar',
       abbr : 'VAN',
       upLTE : 'T02V1',
       downLTE : 'T01V1',
   },
   {
       name : 'Bansi Nagar',
       abbr : 'BAN',
       upLTE : 'T02W1',
       downLTE : 'T01W1',
   },
   {
       name : 'Lokmanya Nagar',
       abbr : 'LON',
       upLTE : 'T08W1',
       downLTE : 'T07W1',
   },
];

const orangeLineLTE = [
   ////////////////////////////UP/////////////////////////////////////////////////
   {name : 'T02J1', station: null, VPF : 'VPF116', diverging : null, siding : null},
   {name : 'T04J1', station: null, VPF : null, diverging : 'P02J', siding : null},
   {name : 'T06J1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T06J2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T08J1', station: 'SIB', VPF : null, diverging : null, siding : null},
   {name : 'T10J1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T10J2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T10J3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T10J4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T10J5', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T10J6', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T10J7', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T10J8', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T04C1', station: 'CON', VPF : null, diverging : null, siding : null},
   {name : 'T06C1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T06C2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T06C3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T06C4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T06C5', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T08C1', station: 'RAC', VPF : null, diverging : null, siding : null},
   {name : 'T10C1', station: null, VPF : 'VPF102', diverging : null, siding : null},
   {name : 'T12C1', station: null, VPF : null, diverging : 'P02C', siding : null},
   {name : 'T14C1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T14C2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T14C3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T14C4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T16C1', station: 'AJS', VPF : null, diverging : null, siding : null},
   {name : 'T18C1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T18C2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T18C3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T18C4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T18C5', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T20C1', station: 'CHS', VPF : null, diverging : null, siding : null},
   {name : 'T22C1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T22C2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T22C3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T24C1', station: 'JPN', VPF : null, diverging : null, siding : null},
   {name : 'T26C1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T26C2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T26C3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T26C4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T02D1', station: 'UJN', VPF : null, diverging : null, siding : null},
   {name : 'T04D1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T04D2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T04D3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T04D4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T04D5', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T06D1', station: 'APT', VPF : null, diverging : null, siding : null},
   {name : 'T08D1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T08D2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T08D3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T08D4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T12D1', station: 'ASS', VPF : null, diverging : null, siding : null},
   {name : 'T14D1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T14D2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T14D3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T14D4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T14D5', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T14D6', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T14D7', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T02E1', station: 'NAP', VPF : null, diverging : null, siding : null},
   {name : 'T04E1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T04E2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T04E3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T04E4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T04E5', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T04E6', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T04E7', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T04E8', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T04E9', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T06E1', station: null, VPF : 'VPF106', diverging : 'P02E', siding : null},
   {name : 'T08E1', station: 'KPR', VPF : null, diverging : null, siding : null},
   {name : 'T10E1', station: null, VPF : null, diverging : 'P04J', siding : null},
   //{name : 'T12E1', station: null, VPF : 'VPF109', diverging : 'P06J', siding : null},
   {name : 'T14E1', station: null, VPF : 'VPF110', diverging : null, siding : null},
   {name : 'T16E1', station: null, VPF : 'VPF110', diverging : null, siding : null},
   {name : 'T16E2', station: null, VPF : 'VPF110', diverging : null, siding : null},
   {name : 'T16E3', station: null, VPF : 'VPF110', diverging : null, siding : null},
   {name : 'T18E1', station: null, VPF : null, diverging : 'P08E', siding : null},
   {name : 'T20E1', station: null, VPF : 'VPF112', diverging : null, siding : null},
   {name : 'T20E2', station: null, VPF : 'VPF112', diverging : null, siding : null},
   {name : 'T20E3', station: null, VPF : 'VPF112', diverging : null, siding : null},
   /////////////////////////////////MID///////////////////////////////////////////
   {name : 'T15C1', station: null, VPF : '113', diverging : null, siding : null},
   /////////////////////////////////DOWN//////////////////////////////////////////
   {name : 'T19E2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T19E1', station: null, VPF : 'VPF109', diverging : null, siding : null},
   {name : 'T17E1', station: null, VPF : null, diverging : 'P11E, P09E', siding : null},
   {name : 'T13E2', station: null, VPF : 'VPF107', diverging : null, siding : null},
   {name : 'T13E1', station: null, VPF : null, diverging : 'P02J', siding : null},
   {name : 'T11E1', station: null, VPF : 'VPF106', diverging : null, siding : null},
   {name : 'T07E1', station: 'KPR', VPF : null, diverging : null, siding : null},
   {name : 'T05E1', station: null, VPF : null, diverging : 'P01E', siding : null},
   {name : 'T03E9', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T03E8', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T03E7', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T03E6', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T03E5', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T03E4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T03E3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T03E2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T03E1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T01E1', station: 'NAP', VPF : null, diverging : null, siding : null},
   {name : 'T13D7', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T13D6', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T13D5', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T13D4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T13D3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T13D2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T13D1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T11D1', station: 'ASS', VPF : null, diverging : null, siding : null},
   {name : 'T07D4', station: null, VPF : 'VPF103', diverging : null, siding : null},
   {name : 'T07D3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T07D2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T07D1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T05D1', station: 'APT', VPF : null, diverging : null, siding : null},
   {name : 'T03D5', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T03D4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T03D3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T03D2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T03D1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T01D1', station: 'UJN', VPF : null, diverging : null, siding : null},
   {name : 'T29C4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T29C3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T29C2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T29C1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T27C1', station: 'JPN', VPF : null, diverging : null, siding : null},
   {name : 'T25C3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T25C2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T25C1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T23C1', station: 'CHS', VPF : null, diverging : 'P02J', siding : null},
   {name : 'T21C5', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T21C4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T21C3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T21C2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T21C1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T19C1', station: 'AJS', VPF : null, diverging : null, siding : null},
   {name : 'T17C4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T17C3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T17C2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T17C1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T11C1', station: null, VPF : null, diverging : 'P01C', siding : null},
   {name : 'T09C1', station: null, VPF : 'VPF101', diverging : null, siding : null},
   {name : 'T07C1', station: 'RAC', VPF : null, diverging : null, siding : null},
   {name : 'T05C5', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T05C4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T05C3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T05C2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T05C1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T03C1', station: 'CON', VPF : null, diverging : null, siding : null},
   {name : 'T09J8', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T09J7', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T09J6', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T09J5', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T09J4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T09J3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T09J2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T09J1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T07J1', station: 'SIB', VPF : null, diverging : null, siding : null},
   {name : 'T05J2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T05J1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T01J1', station: null, VPF : 'VPF115', diverging : null, siding : null},
];

const aquaLineLTE = [
   ///////////////////////////////UP//////////////////////////////////////////////
   {name : 'T02S1', station: null, VPF : 'VPF312', diverging : null, siding : null},
   {name : 'T04S1', station: null, VPF : null, diverging : 'P02S', siding : null},
   {name : 'T06S1', station: 'SIB', VPF : null, diverging : null, siding : null},
   {name : 'T08S1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T08S2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T08S3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T04T1', station: 'JRS', VPF : null, diverging : null, siding : null},
   {name : 'T06T1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T06T2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T06T3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T08T1', station: null, VPF : null, diverging : 'P02T', siding : null},
   {name : 'T08T2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T10T1', station: 'IOE', VPF : null, diverging : null, siding : null},
   {name : 'T12T1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T12T2', station: null, VPF : null, diverging : 'P04J', siding : null},
   {name : 'T14T1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T14T2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T14T3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T16T1', station: 'SNS', VPF : null, diverging : null, siding : null},
   {name : 'T18T1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T18T2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T18T3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T18T4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T20T1', station: 'LAD', VPF : null, diverging : null, siding : null},
   {name : 'T22T1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T22T2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T22T3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T22T4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T22T5', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T02U1', station: 'ALV', VPF : null, diverging : null, siding : null},
   {name : 'T04U1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T04U2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T04U3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T04U4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T06U1', station: 'SBS', VPF : null, diverging : null, siding : null},
   {name : 'T08U1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T08U2', station: null, VPF : 'VPF302', diverging : null, siding : null},
   {name : 'T10U1', station: null, VPF : null, diverging : 'P02U', siding : null},
   {name : 'T12U1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T12U2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T12U3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T12U4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T14U1', station: 'RRP', VPF : null, diverging : null, siding : null},
   {name : 'T16U1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T16U2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T16U3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T16U4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T16U5', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T02V1', station: 'VAM', VPF : null, diverging : null, siding : null},
   {name : 'T04V1', station: null, VPF : null, diverging : 'P02V', siding : null},
   {name : 'T06V1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T06V2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T06V3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T02W1', station: 'BAN', VPF : null, diverging : null, siding : null},
   {name : 'T04W1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T04W2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T04W3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T04W4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T04W5', station: null, VPF : 'VPF304', diverging : null, siding : null},
   {name : 'T06W1', station: null, VPF : null, diverging : 'P02W', siding : null},
   {name : 'T08W1', station: 'LON', VPF : null, diverging : null, siding : null},
   {name : 'T10W1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T10W2', station: null, VPF : 'VPF306', diverging : null, siding : null},
   {name : 'T12W1', station: null, VPF : null, diverging : 'P04W', siding : null},
   {name : 'T14W1', station: null, VPF : 'VPF308', diverging : null, siding : null},
   {name : 'T14W2', station: null, VPF : null, diverging : null, siding : null},
   //////////////////////////////////MID////////////////////////////////////////
   {name : 'T13U1', station: null, VPF : null, diverging : null, siding : null},
   ////////////////////////////DOWN/////////////////////////////////////////////
   {name : 'T15W2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T15W1', station: null, VPF : 'VPF313', diverging : null, siding : null},
   {name : 'T13W0', station: null, VPF : null, diverging : 'P07W', siding : null},
   {name : 'T11W1', station: null, VPF : null, diverging : 'PO5W, P03W', siding : null},
   {name : 'T09W2', station: null, VPF : 'VPF311', diverging : null, siding : null},
   {name : 'T09W1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T07W1', station: 'LON', VPF : null, diverging : null, siding : null},
   {name : 'T05W2', station: null, VPF : null, diverging : 'P01W', siding : null},
   {name : 'T05W1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T03W5', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T03W4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T03W3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T03W2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T03W1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T01W1', station: 'BAN', VPF : null, diverging : null, siding : null},
   {name : 'T07V3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T07V2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T07V1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T05V1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T01V1', station: 'VAN', VPF : null, diverging : null, siding : null},
   {name : 'T19U5', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T19U4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T19U3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T19U2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T19U1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T17U1', station: 'RRP', VPF : null, diverging : null, siding : null},
   {name : 'T15U3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T15U2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T15U1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T09U1', station: null, VPF : null, diverging : 'P01U', siding : null},
   {name : 'T07U3', station: null, VPF : 'VPF305', diverging : null, siding : null},
   {name : 'T07U2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T07U1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T05U1', station: 'SBS', VPF : null, diverging : null, siding : null},
   {name : 'T03U4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T03U3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T03U2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T03U1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T01U1', station: 'ALV', VPF : null, diverging : null, siding : null},
   {name : 'T21T5', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T21T4', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T21T3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T21T2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T21T1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T19T1', station: 'LAD', VPF : null, diverging : null, siding : null},
   {name : 'T17T3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T17T2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T17T1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T15T1', station: 'SNS', VPF : null, diverging : null, siding : null},
   {name : 'T13T3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T13T2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T13T1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T11T1', station: null, VPF : null, diverging : 'P03T', siding : null},
   {name : 'T09T1', station: 'IOE', VPF : null, diverging : null, siding : null},
   {name : 'T07T1', station: null, VPF : null, diverging : 'P01T', siding : null},
   {name : 'T05T3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T05T2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T05T1', station: null, VPF : 'VPF301', diverging : null, siding : null},
   {name : 'T03T1', station: 'JRS', VPF : null, diverging : null, siding : null},
   {name : 'T07S3', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T07S2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T07S1', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T05S1', station: 'STB', VPF : null, diverging : null, siding : null},
   {name : 'T03S2', station: null, VPF : null, diverging : null, siding : null},
   {name : 'T03S1', station: null, VPF : 'VPF317', diverging : null, siding : null},
   {name : 'T01S1', station: null, VPF : null, diverging : null, siding : null},
];

const trains = [
   {rakeId:'001',PTID_A:'32768',PTID_B:'32769',RadioID_A:'84011',RadioID_B:'84012'},
   {rakeId:'002',PTID_A:'32770',PTID_B:'32771',RadioID_A:'84021',RadioID_B:'84022'},
   {rakeId:'003',PTID_A:'32772',PTID_B:'32773',RadioID_A:'84031',RadioID_B:'84032'},
   {rakeId:'004',PTID_A:'32774',PTID_B:'32775',RadioID_A:'84041',RadioID_B:'84042'},
   {rakeId:'005',PTID_A:'32776',PTID_B:'32777',RadioID_A:'84051',RadioID_B:'84052'},
   {rakeId:'006',PTID_A:'32778',PTID_B:'32779',RadioID_A:'84061',RadioID_B:'84062'},
   {rakeId:'007',PTID_A:'32780',PTID_B:'32781',RadioID_A:'84071',RadioID_B:'84072'},
   {rakeId:'008',PTID_A:'32782',PTID_B:'32783',RadioID_A:'84081',RadioID_B:'84082'},
   {rakeId:'009',PTID_A:'32784',PTID_B:'32785',RadioID_A:'84091',RadioID_B:'84092'},
   {rakeId:'010',PTID_A:'32786',PTID_B:'32787',RadioID_A:'84101',RadioID_B:'84102'},
   {rakeId:'011',PTID_A:'32788',PTID_B:'32789',RadioID_A:'84111',RadioID_B:'84112'},
   {rakeId:'012',PTID_A:'32790',PTID_B:'32791',RadioID_A:'84121',RadioID_B:'84122'},
   {rakeId:'013',PTID_A:'32792',PTID_B:'32793',RadioID_A:'84131',RadioID_B:'84132'},
   {rakeId:'014',PTID_A:'32794',PTID_B:'32795',RadioID_A:'84141',RadioID_B:'84142'},
   {rakeId:'015',PTID_A:'32796',PTID_B:'32797',RadioID_A:'84151',RadioID_B:'84152'},
   {rakeId:'016',PTID_A:'32798',PTID_B:'32799',RadioID_A:'84161',RadioID_B:'84162'},
   {rakeId:'017',PTID_A:'32800',PTID_B:'32801',RadioID_A:'84171',RadioID_B:'84172'},
   {rakeId:'018',PTID_A:'32802',PTID_B:'32803',RadioID_A:'84181',RadioID_B:'84182'},
   {rakeId:'019',PTID_A:'32804',PTID_B:'32805',RadioID_A:'84191',RadioID_B:'84192'},
   {rakeId:'020',PTID_A:'32806',PTID_B:'32807',RadioID_A:'84201',RadioID_B:'84202'},
   {rakeId:'021',PTID_A:'32808',PTID_B:'32809',RadioID_A:'84211',RadioID_B:'84212'},
   {rakeId:'022',PTID_A:'32810',PTID_B:'32811',RadioID_A:'84221',RadioID_B:'84222'},
   {rakeId:'023',PTID_A:'32812',PTID_B:'32813',RadioID_A:'84231',RadioID_B:'84232'},
];

const diverging =[
   {name: 'T03J', type: 'Changing', prevLTE:'T05J1', nextLTE:'T02J1', mergingTC:'T04J'},
   {name: 'T04J', type: 'Changing', prevLTE:'T02J1', nextLTE:'T05J1', mergingTC:'T03J'},
   {name: 'T10D', type: 'Changing', prevLTE:'T08D4', nextLTE:'T11D1', mergingTC:'T09D'},
   {name: 'T09D', type: 'Changing', prevLTE:'T07D4', nextLTE:'T12D1', mergingTC:'T10D'},
   {name: 'T06E', type: 'Changing', prevLTE:'T04E9', nextLTE:'T07E1', mergingTC:'T05E'},
   {name: 'T05E', type: 'Changing', prevLTE:'T07E1', nextLTE:'T04E9', mergingTC:'T06E'},
   {name: 'T12E', type: 'Changing', prevLTE:'T08E1', nextLTE:'T11E1', mergingTC:'T09E'},
   {name: 'T09E', type: 'Changing', prevLTE:'T07E1', nextLTE:'T14E1', mergingTC:'T12E'},
   {name: 'T18E', type: 'Siding', prevLTE:'T16E3', nextLTE:'T23E1', mergingTC:'T17E'},
   {name: 'T17E', type: 'Siding', prevLTE:'T17E1', nextLTE:'T16E3', mergingTC:'T18E'},
   {name: 'T15E', type: 'Siding', prevLTE:'T13E2', nextLTE:'T21E1', mergingTC:''},
   {name: 'T12C', type: 'Changing', prevLTE:'T10C1', nextLTE:'T15C1', mergingTC:'T13C'},
   {name: 'T11C', type: 'Changing', prevLTE:'T09C1', nextLTE:'T15C1', mergingTC:'T13C'},
   {name: 'T13C', type: 'Changing', prevLTE:'T15C1', nextLTE:'T10C1', mergingTC:'T12C'},
   {name: 'T15C', type: 'Changing', prevLTE:'T15C1', nextLTE:'T09C1', mergingTC:'T11C'},
   {name: 'T04S', type: 'Changing', prevLTE:'T02S1', nextLTE:'T03S2', mergingTC:'T03S'},
   {name: 'T03S', type: 'Changing', prevLTE:'T03S2', nextLTE:'T02S1', mergingTC:'T04S'},
   {name: 'T07T', type: 'Changing', prevLTE:'T05T3', nextLTE:'T08T2', mergingTC:'T08T'},
   {name: 'T08T', type: 'Changing', prevLTE:'T08T2', nextLTE:'T05T3', mergingTC:'T07T'},
   {name: 'T11T', type: 'Changing', prevLTE:'T13T1', nextLTE:'T12T1', mergingTC:'T12T'},
   {name: 'T12T', type: 'Changing', prevLTE:'T12T1', nextLTE:'T13T1', mergingTC:'T11T'},
   {name: 'T10U', type: 'Changing', prevLTE:'T08U2', nextLTE:'T13U1', mergingTC:'T11U'},
   {name: 'T11U', type: 'Changing', prevLTE:'T13T1', nextLTE:'T08U2', mergingTC:'T10U'},
   {name: 'T09U', type: 'Changing', prevLTE:'T07U3', nextLTE:'T13U1', mergingTC:'T13U'},
   {name: 'T13U', type: 'Changing', prevLTE:'T13U1', nextLTE:'T07U3', mergingTC:'T09U'},
   {name: 'T04V', type: 'Changing', prevLTE:'T02V1', nextLTE:'T05V1', mergingTC:'T05V'},
   {name: 'T05V', type: 'Changing', prevLTE:'T05V1', nextLTE:'T02V1', mergingTC:'T04V'},
   {name: 'T06W', type: 'Changing', prevLTE:'T04W5', nextLTE:'T05W2', mergingTC:'T05W'},
   {name: 'T05W', type: 'Changing', prevLTE:'T05W2', nextLTE:'T04W5', mergingTC:'T06W'},
   {name: 'T12W', type: 'Siding', prevLTE:'T10W2', nextLTE:'T23W1', mergingTC:'T13W'},
   {name: 'T13W', type: 'Siding', prevLTE:'T13W1', nextLTE:'T10W2', mergingTC:'T12W'},
   {name: 'T11W', type: 'Siding', prevLTE:'T19W1', nextLTE:'T09W2', mergingTC:''},
   {name: 'T11U', type: 'Changing', prevLTE:'T13U1', nextLTE:'T08U2', mergingTC:'T10U'},
   {name: 'T13U', type: 'Changing', prevLTE:'T13U1', nextLTE:'T07U3', mergingTC:'T09U'},
];

const stationRadios = [
   //ORANGE
   {stnCode:'KPR', radio_id:'83111', station:'khapri',},//83111
   {stnCode:'NAP', radio_id:'83121', station:'New Airport',},//83121
   {stnCode:'ASS', radio_id:'83131', station:'South Airport ',},
   {stnCode:'APT', radio_id:'83141', station:'Airport ', },
   {stnCode:'UJN', radio_id:'83151', station:'Ujwal Nagar', },
   {stnCode:'JPN', radio_id:'83161', station:'Jaiprakash Nagar', },
   {stnCode:'CHS', radio_id:'83171', station:'Chatrapati Square', },
   {stnCode:'AJS', radio_id:'83181', station:'Ajani Square', },
   {stnCode:'RAC', radio_id:'83191', station:'Rahate Colony', },
   {stnCode:'CON', radio_id:'83201', station:'Congress Nagar', },
   {stnCode:'SIB', radio_id:'11001', station:'Sitaburdi (Interchange)', },//83211
   //
   // {stnCode:'', radio_id:'83221', station:'Zero Mile', },
   // {stnCode:'', radio_id:'83231', station:'Kasturchand Park', },
   // {stnCode:'', radio_id:'83241', station:'Gaddi Godam Sq', },
   // {stnCode:'', radio_id:'83251', station:'Kadvi Chowk ', },
   // {stnCode:'', radio_id:'83261', station:'Indora Chowk', },
   // {stnCode:'', radio_id:'83271', station:'Nari Road ', },
   // {stnCode:'', radio_id:'83281', station:'Automotive Square', },
   {stnCode:'LON', radio_id:'83511', station:'Lokmanya Nagar', },
   //AQUA
   {stnCode:'BAN', radio_id:'83521', station:'Bansi Nagar', },
   {stnCode:'VAN', radio_id:'83531', station:'Vasudev Nagar', },
   {stnCode:'RRR', radio_id:'83541', station:'Rachna Ring Road ', },
   {stnCode:'SBS', radio_id:'83551', station:'Subhash Nagar', },
   {stnCode:'ALV', radio_id:'83571', station:'Dharampeth College', },
   {stnCode:'LAD', radio_id:'83111', station:'LAD Chowk', },
   {stnCode:'SNS', radio_id:'83581', station:'Shankar Nagar Square', },
   {stnCode:'IOE', radio_id:'83591', station:'Institute of Engineers ', },
   {stnCode:'JRS', radio_id:'83601', station:'Jhansi Rani Square', },
   // {stnCode:'', radio_id:'83611', station:'Nagpur Railway Stn', },
   // {stnCode:'', radio_id:'83621', station:'Dosar Vaisya Chowk', },
   // {stnCode:'', radio_id:'83631', station:'Agrasen Chowk', },
   // {stnCode:'', radio_id:'83641', station:'Chittar Oli Chowk', },
   // {stnCode:'', radio_id:'83651', station:'Telephone Exchange', },
   // {stnCode:'', radio_id:'83661', station:'Ambedkar Chowk ', },
   // {stnCode:'', radio_id:'83671', station:'Vaishno Devi Chowk ', },
   // {stnCode:'', radio_id:'83681', station:'Prajapati Nagar', },
 ] 
 
 
 const getDiverging = (trackCircuit)=>{
   return diverging.filter(tc=> tc.name.includes(trackCircuit));
 }
 const getLTEs = (trackCircuit,Line)=>{
   if(trackCircuit !==''){
       if(Line==='Orange'){
           return orangeLineLTE.filter(lte => lte.name.includes(trackCircuit));
       }else 
       if(Line==='Aqua'){
           return aquaLineLTE.filter(lte => lte.name.includes(trackCircuit));
       }
   }else{
       return null
   }
 }
 
 const getStation = (trackCircuit)=>{
   if(trackCircuit!==null){
       return stations.filter(stn => stn.downLTE.includes(trackCircuit) || stn.upLTE.includes(trackCircuit));
   }else{
       return null
   }
   
 }
 
 const trackCircuits = [
   [
       ////////////////////////trackCircuit[0] = Contains Line Information of the project/////////////////////////////////////////
       {text:'Orange', value:'Orange', type:'line', alias:'NS'},
       {text:'Aqua', value:'Aqua', type:'line', alias:'EW'},
       {text:'Hingna Depot', value:'depot1', type:'depot'},
       {text:'Mihan Depot', value:'depot2', type:'depot'},
   ],    
   [
       /////////////////////////////////trackCircuits[1] = Contains Track Circuit Information of the signalong plan////////////////////////////////////////
       ///////////////////////////////////////////////////////////////////Orange UP////////////////////////////////////////////////////////////////////////
       {name: 'T02A', LTEs : getLTEs('T02A','Orange'), station : getStation(null), prevStation : '', nextStation : 'AUS', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T04A', LTEs : getLTEs('T04A','Orange'), station : getStation(null), prevStation : '', nextStation : 'AUS', line:'Orange', direction: 'up', diverging:getDiverging('T04A')},
       {name: 'T06A', LTEs : getLTEs('T06A','Orange'), station : getStation('T06A'), prevStation : '', nextStation : 'NAR', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T08A', LTEs : getLTEs('T08A','Orange'), station : getStation(null), prevStation : 'AUS', nextStation : 'NAR', line:'Orange', direction: 'up', diverging:getDiverging('T08A')},
       {name: 'T10A', LTEs : getLTEs('T10A','Orange'), station : getStation(null), prevStation : 'AUS', nextStation : 'NAR', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T12A', LTEs : getLTEs('T12A','Orange'), station : getStation('T12A'), prevStation : '', nextStation : 'INC', line:'Orange', direction: 'up', diverging:[]},
      {name: 'T14A', LTEs : getLTEs('T14A','Orange'), station : getStation(null), prevStation : 'NAR', nextStation : 'INC', line:'Orange', direction: 'up', diverging:[]},
      {name: 'T16A', LTEs : getLTEs('T16A','Orange'), station : getStation('T16A'), prevStation : '', nextStation : 'KAC', line:'Orange', direction: 'up', diverging:[]},
      {name: 'T18A', LTEs : getLTEs('T18A','Orange'), station : getStation(null), prevStation : 'INC', nextStation : 'KAC', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T20A', LTEs : getLTEs('T20A','Orange'), station : getStation('T20A'), prevStation : '', nextStation : 'GGS', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T22A', LTEs : getLTEs('T22A','Orange'), station : getStation(null), prevStation : 'KAC', nextStation : 'GGS', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T02B', LTEs : getLTEs('T02B','Orange'), station : getStation('T02B'), prevStation : '', nextStation : 'KAP', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T04B', LTEs : getLTEs('T04B','Orange'), station : getStation(null), prevStation : 'GGS', nextStation : 'KAP', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T06B', LTEs : getLTEs('T06B','Orange'), station : getStation(null), prevStation : 'GGS', nextStation : 'KAP', line:'Orange', direction: 'up', diverging:getDiverging('T06B')},
       {name: 'T08B', LTEs : getLTEs('T08B','Orange'), station : getStation('T08B'), prevStation : '', nextStation : 'ZEM', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T10B', LTEs : getLTEs('T10B','Orange'), station : getStation(null), prevStation : 'KAP', nextStation : 'ZEM', line:'Orange', direction: 'up', diverging:getDiverging('T10B')},
       {name: 'T12B', LTEs : getLTEs('T12B','Orange'), station : getStation(null), prevStation : 'KAP', nextStation : 'ZEM', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T14B', LTEs : getLTEs('T14B','Orange'), station : getStation('T14B'), prevStation : '', nextStation : 'SIB', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T16B', LTEs : getLTEs('T16B','Orange'), station : getStation(null), prevStation : 'ZEM', nextStation : 'SIB', line:'Orange', direction: 'up', diverging:[]},

       {name: 'T02J', LTEs : getLTEs('T02J','Orange'), station : getStation(null), prevStation : 'ZEM', nextStation : 'SIB', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T04J', LTEs : getLTEs('T04J','Orange'), station : getStation(null), prevStation : 'ZEM', nextStation : 'SIB', line:'Orange', direction: 'up', diverging:getDiverging('T04J')},
       {name: 'T06J', LTEs : getLTEs('T06J','Orange'), station : getStation(null), prevStation : 'ZEM', nextStation : 'SIB', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T08J', LTEs : getLTEs('T08J','Orange'), station : getStation('T08J'), prevStation : '', nextStation : 'CON', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T10J', LTEs : getLTEs('T10J','Orange'), station : getStation(null), prevStation : 'SIB', nextStation : 'CON', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T04C', LTEs : getLTEs('T04C','Orange'), station : getStation('T04C'), prevStation : '', nextStation : 'RAC', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T06C', LTEs : getLTEs('T06C','Orange'), station : getStation(null), prevStation : 'CON', nextStation : 'RAC', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T08C', LTEs : getLTEs('T08C','Orange'), station : getStation('T08C'), prevStation : '', nextStation : 'AJS', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T10C', LTEs : getLTEs('T10C','Orange'), station : getStation(null), prevStation : 'RAC', nextStation : 'AJS', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T12C', LTEs : getLTEs('T12C','Orange'), station : getStation(null), prevStation : 'RAC', nextStation : 'AJS', line:'Orange', direction: 'up', diverging:getDiverging('T12C')},
       {name: 'T14C', LTEs : getLTEs('T14C','Orange'), station : getStation(null), prevStation : 'RAC', nextStation : 'AJS', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T16C', LTEs : getLTEs('T16C','Orange'), station : getStation('T16C'), prevStation : '', nextStation : 'CHS', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T18C', LTEs : getLTEs('T18C','Orange'), station : getStation(null), prevStation : 'AJS', nextStation : 'CHS', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T20C', LTEs : getLTEs('T20C','Orange'), station : getStation('T20C'), prevStation : '', nextStation : 'JPN', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T22C', LTEs : getLTEs('T22C','Orange'), station : getStation(null), prevStation : 'CHS', nextStation : 'JPN', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T24C', LTEs : getLTEs('T24C','Orange'), station : getStation('T24C'), prevStation : '', nextStation : 'UJN', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T26C', LTEs : getLTEs('T26C','Orange'), station : getStation(null), prevStation : 'JPN', nextStation : 'UJN', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T02D', LTEs : getLTEs('T02D','Orange'), station : getStation('T02D'), prevStation : '', nextStation : 'APT', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T04D', LTEs : getLTEs('T04D','Orange'), station : getStation(null), prevStation : 'UJN', nextStation : 'APT', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T06D', LTEs : getLTEs('T06D','Orange'), station : getStation('T06D'), prevStation : '', nextStation : 'ASS', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T08D', LTEs : getLTEs('T08D','Orange'), station : getStation(null), prevStation : 'APT', nextStation : 'ASS', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T10D', LTEs : getLTEs('T10D','Orange'), station : getStation(null), prevStation : '', nextStation : '', line:'Orange', direction: 'up' , diverging: getDiverging('T10D')},
       {name: 'T12D', LTEs : getLTEs('T12D','Orange'), station : getStation('T12D'), prevStation : '', nextStation : 'NAP', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T14D', LTEs : getLTEs('T14D','Orange'), station : getStation(null), prevStation : 'ASS', nextStation : 'NAP', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T02E', LTEs : getLTEs('T02E','Orange'), station : getStation('T02E'), prevStation : '', nextStation : 'KPR', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T04E', LTEs : getLTEs('T04E','Orange'), station : getStation(null), prevStation : 'NAP', nextStation : 'KPR', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T06E', LTEs : getLTEs('T06E','Orange'), station : getStation(null), prevStation : 'NAP', nextStation : 'KPR', line:'Orange', direction: 'up', diverging: getDiverging('T06E')},
       {name: 'T08E', LTEs : getLTEs('T08E','Orange'), station : getStation('T08E'), prevStation : '', nextStation : '', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T10E', LTEs : getLTEs('T10E','Orange'), station : getStation(null), prevStation : '', nextStation : '', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T12E', LTEs : getLTEs('T12E','Orange'), station : getStation(null), prevStation : '', nextStation : '', line:'Orange', direction: 'up', diverging: getDiverging('T12E')},
       {name: 'T14E', LTEs : getLTEs('T14E','Orange'), station : getStation(null), prevStation : '', nextStation : '', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T16E', LTEs : getLTEs('T16E','Orange'), station : getStation(null), prevStation : '', nextStation : '', line:'Orange', direction: 'up', diverging:[]},
       {name: 'T18E', LTEs : getLTEs('T18E','Orange'), station : getStation(null), prevStation : '', nextStation : '', line:'Orange', direction: 'up', diverging: getDiverging('T18E')},
       {name: 'T20E', LTEs : getLTEs('T20E','Orange'), station : getStation(null), prevStation : '', nextStation : '', line:'Orange', direction: 'up', diverging:[]},
       //////////////////////////////////////////////////////////Orange Mid////////////////////////////////////////////////////////////////////////////
       {name: 'T13C', LTEs : getLTEs('T13C','Orange'), station : getStation(null), prevStation : '', nextStation : '', line:'Orange', direction: 'mid', diverging:getDiverging('T13C')},
       {name: 'T15C', LTEs : getLTEs('T15C','Orange'), station : getStation(null), prevStation : '', nextStation : '', line:'Orange', direction: 'mid', diverging:getDiverging('T15C')},
       /////////////////////////////////////////////////////////////Orange Down////////////////////////////////////////////////////////////////////////////
       {name: 'T19E', LTEs : getLTEs('T19E','Orange'), station : getStation(null), prevStation : '', nextStation : '', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T17E', LTEs : getLTEs('T17E','Orange'), station : getStation(null), prevStation : '', nextStation : '', line:'Orange', direction: 'down', diverging:getDiverging('T17E')},
       {name: 'T15E', LTEs : getLTEs('T15E','Orange'), station : getStation(null), prevStation : '', nextStation : '', line:'Orange', direction: 'down', diverging: getDiverging('T15E')},
       {name: 'T13E', LTEs : getLTEs('T13E','Orange'), station : getStation(null), prevStation : '', nextStation : '', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T11E', LTEs : getLTEs('T11E','Orange'), station : getStation(null), prevStation : '', nextStation : '', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T09E', LTEs : getLTEs('TO9E','Orange'), station : getStation(null), prevStation : '', nextStation : '', line:'Orange', direction: 'down', diverging: getDiverging('T09E')},
       {name: 'T07E', LTEs : getLTEs('T07E','Orange'), station : getStation('T07E'), prevStation : '', nextStation : 'NAP', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T05E', LTEs : getLTEs('T05E','Orange'), station : getStation(null), prevStation : 'KPR', nextStation : 'NAP', line:'Orange', direction: 'down', diverging:getDiverging('T05E')},
       {name: 'T03E', LTEs : getLTEs('T03E','Orange'), station : getStation(null), prevStation : 'KPR', nextStation : 'NAP', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T01E', LTEs : getLTEs('T01E','Orange'), station : getStation('T01E'), prevStation : '', nextStation : 'ASS', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T13D', LTEs : getLTEs('T13D','Orange'), station : getStation(null), prevStation : 'NAP', nextStation : 'ASS', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T11D', LTEs : getLTEs('T11D','Orange'), station : getStation('T11D'), prevStation : '', nextStation : 'APT', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T09D', LTEs : getLTEs('T09D','Orange'), station : getStation(null), prevStation : '', nextStation : '', line:'Orange', direction: 'down', diverging: getDiverging('T09D')},
       {name: 'T07D', LTEs : getLTEs('T07D','Orange'), station : getStation(null), prevStation : 'ASS', nextStation : 'APT', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T05D', LTEs : getLTEs('T05D','Orange'), station : getStation('T05D'), prevStation : '', nextStation : 'UJN', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T03D', LTEs : getLTEs('T03D','Orange'), station : getStation(null), prevStation : 'APT', nextStation : 'UJN', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T01D', LTEs : getLTEs('T01D','Orange'), station : getStation('T01D'), prevStation : '', nextStation : 'JPN', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T29C', LTEs : getLTEs('T29C','Orange'), station : getStation(null), prevStation : 'UJN', nextStation : 'JPN', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T27C', LTEs : getLTEs('T27C','Orange'), station : getStation('T27C'), prevStation : '', nextStation : 'CHS', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T25C', LTEs : getLTEs('T25C','Orange'), station : getStation(null), prevStation : 'JPN', nextStation : 'CHS', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T23C', LTEs : getLTEs('T23C','Orange'), station : getStation('T23C'), prevStation : '', nextStation : 'AJS', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T21C', LTEs : getLTEs('T21C','Orange'), station : getStation(null), prevStation : 'CHS', nextStation : 'AJS', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T19C', LTEs : getLTEs('T19C','Orange'), station : getStation('T19C'), prevStation : '', nextStation : 'RAC', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T17C', LTEs : getLTEs('T17C','Orange'), station : getStation(null), prevStation : 'AJS', nextStation : 'RAC', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T11C', LTEs : getLTEs('T11C','Orange'), station : getStation(null), prevStation : 'AJS', nextStation : 'RAC', line:'Orange', direction: 'down', diverging:getDiverging('T11C')},
       {name: 'T09C', LTEs : getLTEs('T09C','Orange'), station : getStation(null), prevStation : 'AJS', nextStation : 'RAC', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T07C', LTEs : getLTEs('T07C','Orange'), station : getStation('T07C'), prevStation : '', nextStation : 'CON', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T05C', LTEs : getLTEs('T05C','Orange'), station : getStation(null), prevStation : 'RAC', nextStation : 'CON', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T03C', LTEs : getLTEs('T03C','Orange'), station : getStation('T03C'), prevStation : '', nextStation : 'SIB', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T09J', LTEs : getLTEs('T09J','Orange'), station : getStation(null), prevStation : 'CON', nextStation : 'SIB', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T07J', LTEs : getLTEs('T07J','Orange'), station : getStation('T07J'), prevStation : '', nextStation : 'ZEM', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T05J', LTEs : getLTEs('T05J','Orange'), station : getStation(null), prevStation : 'SIB', nextStation : 'ZEM', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T03J', LTEs : getLTEs('T03J','Orange'), station : getStation(null), prevStation : 'SIB', nextStation : 'ZEM', line:'Orange', direction: 'down', diverging: getDiverging('T03J')},
       {name: 'T01J', LTEs : getLTEs('T01J','Orange'), station : getStation(null), prevStation : 'SIB', nextStation : 'ZEM', line:'Orange', direction: 'down', diverging:[]},

       {name: 'T15B', LTEs : getLTEs('T15B','Orange'), station : getStation(null), prevStation : 'SIB', nextStation : 'ZEM', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T13B', LTEs : getLTEs('T13B','Orange'), station : getStation('T13B'), prevStation : '', nextStation : 'KAP', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T11B', LTEs : getLTEs('T11B','Orange'), station : getStation(null), prevStation : 'ZEM', nextStation : 'KAP', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T09B', LTEs : getLTEs('T09B','Orange'), station : getStation(null), prevStation : 'ZEM', nextStation : 'KAP', line:'Orange', direction: 'down', diverging: getDiverging('T09B')},
       {name: 'T07B', LTEs : getLTEs('T07B','Orange'), station : getStation('T07B'), prevStation : '', nextStation : 'GGS', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T05B', LTEs : getLTEs('T05B','Orange'), station : getStation(null), prevStation : 'KAP', nextStation : 'GGS', line:'Orange', direction: 'down', diverging: getDiverging('T05B')},
       {name: 'T03B', LTEs : getLTEs('T03B','Orange'), station : getStation(null), prevStation : 'KAP', nextStation : 'GGS', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T01B', LTEs : getLTEs('T01B','Orange'), station : getStation('T01B'), prevStation : '', nextStation : 'KAC', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T21A', LTEs : getLTEs('T21A','Orange'), station : getStation(null), prevStation : 'GGS', nextStation : 'KAC', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T19A', LTEs : getLTEs('T19A','Orange'), station : getStation('T19A'), prevStation : '', nextStation : '', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T17A', LTEs : getLTEs('T17A','Orange'), station : getStation(null), prevStation : 'KAC', nextStation : '', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T15A', LTEs : getLTEs('T15A','Orange'), station : getStation('T15A'), prevStation : '', nextStation : 'NAR', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T13A', LTEs : getLTEs('T13A','Orange'), station : getStation(null), prevStation : 'INC', nextStation : 'NAR', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T11A', LTEs : getLTEs('T11A','Orange'), station : getStation('T11A'), prevStation : '', nextStation : 'AUS', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T09A', LTEs : getLTEs('T09A','Orange'), station : getStation(null), prevStation : 'NAR', nextStation : 'AUS', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T07A', LTEs : getLTEs('T07A','Orange'), station : getStation(null), prevStation : 'NAR', nextStation : 'AUS', line:'Orange', direction: 'down', diverging: getDiverging('T07A')},
       {name: 'T05A', LTEs : getLTEs('T05A','Orange'), station : getStation('T05A'), prevStation : '', nextStation : '', line:'Orange', direction: 'down', diverging:[]},
       {name: 'T03A', LTEs : getLTEs('T03A','Orange'), station : getStation(null), prevStation : 'AUS', nextStation : '', line:'Orange', direction: 'down', diverging:getDiverging('T03A')},
       {name: 'T01A', LTEs : getLTEs('T01A','Orange'), station : getStation(null), prevStation : 'AUS', nextStation : '', line:'Orange', direction: 'down', diverging:[]},

       ////////////////////////////////////////////////////////////Aqua UP//////////////////////////////////////////////////////////////////////////////
       {name: 'T02S', LTEs : getLTEs('T02S','Aqua'), station : getStation(null), prevStation : '', nextStation : 'STB', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T04S', LTEs : getLTEs('T04S','Aqua'), station : getStation(null), prevStation : '', nextStation : 'STB', line:'Aqua', direction: 'up', diverging: getDiverging('T04S')},
       {name: 'T06S', LTEs : getLTEs('T06S','Aqua'), station : getStation('T06S'), prevStation : '', nextStation : 'JRS', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T08S', LTEs : getLTEs('T08S','Aqua'), station : getStation(null), prevStation : 'STB', nextStation : 'JRS', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T04T', LTEs : getLTEs('T04T','Aqua'), station : getStation('T04T'), prevStation : '', nextStation : 'IOE', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T06T', LTEs : getLTEs('T06T','Aqua'), station : getStation(null), prevStation : 'JRS', nextStation : 'IOE', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T08T', LTEs : getLTEs('T08T','Aqua'), station : getStation(null), prevStation : 'JRS', nextStation : 'IOE', line:'Aqua', direction: 'up', diverging:getDiverging('T08T')},
       {name: 'T10T', LTEs : getLTEs('T10T','Aqua'), station : getStation('T10T'), prevStation : '', nextStation : 'SNS', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T12T', LTEs : getLTEs('T12T','Aqua'), station : getStation(null), prevStation : 'IOE', nextStation : 'SNS', line:'Aqua', direction: 'up', diverging: getDiverging('T12T')},
       {name: 'T14T', LTEs : getLTEs('T14T','Aqua'), station : getStation(null), prevStation : 'IOE', nextStation : 'SNS', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T16T', LTEs : getLTEs('T16T','Aqua'), station : getStation('T16T'), prevStation : '', nextStation : 'LAD', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T18T', LTEs : getLTEs('T18T','Aqua'), station : getStation(null), prevStation : 'SNS', nextStation : 'LAD', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T20T', LTEs : getLTEs('T20T','Aqua'), station : getStation('T20T'), prevStation : '', nextStation : 'ALV', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T22T', LTEs : getLTEs('T22T','Aqua'), station : getStation(null), prevStation : 'LAD', nextStation : 'ALV', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T02U', LTEs : getLTEs('T02U','Aqua'), station : getStation('T02U'), prevStation : '', nextStation : 'SBS', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T04U', LTEs : getLTEs('T04U','Aqua'), station : getStation(null), prevStation : 'ALV', nextStation : 'SBS', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T06U', LTEs : getLTEs('T06U','Aqua'), station : getStation('T06U'), prevStation : '', nextStation : 'RRR', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T08U', LTEs : getLTEs('T08U','Aqua'), station : getStation(null), prevStation : 'SBS', nextStation : 'RRR', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T10U', LTEs : getLTEs('T10U','Aqua'), station : getStation(null), prevStation : 'SBS', nextStation : 'RRR', line:'Aqua', direction: 'up', diverging: getDiverging('T10U')},
       {name: 'T12U', LTEs : getLTEs('T12U','Aqua'), station : getStation(null), prevStation : 'SBS', nextStation : 'RRR', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T14U', LTEs : getLTEs('T14U','Aqua'), station : getStation('T14U'), prevStation : '', nextStation : 'VAN', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T16U', LTEs : getLTEs('T16U','Aqua'), station : getStation(null), prevStation : 'RRR', nextStation : 'VAN', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T02V', LTEs : getLTEs('T02V','Aqua'), station : getStation('T02V'), prevStation : '', nextStation : 'BAN', line:'Aqua', direction: 'up', diverging:[]},  
       {name: 'T04V', LTEs : getLTEs('T04V','Aqua'), station : getStation(null), prevStation : 'VAN', nextStation : 'BAN', line:'Aqua', direction: 'up', diverging: getDiverging('T04V')},
       {name: 'T06V', LTEs : getLTEs('T06V','Aqua'), station : getStation(null), prevStation : 'VAN', nextStation : 'BAN', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T02W', LTEs : getLTEs('T02W','Aqua'), station : getStation('T02W'), prevStation : '', nextStation : 'LON', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T04W', LTEs : getLTEs('T04W','Aqua'), station : getStation(null), prevStation : 'BAN', nextStation : 'LON', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T06W', LTEs : getLTEs('T06W','Aqua'), station : getStation(null), prevStation : 'BAN', nextStation : 'LON', line:'Aqua', direction: 'up', diverging: getDiverging('T06W')},
       {name: 'T08W', LTEs : getLTEs('T08W','Aqua'), station : getStation('T08W'), prevStation : '', nextStation : '', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T10W', LTEs : getLTEs('T10W','Aqua'), station : getStation(null), prevStation : 'LON', nextStation : '', line:'Aqua', direction: 'up', diverging:[]},
       {name: 'T12W', LTEs : getLTEs('T12W','Aqua'), station : getStation(null), prevStation : '', nextStation : '', line:'Aqua', direction: 'up', diverging: getDiverging('T12W')},
       {name: 'T14W', LTEs : getLTEs('T14W','Aqua'), station : getStation(null), prevStation : 'LON', nextStation : '', line:'Aqua', direction: 'up', diverging:[]},
       //////////////////////////////////////////////////////////////////Aqua MID//////////////////////////////////////////////////////////////////////
       {name: 'T11U', LTEs : getLTEs('T11U','Aqua'), station : getStation(null), prevStation : '', nextStation : '', line:'Aqua', direction: 'mid', diverging:getDiverging('T11U')},
       {name: 'T13U', LTEs : getLTEs('T13U','Aqua'), station : getStation(null), prevStation : '', nextStation : '', line:'Aqua', direction: 'mid', diverging: getDiverging('T13U')},
       //////////////////////////////////////////////////////////////////Aqua DOWN/////////////////////////////////////////////////////////////////////
       {name: 'T15W', LTEs : getLTEs('T15W','Aqua'), station : getStation(null), prevStation : '', nextStation : 'LON', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T13W', LTEs : getLTEs('T13W','Aqua'), station : getStation(null), prevStation : '', nextStation : 'LON', line:'Aqua', direction: 'down', diverging:getDiverging('T13W')},
       {name: 'T11W', LTEs : getLTEs('T11W','Aqua'), station : getStation(null), prevStation : '', nextStation : '', line:'Aqua', direction: 'down', diverging: getDiverging('T11W')},
       {name: 'T09W', LTEs : getLTEs('T09W','Aqua'), station : getStation(null), prevStation : '', nextStation : 'LON', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T07W', LTEs : getLTEs('T07W','Aqua'), station : getStation('T07W'), prevStation : '', nextStation : 'BAN', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T05W', LTEs : getLTEs('T05W','Aqua'), station : getStation(null), prevStation : 'LON', nextStation : 'BAN', line:'Aqua', direction: 'down', diverging:getDiverging('T05W')},
       {name: 'T03W', LTEs : getLTEs('T03W','Aqua'), station : getStation(null), prevStation : 'LON', nextStation : 'BAN', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T01W', LTEs : getLTEs('T01W','Aqua'), station : getStation('T01W'), prevStation : '', nextStation : 'VAN', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T07V', LTEs : getLTEs('T07V','Aqua'), station : getStation(null), prevStation : 'BAN', nextStation : 'VAN', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T05V', LTEs : getLTEs('T05V','Aqua'), station : getStation(null), prevStation : 'BAN', nextStation : 'VAN', line:'Aqua', direction: 'down', diverging:getDiverging('T05V')},
       {name: 'T03V', LTEs : getLTEs('T03V','Aqua'), station : getStation(null), prevStation : 'BAN', nextStation : 'VAN', line:'Aqua', direction: 'down', diverging: []},
       {name: 'T01V', LTEs : getLTEs('T01V','Aqua'), station : getStation('T01V'), prevStation : '', nextStation : 'RRR', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T19U', LTEs : getLTEs('T19U','Aqua'), station : getStation(null), prevStation : 'VAN', nextStation : 'RRR', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T17U', LTEs : getLTEs('T17U','Aqua'), station : getStation('T17U'), prevStation : '', nextStation : 'SBS', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T15U', LTEs : getLTEs('T15U','Aqua'), station : getStation(null), prevStation : 'RRR', nextStation : 'SBS', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T09U', LTEs : getLTEs('T09U','Aqua'), station : getStation(null), prevStation : 'RRR', nextStation : 'SBS   ', line:'Aqua', direction: 'down', diverging: getDiverging('T09U')},
       {name: 'T07U', LTEs : getLTEs('T07U','Aqua'), station : getStation(null), prevStation : 'RRR', nextStation : 'SBS', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T05U', LTEs : getLTEs('T05U','Aqua'), station : getStation('T05U'), prevStation : '', nextStation : 'ALV', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T03U', LTEs : getLTEs('T03U','Aqua'), station : getStation(null), prevStation : 'SBS', nextStation : 'ALV', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T01U', LTEs : getLTEs('T01U','Aqua'), station : getStation('T01U'), prevStation : '', nextStation : 'LAD', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T21T', LTEs : getLTEs('T21T','Aqua'), station : getStation(null), prevStation : 'ALV', nextStation : 'LAD', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T19T', LTEs : getLTEs('T19T','Aqua'), station : getStation('T19T'), prevStation : '', nextStation : 'SNS', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T17T', LTEs : getLTEs('T17T','Aqua'), station : getStation(null), prevStation : 'LAD', nextStation : 'SNS', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T15T', LTEs : getLTEs('T15T','Aqua'), station : getStation('T15T'), prevStation : '', nextStation : 'IOE', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T13T', LTEs : getLTEs('T13T','Aqua'), station : getStation(null), prevStation : 'SNS', nextStation : 'IOE', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T11T', LTEs : getLTEs('T11T','Aqua'), station : getStation(null), prevStation : 'SNS', nextStation : 'IOE', line:'Aqua', direction: 'down', diverging:getDiverging('T11T')},
       {name: 'T09T', LTEs : getLTEs('T09T','Aqua'), station : getStation('T09T'), prevStation : '', nextStation : 'JRS', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T07T', LTEs : getLTEs('T07T','Aqua'), station : getStation(null), prevStation : 'IOE', nextStation : 'JRS', line:'Aqua', direction: 'down', diverging: getDiverging('T07T')},
       {name: 'T05T', LTEs : getLTEs('T05T','Aqua'), station : getStation(null), prevStation : 'IOE', nextStation : 'JRS', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T03T', LTEs : getLTEs('T03T','Aqua'), station : getStation('T03T'), prevStation : '', nextStation : 'STB', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T07S', LTEs : getLTEs('T07S','Aqua'), station : getStation(null), prevStation : 'JRS', nextStation : 'STB', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T05S', LTEs : getLTEs('T05S','Aqua'), station : getStation('T05S'), prevStation : '', nextStation : '', line:'Aqua', direction: 'down', diverging:[]},
       {name: 'T03S', LTEs : getLTEs('T03S','Aqua'), station : getStation(null), prevStation : 'STB', nextStation : '', line:'Aqua', direction: 'down', diverging:getDiverging('T03S')},
       {name: 'T01S', LTEs : getLTEs('T01S','Aqua'), station : getStation(null), prevStation : 'STB', nextStation : '', line:'Aqua', direction: 'down', diverging: []},
   ],
   trains,
   stationRadios
 ]
 
 
 module.exports = { aquaLineLTE, orangeLineLTE, trackCircuits, trains,stationRadios};

