// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#fence .add-btn {
  position: absolute;
  top: 65px;
  right: 60px;
  z-index: 999;
}
#fence .action-items {
  z-index: 999;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: rgba(255, 255, 255, 0.8588235294);
  padding: 30px 0;
}
#fence .action-items input {
  width: 300px;
}
#fence .action-items button {
  margin: 0 10px;
  width: 120px;
}
#fence .delete-icon {
  z-index: 99999999999;
  position: absolute;
  background: #1c1d1e;
  color: #fff;
  transform: translateX(-50%) translateY(-50%);
}
#fence .title {
  z-index: 99999999999;
  position: absolute;
  top: -25px;
  padding: 5px;
  border-radius: 5px;
  width: auto;
  min-width: 60px;
  text-align: center;
  background: #1c1d1e;
  color: #fff;
  transform: translateX(-50%) translateY(-50%);
}`, "",{"version":3,"sources":["webpack://./src/styles/fence.scss"],"names":[],"mappings":"AAGE;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;AAFJ;AAIE;EACE,YAAA;EACA,eAAA;EACA,SAAA;EACA,WAAA;EACA,kBAAA;EACA,6CAAA;EACA,eAAA;AAFJ;AAGI;EACE,YAAA;AADN;AAGI;EACE,cAAA;EACA,YAAA;AADN;AAIE;EACE,oBAAA;EACA,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,4CAAA;AAFJ;AAIE;EACE,oBAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,4CAAA;AAFJ","sourcesContent":["@import \"theme\";\n\n#fence{\n  .add-btn{\n    position: absolute;\n    top: 65px;\n    right: 60px;\n    z-index: 999;\n  }\n  .action-items{\n    z-index: 999;\n    position: fixed;\n    bottom: 0;\n    width: 100%;\n    text-align: center;\n    background: #ffffffdb;\n    padding: 30px 0;\n    input{\n      width: 300px;\n    }\n    button{\n      margin: 0 10px;\n      width: 120px;\n    }\n  }\n  .delete-icon{\n    z-index: 99999999999;\n    position: absolute;\n    background: #1c1d1e;\n    color: #fff;\n    transform: translateX(-50%) translateY(-50%);\n  }\n  .title{\n    z-index: 99999999999;\n    position: absolute;\n    top: -25px;\n    padding: 5px;\n    border-radius: 5px;\n    width: auto;\n    min-width: 60px;\n    text-align: center;\n    background: #1c1d1e;\n    color: #fff;\n    transform: translateX(-50%) translateY(-50%);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
