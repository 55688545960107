(function (exports) {
  const EndPoints = require("./endpoints").EndPoints;
  const axios = require("axios").default;
  const remote = {
    /* log : (data)=>{
            axios.put(endpoints.remoteLog,data);
        }, */

    log: (data1, data2) => {
      let data = "";
      if (data2) {
        try {
          data = data1 + " " + JSON.stringify(data2);
        } catch (e) {
          data = data1 + " " + data2;
        }
      } else {
        data = data1;
      }
      // axios.put(EndPoints.getConfig().remoteLog, data);
      if (
        (global.config.DISPACTHER_LOGS_ENABLE &&
          JSON.parse(global.config.DISPACTHER_LOGS_ENABLE) &&
          JSON.parse(global.config.DISPACTHER_LOGS_ENABLE) === true) ||
        (global.config.ipConfig && global.config.ipConfig.isDebugMode)
      ) {
        console.log("remote logs.....", data);
        // axios.put(EndPoints.getConfig().remoteLog, data);
        axios.put(EndPoints.getConfig().remoteLog, data)
          .then(response => {
            // Handle successful response
            console.log('Request successful:', response.data);
          })
          .catch(error => {
            // Handle error
            console.error('Request failed:', error);
          });
      } else {
        console.log("logger logs.....", data);
      }
    },
  };
  exports.remote = remote;
})(typeof exports === "undefined" ? (this["remote"] = {}) : exports);
