(function (exports) {
  const sdpTransform = require("sdp-transform");

  class SDPTransform {
    getParsedSdp(sdpString) {
      return sdpTransform.parse(sdpString);
    }

    writeJsonToSdp(json) {
      return sdpTransform.write(json);
    }

    isMLineExists(sdp) {
      try {
        let parsedSdp = this.getParsedSdp(sdp);
        if (parsedSdp.media.length > 0) {
          for (let med of parsedSdp.media) {
            if (med.type === "audio") {
              return med.port;
            }
          }
        } else {
          return 0;
        }
      } catch (e) {
        throw e;
      }
    }

    isMLineExistsApplication(sdp) {
      try {
        let parsedSdp = this.getParsedSdp(sdp);
        if (parsedSdp.media.length > 0) {
          for (let med of parsedSdp.media) {
            if (med.type === "application") {
              return med.port;
            }
          }
        } else {
          return 0;
        }
      } catch (e) {
        throw e;
      }
    }
    getSDPWithState(sdp, state) {
      let parsedSdp = this.getParsedSdp(sdp);

      // console.log("updateSdpStateRecieveOrSend.......", parsedSdp);
      if (parsedSdp.media.length > 0) {
        for (let i = 0; i < parsedSdp.media.length; i++) {
          parsedSdp.media[i].direction = state;
        }
      } else {
        return sdp;
      }
      let newSdp = this.writeJsonToSdp(parsedSdp);
      return newSdp
    }
    removeExtmapFromSDP(sdp) {
      let localDesc = sdp;
      let parsedSdp = this.getParsedSdp(sdp);

      // console.log("removeExtmapFromSDP.......", parsedSdp);
      if (parsedSdp.media.length > 0) {
        for (let i = 0; i < parsedSdp.media.length; i++) {
          // parsedSdp.media[i].direction = state;
          let fmtp = parsedSdp.media[i].fmtp
          let newFmtp = []
          for (let j = 0; j < fmtp.length; j++) {
            // console.log("removeExtmapFromSDP....... fmtp", fmtp[j]);
            //fmtp// {"config": "minptime=10;useinbandfec=1", "payload": 111}
            if (!fmtp[j].config || !fmtp[j].config.includes("useinbandfec")) {
              newFmtp = [...newFmtp, fmtp[j]];
            }
          }
          parsedSdp.media[i].ext = []
          parsedSdp.media[i].fmtp = newFmtp
          // console.log("removeExtmapFromSDP....... 2",);
        }
      } else {

      }
      let newSdp = this.writeJsonToSdp(parsedSdp);
      console.log("removeExtmapFromSDP.......formatedSdp", newSdp);
      return newSdp
    }
    updateLocalDescriptionStateRecieveOrSend(session, state,) {
      try {
        // sendonly
        //sendrecv
        //recvonly

        if (!session) {
          return;
        }
        if (session.connection) {
          let localDesc = session.connection.localDescription;
          let sdp = localDesc.sdp;
          let parsedSdp = this.getParsedSdp(sdp);

          // console.log("updateSdpStateRecieveOrSend.......", parsedSdp);
          if (parsedSdp.media.length > 0) {
            for (let i = 0; i < parsedSdp.media.length; i++) {
              parsedSdp.media[i].direction = state;
            }
          } else {
            return null;
          }
          let newSdp = this.writeJsonToSdp(parsedSdp);
          // console.log("updateSdpStateRecieveOrSend....... 2 ", newSdp);
          let updatedLocaldescription = {};
          updatedLocaldescription.sdp = newSdp;
          updatedLocaldescription.type = "offer"; //localDesc.type;
          session.connection
            .setLocalDescription(updatedLocaldescription)
            .then((res) => {
              //console.log("localDesc........... 5", res);
              let localDesc = session.connection.localDescription;
              console.log("localDesc........... 7", localDesc.sdp);
            })
            .catch((err) => {
              console.log("localDesc........... 4", err);
            });
          // console.log("localDesc........... 6", updatedLocaldescription);
          return
        }
      } catch (e) {
        throw e;
      }
    }
    setLocalDescription(session, state) {
      if (session.connection) {
        let localDesc = session.connection.localDescription;
        let sdp = localDesc.sdp;
        let parsedSdp = this.getParsedSdp(sdp);

        //console.log("updateSdpStateRecieveOrSend.......", parsedSdp);
        if (parsedSdp.media.length > 0) {
          for (let i = 0; i < parsedSdp.media.length; i++) {
            parsedSdp.media[i].direction = state;
          }
        } else {
          return null;
        }
        let newSdp = this.writeJsonToSdp(parsedSdp);
        // console.log("updateSdpStateRecieveOrSend....... 2 ", newSdp);
        let updatedLocaldescription = {};
        updatedLocaldescription.sdp = newSdp;
        updatedLocaldescription.type = "offer"; //localDesc.type;

        // console.log("localDesc........... 2", localDesc.type, localDesc);
        session.connection
          .setLocalDescription(updatedLocaldescription)
          .then((res) => {
            //console.log("localDesc........... 5", res);
            let localDesc = session.connection.localDescription;
            console.log("localDesc........... 7", localDesc.sdp);
          })
          .catch((err) => {
            console.log("localDesc........... 4", err);
          });
        // console.log("localDesc........... 6", updatedLocaldescription);
        return updatedLocaldescription;
      }
    }
    updateBandwidthRestriction(sdp, bandwidth) {
      console.log("updateBandwidthRestriction....", bandwidth);
      let modifier = "AS";
      // if (adapter.browserDetails.browser === "firefox") {
      //   bandwidth = (bandwidth >>> 0) * 1000;
      //   modifier = "TIAS";
      // }
      if (sdp.indexOf("b=" + modifier + ":") === -1) {
        // insert b= after c= line.
        sdp = sdp.replace(
          /c=IN (.*)\r\n/,
          "c=IN $1\r\nb=" + modifier + ":" + bandwidth + "\r\n"
        );
      } else {
        sdp = sdp.replace(
          new RegExp("b=" + modifier + ":.*\r\n"),
          "b=" + modifier + ":" + bandwidth + "\r\n"
        );
      }
      console.log("updateBandwidthRestriction....3");
      return sdp;
    }
    updateSDPStateRecieveOrSend(sdp, state) {
      try {
        // sendonly
        //sendrecv
        //recvonly

        let parsedSdp = this.getParsedSdp(sdp);

        // console.log("updateSDPStateRecieveOrSend.......", sdp);
        if (parsedSdp.media.length > 0) {
          for (let i = 0; i < parsedSdp.media.length; i++) {
            // console.log("updateSDPStateRecieveOrSend....... 1212");
            parsedSdp.media[i].direction = state;
          }
        } else {
          return null;
        }
        let newSdp = this.writeJsonToSdp(parsedSdp);
        // console.log("updateSDPStateRecieveOrSend.......", newSdp);
        return newSdp;
      } catch (e) {
        throw e;
      }
    }

    getCLineIp(sdp) {
      try {
        let parsedSdp = this.getParsedSdp(sdp);
        if (parsedSdp.hasOwnProperty("connection")) {
          return parsedSdp.connection.ip;
        } else {
          if (parsedSdp.media.length > 0) {
            for (let med of parsedSdp.media) {
              if (med.type === "audio" && med.hasOwnProperty("connection")) {
                return med["connection"]["ip"];
              }
            }
          }
          return false;
        }
      } catch (e) {
        throw e;
      }
    }

    deleteMApplicationLine(sdp) {
      try {
        let mediaApplicationIndex = -1;
        let parsedSdp = this.getParsedSdp(sdp);
        let index = 0;
        for (let media of parsedSdp["media"]) {
          if (media.type === "application") {
            mediaApplicationIndex = index;
            break;
          }
          index = index + 1;
        }
        if (mediaApplicationIndex > -1) {
          delete parsedSdp["media"][mediaApplicationIndex];
        }
        let newTransformedSdp = this.writeJsonToSdp(parsedSdp);
        return newTransformedSdp;
      } catch (e) {
        throw e;
      }
    }

    replacePortInSdp(sdpString, localPort) {
      let parsedSdp = this.getParsedSdp(sdpString);
      if (parsedSdp.media.length > 0) {
        for (let med of parsedSdp.media) {
          if (med.type === "audio") {
            med.port = localPort;
            break;
          }
        }
      }
      console.log("parsed port....", parsedSdp);
      let parsedSdpStr = sdpTransform.write(parsedSdp).split("\r\n");
      return parsedSdpStr;
    }

    addApplicationMLineInSdp(sdpString, localPort) {
      // m=application PORT udp MCPTT
      let sdpAppendLine = `\r\nm=application ${localPort} udp MCPTT\n`;
      sdpString = sdpString.trimEnd();
      sdpString = sdpString + sdpAppendLine;
      return sdpString;
    }
  }

  exports.SDPTransform = SDPTransform;
})(typeof exports === "undefined" ? (this["SDPTransform"] = {}) : exports);
