import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { motion } from "framer-motion"

//Other
import { ContextMenu } from '../commom';
import { getRadioId } from '../../utils/lib';
import '../../constants/trainmapConfig'


let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
}

const TrainIconUpdate = (props) => {
    const { selectedline, moveTo, dirn, coordY, train } = props;
    const [animate, setAnimate] = useState(false);
    useEffect(() => {
        setAnimate(true)
        //console.log("useEffect in trainIconUpodate : " + coordX + " " + coordY + " " + train.line)
        document.addEventListener(visibilityChange, handleVisibilityChange, false);
        return () => {
            document.removeEventListener(visibilityChange, handleVisibilityChange);
        }
    }, [])

    //functions
    const handleVisibilityChange = () => {
        if (document[hidden]) {
            setAnimate(false)
        } else {
            setAnimate(true)
        }
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {props.stn}
        </Tooltip>
    );

    const getTrainNo = (trainNum) => {
        if (trainNum) {
            let trainArr = trainNum.split("-");
            if (trainArr.length > 1) {
                trainArr.pop();
                return trainArr.join("-");
            }
            return trainNum
        }
        return trainNum
    }
    const trainConfig = global.config.trainConfig;
    const lteGap = trainConfig.lteGap;
    const lteSize = trainConfig.lteSize;
    const dotSpace = lteSize + (lteGap * 2);

    let coordX = moveTo - dotSpace / 1.7;
    if (process.env.REACT_APP_PROJECT === 'cdot') {
        coordX = coordX + 30
    }
    if (dirn === 'up1' || dirn === 'down1') {
        coordX = coordX - 50;
        coordX = (train.virtualTrackCircuit === "6UNVT01A") ? coordX - 35 : coordX;
    }
    if (dirn === 'mid') {
        coordX = coordX + global.config.trainConfig.midTrackX;
    }

    const renderNSTrainIcon = (train) => {  //downTrack Train Icon
        const radioData = (process.env.REACT_APP_ISRAILWAY).toLowerCase() == 'true' ? null : getRadioId(props.radioData, train.rakeId, train.movementDirection, train.activeCab);
        let routename = (process.env.REACT_APP_ISRAILWAY).toLowerCase() == 'true' ? 'trn' : ''
        let type = (process.env.REACT_APP_ISRAILWAY).toLowerCase() == 'true' ? 'TDS' : 'TS'
        let traindata = (process.env.REACT_APP_ISRAILWAY).toLowerCase() == 'true' ? `TR-FRONTTRCP-${getTrainNo(train.rakeId)}` : null
        let isFACall = (process.env.REACT_APP_ISRAILWAY).toLowerCase() == 'true' ? true : false;
        
        let trainIcon = train.movementDirection ? train.trainAliveStatus == false ? "assets/images/dirn-train-south-gray.svg" : "assets/images/dirn-train-south.svg" : train.trainAliveStatus == false ? "assets/images/dirn-train-north-gray.svg" : "assets/images/dirn-train-north.svg";
        return (
            <ContextMenu id={train.rakeId.toString()} key={train.rakeId.toString()} type={type} subr={radioData ? radioData.radioId : traindata} inactiveRadio={radioData && radioData.inactiveRadioId} paId={radioData && radioData.livePaId} routeName={routename} isFACall={isFACall}>
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip({ stn: train.rakeId })}
                >
                    <motion.div class="train-move" style={{ ...props.style, marginTop: 20, zIndex: 5 }}
                        // <motion.div class="train-move" style={{ ...props.style, zIndex: 10 }}
                        animate={{ x: coordX, y: coordY }}
                        transition={{ duration: animate ? 5 : 0 }}
                    >
                        <div class="train-status" style={{ width: dotSpace / 2 }}></div>
                        <button class="btn" style={{ width: '90px' }} >
                            <img style={{ padding: '6px' }} src={trainIcon} alt={'train'} />
                            <div class="f-12 f-bold gray-0">{(train.trainNumber !== train.rakeId) ? `${train.trainNumber} / ${train.rakeId}` : `${train.trainNumber}`}</div>
                        </button>
                    </motion.div>
                </OverlayTrigger>
            </ContextMenu>
        )
    }

    const renderSNTrainIcon = (train) => {  //upTrack Train Icon
        //const { selectedline, moveTo,coordY } = props;
        const radioData = (process.env.REACT_APP_ISRAILWAY).toLowerCase() == 'true' ? null : getRadioId(props.radioData, train.rakeId, train.movementDirection, train.activeCab);
        let routename = (process.env.REACT_APP_ISRAILWAY).toLowerCase() == 'true' ? 'trn' : ''
        let type = (process.env.REACT_APP_ISRAILWAY).toLowerCase() == 'true' ? 'TDS' : 'TS'
        let traindata = (process.env.REACT_APP_ISRAILWAY).toLowerCase() == 'true' ? `TR-FRONTTRCP-${getTrainNo(train.rakeId)}` : null
        let isFACall = (process.env.REACT_APP_ISRAILWAY).toLowerCase() == 'true' ? true : false
        
        let trainIcon = train.movementDirection ? train.trainAliveStatus == false ? "assets/images/dirn-train-south-gray.svg" : "assets/images/dirn-train-south.svg" : train.trainAliveStatus == false ? "assets/images/dirn-train-north-gray.svg" : "assets/images/dirn-train-north.svg";
        return (
            <ContextMenu id={train.rakeId.toString()} key={train.rakeId.toString()} type={type} subr={radioData ? radioData.radioId : traindata} inactiveRadio={radioData && radioData.inactiveRadioId} paId={radioData && radioData.livePaId} routeName={routename} isFACall={isFACall}>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip({ stn: train.rakeId })}
                >
                    <motion.div class="train-move-left" style={{ ...props.style, zIndex: 5 }}
                        animate={{ x: coordX, y: coordY }}
                        transition={{ duration: animate ? 5 : 0 }}
                    >
                        <button class="btn" style={{ width: '90px' }}>
                            {/* <div class="f-12 f-bold gray-0">{`${train.trainNumber} / ${train.rakeId}`}</div> */}
                            <div class="f-12 f-bold gray-0">{(train.trainNumber !== train.rakeId) ? `${train.trainNumber} / ${train.rakeId}` : `${train.trainNumber}`}</div>
                            <img style={{ padding: '6px', color: 'red' }} src={trainIcon} alt={'train'} />
                        </button>
                        <div class="train-status m-t-2" style={{ marginTop: '2px', width: dotSpace / 2 }}></div>
                    </motion.div>
                </OverlayTrigger>
            </ContextMenu>
        )
    }

    const renderNonFATrainIcon = () => {  //downTrack Train Icon backgroundColor: 'gray', borderRadius: 5 
        let type = (process.env.REACT_APP_ISRAILWAY).toLowerCase() == 'true' ? 'TD' : 'TS'
        let subr = train.RadioID_A ? train.RadioID_A : train.RadioID_B;
        return (
            <ContextMenu id={train.rakeId.toString()} key={train.rakeId.toString()} type={type} subr={subr} routeName={'trn'} isFACall={false}>
                <div class="train-move" style={{ ...props.style, left: coordX - 5, marginTop: -120, zIndex: 5 }}>
                    <div style={{ width: '90px' }} >
                        <div class="f-12 f-bold gray-0">{train.rakeId.toString()}</div>
                        <div style={{justifyContent: 'center', flexDirection: 'row', display: 'flex'}}>
                            <img style={{ padding: '6px', objectFit: 'contain', height: '50px' }} src={"assets/images/train_stay.png"} alt={'train'} />
                        </div>
                    </div>
                </div>
            </ContextMenu>
        )
    }

    return (
        <React.Fragment>
            {dirn === 'up' || dirn === 'up1' || dirn === 'mid' ? renderSNTrainIcon(props.train) : dirn === 'none' ? renderNonFATrainIcon() : renderNSTrainIcon(props.train)}
        </React.Fragment>
    )
}

const mapStateToProps = ({ train }) => {
    const { radioData } = train;
    return {
        radioData
    };
};

export default connect(mapStateToProps, {})(TrainIconUpdate);

