import React, { useState, useEffect } from "react";
import { Modal } from 'react-bootstrap'
import { connect } from "react-redux";
import AlertDialog from "../../Admin/components/DeleteUserDialog";
import AddParent from "./addPartner";
import { updatePartnerListMtm, updateTenantListMtm, deletePartnerListMtm, deleteTenantListMtm } from "../../modules/mtmstate";
import AddTenant from "./addTenant";

const MtmListRow = (props) => {
  const { SubscribeType, deletePartnerListMtm, deleteTenantListMtm, updatePartnerListMtm, updateTenantListMtm, userlist, deleteUserOnIdmsAndServer } = props
  const [partnerOpen, setPartnerOpen] = useState(false);
  const [tenantOpen, setTenantOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const hideModal = () => {
    if (SubscribeType === "partner") {
      setPartnerOpen(false)
    }
    else {
      setTenantOpen(false)
    }
  }
  const editButtonClick = () => {
    if (SubscribeType === "partner") {
      setPartnerOpen(true)
    }
    else if (props.SubscribeType === "tenant") {
      setTenantOpen(true)
    }
  }
  const deleteButtonClick = () => {
    if (SubscribeType === "partner") {
      if (props.mtmData && props.mtmData.partnerId) {
        setDialogOpen(true)

      }
    }
    else if (props.SubscribeType === "tenant") {
      if (props.mtmData) {
        setDialogOpen(true)
      }
    }

  }

  const deleteDailogHandler = (val) => {
    setDialogOpen(false)
    if (val) {
      if (SubscribeType === "partner") {
        deletePartnerListMtm(props.mtmData.partnerId, { type: SubscribeType })

      } else if (props.SubscribeType === "tenant") {
        deleteTenantListMtm(props.mtmData.tenantId, { type: SubscribeType })
      }
    }

  }

  const mtmHandleClose = () => {
    setPartnerOpen(false)
    setTenantOpen(false)
  }

  const updatedPartnerData = (data) => {
    updatePartnerListMtm(data)
  }
  const updatedTenantData = (data) => {
    updateTenantListMtm(data)
  }

  if (props.SubscribeType === "partner") {
    return (
      /* This div returns the jsx to create single user list row elements*/
      <div style={{ width: "100%", margin: "5px", }}>
        <div className="user-row-grid">
          <div className="tr-tb-icon">
            <img
              src='/assets/images/Vector-7.svg'
              className="single-user-img"
              alt=""
            />
          </div>
          <div className="tr-tb-uname" >
            <span>{props.mtmData.partnerName} </span>
          </div>
          <div className="tr-tb-uid">
            <span>{props.mtmData.partnerEmail}</span>
          </div>
          <div className="tr-tb-mxid">
            <span>{props.mtmData.partnerContactNumber}</span>
          </div>
          <div className="tr-tb-edit">
            <button
              className="editBtn"
              onClick={editButtonClick}
              type="button"
              name=""
            >
              <img
                src="/assets/images/editimg.svg"
                className="edit-user-img"
                alt=""
              />
            </button>
          </div>
          <div className="tr-tb-delete">
            {global.config.userConfig.multiTenantFeature == true &&
              <button
                className="editBtn"
                onClick={deleteButtonClick}
                type="button"
                name=""
              >
                <img
                  src="/assets/images/deleteimg.svg"
                  className="delete-user-img"
                  alt=""
                />
              </button>
            }
          </div>
        </div>
        <Modal
          show={partnerOpen}
          onHide={mtmHandleClose}
          size={"lg"}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ backgroundColor: ' rgba(0,0,0,0.5)' }}
        >
          <Modal.Header closeButton style={{ border: '0px', backgroundColor: '#282828' }}>
            <Modal.Title>UPDATE PARTNER</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: '0.2px' }}>
            {
              partnerOpen ?
                <AddParent purpose="edit" tetraUser={props.tetraUser} hideModal={hideModal} updatedPartnerData={updatedPartnerData} partnerData={props.mtmData}></AddParent>
                : null
            }
          </Modal.Body>
        </Modal>
        <AlertDialog open={dialogOpen} deleteDailogHandler={deleteDailogHandler} userName={props.mtmData.partnerName} />
      </div>
    );
  }
  else if (props.SubscribeType === "tenant") {
    return (
      <div style={{ flexDirection: "row", display: "flex" }}>
        <div style={{ width: "100%", margin: "5px" }}>
          <div className="group-row-grid">
            <div className="tr-tb-icon">
              <img
                src='/assets/images/Vector-6.svg'
                className="group-user-img"
                alt=""
              />
            </div>
            <div className="tr-tb-gname">
              <span>{props.mtmData.tenantName}</span>
            </div>
            <div className="tr-tb-gid">
              <span>{props.mtmData.tenantAddress}</span>
            </div>
            <div className="tr-tb-members">
              <span>{props.mtmData.tenantContactNumber}</span>
            </div>
            <div className="tr-tb-edit">
              <button
                className="editBtn"
                onClick={editButtonClick}
                type="button"
                name=""
              >
                <img
                  src="/assets/images/editimg.svg"
                  class="edit-user-img"
                  alt=""
                />
              </button>
            </div>
            <div className="tr-tb-delete">
              {global.config.userConfig.multiTenantFeature == true &&
                <button
                  className="editBtn"
                  onClick={deleteButtonClick}
                  type="button"
                  name=""
                >
                  <img
                    src="/assets/images/deleteimg.svg"
                    className="delete-user-img"
                    alt=""
                  />
                </button>}
            </div>
          </div>
        </div>
        <Modal
          show={tenantOpen}
          onHide={mtmHandleClose}
          size={"lg"}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ backgroundColor: ' rgba(0,0,0,0.5)' }}
        >
          <Modal.Header closeButton style={{ border: '0px', backgroundColor: '#282828' }}>
            <Modal.Title>UPDATE TENANT</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: '0.2px' }}>
            {
              tenantOpen ?
                <AddTenant purpose="edit" tetraUser={props.tetraUser} hideModal={hideModal} updatedTenantData={updatedTenantData} tenantData={props.mtmData}></AddTenant>
                : null
            }
          </Modal.Body>
        </Modal>
        <AlertDialog open={dialogOpen} deleteDailogHandler={deleteDailogHandler} userName={props.mtmData.tenantName} />

      </div>
    );
  }
}

const mapStateToProps = ({ adminstate }) => {
  const { userlist } = adminstate;
  return {
    userlist
  };
};

export default connect(mapStateToProps, { updatePartnerListMtm, updateTenantListMtm, deletePartnerListMtm, deleteTenantListMtm })(MtmListRow);