import React, { useState } from "react";
import { Modal } from 'react-bootstrap'
import { connect } from "react-redux";
import EditApp from "./Modal/EditApp";
import { deleteApp } from "../../modules/actions/mdmAction";
import AlertDialog from "../../Admin/components/DeleteUserDialog";


const DeviceListRow = (props) => {
  const {data, tenantId, deleteApp } = props;
  const [isModalOpen, setModalOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false);

  const editButtonClick = () => {
    setModalOpen(true)
  }

  const userhandleClose = () => {
    setModalOpen(false)
  }
  const boolDisplay = (option)=>{
    return option? "True": "False"
  }
  const deleteButtonClick = () => {
    setDialogOpen(true)
  }
  const deleteDailogHandler = (val) => {
    setDialogOpen(false)
    if (val) {
      deleteApp({tenantId, id: data.id})
    }

  }
  return (
    /* This div returns the jsx to create single user list row elements*/
    <div style={{ width: "100%", margin: "5px" }}>
      <div class="device-row-grid">
        <div class="tr-tb-icon">
          {/* <img
            src='/assets/images/Vector-7.svg'
            class="single-user-img"
            alt=""
            style={{ height: "20px" }}
          /> */}
          <i className={"feather icon-airplay f-32"} style={{lineHeight: "50%"}}/>
        </div>
        <div class="tr-tb-deviceid">
          <span>{props.data.packageId} </span>
        </div>
        <div class="tr-tb-status">
          <span>{props.data.name}</span>
        </div>
        <div class="tr-tb-config">
          <span>{props.data.version}</span>
        </div>
        <div className="tr-tb-username">
          <span>{boolDisplay(props.data.runAfterInstall)}</span>
        </div>
        <div className="tr-tb-qr">
          <span>{boolDisplay(props.data.runAtBoot)}</span>
        </div>
        <div className="tr-tb-qr">
          <span>{props.data.manufacturer} {props.data.brand}</span>
        </div>
        <div class="tr-tb-edit">
          <button
            class="editBtn"
            onClick={editButtonClick}
            type="button"
            name=""
          >
            <img
              src="/assets/images/editimg.svg"
              class="edit-user-img"
              alt=""
            />
          </button>
        </div>
        <div class="tr-tb-delete">
          <button
            class="editBtn"
            onClick={deleteButtonClick}
            type="button"
            name=""
          >
            <img
              src="/assets/images/deleteimg.svg"
              class="delete-user-img"
              alt=""
            />
          </button>
        </div>
      </div>
      <Modal
        show={isModalOpen}
        onHide={userhandleClose}
        size={"lg"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ backgroundColor: ' rgba(0,0,0,0.5)' }}
      >
        <Modal.Header closeButton style={{ border: '0px', backgroundColor: '#282828' }}>
          <Modal.Title>UPDATE APP</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '0.2px' }}>
          <EditApp setModalOpen={setModalOpen} purpose={"edit"} data={data}/>
        </Modal.Body>
      </Modal>
      <AlertDialog open={dialogOpen} deleteDailogHandler={deleteDailogHandler} userName={props.data.packageId} />
    </div>
  );
}
const mapStateToProps = ({mdm})=>{
  return {
    tenantId: mdm.tenantId
  }
}
export default connect(mapStateToProps, {deleteApp})(DeviceListRow);
