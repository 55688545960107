module.exports = {
    LOGIN_STATE: {
        LoggingIn: 1,
        LoggedIn: 2,
        LoggingOut: 3,
        LoggedOut: 4,
        Requested: 5
    },

    REGISTRATION_STATE: {
        Registered: 1,
        Deregistered: 2,
    },

    XCAP_STATE: {
        None: 1,
        Subscribing: 2,
        Subscribed: 3,
        Notified: 4,
        Fetching: 5,
        Fetched: 6
    },

    PRESENCE_STATE: {
        None: 1,
        Subscribing: 2,
        Subscribed: 3,
        Notified: 4,
    },

    TRANSPORT_STATE: {
        Connected: 1,
        Disconnected: 2,
        Connecting: 3
    }
};