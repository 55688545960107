(function (exports) {
  const EndPoints = require("../endpoints").EndPoints;
  const OutgoingRequest = require("../sipMethods/OutgoingRequest")
    .OutgoingRequest;
  const RequestSender = require("../jssip/RequestSender");
  const js2xmlparser = require("js2xmlparser");
  const Utils = require("../jssip/JsSIP").Utils;
  class FAPublisher {
    constructor(UA) {
      this.host = EndPoints.getConfig().faHost;
      this.UA = UA;

      this.requestType = ""; //activate or deactivate or takeover
      this.instanceId = this.UA._registrator.instance_id;
      this.cseq = 4;

      this.contact = this.UA._registrator._contact;
      this.expires = 4294967295;
      this.ruri = this.UA._registrator._registrar;

      this.subscriptions = new Map();
      this.dialogStateMap = new Map([
        ["terminated", "AVAILABLE"],
        ["early", "RINGING"],
        ["confirmed", "BUSY"],
        ["trying", "BUSY"],
      ]);
    }

    publish(faList, callback = null) {
      //console.log('CallId brfore Publish : ', this.callId);
      const extraHeaders = [
        `Contact: ${this.contact}`,
        `Expires: ${this.requestType === "affiliate" ? this.expires : 0}`,
        "Event: presence",
        "Content-Type: multipart/mixed; boundary=boundary",
        `P-Preferred-Service: urn:urn-7:3gpp-service.ims.icsi.mcptt`,
        `P-Preferred-Identity: <${this.UA._registrator._from_uri}>`,
      ];
      const fromURI = this.UA._registrator._from_uri;

      var body = "";
      if (this.requestType === "affiliate" || this.requestType === "deaffiliate") {
        body = this.getAffiliationBody(faList.groups);
      } else {
        return;
      }
      const options = {
        to_uri: this.ruri,
        from_uri: fromURI,
        call_id: Utils.createRandomToken(22),
        cseq: this.cseq,
        from_tag: "",
      };

      const request = new OutgoingRequest(
        "PUBLISH",
        this.ruri,
        this.UA,
        options,
        extraHeaders,
        body
      );

      const eventHandlers = {
        onReceiveResponse: (response) => {
          //this.subscribeResponseHandler(response);
          console.log('Publish Response :');
          if (response && response.status_code == 404) {
            console.log('Publish Response :', response.status_code);
            if (callback) {
              callback(null);
            }
            return;
          }
          if (callback) {
            callback(response);
          }
        },
        onRequestTimeout: () => {
          console.log("Publish onRequestTimeout");
          if (callback) {
            callback(null);
          }
        },
        onTransportError: () => {
          console.log("Publish onTransportError");
          if (callback) {
            callback(null);
          }
        },
      };

      const requestSender = new RequestSender(this.UA, request, eventHandlers);
      requestSender.send();
    }

    getAffiliationBody(list) {
      var info = mcpttInfo;
      var pidf = PIDFG;

      info["mcptt-Params"][
        "mcptt-request-uri"
      ].mcpttURI = this.UA.mcptt_uri.includes("sip")
          ? `${this.UA.mcptt_uri}`
          : `sip:${this.UA.mcptt_uri}`;

      var groupElements = [];

      list.forEach((element, i) => {
        groupElements[i] = {
          "@": {
            group: element.includes("sip") ? `${element}` : `sip:${element}`,
          },
          "#": "",
        };
      });
      pidf["@"].entity = this.UA.mcptt_uri.includes("sip")
        ? `${this.UA.mcptt_uri}`
        : `sip:${this.UA.mcptt_uri}`;
      pidf.tuple["@"].id = this.instanceId;
      pidf.tuple.status["mcpttPI10:affiliation"] = groupElements;
      const body = `--boundary\r\nContent-Type: application/vnd.3gpp.mcptt-info+xml\r\n\r\n${js2xmlparser.parse(
        "mcpttinfo",
        info
      )}\r\n\r\n--boundary\r\nContent-Type: application/pidf+xml\r\n\r\n${js2xmlparser.parse(
        "presence",
        pidf
      )}\r\n--boundary--`;
      return body;
    }

    publishFA(faData, callback = null) {
      //console.log('CallId brfore FA Publish : ', this.callId, this.requestType);
      const extraHeaders = [
        `Contact: ${this.contact}`,
        `Expires: ${this.requestType === "activate" ? this.expires : 0}`,
        "Event: presence",
        "Content-Type: multipart/mixed; boundary=boundary",
        `P-Preferred-Service: urn:urn-7:3gpp-service.ims.icsi.mcptt`,
        `P-Preferred-Identity: <${this.UA._registrator._from_uri}>`,
      ];
      const fromURI = this.UA._registrator._from_uri;
      let body = this.getFAActivationBody(faData);
      const options = {
        to_uri: this.ruri,
        from_uri: fromURI,
        call_id: Utils.createRandomToken(22),
        cseq: this.cseq,
        from_tag: "",
      };

      const request = new OutgoingRequest(
        "PUBLISH",
        this.ruri,
        this.UA,
        options,
        extraHeaders,
        body
      );

      const eventHandlers = {
        onReceiveResponse: (response) => {
          //this.subscribeResponseHandler(response);
          console.log('PublishFA Response :');
          if (response && response.status_code == 404) {
            console.log('PublishFA Response :', response.status_code);
            if (callback) {
              callback(null);
            }
            return;
          }
          if (callback) {
            callback(response);
          }
        },
        onRequestTimeout: () => {
          console.log("publishFA onRequestTimeout");
          if (callback) {
            callback(null);
          }
        },
        onTransportError: () => {
          console.log("publishFA onTransportError");
          if (callback) {
            callback(null);
          }
        }
      };

      const requestSender = new RequestSender(this.UA, request, eventHandlers);
      requestSender.send();
    }

    getFAActivationBody(faData) {
      var info = mcpttInfo;
      var pidf = PIDF;
      info["mcptt-Params"][
        "mcptt-request-uri"
      ].mcpttURI = faData.mcpttId.includes("sip")
          ? `${faData.mcpttId}`
          : `sip:${faData.mcpttId}`;
      var faElements = [];
      faData.fas.forEach((element, i) => {
        faElements[i] = {
          "@": {
            functionalAliasID: element,
          },
          "#": "",
        };
      });
      pidf["@"].entity = faData.mcpttId.includes("sip")
        ? `${faData.mcpttId}`
        : `sip:${faData.mcpttId}`;
      pidf.tuple["@"].id = this.instanceId;
      pidf.tuple.status["mcpttPIFA10:functionalAlias"] = faElements;
      const body = `--boundary\r\nContent-Type: application/vnd.3gpp.mcptt-info+xml\r\n\r\n${js2xmlparser.parse(
        "mcpttinfo",
        info
      )}\r\n\r\n--boundary\r\nContent-Type: application/pidf+xml\r\n\r\n${js2xmlparser.parse(
        "presence",
        pidf
      )}\r\n--boundary--`;
      return body;
    }
  }

  const mcpttInfo = {
    "mcptt-Params": {
      "mcptt-request-uri": {
        "@": {
          type: "Normal",
        },
        mcpttURI: "",
      },
    },
  };

  const mcpttPIFA = {
    "@": {
      functionalAliasID: "",
    },
    "#": "",
  };

  const PIDF = {
    "@": {
      entity: "",
    },
    "mcpttPIFA10:p-id-fa": "12345678",
    tuple: {
      "@": {
        id: "",
      },
      status: {
        "mcpttPIFA10:functionalAlias": mcpttPIFA,
      },
    },
  };

  const mcpttPIFAG = {
    "@": {
      group: "",
    },
    "#": "",
  };

  const PIDFG = {
    "@": {
      xmlns: "urn:ietf:params:xml:ns:pidf",
      entity: "",
      "xmlns:mcpttPI10": "urn:3gpp:ns:mcpttPresInfo:1.0",
    },
    "mcpttPI10:p-id": "PRSID_50E274420E7E5575AF52",
    tuple: {
      "@": {
        id: "",
      },
      status: {
        "mcpttPI10:affiliation": mcpttPIFAG,
      },
    },
  };

  exports.FAPublisher = FAPublisher;
})(typeof exports === "undefined" ? (this["FAPublisher"] = {}) : exports);
