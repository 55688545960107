(function (exports) {
  const {
    decodeWithType,
    encode,
    decode,
  } = require("./encodedecode");

  const config = {
    SdsMessageType: {
      msgType: {
        __type: "UInt8",
      },
    },

    InReplyTo: {
      iei: {
        __type: "UInt8",
      },
      messageId: {
        __size: 16,
        __type: "String",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        if (data && !data.hasOwnProperty("iei")) {
          data.iei = 33;
        } else {
          data.iei = 33;
        }
      },
    },

    ApplicationId: {
      iei: {
        __type: "UInt8",
      },
      value: {
        __type: "UInt8",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        if (!data.hasOwnProperty("iei")) {
          data.iei = 34;
        } else {
          data.iei = 34;
        }
      },
    },

    SDSDispositionRequest: {
      bitField: {
        __type: "bit_field",
        __size: 1,
        __fields: {
          iei: {
            __offset: 4,
            __size: 4,
          },
          value: {
            __offset: 0,
            __size: 4,
          },
        },
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        if (!data.hasOwnProperty("bitField")) {
          data.bitField = {};
        }
        if (!data.bitField.hasOwnProperty("iei")) {
          data.bitField.iei = 8;
        } else {
          data.bitField.iei = 8;
        }
        if (!data.bitField.hasOwnProperty("value")) {
          data.bitField.value = 1;
        }
      },
    },

    // "ExtendedApplicationId": {
    //     "iei": {
    //         "__type": "UInt8"
    //     },
    //     "length": {
    //         "__type": "UInt16"
    //     },
    //     "value": {
    //         "__type": "vstring",
    //         "__sizeField": "length"
    //     },
    //     "__preEncodeHandler": function (masterConfig, config, data) {
    //         //console.log("Rtp header pre", data)
    //         if (!data.hasOwnProperty('iei')) {
    //             data.iei = 125
    //         } else {
    //             data.iei = 125;
    //         }
    //         if (data.hasOwnProperty('value'))
    //             data.length = data.value.length
    //         else
    //             data.length = 0
    //     }

    // },

    SenderMCDataId: {
      iei: {
        __type: "UInt8",
      },
      length: {
        __type: "UInt16",
      },
      value: {
        __type: "vstring",
        __sizeField: "length",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        if (!data.hasOwnProperty("iei")) {
          data.iei = 81;
        } else {
          data.iei = 81;
        }
        if (data.hasOwnProperty("value")) data.length = data.value.length;
        else data.length = 0;
      },
    },

    ContentType: {
      ctype: {
        __type: "UInt8",
      },
      data: {
        __type: "vstring",
      },
    },

    Payload: {
      iei: {
        __type: "UInt8",
      },
      length: {
        __type: "UInt16",
      },
      ctype: {
        __type: "UInt8",
      },
      value: {
        __type: "vstring",
        __sizeField: "length",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        if (!data.hasOwnProperty("iei")) {
          data.iei = 120;
        } else {
          data.iei = 120;
        }
        if (data.hasOwnProperty("value")) data.length = data.value.length + 1;
        else data.length = 0;
      },
    },

    SdsSignallingPayload: {
      type: {
        __type: "SdsMessageType",
      },
      dateTime: {
        __type: "UTC",
      },
      conversationId: {
        __size: 16,
        __type: "String",
      },
      messageId: {
        __size: 16,
        __type: "String",
      },
      __unorderedSet: {
        InReplyTo: {
          __type: "InReplyTo",
        },
        ApplicationId: {
          __type: "ApplicationId",
        },
        SDSDispositionRequest: {
          __type: "SDSDispositionRequest",
        },
        ExtendedApplicationId: {
          __type: "ExtendedApplicationId",
        },
        SenderMCDataId: {
          __type: "SenderMCDataId",
        },
        __discriminator: function (masterConfig, buffer) {
          let msgType = parseInt(buffer[0]);
          let x = msgType >> 4;
          if (x == 8) {
            return "SDSDispositionRequest";
          }
          switch (msgType) {
            case 33:
              return "InReplyTo";
            case 34:
              return "ApplicationId";
            case 125:
              return "ExtendedApplicationId";
            case 81:
              return "SenderMCDataId";
          }
          return null;
        },
      },

      __preEncodeHandler: function (masterConfig, config, data) {
        if (!data.hasOwnProperty("type")) {
          data.type = {};
          if (!data.type.hasOwnProperty("msgType")) data.type.msgType = 1;
        }
      },
    },

    SdsDataPayload: {
      type: {
        __type: "SdsMessageType",
      },
      numberOfPayloads: {
        __size: 1,
        __type: "UInt8",
      },
      __unorderedSet: {
        Payload: {
          __type: "Payload",
        },
        __discriminator: function (masterConfig, buffer) {
          let msgType = parseInt(buffer[0]);
          switch (msgType) {
            case 120:
              return "Payload";
          }
          return null;
        },
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        if (!data.hasOwnProperty("type")) {
          data.type = {};
          if (!data.type.hasOwnProperty("msgType")) data.type.msgType = 3;
        }
      },
    },

    SdsNotificationMsg: {
      "type": {
        "__type": "SdsMessageType",
      },
      "SdsNotificationType": {
        "__type": "UInt8"
      },
      "dateTime": {
        "__type": "UTC"
      },
      "conversationId": {
        "__size": 16,
        "__type": "String"
      },
      "messageId": {
        "__size": 16,
        "__type": "String"
      },
      "__unorderedSet": {

        "ApplicationId": {
          "__type": "ApplicationId"
        },

        "ExtendedApplicationId": {
          "__type": "ExtendedApplicationId"
        },
        "SenderMCDataId": {
          "__type": "SenderMCDataId"
        },
        "__discriminator": function (masterConfig, buffer) {
          let msgType = parseInt(buffer[0])

          switch (msgType) {

            case 34:
              return "ApplicationId";
            case 125:
              return "ExtendedApplicationId";
            case 81:
              return "SenderMCDataId";

          }
          return null;
        }
      },
      "__preEncodeHandler": function (masterConfig, config, data) {
        if (!data.hasOwnProperty('type')) {
          data.type = {}
          if (!data.type.hasOwnProperty('msgType'))
            data.type.msgType = 5
        }
      }

    },

    __discriminator: function (masterConfig, buffer, encodeDecode) {
      let msgTypeObj = decodeWithType(
        masterConfig,
        "SdsMessageType",
        buffer,
        0
      );
      let msgType = msgTypeObj.data.msgType;
      switch (msgType) {
        case 1:
        case 81:
          return "SdsSignallingPayload";
        case 3:
          return "SdsDataPayload";
        case 5:
          return "SdsNotificationMsg";
        default:
      }
    },
  };

  exports.config = config;
  exports.encode = encode;
  exports.decode = decode;
})(typeof exports === "undefined" ? (this["SdsConfig"] = {}) : exports);

/*let buffer = encode(config, "SdsSignallingPayload", {
      "dateTime": 1666341630,
      "conversationId": "1111111111111111",
      "messageId": "2222222222222222",
      "SenderMCDataId": {
          "value": "6001-consort"
      },
      "SDSDispositionRequest": {
          "bitField": {
              "value": 3
          }
      }
      // "numberOfPayloads": 22,
      // "Payload": {
      //     "value": "abc",
      //     "ctype": 5
      // },
  
  });
  console.log("buffer received", buffer);
  let converted = decode(config, buffer);
  console.log("converted", JSON.stringify(converted));*/
