import * as React from "react";

export const VideoAudioPlayerView = (props) => {
    const { msgType, fileId, msgText } = props

    // const getDownloadLink = () => {
    //     let filePath = `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/uploads/`
    //     if (msgType == 'file') {
    //         const docs = [
    //             { uri: filePath + msgText }, // Remote file
    //         ];
    //         return docs;
    //     }
    //     return filePath + msgText;
    // }

    const getDownloadLink = () => {
        let filePath = `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/uploads/`
        return filePath + msgText;
    }

    let streamUrl = getDownloadLink()
    console.log('btn click..downloadUrl..', streamUrl);

    return (
        <div className="player-view">
            {
                msgType === 'image' ?
                    (
                        <div class="box box1"><img src={streamUrl} /></div>
                    ) :
                    msgType === 'video' ?
                        (
                            <video controls height={'90%'} width={'90%'}>
                                <source src={streamUrl} />
                            </video>
                        ) :
                        msgType === 'audio' ?
                            (
                                <audio controls>
                                    <source src={streamUrl} />
                                </audio>
                            ) :
                            (
                                // <DocViewer documents={streamUrl} pluginRenderers={DocViewerRenderers} 
                                // config={{
                                //     header: {
                                //         'Access-Control-Allow-Origin': '*'
                                //     }}}/>

                                null
                                
                            )

            }
        </div>
    )
}

export default VideoAudioPlayerView