(function (exports) {
  const DOMParser = require("xmldom").DOMParser;
  const js2xmlparser = require("js2xmlparser");
  const Store = require("../Store").Store;
  const parser = new DOMParser();
  const utf8 = require("utf8");

  class Delegates {
    constructor() {
      this.store = new Store();
    }

    getCallAction(message) {
      let obj = {};
      let xml1 = parser.parseFromString(message, "text/xml");
      let xml = xml1.getElementsByTagName("mcdataParams").item(0);
      if (
        xml.getElementsByTagName("sds-type").item(0).textContent ===
        "DGNA_CALL"
      ) {
        obj.name = xml.getElementsByTagName("message-id").item(0).textContent;
        obj.dgnaGroupId = xml
          .getElementsByTagName("mcdata-calling-group-id")
          .item(0).textContent;
        obj.stateType = xml
          .getElementsByTagName("state-type")
          .item(0).textContent;
        obj.callType = xml
          .getElementsByTagName("sds-type")
          .item(0).textContent;
        obj.callActionType = xml
          .getElementsByTagName("payload")
          .item(0).textContent;
        obj.communicationType = "CALL_ACTION";
        obj.fromId = xml
          .getElementsByTagName("mcdata-called-party-id")
          .item(0).textContent;

        return obj;

      } else {
        try {
          obj.callId = xml
            .getElementsByTagName("call-id")
            .item(0).textContent;

          // <request-type>CALL_ACTION</request-type>
          // <mcdata-request-uri>Operation@mcx.consort.org</mcdata-request-uri>
          // <mcdata-calling-user-id>sip:mcx.cons-af6f373.consort@consort.org</mcdata-calling-user-id> caller fromId
          // <mcdata-called-party-id>6005-consort</mcdata-called-party-id> callee toId
          // <mcdata-calling-group-id>Operation@mcx.consort.org</mcdata-calling-group-id> group id
          obj.fromId = xml.getElementsByTagName("mcdata-calling-user-id")
            ? xml.getElementsByTagName("mcdata-calling-user-id").item(0)
              .textContent
            : "";
          obj.fromId = obj.fromId.replace("sip:", "");
          obj.toId = xml.getElementsByTagName("mcdata-called-party-id")
            ? xml.getElementsByTagName("mcdata-called-party-id").item(0)
              .textContent
            : "";
          obj.toId = obj.toId.replace("sip:", "");
          obj.groupId = xml.getElementsByTagName("mcdata-calling-group-id")
            ? xml.getElementsByTagName("mcdata-calling-group-id").item(0)
              .textContent
            : "";
          obj.groupId = obj.groupId.replace("sip:", "");

          try {
            obj.preEstSessionUri = xml.getElementsByTagName("session-uri")
              ? xml.getElementsByTagName("session-uri").item(0).textContent
              : "";
            if (obj.preEstSessionUri) {
              obj.preEstSessionUri = obj.preEstSessionUri.replace("sip:", "");
            }
            //<reason-cause>6</reason-cause>
            obj.reasonCause = xml.getElementsByTagName("reason-cause")
            ? xml.getElementsByTagName("reason-cause").item(0).textContent
            : "";
          } catch (error) {
            //console.log("preEstSessionUri not found", error);
          }

          //fromId = fromId.includes('sip') ? fromId.replace('sip') : fromId
          let talkingPartyId = xml
            .getElementsByTagName("talking-party")
            .item(0).textContent;
          talkingPartyId = talkingPartyId.includes("sip:")
            ? talkingPartyId.replace("sip:", "")
            : talkingPartyId;
          obj.talkingPartyId = talkingPartyId;
          obj.communicationType = xml
            .getElementsByTagName("request-type")
            .item(0).textContent;
          obj.callActionType = xml
            .getElementsByTagName("sds-type")
            .item(0).textContent;
          obj.callType = this.getElementsByTag(xml, "call-type");

          let callPriority = this.getElementsByTag(xml, "call-priority");
          let isEmergency = this.getElementsByTag(xml, "emergency");
          if (isEmergency && isEmergency != "" && isEmergency != "false") {
            callPriority = 15;
          }
          if (callPriority && callPriority != "") {
            obj.callPriority = callPriority;
          }

          if (obj.callActionType === "DISCONNECTED") {
            obj.communicationType = "CALL";
            obj.stateType = "COMPLETED";
          }
        } catch (err) {
          console.log("getCallAction 2", err);
          return null;
        }
        return obj;
      }
    }

    getElementsByTag(xml, tagName) {
      let tagValue = "";
      try {
        tagValue = xml.getElementsByTagName(tagName).item(0).textContent;
      } catch (err) { }
      //console.log("getElementsByTag", tagName, tagValue);
      return tagValue;
    }
    storeMessage(
      message,
      sender,
      reciever,
      messageId,
      sdsType,
      groupId,
      isIncomMsg,
      fileId = "",
      messageType = "text"
    ) {
      let sds = {
        immediate: true,
        consumedReportNeeded: true,
        deliveryReportNeeded: false,
        message: message,
        messageId: messageId,
        messageType: messageType,
        fileId: fileId,
        fileType: "",
        view: false,
        creatorId: isIncomMsg ? reciever : sender,
        communicationType: "SDS",
        sdsType: sdsType,
        fromId: sender,
        toId: reciever,
        indexId: 1,
        groupId: groupId,
      };
      console.log("storing sds message..", sds);
      this.store.addSDS(sds);
      return sds;
    }

    getSDS(message, id, creatorId = null) {
      let obj = {};
      let xml1 = parser.parseFromString(message, "text/xml");
      let xml = xml1.getElementsByTagName("mcdataParams").item(0);
      //console.log("SDS Msg message getSDS xml", xml);
      try {
        obj.communicationType = xml
          .getElementsByTagName("request-type")
          .item(0).textContent;
        obj.sdsType = xml
          .getElementsByTagName("sds-type")
          .item(0).textContent;
        obj.fromId = xml
          .getElementsByTagName("mcdata-calling-user-id")
          .item(0).textContent;
        obj.toId = creatorId
          ? creatorId
          : xml.getElementsByTagName("mcdata-called-party-id").item(0)
            .textContent;
        obj.indexId = xml
          .getElementsByTagName("message-id")
          .item(0).textContent;
        //obj.persistentId = xml.getElementsByTagName('persistent-id').item(0).textContent;
        if (obj.communicationType === "SDS") {
          switch (obj.sdsType) {
            case "TEXT_MESSAGE":
              obj.immediate = xml
                .getElementsByTagName("immediate")
                .item(0).textContent;
              obj.consumedReportNeeded = xml
                .getElementsByTagName("consumed-report")
                .item(0).textContent;
              obj.deliveryReportNeeded = xml
                .getElementsByTagName("delivery-report")
                .item(0).textContent;
              obj.message = utf8.decode(
                xml.getElementsByTagName("payload").item(0).textContent
              );
              obj.messageId = id;
              obj.creatorId = creatorId
                ? creatorId
                : xml.getElementsByTagName("mcdata-called-party-id").item(0)
                  .textContent;
              obj.messageType = xml
                .getElementsByTagName("messageType")
                .item(0).textContent;
              obj.fileId = xml
                .getElementsByTagName("fileId")
                .item(0).textContent;
              obj.fileType = xml
                .getElementsByTagName("fileType")
                .item(0).textContent;
              obj.view = false;
              this.store.addSDS(obj);
              break;
            case "GROUP_TEXT_MESSAGE":
              obj.immediate = xml
                .getElementsByTagName("immediate")
                .item(0).textContent;
              obj.consumedReportNeeded = xml
                .getElementsByTagName("consumed-report")
                .item(0).textContent;
              obj.deliveryReportNeeded = xml
                .getElementsByTagName("delivery-report")
                .item(0).textContent;
              obj.message = utf8.decode(
                xml.getElementsByTagName("payload").item(0).textContent
              );
              obj.groupId = xml
                .getElementsByTagName("mcdata-calling-group-id")
                .item(0).textContent;
              obj.messageType = xml
                .getElementsByTagName("messageType")
                .item(0).textContent;
              obj.fileType = xml
                .getElementsByTagName("fileType")
                .item(0).textContent;
              obj.fileId = xml
                .getElementsByTagName("fileId")
                .item(0).textContent;

              obj.creatorId = creatorId
                ? creatorId
                : xml.getElementsByTagName("mcdata-called-party-id").item(0)
                  .textContent;

              obj.view = false;
              obj.messageId = id;

              this.store.addSDS(obj);
              break;
            case "STATUS_MESSAGE":
              obj.tetraCode = xml
                .getElementsByTagName("tetra-code")
                .item(0).textContent;
              // Alert(user status)message save on message table
              obj.immediate = true;
              obj.consumedReportNeeded = false;
              obj.deliveryReportNeeded = false;
              obj.message = utf8.decode(
                xml.getElementsByTagName("tetra-code").item(0).textContent
              );
              obj.messageId = id;
              obj.creatorId = creatorId
                ? creatorId
                : xml.getElementsByTagName("mcdata-called-party-id").item(0)
                  .textContent;
              obj.messageType = "STATUS";
              obj.fileId = "";
              obj.fileType = "";
              obj.view = false;
              // if (obj.tetraCode !== '65500') {
              //   this.store.addSDS(obj);
              // }
              break;
            case "GROUP_STATUS_MESSAGE":
              obj.tetraCode = xml
                .getElementsByTagName("tetra-code")
                .item(0).textContent;
              obj.groupId = xml
                .getElementsByTagName("mcdata-calling-group-id")
                .item(0).textContent;

              // Alert(user status)message save on message table
              obj.immediate = true;
              obj.consumedReportNeeded = false;
              obj.deliveryReportNeeded = false;
              obj.message = utf8.decode(
                xml.getElementsByTagName("tetra-code").item(0).textContent
              );
              obj.messageId = id;
              obj.creatorId = creatorId
                ? creatorId
                : xml.getElementsByTagName("mcdata-called-party-id").item(0)
                  .textContent;
              obj.messageType = "STATUS";
              obj.fileId = "";
              obj.fileType = "";
              obj.view = false;
              // if (obj.tetraCode !== '65500') {
              //   this.store.addSDS(obj);
              // }
              break;
            case "LOCATION":
              obj.message = JSON.parse(
                xml.getElementsByTagName("payload").item(0).textContent
              );
              obj.ueID = xml.getElementsByTagName("ueID").item(0)
                ? xml.getElementsByTagName("ueID").item(0).textContent
                : "0000";
              obj.locUpdateTimer = xml
                .getElementsByTagName("locUpdateTimer")
                .item(0)
                ? xml.getElementsByTagName("locUpdateTimer").item(0)
                  .textContent
                : "30";
              obj.ueType = xml.getElementsByTagName("ueType").item(0)
                ? xml.getElementsByTagName("ueType").item(0).textContent
                : "";
              obj.userType = xml.getElementsByTagName("userType").item(0)
                ? xml.getElementsByTagName("userType").item(0).textContent
                : "";
              obj.activeFA = xml.getElementsByTagName("activeFA").item(0)
                ? JSON.parse(
                  xml.getElementsByTagName("activeFA").item(0).textContent
                )
                : [];
              break;
            default:
              break;
          }
        } else if (obj.communicationType === "STATE_UPDATE") {
          obj.stateType = xml
            .getElementsByTagName("state-type")
            .item(0).textContent;
          //console.log('SDS msg State..',obj)
        } else if (obj.communicationType === "CALL") {
          obj.callType = xml
            .getElementsByTagName("sds-type")
            .item(0).textContent;
          switch (obj.callType) {
            case "CAD_CALL":
              obj.callId1 = xml
                .getElementsByTagName("mcdata-called-party-id-1")
                .item(0).textContent;
              obj.callId2 = xml
                .getElementsByTagName("mcdata-called-party-id-2")
                .item(0).textContent;
              //obj.stateType = xml.getElementsByTagName('state-type').item(0).textContent;
              obj.requestId = obj.indexId;
              //console.log("CAD CALL OBJECT Creation", obj);
              obj.cadDisconnected = xml
                .getElementsByTagName("cad-disconnect")
                .item(0) ? xml
                  .getElementsByTagName("cad-disconnect")
                  .item(0).textContent : null;
              break;
            case "PATCH_CALL":
              obj.callId1 = xml
                .getElementsByTagName("mcdata-called-party-id-1")
                .item(0).textContent;
              obj.callId2 = xml
                .getElementsByTagName("mcdata-called-party-id-2")
                .item(0).textContent;
              obj.stateType = xml
                .getElementsByTagName("state-type")
                .item(0).textContent;
              obj.requestId = obj.messageId;
              break;
            case "MERGE_CALL":
              obj.callId1 = xml
                .getElementsByTagName("mcdata-called-party-id-1")
                .item(0).textContent;
              obj.callId2 = xml
                .getElementsByTagName("mcdata-called-party-id-2")
                .item(0).textContent;
              obj.stateType = xml
                .getElementsByTagName("state-type")
                .item(0).textContent;
              obj.requestId = obj.messageId;
              break;
            case "DGNA":
              break;
            default:
              break;
          }
        } else if (obj.communicationType === "CALL_ACTION") {
          obj.callType = xml
            .getElementsByTagName("sds-type")
            .item(0).textContent;
          switch (obj.callType) {
            case "CAD_CALL":
              //obj.callId1 = xml.getElementsByTagName('mcdata-called-party-id-1').item(0).textContent
              //obj.callId2 = xml.getElementsByTagName('mcdata-called-party-id-2').item(0).textContent
              //obj.stateType = xml.getElementsByTagName('state-type').item(0).textContent;
              obj.requestId = obj.indexId;
              obj.stateType = xml
                .getElementsByTagName("state-type")
                .item(0).textContent;
              console.log("CAD CALL OBJECT Creation", obj);
              break;
            case "PATCH_CALL":
              obj.callId1 = xml
                .getElementsByTagName("mcdata-called-party-id-1")
                .item(0).textContent;
              obj.callId2 = xml
                .getElementsByTagName("mcdata-called-party-id-2")
                .item(0).textContent;
              obj.stateType = xml
                .getElementsByTagName("state-type")
                .item(0).textContent;
              obj.requestId = obj.messageId;
              break;
            case "MERGE_CALL":
              obj.callId1 = xml
                .getElementsByTagName("mcdata-called-party-id-1")
                .item(0).textContent;
              obj.callId2 = xml
                .getElementsByTagName("mcdata-called-party-id-2")
                .item(0).textContent;
              obj.stateType = xml
                .getElementsByTagName("state-type")
                .item(0).textContent;
              obj.requestId = obj.messageId;
              break;
            case "DGNA_CALL":
              obj.name = xml
                .getElementsByTagName("message-id")
                .item(0).textContent;
              obj.dgnaGroupId = xml
                .getElementsByTagName("mcdata-calling-group-id")
                .item(0).textContent;
              obj.stateType = xml
                .getElementsByTagName("state-type")
                .item(0).textContent;
              obj.callType = "DGNA_CREATION_STATE";
              break;
            default:
              break;
          }
        }
      } catch (err) {
        console.log("getSds() error ", err);
        return null;
      }
      return obj;
    }

    updateSDS(message) {
      this.store.updateSDS(message);
    }

    createXml(data) {
      let object = sds;
      let objdata = {};
      //console.log('SDS msg createXML..',data)
      object.mcdataInfo.mcdataParams["request-type"] = data.communicationType;
      object.mcdataInfo.mcdataParams[
        "mcdata-called-party-id"
      ] = `${data.toId}`;
      object.mcdataInfo.mcdataParams["mcdata-calling-user-id"] = data.fromId;
      object.mcdataInfo.mcdataParams["mcdata-request-uri"] = `${data.toId}`;
      object.mcdataInfo.mcdataParams["mcdata-client-id"] = data.toId;
      object.mcdataInfo.mcdataParams["sds-type"] = data.sdsType
        ? data.sdsType
        : "";
      object.mcdataInfo.mcdataParams["message-id"] = data.indexId
        ? data.indexId
        : data.requestId;
      if (data.communicationType === "SDS") {
        switch (data.sdsType) {
          case "TEXT_MESSAGE":
            object.mcdataInfo.mcdataParams.payload = utf8.encode(
              data.message
            );
            object.mcdataInfo.mcdataParams["immediate"] = data.immediate;
            object.mcdataInfo.mcdataParams["consumed-report"] =
              data.consumedReportNeeded;
            object.mcdataInfo.mcdataParams["delivery-report"] =
              data.deliveryReportNeeded;
            object.mcdataInfo.mcdataParams["messageType"] = data.messageType
              ? data.messageType
              : "text";
            object.mcdataInfo.mcdataParams["fileType"] = data.fileType
              ? data.fileType
              : "none";
            object.mcdataInfo.mcdataParams["fileId"] = data.fileId
              ? data.fileId
              : "";
            this.store.addSDS(data);
            break;
          case "GROUP_TEXT_MESSAGE":
            object.mcdataInfo.mcdataParams.payload = utf8.encode(
              data.message
            );
            object.mcdataInfo.mcdataParams["immediate"] = data.immediate;
            object.mcdataInfo.mcdataParams["consumed-report"] =
              data.consumedReportNeeded;
            object.mcdataInfo.mcdataParams["messageType"] = data.messageType
              ? data.messageType
              : "text";
            object.mcdataInfo.mcdataParams["fileType"] = data.fileType
              ? data.fileType
              : "none";
            object.mcdataInfo.mcdataParams["fileId"] = data.fileId
              ? data.fileId
              : "";
            object.mcdataInfo.mcdataParams["delivery-report"] =
              data.deliveryReportNeeded;
            object.mcdataInfo.mcdataParams[
              "mcdata-calling-group-id"
            ] = `${data.groupId}`;
            this.store.addSDS(data);
            break;
          case "STATUS_MESSAGE":
            object.mcdataInfo.mcdataParams["tetra-code"] = data.tetraCode;

            // Alert(user status)message save on message table

            objdata.communicationType = data.communicationType;
            objdata.sdsType = data.sdsType ? data.sdsType : "";
            objdata.fromId = data.fromId;
            objdata.toId = data.toId;
            objdata.indexId = data.indexId;

            objdata.immediate = true;
            objdata.consumedReportNeeded = false;
            objdata.deliveryReportNeeded = false;
            objdata.message = utf8.decode(data.tetraCode + "");
            objdata.messageId = data.messageId;
            objdata.creatorId = data.fromId;
            objdata.messageType = "STATUS";
            objdata.fileId = "";
            objdata.fileType = "";
            objdata.view = false;
            if (data.tetraCode !== "65500") {
              this.store.addSDS(objdata);
            }
            break;
          case "GROUP_STATUS_MESSAGE":
            object.mcdataInfo.mcdataParams["tetra-code"] = data.tetraCode;
            object.mcdataInfo.mcdataParams[
              "mcdata-calling-group-id"
            ] = `${data.groupId}`;

            // Alert(user status)message save on message table

            objdata.communicationType = data.communicationType;
            objdata.sdsType = data.sdsType ? data.sdsType : "";
            objdata.fromId = data.fromId;
            objdata.toId = data.toId;
            objdata.indexId = data.indexId;

            objdata.immediate = true;
            objdata.consumedReportNeeded = false;
            objdata.deliveryReportNeeded = false;
            objdata.message = utf8.decode(data.tetraCode + "");
            objdata.messageId = data.messageId;
            objdata.creatorId = data.fromId;
            objdata.messageType = "STATUS";
            objdata.fileId = "";
            objdata.fileType = "";
            objdata.view = false;
            objdata.groupId = `${data.groupId}`;
            //console.log('objdata send....', objdata, data.tetraCode)
            if (data.tetraCode !== "65500") {
              this.store.addSDS(objdata);
            }
            break;
          case "STATE_UPDATE":
            object.mcdataInfo.mcdataParams["persistent-id"] =
              data.persistentId;
            object.mcdataInfo.mcdataParams["state-type"] = data.stateType;
            this.store.updateSDS(data);
            break;
          case "LOCATION":
            // console.log("SDS sendSDS createXml ", data);
            object.mcdataInfo.mcdataParams["indexId"] = data.indexId;
            object.mcdataInfo.mcdataParams.payload = JSON.stringify(
              data.lipData
            );
            object.mcdataInfo.mcdataParams.lipData = JSON.stringify(
              data.lipData
            );
            object.mcdataInfo.mcdataParams["ueID"] = data.impu
              ? data.impu
              : "0000";
            object.mcdataInfo.mcdataParams[
              "locUpdateTimer"
            ] = data.locationSec ? data.locationSec : "30";
            object.mcdataInfo.mcdataParams["ueType"] = data.ueType
              ? data.ueType
              : "";
            object.mcdataInfo.mcdataParams["userType"] = data.userType
              ? data.userType
              : "";
            object.mcdataInfo.mcdataParams["activeFA"] = data.activeFA
              ? JSON.stringify(data.activeFA)
              : JSON.stringify([]);
            break;
          case "":
            break;

          default:
            break;
        }
      } else if (data.communicationType === "STATE_UPDATE") {
        object.mcdataInfo.mcdataParams["persistent-id"] = data.persistentId;
        object.mcdataInfo.mcdataParams["state-type"] = data.stateType;
        this.store.updateSDS(data);
      } else if (data.communicationType === "CALL_ACTION") {
        object.mcdataInfo.mcdataParams["request-type"] =
          data.communicationType;
        object.mcdataInfo.mcdataParams[
          "mcdata-called-party-id"
        ] = `${data.toId}`;
        object.mcdataInfo.mcdataParams["mcdata-calling-user-id"] =
          data.fromId;
        object.mcdataInfo.mcdataParams["mcdata-request-uri"] = `${data.toId}`;
        object.mcdataInfo.mcdataParams["sds-type"] = data.callActionType;
        object.mcdataInfo.mcdataParams["call-id"] = data.callId;
      } else if (data.communicationType === "CALL") {
        if (data.callType === "CAD_CALL" || data.callType === "PATCH_CALL" || data.callType === "MERGE_CALL") {
          object.mcdataInfo.mcdataParams["mcdata-called-party-id-1"] =
            data.callId1;
          object.mcdataInfo.mcdataParams["mcdata-called-party-id-2"] =
            data.callId2;
          object.mcdataInfo.mcdataParams["sds-type"] = data.callType;
          object.mcdataInfo.mcdataParams["state-type"] = data.stateType;
          let callData = {
            callId: data.requestId ? data.requestId : 'abcd',
            indexId: data.requestId ? data.requestId : 'abcd',
            toId: data.toId,
            fromId: data.fromId,
            groupId: '',
            callType: data.callType,
            communicationType: data.communicationType,
            callActionType: 'CALL_STATE_UPDATE',
            callPriority: data.callPriority,
            stateType: 'PERSISTED',
            creatorId: data.creatorId ? data.creatorId : '',
            UEid: '0000',
          }
          this.store.addCall(callData);
        }
      }
      const res = js2xmlparser.parse("mcdataInfo", object);
      return res;
    }

    createPTTMessage(data) {
      let object = sds;
      object.mcdataInfo.mcdataParams["request-type"] = data.communicationType;
      object.mcdataInfo.mcdataParams[
        "mcdata-called-party-id"
      ] = `${data.toId}`;
      object.mcdataInfo.mcdataParams["mcdata-calling-user-id"] = data.fromId;
      object.mcdataInfo.mcdataParams["mcdata-request-uri"] = `${data.toId}`;
      object.mcdataInfo.mcdataParams["mcdata-client-id"] = data.toId;
      object.mcdataInfo.mcdataParams["sds-type"] = data.sdsType
        ? data.sdsType
        : "";
      object.mcdataInfo.mcdataParams["message-id"] = data.indexId
        ? data.indexId
        : data.requestId;

      object.mcdataInfo.mcdataParams["request-type"] =
        data.communicationType;
      object.mcdataInfo.mcdataParams[
        "mcdata-called-party-id"
      ] = `${data.toId}`;
      object.mcdataInfo.mcdataParams["mcdata-calling-user-id"] =
        data.fromId;
      object.mcdataInfo.mcdataParams["mcdata-request-uri"] = `${data.toId}`;
      object.mcdataInfo.mcdataParams["sds-type"] = data.callActionType;
      object.mcdataInfo.mcdataParams["call-id"] = data.callId;
      const res = js2xmlparser.parse("mcdataInfo", object);
      return res;
    }

    createCallXml(data, UA) {
      let object = call;
      object.mcdataInfo.mcdataParams["request-type"] = data.communicationType;
      object.mcdataInfo.mcdataParams[
        "mcdata-called-party-id"
      ] = `${data.toId}`;
      object.mcdataInfo.mcdataParams["mcdata-calling-user-id"] = data.fromId;
      object.mcdataInfo.mcdataParams["mcdata-request-uri"] = `${data.toId}`;
      object.mcdataInfo.mcdataParams["mcdata-client-id"] = data.toId;
      object.mcdataInfo.mcdataParams["call-type"] = data.callType;
      object.mcdataInfo.mcdataParams["call-id"] = data.indexId;
      object.mcdataInfo.mcdataParams["action-type"] = data.callActionType;
      object.mcdataInfo.mcdataParams.priority = data.callPriority;
      if (data.groupId) {
        object.mcdataInfo.mcdataParams["mcdata-calling-group-id"] =
          data.groupId;
      }
      if (
        data.callActionType === "DISCONNECTED" ||
        data.callActionType === "REJECTED"
      ) {
        // if (data.indexId && UA.releasePTTCurrentCall && UA.releasePTTCurrentCall.callId == data.indexId) {
        //   UA.releasePTTCurrentCall = null;
        // } else if (!data.indexId || !UA.releasePTTCurrentCall) {
        //   UA.releasePTTCurrentCall = null;
        // }
        UA.resetPTTCallStatus(data.indexId);
        data.communicationType = "CALL";
        if (!data.stateType || data.stateType !== "MISSED") {
          data.stateType = "COMPLETED";
        }

        if (data.fromAction && data.fromAction === true) {
        } else {
          let callObj = {
            fromId: data.fromId,
            toId: data.toId,
            communicationType: "CALL",
            stateType: data.stateType,
            callActionType: data.callActionType,
            groupId: data.groupId ? data.groupId : '',
            callId: data.callId,
            callPriority: data.callPriority,
            callType: data.callType,
            answerMode: data.answerMode,
            creatorId: data.creatorId ? data.creatorId : '',
            UEid: '0000',
          }
          this.store.addCall(callObj);
        }
      }
      const res = js2xmlparser.parse("mcdataInfo", object);
      return res;
    }
  }

  const sds = {
    mcdataInfo: {
      "@": {
        "xmlns:xs": "http://www.w3.org/2001/XMLSchema",
        targetNamespace: "urn:3gpp:ns:mcdataInfo:1.0",
        "xmlns:mcdatainfo": "urn:3gpp:ns:mcdataInfo:1.0",
        elementFormDefault: "qualified",
        attributeFormDefault: "unqualified",
        "xmlns:xenc": "http://www.w3.org/2001/04/xmlenc#",
      },
      mcdataParams: {
        "@": {},
        "request-type": "",
        "mcdata-request-uri": "",
        "mcdata-calling-user-id": "",
        "mcdata-called-party-id": "",
        "mcdata-calling-group-id": "",
        "mcdata-called-party-id1": "",
        "mcdata-called-party-id2": "",
        "mcdata-client-id": "",
        "sds-type": "",
        payload: "",
        "message-id": "",
        "conversation-id": "",
        "call-id": "",
        lipData: "",
        ueID: "",
      },
    },
  };

  const call = {
    mcdataInfo: {
      "@": {
        "xmlns:xs": "http://www.w3.org/2001/XMLSchema",
        targetNamespace: "urn:3gpp:ns:mcdataInfo:1.0",
        "xmlns:mcdatainfo": "urn:3gpp:ns:mcdataInfo:1.0",
        elementFormDefault: "qualified",
        attributeFormDefault: "unqualified",
        "xmlns:xenc": "http://www.w3.org/2001/04/xmlenc#",
      },
      mcdataParams: {
        "@": {},
        "request-type": "",
        "mcdata-request-uri": "",
        "mcdata-calling-user-id": "",
        "mcdata-called-party-id": "",
        "mcdata-calling-group-id": "",
        "mcdata-client-id": "",
        "call-type": "",
        "call-id": "",
        "action-type": "",
        priority: "",
      },
    },
  };

  exports.Delegates = Delegates;
})(typeof exports === "undefined" ? (this["Delegates"] = {}) : exports);
