import React, { useEffect, useState } from "react";
import { Input } from "semantic-ui-react";
import { makeStyles, useTheme, ThemeProvider } from "@material-ui/core/styles";
import { Typography, FormControl, InputLabel, Select, MenuItem, Box, Grid } from "@material-ui/core";
import { TextField, Checkbox, CircularProgress } from '@material-ui/core'
import { connect } from "react-redux";
import { fetchAppList, fetchAuthToken, uploadApp } from "../../../modules/actions/mdmAction";
import axios from "axios";
import { postMethod } from "../utils";
import { EndPoints } from "../../../MCXclient/endpoints";
import CircularWithValueLabel from "../../circularLoader";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const AddApp = (props) => {
    const { setModalOpen, fetchAppList, tenantId, fetchAuthToken, token, deviceTypeList, uploadApp } = props;
    const [appFile, setAppFile] = useState("");
    const [apkData, setApkData] = useState({ tenantId: tenantId });
    const [disableInput, setDisableInput] = useState(false);
    const [progress, setProgress] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [percentageCompleted, setPercentageCompleted] = useState(0)
    const [fileUploaded, setFileUploaded] = useState(false);
    const [error1, setError1] = useState('');
    const [formErrors, setFormErrors] = useState({
        name: '',
        deviceType: ''
    });
    useEffect(() => {
        fetchAuthToken()
    }, [])

    const handleLoader = (data) => {
        setShowLoader(data)
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: "#252524",
            //backgroundColor: theme.palette.background.default,
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            width: "100%",
            //height: "70%",
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
        },
        formControlFA: {
            width: "95%",
            height: "65px",
            marginTop: "2px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600,
            backgroundColor: "#383736"
        },
        listItemFA: {
            padding: "5px",
            fontFamily: "Muli",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        productTypeClass: {
            width: "90%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            border: "solid",
            borderRadius: 10,
            borderWidth: "2px",
            borderColor: "#4B4A4A",
            marginTop: 5,
            marginBottom: 10
        },
        divList: {
            width: '100%',
            marginBottom: '13px',
        },
        lblStyle: {
            width: '100%',
            color: "#D0D3D4",
            fontSize: 18,
            fontWeight: 600
        },
        tetraSwitch: {
            fontFamily: "Muli",
            marginTop: "2px",
            marginRight: "1px",
            marginLeft: "0px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        datePickerStyle: {
            color: "black",
            padding: "10px",
            fontFamily: "Sans-Serif",
            width: '60%',
            border: "solid",
            borderRadius: 20,
            borderWidth: "2px",
            borderColor: "#D3D3D3",
        }
    }));
    const classes = useStyles();
    const theme = useTheme();
    const uploadHandler = () => {
        if (!appFile) {
            setError1("Please Choose file");
            return;
        }
        else {
            setError1('');
        }
        handleLoader(true)
        let formData = new FormData();
        formData.append("application", appFile);
        const config = {
            onUploadProgress: function (progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setPercentageCompleted(percentCompleted)
            }
        };
        postMethod(EndPoints.getConfig().analyzeApp, formData, token, config).then((data) => {
            if (data) {
                setApkData({ name: appFile.name, ...(data), ...apkData })
                setFileUploaded(true);
                setDisableInput(true)
                handleLoader(false)

            }
        }).catch((err) => {
            console.log("Error in analyzing apk", err);
        })

    }

    const fileChangeHandler = (e) => {
        if (e.target.files.length) {
            setError1('')
            setAppFile(e.target.files[0])
        }
    }
    const changeHandler = (e) => {
        let data = { ...apkData }
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        data[e.target.name] = value
        setApkData({ ...data })
    }
    const onSelectHandler = (e) => {
        let data = { ...apkData }
        data[e.target.name] = e.target.value
        setApkData({ ...data })
        if (e.target.name == 'name') {
            if (e.target.value.trim()) {
                setFormErrors({ ...formErrors, name: '' })
            }
            else {
                setFormErrors({ ...formErrors, name: 'Application Name is required' })
            }

        }
        if (e.target.name == 'deviceType' && e.target.value) {
            setFormErrors({ ...formErrors, deviceType: '' })

        }
    }
    const submitHandler = () => {
        const requiredFields = ['deviceType', 'name'];
        let err = false;
        let errors = {
            name: '',
            deviceType: ''
        }
        for (let i = 0; i < 2; i++) {
            let key1 = requiredFields[i];
            if (key1 === 'name' && (!apkData[key1] || apkData[key1].trim() === '')) {
                err = true;
                errors.name = 'Application Name is required'
            }

            if (key1 === 'deviceType' && !apkData[key1]) {
                err = true;
                errors.deviceType = 'DeviceType is required'
            }
        }

        if (err) {
            setFormErrors(errors);
            return;
        }
        handleLoader(true)
        let formData = new FormData();
        formData.append("application", appFile);
        let headers = {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${token}`
        }
        formData.append("document", JSON.stringify(apkData))
        const config = {
            onUploadProgress: function (progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setPercentageCompleted(percentCompleted)
            }
        };
        postMethod(EndPoints.getConfig().uploadApp, formData, token, config)
            .then((data) => {
                if (data) {
                    handleLoader(false)
                    fetchAppList({ token: token, tenantId })
                    setModalOpen(false)
                    if (data.message == "ER_DUP_ENTRY") {
                        uploadApp({
                            header: "MDM Error",
                            content: "App already Exits",
                            type: "error",
                        })
                    } else {
                        uploadApp({
                            header: "MDM",
                            content: "App Uploaded Successfully",
                            type: "success",
                        })
                    }
                }
            }).catch((err) => {
                console.log("Error in analyzing apk", err);
                uploadApp({
                    header: "MDM Error",
                    content: "Failed to upload App",
                    type: "error",
                })
            })
    }
    return (
        <div class={classes.root}>
            <Grid container width="100%" spacing={2}>
                <Grid item xs={12}>
                    <Box className="tab1-account" width={'100%'} marginLeft={0}>
                        {showLoader && <Box style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', position: 'absolute', width: '100%', height: '100%', zIndex: 999, backgroundColor: `rgba(0,0,0,0.85)` }}>
                            <CircularWithValueLabel progress={percentageCompleted} />
                        </Box>}
                        <Box className="tab1-account" width={'100%'} marginLeft={0}>
                            <Box class="form-group" paddingX={10} paddingY={0}>
                                <Box style={{ paddingBottom: "0px", color: 'whitesmoke', fontSize: '18px', fontWeight: '500' }}>
                                    Application
                                </Box>
                                <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', marginTop: '2.5%' }}>
                                    <Input type="file" onChange={fileChangeHandler} /* style={{backgroundColor:'#383736'}} */ />
                                    {progress ? <CircularProgress /> : null}
                                    <Box style={{ flexDirection: 'row', display: 'flex' }}>
                                        <h3 className="m-2" style={{ color: "red" }}>{error1}</h3>
                                        <button className={fileUploaded ? "upload-btn-faded" : "upload-btn"} type="button" disabled={fileUploaded} onClick={uploadHandler}>Upload</button>
                                    </Box>
                                </Box>
                                {disableInput ?
                                    <Box >
                                        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                                            <TextField
                                                required
                                                id="standard-basic"
                                                label="Application Name"
                                                variant="filled"
                                                name="name"
                                                style={{ marginTop: '15px' }}

                                                onChange={onSelectHandler}
                                                value={apkData.name}
                                            />
                                            <TextField
                                                required
                                                id="standard-basic"
                                                label="Pkg Id"
                                                variant="filled"
                                                name="pkgId"
                                                style={{ marginTop: '15px' }}
                                                // onChange={changeHandler}
                                                value={apkData.pkgId}
                                            />
                                            <TextField
                                                required
                                                id="outlined-multiline-flexible"
                                                label="Version"
                                                style={{ marginTop: '15px' }}
                                                variant="filled"
                                                value={apkData.version}

                                            />
                                        </Box>
                                        {formErrors.name ?
                                            <Box style={{ display: "flex", flexDirection: "row" }} className="my-2">
                                                <h3 style={{ color: "red" }}>{formErrors.name}**</h3>
                                            </Box>
                                            : null}
                                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex', marginTop: '5%' }}>
                                            {/* <Box flex={.8} mr="0.5em" marginTop={2} marginBottom={1}>
                                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Device Type</label>
                                            </Box> */}
                                            <FormControl
                                                variant="filled"
                                                className={classes.formControlFA}
                                            >
                                                <InputLabel id="config" className={classes.listItemFA} focused={false} >
                                                    Device Type
                                                </InputLabel>
                                                <Select
                                                    className={classes.listItemFA}
                                                    id="deviceType"
                                                    name="deviceType"
                                                    value={deviceTypeList.id}
                                                    onChange={onSelectHandler}
                                                    disableUnderline={true}
                                                >
                                                    {deviceTypeList &&
                                                        deviceTypeList.map((uetype) => {
                                                            return (
                                                                <MenuItem value={uetype.id}>{uetype.manufacturer} {uetype.brand}</MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        {formErrors.deviceType ?
                                            <Box style={{ display: "flex", flexDirection: "row-reverse" }} className="my-2">
                                                <h3 style={{ color: "red" }}>{formErrors.deviceType}**</h3>
                                            </Box>
                                            : null}
                                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                                            <Box flex={.4} mr="0.5em" marginTop={2} marginBottom={1}>
                                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Run After Install</label>
                                            </Box>
                                            <Checkbox color="primary" name="runAfterInstall" onChange={changeHandler} />
                                        </Box>
                                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                                            <Box flex={.4} mr="0.5em" marginTop={2} marginBottom={1}>
                                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Run At Boot</label>
                                            </Box>
                                            <Checkbox color="primary" name="runAtBoot" onChange={changeHandler} />
                                        </Box>
                                    </Box> : null}
                            </Box>
                        </Box>
                        <Box className="submitbtn">
                            <button
                                class="submit-btn"
                                type="button"
                                onClick={submitHandler}
                            >
                                SUBMIT
                            </button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};
const mapStateToProps = ({ mdm }) => {
    return {
        tenantId: mdm.tenantId,
        token: mdm.token,
        deviceTypeList: mdm.deviceTypeList
    }
}
export default connect(mapStateToProps, { fetchAppList, fetchAuthToken, uploadApp })(AddApp);