(function (exports) {
  const EndPoints = require("../endpoints").EndPoints;
  const EventEmitter = require("events").EventEmitter;
  const axios = require("axios").default;
  const AlertModule = require("./AlertModule").AletrModule;

  class Task extends EventEmitter {
    constructor(ua) {
      super();
      this.headers = {
        "Content-Type":
          'application/org.3gpp.mcptt.user-profile+xml; charset="utf-8" ',
      };
      this.AlertModule = new AlertModule();
      this.ua = ua;
    }
    getAllTasks(data) {
      console.log("getAllTasks req", data);
      return axios
        .post(EndPoints.getConfig().taskList, data, { timeout: 30000 })
        .then((res) => {
          if (res.data) {
            console.log("getAllTasks", res.data);
            return res.data;
          } else {
            return null
          }
        })
        .catch((e) => {
          console.log("🥴 FAILED");
          return null;
        });
    }
    getActiveTasksCount(data) {
      console.log("getActiveTasksCount req", data);
      return axios
        .post(EndPoints.getConfig().getActiveTasksCount, data, { timeout: 30000 })
        .then((res) => {
          if (res.data) {
            console.log("getActiveTasksCount", res.data);
            return res.data;
          } else {
            return null
          }
        })
        .catch((e) => {
          console.log("🥴 FAILED");
          return null;
        });
    }
    getTaskType() {
      return axios
        .get(EndPoints.getConfig().taskType, { timeout: 30000 })
        .then((res) => {
          if (res.data) {
            console.log("getTaskType", res.data);
            return res.data;
          } else {
            return null
          }
        })
        .catch((e) => {
          console.log("🥴 FAILED");
          return null;
        });
    }
    updateTask() {
      return axios
        .put(EndPoints.getConfig().task)
        .then((res) => {
          if (res.data) {
            console.log("updateTask", res.data);
            return res.data;
          } else {
            return null
          }
        })
        .catch((e) => {
          console.log("🥴 FAILED");
          return null;
        });
    }

    acknowledgeTask(taskDataReq) {
      console.log('acknowledge alert task.....', taskDataReq)
      return axios.put(EndPoints.getConfig().task, taskDataReq, {
        headers: {
          Authorization: `Bearer ${this.ua.getActiveToken()}`
        }
      })
        .then(
          (res) => {
            if (res.data) {
              console.log('acknowledge alert task res..', res.data)
              return res.data
            } else {
              return null
            }
          },
          (err) => {
            console.log("TASK UPDATE ER ", err);
            return null
          }
        )
        .catch((err) => {
          console.log("TASK UPDATE ER", err);
          return null
        });
    }

  }
  exports.Task = Task;
})(typeof exports === "undefined" ? (this["Task"] = {}) : exports);
