import { func } from "prop-types";
import { takeEvery, put, takeLatest, call } from "redux-saga/effects";
import {
  FETCH_SDS_TEXT,
  LOG,
  MCX_PATCH_CALLS,
  PATCH_RESPONSE,
  MCX_MERGE_CALLS,
  MERGE_RESPONSE,
  SEND_CAD_UPDATE,
  SEND_GROUP_CALL,
  SEND_GROUP_CALL_ACTION,
  SEND_INDIVIDUAL_CALL,
  SEND_INDIVIDUAL_CALL_ACTION,
  SEND_TEXT_MESSAGE,
  SEND_TEXT_MESSAGE_STATE,
  UPDATE_SDS_TEXT,
  FETCH_DGNA,
  EDIT_DGNA,
  FREE_DGNA,
  EXPORT_DGNA,
  ATTACH_DGNA,
  FETCH_USERLIST,
  DELETE_USERLIST,
  EDIT_USERLIST,
  FETCH_USERLIST_ADMIN,
  UPDATE_USERLIST_ADMIN,
  FETCH_FALIST_ADMIN,
  UPDATE_FALIST_ADMIN,
  CREATE_USER_ADMIN,
  DELETE_USER_ADMIN,
  UPDATE_USER_ADMIN,
  ADD_FA_ADMIN,
  UPDATE_FA_ADMIN,
  UPDATE_FADETAIL_ADMIN,
  FA_DETAIL_ADMIN,
  DELETE_FA_ADMIN,
  FETCH_PROFILE_FA_ADMIN,
  UPDATE_PROFILE_FA_ADMIN,
  FETCH_MCPTTID_URI,
  UPDATE_MCPTTID_URI,
  UPDATE_USERPROFILE_ATTACHED_FA_ADMIN,
  RESET_PROFILE_FA_ADMIN,
  FETCH_GROUP_ADMIN,
  UPDATE_GROUP_ADMIN,
  FETCH_PARTNERLIST_MTM,
  UPDATE_PARTNERLIST_MTM,
  FETCH_TENANTLIST_MTM,
  UPDATE_TENANTLIST_MTM,
  FETCH_ORG_ADMIN,
  UPDATE_ORG_ADMIN,
  CREATE_ORG_ADMIN,
  DELETE_ORG_ADMIN,
  UPDATE_EXISTING_ORG_ADMIN,
  CREATE_GROUP_ADMIN,
  DELETE_GROUP_ADMIN,
  UPDATE_EXISTING_GROUP_ADMIN,
  AFFILIATE_GROUP_TO_USER,
  USER_DEFAULT_GROUP,
  FETCH_IWF_MAP_ADMIN,
  CREATE_IWF_MAP_ADMIN,
  UPDATE_IWF_MAP_ADMIN,
  DELETE_IWF_MAP_ADMIN,
  UPDATE_EXISTING_IWF_MAP_ADMIN,
  FETCH_IWF_TYPE_ADMIN,
  UPDATE_IWF_TYPE_ADMIN,
  UPDATE_GROUP_TYPE_ADMIN,
  FETCH_GROUP_TYPE_ADMIN,
  UPDATE_GROUP_DETAIL_ADMIN,
  FETCH_GROUP_DETAIL_ADMIN,
  UPDATE_ORG_GROUP_ADMIN,
  FETCH_ORG_GROUP_ADMIN,
  RESET_ORG_GROUP_ADMIN,
  SEND_ACK_EMERGENCY_CALL,
  UPDATE_ACK_EMERGENCY_CALL,
  UPDATE_USER_SDS_TEXT, FETCH_USER_SDS_TEXT,
  UPLOAD_SDS_FILE,
  UPDATE_IWFBYFA_ADMIN,
  FETCH_IWFBYFA_ADMIN,
  UPDATE_IWFBYMCPTTID_ADMIN,
  FETCH_IWFBYMCPTTID_ADMIN,
  GET_ALL_ALERT_LIST,
  GET_ALL_ALERT_LIST_TYPES,
  UPDATE_ALL_ALERT_LIST,
  UPDATE_ALL_ALERT_LIST_TYPES,
  EDIT_ALERT_LIST,
  DELETE_ALERT_LIST,
  ADD_ALERT_LIST,
  FETCH_GROUP_ACTIVE_MEMBERS,
  UPDATE_GROUP_ACTIVE_MEMBERS,
  FETCH_USERS_GROUPS,
  UPDATE_USERS_GROUPS,
  UPDATE_TASK_LIST,
  FETCH_TASK_LIST,
  FETCH_ACTIVE_TASK_COUNT,
  UPDATE_ACTIVE_TASK_COUNT,
  UPDATE_TASK_CALL,
  FETCH_CALL_RECORD,
  UPDATE_CALL_RECORD,
  GET_CHAT_USERLIST,
  SET_CHAT_USERLIST,
  SET_CALL_FORWARD,
  DELETE_PARTNERLIST_MTM,
  DELETE_TENANTLIST_MTM,
  CREATE_PARTNERLIST_MTM,
  CREATE_TENANTLIST_MTM,
  CREATE_USERONIDMSANDSERVER_MTM,
  UPDATE_TENANT_MTM,
  UPDATE_PARTNER_MTM,
  GET_ALL_SUBDOMAINS,
  UPDATE_ALL_SUBDOMAINS,
  UPDATE_DEVICE_CHANGE,
  UPDATE_USER_ON_IDMS_AND_SERVER,
  UPDATE_USER_ON_IDMS_AND_SERVER_PARTNER,
  UPDATE_ALL_SWITCH_PROFILES,
  GET_ALL_SWITCH_PROFILES,
  GET_ALL_ADMIN_PROFILE,
  FECTH_DEVICE_LIST,
  SET_DEVICE_LIST,
  FETCH_APP_LIST,
  SET_APP_LIST,
  FETCH_CONFIG_LIST,
  SET_CONFIG_LIST,
  DELETE_CONFIG,
  DELETE_APP,
  DELETE_DEVICE,
  FETCH_AUTH_TOKEN,
  REFRESH_AUTH_TOKEN,
  UPDATE_CONFIG,
  SET_CONFIG_APPLICATION,
  FETCH_CONFIG_APPLICATION,
  ADD_DEVICE,
  UPDATE_DEVICE,
  PULL_LOGS,
  SET_APP_RELN,
  SET_TENANT_ID,
  GET_TENANT_ID,
  GET_TENANT_LIST,
  SET_TENANT_LIST,
  GET_DEVICE_ID_LIST,
  SET_DEVICE_ID_LIST,
  UPDATE_DEVICE_BY_DEVICE,
  SET_DEVICE_ID,
  GET_DEVICE_ID,
  ADD_PRODUCT_CATALOG,
  GET_PRODUCT_CATALOG,
  UPDATE_PRODUCT_CATALOG,
  EDIT_PRODUCT_CATALOG,
  BUY_PRODUCT_CATALOG,
  CONFIRM_ORDER,
  GET_ORDER_LIST,
  UPDATE_ORDER_LIST,
  SET_DEVICE_TYPE_LIST,
  FECTH_DEVICE_TYPE_LIST,
  ADD_DEVICE_TYPE,
  UPDATE_DEVICE_TYPE,
  DELETE_DEVICE_TYPE,
  SET_APP_CONFIG_LIST,
  FETCH_APP_CONFIG_LIST,
  FETCH_DEVICE_INVENTORY,
  ALLOCATE_DEVICE,
  SET_DEVICE_INVENTORY,
  FETCH_UETYPELIST_MTM,
  UPDATE_UETYPE_MTM,
  FETCH_AVAILABLESERVICES_MTM,
  UPDATE_AVAILABLE_SERVICES_MTM,
  GET_SUBSCRIPTION_LIST,
  UPDATE_SUBSCRIPTION_LIST,
  FETCH_MASTERSERVICESCONFIG_MTM,
  UPDATE_MASTERSERVICESCONFIG_MTM,
  UPDATE_DEVICE_BY_USER,
  UPLOAD_APP,
  UPDATE_CURRENT_ACTIVE_SUBSCRIPTION_FOR_USER,
  GET_CURRENT_ACTIVE_SUBSCRIPTION_FOR_USER,
  UPDATE_CURRENT_ACTIVE_SUBSCRIPTION,
  GET_CURRENT_ACTIVE_SUBSCRIPTION,
  UPDATE_ALLOCATE_USER_FROM_TENANT_SUBSCRIPTION,
  GET_ALLOCATE_USER_FROM_TENANT_SUBSCRIPTION,
  CREATED_ADMIN_PROFILE,
  GET_CONFIG_DEVICE,
  UPDATE_CONFIG_DEVICE,
  REJECTED_ORDER,
  SET_DEVICE_DASHBOARD,
  FETCH_DEVICE_DASHBOARD,
  FETCH_DEVICE_INFO,
  SET_DEVICE_INFO,
  

} from "../modules/actions/type";
import {
  groupMockData,
  orgMockData,
  NewOrgMock,
  DeleteOrgMock,
  UpdateOrgMock,
  NewGroupMock,
  DeleteGroupMock,
  UpdateGroupMock,
} from "../constants/groupMockData";


import { FETCH_ACTIVITY_LOG, UPDATE_ACTIVITY_LOG } from '../modules/activityLog'
import { ACKNOWLEDGE_STATUS, ADD_ALERTS, getAlerts, GET_ALERTS, IGNORE_STATUS, SEND_STATUS } from '../modules/alarm'
import { showMessage } from '../modules/alerts'
import { fetchDgna, fetchUserLists, updateDGNA, updateUserLists } from '../modules/common'
import { logSentCall, patchResponseRecd, successGroupCallAction, successIndvCallAction, mergeResponseRecd } from '../modules/communication'
import { initialCallState } from '../constants/constants';
import { getCallActionState } from '../utils/lib';
import moment from 'moment'


const DOMParser = require("xmldom").DOMParser;
const parser = new DOMParser();
const handleNewMessage = function* handleNewMessage(params) {
  try {
    yield takeLatest(SEND_TEXT_MESSAGE, (data) => sendSDS(data, params))
    yield takeLatest(UPLOAD_SDS_FILE, (data) => uploadSDSFile(data, params))
    yield takeLatest(SEND_STATUS, (data) => sendStatus(data, params))

    yield takeLatest(UPDATE_DEVICE_CHANGE, () => replaceTrackDeviceChanges(params))

    yield takeEvery(SEND_TEXT_MESSAGE_STATE, (data) => {
      console.log('SDS msg SEND_TEXT_MESSAGE_STATE', data.data, JSON.stringify(data.data))
      params.mcxClient.sendSDS(data.data)
    })

    yield takeLatest(SEND_INDIVIDUAL_CALL, (data) => sentCall(data, params));
    yield takeLatest(SEND_GROUP_CALL, (data) => sentGroupCall(data, params));

    // yield takeEvery(SEND_INDIVIDUAL_CALL_ACTION, (data) => {
    //   console.log('SEND_INDIVIDUAL_CALL_ACTION', data.data)
    //   params.mcxClient.sendCallAction(data.data)
    // })
    yield takeLatest(SEND_INDIVIDUAL_CALL_ACTION, (data) => sendIndvCallAPI(data.data, params))

    yield takeEvery(SEND_GROUP_CALL_ACTION, (data) => sendCallAPI(data.data, params))

    yield takeEvery(UPDATE_TASK_CALL, (data) => updateTaskCall(data.data, params))

    yield takeEvery(SEND_ACK_EMERGENCY_CALL, (data) => sendAckEmergencyCallAPI(data, params))

    yield takeEvery('COMMUNICATION/ADD_DISCREET_SUBSCRIBER', (data) => {
      // console.log('ADD_DISCREET_SUBSCRIBER', data.data)
      params.delegates.sendMessage("ADD_DISCREET_SUBSCRIBER", data.data);
    });

    yield takeEvery("COMMUNICATION/REMOVE_DISCREET_SUBSCRIBER", (data) => {
      // console.log('REMOVE_DISCREET_SUBSCRIBER', data.data)
      params.delegates.sendMessage("REMOVE_DISCREET_SUBSCRIBER", data.data);
    });

    yield takeEvery(ACKNOWLEDGE_STATUS, (data) => {
      // console.log('ALARM/ACKNOWLEDGE_STATUS', data.data)
      params.delegates.sendMessage("ALARM/ACKNOWLEDGE_STATUS", data.data);
    });

    yield takeEvery(IGNORE_STATUS, (data) => {
      // console.log('ALARM/IGNORE_STATUS', data.data)
      params.delegates.sendMessage("ALARM/IGNORE_STATUS", data.data);
    });

    yield takeEvery("AUTH/LOGOUT", () => {
      params.mcxClient.logoutAuth()
    })

    // MCX UPDATE API's
    yield takeEvery("COMMUNICATION/MCX_ADD_FAV", (data) =>
      addToFav(data, params)
    );

    yield takeEvery("COMMUNICATION/MCX_REMOVE_FAV", (data) =>
      remFav(data, params)
    );

    yield takeEvery("COMMUNICATION/MCX_PIN_ALERT", (data) =>
      pinAlert(data, params)
    );

    yield takeEvery("COMMUNICATION/MCX_ACK_ALERT", (data) =>
      ackAlert(data, params)
    );

    //PAGINATION
    yield takeEvery(GET_ALERTS, (data) => fetchMoreAlerts(data, params));

    yield takeEvery(FETCH_ACTIVITY_LOG, (data) => fetchLogs(data, params));

    yield takeEvery(FETCH_SDS_TEXT, (data) => fetchSDS(data, params))
    yield takeEvery(FETCH_USER_SDS_TEXT, (data) => fetchUserSDSMessage(data, params))

    //ADMIN
    yield takeEvery(FETCH_USERLIST_ADMIN, () => fetchUsersAdmin(params));
    yield takeEvery(FETCH_GROUP_ADMIN, () => fetchGroupAdmin(params));
    yield takeEvery(FETCH_ORG_ADMIN, () => fetchOrgAdmin(params));
    yield takeEvery(FETCH_IWF_MAP_ADMIN, () => fetchIWFMapAdmin(params));
    yield takeEvery(FETCH_IWF_TYPE_ADMIN, () => fetchIWFTypeAdmin(params));

    yield takeEvery(FETCH_FALIST_ADMIN, () => fetchFAsAdmin(params));
    yield takeEvery(CREATE_USER_ADMIN, (data) => createUserAdmin(data, params));
    yield takeEvery(CREATE_ORG_ADMIN, (data) => createOrgAdmin(data, params));
    yield takeEvery(CREATE_GROUP_ADMIN, (data) =>
      createGroupAdmin(data, params)
    );
    yield takeEvery(AFFILIATE_GROUP_TO_USER, (data) =>
      affiliateGroupToUser(data, params)
    );
    yield takeEvery(USER_DEFAULT_GROUP, (data) =>
      userDefaultGroup(data, params)
    );
    yield takeEvery(CREATE_IWF_MAP_ADMIN, (data) =>
      createIWFMapAdmin(data, params)
    );

    yield takeEvery(UPDATE_EXISTING_ORG_ADMIN, (data) =>
      updateOrgAdmin(data, params)
    );
    yield takeEvery(UPDATE_EXISTING_GROUP_ADMIN, (data) =>
      updateGroupAdmin(data, params)
    );
    yield takeEvery(FETCH_GROUP_TYPE_ADMIN, () => fetchGroupTypeAdmin(params));
    yield takeEvery(FETCH_ORG_GROUP_ADMIN, (data) => getMembersForOrgGroup(data, params));
    yield takeEvery(FETCH_GROUP_DETAIL_ADMIN, (data) => getGroupDetail(data, params));
    yield takeEvery(RESET_ORG_GROUP_ADMIN, () => resetOrgGroupAdmin(params));
    yield takeEvery(UPDATE_EXISTING_IWF_MAP_ADMIN, (data) =>
      updateIWFMapAdmin(data, params)
    );

    yield takeEvery(UPDATE_USER_ADMIN, (data) => updateUserAdmin(data, params));
    yield takeEvery(DELETE_USER_ADMIN, (data) => deleteUserAdmin(data, params));
    yield takeEvery(DELETE_ORG_ADMIN, (data) => deleteOrgAdmin(data, params));
    yield takeEvery(DELETE_GROUP_ADMIN, (data) =>
      deleteGroupAdmin(data, params)
    );
    yield takeEvery(DELETE_IWF_MAP_ADMIN, (data) =>
      deletIWFMapAdmin(data, params)
    );

    yield takeEvery(ADD_FA_ADMIN, (data) => addFAAdmin(data, params));
    yield takeEvery(UPDATE_FA_ADMIN, (data) => updateFAAdmin(data, params));
    yield takeEvery(FA_DETAIL_ADMIN, (data) =>
      fetchFAAdminDetail(data, params)
    );
    yield takeEvery(DELETE_FA_ADMIN, (data) => deleteFAAdmin(data, params));
    yield takeEvery(FETCH_PROFILE_FA_ADMIN, (data) =>
      fetchUserProfileWithAttachedFAS(data, params)
    );
    yield takeEvery(UPDATE_USERPROFILE_ATTACHED_FA_ADMIN, (data) =>
      updateUserProfileWithAttachedFAS(data, params)
    );
    yield takeEvery(GET_ALL_ADMIN_PROFILE, (data) =>
      getAdminProfile(data, params)
    );
    yield takeEvery(FETCH_MCPTTID_URI, () =>
      fetchMcpttIdAndUri(params)
    );

    // MTM
    yield takeEvery(FETCH_PARTNERLIST_MTM, () => fetchPartnersMtm(params));
    yield takeEvery(FETCH_MASTERSERVICESCONFIG_MTM, () => fetchMasterConfigServiceConfig(params));
    yield takeEvery(FETCH_UETYPELIST_MTM, (data) => fetchUeTypeListMtm(data, params));
    yield takeEvery(FETCH_AVAILABLESERVICES_MTM, (data) => getAvailableServices(data, params));
    yield takeEvery(FETCH_TENANTLIST_MTM, () => fetchTenantMtm(params));
    yield takeEvery(CREATE_PARTNERLIST_MTM, (data) => createPartnerMtm(data, params));
    yield takeEvery(CREATE_TENANTLIST_MTM, (data) => createTenantMtm(data, params));
    yield takeEvery(CREATE_USERONIDMSANDSERVER_MTM, (data) => createUserOnIdmsAndServer(data, params));
    yield takeEvery(DELETE_PARTNERLIST_MTM, (data) => deletePartnerMtm(data, params));
    yield takeEvery(DELETE_TENANTLIST_MTM, (data) => deleteTenantMtm(data, params));
    yield takeEvery(UPDATE_PARTNERLIST_MTM, (data) => updatePartnerMtm(data, params));
    yield takeEvery(UPDATE_TENANTLIST_MTM, (data) => updateTenantMtm(data, params));

    //LOG
    yield takeEvery(LOG, (data) => {
      console.log(LOG, data.msg, data.data);
      if (data.data) params.mcxClient.log(data.msg, data.data);
      else params.mcxClient.log(data.msg);
    });

    //CAD
    yield takeLatest(SEND_CAD_UPDATE, (data) => sendCADUpdate(data, params));

    //PATCH
    yield takeLatest(MCX_PATCH_CALLS, (data) => sendPatchCallReq(data, params));
    //MERGE
    yield takeLatest(MCX_MERGE_CALLS, (data) => sendMergeCallReq(data, params));

    //DGNA
    yield takeLatest(FETCH_DGNA, (data) => getDGNA(data, params));
    yield takeLatest(EDIT_DGNA, (data) => editDGNA(data, params));
    yield takeLatest(FREE_DGNA, (data) => freeDGNA(data, params));
    yield takeLatest(ATTACH_DGNA, (data) => attachDGNA(data, params));

    //USERLISTS
    yield takeLatest(EXPORT_DGNA, (data) => exportDGNA(data, params));
    yield takeLatest(FETCH_USERLIST, (data) => getUserLists(data, params));
    yield takeLatest(DELETE_USERLIST, (data) => delUserLists(data, params));
    yield takeLatest(EDIT_USERLIST, (data) => editUserLists(data, params));
    yield takeLatest(GET_ALL_SWITCH_PROFILES, (data) => getAllSwitchProfiles(data, params));
    //IWFIN FA DETAIL ADMIN
    yield takeEvery(FETCH_IWFBYFA_ADMIN, (data) => getIWFMapByFa(data, params));
    yield takeEvery(FETCH_IWFBYMCPTTID_ADMIN, (data) => getIWFMapByMCPTTID(data, params));
    //ALERT ADMIN
    yield takeEvery(GET_ALL_ALERT_LIST, () => getAllAlerts(params));
    yield takeEvery(GET_ALL_ALERT_LIST_TYPES, () => getAlertTypes(params));
    yield takeEvery(EDIT_ALERT_LIST, (data) => editAlert(data, params));
    yield takeEvery(ADD_ALERT_LIST, (data) => addAlert(data, params));
    yield takeEvery(DELETE_ALERT_LIST, (data) => deleteAlert(data, params));

    // SUBDOMAIN
    yield takeEvery(GET_ALL_SUBDOMAINS, (data) => getAllSubDomains(data, params))

    // TASK
    yield takeEvery(FETCH_TASK_LIST, (data) => getAllTasks(data, params));
    yield takeEvery(FETCH_ACTIVE_TASK_COUNT, (data) => getActiveTaskCount(data, params));


    //Group Active Members and User Groups
    yield takeEvery(FETCH_GROUP_ACTIVE_MEMBERS, (data) => getGroupActiveMembers(data, params));
    yield takeEvery(FETCH_USERS_GROUPS, (data) => getUserAttachedGroups(data, params));

    // Player
    yield takeEvery(FETCH_CALL_RECORD, (data) => getCallRecord(data, params));

    // CHAT LIST
    yield takeEvery(GET_CHAT_USERLIST, (data) => getChatUserList(data, params));

    yield takeEvery(SET_CALL_FORWARD, (data) => setCallForward(data, params));

    // MDM
    yield takeEvery(FECTH_DEVICE_LIST, (data) => getDeviceList(data, params));
    yield takeEvery(FECTH_DEVICE_TYPE_LIST, () => getDeviceTypeList(params));
    yield takeEvery(FETCH_APP_LIST, (data) => getAppList(data, params));
    yield takeEvery(FETCH_APP_CONFIG_LIST, (data) => getAppConfigList(data, params));
    yield takeEvery(FETCH_CONFIG_LIST, (data) => getConfigList(data, params));
    yield takeEvery(FETCH_DEVICE_INVENTORY, (data) => fetchDeviceInventory(data, params));
    yield takeEvery(ALLOCATE_DEVICE, (data) => allocateDevice(data, params));
    yield takeEvery(DELETE_CONFIG, (data) => deleteConfig(data, params));
    yield takeEvery(DELETE_DEVICE, (data) => deleteDevice(data, params));
    yield takeEvery(DELETE_DEVICE_TYPE, (data) => deleteDeviceType(data, params));
    yield takeEvery(DELETE_APP, (data) => deleteApp(data, params));
    yield takeEvery(FETCH_AUTH_TOKEN, (data) => fetchAuthToken(data, params));
    yield takeEvery(UPDATE_CONFIG, (data) => updateConfig(data, params));
    yield takeEvery(FETCH_CONFIG_APPLICATION, (data) => fetchConfigApp(data, params))
    yield takeEvery(ADD_DEVICE, (data) => addDevice(data, params))
    yield takeEvery(UPDATE_DEVICE, (data) => updateDevice(data, params))
    yield takeEvery(UPLOAD_APP, (data) => uploadApp(data, params))
    yield takeEvery(ADD_DEVICE_TYPE, (data) => addDeviceType(data, params))
    yield takeEvery(PULL_LOGS, (data) => (pullLogs(data, params)))
    yield takeEvery(SET_APP_RELN, (data) => addConfigAppReln(data, params))
    yield takeEvery(GET_TENANT_ID, (data) => getTenantId(data, params))
    yield takeEvery(GET_TENANT_LIST, (data) => getTenantList(data, params))
    yield takeEvery(GET_DEVICE_ID_LIST, (data) => getDeviceIdList(data, params))
    yield takeEvery(UPDATE_DEVICE_BY_DEVICE, (data) => updateDeviceByDeviceId(data, params))
    yield takeEvery(UPDATE_DEVICE_BY_USER, (data) => updateDeviceByUser(data, params))
    yield takeEvery(GET_DEVICE_ID, (data) => getDeviceId(data, params))
    yield takeEvery(GET_CONFIG_DEVICE, (data) => fetchConfigurationByDeviceId(data, params))
    yield takeEvery(FETCH_DEVICE_DASHBOARD,(data)=>fetchDeviceDashboard(data,params))
    yield takeEvery(FETCH_DEVICE_INFO,(data)=>fetchDeviceInfo(data,params))

    //Plan Management
    yield takeEvery(ADD_PRODUCT_CATALOG, (data) => addProductCatalog(data, params));
    yield takeEvery(EDIT_PRODUCT_CATALOG, (data) => editProductCatalog(data, params));
    yield takeEvery(GET_PRODUCT_CATALOG, (data) => getProductCatalog(data, params));
    yield takeEvery(BUY_PRODUCT_CATALOG, (data) => createOrderForProduct(data, params));
    yield takeEvery(CONFIRM_ORDER, (data) => updateOrderForProduct(data, params));
    yield takeEvery(GET_ORDER_LIST, (data) => getOrderList(data, params));

    //Subscription Management
    yield takeEvery(GET_SUBSCRIPTION_LIST, (data) => fetchSubscriptions(data, params));
    yield takeEvery(GET_CURRENT_ACTIVE_SUBSCRIPTION_FOR_USER, (data) => getCurrentActiveSubscriptionsForUser(data, params));
    yield takeEvery(GET_CURRENT_ACTIVE_SUBSCRIPTION, (data) => getCurrentActiveSubscriptions(data, params));
    yield takeEvery(GET_ALLOCATE_USER_FROM_TENANT_SUBSCRIPTION, (data) => allocateDeallocateUserFromTenantSubscription(data, params));

  } catch (error) {
    console.log("saga error ", error);
  }
};

function* getGroupActiveMembers(data, params) {
  try {
    console.log('group active members saga data', data.groupId)
    const res = yield params.mcxClient.admin.getActiveGroupsForUser(data.groupId)
    console.log('group active members saga response', res)
    if (res.data && res.data.userList) {
      yield put({ type: UPDATE_GROUP_ACTIVE_MEMBERS, data: res.data.userList })
    }
  } catch (error) {
    console.log('getGroupActiveMembers error..', error)
  }
}

function* getUserAttachedGroups(data, params) {
  try {
    console.log('user attached members saga data', data.userMcpttId)
    const res = yield params.mcxClient.admin.getActiveGroupsForUser(data.userMcpttId)
    console.log('user attached members saga response', res)
    if (res.data && res.data.userList) {
      yield put({ type: UPDATE_USERS_GROUPS, data: res.data.userList })
    }
  } catch (error) {
    console.log('getUserAttachedGroups error..', error)
  }

}

//FUNCTIONS TO UPDATE REDUX STATE

function* sendCallAPI(data, params) {
  try {
    console.log('group call found saga data', data)
    params.mcxClient.log("SEND_GROUP_CALL_ACTION data: ", data)
    const res = yield params.mcxClient.sendCallAction(data);
    params.mcxClient.log("SEND_GROUP_CALL_ACTION response: ", res)
    console.log('group call found saga res', res)
    if (res && data.callActionType === 'ANSWER') {
      let recdGrpCall = {
        ...data,
        stateType: data.indexId ? 'INITIATED' : 'WAITING',
        created: moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
        stream: data && data.stream ? data.stream[0].id : '',
        actionItem: getCallActionState("ANSWER", initialCallState)
      }
      yield put(successGroupCallAction(recdGrpCall));
    }
  } catch (err) {
    //console.log('GROUP CALL ERROR', err)
    params.mcxClient.log("SEND_GROUP_CALL_ACTION error: ", err)
    //yield put(showMessage({ header: 'Error', content: 'Failed to update Group Call', type: 'error' }))
  }
}

function* updateTaskCall(data, params) {
  try {
    if (data) {
      let taskDataReq = { ...data }
      taskDataReq = {
        ...taskDataReq,
        taskStatus: 'STARTED',
        taskOutput: {
          ...taskDataReq.taskOutput,
          action: 'COMPLETED',
        }
      }
      console.log('update alertTask after call disconnected...', taskDataReq)
      params.mcxClient.task.acknowledgeTask(taskDataReq)
    }
  } catch (err) {
    params.mcxClient.log("updateTaskCall error: ", err)
  }
}

function* replaceTrackDeviceChanges(params) {
  try {
    const res = yield params.mcxClient.replaceTracksWhenDeviceChanges();
  } catch (error) {
    params.mcxClient.log("replaceTrackDeviceChanges error: ", error);
  }
}

function* sendIndvCallAPI(data, params) {
  try {
    console.log('SEND_INDIVIDUAL_CALL_ACTION data', data);
    const res = yield params.mcxClient.sendCallAction(data);
    console.log('SEND_INDIVIDUAL_CALL_ACTION res', res);

    if (res && data.callActionType === 'ANSWER') {
      let recdCall = {
        ...data,
        stateType: data.indexId ? "INITIATED" : "WAITING",
        created: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
        stream: data && data.stream ? data.stream[0].id : "",
        actionItem: getCallActionState("ANSWER", initialCallState),
      };
      yield put(successIndvCallAction(recdCall));
    }
  }
  catch (err) {
    //console.log('INDIVIDUAL CALL ERROR', err)
    params.mcxClient.log("SEND_INDIVIDUAL_CALL_ACTION error: ", err)
    //yield put(showMessage({ header: 'Error', content: 'Failed to update Individual Call', type: 'error' }))
  }
}

function* sendAckEmergencyCallAPI(data, params) {
  try {
    const modifyData = { ...data.data, stateType: 'ACKNOWLEDGED' }
    //console.log('emergency modified data..', modifyData)
    yield params.mcxClient.addData('ACK_CALL', modifyData);
    yield put({ type: UPDATE_ACK_EMERGENCY_CALL, data: modifyData })
  } catch (err) {
    params.mcxClient.log("UPDATE_ACK_EMERGENCY_CALL error: ", err)
  }
}

function* fetchSDS(data, params) {
  try {
    const res = yield params.mcxClient.getPagnData(
      {
        apiType: 'SDS',
        id: data.data.id,
        type: data.data.type,
        current_page: data.data.current_page
      });
    console.log('FETCH_SDS_TEXT', res)
    yield put({ type: UPDATE_SDS_TEXT, data: res })
  } catch (err) {
    params.mcxClient.log("FETCH_SDS_TEXT error: ", err)
  }
}

function* fetchUserSDSMessage(data, params) {
  try {
    const res = yield params.mcxClient.getUserSDSMesage(data.data);
    console.log('msgg FETCH_USER_SDS_TEXT', res)
    yield put({ type: UPDATE_USER_SDS_TEXT, data: res })
  } catch (err) {
    params.mcxClient.log("FETCH_USER_SDS_TEXT error: ", err)
  }
}

function* fetchLogs(data, params) {
  try {
    const res = yield params.mcxClient.getPagnData(
      {
        apiType: 'LOGS',
        id: data.data.id,
        type: data.data.type,
        current_page: data.data.current_page,
        search: data.data.search ? data.data.search : ""
      });
    let isNew = false;
    if (data.data.tabChanged) isNew = true;
    console.log('FETCH_ACTIVITY_LOG', res)
    yield put({ type: UPDATE_ACTIVITY_LOG, data: res, isNew: isNew })
  } catch (err) {
    params.mcxClient.log("FETCH_ACTIVITY_LOG error: ", err)
  }
}

function* fetchMoreAlerts(data, params) {
  try {
    const res = yield params.mcxClient.getPagnData(
      {
        apiType: 'ALERTS',
        id: data.data.id,
        type: data.data.type,
        current_page: data.data.current_page
      });
    console.log('ADD_ALERTS', data, res)
    yield put({ type: ADD_ALERTS, data: res })
  } catch (err) {
    params.mcxClient.log("ADD_ALERTS error: ", err)
  }
}

/*     SDS     */
function* sendSDS(data, params) {
  try {
    console.log('sendSDS data', data.message);
    yield params.mcxClient.sendSDS(data.message);
    // console.log('msggg SEND_TEXT_MESSAGE resp', res)
    // if (res) {
    //   yield put(showMessage({ header: 'SDS', content: 'SDS is sent successfully!', type: 'success' }))
    // }
    // else {
    //   yield put(showMessage({ header: 'Error', content: 'Failed to send SDS', type: 'error' }))
    // }
  } catch (err) {
    params.mcxClient.log("SEND_TEXT_MESSAGE error: ", err);
  }
}

function* uploadSDSFile(data, params) {
  try {
    console.log('msggg upload SDS File', data.data)
    const res = params.mcxClient.uploadFile(data.data.formData)
    console.log('msggg upload SDSFile response', res.data, res.data.File)
    if (res.data.File) {
      let messageObj = { ...data.data.message, fileURL: res.data.File.fileId }
      console.log('msggg after uploading image message', messageObj)
      const resp = params.mcxClient.sendSDS(messageObj)
      console.log('msggg SEND_TEXT_MESSAGE resp', resp)
      if (resp) {
        yield put(showMessage({ header: 'SDS', content: 'SDS is sent successfully!', type: 'success' }))
      }
      else {
        yield put(showMessage({ header: 'Error', content: 'Failed to send SDS', type: 'error' }))
      }
    }
    else {
      yield put(showMessage({ header: 'Error', content: 'Failed to send SDS', type: 'error' }))
    }
  } catch (err) {
    params.mcxClient.log("SEND_TEXT_MESSAGE error: ", err)
  }

}

function* sendStatus(data, params) {
  try {
    yield params.mcxClient.sendSDS(data.data)
    // if (res) {
    //   yield put(showMessage({ header: 'Alert', content: 'Status message successfully sent!', type: 'success' }))
    // }
    // else {
    //   yield put(showMessage({ header: 'Error', content: 'Failed to send Status message!', type: 'error' }))
    // }
  } catch (err) {
    params.mcxClient.log("SEND_STATUS error: ", err)
  }
}
/*     CALL     */
function* sentCall(data, params) {
  try {
    console.log("SEND_INDIVIDUAL_CALL initiate data: ", data.call)
    const res = yield params.mcxClient.initiateCall(data.call);
    console.log("SEND_INDIVIDUAL_CALL initiate response: ", res)
    if (res) {
      const sentCall = data.call;
      sentCall.callId = res;
      console.log('SEND_INDIVIDUAL_CALL RES', res, sentCall)
      if (data.call.alertTask) {
        let taskDataReq = data.call.alertTask
        taskDataReq = {
          ...taskDataReq,
          taskOutput: {
            ...taskDataReq.taskOutput,
            action: 'CALL_STARTED',
            callId: res
          }
        }
        params.mcxClient.task.acknowledgeTask(taskDataReq)
        sentCall.alertTask = taskDataReq
        yield put({ type: 'COMMUNICATION/SENT_CALL', data: sentCall })
        //yield put(showMessage({ header: 'Call', content: 'Call successfully initiated!', type: 'success' }))
      } else {
        yield put({ type: 'COMMUNICATION/SENT_CALL', data: sentCall })
        //yield put(showMessage({ header: 'Call', content: 'Call successfully initiated!', type: 'success' }))
      }
    }
    else {
      //yield put(showMessage({ header: 'Error', content: 'Failed to initiate call', type: 'error' }))
    }
  } catch (err) {
    params.mcxClient.log("SEND_INDIVIDUAL_CALL initiate error: ", err)
  }
}

function* sentGroupCall(data, params) {
  try {
    params.mcxClient.log("SEND_GROUP_CALL initiate data: ", data.call)
    if (data.call && data.call.isRunningCall) {
      const resp = yield params.mcxClient.sendReinviteForPriorityChange(data.call, 15);
      //const resp = yield params.mcxClient.initiateCall(data.call);
      console.log("sendReinviteForPriorityChange reducer", resp);
    } else {
      const res = yield params.mcxClient.initiateCall(data.call);
      console.log('SEND_GROUP_CALL REQ', res);
      params.mcxClient.log("SEND_GROUP_CALL initiate response: ", res);
      if (res) {
        const sentCall = data.call;
        sentCall.callId = res;
        console.log('SEND_GROUP_CALL RES', res, sentCall)
        yield put({ type: 'COMMUNICATION/SENT_CALL', data: sentCall })
        //yield put(showMessage({ header: 'Call', content: 'Group call successfully initiated!', type: 'success' }))
      }
      else {
        //yield put(showMessage({ header: 'Error', content: 'Failed to initiate group call', type: 'error' }))
      }
    }
  } catch (err) {
    params.mcxClient.log("SEND_GROUP_CALL initiate error: ", err)
  }
}

function* addToFav(data, params) {
  try {
    //console.log('COMMUNICATION/MCX_ADD_FAV', data.data)
    params.mcxClient.log("ADD_FAV data: ", data.data)
    yield params.mcxClient.updateData('ADD_FAV', data.data)
  } catch (err) {
    params.mcxClient.log("ADD_FAV error: ", err)
  }
}

function* remFav(data, params) {
  console.log('REM_FAV data:', data.data)
  try {
    params.mcxClient.log("REM_FAV data: ", data.data)
    yield params.mcxClient.updateData('REM_FAV', data.data)
  } catch (err) {
    params.mcxClient.log("REM_FAV error: ", err)
  }
}

function* pinAlert(data, params) {
  // console.log('COMMUNICATION/MCX_PIN_ALERT', data.data)
  try {
    params.mcxClient.log("PIN_ALERT data: ", data.data)
    yield params.mcxClient.updateData('PIN_ALERT', data.data)
  } catch (err) {
    params.mcxClient.log("PIN_ALERT error: ", err)
  }
}

function* ackAlert(data, params) {
  // console.log('COMMUNICATION/MCX_ACK_ALERT', data.data)
  try {
    params.mcxClient.log("ACK_ALERT data: ", data.data)
    yield params.mcxClient.updateData('ACK_ALERT', data.data)
  } catch (err) {
    params.mcxClient.log("ACK_ALERT error: ", err)
  }
}

function* sendCADUpdate(data, params) {
  //console.log(SEND_CAD_UPDATE, data.data)
  try {
    //params.mcxClient.log("CAD_ACTION data: ", data.data)
    yield params.mcxClient.sendCADAction(data.data)
  }
  catch (err) {
    //console.log('ERR CAD_ACTION', err);
    params.mcxClient.log("CAD_ACTION error: ", err)
  }
}

function* sendPatchCallReq(data, params) {
  try {
    //console.log('Patch log Call data', data.data)
    params.mcxClient.log("Patch log Call data: ", data.data)
    const res = params.mcxClient.patchCalls(data.data)
    params.mcxClient.log("Patch log Call response: ", res)
    if (res) {
      const updatedPatch = {
        ...data.data,
        stateType: "WAITING_RES",
        requestId: res,
      };
      yield put(
        showMessage({
          header: "Patch Call",
          content: "Patch call initiated!",
          type: "notif",
        })
      );
      yield put(patchResponseRecd(updatedPatch, true));
    }
  }
  catch (err) {
    //console.log('ERR MCX PATCH', err)
    params.mcxClient.log("Patch Call error: ", err)
    yield put(showMessage({ header: 'Patch Call', content: 'Failed to patch call!', type: 'error' }))
    yield put(patchResponseRecd(data.data, false))
  }
}

function* sendMergeCallReq(data, params) {
  try {
    //console.log('Patch log Call data', data.data)
    params.mcxClient.log("Merge log Call data: ", data.data)
    const res = params.mcxClient.mergeCalls(data.data)
    params.mcxClient.log("Merge log Call response: ", res)
    if (res) {
      const updatedPatch = {
        ...data.data,
        stateType: "WAITING_RES",
        requestId: res,
      };
      yield put(
        showMessage({
          header: "Merge Call",
          content: "Merge call initiated!",
          type: "notif",
        })
      );
      yield put(mergeResponseRecd(updatedPatch, true));
    }
  }
  catch (err) {
    //console.log('ERR MCX PATCH', err)
    params.mcxClient.log("Patch Call error: ", err)
    yield put(showMessage({ header: 'Merge Call', content: 'Failed to merge call!', type: 'error' }))
    yield put(mergeResponseRecd(data.data, false))
  }
}

//DGNA
function* getDGNA(data, params) {
  try {
    //console.log(FETCH_DGNA, data)
    params.mcxClient.log("GET DGNA LIST data: ", data.id)
    const res = yield params.mcxClient.getData('DGNA', data.id)
    params.mcxClient.log("GET DGNA LIST response: ", res)
    if (res) {
      // console.log('DGNA RES',res)
      yield put(updateDGNA(res));
    }
  }
  catch (err) {
    //console.log('FAILED TO GET DGNA LIST', err)
    params.mcxClient.log("GET DGNA LIST error: ", err)
  }
}

function* editDGNA(data, params) {
  console.log('EDIT_DGNA', data.data)
  try {
    params.mcxClient.log("EDIT DGNA data: ", data.data)
    yield params.mcxClient.editDGNA(data.data)
    //yield localStorage.setItem('dgnaEdit', JSON.stringify(data.data))
  }
  catch (err) {
    //console.log('FAILED TO EDIT DGNA', err)
    params.mcxClient.log("EDIT DGNA error: ", err)
  }
}

function* freeDGNA(data, params) {
  try {
    //detach dgna group api here
    console.log('delete dgna', data.data);
    params.mcxClient.log("FREE DGNA data: ", data.data)
    yield params.mcxClient.deleteDGNA(data.data)
  }
  catch (err) {
    //console.log('FAILED TO FREE DGNA', err)
    params.mcxClient.log("FREE DGNA error: ", err)
  }
}

function* attachDGNA(data, params) {
  try {
    //attach dgna group api here
    const param = {
      fromId: data.id,
      ...data.data
    }
    console.log('dgna ATTACH_DGNA', data, param)
    params.mcxClient.log("ATTACH DGNA data: ", param)
    yield params.mcxClient.createDGNA(param)
  }
  catch (err) {
    //console.log('FAILED TO ATTACH DGNA', err)
    params.mcxClient.log("ATTACH DGNA error: ", err)
  }
}

function* exportDGNA(data, params) {
  //console.log(EXPORT_DGNA, data, param)
  try {
    const param = {
      name: data.data.name,
      grpMembers: data.data.grpMembers,
      id: data.id
    }

    params.mcxClient.log("EXPORT DGNA data: ", param)
    const res = yield params.mcxClient.addData('LISTS', param);
    params.mcxClient.log("EXPORT DGNA response: ", res)
    if (res.success) {
      yield put(
        showMessage({ header: "DGNA", content: res.data.msg, type: "success" })
      );
    }
  }
  catch (err) {
    params.mcxClient.log("EXPORT DGNA error: ", err)
    //console.log('FAILED TO EXPORT DGNA', err)
    yield put(showMessage({ header: 'DGNA', content: 'Failed to export!', type: 'error' }))
  }
}

//USERLISTS
function* getUserLists(data, params) {
  try {
    console.log('user dgna members saga data', data.groupId)
    const res = yield params.mcxClient.cmc.getUsersOfGroups(data.groupId);
    console.log('user dgna members saga response', res)
    if (res.data && res.data.length > 0) {
      yield put(updateUserLists(res.data))
    }
  }
  catch (err) {
    //console.log('FAILED TO GET DGNA LIST', err)
    params.mcxClient.log("Get User List error: ", err)
  }
}

function* delUserLists(data, params) {
  const delData = {
    id: data.data.id,
    dispId: data.id,
  };
  try {
    const res = yield params.mcxClient.deleteData("LISTS", delData);
    if (res.status === 200) {
      yield put(fetchUserLists());
    }
  }
  catch (err) {
    //console.log('FAILED TO DELETE LIST', err)
    params.mcxClient.log("Delete User List error: ", err)
  }
}

function* editUserLists(data, params) {
  const updateData = {
    ...data.data,
    dispId: data.id
  }
  //console.log(EDIT_USERLIST, updateData)
  try {
    const res = yield params.mcxClient.updateData("LISTS", updateData);
    if (res) {
      yield put(
        showMessage({
          header: "DGNA",
          content: "User list updated successfully!",
          type: "success",
        })
      );
      yield put(fetchUserLists());
    }
  }
  catch (err) {
    //console.log('FAILED TO EDIT LIST', err)
    params.mcxClient.log("Edit User List error: ", err)
  }
}

/* ADMIN API and Update Reducer */
function* fetchUsersAdmin(params) {
  try {
    const res = yield params.mcxClient.admin.getUsers();
    console.log("user response.. ", res);
    yield put({ type: UPDATE_USERLIST_ADMIN, data: res });
  } catch (err) {
    console.log("Fetch User Admin error: ", err)
    //console.log("saga error fetch user ", err);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to fetch users",
        type: "error",
      })
    );
  }
}
function* fetchGroupAdmin(params) {
  try {
    const token = params.mcxClient.getToken()
    const res = yield params.mcxClient.admin.getGroup(token);
    console.log("group response.. ", res);
    yield put({ type: UPDATE_GROUP_ADMIN, data: res });
  } catch (error) {
    console.log("saga error fetch group ", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to fetch group",
        type: "error",
      })
    );
  }
}
function* fetchOrgAdmin(params) {
  try {
    const token = params.mcxClient.getToken()
    const res = yield params.mcxClient.admin.getOrg(token);
    console.log("org response.. ", res);
    yield put({ type: UPDATE_ORG_ADMIN, data: res });
  } catch (error) {
    console.log("saga error fetch org ", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to fetch org",
        type: "error",
      })
    );

  }
}

/* MTM API and Update Reducer */
function* fetchPartnersMtm(params) {
  try {
    const res = yield params.mcxClient.admin.getPartnerList();
    console.log("Partner response.. ", res);
    yield put({ type: UPDATE_PARTNER_MTM, data: res });
  } catch (err) {
    params.mcxClient.log("Fetch Partner Mtm error: ", err)
    // console.log("saga error fetch user ", err);
    yield put(
      showMessage({
        header: "Mtm Error",
        content: "Failed to fetch Partners",
        type: "error",
      })
    );
  }
}
function* fetchMasterConfigServiceConfig(params) {
  try {
    const res = yield params.mcxClient.admin.getMasterConfigServiceConfig();
    console.log("Master Config response.. ", res);
    if (res && res.result) {
      yield put({ type: UPDATE_MASTERSERVICESCONFIG_MTM, data: res.result });
    }
  } catch (err) {
    console.log("Fetch Partner Mtm error: ", err)
    // console.log("saga error fetch Master Config ", err);
    yield put(
      showMessage({
        header: "Mtm Error",
        content: "Failed to fetch Master Config",
        type: "error",
      })
    );
  }
}
function* fetchUeTypeListMtm(data, params) {
  try {
    const res = yield params.mcxClient.admin.getUeTypeList(data.data);
    console.log("UE Type response.. ", res);
    yield put({ type: UPDATE_UETYPE_MTM, data: res });
  } catch (err) {
    params.mcxClient.log("Fetch UE Type Mtm error: ", err)
    // console.log("saga error fetch user ", err);
    yield put(
      showMessage({
        header: "Mtm Error",
        content: "Failed to fetch Ue Type",
        type: "error",
      })
    );
  }
}
function* getAvailableServices(data, params) {
  try {
    console.log("getAvailableServices ", data.data);
    const res = yield params.mcxClient.admin.getAvailableServices(data.data);
    console.log("Available Service response.. ", res);
    yield put({ type: UPDATE_AVAILABLE_SERVICES_MTM, data: res.result });
  } catch (err) {
    // params.mcxClient.log("Fetch Available Service Mtm error: ", err)
    console.log("Fetch Available Service Mtm error ", err);
    yield put(
      showMessage({
        header: "Mtm Error",
        content: "Failed to fetch Available Service",
        type: "error",
      })
    );
  }
}

function* getAllSwitchProfiles(data, params) {
  try {
    const res = yield params.mcxClient.admin.getAllSwitchProfiles(data.data);
    console.log("Get All User Profiles response.. ", res);
    yield put({ type: UPDATE_ALL_SWITCH_PROFILES, data: res });
  } catch (err) {
    params.mcxClient.log("Get All User Profiles error: ", err)
    // console.log("saga error fetch user ", err);
    // yield put(
    //   showMessage({
    //     header: "Mtm Error",
    //     content: "Failed to fetch ",
    //     type: "error",
    //   })
    // );
  }
}

function* fetchTenantMtm(params) {
  try {
    const res = yield params.mcxClient.admin.getTenantList();
    console.log("Partner response.. ", res);
    if (res) {
      yield put({ type: UPDATE_TENANT_MTM, data: res });
    }
  } catch (err) {
    params.mcxClient.log("Fetch Tenant Mtm error: ", err)
    //console.log("saga error fetch user ", err);
    yield put(
      showMessage({
        header: "Mtm Error",
        content: "Failed to fetch Tenants",
        type: "error",
      })
    );
  }
}

function* createPartnerMtm(data, params) {
  try {
    const res = yield params.mcxClient.admin.createPartner(data.data);
    if (res && res.status == 200) {
      yield put({ type: UPDATE_USER_ON_IDMS_AND_SERVER_PARTNER, data: true });
      const resOut = yield params.mcxClient.admin.getPartnerList();
      if (resOut) {
        yield put({ type: UPDATE_PARTNER_MTM, data: resOut });
      }
      yield put(
        showMessage({
          header: "Partner",
          content: "Partner Created successfully!",
          type: "success",
        })
      );
    } else {
      if (res && res.isError && res.isError) {
        yield put(
          showMessage({
            header: "Tenant",
            content: res.result,
            type: "error",
          })
        );
      }
    }
  } catch (err) {
    params.mcxClient.log("Create Partner error: ", err)
    yield put(
      showMessage({
        header: "Partner Error",
        content: "Failed to create Partner",
        type: "error",
      })
    );
  }
}


function* createTenantMtm(data, params) {
  try {
    const res = yield params.mcxClient.admin.createTenant(data.data);
    if (res && res.status == 200) {
      yield put({ type: UPDATE_USER_ON_IDMS_AND_SERVER, data: true });
      const resOut = yield params.mcxClient.admin.getTenantList();
      if (resOut) {
        yield put({ type: UPDATE_TENANT_MTM, data: resOut });
      }
      yield put(
        showMessage({
          header: "Tenant",
          content: "Tenant Created successfully!",
          type: "success",
        })
      );
    } else {
      if (res && res.isError) {
        yield put(
          showMessage({
            header: "Tenant",
            content: res.result,
            type: "error",
          })
        );
      }
    }
  } catch (err) {
    params.mcxClient.log("Create Tenant error: ", err)
    yield put(
      showMessage({
        header: "Tenant Error",
        content: "Failed to create Tenant",
        type: "error",
      })
    );
  }
}

function* createUserOnIdmsAndServer(data, params) {
  if (data.userType.userType === 'tenant') {
    try {
      const res = yield params.mcxClient.admin.createUserOnIdmsAndServer(data.data);
      if (res && res.success) {
        yield put({ type: UPDATE_USER_ON_IDMS_AND_SERVER, data: false });
        yield put(
          showMessage({
            header: "UserOnIdmsAndServer",
            content: "UserOnIdmsAndServer Created successfully!",
            type: "success",
          })
        );
      }
    } catch (err) {
      params.mcxClient.log("Create UserOnIdmsAndServer error: ", err)
      yield put(
        showMessage({
          header: "UserOnIdmsAndServer Error",
          content: "Failed to create UserOnIdmsAndServer",
          type: "error",
        })
      );
    }
  } else if (data.userType.userType === 'partner') {
    try {
      const res = yield params.mcxClient.admin.createPartnerUserOnIdmsAndServer(data.data);
      if (res.success) {
        yield put({ type: UPDATE_USER_ON_IDMS_AND_SERVER_PARTNER, data: false });
        yield put(
          showMessage({
            header: "UserOnIdmsAndServerPartner",
            content: "UserOnIdmsAndServerPartner Created successfully!",
            type: "success",
          })
        );
      }
    } catch (err) {
      params.mcxClient.log("Create UserOnIdmsAndServerPartner error: ", err)
      yield put(
        showMessage({
          header: "UserOnIdmsAndServerPartner Error",
          content: "Failed to create UserOnIdmsAndServerPartner",
          type: "error",
        })
      );
    }
  }
}

function* updatePartnerMtm(data, params) {
  try {
    console.log("updateParentMtm", data.data)
    const res = yield params.mcxClient.admin.updatePartner(data.data);
    //console.log('update user saga resp', res)
    if (res && res.status == 200) {
      const resOut = yield params.mcxClient.admin.getPartnerList();
      if (resOut) {
        yield put({ type: UPDATE_PARTNER_MTM, data: resOut });
      }
      yield put(
        showMessage({
          header: "Partner",
          content: "update Partner successfully!",
          type: "success",
        })
      );
    }
  } catch (err) {
    //console.log('saga error update Partner ', err)
    params.mcxClient.log("Update Partner error: ", err)
    yield put(showMessage({ header: 'Partner Error', content: 'Failed to update Partner', type: 'error' }))
  }
}
function* updateTenantMtm(data, params) {
  try {
    console.log("updateTenantMtm", data.data)
    const res = yield params.mcxClient.admin.updateTenant(data.data);
    //console.log('update user saga resp', res)
    if (res && res.status == 200) {
      const resOut = yield params.mcxClient.admin.getTenantList();
      if (resOut) {
        yield put({ type: UPDATE_TENANT_MTM, data: resOut });
      }
      yield put(
        showMessage({
          header: "Tenant",
          content: "update Tenant successfully!",
          type: "success",
        })
      );
    }
  } catch (err) {
    console.log('saga error update Tenant ', err)
    params.mcxClient.log("Update Tenant error: ", err)
    yield put(showMessage({ header: 'Tenant Error', content: 'Failed to update Tenant', type: 'error' }))
  }
}

function* deletePartnerMtm(data, params) {
  try {
    const res = yield params.mcxClient.admin.deleteMtmUser(data);
    if (res) {
      const resOut = yield params.mcxClient.admin.getPartnerList();
      if (resOut) {
        console.log("Partner response.. ", resOut);
        yield put({ type: UPDATE_PARTNER_MTM, data: resOut });
      }
    }
  } catch (error) {
    console.log("saga error delete Partner ", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to delete Partner",
        type: "error",
      })
    );
  }
}
function* deleteTenantMtm(data, params) {
  try {
    const res = yield params.mcxClient.admin.deleteMtmUser(data);
    if (res) {
      const resOut = yield params.mcxClient.admin.getTenantList();
      if (resOut) {
        console.log("Tenant response.. ", resOut);
        yield put({ type: UPDATE_TENANT_MTM, data: resOut });
      }
    }
  } catch (error) {
    console.log("saga error delete Tenant ", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to delete Partner",
        type: "error",
      })
    );
  }
}


// function* fetchGroupAdmin(params) {
//   try {
//     const res = yield params.mcxClient.admin.getGroup(groupMockData);
//     console.log('group response.. ', res)
//     yield put({ type: UPDATE_GROUP_ADMIN, data: res })
//   } catch (error) {
//     console.log('saga error fetch group ', error)
//     yield put(showMessage({ header: 'Admin Error', content: 'Failed to fetch group', type: 'error' }))
//   }
// }
// function* fetchOrgAdmin(params) {
//   try {
//     const res = yield params.mcxClient.admin.getOrg(orgMockData);
//     console.log('org response.. ', res)
//     yield put({ type: UPDATE_ORG_ADMIN, data: res })
//   } catch (error) {
//     console.log('saga error fetch org ', error)
//     yield put(showMessage({ header: 'Admin Error', content: 'Failed to fetch org', type: 'error' }))
//   }
// }


function* fetchFAsAdmin(params) {
  try {
    console.log('fa users call')
    const token = params.mcxClient.getToken()
    const res = yield params.mcxClient.cmc.getAllUserProfiles(token);
    console.log('fa user response ', res)
    const falist = res.filter((obj) => {
      if (obj.profile) {
        try {
          let xml = parser.parseFromString(obj.profile.toString())
          let mcptt = xml.getElementsByTagName('mcptt-user-profile').item(0)
          let type = mcptt.getAttribute("xmlns:type");
          return type === "urn:consort:faprofile:1.0"
        } catch (e) {
          return false
        }
      }
    })
    yield put({ type: UPDATE_FALIST_ADMIN, data: falist })
  } catch (err) {
    console.log('saga error fetch fa ', err)
    params.mcxClient.log("Fetch FA List error: ", err)
    yield put(showMessage({ header: 'Admin Error', content: 'Failed to fetch FAs', type: 'error' }))
  }
}

function* createUserAdmin(data, params) {
  let success = false;
  try {
    const res = yield params.mcxClient.admin.createUser(data.data);
    console.log("createUserAdmin res saga", res);
    if (res && res.success) {
      success = true;
      if (data.data.refreshUsers) {
        const resOut = yield params.mcxClient.admin.getUsers();
        yield put({ type: UPDATE_USERLIST_ADMIN, data: resOut });
      }
      yield put(
        showMessage({
          header: "Admin",
          content: "user created successfully!",
          type: "success",
        })
      );
    }
  } catch (err) {
    console.log("Create User error: ", err);
    if (!global.config.isEcommerce) {
      yield put(
        showMessage({
          header: "Admin Error",
          content: "Failed to create user",
          type: "error",
        })
      );
    }
  }
  if (global.config.isEcommerce) {

  }
  let resp = {
    "userName": data.data.userName,
    "success": success
  }
  yield put({ type: CREATED_ADMIN_PROFILE, data: resp });
}

function* createOrgAdmin(data, params) {
  try {
    const res = yield params.mcxClient.admin.createOrg(data.data);
    const newData = NewOrgMock(data.data);
    if (res) {
      const token = params.mcxClient.getToken()
      const resOut = yield params.mcxClient.admin.getOrg(token);
      if (resOut) {
        console.log("org response.. ", resOut);
        yield put({ type: UPDATE_ORG_ADMIN, data: resOut });
      }
    }
  } catch (error) {
    console.log("saga error create user ", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to create user",
        type: "error",
      })
    );
  }
}

function* updateOrgAdmin(data, params) {
  try {
    const res = yield params.mcxClient.admin.updateOrg(data.data);
    if (res) {
      const token = params.mcxClient.getToken()
      const resOut = yield params.mcxClient.admin.getOrg(token);
      if (resOut) {
        console.log("org response.. ", resOut);
        yield put({ type: UPDATE_ORG_ADMIN, data: resOut });
      }
    }
  } catch (error) {
    console.log("saga error create user ", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to create user",
        type: "error",
      })
    );
  }
}
function* deleteOrgAdmin(data, params) {
  try {
    console.log("deleteOrgAdmin ", data.data);
    const res = yield params.mcxClient.admin.deleteOrg(data.data);
    if (res) {
      const token = params.mcxClient.getToken()
      const resOut = yield params.mcxClient.admin.getOrg(token);
      if (resOut) {
        console.log("org response.. ", resOut);
        yield put({ type: UPDATE_ORG_ADMIN, data: resOut });
      }
    }
  } catch (error) {
    console.log("saga error create user ", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to create user",
        type: "error",
      })
    );
  }
}

function* affiliateGroupToUser(data, params) {
  try {
    const res = yield params.mcxClient.admin.affiliateGroupToUser(data.data);
    if (res) {
      // const res = yield params.mcxClient.admin.getGroup(newData);
      // console.log('group response.. ', res)
      // yield put({ type: UPDATE_GROUP_ADMIN, data: res })
    }
  } catch (error) {
    console.log("saga error affiliate group ", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to affiliate group",
        type: "error",
      })
    );
  }
}
function* userDefaultGroup(data, params) {
  try {
    const res = yield params.mcxClient.admin.userDefaultGroup(data.data);
    if (res) {
      // const res = yield params.mcxClient.admin.getGroup(newData);
      // console.log('group response.. ', res)
      // yield put({ type: UPDATE_GROUP_ADMIN, data: res })
    }
  } catch (error) {
    console.log("saga error affiliate group ", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to affiliate group",
        type: "error",
      })
    );
  }
}
//Group crud
function* createGroupAdmin(data, params) {
  try {
    console.log('create group data..', data)
    const res = yield params.mcxClient.admin.createGroup(data.data);
    if (res) {
      const token = params.mcxClient.getToken()
      const resOut = yield params.mcxClient.admin.getGroup(token);
      console.log("group response.. ", resOut);
      yield put(
        showMessage({
          header: "Admin",
          content: "Group created successfully!",
          type: "success",
        })
      );
      if (resOut) {
        yield put({ type: UPDATE_GROUP_ADMIN, data: resOut });
      }
    } else {
      yield put(
        showMessage({
          header: "Admin",
          content: "Failed to create Group!",
          type: "failed",
        })
      );
    }
  } catch (error) {
    console.log("saga error fetch group ", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to fetch group",
        type: "error",
      })
    );
  }
}

function* updateGroupAdmin(data, params) {
  try {
    const res = yield params.mcxClient.admin.updateGroup(data.data);
    const newData = UpdateGroupMock(data.data);
    if (res) {
      const token = params.mcxClient.getToken()
      const resOut = yield params.mcxClient.admin.getGroup(token);
      console.log("group response.. ", resOut);
      yield put(
        showMessage({
          header: "Admin",
          content: "Updated group successfully!",
          type: "success",
        })
      );
      if (resOut) {
        yield put({ type: UPDATE_GROUP_ADMIN, data: resOut });
      }
    }
  } catch (error) {
    console.log("saga error update group ", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to update group",
        type: "error",
      })
    );
  }
}
function* deleteGroupAdmin(data, params) {
  try {
    const res = yield params.mcxClient.admin.deleteGroup(data.data);

    if (res) {
      const token = params.mcxClient.getToken()
      const resOut = yield params.mcxClient.admin.getGroup(token);
      console.log("org response.. ", resOut);
      yield put(
        showMessage({
          header: "Admin",
          content: "Group deleted successfully!",
          type: "success",
        })
      );
      if (resOut) {
        yield put({ type: UPDATE_GROUP_ADMIN, data: resOut });
      }
    }
  } catch (error) {
    console.log("saga error delete group ", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to delete group",
        type: "error",
      })
    );
  }
}

function* updateUserAdmin(data, params) {
  try {
    console.log("updateUserAdmin", data.data)
    const res = yield params.mcxClient.admin.updateUser(data.data);
    console.log('update user saga resp', res);
    if (res && res.success) {
      // const resOut = yield params.mcxClient.admin.getUsers();
      // yield put({ type: UPDATE_USERLIST_ADMIN, data: resOut });
      yield put(
        showMessage({
          header: "Admin",
          content: "update user successfully!",
          type: "success",
        })
      );
    }
  } catch (err) {
    //console.log('saga error update user ', err)
    params.mcxClient.log("Update User error: ", err)
    yield put(showMessage({ header: 'Admin Error', content: 'Failed to update user', type: 'error' }))
  }
}

function* deleteUserAdmin(data, params) {
  try {
    console.log("delete fa api data sagaa", data);
    if (data && data.data && data.data.userId) {
      const res = yield params.mcxClient.admin.getCurrentActiveSubscriptionsForUser(data.data);
      if (res && res.result && res.result.data && res.result.data.length > 0) {
        console.log("delete fa api data sagaa subsc", res);
        let finalAllocationDeallocationArr = [];
        for (let subs of res.result.data) {
          let tenantSub = subs.tenantsubscriptions;
          let allocate = {
            state: "deallocate",
            id: tenantSub.id
          }
          finalAllocationDeallocationArr = [...finalAllocationDeallocationArr, allocate];
        }
        if (finalAllocationDeallocationArr && finalAllocationDeallocationArr.length > 0) {
          let finalData = {
            "subs": finalAllocationDeallocationArr,
            "userId": data.data.userId
          }
          console.log("allocateDeallocateUserFromTenantSubscription finalData saga", finalData);
          const resp = yield params.mcxClient.admin.allocateDeallocateUserFromTenantSubscription(finalData);
          console.log("allocateDeallocateUserFromTenantSubscription res saga", resp);
        }
      }
    }
    const res1 = yield params.mcxClient.cmc.deleteProfile(data.data.mcptt_id);
    console.log('delete fa api resp sagaa', res1)
    if (res1) {
      const resD = yield params.mcxClient.admin.deleteUser(data.data.id);
      console.log('delete user api resp sagaa', resD)
      if (resD) {
        const resOut = yield params.mcxClient.admin.getUsers();
        yield put({ type: UPDATE_USERLIST_ADMIN, data: resOut });
        yield put(
          showMessage({
            header: "Admin",
            content: "Delete user successfully!",
            type: "success",
          })
        );
      }
    }
  } catch (err) {
    //console.log('saga error delete FA ', err)
    params.mcxClient.log("Delete user error: ", err)
    yield put(showMessage({ header: 'Admin Error', content: 'Failed to delete user', type: 'error' }))
  }

}

function* deleteFAAdmin(data, params) {
  try {
    console.log("delete fa api data sagaa", data);
    const res = yield params.mcxClient.cmc.deleteProfile(data.data);
    //console.log('delete fa api resp sagaa', res)
    if (res) {
      // const resP = yield params.mcxClient.cmc.getAllUserProfiles();
      // //console.log('add fa list saga res', resP)
      // yield put({ type: UPDATE_FALIST_ADMIN, data: resP })
      yield call(fetchFAsAdmin, params)
      yield put(showMessage({ header: 'Admin', content: 'FA deleted successfully!', type: 'success' }))
    }
  } catch (err) {
    //console.log('saga error delete FA ', err)
    params.mcxClient.log("Delete FA error: ", err)
    yield put(showMessage({ header: 'Admin Error', content: 'Failed to delete FA', type: 'error' }))
  }
}

function* addFAAdmin(data, params) {
  try {
    console.log('add fa saga', data)
    const resp = yield params.mcxClient.cmc.addFaProfile(data.data);
    console.log('add fa saga resp', resp)
    if (resp) {
      yield put(
        showMessage({
          header: "Admin",
          content: "FA added successfully!",
          type: "success",
        })
      );
      if (data.data.faList.iwf && data.data.faList.iwf.errors) {
        delete data.data.faList.iwf.errors
      }
      if (data.data.faList.iwf && data.data.faList.iwf.fa_mcpttid) {
        delete data.data.faList.iwf.fa_mcpttid
      }
      if (data.data.faList.iwf && data.data.faList.iwf.id && data.data.faList.iwf.id.length) {
        if (data.data.faList.iwf.edit === true) {
          if (data.data.faList.iwf.hasOwnProperty("edit")) {
            delete data.data.faList.iwf["edit"]
          }
          yield call(updateIWFMapAdmin, { data: data.data.faList.iwf }, params)
        }
        else {
          if (data.data.faList.iwf.hasOwnProperty("edit")) {
            delete data.data.faList.iwf["edit"]
          }
          yield call(createIWFMapAdmin, { data: data.data.faList.iwf }, params)
        }
      }

      // const res = yield params.mcxClient.cmc.getAllUserProfiles();
      // yield put({ type: UPDATE_FALIST_ADMIN, data: res });
      yield call(fetchFAsAdmin, params)
    } else {
      yield put(showMessage({ header: 'Admin', content: 'FA is already added', type: 'error' }))
    }
  } catch (err) {
    console.log('saga error add FA ', err)
    params.mcxClient.log("Add FA error: ", err)
    yield put(showMessage({ header: 'Admin Error', content: 'Failed to add FA', type: 'error' }))
  }
}

function* updateFAAdmin(data, params) {
  try {
    console.log('update fa saga', data)
    const resp = yield params.mcxClient.cmc.addFaProfile(data.data);
    console.log('update fa saga resp', resp)
    if (resp) {
      if (data.data.faList.iwf && data.data.faList.iwf.errors) {
        delete data.data.faList.iwf.errors

      }
      if (data.data.faList.iwf && data.data.faList.iwf.fa_mcpttid) {
        delete data.data.faList.iwf.fa_mcpttid

      }
      if (data.data.faList.iwf && data.data.faList.iwf.edit) {
        delete data.data.faList.iwf.edit

      }
      if (data.data.faList.iwf && data.data.faList.iwf.id && data.data.faList.iwf.id.length)
        yield call(updateIWFMapAdmin, { data: data.data.faList.iwf }, params)
      // const res = yield params.mcxClient.cmc.getAllUserProfiles();
      // yield put({ type: UPDATE_FALIST_ADMIN, data: res });
      yield call(fetchFAsAdmin, params)
      yield put(
        showMessage({
          header: "Admin",
          content: "FA updated successfully!",
          type: "success",
        })
      );
    }
  } catch (err) {
    //console.log('saga error update FA ', err)
    params.mcxClient.log("Update FA error: ", err)
    yield put(showMessage({ header: 'Admin Error', content: 'Failed to update FA', type: 'error' }))
  }
}

function* fetchFAAdminDetail(data, params) {
  try {
    console.log("fetchFAAdminDetail ++++++++=================", data.data)
    const resp = yield params.mcxClient.cmc.getProfileByName(data.data);
    if (resp) {
      yield put({ type: UPDATE_FADETAIL_ADMIN, data: resp });
    }
  } catch (err) {
    //console.log('saga error delete FA detail', err)
    params.mcxClient.log("Delete FA Detail error: ", err)
    yield put(showMessage({ header: 'Admin Error', content: 'Failed to fetch FA detail', type: 'error' }))
  }
}
function* getIWFMapByFa(data, params) {
  try {
    console.log("getIWFMapByFa ++++++++=================", data.data)
    const resp = yield params.mcxClient.admin.getIWFMapByFa(data.data);
    if (resp) {
      yield put({ type: UPDATE_IWFBYFA_ADMIN, data: resp });
    } else {
      yield put(showMessage({ header: 'Admin Error', content: 'Failed to fetch IWFMap detail', type: 'error' }))
    }
  } catch (err) {
    //console.log('saga error delete FA detail', err)
    //params.mcxClient.log("Get IWFMap Detail error: ", err)
    yield put(showMessage({ header: 'Admin Error', content: 'Failed to fetch IWFMap detail', type: 'error' }))
  }
}
function* getIWFMapByMCPTTID(data, params) {
  try {
    console.log("getIWFMapByMCPTTID ++++++++=================", data.data)
    const resp = yield params.mcxClient.admin.getIWFMapByMCPTTID(data.data);
    console.log("getIWFMapByMCPTTID response ++++++++=================", resp)
    yield put({ type: UPDATE_IWFBYMCPTTID_ADMIN, data: resp })
  } catch (err) {
    //console.log('saga error delete FA detail', err)
    params.mcxClient.log("Get IWFMap Detail error: ", err)
    yield put(showMessage({ header: 'Admin Error', content: 'Failed to fetch IWFMap detail', type: 'error' }))
  }
}
function* fetchUserProfileWithAttachedFAS(data, params) {
  try {
    const resp = yield params.mcxClient.cmc.getProfileByMcpttId(data.data);
    console.log('admin user profile..', resp)
    if (resp) {
      yield put({ type: UPDATE_PROFILE_FA_ADMIN, data: resp })
    }
  } catch (err) {
    //console.log('saga error fetch profile with attached FA ', err)
    params.mcxClient.log("Fetch profile with attached FA error: ", err)
    yield put(showMessage({ header: 'Admin Error', content: 'Failed to fetch profile with attached FA', type: 'error' }))
  }
}

function* fetchMcpttIdAndUri(params) {
  // console.log('Fetch mcpttID and Uri response')
  try {
    const resp = yield params.mcxClient.cmc.getMcpttIdAndUri();
    // console.log('Fetch mcpttID and Uri response', resp)
    if (resp) {
      yield put({ type: UPDATE_MCPTTID_URI, data: resp })
    }
  } catch (err) {
    //console.log('saga error fetch profile with attached FA ', err)
    params.mcxClient.log("Fetch mcpttID and Uri error: ", err)
    yield put(showMessage({ header: 'Admin Error', content: 'Failed to Fetch mcpttID and Uri', type: 'error' }))
  }
}

function* updateUserProfileWithAttachedFAS(data, params) {
  try {
    console.log("updateUserProfileWithAttachedFAS", data.data)
    const resp = yield params.mcxClient.cmc.addUserProfile(data.data);
    yield put({ type: RESET_PROFILE_FA_ADMIN, data: resp });

    if (data.data.iwf && data.data.iwf.errors) {
      delete data.data.iwf.errors

    }
    if (data.data.iwf && data.data.iwf.fa_mcpttid) {
      delete data.data.iwf.fa_mcpttid
    }
    if (data.data.iwf && data.data.iwf.iwfMapPurpose) {
      delete data.data.iwf.iwfMapPurpose
    }

    if (data.data.iwf && data.data.iwf.id && data.data.iwf.id.length) {
      if (data.data.iwf.edit == true) {
        if (data.data.iwf.hasOwnProperty("edit")) {
          delete data.data.iwf["edit"]
        }
        console.log("updateUserProfileWithAttachedFAS edit true", data.data)

        yield call(updateIWFMapAdmin, { data: data.data.iwf }, params)
      }
      else {
        if (data.data.iwf.hasOwnProperty("edit")) {
          delete data.data.iwf["edit"]
        }
        console.log("updateUserProfileWithAttachedFAS edit false", data.data)
        yield call(createIWFMapAdmin, { data: data.data.iwf }, params)
      }
    }


  } catch (err) {
    console.log('saga error update profile with attached FA ', err)
    params.mcxClient.log("Update profile with attached FA error: ", err)
    yield put(showMessage({ header: 'Admin Error', content: 'Failed to update profile with attached FA', type: 'error' }))
  }
}

function* createIWFMapAdmin(data, params) {
  try {
    console.log('createIWFMapAdmin', data)
    const res = yield params.mcxClient.admin.createIWFMap(data.data);
    const newData = NewOrgMock(data.data);
    if (res) {
      const resOut = yield params.mcxClient.admin.getIWFMap(newData);
      console.log("Iwf response.. ", resOut);
      yield put({ type: UPDATE_IWF_MAP_ADMIN, data: resOut });
    } else {
      console.log("saga error create iwf map 22222", res);
      yield put(
        showMessage({
          header: "Admin Error",
          content: "Failed to create iwf map",
          type: "error",
        })
      );

    }
  } catch (error) {
    console.log("saga error create iwf map ", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to create iwf map",
        type: "error",
      })
    );
  }
}

function* updateIWFMapAdmin(data, params) {
  try {
    console.log("iwf data req.. ", data.data);
    const res = yield params.mcxClient.admin.updateIWFMap(data.data);

    if (res) {
      const resOut = yield params.mcxClient.admin.getIWFMap();
      console.log("iwf response.. ", resOut);
      yield put({ type: UPDATE_IWF_MAP_ADMIN, data: resOut });
    }
  } catch (error) {
    console.log("saga error create iwf map ", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to create iwf",
        type: "error",
      })
    );
  }
}
function* deletIWFMapAdmin(data, params) {
  try {
    console.log("Data ", data)
    const res = yield params.mcxClient.admin.deleteIWFMap(data);

    if (res) {
      const resOut = yield params.mcxClient.admin.getIWFMap();
      console.log("iwf response.. ", resOut);
      yield put({ type: UPDATE_IWF_MAP_ADMIN, data: resOut });
    }
  } catch (error) {
    console.log("saga error create iwf map ", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to create iwf map",
        type: "error",
      })
    );
  }
}

function* fetchIWFMapAdmin(params) {
  try {
    const res = yield params.mcxClient.admin.getIWFMap(orgMockData);
    console.log("iwf response.. ", res);
    yield put({ type: UPDATE_IWF_MAP_ADMIN, data: res });
  } catch (error) {
    console.log("saga error fetch iwf map ", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to fetch iwf map",
        type: "error",
      })
    );
  }
}

function* fetchIWFTypeAdmin(params) {
  try {
    console.log("iwf response type.. ");
    const res = yield params.mcxClient.admin.getIWFType();
    console.log("iwf response type.. ", res);
    yield put({ type: UPDATE_IWF_TYPE_ADMIN, data: res });
  } catch (error) {
    console.log("saga error fetch iwf map type", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to fetch iwf map type",
        type: "error",
      })
    );
  }
}


function* fetchGroupTypeAdmin(params) {
  try {
    console.log("fetchGroupTypeAdmin 2");
    const res = yield params.mcxClient.admin.getGroupType();
    console.log("fetchGroupTypeAdmin 2", res);
    yield put({ type: UPDATE_GROUP_TYPE_ADMIN, data: res });
  } catch (error) {
    console.log("saga error fetch group map type", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to fetch group map type",
        type: "error",
      })
    );
  }
}

function* getMembersForOrgGroup(data, params) {
  try {
    console.log("getMembersForOrgGroup 2");
    const res = yield params.mcxClient.admin.getMembersForOrgGroup(data.data);
    console.log("getMembersForOrgGroup 2", res);
    yield put({ type: UPDATE_ORG_GROUP_ADMIN, data: res });
  } catch (error) {
    console.log("saga error fetch getMembersForOrgGroup", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to fetch getMembersForOrgGroup",
        type: "error",
      })
    );
  }
}

function* resetOrgGroupAdmin() {
  yield put({ type: UPDATE_ORG_GROUP_ADMIN, data: [] });
}

function* getGroupDetail(data, params) {
  try {
    console.log("getGroupDetail 2", data);
    const res = yield params.mcxClient.admin.getGroupDetail(data.data);
    console.log("getGroupDetail 2", res);
    yield put({ type: UPDATE_GROUP_DETAIL_ADMIN, data: res });
  } catch (error) {
    console.log("saga error getGroupDetail", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to fetchGroupDetail",
        type: "error",
      })
    );
  }
}



function* getAffiliations(data, params) {
  try {
    console.log("getAffiliations 2", data);
    const res = yield params.mcxClient.cmc.getAffiliations(data.data);
    console.log("getAffiliations 2", res);
    //yield put({ type: UPDATE_GROUP_DETAIL_ADMIN, data: res });
  } catch (error) {
    console.log("saga error getAffiliations", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to getAffiliations",
        type: "error",
      })
    );
  }
}

function* getActiveUsers(data, params) {
  try {
    console.log("getActiveUsers 2", data);
    const res = yield params.mcxClient.cmc.getActiveUsers(data.data);
    console.log("getActiveUsers 2", res);
    //yield put({ type: UPDATE_GROUP_DETAIL_ADMIN, data: res });
  } catch (error) {
    console.log("saga error getActiveUsers", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to getActiveUsers",
        type: "error",
      })
    );
  }
}

function* getAllAlerts(params) {
  console.log("in getAllAlerts")
  try {
    console.log("getActiveUsers 2");
    const res = yield params.mcxClient.admin.getAllAlerts();
    console.log("getActiveUsers 2", res);
    yield put({ type: UPDATE_ALL_ALERT_LIST, data: res });
  } catch (error) {
    console.log("saga error getActiveUsers", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to All Alerts",
        type: "error",
      })
    );
  }
}

function* getAllTasks(data, params) {
  try {
    console.log("TASK LIST REQ getAllTasks params", data.data);
    const res = yield params.mcxClient.task.getAllTasks(data.data);
    console.log("TASK LIST REQ getAllTasks res", res);
    yield put({ type: UPDATE_TASK_LIST, data: res });
  } catch (error) {
    console.log("saga error getAllTasks", error);
    // yield put(
    //   showMessage({
    //     header: "Task Error",
    //     content: "Failed to All Tasks",
    //     type: "error",
    //   })
    // );
  }
}

function* getAllSubDomains(data, params) {
  try {
    const res = yield params.mcxClient.getAllSubDomains(data.data);
    console.log("getAllSubDomainst res", res);
    yield put({ type: UPDATE_ALL_SUBDOMAINS, data: res });
  } catch (error) {
    console.log("saga error getAllSubDomainst", error, params.mcxClient.admin);
    // yield put(
    //   showMessage({
    //     header: "Task Error",
    //     content: "Failed to All Tasks",
    //     type: "error",
    //   })
    // );
  }
}

function* getActiveTaskCount(data, params) {
  try {
    const res = yield params.mcxClient.task.getActiveTasksCount(data.data);
    console.log("getActiveTaskCount res", res);
    yield put({ type: UPDATE_ACTIVE_TASK_COUNT, data: res });
  } catch (error) {
    console.log("saga error getActiveTaskCount", error, params.mcxClient.task);
    // yield put(
    //   showMessage({
    //     header: "Task Error",
    //     content: "Failed to All Tasks",
    //     type: "error",
    //   })
    // );
  }
}

function* getAlertTypes(params) {
  console.log("in getAlertTypes")
  try {
    const res = yield params.mcxClient.admin.getAlertTypes();
    console.log("getAlertTypes 2", res);
    yield put({ type: UPDATE_ALL_ALERT_LIST_TYPES, data: res });
  } catch (error) {
    console.log("saga error getAlertTypes", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to All Alerts Type",
        type: "error",
      })
    );
  }
}

function* editAlert(data, params) {
  console.log("in getAlertTypes")
  try {
    console.log("getAlertTypes 2");
    const res = yield params.mcxClient.admin.editAlert({ data: data.data });
    console.log("getAlertTypes 2", res);
    if (!res.data.error)
      yield call(getAllAlerts, params);
  } catch (error) {
    console.log("saga error getAlertTypes", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to All Alerts Type",
        type: "error",
      })
    );
  }
}
function* getAdminProfile(data, params) {
  try {
    const res = yield params.mcxClient.getAdminProfile(data.data);
    yield put({ type: UPDATE_ALL_SWITCH_PROFILES, data: res });
  } catch (err) {
    console.log("switch user error", err);
  }
}

function* deleteAlert(data, params) {
  console.log("in deleteAlert")
  try {
    console.log("deleteAlert 2");
    const res = yield params.mcxClient.admin.deleteAlert({ data: data.data });
    console.log("deleteAlert 2", res);
    if (!res.data.error)
      yield call(getAllAlerts, params);
  } catch (error) {
    console.log("saga error deleteAlert", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to Delete Alert",
        type: "error",
      })
    );
  }
}

function* addAlert(data, params) {
  console.log("in addAlert")
  try {
    console.log("addAlert 2");
    const res = yield params.mcxClient.admin.addAlert({ data: data.data });
    console.log("addAlert 2", res);
    if (!res.data.error)
      yield call(getAllAlerts, params);
  } catch (error) {
    console.log("saga error addAlert", error);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to Add Alert",
        type: "error",
      })
    );
  }
}

function* getCallRecord(data, params) {
  try {
    const res = yield params.mcxClient.recordings.getCallRecord(data.data);
    console.log("getCallRecord res", res);
    yield put({ type: UPDATE_CALL_RECORD, data: res });
  } catch (error) {
    console.log("saga error getCallRecord", error);
  }
}

function* getChatUserList(data, params) {
  try {
    const res = yield params.mcxClient.store.getChatList(data.data)
    // console.log("SET_CHAT_USERLIST in SAGA",res);
    yield put({ type: SET_CHAT_USERLIST, data: res })
  } catch (err) {
    console.log("saga error getting chat lsit", err);
  }
}

function* setCallForward(data, params) {
  try {
    const res = yield params.mcxClient.setCallForwardingInfo(data.data)
    // console.log("SET_CALL_FORWARD in SAGA",res);
  } catch (err) {
    console.log("saga error SETTING CALL FORWARD", err);
  }
}
function* getDeviceList(data, params) {
  try {
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.fetchDeviceList({ ...data.data, token })
    if (res) {
      yield put({ type: SET_DEVICE_LIST, data: res })
    }
  } catch (err) {
    console.log("saga error fethcing device list", err);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to Fetch Device List",
        type: "error",
      })
    );
  }
}
function* getDeviceTypeList(params) {
  try {
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.fetchDeviceTypeList(token);
    if (res && res.data) {
      yield put({ type: SET_DEVICE_TYPE_LIST, data: res.data })
    }
  } catch (err) {
    console.log("saga error fethcing device type list", err);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to Fetch Device type List",
        type: "error",
      })
    );
  }
}
function* getAppList(data, params) {
  try {
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.fetchAppList({ ...data.data, token })
    if (res) {
      yield put({ type: SET_APP_LIST, data: res })
    }
  } catch (err) {
    console.log("saga error fetching app list", err);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to Fetch Application List",
        type: "error",
      })
    );
  }
}
function* getAppConfigList(data, params) {
  try {
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.fetchAppConfigList({ ...data.data, token })
    if (res && res.data) {
      yield put({ type: SET_APP_CONFIG_LIST, data: res.data })
    }
  } catch (err) {
    console.log("saga error fetching app cofig list", err);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to Fetch Application config List",
        type: "error",
      })
    );
  }
}
function* getConfigList(data, params) {
  try {
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.fetchConfigList({ ...data.data, token })
    if (res) {
      yield put({ type: SET_CONFIG_LIST, data: res })
    }
  } catch (err) {
    console.log("saga error fetching config list", err);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to Fetch Configurations List",
        type: "error",
      })
    );
  }
}
function* fetchDeviceInventory(data, params) {
  try {
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.fetchInventoryList({ ...data.data, token })
    if (res && res.status == "success") {
      yield put({ type: SET_DEVICE_INVENTORY, data: res.data })
    } else {
      yield put(
        showMessage({
          header: "MDM Error",
          content: `${res.message || "Failed to Fetch Device Inventory"}`,
          type: "error",
        })
      );
    }

  } catch (err) {
    console.log("saga error fetching Device Inventory", err);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to Fetch Device Inventory",
        type: "error",
      })
    );
  }
}
function* allocateDevice(data, params) {
  try {
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.allocateDevice({ ...data.data, token })
    if ( res && res.status == "success") {
      let deviceData = {data: {...data.data, mtmId: global.config.userConfig.partnerId}}
      yield getDeviceList(deviceData, params)
      yield fetchDeviceDashboard(deviceData,params)
    
    }
    else{
      yield put(
        showMessage({
          header: "MDM Error",
          content:res?.data?.message || "Failed to Allocating Device Inventory",
          type: "error",
        })
      );
    }
   
  } catch (err) {
    console.log("saga error Allocating Device Inventory", err);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to Allocating Device Inventory",
        type: "error",
      })
    );
  }
}
function* deleteConfig(data, params) {
  try {
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.deleteConfiguration({ ...data.data, token })
    if (res && res.status == "fail" && res.message && res.message.search("REFERENCED") != -1) {
      yield put(showMessage({ header: "MDM Error", content: "This configurations is already used in one or more devices", type: "error" }));
    } else if (res && res.staus == "fail") {
      yield put(showMessage({ header: "MDM Error", content: res.message, type: "error" }));
    }
    else {
      yield getConfigList(data, params)
    }
  } catch (err) {
    console.log("saga error deleting config list", err);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to Delete Configurations",
        type: "error",
      })
    );
  }
}
function* deleteApp(data, params) {
  try {
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.deleteApp({ ...data.data, token })
    if (res && res.status == "fail" && res.message && res.message.search("REFERENCED") != -1) {
      yield put(showMessage({ header: "MDM Error", content: "This app is already used in one or more configurations", type: "error" }));
    } else if (res && res.status == "success") {
      yield getAppList(data, params)
    }
    else {
      yield put(showMessage({ header: "MDM Error", content: res.message, type: "error" }));
    }
  } catch (err) {
    console.log("saga error deleting app", err);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to Delete Applications",
        type: "error",
      })
    );
  }
}
function* deleteDevice(data, params) {
  try {
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.deleteDevice({ ...data.data, token })
    if (res && res.status == "success") {
      let deviceData = {data: {...data.data, mtmId: global.config.userConfig.partnerId}}
      yield getDeviceList(deviceData, params)
      yield fetchDeviceDashboard(deviceData,params)
    }
    else {
      yield put(showMessage({ header: "MDM Error", content: res.message, type: "error" }));
    }
  } catch (err) {
    console.log("saga error deleting app", err);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to Delete Applications",
        type: "error",
      })
    );
  }
}
function* deleteDeviceType(data, params) {
  try {
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.deleteDeviceType({ ...data.data, token })
    if (res && res.status == "success") {
      yield getDeviceTypeList(params)
    }
    else {
      yield put(showMessage({ header: "MDM Error", content: res.message, type: "error" }));
    }
  } catch (err) {
    console.log("saga error deleting Device Type", err);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to Delete Device Type",
        type: "error",
      })
    );
  }
}
function* fetchAuthToken(data, params) {
  const token = params.mcxClient.getActiveToken()
  yield put({ type: REFRESH_AUTH_TOKEN, data: token })
}
function* updateConfig(data, params) {
  try {
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.updateConfig({ ...data.data, token })
    if (res && res.status == "fail") {
      yield put(showMessage({ header: "MDM Error", content: res.message, type: "error" }));
    } else {
      yield getConfigList(data, params)
    }
  } catch (error) {
    console.log("saga error deleting app", error);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to Update Configurations",
        type: "error",
      })
    );
  }
}
function* fetchConfigApp(data, params) {
  try {
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.fetchConfigApp({ ...data.data, token })
    if (res && res.status == "success" && Array.isArray(res.data))
      yield put({ type: SET_CONFIG_APPLICATION, data: res.data })
  } catch (err) {
    console.log("saga error fetching config app relation", err);
    yield put(
      showMessage({
        header: "Admin Error",
        content: "Failed to Fetch Config App Relation",
        type: "error",
      })
    );
  }
}
function* addDevice(data, params) {
  try {
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.addDevice(data.data, token)
    if (res && res.status == "fail") {
      yield put(showMessage({ header: "MDM Error", content: res.message, type: "error" }));
    } else {
      let deviceData = {data: {...data.data, mtmId: global.config.userConfig.partnerId}}
      yield getDeviceList(deviceData, params)
      yield fetchDeviceDashboard(deviceData,params)
    }
  } catch (error) {
    console.log("saga error adding device", error);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to Add Device",
        type: "error",
      })
    );
  }
}
function* addDeviceType(data, params) {
  try {
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.addDeviceType(data.data, token)
    if (res && res.status == "fail") {
      yield put(showMessage({ header: "MDM Error", content: res.message, type: "error" }));
    } else {
      yield getDeviceTypeList(params)
    }
  } catch (error) {
    console.log("saga error adding device Type", error);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to Add Device Type",
        type: "error",
      })
    );
  }
}
function* updateDevice(data, params) {
  try {
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.updateDevice(data.data, token)
    if (res && res.status == "fail") {
      yield put(showMessage({ header: "MDM Error", content: res.message, type: "error" }));
    } else {
      let deviceData = {data: {...data.data, mtmId: global.config.userConfig.partnerId}}
      yield getDeviceList(deviceData, params)
    }
  } catch (error) {
    console.log("saga error updating device", error);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to Updating Device",
        type: "error",
      })
    );
  }
}
function* uploadApp(data) {
  yield put(
    showMessage({
      header: data.data.header,
      content: data.data.content,
      type: data.data.type,
    })
  );
}

function* pullLogs(data, params) {
  try {
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.pullLogs({ ...data.data, token })
    yield put(
      showMessage({
        header: "MDM",
        content: "Notified to Client",
        type: "success",
      })
    );
  } catch (error) {
    console.log("saga error updating device", error);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to Pull Logs",
        type: "error",
      })
    );
  }
}
function* addConfigAppReln(data, params) {
  try {
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.addConfigAppReln(data.data, token)
  } catch (error) {
    console.log("saga error adding congig app reln", error);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to Add Config App reln",
        type: "error",
      })
    );
  }
}
function* getTenantId(data, params) {
  try {
    const res = yield params.mcxClient.mdm.getTenantId(data.data)
    if (res && res.data) {
      yield put({ type: SET_TENANT_ID, data: res.data })
    }
  } catch (error) {
    console.log("saga error fetching tenant id", error);
    yield put({ type: SET_TENANT_ID, data: {} })
  }
}

function* getTenantList(data, params) {
  try {
    const res = yield params.mcxClient.mdm.getTenantList(data.data)
    if (res && res.data) {
      yield put({ type: SET_TENANT_LIST, data: res.data })
    }
  } catch (error) {
    console.log("saga error fetching tenant list", error);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to fetch tenant list",
        type: "error",
      })
    );
  }
}
function* getDeviceIdList(data, params) {
  try {
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.getDeviceIdList({ ...data.data, token })
    console.log("device id list ", res.data);
    if (res && res.data && res.data.status === "success") {
      yield put({ type: SET_DEVICE_ID_LIST, data: res.data.data })
    }
    else {
      console.log("saga error fetching device id list", res.data);
      yield put(
        showMessage({
          header: "MDM Error",
          content: "Failed to fetch device id list",
          type: "error",
        })
      );
    }
  } catch (error) {
    console.log("saga error fetching device id list", error);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to device id list",
        type: "error",
      })
    );
  }
}
function* fetchDeviceInfo(data,params){
  try{
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.fetchDeviceInfo({...data.data,token})
    if (res && res.data && res.data.status === "success") {    
      yield put({ type: SET_DEVICE_INFO, data: res.data.data  })
    }
      else {
      yield put(showMessage({ header: "MDM Error", content: res?.data?.message || "Failed to Fetch Device Info", type: "error" }));
    }
    
  }
  catch(error){
    console.log("saga error fetching device info", error);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to Fetch Device Info",
        type: "error",
      })
    );
  }
}
function* fetchDeviceDashboard(data,params){
  try{
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.fetchDeviceDashboard({...data.data,token})
    if (res && res.data && res.data.status == "success") {
      yield put({ type: SET_DEVICE_DASHBOARD, data: res.data.data })
    }
      else if(res && res.data && res.data.status == "fail") {
        yield put({ type: SET_DEVICE_DASHBOARD, data: {} })
      yield put(showMessage({ header: "MDM Error", content: res?.data?.message || "Failed to Fetch Device DashBoard", type: "error" }));
    }
    
  }
  catch(error){
    console.log("saga error fetching dashboard device", error);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to Fetch Device DashBoard",
        type: "error",
      })
    );
  }
}
function* updateDeviceByDeviceId(data, params) {
  try {
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.updateDeviceByDeviceId(data.data, token)
    if (res && res.data && res.data.status == "fail") {
      yield put(showMessage({ header: "MDM Error", content: res.message, type: "error" }));
    }
  } catch (error) {
    console.log("saga error updating device", error);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to Updating Device",
        type: "error",
      })
    );
  }
}
function* updateDeviceByUser(data, params) {
  try {
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.updateDeviceByUser(data.data, token)
    if (res && res.data && res.data.status == "fail") {
      yield put(showMessage({ header: "MDM Error", content: res.message, type: "error" }));
    }
  } catch (error) {
    console.log("saga error updating device", error);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to Updating Device",
        type: "error",
      })
    );
  }
}
function* getDeviceId(data, params) {
  try {
    console.log("deviceID", data);
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.getDeviceId({ ...data.data, token })
    if (res && res.data && res.data.status == "fail") {
      yield put(showMessage({ header: "MDM Error", content: res.message, type: "error" }));
    } else if (res && res.data && res.data.data) {
      yield put({ type: SET_DEVICE_ID, data: res.data.data })
    }
  } catch (error) {
    console.log("saga error getting deviceId", error);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to getting Device id",
        type: "error",
      })
    );
  }
}
function* fetchConfigurationByDeviceId(data, params) {
  try {
    const token = params.mcxClient.getActiveToken()
    const res = yield params.mcxClient.mdm.getConfigByDevice({ ...data.data, token });
    console.log("config devices res", res);
    if (res && res.data && res.data.status == "fail") {
      yield put(showMessage({ header: "MDM Error", content: res.message, type: "error" }));
    } else if (res && res.data && res.data.data) {
      yield put({ type: UPDATE_CONFIG_DEVICE, data: res.data.data })
    }
  } catch (error) {
    console.log("saga error getting deviceId", error);
    yield put(
      showMessage({
        header: "MDM Error",
        content: "Failed to getting Device id",
        type: "error",
      })
    );
  }
}

function* addProductCatalog(data, params) {
  try {
    const res = yield params.mcxClient.admin.addProductCatalog(data.data);
    if (res) {
      yield put(showMessage({ header: "Product catalog", content: "Product successfully created!!", type: "success" }));
    } else {
      yield put(showMessage({ header: "Product catalog", content: res.message, type: "error" }));
    }
  } catch (error) {
    console.log("saga error adding device", error);
    yield put(showMessage({ header: "Product catalog", content: "", type: "error" }));
  }
}

function* editProductCatalog(data, params) {
  try {
    const res = yield params.mcxClient.admin.editProductCatalog(data.data);
    if (res) {
      yield put(showMessage({ header: "Product catalog", content: "Product successfully updted!!", type: "success" }));
    } else {
      yield put(showMessage({ header: "Product catalog", content: res.message, type: "error" }));
    }
  } catch (error) {
    console.log("saga error adding device", error);
    yield put(showMessage({ header: "Product catalog", content: "", type: "error" }));
  }
}

function* getProductCatalog(data, params) {
  try {
    const res = yield params.mcxClient.admin.getProductCatalog(data.data);
    if (res) {
      console.log("getProductCatalog res", res);
      yield put({ type: UPDATE_PRODUCT_CATALOG, data: res });
    } else {
      yield put(showMessage({ header: "Product catalog", content: "", type: "error" }));
    }
  } catch (error) {
    console.log("saga error adding device", error);
    yield put(showMessage({ header: "Product catalog", content: "", type: "error" }));
  }
}

function* createOrderForProduct(data, params) {
  try {
    const res = yield params.mcxClient.admin.createOrderForProduct(data.data);
    if (res) {
      console.log("createOrderForProduct res", res);
      yield put(showMessage({ header: "Product catalog", content: "Order for product created successfully!!", type: "success" }));
    } else {
      yield put(showMessage({ header: "Product catalog", content: "", type: "error" }));
    }
  } catch (error) {
    console.log("saga error buying catalog", error);
    yield put(showMessage({ header: "Product catalog", content: "", type: "error" }));
  }
}

function* updateOrderForProduct(data, params) {
  try {
    const res = yield params.mcxClient.admin.updateOrderForProduct(data.data);
    if (res && res.success) {
      console.log("updateOrderForProduct res", res);
      //"newOrderStatus": "Declined"
      if (data.data && data.data.newOrderStatus === "Declined") {
        yield put(showMessage({ header: "Product catalog", content: "Order is declined successfully!!", type: "success" }));
      } else {
        yield put(showMessage({ header: "Product catalog", content: "Order for product created successfully!!", type: "success" }));
      }
    } else {
      if (res && res.errorTuples) {
        yield put({ type: REJECTED_ORDER, data: res.errorTuples });
      }
      if (res && res.errorMsg) {
        yield put(showMessage({ header: "Product catalog", content: res.errorMsg, type: "error" }));
      } else {
        yield put(showMessage({ header: "Product catalog", content: "Order is rejected!!", type: "error" }));
      }
    }
    if (data.data && data.data.sellerMTMId) {
      const filterData = {
        "sellerMTMId": data.data.sellerMTMId,
        "apiType": "sale",
        "paginationModel": {
          "pageSize": 10,
          "page": 0,
        }
      }
      const resp = yield params.mcxClient.admin.getOrderList(filterData);
      if (resp) {
        console.log("getOrderList res saga", resp);
        yield put({ type: UPDATE_ORDER_LIST, data: resp });
      } else {
        yield put(showMessage({ header: "Product catalog", content: "", type: "error" }));
      }
    }
  } catch (error) {
    console.log("saga error order", error);
    yield put(showMessage({ header: "Product catalog", content: "", type: "error" }));
  }
}


function* getOrderList(data, params) {
  try {
    const res = yield params.mcxClient.admin.getOrderList(data.data);
    if (res) {
      console.log("getOrderList res saga", res);
      yield put({ type: UPDATE_ORDER_LIST, data: res });
    } else {
      yield put(showMessage({ header: "Product catalog", content: "", type: "error" }));
    }
  } catch (error) {
    console.log("saga error buying catalog", error);
    yield put(showMessage({ header: "Product catalog", content: "", type: "error" }));
  }
}

function* fetchSubscriptions(data, params) {
  try {
    const res = yield params.mcxClient.admin.fetchSubscriptions(data.data);
    if (res && res.data && res.data.data) {
      console.log("fetchSubscriptions res saga", res);
      yield put({ type: UPDATE_SUBSCRIPTION_LIST, data: res.data.data });
    } else {
      yield put(showMessage({ header: "Subscriptions", content: "", type: "error" }));
    }
  } catch (error) {
    console.log("saga error Subscriptions", error);
    yield put(showMessage({ header: "Subscriptions", content: "", type: "error" }));
  }
}

function* getCurrentActiveSubscriptionsForUser(data, params) {
  try {
    const res = yield params.mcxClient.admin.getCurrentActiveSubscriptionsForUser(data.data);
    if (res) {
      console.log("getCurrentActiveSubscriptionsForUser res saga", res);
      if (res.result && res.result.data) {
        yield put({ type: UPDATE_CURRENT_ACTIVE_SUBSCRIPTION_FOR_USER, data: res.result.data });
      } else {
        yield put({ type: UPDATE_CURRENT_ACTIVE_SUBSCRIPTION_FOR_USER, data: [] });
      }
    } else {
      yield put({ type: UPDATE_CURRENT_ACTIVE_SUBSCRIPTION_FOR_USER, data: [] });
    }
  } catch (error) {
    console.log("saga error getCurrentActiveSubscriptionsForUser", error);
    yield put({ type: UPDATE_CURRENT_ACTIVE_SUBSCRIPTION_FOR_USER, data: [] });
  }
}
function* getCurrentActiveSubscriptions(data, params) {
  try {
    const res = yield params.mcxClient.admin.getCurrentActiveSubscriptions(data.data);
    if (res) {
      console.log("getCurrentActiveSubscriptions res saga", res);
      if (res.data) {
        yield put({ type: UPDATE_CURRENT_ACTIVE_SUBSCRIPTION, data: res.data });
      } else {
        yield put({ type: UPDATE_CURRENT_ACTIVE_SUBSCRIPTION, data: [] });
      }
    }
  } catch (error) {
    console.log("saga error getCurrentActiveSubscriptions", error);
    yield put({ type: UPDATE_CURRENT_ACTIVE_SUBSCRIPTION, data: [] });
  }
}

function* allocateDeallocateUserFromTenantSubscription(data, params) {
  try {
    const res = yield params.mcxClient.admin.allocateDeallocateUserFromTenantSubscription(data.data);
    console.log("allocateDeallocateUserFromTenantSubscription res saga", res);
    if (res) {
      yield put({ type: UPDATE_ALLOCATE_USER_FROM_TENANT_SUBSCRIPTION, data: res });
    } else {
      yield put({ type: UPDATE_ALLOCATE_USER_FROM_TENANT_SUBSCRIPTION, data: [] });
    }
  } catch (error) {
    console.log("saga error allocateDeallocateUserFromTenantSubscription", error);
    yield put({ type: UPDATE_ALLOCATE_USER_FROM_TENANT_SUBSCRIPTION, data: [] });
  }

  try {
    let dataa = {
      "userId": data.data.userId
    }
    const res = yield params.mcxClient.admin.getCurrentActiveSubscriptionsForUser(dataa);
    if (res) {
      console.log("getCurrentActiveSubscriptionsForUser res saga", res);
      if (res.result && res.result.data) {
        yield put({ type: UPDATE_CURRENT_ACTIVE_SUBSCRIPTION_FOR_USER, data: res.result.data });
      } else {
        yield put({ type: UPDATE_CURRENT_ACTIVE_SUBSCRIPTION_FOR_USER, data: [] });
      }
    } else {
      yield put({ type: UPDATE_CURRENT_ACTIVE_SUBSCRIPTION_FOR_USER, data: [] });
    }
  } catch (error) {
    console.log("saga error getCurrentActiveSubscriptionsForUser", error);
    yield put({ type: UPDATE_CURRENT_ACTIVE_SUBSCRIPTION_FOR_USER, data: [] });
  }
}

export default handleNewMessage;
