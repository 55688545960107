import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Header from "../components/Navigation/Header";
import SubscriptionList from "./subscriptionlist";

const SubscriptionManagement = (props) => {
    const { isAuthenticated, navigateToLogin } = props;
    return (
        <div>
            <Header page={'subscriptionManagement'} />
            <div className="main-nav-body">
                <div className="admin-grid" style={{display:'block'}}>
                    <div className="wrap-1 l1">
                        <SubscriptionList></SubscriptionList>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ auth }) => {
    const { user, isAuthenticated } = auth;
    return {
        user,
        isAuthenticated,
    };
};

const mapDispatchToProps = (dispatch) => {
    bindActionCreators(
        {
            navigateToLogin: () => push("/"),
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionManagement);