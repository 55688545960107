// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.contactView{
    flex-direction: row;
    display: flex;
}
.headerText{
    font-size: 12px;
    color: #000;
    font-weight: 700;
    margin: 0px;
    text-align: left;
    font-family: 'Muli';
}

.textVal{
    font-size: 12px;
    color: #aeaeae;
    font-weight: 700;
    margin: 0px;
    text-align: left;
    font-family: 'Muli';
}

.subcriber-details-row-grid {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-template-rows: 20px;
    gap: 0px 2px;
    height: 30px;
    border-bottom: 0.5px solid rgba(34,36,38,.15);
    /* // border-color: rgb(255,255,255,0.1);
    // padding: 8px 4px 8px 8px; */
    margin-top: 5px;
    /* // color: white; */
    align-content:center;
    grid-template-areas: "d1 d2";
}
.d1 { 
    grid-area: d1;
    align-self: self-start;
    font-family: 'Muli';
}

.d2 { 
    grid-area: d2;
    font-family: 'Muli';
}

.f-text-header-18b{
    font-size: 18px;
    font-weight: 1000;
    font-family: 'muli';
    margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/ContactList/contactRow.css"],"names":[],"mappings":";AACA;IACI,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,WAAW;IACX,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,WAAW;IACX,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,gCAAgC;IAChC,wBAAwB;IACxB,YAAY;IACZ,YAAY;IACZ,6CAA6C;IAC7C;kCAC8B;IAC9B,eAAe;IACf,qBAAqB;IACrB,oBAAoB;IACpB,4BAA4B;AAChC;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":["\n.contactView{\n    flex-direction: row;\n    display: flex;\n}\n.headerText{\n    font-size: 12px;\n    color: #000;\n    font-weight: 700;\n    margin: 0px;\n    text-align: left;\n    font-family: 'Muli';\n}\n\n.textVal{\n    font-size: 12px;\n    color: #aeaeae;\n    font-weight: 700;\n    margin: 0px;\n    text-align: left;\n    font-family: 'Muli';\n}\n\n.subcriber-details-row-grid {\n    display: grid;\n    grid-template-columns: 1fr 1.5fr;\n    grid-template-rows: 20px;\n    gap: 0px 2px;\n    height: 30px;\n    border-bottom: 0.5px solid rgba(34,36,38,.15);\n    /* // border-color: rgb(255,255,255,0.1);\n    // padding: 8px 4px 8px 8px; */\n    margin-top: 5px;\n    /* // color: white; */\n    align-content:center;\n    grid-template-areas: \"d1 d2\";\n}\n.d1 { \n    grid-area: d1;\n    align-self: self-start;\n    font-family: 'Muli';\n}\n\n.d2 { \n    grid-area: d2;\n    font-family: 'Muli';\n}\n\n.f-text-header-18b{\n    font-size: 18px;\n    font-weight: 1000;\n    font-family: 'muli';\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
