(function (exports) {
  const EventEmitter = require("events").EventEmitter;

  class AletrModule extends EventEmitter {
    constructor() {
      super();
      this.headers = {
        "Content-Type":
          'application/org.3gpp.mcptt.user-profile+xml; charset="utf-8" ',
      };
      this.isAlertProcessor = false;
      this.alertStatusRange = {
        start: "33000",
        end: "65500"
      }

      this.userStatusRangeOne = {
        start: "33000",
        end: "33055"
      }

      this.userStatusRangeTwo = {
        start: "65024",
        end: "65031"
      }

      this.paStatusRangeTwo = {
        start: "64211",
        end: "64450"
      }

      //1. 64211 - 64450 -> pa alerts
      //2. 33001- 33055 and 65024 - 65031 -> user status

    }
    handleStatusMessage(message) {
      console.log("INCOMING TASK EVENT handleStatusMessage -----------------------------------------------",message);
      if (this.isAlertProcessor) {
        if ((message.tetraCode >= this.alertStatusRange.start && message.tetraCode < this.alertStatusRange.end) || message.tetraCode === '0') {
          this.emit("ALERT_MESSAGE", message);
        }
      } else {
        if ((message.tetraCode >= this.userStatusRangeOne.start && message.tetraCode <= this.userStatusRangeOne.end)) {
          this.emit("ALERT_MESSAGE", message);
        }
        else if ((message.tetraCode >= this.userStatusRangeTwo.start && message.tetraCode <= this.userStatusRangeTwo.end)) {
          this.emit("ALERT_MESSAGE", message);
        }
        else if ((message.tetraCode >= this.paStatusRangeTwo.start && message.tetraCode <= this.paStatusRangeTwo.end)) {
          this.emit("ALERT_MESSAGE", message);
        }
        else if (message.tetraCode === '0') {
          this.emit("ALERT_MESSAGE", message);
        }
        else if (message.tetraCode === this.alertStatusRange.end) {
          this.emit("REFRESH_TASK_LIST", message);
        }
      }
    }

    setAlertProcessor(flag) {
      this.isAlertProcessor = flag;
      console.log("setAlertProcessor ----------", this.isAlertProcessor);
    }

  }
  exports.AletrModule = AletrModule;
})(typeof exports === "undefined" ? (this["AletrModule"] = {}) : exports);
