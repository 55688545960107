(function (exports) {
  const mcpttExtension = "mcptt";
  class FaProfile {
    constructor(data) {
      this.FaProfile = {
        "@": {
          "user-profile-index": data.profileNumber,
          "XUI-URI": "",
          "xsi:schemaLocation":
            "urn:3gpp:mcptt:user-profile:1.0 mcptt-user-profile.xsd",
          "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instanc",
          xmlns: "urn:3gpp:mcptt:userprofile:1.0",
          "xmlns:cp": "urn:ietf:params:xml:ns:common-policy",
          "xmlns:type": "urn:consort:faprofile:1.0",
        },
        Name: data.profileName,
        Status: true,
        Common: {
          "@": {
            index: 0,
          },
          MCPTTUserID: getUserList(data),
          PrivateCall: {
            //This interior node is a placeholder for the MCPTT private call configuration.
            PrivateCallList: {
              //
              "@": {
                index: 0,
              },
              PrivateCallURI: getPrivateCallList(data),
            },
            ManualCommence: true, //When set to "true" the MCPTT user is authorised to make a MCPTT private call in manual commencement mode.When set to "false" the MCPTT user is not authorised to make a MCPTT private call in manual commencement mode.
            AutoCommence: true, //When set to "true" the MCPTT user is authorised to make a MCPTT private call in automatic commencement mode. When set to "false" the MCPTT user is not authorised to make a MCPTT private call in automatic commencement
          },
        },
        OnNetwork: {
          "@": {
            index: 0,
          },
          MCPTTGroupList: getGroupCallList(data),
          ImplicitAffiliations: {
            //This interior node is a placeholder for one or more implicit affiliation configuration.
            "@": {
              "xml:lang": "en-GB",
              index: 0,
            },
            entry: {
              //This interior node is a placeholder for the details of the on-network MCPTT groups
              "@": {
                entryInfo: "UsePreConfigured",
                index: 0,
              },
              "uri-entry": "", //This leaf node indicates a MCPTT group ID to which the MCPTT user is implicitly affiliated to.
              "display-name": "", //This leaf node contains a human readable name that corresponds to the MCPTT Group ID.
              anyExt: "",
            },
            anyExt: "",
          },
          anyExt: {
            functionalAliasList: {
              entry: {
                "@": {
                  index: 0,
                },
                "uri-entry": `${data.faList.uri}`,
                "display-name": {
                  "@": {
                    "xml.lang": "en-US",
                  },
                  "#": data.faList.description,
                },
              },
            },
            tetra : data.tetra
          },
        },
      };
    }
  }

  function getUserList(profile) {
    var users = profile.mcpttId;

    var userList = [];

    /*
          fas = [
              {name:"cheifController", desc:"Cheif Controller",...}
          ]
       */

    if (users && users.length) {
      users.forEach((element, i) => {
        userList[i] = {
          "@": {
            index: i,
          },
          "uri-entry": `${element.uri}`,
          "display-name": {
            "@": {
              "xml.lang": "en-US",
            },
            "#": element.description,
          },
        };
      });
    }

    return userList;
  }

  function getGroupCallList(profile) {
    var groupList = profile.groups;

    var groupCallList = [];

    if (groupList && groupList.length) {
      groupList.forEach((element, i) => {
        groupCallList[i] = {
          "@": {
            index: i,
          },
          "uri-entry": `${element.tetraID}-${mcpttExtension}-group`,
          "display-name": {
            "@": {
              "xml.lang": "en-US",
            },
            "#": element.description,
          },
          TetraID: element.tetraID,
          Organization: profile.homeOrgNo,
          Subscriber_type: element.subscriberType,
          ProfileName: element.profileName,
          OrgName: "",
        };
      });
      return groupCallList;
    } else {
      return {
        "@": {
          "xml:lang": "en-GB",
          index: 0,
        },
        entry: {
          //This interior node is a placeholder for the details of the on-network MCPTT groups
          "@": {
            entryInfo: "UsePreConfigured",
            index: 0,
          },
          "uri-entry": "", //This leaf node indicates a MCPTT group ID to which the MCPTT user is implicitly affiliated to.
          "display-name": "", //This leaf node contains a human readable name that corresponds to the MCPTT Group ID.
          anyExt: "",
        },
        anyExt: "",
      };
    }
  }

  function getPrivateCallList(profile) {
    var userList = profile.privateUsers;

    var privateCallList = [];

    if (userList && userList.length) {
      userList.forEach((element, i) => {
        //Add the subscriber to scbscriber List for SIP register
        //addToSubscriber(element);
        //Make an array for profile
        privateCallList[i] = {
          "@": {
            index: i,
          },
          "uri-entry": `${element.tetraID}-${mcpttExtension}`,
          "display-name": {
            "@": {
              "xml.lang": "en-US",
            },
            "#": element.description,
          },
          Tetra_ID: element.tetraID,
          Subscriber_type: element.subscriberType,
          Reg_status: element.regStatus,
          Reg_date_time: element.regDateTime,
          RegNode: element.regNodeNo,
          Organization: profile.homeOrgNo,
          Domain: "Tetra",
          Groups: getGroupList(element.Group),
          SubscriberID: element.subscriberId,
          ProfileName: element.profileName,
          OrgName: "",
        };
      });
    }

    return privateCallList;
  }

  function getGroupList(list) {
    var groupList = [];
    //logger.info.log("info","Group List",list);
    if (list) {
      list.forEach((group, i) => {
        groupList[i] = {
          "@": {
            index: i,
          },
          Always_scanned: group["Always scanned"],
          Attached: group["Attached"],
          GroupSSI: group["GroupSSI"],
        };
      });
    } else {
      groupList = null;
    }
    return groupList;
  }

  exports.FaProfile = FaProfile;

})(typeof exports === 'undefined' ? this['FaProfile'] = {} : exports);


/*
   profile={
        profileName: "",// toLower,single word //cheifcontroller
        mcpttID: [
            {uri:"", description:""},
            {uri:"mcptt Id", description:"user role"}, //4004-mcptt@consort.org
        ],
        CallerDescr: "Cheif Controller",
        faList:{
            uri: "",
            description:"Cheif Controller",
            trainTab: bool,
            alertTab: bool,
            communicationTab: bool,
            baseStation: bool,
            trainMovement: "",
            grabAllowed: ""
        }
    }
*/

//module.exports = exports = FaProfile;
