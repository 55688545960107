import React from "react";
import { DataGrid } from '@mui/x-data-grid';

const RejectOrderCauses = (props) => {
    const { errorTuples } = props;

    const columns = [
        {
            field: 'service', headerName: 'Service', width: 250, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
        },
        {
            field: 'ueType', headerName: 'UeType', width: 300, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
        },
        {
            field: 'remainingDays', headerName: 'Remaining Days', width: 250, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center",
        },
    ];

    return (
        <div style={{ height: 300, backgroundColor: "#252524" }}>
            <DataGrid
                rows={errorTuples}
                columns={columns}
                rowHeight={68}
                hideFooter
                hideFooterPagination
                hideFooterSelectedRowCount
                disableColumnMenu
                localeText={{ noRowsLabel: "" }}
                getRowSpacing={params => ({
                    top: params.isFirstVisible ? 0 : 0,
                    bottom: params.isLastVisible ? 0 : 0
                })}
                getRowId={row => row.service}
                sx={{
                    boxShadow: 2,
                    border: "none",
                    borderBottom: "none",
                    '& .MuiDataGrid-cell:hover': {
                        color: '#ffb01f',
                    },
                    '& .MuiDataGrid': {
                        border: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        borderColor: "#404040",
                    },
                    '& .MuiDataGrid-columnHeader': {
                        borderColor: "gray",
                    },
                    '& .MuiDataGrid-filler': {
                        backgroundColor: "#262626",
                    },
                }}

            />
        </div>
    )
}

export default RejectOrderCauses;