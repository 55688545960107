
import React, { useState, useEffect } from "react";
import ProductType from "./producttype";
import PartnerProductType from "./partnerproducttype";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { addProductCatalog, updateProductCatalog } from "../modules/productcatalogstate";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MobileDeviceProductType from "./mobileDeviceProductType";
import UETypeAndServices from "./ueTypeAndServices";

import { Box, Grid } from '@mui/material';

const AddProduct = (props) => {
    const { addProductCatalog, deviceTypeList, updateProductCatalog } = props;
    let productDetails = {
        productSku: "",
        productType: "Plan",
        creatorPartnerId: "",
        productSubType: "Operator",
        productImage: "",
        sellable: true,
        enabled: true,
        shortDescription: "",
        longDescription: "",
        productJson: {},
        productBrand: "",
        productManufacturer: "",
        priceType: "Prepaid",
        frequency: "None",
        numInstallments: 0,
        price: 0,
        priceUnit: "day",
    }

    const frequencyList = ["None", "Monthly", "Quarterly", "Yearly"];
    const priceTypeList = ["Prepaid", "Postpaid", "Emi", "Rental"];

    const productTypeDataa = [
        {
            type: "plan",
            subType: "Operator"
        },
        {
            type: "plan",
            subType: "Partner"
        }
    ]

    const productType = [
        {
            text: "Plan",
            val: "plan"
        },
        {
            text: "MobileDevice",
            val: "MobileDevice"
        },
        // {
        //     text: "Edge Device",
        //     val: "edge"
        // },
        // {
        //     text: "Bundle",
        //     val: "bundle"
        // }
    ]

    const [productTypeData, setProductTypeData] = useState([productTypeDataa]);
    const [vlProductType, setvlProductType] = useState("plan");
    const [vlProductSubType, setvlProductSubType] = useState("Operator");
    const [subProductTypes, setSubProductTypes] = useState(["Operator", "Partner"]);
    const [productDetail, setProductDetail] = useState(productDetails);
    const [productJson, setProductJson] = useState(productDetails.productJson);
    const [priceTypeUpList, setPriceTypeUpList] = useState(priceTypeList);
    const [frquencyUpList, setFrquencyUpList] = useState(frequencyList);
    const [showSubmitBtn, setShowSubmitBtn] = useState(true);
    const [file, setFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: "#252524",
            //backgroundColor: theme.palette.background.default,
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            width: "100%",
            //height: "70%",
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
        },
        formControlFA: {
            width: "100%",
            height: "65px",
            marginTop: "2px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600,
            backgroundColor: "#383736"
        },
        listItemFA: {
            padding: "5px",
            fontFamily: "Muli",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        productTypeClass: {
            width: "94%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            border: "solid",
            borderRadius: 10,
            borderWidth: "2px",
            borderColor: "#4B4A4A",
            marginTop: 5,
            marginBottom: 10
        },
        imgTypeClass: {
            width: "45%",
            height: 300,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "left",
            alignItems: "left",
            border: "solid",
            borderRadius: 10,
            borderWidth: "2px",
            borderColor: "#4B4A4A",
            marginTop: 27,
            marginBottom: 5,
            marginRight: 25
        },
        divList: {
            width: '100%',
            marginBottom: '13px',
        },
        lblStyle: {
            width: '100%',
            color: "#D0D3D4",
            fontSize: 18,
            fontWeight: 600,
        },
        lblHeader: {
            width: '100%',
            color: "#D0D3D4",
            fontSize: 20,
            fontWeight: 700
        },
        tetraSwitch: {
            fontFamily: "Muli",
            marginTop: "2px",
            marginRight: "1px",
            marginLeft: "0px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        datePickerStyle: {
            color: "black",
            padding: "10px",
            fontFamily: "Sans-Serif",
            width: '60%',
            border: "solid",
            borderRadius: 20,
            borderWidth: "2px",
            borderColor: "#D3D3D3",
        },

        // box {
        //         display: flex;
        //         flex-wrap: wrap; /* Optional. only if you want the items to wrap */
        //         justify-content: center; /* For horizontal alignment */
        //         align-items: center; /* For vertical alignment */
        // }

        errorLbl: {
            width: '100%',
            color: "red",
            fontSize: 18,
            fontWeight: 600,
        }

    }));

    useEffect(() => {
        let userType = getUserType();
        if (userType === "pr") {
            setSubProductTypes(["Partner"]);
            setvlProductSubType("Partner");
            productDetails = { ...productDetails, productSubType: "Partner", priceUnit: "subscriber/day" };
        } else {
            setSubProductTypes(["Operator", "Partner"]);
        }

        if (props.productCatalog) {
        } else {
            getPriceTypeUpdatedList(productDetails.productSubType);
            let frList = getFrequencyUpdatedList(productDetails.priceType);
            if (frList && frList.length > 0) {
                productDetails = { ...productDetails, frequency: frList[0] };
                setProductDetail(productDetails);
            }
        }

    }, [])

    useEffect(() => {
        console.log("mdm deviceTypeList", deviceTypeList);
        let productData = makeProductTypeData(deviceTypeList);
        if (props.productCatalog) {
            console.log("productCatalog addproduct", props.productCatalog);
            let productCatalogDetail = { ...props.productCatalog };
            if (props.productCatalog.productJson) {
                let productJsonData = props.productCatalog.productJson;
                setProductJson(productJsonData);
            }
            if (props.productCatalog.productType) {
                let productTypeVal = productType.filter(pt => pt.text === props.productCatalog.productType);
                console.log("productCatalog productTypeVal", productTypeVal, props.productCatalog.productType);
                if (productTypeVal && productTypeVal.length > 0) {
                    setvlProductType(productTypeVal[0].val);
                }
            }

            if (props.productCatalog.productSubType) {
                let productSubTypeVal = [];
                if (props.productCatalog.productType === "MobileDevice") {
                    productSubTypeVal = productData.filter(pt => (pt.subType === getProductSubTypeForMobileDeviceFromId(props.productCatalog.productSubType)));
                } else {
                    productSubTypeVal = productData.filter(pt => (pt.subType === props.productCatalog.productSubType));
                }
                console.log("productCatalog productSubTypeVal", productSubTypeVal, props.productCatalog.productSubType);
                if (productSubTypeVal && productSubTypeVal.length > 0) {
                    setvlProductSubType(productSubTypeVal[0].subType);

                    let subProductTypes = productSubTypeVal.map(subtype => subtype.subType);
                    setSubProductTypes(subProductTypes);

                    let priceUnitVal = "subscriber";
                    if (productSubTypeVal[0].subType === "Operator") {
                        priceUnitVal = "day";
                    } else if (props.productCatalog.productType === "MobileDevice") {
                        priceUnitVal = "device";
                    }
                    productCatalogDetail = { ...productCatalogDetail, priceUnit: priceUnitVal };
                    let pricetypeListt = getPriceTypeUpdatedList(productSubTypeVal[0].subType);
                    if (pricetypeListt && pricetypeListt.length > 0) {
                        productCatalogDetail = { ...productCatalogDetail, priceType: pricetypeListt[0] };
                    }
                }
            }
            if (props.productCatalog.priceType) {
                let frList = getFrequencyUpdatedList(props.productCatalog.priceType);
                let priceUnitVal = "day";
                if (frList && frList.length > 0) {
                    if (frList[0] === "None" && productCatalogDetail.productSubType === "Operator") {
                        productCatalogDetail = { ...productCatalogDetail, frequency: frList[0], priceUnit: priceUnitVal };
                    } else {
                        if (props.productCatalog.priceType === "Prepaid") {
                            if (productCatalogDetail.productSubType === "Partner") {
                                priceUnitVal = "subscriber" + "/day";
                                productCatalogDetail = { ...productCatalogDetail, frequency: frList[0], priceUnit: priceUnitVal };
                            } else if (productCatalogDetail.productType === "MobileDevice") {
                                priceUnitVal = "device";
                                productCatalogDetail = { ...productCatalogDetail, frequency: frList[0], priceUnit: priceUnitVal };
                            } else {
                                priceUnitVal = "day";
                                productCatalogDetail = { ...productCatalogDetail, frequency: frList[0], priceUnit: priceUnitVal };
                            }
                        } else {
                            if (productCatalogDetail.productType === "MobileDevice") {
                                priceUnitVal = "device" + "/" + frList[0];
                                productCatalogDetail = { ...productCatalogDetail, frequency: frList[0], priceUnit: priceUnitVal };
                            } else {
                                priceUnitVal = "subscriber" + "/" + frList[0];
                                productCatalogDetail = { ...productCatalogDetail, frequency: frList[0], priceUnit: priceUnitVal };
                            }
                        }
                    }
                }
            }
            setProductDetail(productCatalogDetail);
            console.log("productCatalogDetail", productCatalogDetail);
            if (props.productCatalog.openMode !== "view") {
                setShowSubmitBtn(true);
            } else {
                setShowSubmitBtn(false);
            }
        }
    }, [deviceTypeList])

    const makeProductTypeData = (mobiles) => {
        const plans = [
            {
                type: "plan",
                subType: "Operator"
            },
            {
                type: "plan",
                subType: "Partner"
            }
        ]
        let finalProductsData = [...plans];
        const mobilesBrandFilter = mobiles && mobiles.map((mobile) => {
            let mob = {
                type: "MobileDevice",
                subType: mobile.brand + " " + mobile.manufacturer
            }
            return mob;
        });
        if (mobilesBrandFilter) {
            finalProductsData = [...finalProductsData, ...mobilesBrandFilter];
        }
        console.log("finalProductsData ", finalProductsData);
        setProductTypeData(finalProductsData);
        return finalProductsData;
    }

    const getProductSubTypeForMobileDevice = (productSubType) => {
        if (productDetail.productType === "MobileDevice") {
            if (deviceTypeList && deviceTypeList.length > 0) {
                let productSubTypeIds = deviceTypeList.filter(device => (device.brand + " " + device.manufacturer) === productSubType);
                if (productSubTypeIds && productSubTypeIds.length > 0) {
                    if (productSubTypeIds[0].id) {
                        return productSubTypeIds[0].id;
                    }
                }
            }
        }
        return productSubType;
    }

    const getProductSubTypeForMobileDeviceFromId = (productSubTypeId) => {
        console.log("getProductSubTypeForMobileDeviceFromId ", productSubTypeId, deviceTypeList);
        if (deviceTypeList && deviceTypeList.length > 0) {
            let productSubTypeIds = deviceTypeList.filter(device => device.id == productSubTypeId);
            if (productSubTypeIds && productSubTypeIds.length > 0) {
                if (productSubTypeIds[0]) {
                    console.log("getProductSubTypeForMobileDeviceFromId1 ", productSubTypeIds[0]);
                    return productSubTypeIds[0].brand + " " + productSubTypeIds[0].manufacturer;
                }
            }
        }
        return productSubTypeId;
    }

    const getUserType = () => {
        let userType = "spr";
        if (global.config.userConfig.parentId) {
            if (global.config.userConfig.tenantId) {
                if (global.config.userConfig.systemTenant) {
                    userType = "stn";
                } else {
                    userType = "tn";
                }
            } else {
                userType = "pr";
            }
        }
        return userType;
    }

    const getPriceTypeUpdatedList = (planType, productCatalogDetail = null) => {
        let prD = productCatalogDetail ? productCatalogDetail : productDetail.productType;
        let prList = [];
        if (planType === "Operator") {
            prList = ["Prepaid"];
        } else if (planType === "Partner") {
            prList = ["Prepaid", "Postpaid"];
        } else if (prD === "MobileDevice") {
            prList = ["Prepaid", "Emi", "Rental"];
        }
        else {
            prList = priceTypeList;
        }
        setPriceTypeUpList(prList);
        return prList;
    }

    const getFrequencyUpdatedList = (type) => {
        let fqList = [];
        if (type === "Prepaid") {
            fqList = ["None"];
        } else if (type === "Postpaid") {
            fqList = ["Monthly", "Quarterly", "Yearly"];
        } else {
            fqList = ["Monthly", "Quarterly", "Yearly"];
        }
        setFrquencyUpList(fqList);
        return fqList;
    }

    const productTypesChangeHandler = (e) => {
        let productCatalogDetail = { ...productDetail };
        productCatalogDetail = { ...productCatalogDetail, productType: e.target.value };
        setvlProductType(e.target.value);
        let subTypes = productTypeData.filter(product => product.type === e.target.value);
        if (subTypes) {
            let subProductTypes = subTypes.map(subtype => subtype.subType);
            setSubProductTypes(subProductTypes);
            if (subProductTypes && subProductTypes.length > 0) {
                setvlProductSubType(subProductTypes[0]);
                productCatalogDetail = { ...productCatalogDetail, productSubType: subProductTypes[0] };
                updatePriceFrequency(productCatalogDetail);
            }
        }
    }

    const subProductTypesChangeHandler = (e) => {
        let productCatalogDetail = { ...productDetail };
        productCatalogDetail = { ...productCatalogDetail, productSubType: e.target.value };
        updatePriceFrequency(productCatalogDetail);
        setvlProductSubType(e.target.value);
    }

    const productImgChangeHandler = (e) => {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    const sellableEnableChangeHandler = (e) => {
        setProductDetail({
            ...productDetail,
            sellable: JSON.parse(e.target.checked),
        });
    }

    const enableEnableChangeHandler = (e) => {
        setProductDetail({
            ...productDetail,
            enabled: JSON.parse(e.target.checked),
        });
    }

    const priceUnitChangeHandler = (e) => {
        setProductDetail({
            ...productDetail,
            priceUnit: e.target.value,
        });
    }

    const shortDescriptionChangeHandler = (e) => {
        setProductDetail({
            ...productDetail,
            shortDescription: e.target.value,
        });
    }

    const productSkuChangeHandler = (e) => {
        setProductDetail({
            ...productDetail,
            productSku: e.target.value,
        });
    }

    const longDescriptionhangeHandler = (e) => {
        setProductDetail({
            ...productDetail,
            longDescription: e.target.value,
        });
    }

    const productBrandChangeHandler = (e) => {
        setProductDetail({
            ...productDetail,
            productBrand: e.target.value,
        });
    }

    const productManufacturerChangeHandler = (e) => {
        setProductDetail({
            ...productDetail,
            productManufacturer: e.target.value,
        });
    }

    const frequencyChangeHandler = (e) => {
        if (e.target.value !== "None") {
            let priceUnitVal = "subscriber" + "/" + e.target.value;
            if (productDetail.productType === "MobileDevice") {
                priceUnitVal = "device" + "/" + e.target.value;
            }
            setProductDetail({
                ...productDetail,
                frequency: e.target.value,
                priceUnit: priceUnitVal
            });
        } else {
            setProductDetail({
                ...productDetail,
                frequency: e.target.value,
            });
        }
    }

    const numInstallmentsChangeHandler = (e) => {
        setProductDetail({
            ...productDetail,
            numInstallments: e.target.value,
        });
    }

    const priceTypeChangeHandler = (e) => {
        let productCatalogDetail = { ...productDetail };
        productCatalogDetail = { ...productCatalogDetail, priceType: e.target.value };
        let frList = getFrequencyUpdatedList(e.target.value);
        let priceUnitVal = "day";
        if (frList && frList.length > 0) {
            if (frList[0] === "None" && productCatalogDetail.productSubType === "Operator") {
                productCatalogDetail = { ...productCatalogDetail, frequency: frList[0], priceUnit: priceUnitVal };
            } else {
                if (e.target.value === "Prepaid") {
                    if (productCatalogDetail.productSubType === "Partner") {
                        priceUnitVal = "subscriber" + "/day";
                        productCatalogDetail = { ...productCatalogDetail, frequency: frList[0], priceUnit: priceUnitVal };
                    } else if (productCatalogDetail.productType === "MobileDevice") {
                        priceUnitVal = "device";
                        productCatalogDetail = { ...productCatalogDetail, frequency: frList[0], priceUnit: priceUnitVal };
                    } else {
                        priceUnitVal = "day";
                        productCatalogDetail = { ...productCatalogDetail, frequency: frList[0], priceUnit: priceUnitVal };
                    }
                } else {
                    if (productCatalogDetail.productType === "MobileDevice") {
                        priceUnitVal = "device" + "/" + frList[0];
                        productCatalogDetail = { ...productCatalogDetail, frequency: frList[0], priceUnit: priceUnitVal };
                    } else {
                        priceUnitVal = "subscriber" + "/" + frList[0];
                        productCatalogDetail = { ...productCatalogDetail, frequency: frList[0], priceUnit: priceUnitVal };
                    }
                }
            }
        }
        setProductDetail(productCatalogDetail);
    }

    const priceChangeHandler = (e) => {
        setProductDetail({
            ...productDetail,
            price: e.target.value,
        });
    }

    const productJsonHandler = (productjson) => {
        console.log("productJsonHandler Operator", productjson);
        let productJs = { ...productDetail.productJson, fromDays: productjson.fromDays, toDays: productjson.toDays, validDays: productjson.validDays }
        setProductDetail({
            ...productDetail,
            productJson: productJs,
        });
    }

    const productPartnerJsonHandler = (productjson) => {
        console.log("productJsonHandler Partner", productjson);
        let productJs = {
            ...productDetail.productJson, numDays: productjson.numDays, minNumSubscribers: productjson.minNumSubscribers,
            maxNumSubscribers: productjson.maxNumSubscribers, maxConnectionsPerSubscriber: productjson.maxConnectionsPerSubscriber
        }
        setProductDetail({
            ...productDetail,
            productJson: productJs,
        });
    }

    const productDevicesJsonHandler = (productjson) => {
        console.log("productJsonHandler Devices", productjson);
        let productJs = { ...productDetail.productJson, minNumUnits: productjson.minNumUnits, maxNumUnits: productjson.maxNumUnits }
        setProductDetail({
            ...productDetail,
            productJson: productJs,
        });
    }

    const productJsonWithUeTypesAndServicesHandler = (productjson) => {
        console.log("productJsonHandler UETypes", productjson);
        let productJs = { ...productDetail.productJson, ueTypes: productjson.ueTypes, services: productjson.services }
        setProductDetail({
            ...productDetail,
            productJson: productJs,
        });
    }

    const updatePriceFrequency = (productCatalogDetail) => {
        let pricetypeListt = getPriceTypeUpdatedList(productCatalogDetail.productSubType, productCatalogDetail);
        if (pricetypeListt && pricetypeListt.length > 0) {
            productCatalogDetail = { ...productCatalogDetail, priceType: pricetypeListt[0] };
            let frList = getFrequencyUpdatedList(pricetypeListt[0]);
            let priceUnitVal = "day";
            if (frList && frList.length > 0) {
                if (frList[0] === "None" && productCatalogDetail.productSubType === "Operator") {
                    productCatalogDetail = { ...productCatalogDetail, frequency: frList[0], priceUnit: priceUnitVal };
                } else {
                    if (pricetypeListt[0] === "Prepaid") {
                        if (productCatalogDetail.productSubType === "Partner") {
                            priceUnitVal = "subscriber" + "/day";
                            productCatalogDetail = { ...productCatalogDetail, frequency: frList[0], priceUnit: priceUnitVal };
                        } else if (productCatalogDetail.productType === "MobileDevice") {
                            priceUnitVal = "device";
                            productCatalogDetail = { ...productCatalogDetail, frequency: frList[0], priceUnit: priceUnitVal };
                        } else {
                            priceUnitVal = "day";
                            productCatalogDetail = { ...productCatalogDetail, frequency: frList[0], priceUnit: priceUnitVal };
                        }
                    } else {
                        if (productCatalogDetail.productType === "MobileDevice") {
                            priceUnitVal = "device" + "/" + frList[0];
                            productCatalogDetail = { ...productCatalogDetail, frequency: frList[0], priceUnit: priceUnitVal };
                        } else {
                            priceUnitVal = "subscriber" + "/" + frList[0];
                            productCatalogDetail = { ...productCatalogDetail, frequency: frList[0], priceUnit: priceUnitVal };
                        }
                    }
                }
            }
        }
        setProductDetail(productCatalogDetail);
    }

    const submitBtnClick = () => {
        let pId = global.config.userConfig.partnerId ? global.config.userConfig.partnerId : global.config.userConfig.tenantId;
        if (pId) {
            let errorCheck = validateProduct();
            if (errorCheck.validated === false) {
                console.log("errorCheck ", errorCheck.errorMsg);
                setErrorMessage(errorCheck.errorMsg);
                return;
            }
            setErrorMessage("");
            setDisableSubmitBtn(true);
            let productSubTypeId = getProductSubTypeForMobileDevice(productDetail.productSubType);
            const validTillDate = new Date('12-30-2028').toISOString();
            let productDetailFinal = { ...productDetail, validUntil: validTillDate, currency: "INR", creatorPartnerId: pId, productSubType: productSubTypeId };
            console.log("finalProductToUpload ", productDetailFinal);
            if (props.productCatalog && props.productCatalog.openMode === "edit") {
                updateProductCatalog(productDetailFinal);
            } else if (props.productCatalog && props.productCatalog.openMode === "buy") {
                if (productDetailFinal && productDetailFinal.id) {
                    let buyProduct = { ...productDetailFinal };
                    delete buyProduct.id;
                    console.log("finalProductToUpload to buy", buyProduct);
                    addProductCatalog(buyProduct);
                }
            } else {
                addProductCatalog(productDetailFinal);
            }
            if (props.closeAfterCallApi) {
                try {
                    props.closeAfterCallApi();
                } catch (err) {
                    console.log("submit btn click ", err);
                }
            }
        }
    }

    const validateProduct = () => {
        let errorDetail = {
            "errorMsg": "ProductDetail is empty",
            "validated": false
        }
        if (productDetail) {
            console.log("productDetail going to check ", productDetail);
            if (productDetail.productSku.length === 0) {
                errorDetail = { ...errorDetail, errorMsg: "ProductSku is empty", validated: false };
                return errorDetail;
            }
            if (productDetail.shortDescription.length === 0) {
                errorDetail = { ...errorDetail, errorMsg: "ShortDescription is empty", validated: false };
                return errorDetail;
            }

            if (productDetail.longDescription.length === 0) {
                errorDetail = { ...errorDetail, errorMsg: "LongDescription is empty", validated: false };
                return errorDetail;
            }

            if (productDetail.price.length === 0) {
                errorDetail = { ...errorDetail, errorMsg: "Price is empty", validated: false };
                return errorDetail;
            }

            if (productDetail.priceUnit.length === 0) {
                errorDetail = { ...errorDetail, errorMsg: "PriceUnit is empty", validated: false };
                return errorDetail;
            }

            if (validateProductJson() === false) {
                errorDetail = { ...errorDetail, errorMsg: "ProductJson is empty", validated: false };
                return errorDetail;
            }

            if (!isObjectEmpty(productDetail.productJson)) {
                if (productDetail.productType === "MobileDevice") {
                    errorDetail = { ...errorDetail, errorMsg: "", validated: true };
                    return errorDetail;
                }
                if (productDetail.productJson.ueTypes) {
                    if (productDetail.productJson.ueTypes.length === 0) {
                        errorDetail = { ...errorDetail, errorMsg: "UE Types is empty", validated: false };
                        return errorDetail;
                    }
                } else {
                    errorDetail = { ...errorDetail, errorMsg: "UE Types is empty", validated: false };
                    return errorDetail;
                }

                if (productDetail.productJson.services) {
                    if (productDetail.productJson.services.length === 0) {
                        errorDetail = { ...errorDetail, errorMsg: "Services is empty", validated: false };
                        return errorDetail;
                    }
                } else {
                    errorDetail = { ...errorDetail, errorMsg: "Services is empty", validated: false };
                    return errorDetail;
                }
            }
            errorDetail = { ...errorDetail, errorMsg: "", validated: true };
            return errorDetail;
        } else {
            return errorDetail;
        }
    }

    const validateProductJson = () => {
        if (productDetail && productDetail.productJson) {
            if (isObjectEmpty(productDetail.productJson)) {
                return false;
            }
            if (productDetail.productSubType === "Partner") {
                if (productDetail.productJson.numDays && Number(productDetail.productJson.numDays) === 0) {
                    return false;
                }
                if (productDetail.productJson.maxNumSubscribers && Number(productDetail.productJson.maxNumSubscribers) === 0) {
                    return false;
                }
            }
            if (productDetail.productSubType === "Operator") {
                if (productDetail.productJson.toDays && Number(productDetail.productJson.toDays) === 0) {
                    return false;
                }
                if (productDetail.productJson.validDays && Number(productDetail.productJson.validDays) === 0) {
                    return false;
                }
            }
            if (productDetail.productType === "MobileDevice") {
                if (productDetail.productJson.maxNumUnits && Number(productDetail.productJson.maxNumUnits) === 0) {
                    return false;
                }
            }
        }
        return true;
    }

    const isObjectEmpty = (objectName) => {
        return Object.keys(objectName).length === 0
    }

    const getInputClassName = () => {
        if (props.productCatalog && props.productCatalog.openMode === "view") {
            return "input-control-flat-read";
        }
        return "input-control-flat";
    }

    const isViewMode = () => {
        if (props.productCatalog && props.productCatalog.openMode === "view") {
            return true;
        }
        return false;
    }

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container width="100%" spacing={2}>
                <Grid item xs={12}>
                    <Box display={"flex"} marginTop={1} marginBottom={1}>
                        <label className={classes.lblHeader}>Product Info</label>
                    </Box>
                    <div className={classes.productTypeClass}>
                        <Box display={"flex"} width={"98%"} marginLeft={5} marginRight={5}>
                            <div className={classes.imgTypeClass}>
                                <Box display={"flex"} mr="2.0em" flexDirection={"column"} marginTop={0.5}>
                                    <Box flex={1} mr="0.5em" marginTop={1} marginLeft={0.5}>
                                        <input
                                            type="file"
                                            className="input-control-file"
                                            id="productImg"
                                            value={productDetail && productDetail.productImage}
                                            onChange={productImgChangeHandler}
                                        />
                                    </Box>
                                    <Box flex={1} mr="0.5em" width={"90%"} margin={1}>
                                        {file ?
                                            <img src={file} style={{ objectFit: "contain", width: "100%", height: 200 }} />
                                            : null
                                        }
                                    </Box>
                                </Box>
                            </div>
                            <Box display={"flex"} mr="5.0em" width={"100%"} marginTop={1} marginRight={5} flexDirection={"column"}>
                                <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={2} marginTop={2}>
                                    <Box flex={1} mr="1.0em">
                                        <FormControl variant="filled" className={classes.formControlFA}>
                                            <InputLabel
                                                id="demo-simple-select-filled-label"
                                                className={classes.listItemFA}
                                                focused={false}
                                            >
                                                Product Type
                                            </InputLabel>
                                            <Select
                                                className={classes.listItemFA}
                                                labelId="demo-simple-select-filled-label"
                                                id="demo-simple-select-filled"
                                                value={vlProductType}
                                                onChange={productTypesChangeHandler}
                                                readOnly={isViewMode()}
                                                disableUnderline={true}
                                            >
                                                {productType &&
                                                    productType.map((product) => {
                                                        return (
                                                            <MenuItem value={product.val} key={product.val}>
                                                                {product.text}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box flex={1} mr="1.0em">
                                        <FormControl variant="filled" className={classes.formControlFA}>
                                            <InputLabel
                                                id="demo-simple-select-filled-label"
                                                className={classes.listItemFA}
                                                focused={false}
                                            >
                                                ProductSubType
                                            </InputLabel>
                                            <Select
                                                className={classes.listItemFA}
                                                labelId="demo-simple-select-filled-label"
                                                id="demo-simple-select-filled"
                                                value={vlProductSubType}
                                                onChange={subProductTypesChangeHandler}
                                                readOnly={isViewMode()}
                                                disableUnderline={true}
                                            >
                                                {subProductTypes &&
                                                    subProductTypes.map((subproduct) => {
                                                        return (
                                                            <MenuItem value={subproduct} key={subproduct}>
                                                                {subproduct}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>
                                {/* ---------------- Product Type & Product Sub Type --------------------- */}
                                <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={2} marginTop={1}>
                                    <Box display={"flex"} flex={1.0} mr="0.5em" flexDirection={"column"}>
                                        <Box flex={0.15} mr="0.1em" marginTop={2} marginBottom={1}>
                                            <label className="lblRequired">Product SKU</label>
                                        </Box>
                                        <Box flex={1} mr="0.001em">
                                            <input
                                                type="text"
                                                className={getInputClassName()}
                                                placeholder="Product SKU"
                                                id="productSku"
                                                value={productDetail && productDetail.productSku}
                                                onChange={productSkuChangeHandler}
                                                readOnly={isViewMode()}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={"flex"} flex={1.0} mr="0.5em" flexDirection={"column"}>
                                        <Box flex={0.15} mr="0.1em" marginTop={2} marginBottom={1}>
                                            <label className="lblRequired">Short Description</label>
                                        </Box>
                                        <Box flex={1} mr="0.001em">
                                            <input
                                                type="text"
                                                className={getInputClassName()}
                                                placeholder="Short Description"
                                                id="shortDescription"
                                                value={productDetail && productDetail.shortDescription}
                                                onChange={shortDescriptionChangeHandler}
                                                readOnly={isViewMode()}
                                            />
                                        </Box>
                                    </Box>
                                </Box>

                                <Box display={"flex"} width={"100%"} marginLeft={1} marginTop={1}>
                                    {/* ---------------- Product Brand --------------------- */}
                                    <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={1} flexDirection={"column"}>
                                        <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                            <label className={classes.lblStyle}>Product Brand</label>
                                        </Box>
                                        <Box flex={1} mr="0.5em">
                                            <input
                                                type="text"
                                                className={getInputClassName()}
                                                id="productBrand"
                                                placeholder="Product Brand"
                                                value={productDetail && productDetail.productBrand}
                                                onChange={productBrandChangeHandler}
                                                readOnly={isViewMode()}
                                            />
                                        </Box>
                                    </Box>

                                    {/* ---------------- Product Manufacturer --------------------- */}
                                    <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4} flexDirection={"column"}>
                                        <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                            <label className={classes.lblStyle}>Product Manufacturer</label>
                                        </Box>
                                        <Box flex={1} mr="0.5em">
                                            <input
                                                type="text"
                                                className={getInputClassName()}
                                                id="productManufacturer"
                                                placeholder="Product Manufacturer"
                                                value={productDetail && productDetail.productManufacturer}
                                                onChange={productManufacturerChangeHandler}
                                                readOnly={isViewMode()}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4} flexDirection={"column"}>
                            {/* ---------------- Long Description --------------------- */}
                            <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4} flexDirection={"column"}>
                                <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                    <label className={classes.lblStyle}>Long Description</label>
                                </Box>
                                <Box flex={1} mr="0.5em">
                                    <input
                                        type="text"
                                        className={getInputClassName()}
                                        placeholder="Long Description"
                                        id="longDescription"
                                        value={productDetail && productDetail.longDescription}
                                        onChange={longDescriptionhangeHandler}
                                        readOnly={isViewMode()}
                                    />
                                </Box>
                            </Box>

                            {/* ---------------- Sellable & Enable --------------------- */}
                            <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4}>
                                <Box flex={1} mr="1.0em">
                                    <FormControlLabel
                                        className={classes.tetraSwitch}
                                        control={
                                            <Switch
                                                color="primary"
                                                checked={productDetail.sellable}
                                                onChange={sellableEnableChangeHandler}
                                                readOnly={isViewMode()}
                                            />
                                        }
                                        labelPlacement="start"
                                        label="Sellable"
                                    />
                                </Box>
                                <Box flex={1} mr="1.0em">
                                    <FormControlLabel
                                        className={classes.tetraSwitch}
                                        control={
                                            <Switch
                                                color="primary"
                                                checked={productDetail.enabled}
                                                onChange={enableEnableChangeHandler}
                                                readOnly={isViewMode()}
                                            />
                                        }
                                        labelPlacement="start"
                                        label="Enabled"
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </div>

                    {
                        vlProductType === "MobileDevice" ? null
                            :
                            <Box display={"flex"} marginTop={1} marginBottom={1}>
                                <label className={classes.lblHeader}>UE Types and Services</label>
                            </Box>
                    }
                    {
                        vlProductType === "MobileDevice" ? null
                            :
                            <div className={classes.productTypeClass}>
                                <Box display={"flex"} width={"98%"} marginLeft={5} marginRight={5} marginTop={1} flexDirection={"column"}>
                                    <UETypeAndServices
                                        planJsonData={props.productCatalog && props.productCatalog.productJson ? props.productCatalog.productJson : null}
                                        productJsonHandler={productJsonWithUeTypesAndServicesHandler}
                                        openMode={props.productCatalog && props.productCatalog.openMode}
                                    >
                                    </UETypeAndServices>
                                </Box>
                            </div>
                    }

                    <Box display={"flex"} marginTop={1} marginBottom={1}>
                        <label className={classes.lblHeader}>Pricing</label>
                    </Box>

                    <div className={classes.productTypeClass}>
                        <Box display={"flex"} width={"98%"} marginLeft={5} marginRight={5} marginTop={1} flexDirection={"column"}>
                            {/* ---------------- Product --------------------- */}
                            <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={2} >
                                {vlProductSubType === "Operator" ?
                                    <ProductType
                                        planJsonData={props.productCatalog && props.productCatalog.productJson ? props.productCatalog.productJson : null}
                                        productJsonHandler={productJsonHandler}
                                        openMode={props.productCatalog && props.productCatalog.openMode}
                                    ></ProductType>
                                    :
                                    vlProductSubType === "Partner" ?
                                        <PartnerProductType
                                            planJsonData={props.productCatalog && props.productCatalog.productJson ? props.productCatalog.productJson : null}
                                            productJsonHandler={productPartnerJsonHandler}
                                            openMode={props.productCatalog && props.productCatalog.openMode}
                                        ></PartnerProductType>
                                        :
                                        vlProductType === "MobileDevice" ?
                                            <MobileDeviceProductType
                                                planJsonData={props.productCatalog && props.productCatalog.productJson ? props.productCatalog.productJson : null}
                                                productJsonHandler={productDevicesJsonHandler}
                                                openMode={props.productCatalog && props.productCatalog.openMode}
                                            ></MobileDeviceProductType>
                                            : null
                                }
                            </Box>

                            {/* ---------------- Price && Frequency Type --------------------- */}
                            <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4}>
                                <Box flex={1} mr="1.0em">
                                    <FormControl variant="filled" className={classes.formControlFA}>
                                        <InputLabel
                                            id="demo-simple-select-filled-label"
                                            className={classes.listItemFA}
                                            focused={false}
                                        >
                                            Price Type
                                        </InputLabel>
                                        <Select
                                            className={classes.listItemFA}
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            value={productDetail && productDetail.priceType}
                                            onChange={priceTypeChangeHandler}
                                            readOnly={isViewMode()}
                                            disableUnderline={true}
                                        >
                                            {priceTypeUpList &&
                                                priceTypeUpList.map((pType) => {
                                                    return (
                                                        <MenuItem value={pType} key={pType}>
                                                            {pType}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box flex={1} mr="1.0em">
                                    {
                                        productDetail && productDetail.priceType === "Prepaid" ? null :
                                            <div className={classes.divList}>
                                                <FormControl variant="filled" className={classes.formControlFA}>
                                                    <InputLabel
                                                        id="demo-simple-select-filled-label"
                                                        className={classes.listItemFA}
                                                        focused={false}
                                                    >
                                                        Frequency
                                                    </InputLabel>
                                                    <Select
                                                        className={classes.listItemFA}
                                                        labelId="demo-simple-select-filled-label"
                                                        id="demo-simple-select-filled"
                                                        value={productDetail && productDetail.frequency}
                                                        onChange={frequencyChangeHandler}
                                                        readOnly={isViewMode()}
                                                        disableUnderline={true}
                                                    >
                                                        {frquencyUpList &&
                                                            frquencyUpList.map((frq) => {
                                                                return (
                                                                    <MenuItem value={frq} key={frq}>
                                                                        {frq}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                    }
                                </Box>
                            </Box>

                            {/* ---------------- NumInstallments --------------------- */}
                            {
                                productDetail && productDetail.priceType === "Prepaid" ? null :
                                    <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4} flexDirection={"column"}>
                                        <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                            <label className={classes.lblStyle}>NumInstallments</label>
                                        </Box>
                                        <Box flex={1} mr="0.5em">
                                            <input
                                                type="number"
                                                className={getInputClassName()}
                                                id="numInstallments"
                                                placeholder="NumInstallments"
                                                value={productDetail && productDetail.numInstallments}
                                                onChange={numInstallmentsChangeHandler}
                                                readOnly={isViewMode()}
                                            />
                                        </Box>
                                    </Box>

                            }
                            {/* ---------------- Price & PriceUnit --------------------- */}
                            <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4}>
                                <Box flex={1} mr="0.5em">
                                    <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                        <label className="lblRequired">Price</label>
                                    </Box>
                                    <Box flex={1} mr="0.5em">
                                        <input
                                            type="number"
                                            className={getInputClassName()}
                                            id="price"
                                            placeholder="Price"
                                            value={productDetail && productDetail.price}
                                            onChange={priceChangeHandler}
                                            readOnly={isViewMode()}
                                        />
                                    </Box>
                                </Box>
                                <Box flex={1} mr="0.5em">
                                    <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                        <label className="lblRequired">Price Unit</label>
                                    </Box>
                                    <Box flex={1} mr="0.5em">
                                        <input
                                            type="text"
                                            className={getInputClassName()}
                                            placeholder="Price Unit"
                                            id="priceUnit"
                                            value={productDetail && productDetail.priceUnit}
                                            onChange={priceUnitChangeHandler}
                                            readOnly={isViewMode()}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </div>

                    <Box display={"flex"} flexDirection={"row-reverse"} width={"100%"}>
                        <box display={"flex"} flexDirection={"column"}>
                            <box display={"flex"} marginTop={1} marginBottom={1}>
                                {errorMessage.length > 0 ?
                                    <div className={classes.errorLbl}>
                                        {errorMessage}
                                    </div>
                                    : null
                                }
                            </box>
                            <box display={"flex"} marginTop={1} marginBottom={1}>
                                {showSubmitBtn ?
                                    <div className="form-group">
                                        <button
                                            className="update-btn-profile"
                                            type="button"
                                            onClick={submitBtnClick}
                                            disabled={disableSubmitBtn}
                                        >
                                            {props.productCatalog && props.productCatalog.openMode === "edit" ? "UPDATE" : "SUBMIT"}
                                        </button>
                                    </div> : null}
                            </box>
                        </box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = ({ mdm }) => {
    const { deviceTypeList } = mdm;
    return {
        deviceTypeList,
    };
};

export default connect(mapStateToProps, {
    addProductCatalog,
    updateProductCatalog
})(AddProduct);
