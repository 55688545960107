import React, { useState } from "react";
import { Modal } from 'react-bootstrap'
import AddConfig from "./Modal/AddConfig"
import { connect } from "react-redux";
import { deleteConfig } from "../../modules/actions/mdmAction";
import AlertDialog from "../../Admin/components/DeleteUserDialog";


const ConfigListRow = (props) => {
  const { data, tenantId, deleteConfig,deviceTypeList } = props
  const [isModalOpen, setModalOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const editButtonClick = () => {
    setModalOpen(true)
  }

  const userhandleClose = () => {
    setModalOpen(false)
  }
  const pushNotification = (pushoptions) => {
    if (pushoptions === "mqttWorker")
      return "MQTT (battery saver)"
    if (pushoptions === "polling")
      return "HTTP Polling"
    if (pushoptions === "mqttAlarm")
      return "MQTT (instant delivery)"
    return ""
  }
  const kioskmode = (kiosk) => {
    return kiosk ? "Enabled" : "Disabled"
  }
  const deleteButtonClick = () => {
    setDialogOpen(true)
  }
  const deleteDailogHandler = (val) => {
    setDialogOpen(false)
    if (val) {
      deleteConfig({ tenantId, configId: data.id })
    }

  }
  return (
    /* This div returns the jsx to create single user list row elements*/
    <div style={{ width: "100%", margin: "5px" }}>
      <div class="config-row-grid">
        <div class="tr-tb-icon">
          <i className={"feather icon-settings f-32"} style={{ lineHeight: "50%" }} />
        </div>
        <div class="tr-tb-name">
          <span>{props.data.name} </span>
        </div>
        <div class="tr-tb-desc">
          <span>{props.data.description}</span>
        </div>
        <div class="tr-tb-kiosk">
          <span>{kioskmode(props.data.kioskmode)}</span>
        </div>
        <div className="tr-tb-pushnot">
          <span>{pushNotification(props.data.pushoptions)}</span>
        </div>
        <div className="tr-tb-deviceType">
          <span>{props.data.manufacturer} {props.data.brand}</span>
        </div>
        <div class="tr-tb-edit">
          <button
            class="editBtn"
            disabled={props.data.defaultConfig?true:false}
            onClick={editButtonClick}
            type="button"
            name=""
          >
            <img
              src="/assets/images/editimg.svg"
              class="edit-user-img"
              alt=""
            />
          </button>
        </div>
        <div class="tr-tb-delete">
          <button
            class="editBtn"
            disabled={props.data.defaultConfig?true:false}
            onClick={deleteButtonClick}
            type="button"
            name=""
          >
            <img
              src="/assets/images/deleteimg.svg"
              class="delete-user-img"
              alt=""
            />
          </button>
        </div>
      </div>
      <Modal
        show={isModalOpen}
        onHide={userhandleClose}
        size={"lg"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ backgroundColor: ' rgba(0,0,0,0.5)' }}
      >
        <Modal.Header closeButton style={{ border: '0px', backgroundColor: '#282828' }}>
          <Modal.Title>UPDATE CONFIG</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '0.2px' }}>
          {
            isModalOpen ?
              <AddConfig purpose="edit" data={data} setModalOpen={setModalOpen}></AddConfig>
              : null
          }
        </Modal.Body>
      </Modal>
      <AlertDialog open={dialogOpen} deleteDailogHandler={deleteDailogHandler} userName={props.data.name} />
    </div>
  );
}
const mapStateToProps = ({ mdm, auth }) => {
  return {
    user: auth.user,
    tenantId: mdm.tenantId,
    deviceTypeList:mdm.deviceTypeList
  }
}
export default connect(mapStateToProps, { deleteConfig })(ConfigListRow);
