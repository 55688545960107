(function (exports) {
  const mcpttExtension = "mcptt";
  class UserProfile {
    constructor(data) {
      this.userProfile = {
        "@": {
          "user-profile-index": data.profileNumber,
          "XUI-URI": "",
          "xsi:schemaLocation":
            "urn:3gpp:mcptt:user-profile:1.0 mcptt-user-profile.xsd",
          "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instanc",
          xmlns: "urn:3gpp:mcptt:userprofile:1.0",
          "xmlns:cp": "urn:ietf:params:xml:ns:common-policy",
          "xmlns:type": "urn:consort:userprofile:1.0",
        },
        source: "MCX",
        Name: data.profileName,
        Status: true,
        orgId: data.orgId,
        Common: {
          "@": {
            index: 0,
          },
          MCPTTUserID: {
            "@": {
              index: 0,
            },
            "uri-entry": data.mcpttId.includes("@")
              ? data.mcpttId
              : `${data.mcpttId}@${data.domain}`,
            "subscriber-type": `${data.subscriberType}`,
            "display-name": {
              "@": {
                "xml.lang": "en-US",
              },
              "#": data.CallerDescr,
            },
          },
          PrivateCall: {
            //This interior node is a placeholder for the MCPTT private call configuration.
            PrivateCallList: {
              //
              "@": {
                index: 0,
              },
              PrivateCallURI: getPrivateCallList(data),
            },
            ManualCommence: true, //When set to "true" the MCPTT user is authorised to make a MCPTT private call in manual commencement mode.When set to "false" the MCPTT user is not authorised to make a MCPTT private call in manual commencement mode.
            AutoCommence: true, //When set to "true" the MCPTT user is authorised to make a MCPTT private call in automatic commencement mode. When set to "false" the MCPTT user is not authorised to make a MCPTT private call in automatic commencement
          },
        },
        OnNetwork: {
          "@": {
            index: 0,
          },
          MCPTTGroupList: {
            entry: getGroupCallList(data.groupList),
          },
          ImplicitAffiliations: {
            entry: getGroupCallList(data.implicitGroupList),
          },
          anyExt: {
            functionalAliasList: {
              entry: getFaList(data),
            },
            tetra: data.tetra,
            'call-forwarding-on': data.callForwardingData.callForwardingOn ? data.callForwardingData.callForwardingOn : false,
            'call-forwarding-target': data.callForwardingData.callForwardingTarget ? data.callForwardingData.callForwardingTarget : '',
            'call-forwarding-condition': data.callForwardingData.callForwardingCondition ? data.callForwardingData.callForwardingCondition : '',
            'call-forwarding-no-answer-timeout': data.callForwardingData.callForwardingNoAnswerTimeout ? data.callForwardingData.callForwardingNoAnswerTimeout : '0',
            'allow-call-forward-manual-input-target': data.callForwardingData.allowCallForwardManualInputTarget ? data.callForwardingData.allowCallForwardManualInputTarget : '',
            'incoming-authorization-required': data.cadCallData.incomingAuthorizationRequired ? data.cadCallData.incomingAuthorizationRequired : false,
            'outgoing-authorization-required': data.cadCallData.outgoingAuthorizationRequired ? data.cadCallData.outgoingAuthorizationRequired : false,
            'default-authorizer': data.cadCallData.defaultAuthorizer ? data.cadCallData.defaultAuthorizer : "",
            VideoEnable: data.VideoEnable,
            mcDataId: data.mcDataId ? data.mcDataId : data.mcxDataIdText ? data.mcxDataIdText : '',
            mcVideoId: data.mcVideoId ? data.mcVideoId : data.mcxVideoIdText ? data.mcxVideoIdText : '',
            activateAllFAsOnLogin: data.activateAllFAsOnLogin ? data.activateAllFAsOnLogin : false,
            ueInfo: {
              entry: getUeList(data),
            },
            userType: data.userType ? data.userType : '',
            location: {
              locationEnable: data.location ? data.location : data.locationEnable ? data.locationEnable : false,
              locationHour: data.hour ? data.hour : data.locationHour ? data.locationHour : '0',
              locationMin: data.min ? data.min : data.locationMin ? data.locationMin : '0',
              locationSec: data.sec ? data.sec : data.locationSec ? data.locationSec : '0',
            },
            permissions: data.permissions ? data.permissions : {},
            userServiceTree: data.selectedServices ? data.selectedServices : []
          },
        },
        "default-group": data.defaultGroup ? data.defaultGroup : data.defaultgroup ? data.defaultgroup : '',
        "ruleset": {
          "@": {
            "xmlns": "urn:ietf:params:xml:ns:common-policy",
          },
          "rule": {
            "actions": {
              "anyExt": {
                "allow-call-forwarding": data.callForwardingData.allowCallForwarding ? data.callForwardingData.allowCallForwarding : false,
                "allow-call-forward-manual-input": data.callForwardingData.allowCallForwardManualInput ? data.callForwardingData.allowCallForwardManualInput : false,
                "allow-private-call-participation": data.cadCallData.hasOwnProperty("allowPrivateCallParticipation") ? data.cadCallData.allowPrivateCallParticipation : true,
                "allow-private-call-to-any-user": data.cadCallData.allowPrivateCallToAnyUser ? data.cadCallData.allowPrivateCallToAnyUser : false
              }
            }
          }
        },
      };
    }
  }
  function getFaList(profile) {
    var FAs = profile.faList;
    var FaList = [];
    /*
            fas = [
                {name:"cheifController", desc:"Cheif Controller",...}
            ]
        */
    if (FAs && FAs.length) {
      FAs.forEach((element, i) => {
        FaList[i] = {
          "@": {
            index: i,
          },
          "uri-entry": `${element.name}`,
          "anyExt": {
            "ue-type": `${element.ueType}`,
            "auto-activate": `${element.default}`
          },
          "display-name": {
            "@": {
              "xml.lang": "en-US",
            },
            "#": element.CallerDescr,
          },
        };
      });
    }

    return FaList;
  }

  function getGroupCallList(groupList) {
    var groupCallList = [];
    if (groupList && groupList.length) {
      groupList.forEach((element, i) => {
        let id = element.name;
        let name = element.CallerDescr;
        groupCallList[i] = {
          "@": {
            index: i,
          },
          "uri-entry": id,
          "display-name": {
            "@": {
              "xml.lang": "en-US",
            },
            "#": name,
          },
        };
      });
    }
    return groupCallList;
  }

  function getPrivateCallList(profile) {
    var userList = profile.privateUsers;
    var privateCallList = [];
    if (userList && userList.length) {
      userList.forEach((element, i) => {
        //Add the subscriber to scbscriber List for SIP register
        //addToSubscriber(element);
        //Make an array for profile
        privateCallList[i] = {
          "@": {
            index: i,
          },
          "uri-entry": `${element.tetraID}-${mcpttExtension}@${profile.domain}`,
          "display-name": {
            "@": {
              "xml.lang": "en-US",
            },
            "#": element.description,
          },
          Tetra_ID: element.tetraID,
          Subscriber_type: element.subscriberType,
          Reg_status: element.regStatus,
          Reg_date_time: element.regDateTime,
          RegNode: element.regNodeNo,
          Organization: profile.homeOrgNo,
          Domain: "Tetra",
          Groups: getGroupList(element.Group),
          SubscriberID: element.subscriberId,
          ProfileName: element.profileName,
          OrgName: "",
        };
      });
    }
    return privateCallList;
  }

  function getGroupList(list) {
    var groupList = [];
    //logger.info.log("info","Group List",list);
    if (list) {
      list.forEach((group, i) => {
        groupList[i] = {
          "@": {
            index: i,
          },
          Always_scanned: group["Always scanned"],
          Attached: group["Attached"],
          GroupSSI: group["GroupSSI"],
        };
      });
    } else {
      groupList = null;
    }
    return groupList;
  }

  function getUeList(profile) {
    var UEs = profile.UEInfo;
    var UeInfoList = [];
    if (UEs && UEs.length) {
      UEs.forEach((element, i) => {
        UeInfoList[i] = {
          "@": {
            index: i,
          },
          "ue-type": `${element.value}`,
          "number-of-reg": `${element.noOfReg}`,
          "primary": `${element.primary}`
        };
      });
    }
    return UeInfoList;
  }

  exports.UserProfile = UserProfile;
})(typeof exports === "undefined" ? (this["UserProfile"] = {}) : exports);
