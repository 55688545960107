import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Header from "../components/Navigation/Header";
import MTMList from "./components/mtmList";

const Mtm = (props) => {
    const { mtmListRefresh } = props;

    return (
        <div>
            <Header page={'mtm'} />
            <div class="main-nav-body">
                <div class="admin-grid" style={{ display: 'block' }}>
                    <div class="wrap-1 l1">
                        <MTMList tetraUser={true} mtmListRefresh={mtmListRefresh} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ logs }) => {
    const { mtmListRefresh } = logs;
    return {
        mtmListRefresh
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    navigateToLogin: () => push('/'),
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Mtm);