/**
 *  Model: 'Location'
 */

export default class FenceModel { 
  constructor() {
    this.name = ""    
    this.geoFenceCoordinates = []
    this.encircledSubscribers = []
  }
}