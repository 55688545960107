import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import TenantDetails from "./tenantDetails";
import ConfigDetails from "./configDetails";
const AddTenant = (props) => {

    const [tenantDetailsData, setTenantDetailsData] = useState()
    const [value, setValue] = React.useState(0);

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            "aria-controls": `full-width-tabpanel-${index}`,
        };
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            // backgroundColor: theme.palette.background.paper,
            width: "100%",
        },
    }));

    const classes = useStyles();
    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(tabprops) {
        const { children, value, index, ...other } = tabprops;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    const tenantDetails = (data) => {
        props.purpose === "edit" && (data[0].data = { tenantId: props.tenantData.tenantId, ...data[0].data })
        setTenantDetailsData(data[0])
        setValue(1)
    }

    const configDetails = (data) => {
        props.purpose === "edit" ? props.updatedTenantData(data) : props.createTenantData(data)
        props.hideModal()
    }

    return (
        <div class={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label="Details" {...a11yProps(0)} />
                    <Tab label="Configs" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <div>
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <TenantDetails tenantData={props.tenantData} createTenantData={tenantDetails} purpose={props.purpose} partnerData={props.partnerData}></TenantDetails>
                </TabPanel>

                <TabPanel value={value} index={1} dir={theme.direction}>
                    <ConfigDetails tenantData={props.tenantData} tenantDetails={tenantDetailsData} createTenantData={configDetails} purpose={props.purpose}></ConfigDetails>
                </TabPanel>
            </div>
        </div>
    )

}

export default connect(null, {
})(AddTenant);