(function (exports) {
  const EndPoints = require('../endpoints').EndPoints;
  const axios = require("axios").default;
  const headers = {
    "Content-Type": 'application/vnd.oma.poc.groups+xml; charset="utf-8"',
  };

  class HTTPHandler {
    constructor() {
      this.defaultPath = `https://${EndPoints.getConfig().host}:${EndPoints.getConfig().gmcPort}/gms/consort/groups/`;
    }
    postDocument(path, document) {
      const URL = path ? path : this.defaultPath;
      try {
        return put(URL, document);
      } catch (e) {
        //todo catch error and throw exception
      }
    }

    getDocument(path) {
      try {
        return get(path, document);
      } catch (e) {
        //todo catch error and throw exception
      }
    }

    deleteDocument(path) {
      try {
        return httpDelete(path);
      } catch (e) {
        //todo catch error and throw exception
      }
    }

    updateDocument(path, document) {
      try {
        put(path, document);
      } catch (e) {
        //todo catch error and throw exception
      }
    }

    getGroupMembersofDgna(path) {
      try {
        return get(path);
      } catch (e) {
        //todo catch error and throw exception
      }
    }
  }

  function put(path, data) {
    //console.log("PUT GRoup Api", path, JSON.stringify(data));
    return new Promise(function (resolve, reject) {
      axios.put(path, data, { headers: headers }).then(function (res) {
        console.log("PUT GRoup Api Res", res);
        if (res.status === 200) resolve({ status: res.status, success: true });
        else resolve({ status: res.status, success: false });
      }).catch(function (err) {
        console.log("PUT GRoup Api error", err);
        resolve({ status: 400, success: false });
      })
    })
  }

  function get(path) {
    return new Promise(function (resolve, reject) {
      axios.get(path, { timeout: 30000 }).then(function (res) {
        if (res.status === 200) resolve(res.data);
        else resolve([]);
      }).catch(function (err) {
        resolve([]);
      })
    })
  }

  function httpDelete(path) {
    return new Promise(function (resolve, reject) {
      axios.delete(path, { timeout: 30000 }).then(function (res) {
        console.log('dgna delete response..', res)
        resolve(res);
      }).catch(function (err) {
        reject(err);
      })
    })
  }

  function post(path, data) {
    console.log("post group api", path, JSON.stringify(data));
    return new Promise(function (resolve, reject) {
      axios.post(path, data, { timeout: 30000 }).then(function (res) {
        console.log("POST  groupAPI RES ", res);
        if (res.status === 200) {
          resolve({ status: res.status, success: true, data: res.data });
        } else {
          resolve({ status: 400, success: false });
        }
      }).catch(function (err) {
        console.log("POST  groupAPI error ", err);
        resolve({ status: 400, success: false });
      })
    })
  }

  exports.HTTPHandler = HTTPHandler
})(typeof exports === 'undefined' ? this['HTTPHandler'] = {} : exports);


