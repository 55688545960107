import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Header from "../../components/Navigation/Header";
import DeviceIndex from '../../components/DeviceManager'
import { getTenantList } from "../../modules/actions/mdmAction";

//Redux actions

const DeviceManager = (props) => {
  const { isAuthenticated, navigateToLogin, getTenantList } = props;
  useEffect(() => {
    if (!isAuthenticated) navigateToLogin()
  }, [isAuthenticated])
  useEffect(() => {
    getTenantList({ partnerId: global.config.userConfig.partnerId })
  }, [])
  return (
    <div>
      <Header page={'deviceManager'} />
      <div className="main-nav-body">
        <DeviceIndex />
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  const { user, isAuthenticated } = auth;
  return {
    user,
    isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      navigateToLogin: () => push("/"),
      getTenantList
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DeviceManager);
