(function (exports) {
    const axios = require("axios").default;

    class GetRegStatus {
        constructor(ua) {
            this.ua = ua;
            this.regPath = "/stats/consort/mcptt/regstatus";
            this.contactList = [];
            this.headers = {
                "Content-Type":
                    'application/org.3gpp.mcptt.user-profile+xml; charset="utf-8" ',
            };
            this.storeRegstatus = null;
            //this.abortController = new AbortController();
        }

        newAbortSignal(timeoutMs) {
            const _this = this;
            setTimeout(() => {
                _this.abortController.abort();
                console.log("regstatus api ia aborted");
            }, timeoutMs || 20000);

            return this.abortController.signal;
            //signal: _this.newAbortSignal(22000)
        }

        getData(linkPath) {
            console.log("regstatus url: ", linkPath);
            const _this = this;
            return new Promise(function (resolve, reject) {
                try {
                    axios.get(linkPath, { headers: { authorization: `Bearer ${_this.ua.getActiveToken()}`, version: _this.ua.getVersion() }, timeout: _this.ua.getHttpClientTimeout() }).then(newRegData => {
                        console.log("got regstatus resp");
                        if (newRegData && newRegData.data) {
                            resolve(newRegData.data);
                        } else {
                            resolve(null);
                        }
                    }).catch(err => {
                        console.log("reg status error: ", err);
                        resolve(null);
                    }).finally()
                } catch (err) {
                    console.log("reg status error trycatch: ", err);
                    resolve(null);
                }
            });
        }

        // logic for check update
        contactListDiff(fetchedData) {
            try {
                let isNew = false;
                for (let i = 0; i < this.contactList.length; i++) {
                    let current_user_uri = this.contactList[i].mcptt_id;
                    let subsriber = null;
                    if (fetchedData.has(current_user_uri.trim())) {
                        subsriber = current_user_uri.trim();
                    }
                    //console.log("regstatus subsriber", subsriber, current_user_uri, setfetchedData);
                    if (subsriber) {
                        if (Number(this.contactList[i]['Reg_status']) === 2) {
                            isNew = true;
                            this.contactList[i]['Reg_status'] = "3";
                        }
                    }
                    else if (Number(this.contactList[i]['Reg_status']) === 3) {
                        isNew = true;
                        this.contactList[i]['Reg_status'] = '2';
                        //this.contactList[i]['RegNode'] = '';
                    }
                }
                return isNew;
            } catch (error) {
                console.log("GetRegStatus:contactListDiff === error", error);
                return false;
            }
        }

        isIterableObj(data) {
            if (data != null && typeof data[Symbol.iterator] === 'function') {
                return true;
            }
            return false;
        }

        regStatusData(linkPath) {
            try {
                const _this = this;
                return new Promise(function (resolve, reject) {
                    _this.getData(linkPath).then(fetchedData => {
                        console.log("got getData resp")
                        if (!fetchedData || !_this.isIterableObj(fetchedData)) {
                            console.log("regstatus fetchedData response is null", fetchedData);
                            resolve(null);
                            return;
                        }
                        // logic for check update
                        let setfetchedData = new Set(fetchedData);
                        if (_this.contactList && _this.contactList.length > 0) {
                            let isUpdate = _this.contactListDiff(setfetchedData);
                            if (isUpdate) {
                                let contactsPlsRegs = {
                                    contacts: _this.contactList,
                                    regStatus: setfetchedData
                                }
                                resolve(contactsPlsRegs);
                            } else {
                                resolve(null);
                            }
                        } else {
                            _this.storeRegstatus = setfetchedData;
                            resolve(null);
                        }
                    });
                })
            } catch (err) {
                console.log("regstatus ------ err response ", err);
                return false;
            }
        }

        mergeRegstatusData() {
            console.log("mergeRegstatusData()");
            if (this.storeRegstatus) {
                if (this.contactList && this.contactList.length > 0) {
                    let isUpdate = this.contactListDiff(this.storeRegstatus);
                    if (isUpdate) {
                        let contactsPlsRegs = {
                            contacts: this.contactList,
                            regStatus: this.storeRegstatus
                        }
                        return contactsPlsRegs;
                    } else {
                        return null;
                    }
                }
            }
            this.storeRegstatus = null;
            return null;
        }

    }
    exports.GetRegStatus = GetRegStatus

})(typeof exports === 'undefined' ? this['GetRegStatus'] = {} : exports);