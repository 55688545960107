import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, ThemeProvider } from "@material-ui/core/styles";
import { Typography, Box, FormControl, InputLabel, Select, MenuItem, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { addDeviceType } from "../../../modules/actions/mdmAction";
import { getMethod } from "../utils"
import { EndPoints } from "../../../MCXclient/endpoints";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const AddDeviceType = (props) => {
    const { configList, setModalOpen, data, tenantId, addDeviceType } = props
    const [deviceData, setDeviceData] = useState({ ...data })
    const [deviceTypeList, setDeviceTypeList] = useState([])

    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: "#252524",
            //backgroundColor: theme.palette.background.default,
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            width: "100%",
            //height: "70%",
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
        },
        formControlFA: {
            width: "95%",
            height: "65px",
            marginTop: "2px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600,
            backgroundColor: "#383736"
        },
        listItemFA: {
            padding: "5px",
            fontFamily: "Muli",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        productTypeClass: {
            width: "90%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            border: "solid",
            borderRadius: 10,
            borderWidth: "2px",
            borderColor: "#4B4A4A",
            marginTop: 5,
            marginBottom: 10
        },
        divList: {
            width: '100%',
            marginBottom: '13px',
        },
        lblStyle: {
            width: '100%',
            color: "#D0D3D4",
            fontSize: 18,
            fontWeight: 600
        },
        tetraSwitch: {
            fontFamily: "Muli",
            marginTop: "2px",
            marginRight: "1px",
            marginLeft: "0px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        datePickerStyle: {
            color: "black",
            padding: "10px",
            fontFamily: "Sans-Serif",
            width: '60%',
            border: "solid",
            borderRadius: 20,
            borderWidth: "2px",
            borderColor: "#D3D3D3",
        }
    }));
    const classes = useStyles();
    const theme = useTheme();
    const onSelectHandler = (e) => {
        let data = { ...deviceData }
        if (e.target.name == "ueType") {
            data[e.target.name] = Object.keys(e.target.value)
            data["runtimeUE"] = Object.values(e.target.value)
        } else {
            data[e.target.name] = e.target.value
        }
        setDeviceData({ ...data })
    }
    const submitButtonHandler = () => {
        addDeviceType(deviceData)
        setModalOpen(false)
    }

    useEffect(() => {
        getUeTypeConfig()
    }, [])

    const getUeTypeConfig = async () => {
        let url = `${EndPoints.getConfig().masterUeTypeConfig}`
        let data = await getMethod(url)
        if (data.isError === false && data.result) {
            if (data.result.serviceUeTypeConfig) {
                const ueTypeList = Object.entries(data.result.serviceUeTypeConfig).map(([key, value]) => ({ [key]: value }));
                setDeviceTypeList(ueTypeList)
            } else {
                setDeviceTypeList([])
            }
        }
    }
    return (
        <div class={classes.root}>
            <Grid container width="100%" spacing={2}>
                <Grid item xs={12}>
                    <Box className="tab1-account" width={'100%'} marginLeft={0}>
                        <Box class="form-group" paddingX={10} paddingY={0}>
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Manufacturer</label>
                            </Box>
                            <input
                                class="input-control-flat"
                                id="manufacturer"
                                name="manufacturer"
                                value={deviceData.manufacturer}
                                onChange={onSelectHandler}
                            />
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0}>
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Model</label>
                            </Box>
                            <input
                                type="text"
                                class="input-control-flat"
                                id="brand"
                                name="brand"
                                value={deviceData.brand}
                                onChange={onSelectHandler}
                            />
                        </Box>
                        <Box className={classes.divList}>
                            <Box flexDirection={'row'} display={'flex'}>
                                {/* <Box flex={.875} mr="0.5em" marginTop={2} marginBottom={1}>
                                    <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Ue Type</label>
                                </Box> */}
                                <FormControl
                                    variant="filled"
                                    className={classes.formControlFA}
                                >
                                    <InputLabel id="config" className={classes.listItemFA} focused={false} >
                                        Ue Type
                                    </InputLabel>
                                    <Select
                                        className={classes.listItemFA}
                                        id="ueType"
                                        name="ueType"
                                        // value={listType}
                                        onChange={onSelectHandler}
                                        disableUnderline={true}
                                    >
                                        {deviceTypeList &&
                                            deviceTypeList.map((devicetype, i) => {
                                                return (
                                                    <MenuItem value={devicetype} key={i}>{Object.keys(devicetype)}</MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box display={"flex"} flexDirection={"row-reverse"} width={"100%"}>
                            {deviceData && deviceData['manufacturer'] && deviceData['manufacturer'].length > 0 && deviceData['manufacturer'].trim() && deviceData['brand'] && deviceData['brand'].trim() && deviceData['brand'].length > 0 &&
                                <div className="form-group">
                                    <button
                                        class="update-btn-profile"
                                        type="button"
                                        onClick={submitButtonHandler}
                                    >
                                        {"Submit"}
                                    </button>
                                </div>
                            }
                        </Box>
                    </Box >
                </Grid >
            </Grid >
        </div >
    );
};
const mapStateToProps = ({ mdm, auth }) => {
    return {
    }
}
export default connect(mapStateToProps, { addDeviceType })(AddDeviceType);

