(function (exports) {
  const axios = require("axios").default;
  const EndPoints = require("../endpoints").EndPoints;
  class Store {
    /* constructor(){
    
        } */

    getCon() {
      return get(EndPoints.getConfig().conn);
    }

    addSDS(data) {
      console.log("Storing SDS", data);
      let path = EndPoints.getConfig().sdsTextMessage;
      return post(path, data);
    }
    updateSDS(data) {
      console.log("Storing update SDS", data);
      let path = EndPoints.getConfig().sdsTextMessage;
      put(path, data);
    }

    getSDS(id) {
      return getbyId(EndPoints.getConfig().sdsTextMessage, id);
    }

    getGroupSDS(id) {
      return getbyId(EndPoints.getConfig().sdsGroupMessage, id);
    }

    deleteSDS(msgId) {
      const data = {
        stateType: "DELETE",
        messageId: msgId,
      };
      var path = EndPoints.getConfig().sdsTextMessage;
      put(path, data);
    }

    addStatus(data) {
      post(EndPoints.getConfig().sdsAlerts, data);
    }

    updateStatus(data) {
      put(EndPoints.getConfig().sdsAlerts, data);
    }

    getStatus(id) {
      return getbyId(EndPoints.getConfig().sdsAlerts, id);
    }

    getGroupStatus(id) {
      return getbyId(EndPoints.getConfig().sdsGroupAlerts, id);
    }

    deleteStatus(data) { }

    addCall(data) {
      console.log("Storing status call", data, EndPoints.getConfig().calls);
      post(EndPoints.getConfig().calls, data);
    }

    updateCall(data) {
      put(EndPoints.getConfig().calls, data);
    }

    getCall(id) {
      return getbyId(EndPoints.getConfig().individualCall, id);
    }

    getGroupCall(id) {
      return getbyId(EndPoints.getConfig().groupCall, id);
    }

    deleteCall(data) { }

    getfavCont(id) {
      return getbyId(EndPoints.getConfig().favContacts, id);
    }

    addFav(data) {
      return post(EndPoints.getConfig().addFav, data);
    }

    remFav(data) {
      return deleteData(EndPoints.getConfig().favContacts, data);
    }

    pinAlert(data) {
      put(EndPoints.getConfig().pinAlert, data);
    }

    ackAlert(data) {
      put(EndPoints.getConfig().sdsAlerts, data);
    }
    //PAGINATION API
    getSDSData(id, type = "inbox", per_page = 20, current_page = 1) {
      const path = `${EndPoints.getConfig().sdsLogs
        }?type=${type}&current_page=${current_page}&id=${id}&per_page=${per_page}&sdsType=${"ALL"}`;
      return get(path);
    }
    getAlertsData(id, type = "inc", per_page = 100, current_page = 1) {
      const path = `${EndPoints.getConfig().alertLogs
        }?type=${type}&current_page=${current_page}&id=${id}&per_page=${per_page}`;
      return get(path);
    }
    getLogs(id, type = "all", per_page = 100, current_page = 1, search = "") {
      const path = `${EndPoints.getConfig().logs
        }?type=${type}&current_page=${current_page}&id=${id}&per_page=${per_page}&search=${search}`;
      console.log("getLogs path....", path);
      return get(path);
    }

    getUserSDSMesage(disp_id, toId, per_page, current_page) {
      const path = `${EndPoints.getConfig().sdsUserMsg
        }?disp_id=${disp_id}&toId=${toId}&per_page=${per_page}&current_page=${current_page}`;
      console.log("msgg sdsPath", path);
      return get(path);
    }

    getUnreadUserCountMsg(disp_id) {
      const path = `${EndPoints.getConfig().unreadUserMsg}${disp_id}`;
      console.log("msgg sdsPath getUnreadUserCountMsg", path);
      return get(path);
    }
    putSDSMsgView(data) {
      const path = `${EndPoints.getConfig().sdsMessageViewed}`;
      console.log("msgg sdsPath getUnreadUserCountMsg", path);
      return put(path, data);
    }

    //USERLISTS
    getUserLists(id) {
      return getbyId(EndPoints.getConfig().userLists, id);
    }
    exportList(data) {
      return post(EndPoints.getConfig().userLists, data);
    }
    updateLists(data) {
      console.log("LIST RECD", data);
      return put(EndPoints.getConfig().userLists, data);
    }
    deleteList(data) {
      return deleteData(EndPoints.getConfig().userLists, data);
    }

    //DGNA
    getDGNA(id) {
      return getbyId(EndPoints.getConfig().dgna, id);
    }
    createDGNA(data) {
      return post(EndPoints.getConfig().dgna, data);
    }
    updateDGNA(data) {
      return put(EndPoints.getConfig().dgna, data);
    }
    deleteDGNA(data) {
      return deleteData(EndPoints.getConfig().dgna, data);
    }

    uploadFile(data) {
      return uploadFileData(EndPoints.getConfig().fileUpload, data);
    }

    downloadFile(fileId) {
      const path = `${EndPoints.getConfig().fileDownload} + ${fileId}`;
      return downloadFileData(path);
    }

    downloadFileURL(fileId) {
      const path = `${EndPoints.getConfig().fileDownloadURL} + ${fileId}`;
      return downloadFileURLData(path);
    }

    getChatList(dispId, ueId = '0000') {
      const path = `${EndPoints.getConfig().getChatUserList}?dispId=${dispId}&UEid=${ueId}`;
      return get(path);
    }
  }

  function put(path, data) {
    return new Promise(function (resolve, reject) {
      //console.log('Storing PUT Api data ', data, JSON.stringify(data))
      let callData = { ...data };
      if (callData.session) {
        callData.session = null;
        callData.track = null;
        callData.localAudio = null;
        callData.actionItem = null;
        callData.stream = null;
      }
      axios
        .put(path, callData, { timeout: 30000 })
        .then(function (res) {
          //console.log('Storing PUT Api RES', res)
          if (res.status === 200)
            resolve({ status: res.status, success: true });
          else resolve({ status: res.status, success: false });
        })
        .catch(function (err) {
          resolve({ status: 400, success: false });
        });
    });
  }

  function get(path) {
    return new Promise(function (resolve, reject) {
      axios
        .get(path, { timeout: 30000 })
        .then(function (res) {
          if (res.status === 200) resolve(res.data);
          else resolve([]);
        })
        .catch(function (err) {
          reject([]);
        });
    });
  }

  function getbyId(path, id) {
    return new Promise(function (resolve, reject) {
      axios
        .get(path + id, { timeout: 30000 })
        .then(function (res) {
          // console.log('API GET',path,res.data);
          if (res.status === 200) resolve(res.data);
          else resolve([]);
        })
        .catch(function (err) {
          resolve([]);
        });
    });
  }

  function deleteData(path, data) {
    return new Promise(function (resolve, reject) {
      axios
        .delete(path, { data: data, timeout: 30000 })
        .then(function (res) {
          resolve(res);
        })
        .catch(function (err) {
          reject(err);
        });
    });
  }

  function post(path, data) {
    return new Promise(function (resolve, reject) {
      //console.log("Storing API data post ", data);
      let callData = { ...data };
      if (callData.session) {
        callData.session = null;
        callData.track = null;
        callData.localAudio = null;
        callData.actionItem = null;
        callData.stream = null;
      }
      axios
        .post(path, callData, { timeout: 30000 })
        .then(function (res) {
          console.log("msgg file API res ");
          if (res.status === 200) {
            console.log("POST API RES ");
            resolve({ status: res.status, success: true, data: res.data });
          } else {
            resolve({ status: 400, success: false });
          }
        })
        .catch(function (err) {
          console.log("msgg file API err 1", err);
          resolve({ status: 400, success: false });
        });
    });
  }

  function uploadFileData(path, formData) {
    return new Promise(function (resolve, reject) {
      axios({
        method: "post",
        url: path,
        data: formData,
        timeout: 60000
      })
        .then(function (res) {
          console.log("msgg file API res ");
          if (res.status === 200) {
            console.log("POST API RES ");
            resolve({ status: res.status, success: true, data: res.data });
          } else {
            resolve({ status: 400, success: false });
          }
        })
        .catch(function (err) {
          console.log("msgg file API err 2", err);
          resolve({ status: 400, success: false });
        });
    });
  }

  function downloadFileData(path) {
    return new Promise(function (resolve, reject) {
      axios({
        method: "get",
        url: path,
        responseType: "blob",
        timeout: 60000
      })
        .then(function (res) {
          console.log("msgg download API res ", res);
          if (res.status === 200) {
            resolve({ status: res.status, success: true, data: res.file });
          } else {
            resolve({ status: 400, success: false });
          }
        })
        .catch(function (err) {
          console.log("msgg file API err 3", err);
          resolve({ status: 400, success: false });
        });
    });
  }

  function downloadFileURLData(path) {
    return new Promise(function (resolve, reject) {
      axios({
        method: "get",
        url: path,
        timeout: 60000
      })
        .then(function (res) {
          console.log("msgg file API res ");
          if (res.status === 200) {
            console.log("POST API RES ");
            resolve({ status: res.status, success: true, data: res.data });
          } else {
            resolve({ status: 400, success: false });
          }
        })
        .catch(function (err) {
          console.log("msgg file API err 4", err);
          resolve({ status: 400, success: false });
        });
    });
  }

  exports.Store = Store;
})(typeof exports === "undefined" ? (this["Store"] = {}) : exports);
