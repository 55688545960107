import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from '@mui/material';

const PostpaidOrder = (props) => {
    const { order, getBillingCycle } = props;
    const [billingCycle, setBillingCycle] = useState(1);

    useEffect(() => {
        let numInstallments = 1;
        if (order && order.numInstallments) {
            numInstallments = order.numInstallments;
        } else if (order && order.products && order.products.numInstallments) {
            numInstallments = order.products.numInstallments;
        }
        setBillingCycle(numInstallments);

    }, [])

    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: "#252524",
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            height: 200,
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
        },
        divList: {
            width: '100%',
            marginBottom: '1px',
        },
        formControlFA: {
            width: "80%",
            height: "50px",
        },
        listItemFA: {
            padding: "2px",
            fontFamily: "Muli",
        },
        lblStyle: {
            width: '100%',
            color: "#D0D3D4",
            fontSize: 18,
            fontWeight: 600,
        },
    }));

    const OnAddBillingCycle = () => {
        if (getBillingCycle && billingCycle > 0) {
            getBillingCycle(billingCycle);
        }
    }

    const billingChangeHandler = (e) => {
        setBillingCycle(e.target.value);
    }



    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container width="100%" spacing={2} style={{ backgroundColor: "#252524" }}>
                <Grid item xs={12}>
                    <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4}>
                        <Box flex={0.45} mr="0.5em" marginTop={2} >
                            <label className={classes.lblStyle}>No. of billing cycle: </label>
                        </Box>
                        <Box flex={0.6} mr="0.5em">
                            <input
                                type="number"
                                className="input-control-flat"
                                id="billing"
                                placeholder="No. of billing cycle"
                                value={billingCycle}
                                onChange={billingChangeHandler}
                            />
                        </Box>
                    </Box>
                </Grid>
                <button
                    className="add-btn-device"
                    type="button"
                    onClick={OnAddBillingCycle}
                >
                    Confirm
                </button>
            </Grid>
        </div>
    )

}

export default PostpaidOrder;