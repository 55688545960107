import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DeviceListRow from "./DeviceListRow";
import { makeStyles } from "@material-ui/core/styles";
import "./style.css"
import QRCode from "react-qr-code";
import { Modal } from 'react-bootstrap'
import { fetchDeviceList } from "../../modules/actions/mdmAction";
import FadeInOut from "../../Admin/components/FadeInOut";
import AlertDialog from "../../Admin/components/DeleteUserDialog";
import { DataGrid } from "@mui/x-data-grid";
import { deleteDevice, pullLogs } from "../../modules/actions/mdmAction";
import DeviceInfo from "./DeviceInfo";
import DeviceLocation from "./DeviceLocation";
import AddDevice from "./Modal/AddDevice";
const DeviceList = (props) => {
    const { fetchDeviceList, tenantId, filteredData, deleteDevice, pullLogs,tenantList} = props;
    const [isModalOpen, setModalOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [userName, setUserName] = useState('')
    const [openUserEditMode, setOpenUserEditMode] = useState(false);
    const [selectedId, setSelectedId] = useState('')
    const [selectedData, setSelectedData] = useState([])
    const [selectedDevicerow,setSelecteddevicerow]=useState({});
    const [deviceinfopage,setDeviceinfo]=useState(false);
    const [locationpage,setLocationPage]=useState(false);
    const [selectedDeviceLocID,setselectedDeviceLocID]=useState({});
    useEffect(() => {
        // console.log("mtmId--",global.config.userConfig.partnerId);
        fetchDeviceList({ tenantId,mtmId:global.config.userConfig.partnerId })
    }, [])
    const handleClose = () => {
        setModalOpen(false)
    }
    const useStyles = makeStyles((theme) => ({
        root: {
            //backgroundColor: theme.palette.background.paper,
            // paddingTop: 10,
            // paddingLeft: 20,
            // paddingRight: 20,
            width: "100%",
            //height: "70%",
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
        },
        deviceManagementClass: {
            width: "70%",
            height: 800,
            // display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            border: "solid",
            borderRadius: 10,
            borderWidth: "1px",
            borderColor: "#4B4A4A",
            marginLeft: 50,
            //marginTop: 5,
            marginBottom: 10,
            marginTop: 20,
            overflowY: "scroll",
        },
        divList: {
            width: '100%',
            marginBottom: '13px',
        },

    }));
    const getTenantname=(tenantId)=>{
        if(tenantId===null)return "";
        var filtered = tenantList.filter(function(item) {
            return (item.value == tenantId)
          });
          if(filtered.length===0)return tenantId;
          return filtered[0].name;
    }

    const classes = useStyles();

    const columns = [
        {
            field: 'userIcon',
            headerName: 'Icon',
            width: 60,
            height: 50,
            sortable: false,
            headerClassName: "list-head-Val",
            align: "center",
            headerAlign: "center",
            renderCell: (cellValue) => {
                return (
                    <div class="tr-tb-icon">
                        <i className={"feather icon-smartphone f-32"} style={{ lineHeight: "50%", color: 'whitesmoke' }} />
                    </div>
                )
            }
        },
        {
            field: 'deviceId', headerName: 'Device Id', width: 281, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
        },
        {
            field: 'userId', headerName: 'User Name', width: 280, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
        },
        {
            field: 'cmsURI', headerName: 'Cms Uri', width: 281, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center",
        },
        {
            field: 'deviceState', headerName: 'Allocation Status', width: 181, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center",
        },
        {
            field: 'manufacturer', headerName: 'Tenant', width: 181, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center",
            valueGetter: (value, row) => `${getTenantname(row.tenantId)}`,
        },
        {
            field: 'name', headerName: 'Configuration', width: 281, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center",
        },
        {
            field: 'deviceType', headerName: 'Device Type', width: 281, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center", valueGetter: (value, row) => `${getDeviceType(row)}`,
        },
        {
            field: 'qrcode', headerName: 'Qr Code', width: 100, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center",
            renderCell: (cellValue) => {
                return (
                    <button
                        class="qrcode"
                        onClick={
                            (event)=>{
                                event.stopPropagation()
                                viewQrCode();
                            }
                           }
                        type="button"
                        name=""
                    >
                        <img
                            src="/assets/images/qr-code.png"
                            class="qrcode-img"
                            alt=""
                        />
                    </button>
                )
            }
        },
        {
            field: 'editBtn', headerName: 'Pull Log', width: 100, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center",
            renderCell: (cellValue) => {
                return (
                    <button
                        class="editBtn"
                        onClick={(event) => { 
                            event.stopPropagation()
                            pullButtonClick(cellValue.row) }}
                        type="button"
                        name=""
                    >
                        <i className={"feather icon-download-cloud f-28 sq-icon-btn in-blc wx32"} style={{ lineHeight: "50%" }} />
                    </button>
                )
            }
        },
        {
            ield: 'location',
            headerName: 'Location',
            width: 101,
            height: 50,
            sortable: false,
            headerClassName: "list-head-Val",
            align: "center",
            headerAlign: "center",
            renderCell: (cellValue) => {
                return (
                    <button
                        class="editBtn"
                        onClick={(event) => { 
                            event.stopPropagation()
                            openLocationPage(cellValue.row) }}
                        type="button"
                        name=""
                    >
                        <img
                            src="/assets/images/location/gray-marker.png"
                            class="edit-user-img"
                            alt=""
                        />
                    </button>
                )
            }
        },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 71,
            height: 50,
            sortable: false,
            headerClassName: "list-head-Val",
            align: "center",
            headerAlign: "center",
            renderCell: (cellValue) => {
                return (
                    <button
                        class="editBtn"
                        onClick={(event) => { 
                            event.stopPropagation()
                            openUserEditPage(cellValue.row) }}
                        type="button"
                        name=""
                    >
                        <img
                            src="/assets/images/editimg.svg"
                            class="edit-user-img"
                            alt=""
                        />
                    </button>
                )
            }
        },
        {
            field: 'delete',
            headerName: 'Delete',
            width: 91,
            height: 50,
            sortable: false,
            headerClassName: "list-head-Val",
            align: "center",
            headerAlign: "center",
            renderCell: (cellValue) => {
                return (
                    <button
                        class="editBtn"
                        onClick={(event) => { 
                            event.stopPropagation()
                            deleteButtonClick(cellValue.row) }}
                        type="button"
                        name=""
                    >
                        <img
                            src="/assets/images/deleteimg.svg"
                            class="delete-user-img"
                            alt=""
                        />
                    </button>
                )
            },
        },
    ];

    const getDeviceType = (device) => {
        let deviceType = "";
        if (device && device.manufacturer && device.brand) {
            deviceType = device.manufacturer + device.brand
        }

        return deviceType
    }

    const viewQrCode = () => {
        setModalOpen(true)
    }

    const pullButtonClick = (val) => {
        pullLogs({ id: val.deviceId })
    }

    const openUserEditPage = (val) => {
        setSelectedData(val)
        setOpenUserEditMode(true);
        setShow(true);
    }
    const openLocationPage=(val)=>{
          setLocationPage(true);
          console.log("samm",val);
          setselectedDeviceLocID(val);
    }

    const deleteButtonClick = (val) => {
        if (val) {
            setDialogOpen(true)
            setUserName(val.deviceId)
            setSelectedId(val.id)
        }
    }

    const deleteDailogHandler = (val) => {
        setDialogOpen(false)
        if (val) {
            deleteDevice({ id: selectedId, tenantId })
        }

    }

    const deviceManagementHandleClose = () => {
        setShow(false)
    }
    const deviceinfoopen=(data)=>{
        setSelecteddevicerow(data.row)
       setDeviceinfo(true);
    }
    const deviceinfoclose=()=>{
        setDeviceinfo(false);
       
    }
    const switchlocationWindow=()=>{
        setLocationPage(false);
    }
    
   

    return (<>
        <div className={classes.root}>
            {show ?
                <div className={classes.mtmTypeClass}>
                    <FadeInOut show={show} duration={500}>
                        {openUserEditMode === false ?
                            <AddDevice setModalOpen={deviceManagementHandleClose} />
                            :
                            <AddDevice purpose={"edit"} data={selectedData} setModalOpen={deviceManagementHandleClose} />
                        }
                    </FadeInOut>
                </div>
                : ((!(filteredData.length===0) && !deviceinfopage && !locationpage ) ?
                    <DataGrid
                    rows={filteredData}
                    columns={columns}
                    rowHeight={68}
                    hideFooter
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    disableColumnMenu
                    localeText={{ noRowsLabel: "" }}
                    onRowClick={deviceinfoopen}
                    getRowSpacing={params => ({
                        top: params.isFirstVisible ? 0 : 0,
                        bottom: params.isLastVisible ? 0 : 0
                    })}
                    sx={{
                        boxShadow: 2,
                        border: "none",
                        borderBottom: "none",
                        '& .MuiDataGrid-cell:hover': {
                            color: '#ffb01f',
                        },
                        '& .MuiDataGrid': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-cell': {
                            borderColor: "#404040",
                        },
                        '& .MuiDataGrid-columnHeader': {
                            borderColor: "gray",
                        },
                        '& .MuiDataGrid-filler': {
                            backgroundColor: "#262626",
                        },
                    }}

                />:(deviceinfopage && !locationpage)?
                <DeviceInfo switchWindow={deviceinfoclose} selectedDevicerow={selectedDevicerow}/>
                :(!deviceinfopage && locationpage)?<DeviceLocation switchlocationWindow={switchlocationWindow} selectedDeviceLocID={selectedDeviceLocID}/>:"")
                
            }
            <AlertDialog open={dialogOpen} deleteDailogHandler={deleteDailogHandler} userName={userName} />
        </div>
        {/* <div className="device-row-grid-head">
            <div className="tr-tb-icon"></div>
            <div className="tr-tb-deviceid">
                <span>Device Id</span>
            </div>
            <div className="tr-tb-username">
                <span>User Name</span>
            </div>
            <div className="tr-tb-status">
                <span>CMS URI</span>
            </div>
            <div className="tr-tb-config">
                <span>Configuration</span>
            </div>
            <div className="tr-tb-qr">
                <span>QR CODE</span>
            </div>
            <div class="tr-tb-logs">
                <span>Pull Log</span>
            </div>
            <div class="tr-tb-deviceType">
                <span>Device Type</span>
            </div>
            <div class="tr-tb-edit">
                <span></span>
            </div>
            <div class="tr-tb-delete">
                <span></span>
            </div>
        </div>


        <div style={{ height: '880px', marginTop: '10px', overflowY: 'scroll' }}>
            {filteredData &&
                filteredData.map((data, i) => {
                    return (
                        <DeviceListRow
                            key={i}
                            data={data}
                            setModalOpen={setModalOpen}
                        // grouplist={grouplist}
                        // userData={data}
                        // userlist={userlist}
                        // orglist={orglist}
                        // tetraUser={props.tetraUser}
                        // SubscribeType={subscribeType}
                        />
                    );
                })}
        </div> */}
        <Modal
            show={isModalOpen}
            onHide={handleClose}
            // scrollable={true}
            // size={"lg"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ backgroundColor: " rgba(0,0,0,0.5)" }}
        >
            <Modal.Header
                closeButton
                style={{ border: "0px", backgroundColor: "#282828" }}
            >
                <Modal.Title>QR Code</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: "0.2px" }}>
                <div style={{ margin: "10px", display: "flex", justifyContent: "center" }}>
                    <QRCode
                        size={256}
                        value={{ test: 24235 }}
                        viewBox={`0 0 256 256`}
                    />
                </div>
            </Modal.Body>
        </Modal>
    </>
    )
}
const mapStateToProps = ({ mdm }) => {
    return {
        tenantId: mdm.tenantId,
        filteredData: mdm.filteredData,
        tenantList:mdm.tenantList
    }
}
export default connect(mapStateToProps, { fetchDeviceList, deleteDevice, pullLogs })(DeviceList)