import React from 'react'
import { ContextMenu } from '../commom';
import { getRandomString } from '../../utils/lib';

function NonFATrainTableRow(props) {
    const { trainData } = props

    return (
        <div class="trainTable-row-grid">
            {/* <div class="trt-tb-cb">
                    <input type="checkbox" />
                </div> */}
            <div class="trt-tb-tno train-cell-center">
                <span>{trainData.rakeId}</span>
            </div>
            <div class="trt-tb-dir train-cell-center">
                <span>{'-'}</span>
            </div>
            <div class="trt-tb-tgrp train-cell-center">
                <span>{'-'}</span>
            </div>
            <div class="trt-tb-gf train-cell-center">
                <span>{trainData.line ? trainData.line : '-'}</span>
            </div>
            <div class="trt-tb-sgrp train-cell-center">
                {
                    trainData.trackCircuit ?
                        (
                            <ContextMenu id={getRandomString(5)} Class="sds-ops" type={'TD'} subr={`SM-${trainData.trackCircuit}`} routeName={'trn'} isFACall={true} clickOpen={true}>
                                <button class="arrow-icon vg_bottom">
                                    <i class="fa fa-caret-right"></i>
                                </button>
                            </ContextMenu>
                        ) :
                        (
                            <span>{'-'}</span>
                        )
                }
            </div>
            <div class="trt-tb-dr train-cell-center">
                <span>{'-'}</span>
            </div>
            <div class="trt-tb-astdr train-cell-center">
                <span>{'-'}</span>
            </div>
            <div class="trt-tb-ftrcp train-cell-center">
                {
                    trainData.RadioID_A && trainData.RadioID_A.length > 0 ?
                        (
                            <ContextMenu id={getRandomString(5)} Class="sds-ops" type={'TD'} subr={`${trainData.RadioID_A}`} routeName={'trn'} isFACall={false} clickOpen={true}>
                                <button class="arrow-icon vg_bottom">
                                    <i class="fa fa-caret-right"></i>
                                </button>
                            </ContextMenu>
                        ) :
                        (
                            <span>{'-'}</span>
                        )

                }
            </div>
            <div class="trt-tb-rtrcp train-cell-center">
                {
                    trainData.RadioID_B && trainData.RadioID_B.length > 0 ?
                        (
                            <ContextMenu id={getRandomString(5)} Class="sds-ops" type={'TD'} subr={`${trainData.RadioID_B}`} routeName={'trn'} isFACall={false} clickOpen={true}>
                                <button class="arrow-icon vg_bottom">
                                    <i class="fa fa-caret-right"></i>
                                </button>
                            </ContextMenu>
                        ) :
                        (
                            <span>{'-'}</span>
                        )
                }
            </div>
            <div class="trt-tb-grd train-cell-center">
                <span>{'-'}</span>
            </div>
        </div>
    )
}

export default NonFATrainTableRow