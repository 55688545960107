/* globals RTCPeerConnection: false, RTCSessionDescription: false */

const EventEmitter = require("events").EventEmitter;
const sdp_transform = require("sdp-transform");
const Logger = require("./Logger");
const JsSIP_C = require("./Constants");
const Exceptions = require("./Exceptions");
const Transactions = require("./Transactions");
const Utils = require("./Utils");
const Timers = require("./Timers");
const SIPMessage = require("./SIPMessage");
const Dialog = require("./Dialog");
const RequestSender = require("./RequestSender");
const RTCSession_DTMF = require("./RTCSession/DTMF");
const RTCSession_Info = require("./RTCSession/Info");
const RTCSession_ReferNotifier = require("./RTCSession/ReferNotifier");
const RTCSession_ReferSubscriber = require("./RTCSession/ReferSubscriber");
const URI = require("./URI");
// const moment = require("moment");
const logger = new Logger("RTCSession");

const C = {
  // RTCSession states.
  STATUS_NULL: 0,
  STATUS_INVITE_SENT: 1,
  STATUS_1XX_RECEIVED: 2,
  STATUS_INVITE_RECEIVED: 3,
  STATUS_WAITING_FOR_ANSWER: 4,
  STATUS_ANSWERED: 5,
  STATUS_WAITING_FOR_ACK: 6,
  STATUS_CANCELED: 7,
  STATUS_TERMINATED: 8,
  STATUS_CONFIRMED: 9,
};

const P = {
  // RTCSession local/remote streams tates.
  STARTED: 0,
  FINISHED: 1,
  TERMINATED: 2,
};

/**
 * Local variables.
 */
const holdMediaTypes = ["audio", "video"];

module.exports = class RTCSession extends EventEmitter {
  /**
   * Expose C object.
   */
  static get C() {
    return C;
  }

  constructor(ua) {
    logger.debug("new");

    super();

    this._id = null;
    this._ua = ua;
    this._status = C.STATUS_NULL;
    this._dialog = null;
    this._earlyDialogs = {};
    this._contact = null;
    this._from_tag = null;
    this._to_tag = null;
    this.callEntryTime = 0;
    // The RTCPeerConnection instance (public attribute).
    this._connection = null;

    // Prevent races on serial PeerConnction operations.
    this._connectionPromiseQueue = Promise.resolve();

    // Incoming/Outgoing request being currently processed.
    this._request = null;

    // Cancel state for initial outgoing request.
    this._is_canceled = false;
    this._cancel_reason = "";

    // RTCSession confirmation flag.
    this._is_confirmed = false;

    // Is late SDP being negotiated.
    this._late_sdp = false;

    // Default rtcOfferConstraints and rtcAnswerConstrainsts (passed in connect() or answer()).
    this._rtcOfferConstraints = null;
    this._rtcAnswerConstraints = null;

    // Local MediaStream.
    this._localMediaStream = null;
    this._localMediaStreamLocallyGenerated = false;

    // Flag to indicate PeerConnection ready for new actions.
    this._rtcReady = true;

    // Flag to indicate ICE candidate gathering is finished even if iceGatheringState is not yet 'complete'.
    this._iceReady = false;

    this._progressStatus = P.FINISHED;
    this.preSessionAccepted = false;

    // SIP Timers.
    this._timers = {
      ackTimer: null,
      expiresTimer: null,
      invite2xxTimer: null,
      userNoAnswerTimer: null,
    };

    // Session info.
    this._direction = null;
    this._local_identity = null;
    this._remote_identity = null;
    this._start_time = null;
    this._end_time = null;
    this._tones = null;

    // Mute/Hold state.
    this._audioMuted = false;
    this._videoMuted = false;
    this._localHold = false;
    this._remoteHold = false;

    // Session Timers (RFC 4028).
    this._sessionTimers = {
      enabled: this._ua.configuration.session_timers,
      refreshMethod: this._ua.configuration.session_timers_refresh_method,
      defaultExpires: JsSIP_C.SESSION_EXPIRES,
      currentExpires: null,
      running: false,
      refresher: false,
      timer: null, // A setTimeout.
    };

    // Map of ReferSubscriber instances indexed by the REFER's CSeq number.
    this._referSubscribers = {};

    // Custom session empty object for high level use.
    this._data = {};
  }

  /**
   * User API
   */

  // Expose RTCSession constants as a property of the RTCSession instance.
  get C() {
    return C;
  }

  // Expose session failed/ended causes as a property of the RTCSession instance.
  get causes() {
    return JsSIP_C.causes;
  }

  get id() {
    return this._id;
  }

  get connection() {
    return this._connection;
  }

  get contact() {
    return this._contact;
  }

  get direction() {
    return this._direction;
  }

  get local_identity() {
    return this._local_identity;
  }

  get remote_identity() {
    return this._remote_identity;
  }

  get start_time() {
    return this._start_time;
  }

  get end_time() {
    return this._end_time;
  }

  get data() {
    return this._data;
  }

  set data(_data) {
    this._data = _data;
  }

  get status() {
    return this._status;
  }

  isInProgress() {
    switch (this._status) {
      case C.STATUS_NULL:
      case C.STATUS_INVITE_SENT:
      case C.STATUS_1XX_RECEIVED:
      case C.STATUS_INVITE_RECEIVED:
      case C.STATUS_WAITING_FOR_ANSWER:
        return true;
      default:
        return false;
    }
  }

  isEstablished() {
    switch (this._status) {
      case C.STATUS_ANSWERED:
      case C.STATUS_WAITING_FOR_ACK:
      case C.STATUS_CONFIRMED:
        return true;
      default:
        return false;
    }
  }

  isEnded() {
    switch (this._status) {
      case C.STATUS_CANCELED:
      case C.STATUS_TERMINATED:
        return true;
      default:
        return false;
    }
  }

  isMuted() {
    return {
      audio: this._audioMuted,
      video: this._videoMuted,
    };
  }

  isOnHold() {
    return {
      local: this._localHold,
      remote: this._remoteHold,
    };
  }

  connect(target, options = {}, initCallback) {
    logger.debug("connect()");

    const originalTarget = target;
    const eventHandlers = Utils.cloneObject(options.eventHandlers);
    const extraHeaders = Utils.cloneArray(options.extraHeaders);
    const mediaConstraints = Utils.cloneObject(options.mediaConstraints, {
      audio: true,
      video: true,
    });
    const mediaStream = options.mediaStream || null;
    const pcConfig = Utils.cloneObject(options.pcConfig, { iceServers: [] });
    const rtcConstraints = options.rtcConstraints || null;
    const rtcOfferConstraints = options.rtcOfferConstraints || null;
    const disableSessionExpireHeader = options.disableSessionExpireHeader
      ? true
      : false;
    this._rtcOfferConstraints = rtcOfferConstraints;
    this._rtcAnswerConstraints = options.rtcAnswerConstraints || null;

    this._data = options.data || this._data;

    // Check target.
    if (target === undefined) {
      throw new TypeError("Not enough arguments");
    }

    // Check Session Status.
    if (this._status !== C.STATUS_NULL) {
      throw new Exceptions.InvalidStateError(this._status);
    }

    // Check WebRTC support.
    if (!window.RTCPeerConnection) {
      throw new Exceptions.NotSupportedError("WebRTC not supported");
    }

    // Check target validity.
    target = this._ua.normalizeTarget(target);
    if (!target) {
      throw new TypeError(`Invalid target: ${originalTarget}`);
    }

    // Session Timers.
    if (this._sessionTimers.enabled) {
      if (Utils.isDecimal(options.sessionTimersExpires)) {
        if (options.sessionTimersExpires >= JsSIP_C.MIN_SESSION_EXPIRES) {
          this._sessionTimers.defaultExpires = options.sessionTimersExpires;
        } else {
          this._sessionTimers.defaultExpires = JsSIP_C.SESSION_EXPIRES;
        }
      }
    }

    // Set event handlers.
    for (const event in eventHandlers) {
      if (Object.prototype.hasOwnProperty.call(eventHandlers, event)) {
        this.on(event, eventHandlers[event]);
      }
    }

    // Session parameter initialization.
    this._from_tag = Utils.newTag();

    // Set anonymous property.
    const anonymous = options.anonymous || false;

    const requestParams = { from_tag: this._from_tag };

    this._contact = this._ua.contact.toString({
      anonymous,
      outbound: true,
    });

    if (anonymous) {
      requestParams.from_display_name = "Anonymous";
      requestParams.from_uri = new URI("sip", "anonymous", "anonymous.invalid");

      extraHeaders.push(
        `P-Preferred-Identity: ${this._ua.configuration.uri.toString()}`
      );
      extraHeaders.push("Privacy: id");
    } else if (options.fromUserName) {
      requestParams.from_uri = new URI(
        "sip",
        options.fromUserName,
        this._ua.configuration.uri.host
      );

      extraHeaders.push(
        `P-Preferred-Identity: ${this._ua.configuration.uri.toString()}`
      );
    }

    if (options.fromDisplayName) {
      requestParams.from_display_name = options.fromDisplayName;
    }

    extraHeaders.push(`Contact: ${this._contact}`);
    extraHeaders.push("Content-Type: application/sdp");
    console.log(
      "disableSessionExpireHeader............1",
      disableSessionExpireHeader
    );
    if (this._sessionTimers.enabled && !disableSessionExpireHeader) {
      extraHeaders.push(
        `Session-Expires: ${this._sessionTimers.defaultExpires}${this._ua.configuration.session_timers_force_refresher
          ? ";refresher=uac"
          : ""
        }`
      );
    }

    this._request = new SIPMessage.InitialOutgoingInviteRequest(
      target,
      this._ua,
      requestParams,
      extraHeaders
    );

    this._id = this._request.call_id + this._from_tag;

    // Create a new RTCPeerConnection instance.
    this._createRTCConnection(pcConfig, rtcConstraints);

    // Set internal properties.
    this._direction = "outgoing";
    this._local_identity = this._request.from;
    this._remote_identity = this._request.to;

    // User explicitly provided a newRTCSession callback for this session.
    if (initCallback) {
      initCallback(this);
    }

    this._newRTCSession("local", this._request);

    this._sendInitialRequest(
      mediaConstraints,
      rtcOfferConstraints,
      mediaStream
    );
  }

  init_incoming(request, initCallback) {
    logger.debug("init_incoming()");

    let expires;
    const contentType = request.hasHeader("Content-Type")
      ? request.getHeader("Content-Type").toLowerCase()
      : undefined;
    logger.debug("init_incoming() calling..");
    // Check body and content type.
    // if (request.body && (contentType !== 'application/sdp'))
    // {
    //   request.reply(415);

    //   return;
    // }

    // Session parameter initialization.
    this._status = C.STATUS_INVITE_RECEIVED;
    this._from_tag = request.from_tag;
    this._id = request.call_id + this._from_tag;
    this._request = request;
    this._contact = this._ua.contact.toString();

    // Get the Expires header value if exists.
    if (request.hasHeader("expires")) {
      expires = request.getHeader("expires") * 1000;
    }

    /* Set the to_tag before
     * replying a response code that will create a dialog.
     */
    request.to_tag = Utils.newTag();

    // An error on dialog creation will fire 'failed' event.
    if (!this._createDialog(request, "UAS", true)) {
      console.log("Missing Contact header field 500 server internal error sending");
      request.reply(500, "Missing Contact header field");

      return;
    }

    if (request.body) {
      this._late_sdp = false;
    } else {
      this._late_sdp = true;
    }

    this._status = C.STATUS_WAITING_FOR_ANSWER;

    // Set userNoAnswerTimer.
    this._timers.userNoAnswerTimer = setTimeout(() => {
      request.reply(408);
      this._failed("local", null, JsSIP_C.causes.NO_ANSWER);
    }, this._ua.configuration.no_answer_timeout);

    /* Set expiresTimer
     * RFC3261 13.3.1
     */
    if (expires) {
      this._timers.expiresTimer = setTimeout(() => {
        if (this._status === C.STATUS_WAITING_FOR_ANSWER) {
          request.reply(487);
          this._failed("system", null, JsSIP_C.causes.EXPIRES);
        }
      }, expires);
    }

    // Set internal properties.
    this._direction = "incoming";
    this._local_identity = request.to;
    this._remote_identity = request.from;

    // A init callback was specifically defined.
    if (initCallback) {
      initCallback(this);
    }

    // Fire 'newRTCSession' event.
    this._newRTCSession("remote", request);

    // The user may have rejected the call in the 'newRTCSession' event.
    if (this._status === C.STATUS_TERMINATED) {
      return;
    }

    // Reply 180.
    request.reply(180, null, [`Contact: ${this._contact}`]);

    // Fire 'progress' event.
    // TODO: Document that 'response' field in 'progress' event is null for incoming calls.
    this._progress("local", null);
  }

  /**
   * Answer the call.
   */
  answer(options = {}) {
    logger.debug("answer()");

    const request = this._request;
    const extraHeaders = Utils.cloneArray(options.extraHeaders);
    const mediaConstraints = Utils.cloneObject(options.mediaConstraints);
    const mediaStream = options.mediaStream || null;
    const pcConfig = Utils.cloneObject(options.pcConfig, { iceServers: [] });
    const rtcConstraints = options.rtcConstraints || null;
    const rtcAnswerConstraints = options.rtcAnswerConstraints || null;
    const rtcOfferConstraints = Utils.cloneObject(options.rtcOfferConstraints);

    let tracks;
    let peerHasAudioLine = false;
    let peerHasVideoLine = false;
    let peerOffersFullAudio = false;
    let peerOffersFullVideo = false;

    this._rtcAnswerConstraints = rtcAnswerConstraints;
    this._rtcOfferConstraints = options.rtcOfferConstraints || null;

    this._data = options.data || this._data;
    const userAgent = this._ua.configuration.user_agent || JsSIP_C.USER_AGENT;

    // Check Session Direction and Status.
    if (this._direction !== "incoming") {
      throw new Exceptions.NotSupportedError(
        '"answer" not supported for outgoing RTCSession'
      );
    }

    // Check Session status.
    if (this._status !== C.STATUS_WAITING_FOR_ANSWER) {
      //throw new Exceptions.InvalidStateError(this._status);
      console.log("answer() not STATUS_WAITING_FOR_ANSWER");
      return;
    }

    // Session Timers.
    if (this._sessionTimers.enabled) {
      if (Utils.isDecimal(options.sessionTimersExpires)) {
        if (options.sessionTimersExpires >= JsSIP_C.MIN_SESSION_EXPIRES) {
          this._sessionTimers.defaultExpires = options.sessionTimersExpires;
        } else {
          this._sessionTimers.defaultExpires = JsSIP_C.SESSION_EXPIRES;
        }
      }
    }

    this._status = C.STATUS_ANSWERED;

    // An error on dialog creation will fire 'failed' event.
    if (!this._createDialog(request, "UAS")) {
      console.log('Error creating dialog 500 server internal error sending');
      request.reply(500, "Error creating dialog");

      return;
    }

    clearTimeout(this._timers.userNoAnswerTimer);

    extraHeaders.unshift(`Contact: ${this._contact}`);

    // Determine incoming media from incoming SDP offer (if any).
    const sdp = request.parseSDP();

    // Make sure sdp.media is an array, not the case if there is only one media.
    if (!Array.isArray(sdp.media)) {
      sdp.media = [sdp.media];
    }

    // Go through all medias in SDP to find offered capabilities to answer with.
    for (const m of sdp.media) {
      if (m.type === "audio") {
        peerHasAudioLine = true;
        if (!m.direction || m.direction === "sendrecv") {
          peerOffersFullAudio = true;
        }
      }
      if (m.type === "video") {
        peerHasVideoLine = true;
        if (!m.direction || m.direction === "sendrecv") {
          peerOffersFullVideo = true;
        }
      }
    }

    // Remove audio from mediaStream if suggested by mediaConstraints.
    if (mediaStream && mediaConstraints.audio === false) {
      tracks = mediaStream.getAudioTracks();
      for (const track of tracks) {
        mediaStream.removeTrack(track);
      }
    }

    // Remove video from mediaStream if suggested by mediaConstraints.
    if (mediaStream && mediaConstraints.video === false) {
      tracks = mediaStream.getVideoTracks();
      for (const track of tracks) {
        mediaStream.removeTrack(track);
      }
    }

    // Set audio constraints based on incoming stream if not supplied.
    if (!mediaStream && mediaConstraints.audio === undefined) {
      mediaConstraints.audio = peerOffersFullAudio;
    }

    // Set video constraints based on incoming stream if not supplied.
    if (!mediaStream && mediaConstraints.video === undefined) {
      mediaConstraints.video = peerOffersFullVideo;
    }

    // Don't ask for audio if the incoming offer has no audio section.
    if (
      !mediaStream &&
      !peerHasAudioLine &&
      !rtcOfferConstraints.offerToReceiveAudio
    ) {
      mediaConstraints.audio = false;
    }

    // Don't ask for video if the incoming offer has no video section.
    if (
      !mediaStream &&
      !peerHasVideoLine &&
      !rtcOfferConstraints.offerToReceiveVideo
    ) {
      mediaConstraints.video = false;
    }

    // Create a new RTCPeerConnection instance.
    // TODO: This may throw an error, should react.
    this._createRTCConnection(pcConfig, rtcConstraints);

    Promise.resolve()
      // Handle local MediaStream.
      .then(() => {
        // A local MediaStream is given, use it.
        // this.callEntryTime = moment().valueOf();
        // console.log(
        //   "ANSWER Callback Difference in time1 6666666",
        //   this.callEntryTime
        // );
        if (mediaStream) {
          return mediaStream;
        }

        // Audio and/or video requested, prompt getUserMedia.
        else if (mediaConstraints.audio || mediaConstraints.video) {
          this._localMediaStreamLocallyGenerated = true;

          return navigator.mediaDevices
            .getUserMedia(mediaConstraints)
            .catch((error) => {
              if (this._status === C.STATUS_TERMINATED) {
                throw new Error("terminated");
              }

              request.reply(480);
              this._failed(
                "local",
                null,
                JsSIP_C.causes.USER_DENIED_MEDIA_ACCESS
              );

              logger.warn('emit "getusermediafailed" [error:%o]', error);

              this.emit("getusermediafailed", error);

              throw new Error("getUserMedia() failed");
            });
        }
      })
      // Attach MediaStream to RTCPeerconnection.
      .then((stream) => {
        if (this._status === C.STATUS_TERMINATED) {
          throw new Error("terminated");
        }

        this._localMediaStream = stream;
        if (stream) {
          const os = this._ua.configuration.os;
          console.log("rtcsession userAgent...", userAgent, os);
          if (typeof this._connection.addStream === "function") {
            this._connection.addStream(stream);
          } else {
            stream.getTracks().forEach((track) => {
              this._connection.addTrack(track, stream);
            });
          }
          // this._updateBitRateForPeerConnection();
        }
      })
      // Set remote description.
      .then(() => {
        if (this._late_sdp) {
          return;
        }

        let offerSDP = request.body;

        const e = { originator: "remote", type: "offer", sdp: offerSDP };

        logger.debug('emit "sdp"');
        this.emit("sdp", e);

        const offer = new RTCSessionDescription({ type: "offer", sdp: e.sdp });
        this._progressStatus = P.STARTED;
        this._connectionPromiseQueue = this._connectionPromiseQueue
          .then(() => {
            return this._connection.setRemoteDescription(offer);
          })
          .catch((error) => {
            this._progressStatus = P.FINISHED;
            request.reply(488);

            this._failed("system", null, JsSIP_C.causes.WEBRTC_ERROR);

            logger.warn(
              'emit "peerconnection:setremotedescriptionfailed" [error:%o]',
              error
            );

            this.emit("peerconnection:setremotedescriptionfailed", error);

            throw new Error("peerconnection.setRemoteDescription() failed");
          });

        return this._connectionPromiseQueue;
      })
      .then(() => {
        console.log("answer() RemoteDescription callback ", this._progressStatus);
        if (this._progressStatus == P.TERMINATED) {
          this._progressStatus = P.FINISHED;
          console.log("answer() got terminated action before answer ");
          this._failed("remote", this._request, JsSIP_C.causes.CANCELED);
          return;
        }
        if (this._status === C.STATUS_TERMINATED) {
          this._progressStatus = P.FINISHED;
          throw new Error("terminated");
        }

        // TODO: Is this event already useful?
        this._connecting(request);

        if (!this._late_sdp) {
          return this._createLocalDescription(
            "answer",
            rtcAnswerConstraints
          ).catch(() => {
            console.log('_createLocalDescription answer 1 500 server internal error sending');
            request.reply(500);

            throw new Error("_createLocalDescription() failed");
          });
        } else {
          return this._createLocalDescription(
            "offer",
            this._rtcOfferConstraints
          ).catch(() => {
            console.log('_createLocalDescription offer 1 500 server internal error sending');
            request.reply(500);

            throw new Error("_createLocalDescription() failed");
          });
        }
      })
      // Send reply.
      .then((desc) => {
        if (this._progressStatus == P.TERMINATED) {
          this._progressStatus = P.FINISHED;
          console.log("answer() send block got terminated action before answer ");
          this._failed("remote", this._request, JsSIP_C.causes.CANCELED);
          return;
        }
        this._progressStatus = P.FINISHED;

        if (this._status === C.STATUS_TERMINATED) {
          throw new Error("terminated");
        }

        this._handleSessionTimersInIncomingRequest(
          request,
          extraHeaders,
          options
        );

        request.reply(
          200,
          null,
          extraHeaders,
          desc,
          () => {
            this._status = C.STATUS_WAITING_FOR_ACK;
            this._setInvite2xxTimer(request, desc);
            this._setACKTimer();
            this._accepted("local");
          },
          () => {
            this._failed("system", null, JsSIP_C.causes.CONNECTION_ERROR);
          }
        );
      })
      .catch((error) => {
        console.log("got_sdp error", error, userAgent);
        if (this._progressStatus == P.TERMINATED) {
          this._progressStatus = P.FINISHED;
          console.log("answer() catch got terminated action before answer ");
          this._failed("remote", this._request, JsSIP_C.causes.CANCELED);
          return;
        }
        this._progressStatus = P.FINISHED;
        if (this._status === C.STATUS_TERMINATED) {
          return;
        }

        logger.warn(error);
      });
  }

  /**
   * Terminate the call.
   */
  terminate(options = {}) {
    logger.debug("terminate()");
    console.log('terminate status..', this._status, this._progressStatus);
    const cause = options.cause || JsSIP_C.causes.BYE;
    const extraHeaders = Utils.cloneArray(options.extraHeaders);
    const body = options.body;

    let cancel_reason;
    let status_code = options.status_code;
    let reason_phrase = options.reason_phrase;

    // Check Session Status.
    if (this._status === C.STATUS_TERMINATED) {
      //throw new Exceptions.InvalidStateError(this._status);
      return;
    }

    switch (this._status) {
      // - UAC -
      case C.STATUS_NULL:
      case C.STATUS_INVITE_SENT:
      case C.STATUS_1XX_RECEIVED:
        logger.debug("canceling session");

        if (status_code && (status_code < 200 || status_code >= 700)) {
          throw new TypeError(`Invalid status_code: ${status_code}`);
        } else if (status_code) {
          reason_phrase =
            reason_phrase || JsSIP_C.REASON_PHRASE[status_code] || "";
          cancel_reason = `SIP ;cause=${status_code} ;text="${reason_phrase}"`;
        }

        // Check Session Status.
        if (
          this._status === C.STATUS_NULL ||
          this._status === C.STATUS_INVITE_SENT
        ) {
          this._is_canceled = true;
          this._cancel_reason = cancel_reason;
          console.log('canceling session..STATUS_INVITE_SENT');
          this._request.cancel(cancel_reason);
        } else if (this._status === C.STATUS_1XX_RECEIVED) {
          this._is_canceled = true;
          console.log('canceling session..STATUS_1XX_RECEIVED');
          this._request.cancel(cancel_reason);
        }

        this._status = C.STATUS_CANCELED;

        this._failed("local", null, JsSIP_C.causes.CANCELED);
        break;

      // - UAS -
      case C.STATUS_WAITING_FOR_ANSWER:
      case C.STATUS_ANSWERED:
        logger.debug("rejecting session");

        status_code = status_code || 480;

        if (status_code < 300 || status_code >= 700) {
          throw new TypeError(`Invalid status_code: ${status_code}`);
        }

        this._request.reply(status_code, reason_phrase, extraHeaders, body);
        //this._failed("local", null, JsSIP_C.causes.REJECTED);
        if (this._progressStatus == P.STARTED) {
          this._progressStatus = P.TERMINATED;
        }
        if (this._progressStatus == P.FINISHED) {
          this._failed("local", null, JsSIP_C.causes.REJECTED);
        }
        break;

      case C.STATUS_WAITING_FOR_ACK:
      case C.STATUS_CONFIRMED:
        logger.debug("terminating session");

        reason_phrase =
          options.reason_phrase || JsSIP_C.REASON_PHRASE[status_code] || "";

        if (status_code && (status_code < 200 || status_code >= 700)) {
          throw new TypeError(`Invalid status_code: ${status_code}`);
        } else if (status_code) {
          extraHeaders.push(
            `Reason: SIP ;cause=${status_code}; text="${reason_phrase}"`
          );
        }

        /* RFC 3261 section 15 (Terminating a session):
         *
         * "...the callee's UA MUST NOT send a BYE on a confirmed dialog
         * until it has received an ACK for its 2xx response or until the server
         * transaction times out."
         */
        if (
          this._status === C.STATUS_WAITING_FOR_ACK &&
          this._direction === "incoming" &&
          this._request.server_transaction.state !==
          Transactions.C.STATUS_TERMINATED
        ) {
          // Save the dialog for later restoration.
          const dialog = this._dialog;

          // Send the BYE as soon as the ACK is received...
          this.receiveRequest = ({ method }) => {
            if (method === JsSIP_C.ACK) {
              this.sendRequest(JsSIP_C.BYE, {
                extraHeaders,
                body,
              });
              dialog.terminate();
            }
          };

          // .., or when the INVITE transaction times out
          this._request.server_transaction.on("stateChanged", () => {
            if (
              this._request.server_transaction.state ===
              Transactions.C.STATUS_TERMINATED
            ) {
              this.sendRequest(JsSIP_C.BYE, {
                extraHeaders,
                body,
              });
              dialog.terminate();
            }
          });

          this._ended("local", null, cause);

          // Restore the dialog into 'this' in order to be able to send the in-dialog BYE :-).
          this._dialog = dialog;

          // Restore the dialog into 'ua' so the ACK can reach 'this' session.
          this._ua.newDialog(dialog);
        } else {
          console.log("terminate session reason_phrase ", reason_phrase);
          if (reason_phrase === JsSIP_C.causes.RTP_TIMEOUT || reason_phrase === JsSIP_C.causes.REQUEST_TIMEOUT
            || reason_phrase === JsSIP_C.causes.CONNECTION_ERROR) {
            let req = this._dialog.createByeRequest(JsSIP_C.BYE, {
              extraHeaders,
              body,
            })
            this._ua.handleByeReq(req);
          }
          if (this._progressStatus == P.FINISHED) {
            this.sendRequest(JsSIP_C.BYE, {
              extraHeaders,
              body,
            });
            this._ended("local", null, cause);
          }
          if (this._progressStatus == P.STARTED) {
            this._progressStatus = P.TERMINATED;
          }
        }
    }
  }

  sendDTMF(tones, options = {}) {
    logger.debug("sendDTMF() | tones: %s", tones);

    let position = 0;
    let duration = options.duration || null;
    let interToneGap = options.interToneGap || null;
    const transportType = options.transportType || JsSIP_C.DTMF_TRANSPORT.INFO;

    if (tones === undefined) {
      throw new TypeError("Not enough arguments");
    }

    // Check Session Status.
    if (
      this._status !== C.STATUS_CONFIRMED &&
      this._status !== C.STATUS_WAITING_FOR_ACK &&
      this._status !== C.STATUS_1XX_RECEIVED
    ) {
      throw new Exceptions.InvalidStateError(this._status);
    }

    // Check Transport type.
    if (
      transportType !== JsSIP_C.DTMF_TRANSPORT.INFO &&
      transportType !== JsSIP_C.DTMF_TRANSPORT.RFC2833
    ) {
      throw new TypeError(`invalid transportType: ${transportType}`);
    }

    // Convert to string.
    if (typeof tones === "number") {
      tones = tones.toString();
    }

    // Check tones.
    if (
      !tones ||
      typeof tones !== "string" ||
      !tones.match(/^[0-9A-DR#*,]+$/i)
    ) {
      throw new TypeError(`Invalid tones: ${tones}`);
    }

    // Check duration.
    if (duration && !Utils.isDecimal(duration)) {
      throw new TypeError(`Invalid tone duration: ${duration}`);
    } else if (!duration) {
      duration = RTCSession_DTMF.C.DEFAULT_DURATION;
    } else if (duration < RTCSession_DTMF.C.MIN_DURATION) {
      logger.debug(
        `"duration" value is lower than the minimum allowed, setting it to ${RTCSession_DTMF.C.MIN_DURATION} milliseconds`
      );
      duration = RTCSession_DTMF.C.MIN_DURATION;
    } else if (duration > RTCSession_DTMF.C.MAX_DURATION) {
      logger.debug(
        `"duration" value is greater than the maximum allowed, setting it to ${RTCSession_DTMF.C.MAX_DURATION} milliseconds`
      );
      duration = RTCSession_DTMF.C.MAX_DURATION;
    } else {
      duration = Math.abs(duration);
    }
    options.duration = duration;

    // Check interToneGap.
    if (interToneGap && !Utils.isDecimal(interToneGap)) {
      throw new TypeError(`Invalid interToneGap: ${interToneGap}`);
    } else if (!interToneGap) {
      interToneGap = RTCSession_DTMF.C.DEFAULT_INTER_TONE_GAP;
    } else if (interToneGap < RTCSession_DTMF.C.MIN_INTER_TONE_GAP) {
      logger.debug(
        `"interToneGap" value is lower than the minimum allowed, setting it to ${RTCSession_DTMF.C.MIN_INTER_TONE_GAP} milliseconds`
      );
      interToneGap = RTCSession_DTMF.C.MIN_INTER_TONE_GAP;
    } else {
      interToneGap = Math.abs(interToneGap);
    }

    // RFC2833. Let RTCDTMFSender enqueue the DTMFs.
    if (transportType === JsSIP_C.DTMF_TRANSPORT.RFC2833) {
      // Send DTMF in current audio RTP stream.
      const sender = this._getDTMFRTPSender();

      if (sender) {
        // Add remaining buffered tones.
        tones = sender.toneBuffer + tones;
        // Insert tones.
        sender.insertDTMF(tones, duration, interToneGap);
      }

      return;
    }

    if (this._tones) {
      // Tones are already queued, just add to the queue.
      this._tones += tones;

      return;
    }

    this._tones = tones;

    // Send the first tone.
    _sendDTMF.call(this);

    function _sendDTMF() {
      let timeout;

      if (
        this._status === C.STATUS_TERMINATED ||
        !this._tones ||
        position >= this._tones.length
      ) {
        // Stop sending DTMF.
        this._tones = null;

        return;
      }

      const tone = this._tones[position];

      position += 1;

      if (tone === ",") {
        timeout = 2000;
      } else {
        // Send DTMF via SIP INFO messages.
        const dtmf = new RTCSession_DTMF(this);

        options.eventHandlers = {
          onFailed: () => {
            this._tones = null;
          },
        };
        dtmf.send(tone, options);
        timeout = duration + interToneGap;
      }

      // Set timeout for the next tone.
      setTimeout(_sendDTMF.bind(this), timeout);
    }
  }

  sendInfo(contentType, body, options = {}) {
    logger.debug("sendInfo()");

    // Check Session Status.
    if (
      this._status !== C.STATUS_CONFIRMED &&
      this._status !== C.STATUS_WAITING_FOR_ACK &&
      this._status !== C.STATUS_1XX_RECEIVED
    ) {
      throw new Exceptions.InvalidStateError(this._status);
    }

    const info = new RTCSession_Info(this);

    info.send(contentType, body, options);
  }

  /**
   * Mute
   */
  mute(options = { audio: true, video: false }) {
    logger.debug("mute()");

    let audioMuted = false,
      videoMuted = false;

    if (this._audioMuted === false && options.audio) {
      audioMuted = true;
      this._audioMuted = true;
      this._toggleMuteAudio(true);
    }

    if (this._videoMuted === false && options.video) {
      videoMuted = true;
      this._videoMuted = true;
      this._toggleMuteVideo(true);
    }

    if (audioMuted === true || videoMuted === true) {
      this._onmute({
        audio: audioMuted,
        video: videoMuted,
      });
    }
  }

  /**
   * Unmute
   */
  unmute(options = { audio: true, video: true }) {
    logger.debug("unmute()");

    let audioUnMuted = false,
      videoUnMuted = false;

    if (this._audioMuted === true && options.audio) {
      audioUnMuted = true;
      this._audioMuted = false;

      if (this._localHold === false) {
        this._toggleMuteAudio(false);
      }
    }

    if (this._videoMuted === true && options.video) {
      videoUnMuted = true;
      this._videoMuted = false;

      if (this._localHold === false) {
        this._toggleMuteVideo(false);
      }
    }

    if (audioUnMuted === true || videoUnMuted === true) {
      this._onunmute({
        audio: audioUnMuted,
        video: videoUnMuted,
      });
    }
  }

  /**
   * Hold
   */
  hold(options = {}, done) {
    logger.debug("hold()");

    if (
      this._status !== C.STATUS_WAITING_FOR_ACK &&
      this._status !== C.STATUS_CONFIRMED
    ) {
      return false;
    }

    if (this._localHold === true) {
      return false;
    }

    if (!this._isReadyToReOffer()) {
      return false;
    }

    this._localHold = true;
    this._onhold("local");

    const eventHandlers = {
      succeeded: () => {
        if (done) {
          done();
        }
      },
      failed: () => {
        this.terminate({
          cause: JsSIP_C.causes.WEBRTC_ERROR,
          status_code: 500,
          reason_phrase: "Hold Failed",
        });
      },
    };

    if (options.useUpdate) {
      this._sendUpdate({
        sdpOffer: true,
        eventHandlers,
        extraHeaders: options.extraHeaders,
      });
    } else {
      this._sendReinvite({
        eventHandlers,
        extraHeaders: options.extraHeaders,
      });
    }

    return true;
  }

  unhold(options = {}, done) {
    logger.debug("unhold()");

    if (
      this._status !== C.STATUS_WAITING_FOR_ACK &&
      this._status !== C.STATUS_CONFIRMED
    ) {
      return false;
    }

    if (this._localHold === false) {
      return false;
    }

    if (!this._isReadyToReOffer()) {
      return false;
    }

    this._localHold = false;
    this._onunhold("local");

    const eventHandlers = {
      succeeded: () => {
        if (done) {
          done();
        }
      },
      failed: () => {
        this.terminate({
          cause: JsSIP_C.causes.WEBRTC_ERROR,
          status_code: 500,
          reason_phrase: "Unhold Failed",
        });
      },
    };

    if (options.useUpdate) {
      this._sendUpdate({
        sdpOffer: true,
        eventHandlers,
        extraHeaders: options.extraHeaders,
      });
    } else {
      this._sendReinvite({
        eventHandlers,
        extraHeaders: options.extraHeaders,
      });
    }

    return true;
  }

  renegotiate(options = {}, done) {
    logger.debug("renegotiate()");

    const rtcOfferConstraints = options.rtcOfferConstraints || null;

    if (
      this._status !== C.STATUS_WAITING_FOR_ACK &&
      this._status !== C.STATUS_CONFIRMED
    ) {
      return false;
    }

    if (!this._isReadyToReOffer()) {
      return false;
    }

    const eventHandlers = {
      succeeded: () => {
        if (done) {
          done();
        }
      },
      failed: () => {
        this.terminate({
          cause: JsSIP_C.causes.WEBRTC_ERROR,
          status_code: 500,
          reason_phrase: "Media Renegotiation Failed",
        });
      },
    };

    this._setLocalMediaStatus();

    if (options.useUpdate) {
      this._sendUpdate({
        sdpOffer: true,
        eventHandlers,
        rtcOfferConstraints,
        extraHeaders: options.extraHeaders,
      });
    } else {
      this._sendReinvite({
        eventHandlers,
        rtcOfferConstraints,
        extraHeaders: options.extraHeaders,
      });
    }

    return true;
  }

  /**
   * Refer
   */
  refer(target, options) {
    logger.debug("refer()");

    const originalTarget = target;
    console.log("refer........ 1", target, options);
    if (
      this._status !== C.STATUS_WAITING_FOR_ACK &&
      this._status !== C.STATUS_CONFIRMED
    ) {
      return false;
    }

    // Check target validity.
    target = this._ua.normalizeTarget(target);
    if (!target) {
      console.log("refer........ 2", target, options);
      throw new TypeError(`Invalid target: ${originalTarget}`);
    }

    const referSubscriber = new RTCSession_ReferSubscriber(this);

    referSubscriber.sendRefer(target, options);
    console.log("refer........ 3", target, options);
    // Store in the map.
    const id = referSubscriber.id;

    this._referSubscribers[id] = referSubscriber;

    // Listen for ending events so we can remove it from the map.
    referSubscriber.on("requestFailed", () => {
      console.log("refer........ 4", target, options);
      delete this._referSubscribers[id];
    });
    referSubscriber.on("accepted", () => {
      console.log("refer........ 5", target, options);
      delete this._referSubscribers[id];
    });
    referSubscriber.on("failed", () => {
      console.log("refer........ 6", target, options);
      delete this._referSubscribers[id];
    });
    console.log("refer........ 8", target, options);
    return referSubscriber;
  }

  /**
   * Send a generic in-dialog Request
   */
  sendRequest(method, options) {
    logger.debug("sendRequest()");

    return this._dialog.sendRequest(method, options);
  }

  /**
   * In dialog Request Reception
   */
  receiveRequest(request) {
    logger.debug("receiveRequest()");

    if (request.method === JsSIP_C.CANCEL) {
      /* RFC3261 15 States that a UAS may have accepted an invitation while a CANCEL
       * was in progress and that the UAC MAY continue with the session established by
       * any 2xx response, or MAY terminate with BYE. JsSIP does continue with the
       * established session. So the CANCEL is processed only if the session is not yet
       * established.
       */

      /*
       * Terminate the whole session in case the user didn't accept (or yet send the answer)
       * nor reject the request opening the session.
       */
      if (
        this._status === C.STATUS_WAITING_FOR_ANSWER ||
        this._status === C.STATUS_ANSWERED
      ) {
        console.log("got STATUS_WAITING_FOR_ANSWER block", this._progressStatus);
        this._status = C.STATUS_CANCELED;
        this._request.reply(487);
        if (this._progressStatus == P.STARTED) {
          this._progressStatus = P.TERMINATED;
        }
        if (this._progressStatus == P.FINISHED) {
          this._failed("remote", request, JsSIP_C.causes.CANCELED);
        }
      } else if (this._status == C.STATUS_WAITING_FOR_ACK) {
        this._status = C.STATUS_CANCELED;
        if (this._timers.ackTimer) {
          clearTimeout(this._timers.ackTimer);
        }
        if (this._timers.invite2xxTimer) {
          clearTimeout(this._timers.invite2xxTimer);
        }
        console.log("got cancel event before ACK");
        this._failed("remote", request, JsSIP_C.causes.CANCELED);
      }
    } else {
      // Requests arriving here are in-dialog requests.
      switch (request.method) {
        case JsSIP_C.ACK:
          if (this._status !== C.STATUS_WAITING_FOR_ACK) {
            return;
          }

          // Update signaling status.
          this._status = C.STATUS_CONFIRMED;

          clearTimeout(this._timers.ackTimer);
          clearTimeout(this._timers.invite2xxTimer);

          if (this._late_sdp) {
            if (!request.body) {
              this.terminate({
                cause: JsSIP_C.causes.MISSING_SDP,
                status_code: 400,
              });
              break;
            }

            const e = {
              originator: "remote",
              type: "answer",
              sdp: request.body,
            };

            logger.debug('emit "sdp"');
            this.emit("sdp", e);

            const answer = new RTCSessionDescription({
              type: "answer",
              sdp: e.sdp,
            });

            this._connectionPromiseQueue = this._connectionPromiseQueue
              .then(() => this._connection.setRemoteDescription(answer))
              .then(() => {
                if (!this._is_confirmed) {
                  this._confirmed("remote", request);
                }
              })
              .catch((error) => {
                this.terminate({
                  cause: JsSIP_C.causes.BAD_MEDIA_DESCRIPTION,
                  status_code: 488,
                });

                logger.warn(
                  'emit "peerconnection:setremotedescriptionfailed" [error:%o]',
                  error
                );
                this.emit("peerconnection:setremotedescriptionfailed", error);
              });
          } else if (!this._is_confirmed) {
            this._confirmed("remote", request);
          }

          break;
        case JsSIP_C.BYE:
          if (
            this._status === C.STATUS_CONFIRMED ||
            this._status === C.STATUS_WAITING_FOR_ACK
          ) {
            request.reply(200);
            this._ended("remote", request, JsSIP_C.causes.BYE);
          } else if (
            this._status === C.STATUS_INVITE_RECEIVED ||
            this._status === C.STATUS_WAITING_FOR_ANSWER
          ) {
            request.reply(200);
            this._request.reply(487, "BYE Received");
            this._ended("remote", request, JsSIP_C.causes.BYE);
          } else {
            request.reply(403, "Wrong Status");
          }
          break;
        case JsSIP_C.INVITE:
          if (this._status === C.STATUS_CONFIRMED) {
            if (request.hasHeader("replaces")) {
              this._receiveReplaces(request);
            } else {
              // request.reply(200, "OK");
              this._receiveReinvite(request);
            }
          } else {
            request.reply(403, "Wrong Status");
          }
          break;
        case JsSIP_C.INFO:
          if (
            this._status === C.STATUS_1XX_RECEIVED ||
            this._status === C.STATUS_WAITING_FOR_ANSWER ||
            this._status === C.STATUS_ANSWERED ||
            this._status === C.STATUS_WAITING_FOR_ACK ||
            this._status === C.STATUS_CONFIRMED
          ) {
            const contentType = request.hasHeader("Content-Type")
              ? request.getHeader("Content-Type").toLowerCase()
              : undefined;

            if (contentType && contentType.match(/^application\/dtmf-relay/i)) {
              new RTCSession_DTMF(this).init_incoming(request);
            } else if (contentType !== undefined) {
              new RTCSession_Info(this).init_incoming(request);
            } else {
              request.reply(415);
            }
          } else {
            request.reply(403, "Wrong Status");
          }
          break;
        case JsSIP_C.UPDATE:
          if (this._status === C.STATUS_CONFIRMED) {
            this._receiveUpdate(request);
          } else {
            request.reply(403, "Wrong Status");
          }
          break;
        case JsSIP_C.REFER:
          if (this._status === C.STATUS_CONFIRMED) {
            this._receiveRefer(request);
          } else {
            request.reply(403, "Wrong Status");
          }
          break;
        case JsSIP_C.NOTIFY:
          if (this._status === C.STATUS_CONFIRMED) {
            this._receiveNotify(request);
          } else {
            request.reply(403, "Wrong Status");
          }
          break;
        default:
          request.reply(501);
      }
    }
  }

  /**
   * Session Callbacks
   */

  onTransportError() {
    logger.warn("onTransportError()");

    if (this._status !== C.STATUS_TERMINATED) {
      this.terminate({
        status_code: 500,
        reason_phrase: JsSIP_C.causes.CONNECTION_ERROR,
        cause: JsSIP_C.causes.CONNECTION_ERROR,
      });
    }
  }

  onRequestTimeout() {
    logger.warn("onRequestTimeout()");

    if (this._status !== C.STATUS_TERMINATED) {
      this.terminate({
        status_code: 408,
        reason_phrase: JsSIP_C.causes.REQUEST_TIMEOUT,
        cause: JsSIP_C.causes.REQUEST_TIMEOUT,
      });
    }
  }

  onDialogError() {
    logger.warn("onDialogError()");

    if (this._status !== C.STATUS_TERMINATED) {
      this.terminate({
        status_code: 500,
        reason_phrase: JsSIP_C.causes.DIALOG_ERROR,
        cause: JsSIP_C.causes.DIALOG_ERROR,
      });
    }
  }

  // Called from DTMF handler.
  newDTMF(data) {
    logger.debug("newDTMF()");

    this.emit("newDTMF", data);
  }

  // Called from Info handler.
  newInfo(data) {
    logger.debug("newInfo()");

    this.emit("newInfo", data);
  }

  /**
   * Check if RTCSession is ready for an outgoing re-INVITE or UPDATE with SDP.
   */
  _isReadyToReOffer() {
    if (!this._rtcReady) {
      logger.debug("_isReadyToReOffer() | internal WebRTC status not ready");

      return false;
    }

    // No established yet.
    if (!this._dialog) {
      logger.debug("_isReadyToReOffer() | session not established yet");

      return false;
    }

    // Another INVITE transaction is in progress.
    if (
      this._dialog.uac_pending_reply === true ||
      this._dialog.uas_pending_reply === true
    ) {
      logger.debug(
        "_isReadyToReOffer() | there is another INVITE/UPDATE transaction in progress, was false we are sending true"
      );

      return true;
    }

    return true;
  }

  _close() {
    logger.debug("close() call session");

    // Close local MediaStream if it was not given by the user.
    if (this._localMediaStream || this._localMediaStreamLocallyGenerated) {
      logger.debug("close() | closing local MediaStream");

      Utils.closeMediaStream(this._localMediaStream);
    }

    if (this._status === C.STATUS_TERMINATED) {
      return;
    }

    this._status = C.STATUS_TERMINATED;

    // Terminate RTC.
    if (this._connection) {
      try {
        this._connection.close();
      } catch (error) {
        logger.warn("close() | error closing the RTCPeerConnection: %o", error);
      }
    }

    // Terminate signaling.

    // Clear SIP timers.
    for (const timer in this._timers) {
      if (Object.prototype.hasOwnProperty.call(this._timers, timer)) {
        clearTimeout(this._timers[timer]);
      }
    }

    // Clear Session Timers.
    clearTimeout(this._sessionTimers.timer);

    // Terminate confirmed dialog.
    if (this._dialog) {
      this._dialog.terminate();
      delete this._dialog;
    }

    // Terminate early dialogs.
    for (const dialog in this._earlyDialogs) {
      if (Object.prototype.hasOwnProperty.call(this._earlyDialogs, dialog)) {
        this._earlyDialogs[dialog].terminate();
        delete this._earlyDialogs[dialog];
      }
    }

    // Terminate REFER subscribers.
    for (const subscriber in this._referSubscribers) {
      if (
        Object.prototype.hasOwnProperty.call(this._referSubscribers, subscriber)
      ) {
        delete this._referSubscribers[subscriber];
      }
    }

    this._ua.destroyRTCSession(this);
  }

  /**
   * Private API.
   */

  /**
   * RFC3261 13.3.1.4
   * Response retransmissions cannot be accomplished by transaction layer
   *  since it is destroyed when receiving the first 2xx answer
   */
  _setInvite2xxTimer(request, body) {
    let timeout = Timers.T1;

    function invite2xxRetransmission() {
      if (this._status !== C.STATUS_WAITING_FOR_ACK) {
        return;
      }

      request.reply(200, null, [`Contact: ${this._contact}`], body);

      if (timeout < Timers.T2) {
        timeout = timeout * 2;
        if (timeout > Timers.T2) {
          timeout = Timers.T2;
        }
      }

      this._timers.invite2xxTimer = setTimeout(
        invite2xxRetransmission.bind(this),
        timeout
      );
    }

    this._timers.invite2xxTimer = setTimeout(
      invite2xxRetransmission.bind(this),
      timeout
    );
  }

  /**
   * RFC3261 14.2
   * If a UAS generates a 2xx response and never receives an ACK,
   *  it SHOULD generate a BYE to terminate the dialog.
   */
  _setACKTimer() {
    this._timers.ackTimer = setTimeout(() => {
      if (this._status === C.STATUS_WAITING_FOR_ACK) {
        logger.debug("no ACK received, terminating the session");

        clearTimeout(this._timers.invite2xxTimer);
        this.sendRequest(JsSIP_C.BYE);
        this._ended("remote", null, JsSIP_C.causes.NO_ACK);
      }
    }, Timers.TIMER_H);
  }

  _createRTCConnection(pcConfig, rtcConstraints) {
    this._connection = new RTCPeerConnection(pcConfig, rtcConstraints);

    // this._connection.addEventListener("iceconnectionstatechange", () => {
    //   const state = this._connection.iceConnectionState;
    //   console.log("iceconnectionstatechange ", state);
    //   // TODO: Do more with different states.
    //   if (state === "failed") {
    //     this.terminate({
    //       cause: JsSIP_C.causes.RTP_TIMEOUT,
    //       status_code: 408,
    //       reason_phrase: JsSIP_C.causes.RTP_TIMEOUT,
    //     });
    //   } 
    // });

    // this._connection.addEventListener("connectionstatechange", () => {
    //   const state = this._connection.connectionState;
    //   console.log("connectionstatechange ", state);
    //   if (state === "failed") {
    //     this._createLocalDescription("offer", { iceRestart: true });
    //   }
    // });

    this._connection.addEventListener("iceconnectionstatechange", () => {
      const state = this._connection.iceConnectionState;
      console.log("iceconnectionstatechange ", state);
      // TODO: Do more with different states.
      if (state === "failed" || state === "closed") {
        this.terminate({
          cause: JsSIP_C.causes.RTP_TIMEOUT,
          status_code: 408,
          reason_phrase: JsSIP_C.causes.RTP_TIMEOUT,
        });
      } else if (state === "disconnected") {
        this._createLocalDescription("offer", { iceRestart: true });
      }
      this.emit("connectionstate", {
        state: state
      });
    });

    this._connection.addEventListener("connectionstatechange", () => {
      const state = this._connection.connectionState;
      console.log("connectionstatechange ", state);
      if (state === "failed" || state === "closed") {
        this.terminate({
          cause: JsSIP_C.causes.RTP_TIMEOUT,
          status_code: 408,
          reason_phrase: JsSIP_C.causes.RTP_TIMEOUT,
        });
      }
    });

    this._connection.addEventListener("signalingstatechange", () => {
      const state = this._connection.signalingState;
      console.log("signalingstatechange ", state);
    });

    logger.debug('emit "peerconnection"');

    this.emit("peerconnection", {
      peerconnection: this._connection,
    });
  }

  _createLocalDescription(type, constraints) {
    logger.debug("createLocalDescription()", type);

    if (type !== "offer" && type !== "answer")
      throw new Error(`createLocalDescription() | invalid type "${type}"`);

    const connection = this._connection;
    this._rtcReady = false;
    try {
      return (
        Promise.resolve()
          // Create Offer or Answer.
          .then(() => {
            if (type === "offer") {
              return connection.createOffer(constraints).catch((error) => {
                logger.warn(
                  'emit "peerconnection:createofferfailed" [error:%o]',
                  error
                );

                this.emit("peerconnection:createofferfailed", error);

                return Promise.reject(error);
              });
            } else {
              return connection.createAnswer(constraints).catch((error) => {
                logger.warn(
                  'emit "peerconnection:createanswerfailed" [error:%o]',
                  error
                );

                this.emit("peerconnection:createanswerfailed", error);

                return Promise.reject(error);
              });
            }
          })
          // Set local description.
          .then((desc) => {
            console.log('going to setLocalDescription');
            return connection.setLocalDescription(desc).catch((error) => {
              this._rtcReady = true;

              logger.warn(
                'emit "peerconnection:setlocaldescriptionfailed" [error:%o]',
                error
              );

              this.emit("peerconnection:setlocaldescriptionfailed", error);

              return Promise.reject(error);
            });
          })
          .then(() => {
            // Resolve right away if 'pc.iceGatheringState' is 'complete'.
            /**
             * Resolve right away if:
             * - 'connection.iceGatheringState' is 'complete' and no 'iceRestart' constraint is set.
             * - 'connection.iceGatheringState' is 'gathering' and 'iceReady' is true.
             */

            const iceRestart = constraints && constraints.iceRestart;
            console.log("Going to add icecandidate handler..", connection.iceGatheringState, iceRestart);
            if (
              (connection.iceGatheringState === "complete" && !iceRestart) ||
              (connection.iceGatheringState === "gathering" && this._iceReady)
            ) {
              this._rtcReady = true;
              let modifiedSdp = sdp_transform.parse(
                connection.localDescription.sdp
              );
              for (let m of modifiedSdp.media) {
                if (m.setup != "active") {
                  m.setup = "active";
                }
              }
              let finalModifiedSdp = sdp_transform.write(modifiedSdp);
              const e = {
                originator: "local",
                type: type,
                sdp: finalModifiedSdp,
              };
              logger.debug('emit "sdp complete iceGatheringState"');
              this.emit("sdp", e);
              return Promise.resolve(e.sdp);
            }
            // Add 'pc.onicencandidate' event handler to resolve on last candidate.
            return new Promise((resolve) => {
              if (
                (connection.iceGatheringState === "complete") ||
                (connection.iceGatheringState === "gathering" && this._iceReady)
              ) {
                this._rtcReady = true;
                this._iceReady = true;

                let modifiedSdp = sdp_transform.parse(
                  connection.localDescription.sdp
                );
                for (let m of modifiedSdp.media) {
                  if (m.setup != "active") {
                    m.setup = "active";
                  }
                }
                let finalModifiedSdp = sdp_transform.write(modifiedSdp);
                const e = {
                  originator: "local",
                  type: type,
                  sdp: finalModifiedSdp,
                };
                logger.debug('emit "sdp iceGatheringState complete state"');
                this.emit("sdp", e);
                return resolve(e.sdp);
              }

              let finished = false;
              let iceCandidateListener;
              let iceGatheringStateListener;
              let iceCandidateErrorListener;

              this._iceReady = false;

              let icegatherTimer = setTimeout(() => {
                console.log("icegatherTimer block", finished);
                if (!finished) {
                  console.log("icegatherTimer block ready called");
                  ready();
                }
              }, 5000);

              const ready = () => {
                icegatherTimer && clearTimeout(icegatherTimer);

                connection.removeEventListener(
                  "icecandidate",
                  iceCandidateListener
                );
                connection.removeEventListener(
                  "icegatheringstatechange",
                  iceGatheringStateListener
                );

                connection.removeEventListener(
                  "icecandidateerror",
                  iceCandidateErrorListener
                );

                finished = true;
                this._rtcReady = true;

                // connection.iceGatheringState will still indicate 'gathering' and thus be blocking.
                this._iceReady = true;

                let modifiedSdp = sdp_transform.parse(
                  connection.localDescription.sdp
                );
                for (let m of modifiedSdp.media) {
                  if (m.setup != "active") {
                    m.setup = "active";
                  }
                }
                let finalModifiedSdp = sdp_transform.write(modifiedSdp);
                // console.log('got_sdp modifiedSdp final....', finalModifiedSdp);
                const e = {
                  originator: "local",
                  type: type,
                  sdp: finalModifiedSdp, //connection.localDescription.sdp,
                };
                logger.debug('emit "sdp in ready"');
                this.emit("sdp", e);
                resolve(e.sdp);
              };

              console.log("icecandidate() event handler");
              connection.addEventListener(
                "icecandidate",
                (iceCandidateListener = (event) => {
                  const candidate = event.candidate;
                  if (candidate) {
                    console.log("got candidate", candidate);
                    this.emit("icecandidate", {
                      candidate,
                      ready,
                    });
                  } else if (!finished) {
                    console.log("got candidate in not finished state");
                    ready();
                  }
                })
              );

              console.log("icegatheringstatechange() event handler");
              connection.addEventListener(
                "icegatheringstatechange",
                (iceGatheringStateListener = () => {
                  console.log("icegatheringstatechange() gather state ", connection.iceGatheringState, finished);
                  if (connection.iceGatheringState === "complete" && !finished) {
                    console.log("icegatheringstatechange event completed");
                    ready();
                  }
                })
              );

              console.log("iceCandidateErrorListener() event handler");
              connection.addEventListener("icecandidateerror", (iceCandidateErrorListener = (event) => {
                console.log("iceCandidateErrorListener event came", event, finished);
                if (!finished) {
                  ready();
                }
              }));

            });
          })
      );
    } catch (error) {
      console.log('getting error while creating call createLocalDescription', error);
      return Promise.reject(error)
    }
  }

  /**
   * Dialog Management
   */
  _createDialog(message, type, early) {
    const local_tag = type === "UAS" ? message.to_tag : message.from_tag;
    const remote_tag = type === "UAS" ? message.from_tag : message.to_tag;
    const id = message.call_id + local_tag + remote_tag;

    let early_dialog = this._earlyDialogs[id];
    // Early Dialog.
    if (early) {
      if (early_dialog) {
        return true;
      } else {
        early_dialog = new Dialog(this, message, type, Dialog.C.STATUS_EARLY);

        // Dialog has been successfully created.
        if (early_dialog.error) {
          console.log("_createDialog 3", early_dialog.error);
          logger.debug(early_dialog.error);
          this._failed("remote", message, JsSIP_C.causes.INTERNAL_ERROR);

          return false;
        } else {
          this._earlyDialogs[id] = early_dialog;

          return true;
        }
      }
    }

    // Confirmed Dialog.
    else {
      this._from_tag = message.from_tag;
      this._to_tag = message.to_tag;

      // In case the dialog is in _early_ state, update it.
      if (early_dialog) {
        early_dialog.update(message, type);
        this._dialog = early_dialog;
        delete this._earlyDialogs[id];

        return true;
      }

      // Otherwise, create a _confirmed_ dialog.
      const dialog = new Dialog(this, message, type);

      if (dialog.error) {
        console.log("_createDialog 4", dialog.error);
        logger.debug(dialog.error);
        this._failed("remote", message, JsSIP_C.causes.INTERNAL_ERROR);

        return false;
      } else {
        this._dialog = dialog;

        return true;
      }
    }
  }

  /**
   * In dialog INVITE Reception
   */

  _receiveReinvite(request) {
    logger.debug("receiveReinvite()");

    const contentType = request.hasHeader("Content-Type")
      ? request.getHeader("Content-Type").toLowerCase()
      : undefined;
    const data = {
      request,
      callback: undefined,
      reject: reject.bind(this),
    };

    let rejected = false;

    function reject(options = {}) {
      rejected = true;

      const status_code = options.status_code || 403;
      const reason_phrase = options.reason_phrase || "";
      const extraHeaders = Utils.cloneArray(options.extraHeaders);

      if (this._status !== C.STATUS_CONFIRMED) {
        return false;
      }

      if (status_code < 300 || status_code >= 700) {
        throw new TypeError(`Invalid status_code: ${status_code}`);
      }

      request.reply(status_code, reason_phrase, extraHeaders);
    }
    function sendAnswer(desc) {
      const extraHeaders = [`Contact: ${this._contact}`];

      this._handleSessionTimersInIncomingRequest(request, extraHeaders);

      if (this._late_sdp && desc) {
        desc = this._mangleOffer(desc);
      }

      request.reply(200, null, extraHeaders, desc, () => {
        this._status = C.STATUS_WAITING_FOR_ACK;
        this._setInvite2xxTimer(request, desc);
        this._setACKTimer();
      });

      // If callback is given execute it.
      if (typeof data.callback === "function") {
        data.callback();
      }
    }
    data.sendAnswer = sendAnswer;

    if (request.hasHeader("Contact")) {
      let headerValStr = request.getHeader("Contact");

      if (headerValStr) {
        let headerValArr = headerValStr.split(";");
        console.log("reinvite init headers...", headerValArr);
        if (headerValArr && headerValArr.length > 0) {
          if (headerValArr[1] == "isfocus") {
            // Merge call condition
            this.emit("reinvite", data);
          } else if (headerValStr.includes("rendering")) {
            // Hold call condition
            this.emit("reinvite", data);
          } else {
            // Emit 'reinvite'.
            //Check wheather Pressesion is completely setup or not, then we send reinvite
            console.log("pressession setup for this reinvite..", this.preSessionAccepted);
            if (this.preSessionAccepted) {
              this.emit("reinvite", data);
            } else {
              // reject the request
              console.log("pressession setup is still in progress");
              try {
                this._rejectForReinvite(request, {
                  cause: JsSIP_C.causes.CANCELED,
                  ignoreCondition: true
                });
              } catch (err) {
                console.log("_rejectForReinvite session catch", err);
              }
            }
            return; // handling for reinvite preestablished private calls
          }
        }
      }
    }
    if (rejected) {
      return;
    }
    this._late_sdp = false;

    // Request without SDP.
    if (!request.body) {
      this._late_sdp = true;
      if (this._remoteHold) {
        this._remoteHold = false;
        this._onunhold("remote");
      }
      this._connectionPromiseQueue = this._connectionPromiseQueue
        .then(() =>
          this._createLocalDescription("offer", this._rtcOfferConstraints)
        )
        .then((sdp) => {
          sendAnswer.call(this, sdp);
        })
        .catch(() => {
          console.log('_createLocalDescription body reeciveReinvite server internal error sending');
          request.reply(500);
        });

      return;
    }

    // Request with SDP.
    if (contentType !== "application/sdp") {
      // logger.debug('invalid Content-Type');
      // request.reply(415);
      // return;
    }
    let localdescription = this.connection.localDescription;
    if (localdescription) {
      if (this._status === C.STATUS_TERMINATED) {
        return;
      }
      sendAnswer.call(this, localdescription);
    } else {
      logger.warn("No local description found");
    }
    // this._processInDialogSdpOffer(request)
    //   // Send answer.
    //   .then((desc) => {
    //     if (this._status === C.STATUS_TERMINATED) {
    //       return;
    //     }

    //     sendAnswer.call(this, desc);
    //   })
    //   .catch((error) => {
    //     logger.warn(error);
    //   });
  }
  _rejectForReinvite(request, options = {}) {
    console.log(
      "_rejectForReinvite() ",
      this._status,
      C.STATUS_CONFIRMED,
      options
    );

    const status_code = options.status_code || 403;
    const reason_phrase = options.reason_phrase || "";
    const ignoreCondition = options.ignoreCondition || false;
    const extraHeaders = Utils.cloneArray(options.extraHeaders);

    if (!ignoreCondition) {
      if (this._status !== C.STATUS_CONFIRMED) {
        return false;
      }
      if (status_code < 300 || status_code >= 700) {
        //throw new TypeError(`Invalid status_code: ${status_code}`);
        return false;
      }
    }

    if (request && typeof request.reply === 'function') {
      request.reply(status_code, reason_phrase, extraHeaders);
    } else {
      console.log("finalReq has not reply function");
      return false;
    }
    return true;
  }

  _sendReply200Ok(request, options = {}) {
    console.log("_sendReply200Ok()");
    const extraHeaders = [`Contact: ${this._contact}`];
    let desc = this.connection.localDescription;
    // this._handleSessionTimersInIncomingRequest(request, extraHeaders, options);

    // if (this._late_sdp) {
    //   desc = this._mangleOffer(desc);
    // }

    request.reply(200, "OK", extraHeaders, desc, (data) => {
      console.log("_sendReply200Ok callback", data);
    });
  }
  _sendAnswerForReinvite(request, options = {}) {
    console.log("_sendAnswerForReinvite()", this._status);
    if (this._status == C.STATUS_TERMINATED) {
      console.log("_sendAnswerForReinvite() already in termionated state");
      return false;
    }
    if (!request || !request.server_transaction || request.server_transaction.transportError) {
      console.log("_sendAnswerForReinvite() no rquest or no server_transaction available or transportError true");
      return false;
    }
    if (!request || !request.server_transaction || request.server_transaction.transportError) {
      console.log("_sendAnswerForReinvite() no rquest or no server_transaction available or transportError true");
      return;
    }
    const extraHeaders = [`Contact: ${this._contact}`];
    let desc = this.connection.localDescription;
    this._handleSessionTimersInIncomingRequest(request, extraHeaders, options);
    // if (this._late_sdp) {
    //   desc = this._mangleOffer(desc);
    // }
    this._status = C.STATUS_ANSWERED;
    request.reply(200, null, extraHeaders, desc, () => {
      this._status = C.STATUS_WAITING_FOR_ACK;
      this._setInvite2xxTimer(request, desc);
      this._setACKTimer();
    });
    return true;
  }
  /**
   * In dialog UPDATE Reception
   */
  _receiveUpdate(request) {
    logger.debug("receiveUpdate()");

    const contentType = request.hasHeader("Content-Type")
      ? request.getHeader("Content-Type").toLowerCase()
      : undefined;
    const data = {
      request,
      callback: undefined,
      reject: reject.bind(this),
    };

    let rejected = false;

    function reject(options = {}) {
      rejected = true;

      const status_code = options.status_code || 403;
      const reason_phrase = options.reason_phrase || "";
      const extraHeaders = Utils.cloneArray(options.extraHeaders);

      if (this._status !== C.STATUS_CONFIRMED) {
        return false;
      }

      if (status_code < 300 || status_code >= 700) {
        throw new TypeError(`Invalid status_code: ${status_code}`);
      }

      request.reply(status_code, reason_phrase, extraHeaders);
    }

    // Emit 'update'.
    this.emit("update", data);

    if (rejected) {
      return;
    }

    if (!request.body) {
      sendAnswer.call(this, null);

      return;
    }

    if (contentType !== "application/sdp") {
      // logger.debug('invalid Content-Type');
      // request.reply(415);
      // return;
    }
    let localdescription = this.connection.localDescription;
    if (localdescription) {
      if (this._status === C.STATUS_TERMINATED) {
        return;
      }
      sendAnswer.call(this, localdescription);
    } else {
      logger.warn("No local description found");
    }
    // this._processInDialogSdpOffer(request)
    //   // Send answer.
    //   .then((desc) => {
    //     if (this._status === C.STATUS_TERMINATED) {
    //       return;
    //     }

    //     sendAnswer.call(this, desc);
    //   })
    //   .catch((error) => {
    //     logger.warn(error);
    //   });

    function sendAnswer(desc) {
      const extraHeaders = [`Contact: ${this._contact}`];

      this._handleSessionTimersInIncomingRequest(request, extraHeaders);

      request.reply(200, null, extraHeaders, desc);

      // If callback is given execute it.
      if (typeof data.callback === "function") {
        data.callback();
      }
    }
  }

  _processInDialogSdpOffer(request) {
    logger.debug("_processInDialogSdpOffer()");

    const sdp = request.parseSDP();

    let hold = false;

    for (const m of sdp.media) {
      if (holdMediaTypes.indexOf(m.type) === -1) {
        continue;
      }

      const direction = m.direction || sdp.direction || "sendrecv";

      if (direction === "sendonly" || direction === "inactive") {
        hold = true;
      }
      // If at least one of the streams is active don't emit 'hold'.
      else {
        hold = false;
        break;
      }
    }

    const e = { originator: "remote", type: "offer", sdp: request.body };

    logger.debug('emit "sdp"');
    this.emit("sdp", e);

    const offer = new RTCSessionDescription({ type: "offer", sdp: e.sdp });

    this._connectionPromiseQueue = this._connectionPromiseQueue
      // Set remote description.
      .then(() => {
        if (this._status === C.STATUS_TERMINATED) {
          throw new Error("terminated");
        }

        return this._connection.setRemoteDescription(offer).catch((error) => {
          request.reply(488);
          logger.warn(
            'emit "peerconnection:setremotedescriptionfailed" [error:%o]',
            error
          );

          this.emit("peerconnection:setremotedescriptionfailed", error);

          throw error;
        });
      })
      .then(() => {
        if (this._status === C.STATUS_TERMINATED) {
          throw new Error("terminated");
        }

        if (this._remoteHold === true && hold === false) {
          this._remoteHold = false;
          this._onunhold("remote");
        } else if (this._remoteHold === false && hold === true) {
          this._remoteHold = true;
          this._onhold("remote");
        }
      })
      // Create local description.
      .then(() => {
        if (this._status === C.STATUS_TERMINATED) {
          throw new Error("terminated");
        }

        return this._createLocalDescription(
          "answer",
          this._rtcAnswerConstraints
        ).catch((error) => {
          console.log('_createLocalDescription processInDialogOffer 500 server internal error sending');
          request.reply(500);
          logger.warn(
            'emit "peerconnection:createtelocaldescriptionfailed" [error:%o]',
            error
          );

          throw error;
        });
      })
      .catch((error) => {
        logger.warn("_processInDialogSdpOffer() failed [error: %o]", error);
      });

    return this._connectionPromiseQueue;
  }

  /**
   * In dialog Refer Reception
   */
  _receiveRefer(request) {
    logger.debug("receiveRefer()");

    if (!request.refer_to) {
      logger.debug("no Refer-To header field present in REFER");
      request.reply(400);

      return;
    }

    if (request.refer_to.uri.scheme !== JsSIP_C.SIP) {
      logger.debug("Refer-To header field points to a non-SIP URI scheme");
      request.reply(416);

      return;
    }

    // Reply before the transaction timer expires.
    request.reply(202);

    const notifier = new RTCSession_ReferNotifier(this, request.cseq);

    logger.debug('emit "refer"');

    // Emit 'refer'.
    this.emit("refer", {
      request,
      accept: (initCallback, options) => {
        accept.call(this, initCallback, options);
      },
      reject: () => {
        reject.call(this);
      },
    });

    function accept(initCallback, options = {}) {
      initCallback = typeof initCallback === "function" ? initCallback : null;

      if (
        this._status !== C.STATUS_WAITING_FOR_ACK &&
        this._status !== C.STATUS_CONFIRMED
      ) {
        return false;
      }

      const session = new RTCSession(this._ua);

      session.on("progress", ({ response }) => {
        notifier.notify(response.status_code, response.reason_phrase);
      });

      session.on("accepted", ({ response }) => {
        notifier.notify(response.status_code, response.reason_phrase);
      });

      session.on("_failed", ({ message, cause }) => {
        if (message) {
          notifier.notify(message.status_code, message.reason_phrase);
        } else {
          notifier.notify(487, cause);
        }
      });

      // Consider the Replaces header present in the Refer-To URI.
      if (request.refer_to.uri.hasHeader("replaces")) {
        const replaces = decodeURIComponent(
          request.refer_to.uri.getHeader("replaces")
        );

        options.extraHeaders = Utils.cloneArray(options.extraHeaders);
        options.extraHeaders.push(`Replaces: ${replaces}`);
      }

      session.connect(request.refer_to.uri.toAor(), options, initCallback);
    }

    function reject() {
      notifier.notify(603);
    }
  }

  /**
   * In dialog Notify Reception
   */
  _receiveNotify(request) {
    logger.debug("receiveNotify()");

    if (!request.event) {
      request.reply(400);
    }

    switch (request.event.event) {
      case "refer": {
        let id;
        let referSubscriber;

        if (request.event.params && request.event.params.id) {
          id = request.event.params.id;
          referSubscriber = this._referSubscribers[id];
        } else if (Object.keys(this._referSubscribers).length === 1) {
          referSubscriber = this._referSubscribers[
            Object.keys(this._referSubscribers)[0]
          ];
        } else {
          request.reply(400, "Missing event id parameter");

          return;
        }

        if (!referSubscriber) {
          request.reply(481, "Subscription does not exist");

          return;
        }

        referSubscriber.receiveNotify(request);
        request.reply(200);

        break;
      }

      default: {
        request.reply(489);
      }
    }
  }

  /**
   * INVITE with Replaces Reception
   */
  _receiveReplaces(request) {
    logger.debug("receiveReplaces()");

    function accept(initCallback) {
      if (
        this._status !== C.STATUS_WAITING_FOR_ACK &&
        this._status !== C.STATUS_CONFIRMED
      ) {
        return false;
      }

      const session = new RTCSession(this._ua);

      // Terminate the current session when the new one is confirmed.
      session.on("confirmed", () => {
        this.terminate();
      });

      session.init_incoming(request, initCallback);
    }

    function reject() {
      logger.debug("Replaced INVITE rejected by the user");
      request.reply(486);
    }

    // Emit 'replace'.
    this.emit("replaces", {
      request,
      accept: (initCallback) => {
        accept.call(this, initCallback);
      },
      reject: () => {
        reject.call(this);
      },
    });
  }

  /**
   * Initial Request Sender
   */
  _sendInitialRequest(mediaConstraints, rtcOfferConstraints, mediaStream) {
    const request_sender = new RequestSender(this._ua, this._request, {
      onRequestTimeout: () => {
        this.onRequestTimeout();
      },
      onTransportError: () => {
        this.onTransportError();
      },
      // Update the request on authentication.
      onAuthenticated: (request) => {
        this._request = request;
      },
      onReceiveResponse: (response) => {
        this._receiveInviteResponse(response);
      },
    });

    // This Promise is resolved within the next iteration, so the app has now
    // a chance to set events such as 'peerconnection' and 'connecting'.
    Promise.resolve()
      // Get a stream if required.
      .then(() => {
        // A stream is given, let the app set events such as 'peerconnection' and 'connecting'.
        if (mediaStream) {
          return mediaStream;
        }
        // Request for user media access.
        else if (mediaConstraints.audio || mediaConstraints.video) {
          this._localMediaStreamLocallyGenerated = true;

          return navigator.mediaDevices
            .getUserMedia(mediaConstraints)
            .catch((error) => {
              if (this._status === C.STATUS_TERMINATED) {
                throw new Error("terminated");
              }

              this._failed(
                "local",
                null,
                JsSIP_C.causes.USER_DENIED_MEDIA_ACCESS
              );

              logger.warn('emit "getusermediafailed" [error:%o]', error);

              this.emit("getusermediafailed", error);

              throw error;
            });
        }
      })
      .then((stream) => {
        if (this._status === C.STATUS_TERMINATED) {
          throw new Error("terminated");
        }

        this._localMediaStream = stream;

        if (stream) {
          const userAgent =
            this._ua.configuration.user_agent || JsSIP_C.USER_AGENT;
          const os = this._ua.configuration.os;
          console.log("rtcsession userAgent...", userAgent, os);
          if (typeof this._connection.addStream === "function") {
            this._connection.addStream(stream);
          } else {
            stream.getTracks().forEach((track) => {
              this._connection.addTrack(track, stream);
            });
          }
          // this._updateBitRateForPeerConnection();
        }

        // TODO: should this be triggered here?
        this._connecting(this._request);

        return this._createLocalDescription("offer", rtcOfferConstraints).catch(
          (error) => {
            this._progressStatus = P.FINISHED;
            this._failed("local", null, JsSIP_C.causes.WEBRTC_ERROR);
            throw error;
          }
        );
      })
      .then((desc) => {
        if (this._is_canceled || this._status === C.STATUS_TERMINATED) {
          this._progressStatus = P.FINISHED;
          throw new Error("terminated");
        }
        this._progressStatus = P.FINISHED;

        this._request.body = desc;
        this._status = C.STATUS_INVITE_SENT;

        //logger.debug('emit "sending" [request:%o]', this._request);

        // Emit 'sending' so the app can mangle the body before the request is sent.
        this.emit("sending", {
          request: this._request,
        });

        request_sender.send();
      })
      .catch((error) => {
        this._progressStatus = P.FINISHED;
        if (this._status === C.STATUS_TERMINATED) {
          return;
        }
        logger.warn(error);
      });
  }

  isDeviceTypeHandsetOrAndroid() {
    const userAgent = this._ua.configuration.user_agent || JsSIP_C.USER_AGENT;
    const os = this._ua.configuration.os;
    console.log("rtcsession userAgent...", userAgent, os);

    if (
      (userAgent && userAgent.toLowerCase().includes("handset")) ||
      (os && os.toLowerCase().includes("android"))
    ) {
      return true;
    }
    return false;
  }
  /**
   * Get DTMF RTCRtpSender.
   */
  _getDTMFRTPSender() {
    const sender = this._connection.getSenders().find((rtpSender) => {
      return rtpSender.track && rtpSender.track.kind === "audio";
    });

    if (!(sender && sender.dtmf)) {
      logger.warn("sendDTMF() | no local audio track to send DTMF with");

      return;
    }

    return sender.dtmf;
  }

  /**
   * Reception of Response for Initial INVITE
   */
  _receiveInviteResponse(response) {
    logger.debug("receiveInviteResponse()");

    // Handle 2XX retransmissions and responses from forked requests.
    if (
      this._dialog &&
      response.status_code >= 200 &&
      response.status_code <= 299
    ) {
      /*
       * If it is a retransmission from the endpoint that established
       * the dialog, send an ACK
       */
      if (
        this._dialog.id.call_id === response.call_id &&
        this._dialog.id.local_tag === response.from_tag &&
        this._dialog.id.remote_tag === response.to_tag
      ) {
        this.sendRequest(JsSIP_C.ACK);

        return;
      }

      // If not, send an ACK  and terminate.
      else {
        const dialog = new Dialog(this, response, "UAC");

        if (dialog.error !== undefined) {
          logger.debug(dialog.error);

          return;
        }

        this.sendRequest(JsSIP_C.ACK);
        this.sendRequest(JsSIP_C.BYE);

        return;
      }
    }

    // Proceed to cancellation if the user requested.
    if (this._is_canceled) {
      if (response.status_code >= 100 && response.status_code < 200) {
        this._request.cancel(this._cancel_reason);
      } else if (response.status_code >= 200 && response.status_code < 299) {
        this._progressStatus = P.FINISHED;
        this._acceptAndTerminate(response);
      }
      return;
    }

    if (
      this._status !== C.STATUS_INVITE_SENT &&
      this._status !== C.STATUS_1XX_RECEIVED
    ) {
      return;
    }

    switch (true) {
      case /^100$/.test(response.status_code):
        this._status = C.STATUS_1XX_RECEIVED;
        break;

      case /^1[0-9]{2}$/.test(response.status_code): {
        // Do nothing with 1xx responses without To tag.
        if (!response.to_tag) {
          logger.debug("1xx response received without to tag");
          break;
        }

        // Create Early Dialog if 1XX comes with contact.
        if (response.hasHeader("contact")) {
          // An error on dialog creation will fire 'failed' event.
          if (!this._createDialog(response, "UAC", true)) {
            break;
          }
        }

        this._status = C.STATUS_1XX_RECEIVED;

        if (!response.body) {
          this._progress("remote", response);
          break;
        }

        const e = { originator: "remote", type: "answer", sdp: response.body };

        logger.debug('emit "sdp"');
        this.emit("sdp", e);

        const answer = new RTCSessionDescription({
          type: "answer",
          sdp: e.sdp,
        });

        this._connectionPromiseQueue = this._connectionPromiseQueue
          .then(() => this._connection.setRemoteDescription(answer))
          .then(() => this._progress("remote", response))
          .catch((error) => {
            this._progressStatus = P.FINISHED;
            logger.warn(
              'emit "peerconnection:setremotedescriptionfailed" [error:%o]',
              error
            );

            this.emit("peerconnection:setremotedescriptionfailed", error);
          });
        break;
      }

      case /^2[0-9]{2}$/.test(response.status_code): {
        this._status = C.STATUS_CONFIRMED;

        if (!response.body) {
          this._progressStatus = P.FINISHED;
          this._acceptAndTerminate(response, 400, JsSIP_C.causes.MISSING_SDP);
          this._failed(
            "remote",
            response,
            JsSIP_C.causes.BAD_MEDIA_DESCRIPTION
          );
          break;
        }

        // An error on dialog creation will fire 'failed' event.
        if (!this._createDialog(response, "UAC")) {
          break;
        }

        const e = { originator: "remote", type: "answer", sdp: response.body };

        logger.debug('emit "sdp remote"');
        this.emit("sdp", e);

        const answer = new RTCSessionDescription({
          type: "answer",
          sdp: e.sdp,
        });

        this._connectionPromiseQueue = this._connectionPromiseQueue
          .then(() => {
            // Be ready for 200 with SDP after a 180/183 with SDP.
            // We created a SDP 'answer' for it, so check the current signaling state.
            if (this._connection.signalingState === "stable") {
              try {
                return this._connection
                  .createOffer(this._rtcOfferConstraints)
                  .then((offer) => this._connection.setLocalDescription(offer))
                  .catch((error) => {
                    this._progressStatus = P.FINISHED;
                    this._acceptAndTerminate(response, 500, error.toString());
                    this._failed("local", response, JsSIP_C.causes.WEBRTC_ERROR);
                  });
              } catch (error) {
                console.log('getting error while creating local stream or offer..', error);
                this._progressStatus = P.FINISHED;
                this._acceptAndTerminate(response, 500, error.toString());
                this._failed("local", response, JsSIP_C.causes.WEBRTC_ERROR);
                return;
              }
            }
          })
          .then(() => {
            try {
              this._progressStatus = P.STARTED;
              console.log('going to set remote Description for recieve invite response');
              this._connection
                .setRemoteDescription(answer)
                .then(() => {
                  if (this._progressStatus == P.TERMINATED) {
                    //Call is terminated before remote description
                    console.log('Call is terminated before remote description');
                    this._progressStatus = P.FINISHED;
                    this.terminate();
                    return;
                  }
                  this._progressStatus = P.FINISHED;
                  // Handle Session Timers.
                  this._handleSessionTimersInIncomingResponse(response);

                  this._accepted("remote", response);
                  this.sendRequest(JsSIP_C.ACK);
                  this._confirmed("local", null);
                })
                .catch((error) => {
                  this._progressStatus = P.FINISHED;
                  console.log('getting error while setting remote stream..', error);
                  this._acceptAndTerminate(response, 488, "Not Acceptable Here");
                  this._failed(
                    "remote",
                    response,
                    JsSIP_C.causes.BAD_MEDIA_DESCRIPTION
                  );
                  logger.warn(
                    'emit "peerconnection:setremotedescriptionfailed" [error:%o]',
                    error
                  );

                  this.emit("peerconnection:setremotedescriptionfailed", error);
                });
            } catch (error) {
              this._progressStatus = P.FINISHED;
              console.log('getting error while creating remote stream..', error);
              this._acceptAndTerminate(response, 500, error.toString());
              this._failed("local", response, JsSIP_C.causes.WEBRTC_ERROR);
              return;
            }
          });
        break;
      }

      default: {
        const cause = Utils.sipErrorCause(response.status_code);
        this._progressStatus = P.FINISHED;
        this._failed("remote", response, cause);
      }
    }
  }

  /**
   * Send Re-INVITE
   */
  _sendReinvite(options = {}) {
    logger.debug("sendReinvite()");

    const extraHeaders = Utils.cloneArray(options.extraHeaders);
    const eventHandlers = Utils.cloneObject(options.eventHandlers);
    const rtcOfferConstraints =
      options.rtcOfferConstraints || this._rtcOfferConstraints || null;
    const disableSessionExpireHeader = options.disableSessionExpireHeader
      ? true
      : false;
    let succeeded = false;
    if (this._localHold) {
      extraHeaders.push(`Contact: ${this._contact};+sip.rendering="no"`);
    } else {
      extraHeaders.push(`Contact: ${this._contact};+sip.rendering="yes"`);
    }
    extraHeaders.push("Content-Type: application/sdp");
    // Session Timers.
    if (this._sessionTimers.running && !disableSessionExpireHeader) {
      extraHeaders.push(
        `Session-Expires: ${this._sessionTimers.currentExpires};refresher=${this._sessionTimers.refresher ? "uac" : "uas"
        }`
      );
    }

    this._connectionPromiseQueue = this._connectionPromiseQueue
      .then(() => this._createLocalDescription("offer", rtcOfferConstraints))
      .then((sdp) => {
        sdp = this._mangleOffer(sdp);

        const e = { originator: "local", type: "offer", sdp };

        logger.debug('emit "sdp"');
        this.emit("sdp", e);

        this.sendRequest(JsSIP_C.INVITE, {
          extraHeaders,
          body: sdp,
          eventHandlers: {
            onSuccessResponse: (response) => {
              onSucceeded.call(this, response);
              succeeded = true;
            },
            onErrorResponse: (response) => {
              console.log("HOLD send request error issue");
              onFailed.call(this, response);
            },
            onTransportError: () => {
              this.onTransportError(); // Do nothing because session ends.
            },
            onRequestTimeout: () => {
              this.onRequestTimeout(); // Do nothing because session ends.
            },
            onDialogError: () => {
              this.onDialogError(); // Do nothing because session ends.
            },
          },
        });
      })
      .catch(() => {
        console.log("HOLD send request issue");
        onFailed();
      });

    function onSucceeded(response) {
      if (this._status === C.STATUS_TERMINATED) {
        return;
      }

      this.sendRequest(JsSIP_C.ACK);
      console.log("HOLD sent ack");

      // If it is a 2XX retransmission exit now.
      if (succeeded) {
        console.log("HOLD response succeeded");
        return;
      }

      // Handle Session Timers.
      this._handleSessionTimersInIncomingResponse(response);

      // Must have SDP answer.
      if (!response.body) {
        onFailed.call(this);
        console.log("HOLD response body not found issue");
        return;
      } else if (
        !response.hasHeader("Content-Type") ||
        response.getHeader("Content-Type").toLowerCase() !== "application/sdp"
      ) {
        onFailed.call(this);
        console.log("HOLD content-type issue");
        return;
      }

      /*let modifiedSdp = sdp_transform.parse(response.body);
      console.log('modifiedSdp....', modifiedSdp);
      for (let m of modifiedSdp.media) {
        if (m.setup != "active") {
          m.setup = "passive"
        }
      }
     let finalModifiedSdp =  sdp_transform.write(modifiedSdp);
     console.log('modifiedSdp final....', finalModifiedSdp);*/

      const e = { originator: "remote", type: "answer", sdp: response.body };

      logger.debug('emit "sdp"');
      this.emit("sdp", e);

      const answer = new RTCSessionDescription({ type: "answer", sdp: e.sdp });

      this._connectionPromiseQueue = this._connectionPromiseQueue
        .then(() => this._connection.setRemoteDescription(answer))
        .then(() => {
          if (eventHandlers.succeeded) {
            eventHandlers.succeeded(response);
          }
        })
        .catch((error) => {
          console.log("HOLD answer error");
          onFailed.call(this);

          logger.warn(
            'emit "peerconnection:setremotedescriptionfailed" [error:%o]',
            error
          );

          this.emit("peerconnection:setremotedescriptionfailed", error);
        });
    }

    function onFailed(response) {
      if (eventHandlers.failed) {
        console.log("HOLD onFailed error");
        eventHandlers.failed(response);
      }
    }
  }

  /**
   * Send Re-INVITE
   */
  _sendReinviteOnDemand(options = {}, bodyRecv) {
    logger.debug("_sendReinviteOnDemand()");

    let extraHeaders = Utils.cloneArray(options.extraHeaders);
    let eventHandlers = Utils.cloneObject(options.eventHandlers);
    let contentType = options.contentType;
    let isPreEstCall = options.isPreEstCall;

    const rtcOfferConstraints =
      options.rtcOfferConstraints || this._rtcOfferConstraints || null;
    const disableSessionExpireHeader = options.disableSessionExpireHeader
      ? true
      : false;
    let succeeded = false;
    extraHeaders.push(`Contact: ${this._contact}`);
    if (contentType) {
      extraHeaders.push("Content-Type: " + contentType);
    } else {
      extraHeaders.push("Content-Type: application/sdp");
    }

    // Session Timers.
    if (this._sessionTimers.running && !disableSessionExpireHeader) {
      extraHeaders.push(
        `Session-Expires: ${this._sessionTimers.currentExpires};refresher=${this._sessionTimers.refresher ? "uac" : "uas"
        }`
      );
    }

    this._connectionPromiseQueue = this._connectionPromiseQueue
      .then(() => this._createLocalDescription("offer", rtcOfferConstraints))
      .then((sdp) => {
        sdp = this._mangleOffer(sdp);
        console.log("_sendReinviteOnDemand...... 2");
        //const e = { originator: "local", type: "offer", sdp };
        //logger.debug('emit "sdp"');
        //this.emit("sdp", e);

        let req = {
          request: {
            ...options,
            body: sdp,
          },
        };
        let body = sdp;
        if (isPreEstCall) {
          console.log("_sendReinviteOnDemand...... 3 ");
          body = bodyRecv;
        } else {
          console.log("_sendReinviteOnDemand...... 4");
          this.emit("sending_reinvite", req);
          extraHeaders = Utils.cloneArray(req.request.extraHeaders);
          eventHandlers = Utils.cloneObject(req.request.eventHandlers);
          body = req.request.body;
        }

        // if (this._sessionTimers.running && !disableSessionExpireHeader) {
        //   extraHeaders.push(
        //     `Session-Expires: ${this._sessionTimers.currentExpires};refresher=${
        //       this._sessionTimers.refresher ? "uac" : "uas"
        //     }`
        //   );
        // }

        this.sendRequest(JsSIP_C.INVITE, {
          extraHeaders,
          body: body,
          eventHandlers: {
            onSuccessResponse: (response) => {
              onSucceeded.call(this, response);
              succeeded = true;
            },
            onErrorResponse: (response) => {
              console.log("Reinvite send request error issue");
              onFailed.call(this, response);
            },
            onTransportError: () => {
              this.onTransportError(); // Do nothing because session ends.
            },
            onRequestTimeout: () => {
              this.onRequestTimeout(); // Do nothing because session ends.
            },
            onDialogError: () => {
              this.onDialogError(); // Do nothing because session ends.
            },
          },
        });
      })
      .catch(() => {
        console.log("HOLD send request issue");
        onFailed();
      });

    function onSucceeded(response) {
      if (this._status === C.STATUS_TERMINATED) {
        return;
      }

      this.sendRequest(JsSIP_C.ACK);
      console.log("_sendReinviteOnDemand ack");

      // If it is a 2XX retransmission exit now.
      if (succeeded) {
        console.log("HOLD response succeeded");
        return;
      }

      // Handle Session Timers.
      this._handleSessionTimersInIncomingResponse(response);

      // Must have SDP answer.
      /*if (!response.body) {
        onFailed.call(this);
        console.log("HOLD response body not found issue");
        return;
      } else if (
        !response.hasHeader("Content-Type") ||
        response.getHeader("Content-Type").toLowerCase() !== "application/sdp"
      ) {
        onFailed.call(this);
        console.log("HOLD content-type issue");
        return;
      }
      const e = { originator: "remote", type: "answer", sdp: response.body };

      logger.debug('emit "sdp"');
      this.emit("sdp", e);

      const answer = new RTCSessionDescription({ type: "answer", sdp: e.sdp });

      this._connectionPromiseQueue = this._connectionPromiseQueue
        .then(() => this._connection.setRemoteDescription(answer))
        .then(() => {
          if (eventHandlers.succeeded) {
            eventHandlers.succeeded(response);
          }
        })
        .catch((error) => {
          console.log("HOLD answer error");
          onFailed.call(this);

          logger.warn(
            'emit "peerconnection:setremotedescriptionfailed" [error:%o]',
            error
          );

          this.emit("peerconnection:setremotedescriptionfailed", error);
        });*/
    }

    function onFailed(response) {
      if (eventHandlers.failed) {
        console.log("HOLD onFailed error");
        eventHandlers.failed(response);
      }
    }
  }

  /**
   * Send UPDATE
   */
  _sendUpdate(options = {}) {
    logger.debug("sendUpdate()");

    const extraHeaders = Utils.cloneArray(options.extraHeaders);
    const eventHandlers = Utils.cloneObject(options.eventHandlers);
    const rtcOfferConstraints =
      options.rtcOfferConstraints || this._rtcOfferConstraints || null;
    const sdpOffer = options.sdpOffer || false;
    const disableSessionExpireHeader = options.disableSessionExpireHeader
      ? true
      : false;
    let succeeded = false;

    extraHeaders.push(`Contact: ${this._contact}`);
    console.log(
      "sendcallupdate disableSessionExpireHeader............3",
      disableSessionExpireHeader
    );
    // Session Timers.
    if (this._sessionTimers.running && !disableSessionExpireHeader) {
      extraHeaders.push(
        `Session-Expires: ${this._sessionTimers.currentExpires};refresher=${this._sessionTimers.refresher ? "uac" : "uas"
        }`
      );
    }

    if (sdpOffer) {
      console.log("sendcallupdate _sendUpdate sdpoffer");
      extraHeaders.push("Content-Type: application/sdp");

      this._connectionPromiseQueue = this._connectionPromiseQueue
        .then(() => this._createLocalDescription("offer", rtcOfferConstraints))
        .then((sdp) => {
          sdp = this._mangleOffer(sdp);

          const e = { originator: "local", type: "offer", sdp };

          logger.debug('emit "sdp"');
          this.emit("sdp", e);

          this.sendRequest(JsSIP_C.UPDATE, {
            extraHeaders,
            body: sdp,
            eventHandlers: {
              onSuccessResponse: (response) => {
                onSucceeded.call(this, response);
                succeeded = true;
              },
              onErrorResponse: (response) => {
                onFailed.call(this, response);
              },
              onTransportError: () => {
                this.onTransportError(); // Do nothing because session ends.
              },
              onRequestTimeout: () => {
                this.onRequestTimeout(); // Do nothing because session ends.
              },
              onDialogError: () => {
                this.onDialogError(); // Do nothing because session ends.
              },
            },
          });
        })
        .catch(() => {
          onFailed.call(this);
        });
    }
    // No SDP.
    else {
      console.log("sendcallupdate no sdp offer request");
      this.sendRequest(JsSIP_C.UPDATE, {
        extraHeaders,
        eventHandlers: {
          onSuccessResponse: (response) => {
            console.log("sendcallupdate succeeded response came");
            onSucceeded.call(this, response);
          },
          onErrorResponse: (response) => {
            console.log("sendcallupdate succeeded response error");
            onFailed.call(this, response);
          },
          onTransportError: () => {
            this.onTransportError(); // Do nothing because session ends.
          },
          onRequestTimeout: () => {
            this.onRequestTimeout(); // Do nothing because session ends.
          },
          onDialogError: () => {
            this.onDialogError(); // Do nothing because session ends.
          },
        },
      });
    }

    function onSucceeded(response) {
      if (this._status === C.STATUS_TERMINATED) {
        return;
      }

      // If it is a 2XX retransmission exit now.
      if (succeeded) {
        console.log("sendcallupdate succeeded block");
        return;
      }

      // Handle Session Timers.
      this._handleSessionTimersInIncomingResponse(response);

      // Must have SDP answer.
      if (sdpOffer) {
        console.log("sendcallupdate succeeded with sdpoffer true");
        if (!response.body) {
          console.log("sendcallupdate not response body");
          onFailed.call(this);

          return;
        } else if (
          !response.hasHeader("Content-Type") ||
          response.getHeader("Content-Type").toLowerCase() !== "application/sdp"
        ) {
          console.log("sendcallUpdate content type error issue");
          onFailed.call(this);

          return;
        }

        const e = { originator: "remote", type: "answer", sdp: response.body };

        logger.debug('emit "sdp"');
        this.emit("sdp", e);

        const answer = new RTCSessionDescription({
          type: "answer",
          sdp: e.sdp,
        });

        this._connectionPromiseQueue = this._connectionPromiseQueue
          .then(() => this._connection.setRemoteDescription(answer))
          .then(() => {
            if (eventHandlers.succeeded) {
              eventHandlers.succeeded(response);
            }
          })
          .catch((error) => {
            onFailed.call(this);

            logger.warn(
              'emit "peerconnection:setremotedescriptionfailed" [error:%o]',
              error
            );

            this.emit("peerconnection:setremotedescriptionfailed", error);
          });
      }
      // No SDP answer.
      else if (eventHandlers.succeeded) {
        console.log("sendcallupdate eventHandlers succeeded");
        eventHandlers.succeeded(response);
      }
    }

    function onFailed(response) {
      if (eventHandlers.failed) {
        eventHandlers.failed(response);
      }
    }
  }

  _acceptAndTerminate(response, status_code, reason_phrase) {
    logger.debug("acceptAndTerminate()");

    const extraHeaders = [];

    if (status_code) {
      reason_phrase = reason_phrase || JsSIP_C.REASON_PHRASE[status_code] || "";
      extraHeaders.push(
        `Reason: SIP ;cause=${status_code}; text="${reason_phrase}"`
      );
    }

    // An error on dialog creation will fire 'failed' event.
    if (this._dialog || this._createDialog(response, "UAC")) {
      console.log('terminating bye for dialog is found');
      this.sendRequest(JsSIP_C.ACK);
      this.sendRequest(JsSIP_C.BYE, {
        extraHeaders,
      });
    } else {
      console.log('terminating bye for dialog is not found');
      this.sendRequest(JsSIP_C.BYE, {
        extraHeaders,
      });
    }

    // Update session status.
    this._status = C.STATUS_TERMINATED;
  }

  /**
   * Correctly set the SDP direction attributes if the call is on local hold
   */
  _mangleOffer(sdp) {
    if (!this._localHold && !this._remoteHold) {
      return sdp;
    }

    sdp = sdp_transform.parse(sdp);

    // Local hold.
    if (this._localHold && !this._remoteHold) {
      logger.debug("mangleOffer() | me on hold, mangling offer");
      for (const m of sdp.media) {
        if (holdMediaTypes.indexOf(m.type) === -1) {
          continue;
        }
        if (!m.direction) {
          m.direction = "sendonly";
        } else if (m.direction === "sendrecv") {
          m.direction = "sendonly";
        } else if (m.direction === "recvonly") {
          m.direction = "inactive";
        }
      }
    }
    // Local and remote hold.
    else if (this._localHold && this._remoteHold) {
      logger.debug("mangleOffer() | both on hold, mangling offer");
      for (const m of sdp.media) {
        if (holdMediaTypes.indexOf(m.type) === -1) {
          continue;
        }
        m.direction = "inactive";
      }
    }
    // Remote hold.
    else if (this._remoteHold) {
      logger.debug("mangleOffer() | remote on hold, mangling offer");
      for (const m of sdp.media) {
        if (holdMediaTypes.indexOf(m.type) === -1) {
          continue;
        }
        if (!m.direction) {
          m.direction = "recvonly";
        } else if (m.direction === "sendrecv") {
          m.direction = "recvonly";
        } else if (m.direction === "recvonly") {
          m.direction = "inactive";
        }
      }
    }

    return sdp_transform.write(sdp);
  }

  _setLocalMediaStatus() {
    let enableAudio = true,
      enableVideo = true;

    if (this._localHold || this._remoteHold) {
      enableAudio = false;
      enableVideo = false;
    }

    if (this._audioMuted) {
      enableAudio = false;
    }

    if (this._videoMuted) {
      enableVideo = false;
    }

    this._toggleMuteAudio(!enableAudio);
    this._toggleMuteVideo(!enableVideo);
  }

  /**
   * Handle SessionTimers for an incoming INVITE or UPDATE.
   * @param  {IncomingRequest} request
   * @param  {Array} responseExtraHeaders  Extra headers for the 200 response.
   */
  _handleSessionTimersInIncomingRequest(
    request,
    responseExtraHeaders,
    options = {}
  ) {
    console.log("sendcallupdate _handleSessionTimersInIncomingRequest method");
    if (!this._sessionTimers.enabled) {
      return;
    }
    const disableSessionExpireHeader = options.disableSessionExpireHeader
      ? true
      : false;
    let session_expires_refresher;

    if (
      request.session_expires &&
      request.session_expires >= JsSIP_C.MIN_SESSION_EXPIRES
    ) {
      this._sessionTimers.currentExpires = request.session_expires;
      session_expires_refresher = request.session_expires_refresher || "uas";
    } else {
      this._sessionTimers.currentExpires = this._sessionTimers.defaultExpires;
      session_expires_refresher = "uas";
    }
    console.log(
      "disableSessionExpireHeader............4",
      disableSessionExpireHeader
    );
    if (!disableSessionExpireHeader) {
      responseExtraHeaders.push(
        `Session-Expires: ${this._sessionTimers.currentExpires};refresher=${session_expires_refresher}`
      );
    }
    if (!disableSessionExpireHeader) {
      this._sessionTimers.refresher = session_expires_refresher === "uas";
      this._runSessionTimer();
    }
  }

  /**
   * Handle SessionTimers for an incoming response to INVITE or UPDATE.
   * @param  {IncomingResponse} response
   */
  _handleSessionTimersInIncomingResponse(response) {
    console.log("sendcallupdate _handleSessionTimersInIncomingResponse method");
    if (!this._sessionTimers.enabled) {
      console.log("sendcallupdate _sessionTimers not enabled");
      return;
    }

    let session_expires_refresher;

    if (
      response.session_expires &&
      response.session_expires >= JsSIP_C.MIN_SESSION_EXPIRES
    ) {
      this._sessionTimers.currentExpires = response.session_expires;
      session_expires_refresher = response.session_expires_refresher || "uac";
    } else {
      this._sessionTimers.currentExpires = this._sessionTimers.defaultExpires;
      session_expires_refresher = "uac";
    }

    this._sessionTimers.refresher = session_expires_refresher === "uac";
    console.log(
      "sendcallupdate call update refresher",
      this._sessionTimers.refresher,
      session_expires_refresher
    );
    this._runSessionTimer();
  }

  _runSessionTimer() {
    console.log("sendcallupdate _runSessionTimer method");
    const expires = this._sessionTimers.currentExpires;

    this._sessionTimers.running = true;

    clearTimeout(this._sessionTimers.timer);

    // I'm the refresher.
    if (this._sessionTimers.refresher) {
      this._sessionTimers.timer = setTimeout(() => {
        console.log("sendcallupdate session update timer");
        if (this._status === C.STATUS_TERMINATED) {
          return;
        }

        if (!this._isReadyToReOffer()) {
          return;
        }

        logger.debug("runSessionTimer() | sending session refresh request");

        if (this._sessionTimers.refreshMethod === JsSIP_C.UPDATE) {
          this._sendUpdate();
        } else {
          this._sendReinvite();
        }
      }, expires * 500); // Half the given interval (as the RFC states).
    }

    // I'm not the refresher.
    else {
      this._sessionTimers.timer = setTimeout(() => {
        console.log("sendcallupdate session update timer timeout");
        if (this._status === C.STATUS_TERMINATED) {
          return;
        }

        logger.warn(
          "runSessionTimer() | timer expired, terminating the session"
        );

        this.terminate({
          cause: JsSIP_C.causes.REQUEST_TIMEOUT,
          status_code: 408,
          reason_phrase: "Session Timer Expired",
        });
      }, expires * 1100);
    }
  }

  _toggleMuteAudio(mute) {
    if (this.isDeviceTypeHandsetOrAndroid()) {
      if (typeof this._connection.getLocalStreams === "function") {
        this._connection.getLocalStreams().forEach((stream) => {
          stream.getAudioTracks().forEach((t) => {
            // t.muted = !mute;
            t.enabled = !mute;
          });
        });
        return;
      }
    }
    const userAgent = this._ua.configuration.user_agent || JsSIP_C.USER_AGENT;
    if (userAgent && (userAgent.toLowerCase().includes("trcp") || userAgent.toLowerCase().includes("rcp"))) {
      console.log("Mute/Unmute audio is closed for RCP and TRCP");
      return;
    }
    const senders = this._connection.getSenders().filter((sender) => {
      return sender.track && sender.track.kind === "audio";
    });
    for (const sender of senders) {
      sender.track.enabled = !mute;
    }
  }

  _toggleMuteVideo(mute) {
    if (this.isDeviceTypeHandsetOrAndroid()) {
      if (typeof this._connection.getLocalStreams === "function") {
        this._connection.getLocalStreams().forEach((stream) => {
          console.log("_toggleMuteVideo.......", stream);
          stream.getVideoTracks().forEach((t) => {
            t.enabled = !mute;
          });
        });
        return;
      }
    }
    const userAgent = this._ua.configuration.user_agent || JsSIP_C.USER_AGENT;
    if (userAgent && (userAgent.toLowerCase().includes("trcp") || userAgent.toLowerCase().includes("rcp"))) {
      console.log("Mute/Unmute video is closed for RCP and TRCP");
      return;
    }
    const senders = this._connection.getSenders().filter((sender) => {
      return sender.track && sender.track.kind === "video";
    });
    for (const sender of senders) {
      sender.track.enabled = !mute;
    }
  }

  _newRTCSession(originator, request) {
    logger.debug("newRTCSession()");

    this._ua.newRTCSession(this, {
      originator,
      session: this,
      request,
    });
  }

  _connecting(request) {
    logger.debug("session connecting");

    logger.debug('emit "connecting"');

    this.emit("connecting", {
      request,
    });
  }

  _progress(originator, response) {
    logger.debug("session progress");

    logger.debug('emit "progress"');

    this.emit("progress", {
      originator,
      response: response || null,
    });
  }

  _accepted(originator, message) {
    logger.debug("session accepted");

    this._start_time = new Date();

    logger.debug('emit "accepted"');

    this.emit("accepted", {
      originator,
      response: message || null,
    });
  }

  _confirmed(originator, ack) {
    logger.debug("session confirmed");

    this._is_confirmed = true;

    logger.debug('emit "confirmed"');

    this.emit("confirmed", {
      originator,
      ack: ack || null,
    });
  }

  _ended(originator, message, cause) {
    logger.debug("session ended");

    this._end_time = new Date();

    this._close();

    logger.debug('emit "ended"');

    this.emit("ended", {
      originator,
      message: message || null,
      cause,
    });
  }

  _failed(originator, message, cause) {
    logger.debug("session failed", this._progressStatus);
    this._progressStatus = P.FINISHED;
    // Emit private '_failed' event first.
    logger.debug('emit "_failed"');

    this.emit("_failed", {
      originator,
      message: message || null,
      cause,
    });

    this._close();

    logger.debug('emit "failed"');

    this.emit("failed", {
      originator,
      message: message || null,
      cause,
    });
  }

  _onhold(originator) {
    logger.debug("session onhold");

    this._setLocalMediaStatus();

    logger.debug('emit "hold"');

    this.emit("hold", {
      originator,
    });
  }

  _onunhold(originator) {
    logger.debug("session onunhold");

    this._setLocalMediaStatus();

    logger.debug('emit "unhold"');

    this.emit("unhold", {
      originator,
    });
  }

  _onmute({ audio, video }) {
    logger.debug("session onmute");

    this._setLocalMediaStatus();

    logger.debug('emit "muted"');

    this.emit("muted", {
      audio,
      video,
    });
  }

  _onunmute({ audio, video }) {
    logger.debug("session onunmute");

    this._setLocalMediaStatus();

    logger.debug('emit "unmuted"');

    this.emit("unmuted", {
      audio,
      video,
    });
  }

  _updateBitRateForPeerConnection() {
    const maxBitrateInBitsPerSecond = 30000;
    const senders = this._connection.getSenders();

    senders.forEach((sender) => {
      if (sender.track.kind === 'video') {
        // Change bitrate for video track here
        const parameters = sender.getParameters()
        if (!parameters.encodings) {
          parameters.encodings = [{}]
        }
        parameters.encodings[0].maxBitrate = maxBitrateInBitsPerSecond
        //parameters.encodings[0].scaleResolutionDownBy = 2;
        sender.setParameters(parameters).then(() => {
          console.log('Bitrate changed successfuly');
        }).catch(e => console.log('Bitrate changed error', e));
      }
      if (sender.track.kind === 'audio') {
        // Change bitrate for audio track here
      }
    });
  }

  _updateDirTransForPeerConnection(dir) {
    logger.debug("_updateDirTransForPeerConnection()");
    const userAgent = this._ua.configuration.user_agent || JsSIP_C.USER_AGENT;
    if (userAgent && (userAgent.toLowerCase().includes("trcp") || userAgent.toLowerCase().includes("rcp"))) {
      /*if (this._connection && typeof this._connection.getTransceivers === 'function') {
        this._connection.getTransceivers().forEach((transceiver) => {
          transceiver.direction = dir;
          console.log('set transceiver direction', dir);
        });
        return true;
      } else {
        console.log('connection is not created yet!!');
        return false;
      }*/
      return true;
    } else {
      return false;
    }
  }

  _replaceTracksWhenInputDeviceChanges(track = null) {
    console.log("_replaceTracksWhenInputDeviceChanges()");
    if (track) {
      try {
        let sender = this._connection
          .getSenders()
          .find((s) => s.track.kind === track.kind);
        if (sender) {
          track.enabled = true;
          sender.replaceTrack(track);
          sender.track.enabled = true;
          console.log("Found sender: replace track found", track);
        } else {
          console.log("not Found sender");
        }
      } catch (error) {
        console.log("Found sender: replace track error", error);
      }
      return;
    }
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const [audioTrack] = stream.getAudioTracks();
        let sender = this._connection
          .getSenders()
          .find((s) => s.track.kind === audioTrack.kind);
        if (sender) {
          sender.replaceTrack(audioTrack);
          console.log("Found sender: replace track");
        }

        /*const senders = this._connection.getSenders();
        if (senders) {
          senders.forEach((sender) => this._connection.removeTrack(sender)); 
        }
        if (this._localMediaStream) {
          let tracks = this._localMediaStream.getAudioTracks();
          for (const track of tracks) {
            this._localMediaStream.removeTrack(track);
          }
          this._localMediaStream = stream;
          let updatedTracks = stream.getAudioTracks();
          for (const uptrack of updatedTracks) {
            this._connection.addTrack(uptrack);
          }
          console.log("Found sender: updated track");
        }*/

      })
      .catch((err) => {
        console.error(`Error happened usermedia: ${err}`);
      });
  }


};
