(function (exports) {
  // const dgram = require("react-native-udp");
  const PttConfig = require("./pttConfigflat");
  const FLOOR_REQUEST_MESSAGE = "FloorRequestMessage";
  const MCPC_MESSAGE = "MCPCAcknowledge";
  const FLOOR_RELEASE_MESSAGE = "FloorReleaseMessage";

  class PttMessage {
    /* 
            key: callId
            value: {callId: callId, port: port, socket:socket, remoteIP: ip, remotePort: remotePort, callback:cb}
        */
    constructor() {
      this.callIdMap = {};
      this.freePorts = [];
      this.dgram = null; //
    }
    setPttUdpDgram(dgram) {
      this.dgram = dgram;
    }
    /* portStart and portEnd included
     */
    init(portStart, portEnd) {
      for (let i = portStart; i <= portEnd; i++) {
        this.freePorts.push(i);
      }
    }

    /* returns a port 
           onMsgCallBack(err, msgType, msg) */
    allocate(callId, remotePort, remoteIp, onMsgCallBack) {
      const _this = this;
      console.log("allocate port", remoteIp, remotePort);
      if (this.freePorts.length <= 0) return -1;

      if (this.callIdMap.hasOwnProperty(callId)) {
        return this.callIdMap[callId].port;
      }
      let port = this.freePorts.pop();
      let conf = {
        type: "udp4",
        debug: true,
      };
      // let conf = "udp4"
      const socket = this.dgram.createSocket(conf);
      socket.on("connect", (connect) => {
        console.log("allocate..... connect......", connect);
      });
      socket.on("error", (err) => {
        console.log("allocate...... error......", err);
        socket.close();
        _this.freePorts.push(port);
        delete _this.callIdMap[callId];
        onMsgCallBack(err, null, null);
      });

      socket.on("close", (err) => {
        _this.freePorts.push(port);
        delete _this.callIdMap[callId];
        onMsgCallBack(err, null, null);
      });

      socket.on("message", (msg, rinfo) => {
        let callIdEntry = _this.callIdMap[callId];
        if (!callIdEntry) {
          onMsgCallBack("Got a message with no entry in callIdMap", null, null);
          return;
        }
        // MCX client init error  [TypeError: null is not an object (evaluating 'PttConfig.config')]
        console.log("local port dgram", msg, rinfo);
        let decodeResponse = PttConfig.decode(PttConfig.config, msg);
        console.log("decoded response", decodeResponse);
        onMsgCallBack(
          null,
          decodeResponse.messageType,
          decodeResponse.decodedJson
        );
      });

      socket.on("listening", (data) => {
        console.log("allocate...... listening......", data);
        //const address = localPortSocket.address();
        //console.log(`server incoming port listening ${address.address}:${address.port}, ${callId}`);
      });

      var mapEntry = {
        callId: callId,
        port: port,
        socket: socket,
        callback: onMsgCallBack,
        remotePort: remotePort,
        remoteIp: remoteIp,
      };
      this.callIdMap[callId] = mapEntry;
      socket.bind({ port: port, exclusive: true });
      if (remotePort && remoteIp) {
        _this.setRemote(callId, remotePort, remoteIp);
      }
      return port;
    }

    getPort(callId) {
      try {
        if (!this.callIdMap.hasOwnProperty(callId)) {
          return false;
        }
        let mapEntry = this.callIdMap[callId];
        // mapEntry.socket.bind(remotePort, remoteIp);
        return mapEntry.port;
      } catch (err) {
        console.log("getPort err", err);
        // return -1;
      }
    }

    /* returns boolean */
    setRemote(callId, remotePort, remoteIp) {
      if (!this.callIdMap.hasOwnProperty(callId)) {
        return false;
      }
      let mapEntry = this.callIdMap[callId];
      // mapEntry.socket.bind(remotePort, remoteIp);
      mapEntry.remotePort = remotePort;
      mapEntry.remoteIp = remoteIp;
      return true;
    }
    enodeDecode() {
      console.log("encode decode called ");
      // PttConfig = new PttConfig(PttConfig);
      console.log("encode decode called 2", PttConfig);
      let buffer = PttConfig.encode(PttConfig.config, "FloorReleaseMessage", {
        header: {
          ssrc: 223239,
        },
        userId: {
          value: "60001@mcptt.org.is.that.this",
        },
        floorIndicator: {
          value: {
            systemCall: 1,
            emergencyCall: 1,
          },
        },
      });
      console.log("buffer received", buffer);

      let converted = PttConfig.decode(PttConfig.config, buffer);
      console.log("converted", JSON.stringify(converted));
    }

    sendMessage(callId, type, mcpttId) {
      /* encode the message */
      if (!this.callIdMap.hasOwnProperty(callId)) {
        return false;
      }
      let mapEntry = this.callIdMap[callId];
      console.log(
        "sendMessage.......2",
        type,
        mcpttId,
        mapEntry.remotePort,
        mapEntry.remoteIp
      );
      let floorMessage = this.getFloorMessage(type, mcpttId);
      console.log(
        "sendMessage.......3",
        type,
        floorMessage,
        mapEntry.remotePort,
        mapEntry.remoteIp
      );
      // 'sendMessage.......3', 'MCPCAcknowledge', { header: { ssrc: 223239 }, reasonCode: { value: 0 } }, 40203, '192.168.1.187'
      let msgBuf = PttConfig.encode(PttConfig.config, type, floorMessage);
      mapEntry.socket.send(
        msgBuf,
        undefined,
        undefined,
        mapEntry.remotePort,
        mapEntry.remoteIp
      );
      return true;
    }

    getFloorMessage(floorMessageType, mcpttId) {
      try {
        let obj = {};
        if (floorMessageType === FLOOR_RELEASE_MESSAGE) {
          obj = {
            header: {
              ssrc: 223239,
            },
            userId: {
              value: mcpttId,
            },
            floorIndicator: {
              value: {
                systemCall: 1,
              },
            },
          };
        } else if (floorMessageType === FLOOR_REQUEST_MESSAGE) {
          obj = {
            header: {
              //   ssrc: floorMessageObj.ssrc,
              ssrc: 223239,
            },
            userId: {
              value: mcpttId,
            },
            floorIndicator: {
              value: {
                systemCall: 1,
              },
            },
          };
        } else if (floorMessageType === MCPC_MESSAGE) {
          obj = {
            header: {
              //   ssrc: floorMessageObj.ssrc,
              ssrc: 223239,
            },
            reasonCode: {
              value: 0, //accept 0 , 2 not accept, 1 busy
            },
          };
        }

        console.log(
          "sending floor message from client",
          floorMessageType,
          FLOOR_REQUEST_MESSAGE,
          FLOOR_RELEASE_MESSAGE,
          JSON.stringify(obj)
        );
        return obj;
      } catch (e) {
        console.log("sendFloorMessage err", e);
      }
    }

    free(callId) {
      if (!this.callIdMap.hasOwnProperty(callId)) {
        return false;
      }
      let mapEntry = this.callIdMap[callId];
      mapEntry.socket.close();
      this.freePorts.push(mapEntry.port);
      delete this.callIdMap[callId];
      return true;
    }
    getMessage(messageType, message, callObj) {
      console.log("getMessage......112", messageType, message, callObj);
      let pttCallAction = {
        communicationType: "CALL_ACTION",
        callActionType: "PUSH_TO_TALK_STATUS",
        callId: callObj.callId,
        fromId: callObj.fromId,
        toId: callObj.toId,
        callType: callObj.callType,
        isVideo: callObj.isVideo,
      };
      let talkingPartyId = "0";

      // preEstSessionUri,
      // callActionType

      // action.callId
      // try {
      //   obj.preEstSessionUri = xml.getElementsByTagName("session-uri")
      //     ? xml.getElementsByTagName("session-uri").item(0).textContent
      //     : "";
      //   obj.preEstSessionUri = obj.preEstSessionUri.replace("sip:", "");
      // } catch (error) {
      //   console.log("preEstSessionUri not found", error);
      // }

      // //fromId = fromId.includes('sip') ? fromId.replace('sip') : fromId
      // let talkingPartyId = xml
      //   .getElementsByTagName("talking-party")
      //   .item(0).textContent;

      if (messageType.toLowerCase() === "MCPCConnectMessage".toLowerCase()) {
        pttCallAction.callActionType = "MCPC_CONNECT";
        pttCallAction.preEstSessionUri = this.getIdWithoutSip(
          message.sessionId && message.sessionId.sessionIdentity
            ? message.sessionId.sessionIdentity
            : ""
        );
        pttCallAction.groupId = this.getIdWithoutSip(
          message.groupId && message.groupId.groupIdentity
            ? message.groupId.groupIdentity
            : ""
        );
        pttCallAction.fromId = this.getIdWithoutSip(
          message.invitingUser && message.invitingUser.invitingMCPTTUserIdentity
            ? message.invitingUser.invitingMCPTTUserIdentity
            : ""
        );
        // pttCallAction.toId = message.invitingUser.invitingMCPTTUserIdentity;
      } else if (
        messageType.toLowerCase() === "MCPCDisconnectMessage".toLowerCase()
      ) {
        pttCallAction.callActionType = "MCPC_DISCONNECT";
        pttCallAction.preEstSessionUri = this.getIdWithoutSip(
          message.sessionId.sessionIdentity
        );
      } else if (
        messageType.toLowerCase() === "FloorIdleMessage".toLowerCase()
      ) {
        talkingPartyId = "0";
      } else {
        //FloorGrantedMessage //FloorTakenMessage
        talkingPartyId =
          message.grantedPartyId && message.grantedPartyId.value
            ? message.grantedPartyId.value
            : "";
      }

      if (!pttCallAction.callType) {
        pttCallAction.callType = this.getCallType(message);
      }

      talkingPartyId =
        talkingPartyId && talkingPartyId.includes("sip:")
          ? talkingPartyId.replace("sip:", "")
          : talkingPartyId;

      pttCallAction.talkingPartyId = talkingPartyId;

      //
      // let pttAction = {
      //   "fromId": this.callLegObjects[callLegId].mcpttId,
      //   "toId": this.callLegObjects[callLegId].sipUri,
      //   "talkingPartyId": this.talkingParty ? this.talkingParty : "0",
      //   "callId": callLegId,
      //   "callType": this.callType,
      //   "communicationType": "CALL_ACTION",
      //   "callActionType": "PUSH_TO_TALK_STATUS"
      // }
      return pttCallAction;
    }
    getIdWithoutSip(id) {
      if (id && id.includes("sip:")) {
        return id.replace("sip:", "");
      }
      // if (id && id.includes("\u0003")) {
      //   return id.replace("\u0003", "");
      // }
      return id;
    }
    getCallType(message) {
      // sessionId: { id: 116, length: 2, sessionType: 1//Pvt, 2//PreArrange, sessionIdentity: 0 },
      // groupId: { id: 116, length: 2, groupIdentity: 0 },
      // mediaStream: { id: 116, length: 2, value: 1, controlChannel: 1 },//ptt
      // warningText: { id: 116, length: 2, warningText: 0 },
      // answerState: { id: 116, length: 2, value: 0 }//Manual },
      let isCallTypeManual = false;
      let isCallTypePTT = false;
      let isCallTypePvt = false;
      try {
        isCallTypeManual = message.answerState.value == 0; //0
      } catch (err) {}
      try {
        isCallTypePTT = message.mediaStream.controlChannel == 1; //1
      } catch (err) {}
      try {
        isCallTypePvt = message.sessionId.sessionType == 1; //1
      } catch (err) {}
      //   sessionId:
      //   { id: 119,
      //     length: 29,
      //     sessionType: 1,
      //     sessionIdentity: 'sip:1687419938245@consort.org' },
      //  groupId: { id: 126, length: 0, groupIdentity: '' },
      //  mediaStream: { id: 122, length: 2, value: 1, controlChannel: 1 },
      //  warningText: { id: 123, length: 0, warningText: '' },
      //  answerState: { id: 132, length: 2, value: 0 },
      let callType = "";
      //   { id: 119,
      //     length: 29,
      //     sessionType: 1,
      //     sessionIdentity: 'sip:1687263381978@consort.org' },
      //  groupId: { id: 126, length: 0, groupIdentity: '' },
      //  mediaStream: { id: 122, length: 2, value: 1, controlChannel: 0 },
      //  warningText: { id: 123, length: 0, warningText: '' },
      //  answerState: { id: 132, length: 2, value: 0 },
      if (isCallTypeManual && isCallTypePTT) {
        //halfduplex
        callType = "SIMPLEX_INDIVIDUAL_HOOK_CALL";
      } else if (isCallTypeManual && !isCallTypePTT) {
        //Duplex
        callType = "DUPLEX_INDIVIDUAL_CALL";
      } else if (isCallTypePTT && isCallTypePvt) {
        //Direct call
        callType = "SIMPLEX_INDIVIDUAL_DIRECT_CALL";
      } else if (isCallTypePTT && !isCallTypePvt) {
        //group call
        callType = "SIMPLEX_GROUP_CALL";
      } else if (isCallTypePTT) {
        callType = "SIMPLEX_BROADCAST_GROUP_CALL";
      }
      return callType;
    }
  }
  exports.PttMessage = PttMessage;
})(typeof exports === "undefined" ? (this["PttMessage"] = {}) : exports);
