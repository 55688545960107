// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signal {
  display: inline-block;
  height: 13px;
  width: 13px;
  border: 1px solid #DDD;
  border-radius: 50%;
  background: #EEE;
  margin-right: 7px;
}
.signal.green {
  background: #56af22;
}
.signal.red {
  background: red;
}`, "",{"version":3,"sources":["webpack://./src/styles/signal.scss","webpack://./src/styles/theme.scss"],"names":[],"mappings":"AAEA;EACE,qBAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AADF;AAEE;EACE,mBCJI;ADIR;AAEE;EACE,eAAA;AAAJ","sourcesContent":["@import \"theme\";\n\n.signal{\n  display: inline-block;\n  height: 13px;\n  width: 13px;\n  border: 1px solid #DDD;\n  border-radius: 50%;\n  background: #EEE;\n  margin-right: 7px;\n  &.green{\n    background: $green;\n  }\n  &.red{\n    background: red;\n  }\n}","$active-primary: #4a90e2;\n$orange: #f1992b;\n$marker-color: #68b128;\n$heading-green: #4b9a4a;\n$dark-grey: #484848;\n\n$orange: #f09922;\n$green: #56af22;\n$red: #ea4f4b;\n$blue: #4a90e2;\n$light-black: #1b1c1d;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
