// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-div {
    display: flex;
    background-color: #121212 !important;
    justify-content: space-between;
    padding: 16px;
    border-radius: 16px;
    height: 1025px;
}

.w20 {
    width: 20%;
    background-color: aquamarine;
}

.w79_5 {
    width: 79.5%;
    background-color: blue;
}

.p20 {
    padding: 20px;
}

.sub-div{
    border-radius: 12px;
    padding: 20px;
    background-color: rgba(255,255,255,0.07)
}

.mt-5{
    margin-top:5px !important;
}`, "",{"version":3,"sources":["webpack://./src/containers/recordings/player.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,oCAAoC;IACpC,8BAA8B;IAC9B,aAAa;IACb,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,UAAU;IACV,4BAA4B;AAChC;;AAEA;IACI,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb;AACJ;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".main-div {\r\n    display: flex;\r\n    background-color: #121212 !important;\r\n    justify-content: space-between;\r\n    padding: 16px;\r\n    border-radius: 16px;\r\n    height: 1025px;\r\n}\r\n\r\n.w20 {\r\n    width: 20%;\r\n    background-color: aquamarine;\r\n}\r\n\r\n.w79_5 {\r\n    width: 79.5%;\r\n    background-color: blue;\r\n}\r\n\r\n.p20 {\r\n    padding: 20px;\r\n}\r\n\r\n.sub-div{\r\n    border-radius: 12px;\r\n    padding: 20px;\r\n    background-color: rgba(255,255,255,0.07)\r\n}\r\n\r\n.mt-5{\r\n    margin-top:5px !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
