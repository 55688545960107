(function (exports) {
  // const Utils = require("jssip/lib/Utils");
  // const JsSIP_C = require("jssip/lib/Constants");
  const Utils = require("../jssip/JsSIP").Utils;
  const JsSIP_C = require("../jssip/JsSIP").C;
  const NameAddrHeader = require("../jssip/NameAddrHeader");

  class OutgoingRequest {
    constructor(method, ruri, ua, params, extraHeaders, body) {
      // Mandatory parameters check.
      // if (!method || !ruri || !ua)
      // {
      //   return null;
      // }

      this.params = params || {};

      this.ua = ua;
      this.headers = {};
      this.method = method;
      this.ruri = ruri;
      this.body = body;
      this.extraHeaders = Utils.cloneArray(extraHeaders);

      //console.log('Method ',this.params.to_uri);

      // Fill the Common SIP Request Headers.

      // Route.
      if (params && params.route_set) {
        this.setHeader("route", params.route_set);
      } else if (ua.configuration.use_preloaded_route) {
        this.setHeader("route", `<${ua.transport.sip_uri};lr>`);
      }

      // Via.
      // Empty Via header. Will be filled by the client transaction.
      this.setHeader("via", "");

      // Max-Forwards.
      this.setHeader("max-forwards", JsSIP_C.MAX_FORWARDS);

      // To
      const to_uri = params.to_uri;
      const to_params = params.to_tag ? { tag: params.to_tag } : null;
      const to_display_name =
        typeof params.to_display_name !== "undefined"
          ? params.to_display_name
          : null;

      if (this.ruri == to_uri) {
        this.to = `<${this.ruri}>`;
        this.setHeader("to", this.to);
      } else {
        this.to = new NameAddrHeader(to_uri, to_display_name, to_params);
        this.setHeader("to", this.to.toString());
      }

      // From.
      const from_uri = params.from_uri || ua.configuration.uri;
      const from_params = { tag: params.from_tag || Utils.newTag() };
      let display_name;

      if (typeof params.from_display_name !== "undefined") {
        display_name = params.from_display_name;
      } else if (ua.configuration.display_name) {
        display_name = ua.configuration.display_name;
      } else {
        display_name = null;
      }

      this.from = new NameAddrHeader(from_uri, display_name, from_params);
      this.setHeader("from", this.from.toString());

      // Call-ID.
      const call_id =
        params.call_id ||
        ua.configuration.jssip_id + Utils.createRandomToken(15);

      this.call_id = call_id;
      this.setHeader("call-id", call_id);

      // CSeq.
      const cseq = params.cseq || Math.floor(Math.random() * 10000);

      this.cseq = cseq;
      this.setHeader("cseq", `${cseq} ${method}`);
    }

    /**
     * Replace the the given header by the given value.
     * -param {String} name header name
     * -param {String | Array} value header value
     */
    setHeader(name, value) {
      // Remove the header from extraHeaders if present.
      const regexp = new RegExp(`^\\s*${name}\\s*:`, "i");

      for (let idx = 0; idx < this.extraHeaders.length; idx++) {
        if (regexp.test(this.extraHeaders[idx])) {
          this.extraHeaders.splice(idx, 1);
        }
      }

      this.headers[Utils.headerize(name)] = Array.isArray(value)
        ? value
        : [value];
      //this.headers = header;
    }

    /**
     * Get the value of the given header name at the given position.
     * -param {String} name header name
     * -returns {String|undefined} Returns the specified header, null if header doesn't exist.
     */
    getHeader(name) {
      const headers = this.headers[Utils.headerize(name)];

      if (headers) {
        if (headers[0]) {
          return headers[0];
        }
      } else {
        const regexp = new RegExp(`^\\s*${name}\\s*:`, "i");

        for (const header of this.extraHeaders) {
          if (regexp.test(header)) {
            return header.substring(header.indexOf(":") + 1).trim();
          }
        }
      }

      return;
    }

    /**
     * Get the header/s of the given name.
     * -param {String} name header name
     * -returns {Array} Array with all the headers of the specified name.
     */
    getHeaders(name) {
      const headers = this.headers[Utils.headerize(name)];
      const result = [];

      if (headers) {
        for (const header of headers) {
          result.push(header);
        }

        return result;
      } else {
        const regexp = new RegExp(`^\\s*${name}\\s*:`, "i");

        for (const header of this.extraHeaders) {
          if (regexp.test(header)) {
            result.push(header.substring(header.indexOf(":") + 1).trim());
          }
        }

        return result;
      }
    }

    /**
     * Verify the existence of the given header.
     * -param {String} name header name
     * -returns {boolean} true if header with given name exists, false otherwise
     */
    hasHeader(name) {
      if (this.headers[Utils.headerize(name)]) {
        return true;
      } else {
        const regexp = new RegExp(`^\\s*${name}\\s*:`, "i");

        for (const header of this.extraHeaders) {
          if (regexp.test(header)) {
            return true;
          }
        }
      }

      return false;
    }

    /**
     * Parse the current body as a SDP and store the resulting object
     * into this.sdp.
     * -param {Boolean} force: Parse even if this.sdp already exists.
     *
     * Returns this.sdp.
     */

    toString() {
      let msg = `${this.method} ${this.ruri} SIP/2.0\r\n`;

      for (const headerName in this.headers) {
        if (Object.prototype.hasOwnProperty.call(this.headers, headerName)) {
          for (const headerValue of this.headers[headerName]) {
            msg += `${headerName}: ${headerValue}\r\n`;
          }
        }
      }

      for (const header of this.extraHeaders) {
        msg += `${header.trim()}\r\n`;
      }

      // Supported.
      const supported = [];

      switch (this.method) {
        case JsSIP_C.REGISTER:
          supported.push("path", "gruu");
          break;
        case JsSIP_C.INVITE:
          if (this.ua.configuration.session_timers) {
            supported.push("timer");
          }
          if (this.ua.contact.pub_gruu || this.ua.contact.temp_gruu) {
            supported.push("gruu");
          }
          supported.push("ice", "replaces");
          break;
        case JsSIP_C.UPDATE:
          if (this.ua.configuration.session_timers) {
            supported.push("timer");
          }
          supported.push("ice");
          break;
      }

      supported.push("outbound");

      const userAgent = this.ua.configuration.user_agent || JsSIP_C.USER_AGENT;
      const version = this.ua.version || "2.0.0";
      // Allow.
      msg += `Allow: ${JsSIP_C.ALLOWED_METHODS}\r\n`;
      msg += `Supported: ${supported}\r\n`;
      msg += `User-Agent: ${userAgent}\r\n`;
      msg += `Ua-Version: ${version}\r\n`;

      if (this.body) {
        const length = Utils.str_utf8_length(this.body);

        msg += `Content-Length: ${length}\r\n\r\n`;
        msg += this.body;
      } else {
        msg += "Content-Length: 0\r\n\r\n";
      }

      return msg;
    }

    clone() {
      return new OutgoingRequest(
        this.method,
        this.ruri,
        this.ua,
        this.params,
        this.extraHeaders,
        this.body
      );

      /*Object.keys(this.headers).forEach(function (name) {
        request.headers[name] = this.headers[name].slice();
      }, this);

      request.body = this.body;
      request.extraHeaders = Utils.cloneArray(this.extraHeaders);
      request.to = this.to;
      request.from = this.from;
      request.call_id = this.call_id;
      request.cseq = this.cseq;*/

      //return request;
    }
  }
  exports.OutgoingRequest = OutgoingRequest;
})(typeof exports === "undefined" ? (this["OutgoingRequest"] = {}) : exports);

//module.exports = OutgoingRequest;
