import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import ConfigCommonSettings from "./ConfigCommonSettings.js";
import ConfigDesignSetting from "./ConfigDesignSetting.js";
import ConfigApplication from "./ConfigApplication.js";
import ConfigMDMSetting from "./ConfigMDMSetting.js";
import { postMethod, putMethod } from "../utils.js";
import { fetchConfigList, updateConfig, fetchAuthToken } from "../../../modules/actions/mdmAction.js";
import { EndPoints } from "../../../MCXclient/endpoints.js";
import SwipeableViews from "react-swipeable-views";

const AddUser = (props) => {
    const { setModalOpen, purpose, data, tenantId, fetchConfigList, updateConfig, token, fetchAuthToken, deviceTypeList } = props
    const [configData, setConfigData] = useState({ ...data })
    function TabPanel(tabprops) {
        const { children, value, index, ...other } = tabprops;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={5}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
        index2: PropTypes.any.isRequired,
        index3: PropTypes.any.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            "aria-controls": `full-width-tabpanel-${index}`,
        };
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.background.paper,
            width: '100%',
        },
    }));
    useEffect(() => {
        fetchAuthToken()
    }, [])
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const addConfigData = (data) => {
        postMethod(EndPoints.getConfig().addConfig, data, token).then((data) => {
            setConfigData({ ...configData, id: data[0] })
            fetchConfigList({ token, tenantId })
        })
    }
    const nextButtonHandler = (tabValue, data) => {
        if (purpose === "add" && tabValue === 0) {
            addConfigData({ ...configData, ...data, tenantId })
        } else {
            updateConfig({ ...configData, ...data, tenantId })
        }
        setConfigData({ ...configData, ...data })
        setValue(tabValue + 1);
    }
    const submitButtonHandler = (data) => {
        updateConfig({ ...configData, ...data, tenantId })
        setModalOpen(false)
    }
    return (
        <div class={classes.root}>
            <AppBar position="static" style={{ background: '#383736', height: 60 }}>
                <Tabs
                    value={value}
                    onChange={() => { }}
                    indicatorColor="primary"
                    // textColor="primary"
                    style={{
                        color: 'white', height: 60, fontSize: 18, fontWeight: 700, fontSize: 17,
                        fontFamily: "Muli"
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label="Common Settings" style={{ color: 'white', fontSize: 17, fontWeight: 600, textTransform: "capitalize", fontFamily: "Muli" }} {...a11yProps(0)} />
                    <Tab label="Design Settings" style={{ color: 'white', fontSize: 17, fontWeight: 600, textTransform: "capitalize", fontFamily: "Muli" }} {...a11yProps(1)} />
                    <Tab label="Applications" style={{ color: 'white', fontSize: 17, fontWeight: 600, textTransform: "capitalize", fontFamily: "Muli" }} {...a11yProps(2)} />
                    <Tab label="MDM Settings" style={{ color: 'white', fontSize: 17, fontWeight: 600, textTransform: "capitalize", fontFamily: "Muli" }} {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                // onChangeIndex={handleChangeIndex}
                style={{ backgroundColor: '#252524' }}
            >
                <div class="group-tabs" style={{
                    border: "solid",
                    borderBottomRightRadius: 10,
                    borderBottomLeftRadius: 10,
                    borderWidth: "2px",
                    borderColor: "#4B4A4A",
                }}>
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <ConfigCommonSettings nextButtonHandler={nextButtonHandler} data={configData} deviceTypeList={deviceTypeList} purpose={purpose}></ConfigCommonSettings>
                    </TabPanel>
                </div>
                <div class="group-tabs" style={{
                    border: "solid",
                    borderBottomRightRadius: 10,
                    borderBottomLeftRadius: 10,
                    borderWidth: "2px",
                    borderColor: "#4B4A4A",
                }}>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <ConfigDesignSetting nextButtonHandler={nextButtonHandler} data={configData}></ConfigDesignSetting>
                    </TabPanel>
                </div>
                <div class="group-tabs" style={{
                    border: "solid",
                    borderBottomRightRadius: 10,
                    borderBottomLeftRadius: 10,
                    borderWidth: "2px",
                    borderColor: "#4B4A4A",
                }}>
                    <TabPanel value={value} index={2} dir={theme.direction}>
                        <ConfigApplication nextButtonHandler={nextButtonHandler} data={configData}></ConfigApplication>
                    </TabPanel>
                </div>
                <div class="group-tabs" style={{
                    border: "solid",
                    borderBottomRightRadius: 10,
                    borderBottomLeftRadius: 10,
                    borderWidth: "2px",
                    borderColor: "#4B4A4A",
                }}>
                    <TabPanel value={value} index={3} dir={theme.direction}>
                        <ConfigMDMSetting submitButtonHandler={submitButtonHandler} data={configData} setModalOpen={setModalOpen}></ConfigMDMSetting>
                    </TabPanel>

                </div>
            </SwipeableViews>
        </div>
    );
};
const mapStateToProps = ({ mdm }) => {
    return {
        tenantId: mdm.tenantId,
        token: mdm.token,
        deviceTypeList: mdm.deviceTypeList
    }
}
export default connect(mapStateToProps, { fetchConfigList, updateConfig, fetchAuthToken })(AddUser);

