import axios from "axios";

export const getMethod = (url,token)=>{
    return axios.get(url,{headers: {authorization: `Bearer ${token}`}}).then((resp)=>{
        return resp.data;
    }).catch((err)=>{
        console.log("error in getMethod", url, err);
        return []
    })
}

export const postMethod = async (url, body, token, config)=>{
    return new Promise((resolve, reject)=>{
        axios.post(url, body, {headers: {authorization: `Bearer ${token}`}, onUploadProgress: config && config.onUploadProgress ? config.onUploadProgress : null})
        .then((resp)=>{
            resolve(resp.data)
        })
        .catch(err=>{
            reject(err)
        })
    })
}
export const putMethod = async (url, body, token,config)=>{
    return new Promise((resolve, reject)=>{
        axios.put(url, body, {headers: {authorization: `Bearer ${token}`},onUploadProgress: config && config.onUploadProgress ? config.onUploadProgress : null})
        .then((resp)=>{
            resolve(resp.data)
        })
        .catch(err=>{
            reject(err)
        })
    })
}
export const deleteMethod = async (url, token)=>{
    return new Promise((resolve, reject)=>{
        axios.delete(url,{headers: {authorization: `Bearer ${token}`}})
        .then((resp)=>{
            resolve(resp.data)
        })
        .catch(err=>{
            reject(err)
        })
    })
}