(function (exports) {
    const defaultPriority = 5; //to be added in config
    const js2xmlparser = require('js2xmlparser');
    const XMLProcessor = {
        createXML(data) {
            //var xml;
            var docData = groupDoc;
            docData.group['list-service']['@'].uri = `${data.name}@${data.domain}`;
            docData.group['list-service']['display-name']['#'] = data.name;
            docData.group['list-service'].list.entry = getMembersList(data.grpMembers);
            docData.group["list-service"]["group-type"] = 'TEMPORARY';
            docData.group["list-service"]["members-type"] = 'USER_LIST';
            docData.group["list-service"]["source"] = 'MCX';
            docData.group['list-service']['supervisorId'] = data.fromId;
            const res = js2xmlparser.parse('group', docData["group"]);
            return res;
        },

        updateXML(data) {
            var docData = groupDoc;
            docData.group['list-service']['@'].uri = `${data.dgnaGroupId ? data.dgnaGroupId : data.mcptt_id}`;
            docData.group['list-service']['display-name']['#'] = data.name;
            docData.group['list-service'].list.entry = getMembersList(data.grpMembers);
            docData.group["list-service"]["group-type"] = 'TEMPORARY';
            docData.group["list-service"]["members-type"] = 'USER_LIST';
            docData.group["list-service"]["source"] = 'MCX';
            docData.group['list-service']['supervisorId'] = data.fromId;
            let updateData = updateDoc;
            updateData['gmop:request'].group = docData.group;
            const res = js2xmlparser.parse('gmop:request', updateData["gmop:request"]);
            return res;
        },

        updateRISXML(data, globalContactList) {
            var docData = groupDoc;
            docData.group['list-service']['@'].uri = `${data.dgnaGroupId}`;
            docData.group['list-service']['display-name']['#'] = data.name;
            docData.group['list-service'].list.entry = getUpdatedMembersList(data.grpMembers, globalContactList);
            docData.group["list-service"]["group-type"] = 'TEMPORARY';
            docData.group["list-service"]["members-type"] = 'USER_LIST';
            docData.group["list-service"]["source"] = 'Tetra';
            docData.group['list-service']['supervisorId'] = data.fromId;
            let updateData = updateDoc;
            updateData['gmop:request'].group = docData.group;
            const res = js2xmlparser.parse('gmop:request', updateData["gmop:request"]);
            return res;
        },

        processXML(xml) {
            var data;
        },
    }


    const updateDoc = {
        "gmop:request": {
            "group": {

            }
        }
    }

    const groupDoc = {
        "group": {
            "@": {
                "xmlns": "urn:oma:xml:poc:list-service",
                "xmlns:rl": "urn:ietf:params:xml:ns:resource-lists",
                "xmlns:cp": "urn:ietf:params:xml:ns:common-policy",
                "xmlns:ocp": "urn:oma:xml:xdm:common-policy",
                "xmlns:oxe": "urn:oma:xml:xdm:extensions",
                "xmlns:mcpttgi": "urn:3gpp:ns:mcpttGroupInfo:1.0"

            },
            "list-service": {
                "@": {
                    "uri": ""
                },
                "display-name": {
                    "@": {
                        "xml:lang": "en-us"
                    },
                    "#": ""
                },
                "list": {
                    "entry": {
                        "@": {
                            "uri": ""
                        },
                        "rl:display-name": "",
                        "mcpttgi:on-network-required": "",
                        "mcpttgi:user-priority": ""
                    }
                },
                "mcpttgi:on-network-invite-members": true,
                "mcpttgi:on-network-max-participant-count": 1,
                "cp:ruleset": {
                    "cp:rule": {
                        "@": {
                            "id": ""
                        },
                        "cp:conditions": {
                            "is-list-member": ""
                        },
                        "cp:actions": {
                            "allow-initiate-conference": true,
                            "join-handling": true,
                            "mcpttgi:allow-MCPTT-emergency-call": true,
                            "mcpttgi:allow-imminent-peril-call": true,
                            "mcpttgi:allow-MCPTT-emergency-alert": true
                        }
                    }
                },
                "oxe:supported-services": {
                    "oxe:service": {
                        "@": {
                            "enabler": "urn:urn-7:3gpp-service.ims.icsi.mcptt"
                        },
                        "oxe:group-media": {
                            "mcpttgi:mcptt-speech": ""
                        }
                    }
                },
                "mcpttgi:on-network-disabled": "",
                "mcpttgi:on-network-group-priority": 1,
            }

        }
    }

    function getMembersList(list) {
        var mList = [];
        list.forEach((element, i) => {
            mList[i] = {
                "@": {
                    "uri": `${element.mcptt_uri}`
                },
                "rl:display-name": `${element.contactName}`,
                "mcpttgi:on-network-required": true,
                "mcpttgi:user-priority": defaultPriority,
                "mcpttgi:tetra-id": element.mcptt_id
            }
        })
        return mList;
    }

    // function getUpdatedMembersList(list) {
    //     var mList = [];
    //     list.forEach((element, i) => {
    //         mList[i] = {
    //             "@": {
    //                 "uri": `${element.tetra_id}-mcptt@consortiwfdomain.org`
    //             },
    //             "rl:display-name": `${element.name}`,
    //             "mcpttgi:on-network-required": true,
    //             "mcpttgi:user-priority": defaultPriority,
    //             "mcpttgi:tetra-id": element.tetra_id
    //         }
    //     })
    //     return mList;
    // }

    function getUpdatedMembersList(list, globalContactList) {
        var mList = [];
        list.forEach((element, i) => {
            let found = false;
            let uri = '', mcpttId = '';
            for (let i = 0; i < globalContactList.length; i++) {
                if (globalContactList[i]["mcptt_id"].includes(element.tetra_id)) {
                    found = true;
                    mcpttId = globalContactList[i]["mcptt_id"];
                    uri = globalContactList[i]["mcptt_uri"];
                    break;
                }
            }
            if (found) {
                mList[i] = {
                    "@": {
                        "uri": uri,
                    },
                    "rl:display-name": `${element.name}`,
                    "mcpttgi:on-network-required": true,
                    "mcpttgi:user-priority": defaultPriority,
                    "mcpttgi:tetra-id": mcpttId
                }
            } else {
                console.log("RIS:SYSTEM_DGNA:getUpdatedMembersList============ user not found === ", element, list, JSON.stringify(globalContactList));
            }
        })
        return mList;
    }

    exports.XMLProcessor = XMLProcessor

})(typeof exports === 'undefined' ? this['XMLProcessor'] = {} : exports);



//module.exports = XMLProcessor;