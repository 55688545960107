import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const SearchCollapse = (props) => {
    const [seen1, setSeen1] = useState(true);
    const [seen2, setSeen2] = useState(false);
    const [serchText, setSearchText] = useState("");
    const { search, dataList, indvGrp, mtmOpen } = props;

    const collapse = () => {
        setSeen1(!seen1);
        setSeen2(!seen2);
        search("");
    };

    const searchEntered = (text) => {
        setSearchText(text);
        search(text);
    };

    return (
        <div className="flt-l">
            {seen1 ? (
                <div className="in-blc">
                    {dataList ? (
                        <React.Fragment>
                            {global.config.userConfig.multiTenantFeature == true && mtmOpen == false &&
                                <FilterButton
                                    iconclass={"feather icon-plus-circle f-16 text-success"}
                                    filtr={indvGrp}
                                    info={"Add"}
                                    type={"add"}
                                />
                            }
                            {global.config.userConfig.partnerManagement &&
                                <FilterButton
                                    iconclass={"feather icon-user mr-2"}
                                    filtr={indvGrp}
                                    info={"Partner List"}
                                    type={"partner"}
                                />
                            }
                            {global.config.userConfig.tenantManagement &&
                                <FilterButton
                                    iconclass={"feather icon-users mr-2"}
                                    filtr={indvGrp}
                                    info={"Tenant List"}
                                    type={"tenant"}
                                />
                            }
                        </React.Fragment>
                    ) : null}
                    {dataList ? (
                        <button
                            className="sq-icon-btn in-blc m-r-5 wx32"
                            onClick={collapse}
                            type="button"
                            id=""
                        >
                            <i class="feather icon-search"> </i>
                        </button>
                    ) : null}
                </div>
            ) : null}
            {seen2 ? (
                <div className="input-group in-blc">
                    <input
                        type="text"
                        className="textinput searchinput w80 in-blc"
                        autoFocus
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="button-addon2"
                        onChange={(e) => searchEntered(e.target.value)}
                    />
                    <button
                        className="btnsearch in-blc"
                        onClick={collapse}
                        type="submit"
                        id="button-addon2"
                    >
                        <i className="feather icon-chevron-right"> </i>
                    </button>
                </div>
            ) : null}
        </div>
    )
}

const FilterButton = (props) => {
    const { info, filtr, btnClass, iconclass, type, active } = props;
    const filtrType =
        info === "TETRA" ? "tetra" : info === "Individual" ? "indv" : "grp";
    return (
        <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip({ info: info })}
        >
            <button
                class={
                    btnClass
                        ? btnClass
                        : "sq-icon-btn in-blc wx64 " + (active === type ? "active" : "")
                }
                onClick={() => filtr(type)}
            >
                {type == 'add' ? <img style={{ position: 'relative', top: '3px' }} className="imgfilterButton" src={`/assets/images/addP_icon.png`} /> :
                    <i class={iconclass}></i>}
            </button>
        </OverlayTrigger>
    );
};
const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        {props.info}
    </Tooltip>
);


const mapStateToProps = ({ communication }) => {
    return {};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(SearchCollapse);