(function (exports) {
    class Organisation {
        constructor(data) {
            this.organisationProfile = {
                "@": {
                    "org-profile-index": data.orgId,
                    "XUI-URI": "",
                    "xsi:schemaLocation": "urn:3gpp:mcptt:user-profile:1.0 mcptt-user-profile.xsd",
                    "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instanc",
                    "xmlns": "urn:3gpp:mcptt:userprofile:1.0",
                    "xmlns:cp": "urn:ietf:params:xml:ns:common-policy"
                },
                "Name": data.orgName,
                "Status": true,
                "display-name": data.orgProfile
            }
        }
    }
    exports.Organisation = Organisation
})(typeof exports === 'undefined' ? this['Organisation'] = {} : exports);
