import React, { useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
//import assest from "../../../public/assets/images/purchase_icon.png"
// import './styles.css'

//Other files

const propTypes = {
  propData: PropTypes.array,
};


const SearchCollapse = (props) => {
  const [seen1, setSeen1] = useState(true);
  const [seen2, setSeen2] = useState(false);
  const [serchText, setSearchText] = useState("");
  const { search, userList, template, org, indvGrp, sprTemplate, prTemplate, tnTemplate, userOpen, subscrTemplate } = props;
  const collapse = () => {
    setSeen1(!seen1);
    setSeen2(!seen2);
    search("");
  };

  const searchEntered = (text) => {
    setSearchText(text);
    search(text);
  };

  return (
    <div className="flt-l">
      {seen1 ? (
        <div className="in-blc" style={{ marginRight: 10 }}>
          {userList ? (
            <React.Fragment>
              {userOpen == false &&
                < FilterButton
                  imgName={"addP_icon"}
                  filtr={indvGrp}
                  info={"Add User"}
                  type={"add"}
                />}
              <FilterButton
                iconclass={"feather icon-user mr-2"}
                filtr={indvGrp}
                info={"Individual"}
                type={"indv"}
              />
              <FilterButton
                iconclass={"feather icon-map mr-2"}
                filtr={indvGrp}
                info={"IWF Map"}
                type={"iwf_map"}
              />
              {/* <FilterButton
                  iconclass={"feather icon-alert-triangle mr-2"}
                  filtr={indvGrp}
                  info={"ALERTS"}
                  type={"alerts"}
                  /> */}
              <FilterButton
                iconclass={"feather icon-aperture mr-2"}
                filtr={indvGrp}
                info={"Org"}
                type={"org"}
              />
              <FilterButton
                iconclass={"feather icon-users mr-2"}
                filtr={indvGrp}
                info={"Group"}
                type={"grp"}
              />
            </React.Fragment>
          ) : null}
          {org ? (
            <React.Fragment>
              <FilterButton
                imgName={"addP_icon"}
                filtr={indvGrp}
                info={"Add Org"}
                type={"add"}
              />
              <FilterButton
                iconclass={"feather icon-user mr-2"}
                filtr={indvGrp}
                info={"Individual"}
                type={"indv"}
              />
              <FilterButton
                iconclass={"feather icon-users mr-2"}
                filtr={indvGrp}
                info={"Group"}
                type={"grp"}
              />
            </React.Fragment>
          ) : null}
          {template ? (
            <React.Fragment>
              {userOpen == false &&
                <FilterButton
                  imgName={"addP_icon"}
                  filtr={indvGrp}
                  info={"Add Template"}
                  type={"add"}
                />}
              <FilterButton
                iconclass={"feather icon-user mr-2"}
                filtr={indvGrp}
                info={"FA Template"}
                type={"fa"}
              />
            </React.Fragment>
          ) : null}
          {userList ? (
            <button
              className="sq-icon-btn in-blc m-r-5 wx32"
              onClick={collapse}
              type="button"
              id=""
            >
              <i class="feather icon-search"> </i>
            </button>
          ) : null}
          {
            sprTemplate ? (
              <React.Fragment>
                <FilterWithoutIconButton
                  imgName={"addP_icon"}
                  filtr={indvGrp}
                  info={"New Product"}
                  type={"add"}
                />
                <FilterWithoutIconButton
                  imgName={"catalog_con"}
                  filtr={indvGrp}
                  info={"My Catalog"}
                  type={"catalog"}
                />
                <FilterWithoutIconButton
                  imgName={"sales_icon"}
                  filtr={indvGrp}
                  info={"My Sales"}
                  type={"sale"}
                />
              </React.Fragment>
            ) : null
          }
          {
            prTemplate ? (
              <React.Fragment>
                <FilterWithoutIconButton
                  imgName={"addP_icon"}
                  filtr={indvGrp}
                  info={"New Product"}
                  type={"add"}
                />
                <FilterWithoutIconButton
                  imgName={"catalog_con"}
                  filtr={indvGrp}
                  info={"My Catalog"}
                  type={"catalog"}
                />
                <FilterWithoutIconButton
                  imgName={"buy_icon"}
                  filtr={indvGrp}
                  info={"Buy"}
                  type={"buy"}
                />
                <FilterWithoutIconButton
                  imgName={"sales_icon"}
                  filtr={indvGrp}
                  info={"My Sales"}
                  type={"sale"}
                />
                <FilterWithoutIconButton
                  imgName={"purchase_icon"}
                  filtr={indvGrp}
                  info={"My Purchases"}
                  type={"purchase"}
                />
              </React.Fragment>
            ) : null
          }
          {
            tnTemplate ? (
              <React.Fragment>
                <FilterWithoutIconButton
                  imgName={"buy_icon"}
                  filtr={indvGrp}
                  info={"Buy"}
                  type={"buy"}
                />
                <FilterWithoutIconButton
                  imgName={"purchase_icon"}
                  filtr={indvGrp}
                  info={"My Purchases"}
                  type={"purchase"}
                />
              </React.Fragment>
            ) : null
          }
          {
            subscrTemplate ? (
              <React.Fragment>
                <FilterWithoutIconButton
                  imgName={"catalog_con"}
                  filtr={indvGrp}
                  info={"My Subscriptions"}
                  type={"subscr"}
                />
              </React.Fragment>
            ) : null
          }
        </div>
      ) : null}

      {seen2 ? (
        <div className="input-group in-blc">
          <input
            type="text"
            className="textinput searchinput w80 in-blc"
            autoFocus
            placeholder="Search"
            aria-label="Search"
            aria-describedby="button-addon2"
            onChange={(e) => searchEntered(e.target.value)}
          />
          <button
            className="btnsearch in-blc"
            onClick={collapse}
            type="submit"
            id="button-addon2"
          >
            <i className="feather icon-chevron-right"> </i>
          </button>
        </div>
      ) : null}
    </div>
  );
};

const FilterButton = (props) => {
  const { info, filtr, btnClass, iconclass, type, active, imgName } = props;
  const filtrType =
    info === "TETRA" ? "tetra" : info === "Individual" ? "indv" : "grp";
  let imgPath = type == "add" ? "/assets/images/" + imgName + ".png" : ""
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip({ info: info })}
    >
      <button
        className={
          btnClass
            ? btnClass
            : "sq-icon-btn in-blc wx47 " + (active === type ? "active" : "")
        }
        //className={"filterButton " + " " + "sq-icon-btn wx64 "}
        onClick={() => filtr(type)}
        style={{ justifyContent: 'center', alignItems: "center" }}
      >
        {type !== "add" ? <i className={iconclass}></i>
          :
          <img className="imgfilterButton" src={`${imgPath}`} />
        }
      </button>
    </OverlayTrigger>
  );
};

const FilterWithoutIconButton = (props) => {
  const { info, filtr, type, imgName } = props;
  let imgPath = "/assets/images/" + imgName + ".png";
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip({ info: info })}
    >
      <button
        // className="filterButton"
        className="sq-icon-btn in-blc wx47 "
        onClick={() => filtr(type)}
      >
        <img className="imgfilterButton" src={`${imgPath}`} />
      </button>
    </OverlayTrigger>
  );
};

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    {props.info}
  </Tooltip>
);

const mapStateToProps = ({ communication }) => {
  return {};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

SearchCollapse.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(SearchCollapse);
