import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles, createTheme } from "@material-ui/core/styles";
const DefaultInfoData = {
  orgName: "",
  orgId: "",
  orgProfile: ""
}

const CreateOrg = (props) => {
  const { infoHandler, infoData } = props
  const [orgInfo, SetorgInfo] = useState(DefaultInfoData)
  const [emptyOrgId, setEmptyOrg] = useState(false)
  const [emptyOrgName, setEmptyOrgName] = useState(false)
  const [emptyOrgProfile, setEmptyOrgProfile] = useState(false)




  useEffect(() => {
    if (infoData && infoData.oid !== "") {
      SetorgInfo(infoData)

    }
    else {
      SetorgInfo(DefaultInfoData)

    }

  }, [])


  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#252524",
      //backgroundColor: theme.palette.background.default,
      paddingTop: 10,
      paddingLeft: 20,
      paddingRight: 20,
      width: "100%",
      //height: "70%",
      overflowY: "scroll",
      display: 'flex',
      flexDirection: 'column',
      // justifyContent: 'center',
    },
    formControlFA: {
      width: "60%",
      height: "65px",
      marginTop: "2px",
      color: "#D0D3D4",
      fontSize: 17,
      fontWeight: 600,
      backgroundColor: "#383736"
    },
    listItemFA: {
      padding: "5px",
      fontFamily: "Muli",
      color: "#D0D3D4",
      fontSize: 17,
      fontWeight: 600
    },
    productTypeClass: {
      width: "90%",
      display: 'flex',
      flexDirection: 'column',
      justifyContent: "center",
      alignItems: "center",
      border: "solid",
      borderRadius: 10,
      borderWidth: "2px",
      borderColor: "#4B4A4A",
      marginTop: 5,
      marginBottom: 10
    },
    divList: {
      width: '100%',
      marginBottom: '13px',
    },
    lblStyle: {
      width: '100%',
      color: "#D0D3D4",
      fontSize: 18,
      fontWeight: 600
    },
    tetraSwitch: {
      fontFamily: "Muli",
      marginTop: "2px",
      marginRight: "1px",
      marginLeft: "0px",
      color: "#D0D3D4",
      fontSize: 17,
      fontWeight: 600
    },
    datePickerStyle: {
      color: "black",
      padding: "10px",
      fontFamily: "Sans-Serif",
      width: '60%',
      border: "solid",
      borderRadius: 20,
      borderWidth: "2px",
      borderColor: "#D3D3D3",
    }

  }));
  const classes = useStyles();


  const UpdateBasicDetails = () => {
    console.log("update org ", orgInfo, global.config.userConfig.multiTenantFeature);
    // infoHandler(orgInfo)
    let orgList = orgInfo
    if (global.config.userConfig.multiTenantFeature) {
      if (global.config.userConfig.domainName.length > 0) {
        orgList.orgId = (orgInfo.orgId && orgInfo.orgId !== "" && orgInfo.orgId.includes("@")) ? orgInfo.orgId : (orgInfo.orgId + "@" + global.config.userConfig.domainName)
      }
    }
    SetorgInfo(orgList)
    if ((orgInfo.orgId.length === 0)) {
      setEmptyOrg(true)

    }
    else if (orgInfo.orgName.length === 0) {
      setEmptyOrgName(true)
    }
    else if (orgInfo.orgProfile.length === 0) {
      setEmptyOrgProfile(true)
    }
    else {

      infoHandler(orgInfo)
    }

  }
  const nameChangeHandler = (e) => {
    SetorgInfo({
      ...orgInfo,
      orgName: e.target.value,
    });
    if (e.target.value.length !== 0) {
      setEmptyOrgName(false)
    } else {
      setEmptyOrgName(true)
    }
    console.log("update org name", orgInfo);

  };
  const idChangeHandler = (e) => {
    SetorgInfo({
      ...orgInfo,
      orgId: e.target.value,
    });
    if (e.target.value.length !== 0) {
      setEmptyOrg(false)
    } else {
      setEmptyOrg(true)
    }
  };
  const descriptioChangeHandler = (e) => {
    SetorgInfo({
      ...orgInfo,
      orgProfile: e.target.value,
    });
    if (e.target.value.length !== 0) {
      setEmptyOrgProfile(false)
    } else {
      setEmptyOrgProfile(true)
    }
  };
  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Box className="tab1-account" >
          <Box class="form-group">
            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
              <label class="attribute-heading" style={{ color: 'whitesmoke' }} >Org Id</label>
            </Box>
            <input type="text" style={{ borderColor: '#383736' }} class="input-control-flat" value={orgInfo.orgId} id="name" onChange={idChangeHandler} disabled={infoData} />
            {
              emptyOrgId === true ?
                (
                  <label class="error-handling-lbl">Please enter the OrgId</label>
                )
                :
                null
            }
          </Box>
          <Box class="form-group">
            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
              <label for="priority" style={{ color: 'whitesmoke' }}>Name</label>
            </Box>
            <input type="text"
              class="input-control-flat"
              style={{ borderColor: '#383736' }}
              value={orgInfo.orgName}
              id="priority"
              onChange={nameChangeHandler} />
            {
              emptyOrgName === true ?
                (
                  <label class="error-handling-lbl">Please enter the name</label>
                )
                :
                null
            }

          </Box>
          <Box class="form-group">
            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
              <label for="priority" style={{ color: 'whitesmoke' }}>Description</label>
            </Box>
            <input type="text" style={{ borderColor: '#383736' }} class="input-control-flat" id="priority" value={orgInfo.orgProfile} onChange={descriptioChangeHandler} />
            {
              emptyOrgProfile === true ?
                (
                  <label class="error-handling-lbl">Please enter the profile</label>
                )
                :
                null
            }
          </Box>
        </Box>
      </Grid>
      <button
        class="update-btn-profile"
        type="button"
        onClick={UpdateBasicDetails}
      >
        SUBMIT
        {/* {newOrUpdateProfile ? 'SUBMIT' : 'UPDATE'} */}
      </button>
    </div>
  )

}
export default CreateOrg
