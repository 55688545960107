/** @format */

import React, { useState } from "react";
import { createTheme } from "@material-ui/core/styles";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import { Checkbox, FormControlLabel, InputLabel, MenuItem, FormControl, Select, Grid, Box } from "@material-ui/core";
import { connect } from "react-redux";
const ConfigDesignSetting = (props) => {
    const {
        nextButtonHandler,
        data
    } = props;
    const [designSettingData, setDesignSettingData] = useState({ ...data })
    const iconSizeOption = [
        { text: "Small", value: "SMALL" },
        { text: "Average (+20%)", value: "AVERAGE" },
        { text: "Large (+20%)", value: "LARGE" }
    ];
    const desktopTitleOption = [
        { text: "No", value: "no" },
        { text: "Device ID", value: "deviceId" },
        { text: "Description", value: "description" },
        { text: "Custom Template", value: "custom" }
    ];
    const lockOrientationOption = [
        { text: "Do Not Lock", value: null },
        { text: "Portrait", value: 1 },
        { text: "Landscape", value: 2 }
    ]

    const isEmptyObject = (obj) => {
        return (
            obj &&
            Object.keys(obj).length === 0 &&
            Object.getPrototypeOf(obj) === Object.prototype
        );
    };

    const useStyles = makeStyles((theme) => ({
        // root: {
        //     width: "100%",
        //     maxWidth: 360,
        // },
        // formControl: {
        //     width: "100%",
        //     marginTop: "5px",
        // },
        // formControlFA: {
        //     width: "80%",
        //     height: "60px",
        //     marginTop: "5px",
        // },
        // listMember_all: {
        //     // width: 250,
        //     maxHeight: 200,
        // },
        // listItemFA: {
        //     padding: "5px",
        //     fontFamily: "Muli",
        // },
        // tetraSwitch: {
        //     fontFamily: "Muli",
        //     marginTop: "2px",
        //     marginRight: "1px",
        //     marginLeft: "0px",
        // },
        // noOfRegTextField: {
        //     width: "40%",
        //     height: "25px",
        // },
        // ueInfoList: {
        //     padding: "5px",
        //     fontFamily: "Muli",
        // },
        // formControlUEType: {
        //     width: "80%",
        //     height: "60px",
        //     marginTop: "5px",
        // },
        // mcxDataIdInput: {
        //     backgroundColor: "rgb(232, 232, 232) !important",
        // },
        // tickSize: {
        //     transform: "scale(1.3)",
        //     marginLeft: "20px",
        // },
        // ml0: {
        //     marginTop: "10px",
        // },
        // mleft0: {
        //     marginLeft: "0px",
        // },
        // errorIndicator: {
        //     background: "#ffeded",
        // },
        // paper: {
        //     position: 'relative',
        //     background: '#e8e8e8',
        //     overflow: 'hidden'
        // }


        root: {
            backgroundColor: "#252524",
            //backgroundColor: theme.palette.background.default,
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            width: "100%",
            //height: "70%",
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
        },
        formControlFA: {
            width: "90%",
            height: "65px",
            marginTop: "2px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600,
            backgroundColor: "#383736"
        },
        listItemFA: {
            padding: "5px",
            fontFamily: "Muli",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        productTypeClass: {
            width: "90%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            border: "solid",
            borderRadius: 10,
            borderWidth: "2px",
            borderColor: "#4B4A4A",
            marginTop: 5,
            marginBottom: 10
        },
        divList: {
            width: '100%',
            marginBottom: '13px',
        },
        lblStyle: {
            width: '100%',
            color: "#D0D3D4",
            fontSize: 18,
            fontWeight: 600
        },
        tetraSwitch: {
            fontFamily: "Muli",
            marginTop: "2px",
            marginRight: "1px",
            marginLeft: "0px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        datePickerStyle: {
            color: "black",
            padding: "10px",
            fontFamily: "Sans-Serif",
            width: '60%',
            border: "solid",
            borderRadius: 20,
            borderWidth: "2px",
            borderColor: "#D3D3D3",
        }

    }));

    const theme = createTheme({
        palette: {
            primary: {
                main: "#006400",
            },
            secondary: {
                main: "#fdce2a",
            },
        },
    });

    const classes = useStyles();

    const onSelectHandler = (e) => {
        let data = { ...designSettingData }
        data[e.target.name] = e.target.value
        setDesignSettingData({ ...data })
    }
    const checkBoxHandler = (e) => {
        let data = { ...designSettingData }
        data[e.target.name] = !data[e.target.name]
        setDesignSettingData({ ...data })
    }
    return (
        <div className={classes.root}>
            <Grid container width="100%" spacing={2}>
                <Grid item xs={12}>
                    <Box className="tab1-account" width={'100%'} marginLeft={0}>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }} >
                            <Box flex={0.4} mr="0.5em" marginTop={1} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Use default design</label>
                            </Box>
                            <FormControlLabel
                                className={classes.tickSize}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={designSettingData.usedefaultdesignsettings ? true : false}
                                        name="usedefaultdesignsettings"
                                        onChange={checkBoxHandler}
                                    />
                                }
                            />
                        </Box>
                        <Box className="form-group" paddingRight={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <Box flex={1} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Icon Size</label>
                            </Box> */}
                            <FormControl
                                variant="filled"
                                className={classes.formControlFA}
                                disabled={designSettingData.defaultDesign ? true : false}
                            >
                                <InputLabel id="ueTypeID" className={classes.listItemFA} focused={false}>
                                    Icon Size
                                </InputLabel>
                                <Select
                                    className={classes.listItemFA}
                                    id="ueType-select-filled"
                                    value={designSettingData.iconsize || ""}
                                    name="iconsize"
                                    onChange={onSelectHandler}
                                    disableUnderline={true}
                                >
                                    {iconSizeOption &&
                                        iconSizeOption.map((uetype) => {
                                            return (
                                                <MenuItem value={uetype.value}>{uetype.text}</MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className="form-group" paddingRight={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <Box flex={1} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Desktop Title</label>
                            </Box> */}
                            <FormControl
                                variant="filled"
                                className={classes.formControlFA}
                                disabled={designSettingData.defaultDesign ? true : false}
                            >
                                <InputLabel id="ueTypeID" className={classes.listItemFA} focused={false} >
                                    Desktop Title
                                </InputLabel>
                                <Select
                                    className={classes.listItemFA}
                                    id="desktopheader"
                                    value={designSettingData.desktopheader || ""}
                                    name="desktopheader"
                                    onChange={onSelectHandler}
                                    disableUnderline={true}
                                >
                                    {desktopTitleOption &&
                                        desktopTitleOption.map((uetype) => {
                                            return (
                                                <MenuItem value={uetype.value}>{uetype.text}</MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className="form-group" paddingRight={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <Box flex={1} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Lock Orientation</label>
                            </Box> */}
                            <FormControl
                                variant="filled"
                                className={classes.formControlFA}
                            >
                                <InputLabel id="ueTypeID" className={classes.listItemFA} focused={false} >
                                    Lock Orientation
                                </InputLabel>
                                <Select
                                    className={classes.listItemFA}
                                    id="orientation"
                                    value={designSettingData.orientation || ""}
                                    name="orientation"
                                    onChange={onSelectHandler}
                                    disableUnderline={true}
                                >
                                    {lockOrientationOption &&
                                        lockOrientationOption.map((uetype) => {
                                            return (
                                                <MenuItem value={uetype.value}>{uetype.text}</MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className="submit-btns">
                            <button
                                class="update-btn-profile"
                                type="button"
                                onClick={() => nextButtonHandler(1, designSettingData)}
                            >
                                Next
                            </button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div >
    );
};

export default connect()(ConfigDesignSetting);
