(function (exports) {
  const EndPoints = require("../endpoints").EndPoints;
  const axios = require("axios").default;

  class Recordings {
    constructor() {
      this.headers = {
        "Content-Type":
          'application/org.3gpp.mcptt.user-profile+xml; charset="utf-8" ',
      };
    }
    getCallRecord(data) {
      console.log("getCallRecord req", data);
      return axios
        .post(EndPoints.getConfig().getCallRecord, data)
        .then((res) => {
          if (res.data) {
            console.log("CallRecord response--", res.data);
            return res.data
          }
          else
            return null;
        })
        .catch((err) => {
          console.log("🥴 FAILED", err);
          return null;
        })
    }
  }
  exports.Recordings = Recordings;
})(typeof exports === "undefined" ? (this["Recordings"] = {}) : exports);
