import {
    GET_PRODUCT_CATALOG,
    ADD_PRODUCT_CATALOG,
    UPDATE_PRODUCT_CATALOG,
    EDIT_PRODUCT_CATALOG,
    BUY_PRODUCT_CATALOG,
    CONFIRM_ORDER,
    REJECTED_ORDER,
    GET_ORDER_LIST,
    UPDATE_ORDER_LIST,
    GET_SUBSCRIPTION_LIST,
    UPDATE_SUBSCRIPTION_LIST,
    GET_CURRENT_ACTIVE_SUBSCRIPTION_FOR_USER,
    UPDATE_CURRENT_ACTIVE_SUBSCRIPTION_FOR_USER,
    GET_CURRENT_ACTIVE_SUBSCRIPTION,
    UPDATE_CURRENT_ACTIVE_SUBSCRIPTION,
    GET_ALLOCATE_USER_FROM_TENANT_SUBSCRIPTION,
    UPDATE_ALLOCATE_USER_FROM_TENANT_SUBSCRIPTION,
    GET_DEALLOCATE_USER_FROM_TENANT_SUBSCRIPTION,
    UPDATE_DEALLOCATE_USER_FROM_TENANT_SUBSCRIPTION,
    RESET_SUBSCRIPTIONS
} from './actions/type';


const initialState = {
    productcataloglist: [],
    orderList: [],
    subscriptionList: [],
    currentActiveSubscriptionsForUser: [],
    currentActiveSubscriptions: [],
    allocateDeallocateSubscriptionsuccessIds: [],
    errorTuples: []
}

export default (state = initialState, action) => {
    const fetchedData = action.data;
    switch (action.type) {
        case UPDATE_PRODUCT_CATALOG:
            return {
                ...state,
                productcataloglist: fetchedData,
            }
        case UPDATE_ORDER_LIST:
            return {
                ...state,
                orderList: fetchedData,
            }
        case UPDATE_SUBSCRIPTION_LIST:
            return {
                ...state,
                subscriptionList: fetchedData,
            }
        case UPDATE_CURRENT_ACTIVE_SUBSCRIPTION_FOR_USER:
            return {
                ...state,
                currentActiveSubscriptionsForUser: fetchedData,
            }
        case UPDATE_CURRENT_ACTIVE_SUBSCRIPTION:
            return {
                ...state,
                currentActiveSubscriptions: fetchedData,
            }
        case UPDATE_ALLOCATE_USER_FROM_TENANT_SUBSCRIPTION:
            return {
                ...state,
                allocateDeallocateSubscriptionsuccessIds: fetchedData,
            }
        case RESET_SUBSCRIPTIONS:
            return {
                ...state,
                currentActiveSubscriptionsForUser: [],
                currentActiveSubscriptions: [],
                allocateDeallocateSubscriptionsuccessIds: [],
            }
        case REJECTED_ORDER:
            return {
                ...state,
                errorTuples: fetchedData,
            }
        default:
            return state
    }
};

const actions = {
    addProductCatalog: (data) => ({ type: ADD_PRODUCT_CATALOG, data }),
    updateProductCatalog: (data) => ({ type: EDIT_PRODUCT_CATALOG, data }),
    fetchProductCatalog: (data) => ({ type: GET_PRODUCT_CATALOG, data }),
    createOrderForProduct: (data) => ({ type: BUY_PRODUCT_CATALOG, data }),
    updateOrderForProduct: (data) => ({ type: CONFIRM_ORDER, data }),
    resetOrderRejectedCauses: (data) => ({ type: REJECTED_ORDER, data }),
    fetchOrderList: (data) => ({ type: GET_ORDER_LIST, data }),
    fetchSubscriptions: (data) => ({ type: GET_SUBSCRIPTION_LIST, data }),
    getCurrentActiveSubscriptionsForUser: (data) => ({ type: GET_CURRENT_ACTIVE_SUBSCRIPTION_FOR_USER, data }),
    getCurrentActiveSubscriptions: (data) => ({ type: GET_CURRENT_ACTIVE_SUBSCRIPTION, data }),
    allocateUserFromTenantSubscription: (data) => ({ type: GET_ALLOCATE_USER_FROM_TENANT_SUBSCRIPTION, data }),
    deAllocateUserFromTenantSubscription: (data) => ({ type: GET_DEALLOCATE_USER_FROM_TENANT_SUBSCRIPTION, data }),
    resetSubscriptionsData: () => ({ type: RESET_SUBSCRIPTIONS })
}


export const fetchProductCatalog = (data) => {
    return dispatch => {
        dispatch(actions.fetchProductCatalog(data));
    }
}

export const addProductCatalog = (data) => {
    return dispatch => {
        dispatch(actions.addProductCatalog(data));
    }
}

export const updateProductCatalog = (data) => {
    return dispatch => {
        dispatch(actions.updateProductCatalog(data));
    }
}

export const createOrderForProduct = (data) => {
    return dispatch => {
        dispatch(actions.createOrderForProduct(data));
    }
}

export const updateOrderForProduct = (data) => {
    return dispatch => {
        dispatch(actions.updateOrderForProduct(data));
    }
}

export const resetOrderRejectedCauses = (data) => {
    return dispatch => {
        dispatch(actions.resetOrderRejectedCauses(data));
    }
}

export const fetchOrderList = (data) => {
    return dispatch => {
        dispatch(actions.fetchOrderList(data));
    }
}

export const fetchSubscriptions = (data) => {
    return dispatch => {
        dispatch(actions.fetchSubscriptions(data));
    }
}
export const getCurrentActiveSubscriptionsForUser = (data) => {
    return dispatch => {
        dispatch(actions.getCurrentActiveSubscriptionsForUser(data));
    }
}
export const getCurrentActiveSubscriptions = (data) => {
    return dispatch => {
        dispatch(actions.getCurrentActiveSubscriptions(data));
    }
}
export const allocateUserFromTenantSubscription = (data) => {
    return dispatch => {
        dispatch(actions.allocateUserFromTenantSubscription(data));
    }
}
export const deAllocateUserFromTenantSubscription = (data) => {
    return dispatch => {
        dispatch(actions.deAllocateUserFromTenantSubscription(data));
    }
}
export const resetSubscriptionsData = () => {
    return dispatch => {
        dispatch(actions.resetSubscriptionsData());
    }
}
