(function (exports) {
  var instance_id;
  const stateType = require("./StateType");
  const JsSIP = require("./jssip/JsSIP");
  const CMC = require("./CMC").CMC;
  const GMC = require("./GMC").GMC;
  const { Delegates } = require("./Delegates");
  const js2xmlparser = require("js2xmlparser");
  const Store = require("./Store").Store;
  const DOMParser = require("xmldom").DOMParser;
  const parser = new DOMParser();
  JsSIP.debug.enable("JsSIP:*");
  const remote = require("./remoteLog").remote;
  const FAManager = require("./FAMC").FAManager;
  const FAPublisher = require("./FAMC/publisher").FAPublisher;
  const Registrator = require("./Registerer").Registrator;
  const Admin = require("./Admin").Admin;
  const IDMC = require("./IDMC").IDMC;
  const NodeStatus = require("./NodeStatus").NodeStatus;
  const jwt_decode = require("jwt-decode").default;
  const Task = require("./Task").Task;
  const PttMessage = require("./PttMessage/pttmessage").PttMessage;
  const SDPTransform = require("./PttMessage/sdptransform").SDPTransform;
  const CallHeader = require("./PttMessage/callheader").CallHeader;
  const Util = require("./Utils").Util;
  const sdsConfig = require("./SDS/sdsconfig");

  const SDSFormat = require("./SDS/sdsFormat").SDSFormat;
  const base64 = require("base64-arraybuffer");
  const Buffer = require("buffer").Buffer;
  const Recordings = require("./Recordings").Recordings;
  const RegisterProcessStates = require("./RegisterProcessStates").RegisterProcessStates;

  const PTT_STATUS = {
    none: 1,
    requested: 2,
    pending: 3,
  };
  const MDM = require("./MDM").MDM;

  class MCXClient extends JsSIP.UA {
    // static instance = null;
    // static alreadyHasInstance = false;
    // static createInstance(config) {
    //   var object = new MCXClient(config);
    //   return object;
    // }
    // static getAlreadyHasInstance() {
    //   return MCXClient.alreadyHasInstance;
    // }
    // static getInstance(config) {
    //   MCXClient.alreadyHasInstance = true;
    //   if (!MCXClient.instance) {
    //     MCXClient.alreadyHasInstance = false;
    //     MCXClient.instance = MCXClient.createInstance(config);
    //   }
    //   return MCXClient.instance;
    // }

    constructor(config) {
      config.register = false;

      super(config);
      this.appContext = null;
      console.log("mcxclient constructor called", config);
      this.config = config;
      this.version = "2.0.1";
      this.httpClientTimeout = config.httpClientTimeout ? Number(config.httpClientTimeout) : 20000;
      this.udpdgram = null;
      this.globalContactList = [];
      this.globalUserRegList = new Set();
      this.mcxUserProfile = null;
      this.store = new Store();
      this._registrator = new Registrator(config, this);
      this._registrator.setExtraHeaders([
        `Content-Type: ` + config.contentType,
        // `P-Preferred-Identity: <${this._registrator._from_uri}>`,
      ]);
      instance_id = this._registrator.instance_id;

      this.pbxDomain = config.pbxDomain
        ? config.pbxDomain
        : "consortpbxdomain.org";
      this.isManualCallHandle = config.isManualCallHandle
        ? config.isManualCallHandle
        : false;
      this.domainsConfig = config.domainsConfig ? config.domainsConfig : null;
      this.userDomain = "";
      this.userDomain = "";
      this.tenantDomain = "";
      this.userAgent = config.user_agent ? config.user_agent : "";
      this.participatingServer = this._registrator._registrar;
      this.callOnHold = config.callOnHold ? config.callOnHold : false;
      this.totalCountIdex = 0;
      this.preEstSessionCreationRunning = false;
      this.configPreEstSessionCount =
        config.totalPreEstSessionCount || config.totalPreEstSessionCount == -1
          ? config.totalPreEstSessionCount
          : 0;
      this.sessionBandwidthInKbps = config.sessionBandwidthInKbps
        ? config.sessionBandwidthInKbps
        : 5;
      this.isNoiseSuppression =
        config.isNoiseSuppression == false ? false : true;
      // this.privatePreEstCallsAllowed = config.privatePreEstCallsAllowed
      this.preEstCallTypeAllowed = config.preEstCallTypeAllowed;
      this.os = config.os ? config.os : "";
      this.isDebugModeEnable = false;
      this.assignFaList = [];
      this.ongoingCalls = [];
      this.callforwardingArr = [];
      this.callforwardingNoAnsTimer = null;
      this.ongoingPreestablishedCallSessions = [];
      this.mcpcconnectTimerMaps = {};
      this.pendingSDS = [];
      this.assignGroupList = [];
      this.pendingPTT = {};
      this.loginCompleteEventSent = false;
      this.userData = null;
      this.userLoginXCAP = null;
      this.tokenRefresher = null;
      this.subscriptionCallId = "";
      this.noOfLoginAttempt = 2;
      this.releasePTTCurrentCall = null;
      this.preEstCallInitiatedAt = 0;
      this.isAudiPermissionGranted = config.isAudiPermissionGranted
        ? true
        : false;
      this.lastResult = null;
      this.callStats = [];

      /* this.registrator().setExtraHeaders([
              `X-MCX-Access-Token: ${token}`,
              `tetraid : true`,
            ]); */

      this.registerprocessSate = new RegisterProcessStates(this);
      this.delegates = new Delegates();
      this.idmc = new IDMC();
      this.gmc = new GMC(this);
      this.sdptransform = new SDPTransform();
      this.mcpttBodyUtils = new Util();
      this.callheader = new CallHeader(this.mcpttBodyUtils);
      this.pttMsgClient = new PttMessage();
      this.pttMsgClient.init(5001, 6000);
      this.sdsformat = new SDSFormat(this.mcpttBodyUtils);
      this.recordings = new Recordings();
      this.mdm = new MDM();
      this.admin = new Admin(this);
      this.task = new Task(this);

      this._autoActivateFAList = new Set();
      this.faActivationList = null;
      this.affiliatedGroupList = null;
      this.cmc = new CMC(this);
      this.faMc = new FAManager(this);
      this.publisher = new FAPublisher(this);
      this.admin = new Admin(this);
      this.task = new Task(this);
      this.storageUEId = "0";
      this.byeReqArr = [];

      this.setFAMClistener();
      this.setCMClistener();

      this._receiveRequest = this.receiveRequest;
      this.callInitiedTime = 0;
      this.timeDiffFromCallInitiatedAtApp = 0;
      this.receiveRequest = (request) => {
        this._HandleNewReceiveRequest(request);
      };

      this.on("newMessage", (req) => {
        console.log("newMessage()");
        this._HandleNewMessage(req);
      });

      this.on("newRTCSession", (data) => {
        this._HandleNewRTCSession(data, false);
      });

      this.on("disconnected", (error, code, reason) => {
        console.log("socket status disconnected........", code, reason);
        this.updateRegistrationProcess("TRANSPORT", stateType.TRANSPORT_STATE.Disconnected);
      });

      this.on("connecting", (error, code, reason) => {
        console.log("socket status connecting........", code, reason);
        this.updateRegistrationProcess("TRANSPORT", stateType.TRANSPORT_STATE.Connecting);
      });

      this.start();

      this.on("connected", (error, code, reason) => {
        console.log("socket connected........", code, reason);
        this.sendLogsToApp("connected", {
          "reason": "Transport connected"
        });
        this.updateRegistrationProcess("TRANSPORT", stateType.TRANSPORT_STATE.Connected);
      });

      this.registerBody = "";

      this.on("unregistered", (data) => {
        console.log("mcxclient unregistered..", data, this.registerprocessSate.getRegistrationState());
        this.sendLogsToApp("onUnregistered", {
          "reason": "Due to timeout or transport error"
        });
        if (this.registerprocessSate.getRegistrationState() !== stateType.REGISTRATION_STATE.Deregistered) {
          console.log("onUnregistered event sent");
          this.emit("onUnregistered", true);
        }
        this.updateRegistrationProcess("REGISTER", stateType.REGISTRATION_STATE.Deregistered, data);
      });

      this.on("registered", (data) => {
        console.log("mcxclient registeration...", this.loginCompleteEventSent, this.registerprocessSate.getRegistrationState(), data);
        if (this.loginCompleteEventSent) {
          if (this.registerprocessSate.getRegistrationState() !== stateType.REGISTRATION_STATE.Registered) {
            console.log("onRegister event sent...");
            this.emit("onRegister");
            this.sendByeReq();
          }
        } else {
          if (this.getUserProfileRole() === "Admin") {
            let userLogin = {
              userData: this.userData,
            };
            this.loginCompleteEventSent = true;
            this.emit("LOGIN_COMPLETE", userLogin);
          }
        }
        this.updateRegistrationProcess("REGISTER", stateType.REGISTRATION_STATE.Registered, data);
      });
    }

    isMCXRegistered() {
      return this.registerprocessSate.getRegistrationState() === stateType.REGISTRATION_STATE.Registered;
    }

    getVersion() {
      return this.version;
    }

    getHttpClientTimeout() {
      return this.httpClientTimeout;
    }

    getMcxUserProfile() {
      return this.mcxUserProfile;
    }

    startXcapProcess() {
      console.log("startXcapProcess()");
      if (this.cmc && this.cmc.subscriber) {
        this._FATimer && clearTimeout(this._FATimer);
        this._FATimer = null;
        this.cmc.subscriber.subscribe();
      }
    }

    startFetchingProfile() {
      console.log("startFetchingProfile()");
      if (this.cmc && this.cmc.subscriber) {
        this.cmc.subscriber.fetchCmsProfile();
      }
    }

    startPresenceProcess() {
      console.log("startPresenceProcess()");
      if (this.faMc && this.faMc.subscriber) {
        this.faMc.subscriber.subscribe();
      }
    }

    startRegistrationProcess() {
      console.log("startRegistrationProcess()");
      this._registrator.register(this.registerBody, false, null);
    }

    printRegistrationProcessStates() {
      if (this.registerprocessSate) {
        console.log("TRANSPORT_STATE ", this.registerprocessSate.getTransportState());
        console.log("LOGIN_STATE ", this.registerprocessSate.getLoginState());
        console.log("REGISTER_STATE ", this.registerprocessSate.getRegistrationState());
        console.log("XCAP_STATE ", this.registerprocessSate.getXacpState());
        console.log("PRESENCE_STATE ", this.registerprocessSate.getPresenceState());
      }
    }

    checkPreEstSessionInRegInterval() {
      //this.printRegistrationProcessStates();
      //check for preEst session if any
      if (this.isManualCallHandle) {
        const _this = this;
        if (this.appContext) {
          if (typeof this.appContext.checkPermissionAudio === "function") {
            this.appContext.checkPermissionAudio((granted) => {
              if (granted) {
                console.log("checkPreEstSessionInRegInterval() Audio permission granted")
                _this.initiateSessionCreation();
              } else {
                console.log("checkPreEstSessionInRegInterval() Audio permission rejected")
              }
            })
          } else {
            this.initiateSessionCreation();
          }
        } else {
          this.initiateSessionCreation();
        }
      } else {
        this.initiateSessionCreation();
      }
    }

    initiateSessionCreation() {
      if (this.ongoingPreestablishedCallSessions) {
        console.log("PreEstSession Count ", this.ongoingPreestablishedCallSessions.length);
      }
      if (
        this.ongoingPreestablishedCallSessions &&
        this.ongoingPreestablishedCallSessions.length <
        this.configPreEstSessionCount
      ) {
        if (this.loginCompleteEventSent) {
          this.createPreEstSession(this.configPreEstSessionCount);
        } else {
          console.log("createPreEstSession() not called due to ", this.loginCompleteEventSent);
        }
      }
    }

    getToken() {
      return this._currentToken
    }

    setDebudMode(isDebugMode) {
      this.isDebugModeEnable = isDebugMode;
    }

    getActiveToken() {
      return this._currentToken;
    }

    removeAllPreEstSession() {
      try {
        this.preEstSessionCreationRunning = false;
        try {
          this.ongoingPreestablishedCallSessions.every((callObj) => {
            // console.log("removeAllPreEstSession.........", callObj);
            callObj.session.terminate();
            return true;
          });
        } catch (err) {
          console.log("removeAllPreEstSession session.terminate(); ", err);
        }

        this.ongoingPreestablishedCallSessions = [];
        // this.emit(
        //   "PRE_EST_COUNT",
        //   this.ongoingPreestablishedCallSessions.length +
        //   " " +
        //   this.ongoingCalls.length +
        //   " " +
        //   this.timeDiffFromCallInitiatedAtApp
        // );
      } catch (err) {
        console.log("removeAllPreEstSession err", err);
      }
    }

    rejectPreEstInviteCallIfNotAnswer(currentCall) {
      let notAnswered = false;
      let data = currentCall.callData;
      if (
        data.callType == "DUPLEX_INDIVIDUAL_CALL" ||
        data.callType == "SIMPLEX_INDIVIDUAL_HOOK_CALL"
      ) {
        console.log("rejectPreEstInviteCallIfNotAnswer disconnect..... 1");
        if (
          currentCall.session &&
          (currentCall.session._status <
            currentCall.session.C.STATUS_ANSWERED ||
            currentCall.callState == "started")
        ) {
          notAnswered = true;
          try {
            if (currentCall.request) {
              console.log("disconnect..... request");
            }
            console.log("rejectPreEstInviteCallIfNotAnswer disconnect..... 2");
            currentCall.session._rejectForReinvite(currentCall.request, {
              cause: JsSIP.C.causes.CANCELED,
              // disableSessionExpireHeader: true,
            });
          } catch (err) {
            console.log("rejectPreEstInviteCallIfNotAnswer disconnect..... 4");
            console.log("_rejectForReinvite error", err);
          }
        }
      }
      return notAnswered;
    }

    handlePreEstSessionState(data) {
      console.log("handlePreEstSessionState()");
      let isCurrentPreEstSessionAvailable = true;
      let mcpcRecvTime = new Date().getTime();
      try {
        if (!data) {
          return;
        }
        this.ongoingPreestablishedCallSessions.every((callObj) => {
          if (callObj.callId == data.callId) {
            isCurrentPreEstSessionAvailable = false;
            return false;
          }
          return true;
        });
        this.ongoingCalls.every((callObj) => {
          if (callObj.mcpcConnectRecvTime) {
            mcpcRecvTime = callObj.mcpcConnectRecvTime;
            return false;
          }
          return true;
        });
        let currentTime = new Date().getTime();
        if (
          isCurrentPreEstSessionAvailable &&
          currentTime - mcpcRecvTime > 60 * 1000
        ) {
          console.log("handlePreEstSessionState() removed", data.callId);
          this.removePreEstSession(data);
        }
      } catch (err) {
        console.log("handlePreEstSessionState err", err);
      }
    }

    maintainPreEstSessionCount(totalSessionCount) {
      let itemTobeRemoved =
        this.ongoingPreestablishedCallSessions.length - totalSessionCount;
      if (itemTobeRemoved > 0) {
        let i = 0;

        while (i < itemTobeRemoved) {
          try {
            this.ongoingPreestablishedCallSessions[i].session.terminate();
          } catch (err) { }
          this.ongoingPreestablishedCallSessions.splice(i, 1);
          i++;
        }

        // this.emit(
        //   "PRE_EST_COUNT",
        //   this.ongoingPreestablishedCallSessions.length +
        //   " ~ " +
        //   this.ongoingCalls.length +
        //   " " +
        //   this.timeDiffFromCallInitiatedAtApp
        // );
      }
    }

    clearLocalAudioStreamForTRCPLinux(data) {
      try {
        if (this.isTRCPRCPUserAgent() && !this.isAndroidOS()) {
          if (data) {
            let tracks = data.callData.localAudio.getTracks();
            if (tracks) {
              tracks.forEach((track) => {
                if (track) {
                  track.stop();
                }
              });
            }
          }
        }
      } catch (err) { }
    }

    updateIceStateForPreEstSession(data, iceState) {
      console.log("updateIceStateForPreEstSession() ", iceState);
      this.ongoingPreestablishedCallSessions.every((callObj) => {
        if (
          callObj.preEstSessionUri == data.preEstSessionUri
        ) {
          callObj.iceProgressState = iceState;
          return false;
        }
        return true;
      });
    }

    removePreEstSession(data, isClearSessionOnly = false) {
      console.log("removePreEstSession() ", data.preEstSessionUri);
      this.removeOnGoingArrayCall(data);
      try {
        if (!isClearSessionOnly) {
          data.session.terminate();
          this.clearLocalAudioStreamForTRCPLinux(data);
        }
      } catch (err) { }

      let indexPreEstCall = this.ongoingPreestablishedCallSessions.findIndex(
        (cc) => cc.preEstSessionUri === data.preEstSessionUri
      );
      if (indexPreEstCall >= 0) {
        this.ongoingPreestablishedCallSessions.splice(indexPreEstCall, 1);
      }
      console.log("removePreEstSession() indexPreEstCall ", indexPreEstCall);

      // this.emit(
      //   "PRE_EST_COUNT",
      //   this.ongoingPreestablishedCallSessions.length +
      //   " # " +
      //   this.ongoingCalls.length +
      //   " " +
      //   this.timeDiffFromCallInitiatedAtApp
      // );
    }


    releasePreEstSession(data) {
      console.log("releasePreEstSession()");
      let isPreEstSessionAvailable = false;
      this.removeOnGoingArrayCall(data);
      this.ongoingPreestablishedCallSessions.every((callObj) => {
        if (
          callObj.preEstSessionUri == data.preEstSessionUri &&
          callObj.occupied
        ) {
          isPreEstSessionAvailable = true;
          callObj.occupied = false;
          return false;
        }
        return true;
      });

      if (this.ongoingPreestablishedCallSessions && this.ongoingPreestablishedCallSessions.length > 0) {
        this.ongoingPreestablishedCallSessions.push(
          this.ongoingPreestablishedCallSessions.shift()
        ); //Shift first session to the last on release of session.
      }

      // this.emit(
      //   "PRE_EST_COUNT",
      //   this.ongoingPreestablishedCallSessions.length +
      //   " $ " +
      //   this.ongoingCalls.length +
      //   " " +
      //   this.timeDiffFromCallInitiatedAtApp
      // );

      return isPreEstSessionAvailable;
    }

    disconnectAllOngoingCalls() {
      console.log("disconnectAllOngoingCalls() ");
      for (let callObj of this.ongoingCalls) {
        if (callObj.callData) {
          this.sendReferRequest(callObj.callData, "DISCONNECTED");
        }
      }
    }

    isCallIsRunningOnSameSession(preEstSessionUri) {
      let indexOfCurrentCall = this.ongoingCalls.findIndex(
        (cc) =>
          (cc.callData &&
            cc.callData.preEstSessionUri &&
            cc.callData.preEstSessionUri === preEstSessionUri) ||
          (cc.preEstSessionUri &&
            cc.preEstSessionUri === preEstSessionUri)
      );
      if (indexOfCurrentCall >= 0) {
        return true;
      }
      return false;
    }

    updateSessionStateForPreEstSessionURI(preEstSessionUri, state) {
      console.log("updateSessionStateForPreEstSessionURI() ");
      this.ongoingCalls.every((callObj) => {
        if (callObj.preEstSessionUri == preEstSessionUri) {
          callObj.sessionState = state;
          return false;
        }
        return true;
      });
    }

    getSessionStateForPreEstSessionURI(preEstSessionUri) {
      let state = "";
      this.ongoingCalls.every((callObj) => {
        if (callObj.preEstSessionUri == preEstSessionUri) {
          state = callObj.sessionState;
          return false;
        }
        return true;
      });
      console.log("getSessionStateForPreEstSessionURI() ", state);
      return state;
    }

    removeAllMcpcConnectTimersForRefer() {
      if (this.mcpcconnectTimerMaps) {
        for (const key in this.mcpcconnectTimerMaps) {
          let valTimer = this.mcpcconnectTimerMaps[key];
          if (valTimer) {
            clearTimeout(valTimer);
            valTimer = null;
          }
          delete this.mcpcconnectTimerMaps[key];
        }
      }
      console.log("removeAllMcpcConnectTimersForRefer() ", this.mcpcconnectTimerMaps);
    }

    removeMcpcConnectTimersForRefer(preEstSessionUri) {
      console.log("removeMcpcConnectTimersForRefer() ", preEstSessionUri);
      if (preEstSessionUri && this.mcpcconnectTimerMaps) {
        for (const key in this.mcpcconnectTimerMaps) {
          if (this.removeSipMsgInSessionURI(key) == this.removeSipMsgInSessionURI(preEstSessionUri)) {
            let valTimer = this.mcpcconnectTimerMaps[key];
            if (valTimer) {
              clearTimeout(valTimer);
              valTimer = null;
            }
            delete this.mcpcconnectTimerMaps[key];
          }
        }
      }
    }

    removeSipMsgInSessionURI(preEstSessionUri) {
      let sessionUri = preEstSessionUri;
      if (sessionUri) {
        if (sessionUri.includes("sip:")) {
          sessionUri = sessionUri.replace("sip:", "");
        }
      }
      return sessionUri;
    }

    isPreEstCallAnswered(callId) {
      let isCallAnswerSent = false;
      this.ongoingCalls.every((callObj) => {
        if (callObj.preEstSessionUri == callId || callObj.callId == callId) {
          isCallAnswerSent = callObj.isCallAnswerSent;
          return false;
        }
        return true;
      });
      console.log("isPreEstCallAnswered()", isCallAnswerSent);
      return isCallAnswerSent;
    }

    preEstCallAnswereEventSent(preEstSessionUri) {
      let callObject = {};
      this.ongoingCalls.every((callObj) => {
        if (callObj.preEstSessionUri == preEstSessionUri) {
          callObj.isCallAnswerSent = true;
          callObject = callObj;
          return false;
        }
        return true;
      });
      return callObject;
    }

    isOnDemandCallAnswered(callId) {
      let isCallAnswerSent = false;
      this.ongoingCalls.every((callObj) => {
        if (callObj.callData && callObj.callData.callId == callId) {
          isCallAnswerSent = callObj.isCallAnswerSent;
          return false;
        }
        return true;
      });
      console.log("isOnDemandCallAnswered()", isCallAnswerSent);
      return isCallAnswerSent;
    }

    onDemandCallAnswereEventSent(callId) {
      this.ongoingCalls.every((callObj) => {
        if (callObj.callData && callObj.callData.callId == callId) {
          callObj.isCallAnswerSent = true;
          return false;
        }
        return true;
      });
      console.log("onDemandCallAnswereEventSent()");
    }

    pttQueueOnOngoingCall(callId, isPTTQueue) {
      this.ongoingCalls.every((callObj) => {
        if (callObj.callData && (callObj.preEstSessionUri == callId || callObj.callData.callId == callId)) {
          callObj.isPTTQueue = isPTTQueue;
          return false;
        }
        return true;
      });
      console.log("pttQueueOnOngoingCall()..");
    }

    isCallAlreadyGetPttEvent(callId) {
      let callObject = null;
      this.ongoingCalls.every((callObj) => {
        if (callObj.callData && (callObj.preEstSessionUri == callId || callObj.callData.callId == callId) && callObj.isPTTQueue) {
          callObject = callObj.callData;
          return false;
        }
        return true;
      });
      return callObject;
    }

    isPreEstCall(preEstSessionUri) {
      let isPreEstSession = false;
      this.ongoingPreestablishedCallSessions.every((callObj) => {
        if (
          callObj.preEstSessionUri == preEstSessionUri ||
          callObj.callId == preEstSessionUri
        ) {
          isPreEstSession = true;
          return false;
        }
        return true;
      });
      return isPreEstSession;
    }

    getPreEstSessionLocalAudio(callId) {
      let localAudio = {};
      this.ongoingCalls.every((callObj) => {
        if (
          callObj.preEstSessionUri == callId ||
          callObj.callData.callId == callId
        ) {
          localAudio = callObj.callData.localAudio;
          return false;
        }
        return true;
      });

      return localAudio;
    }

    getCurrentOnGoingPreEstCall(data) {
      let callObj1 = {};
      this.ongoingCalls.every((callObj) => {
        if (
          callObj.preEstSessionUri == data.preEstSessionUri ||
          callObj.callData.callId == data.callId
        ) {
          callObj1 = callObj;
          return false;
        }
        return true;
      });
      return callObj1;
    }

    occupyPreEstCall(data) {
      let callObj1 = {};
      this.ongoingPreestablishedCallSessions.every((callObj) => {
        if (data && callObj.preEstSessionUri == data.preEstSessionUri) {
          callObj.occupied = true;
          callObj.preEstCall = true;
          callObj1.callId = callObj.callId;
          callObj1.session = callObj.session;
          callObj1.preEstCall = callObj.preEstCall;
          callObj1.preEstSessionUri = callObj.preEstSessionUri;
          callObj1.occupied = callObj.occupied;
          callObj1.callData = data;
          callObj1.callData.session = callObj.session;
          callObj1.callData.preEstSessionUri = callObj.preEstSessionUri;
          callObj1.callData.preEstCall = callObj.preEstCall;
          callObj1.callData.occupied = callObj.occupied;
          if (callObj.localAudio) {
            callObj1.callData.localAudio = callObj.localAudio;
          }
          if (callObj.stream) {
            callObj1.callData.stream = callObj.stream;
          }
          if (callObj.track) {
            callObj1.callData.track = callObj.track;
          }
          return false;
        }
        return true;
      });
      // console.log("occupyPreEstCall", callObj1, data);
      return callObj1;
    }

    getAvailablePreEstSession(data) {
      let callObj1 = {};
      this.ongoingCalls.every((callObj) => {
        if (callObj.preEstSessionUri == data.preEstSessionUri) {
          callObj1 = callObj;
          if (
            callObj1.callData &&
            (!callObj1.callData.callType || callObj1.callData.callType == "")
          ) {
            callObj1.callData = callObj1.callData ? callObj1.callData : data;
            callObj1.callData.callPriority = callObj.callData.callPriority;
            callObj1.callData.callId = callObj.callId;
            callObj1.callData.preEstCall = callObj.preEstCall;
            callObj1.callData.preEstSessionUri = callObj.preEstSessionUri;
            callObj1.callData.preEstCall = callObj.preEstCall;
            callObj1.callData.occupied = callObj.occupied;
            callObj1.callData.localAudio = callObj.localAudio;
            if (callObj.stream) {
              callObj1.callData.stream = callObj.stream;
            }
            if (callObj.track) {
              callObj1.callData.track = callObj.track;
            }
          }
          callObj1.mcpcRecvTime = new Date().getTime();
          return false;
        }
        return true;
      });

      this.ongoingPreestablishedCallSessions.every((callObj) => {
        if (
          !callObj.occupied &&
          callObj.preEstSessionUri == data.preEstSessionUri
        ) {
          // console.log(
          //   "getAvailablePreEstSession..... inside ongoingPreestablishedCallSessions callss"
          // );
          callObj.occupied = true;
          callObj.preEstCall = true;
          callObj1.callId = callObj.callId;
          callObj1.session = callObj.session;
          callObj1.preEstCall = callObj.preEstCall;
          callObj1.preEstSessionUri = callObj.preEstSessionUri;
          callObj1.occupied = callObj.occupied;
          callObj1.callData = callObj1.callData ? callObj1.callData : data;
          if (data.callPriority) {
            callObj1.callData.callPriority = data.callPriority;
          }
          callObj1.callData.callId = callObj.callId;
          callObj1.callData.preEstCall = callObj.preEstCall;
          callObj1.callData.preEstSessionUri = callObj.preEstSessionUri;
          callObj1.callData.preEstCall = callObj.preEstCall;
          callObj1.callData.occupied = callObj.occupied;
          callObj1.callData.localAudio = callObj.localAudio;
          if (callObj.stream) {
            callObj1.callData.stream = callObj.stream;
          }
          if (callObj.track) {
            callObj1.callData.track = callObj.track;
          }
          callObj1.mcpcRecvTime = new Date().getTime();
          return false;
        }

        return true;
      });
      if (!callObj1 || !callObj1.preEstSessionUri) {
        callObj1 = this.occupyPreEstCall(data);
        callObj1.mcpcRecvTime = new Date().getTime();
      }
      console.log("getAvailablePreEstSession", callObj1.preEstSessionUri);
      return callObj1;
    }

    getFreePreEstSession() {
      if (
        this.ongoingPreestablishedCallSessions &&
        this.ongoingPreestablishedCallSessions.length > 0
      ) {
        let filteredPreSessions = this.ongoingPreestablishedCallSessions.filter(
          (callObj) => callObj.occupied == false
        );
        return filteredPreSessions.length;
      }
      return 0;
    }

    initiatePreEstCall(data, callForwardData = false) {
      let callObj1 = {};
      this.preEstCallInitiatedAt = new Date().getTime();
      this.ongoingPreestablishedCallSessions.every((callObj) => {
        let updatedCall = false;
        if (callForwardData) {
          if (data.callId == callObj.callId) {
            updatedCall = true;
          }
        } else {
          if (callObj.iceProgressState) {
            updatedCall = false;
          } else if (!callObj.occupied) {
            updatedCall = true;
          }
        }
        if (updatedCall) {
          callObj.occupied = true;
          callObj.callState = "started";
          callObj.preEstCall = true;
          callObj.callDirection = "OUTGOING";
          callObj1 = { ...data, ...callObj };
          let currentCall = {
            callData: callObj1,
            preEstCall: true,
            session: callObj1.session,
            callId: callObj.callId,
            preEstSessionUri: callObj.preEstSessionUri,
            callState: "started",
            callDirection: "OUTGOING",
            sessionState: "setup"
          };

          this.ongoingCalls.push(currentCall);

          console.log("sendRefer Request call...", callObj1.callId);
          this.sendReferRequest({ ...callObj1 });

          if (!this.isCallTypeManual(callObj1.callType)) {
            this.updateSdpOnPTT(this.mcptt_uri, { ...callObj1 });
          }

          return false;
        }
        return true;
      });
      return callObj1;
    }

    sendReinviteForPriorityChange(data, priority, callData = null) {
      console.log("sendReinviteForPriorityChange() ", priority, data);
      let call = callData;
      if (!call) {
        call = this.isSameGroupCallRunning(data);
      }
      if (call) {
        console.log("sendReinviteForPriorityChange found");
        let currentCall = this.getCurrentOnGoingPreEstCall(call);
        if (currentCall.preEstCall) {
          console.log(
            "sendReinviteForPriorityChange with preEst",
            priority
          );

          let callInfo = currentCall.callData;
          callInfo.callPriority = priority;

          let callObj = { ...callInfo };
          callObj.communicationType = "CALL_ACTION";
          callObj.callActionType = "CALL_UPGRADE";
          this.emit("CALL_ACTION", callObj);

          if (!callInfo.session) {
            callInfo.session = currentCall.session;
          }
          this.sendReferRequest(callInfo, "SEND_REINVITE");
        } else {
          console.log(
            "sendReinviteForPriorityChange with onDemand",
            priority
          );

          let callInfo = currentCall.callData;
          callInfo.callPriority = priority;
          let isEmergency = priority == 15 ? true : false;

          let callObj = { ...callInfo };
          callObj.communicationType = "CALL_ACTION";
          callObj.callActionType = "CALL_UPGRADE";
          this.emit("CALL_ACTION", callObj);

          currentCall.session._sendReinviteOnDemand({
            isEmergency,
            isPreEstCall: false,
          });
        }
        return call.callId;
      } else {
        console.log("sendReinviteForPriorityChange not found");
      }
      return false;
    }

    preEstCallLog(message, info = "_") {
      // PreEst Call Time................
      let currentTime = new Date().getTime();
      let timeDifference = currentTime - this.preEstCallInitiatedAt;
      console.log(
        "PreEst Call Time................  " + message,
        currentTime,
        timeDifference,
        info
      );
    }

    sendReferRequest(callObject1, requestType1) {
      console.log("sendReferRequest() ", callObject1, requestType1);
      let callObject = { ...callObject1 };
      let requestType = requestType1;
      let _this = this;

      let callerUri = _this.mcptt_uri;

      if (!requestType || requestType == "SEND_REINVITE") {
        let callType = "private";
        let answerMode = "Manual";

        let contact = _this._registrator._contact;

        let sessionType = "private";

        if (callObject.callType.toLowerCase().includes("group")) {
          callType = "prearranged";
          sessionType = "prearranged";
          answerMode = "Auto";
        }
        if (callObject.callType == "SIMPLEX_INDIVIDUAL_DIRECT_CALL") {
          answerMode = "Auto";
        }
        if (callObject.isFACall) {
          callType = "first-to-answer";
          sessionType = "first-to-answer";
        } else {
          callObject.fromId = callerUri;
        }
        //getHeadersAndBody(callObject, mangledSdp, callType, answerMode, contact, To, sessionType)

        let isPTTCall =
          callObject.callType == "DUPLEX_INDIVIDUAL_CALL" ? false : true;

        let isBroadcastCall =
          callObject.callType == "SIMPLEX_BROADCAST_GROUP_CALL" ? true : false;

        let isEmgCall =
          callObject.callPriority && parseInt(callObject.callPriority) == 15
            ? true
            : false;

        /*if (callObject.pbxUser) {
          if (callObject.pbxDomain) {
            callObject.toId = callObject.toId + "@" + callObject.pbxDomain;
          } else {
            callObject.toId = callObject.toId + "@" + "pbx";
          }
        }*/

        // getCallObjFromResponse.....

        let preEstSessionUri = callObject.preEstSessionUri; // from pre est session
        let mangledSdp = callObject.session.connection.localDescription.sdp; // from pre est session
        let contentId = JsSIP.Utils.newUUID() + "@consort.org ";
        callObject.contact = contact;
        callObject.preferredIdentity = callerUri;

        callObject.preEstSessionUri = preEstSessionUri;
        callObject.contentId = contentId;
        callObject.answerMode = answerMode;
        callObject.mangledSdp = mangledSdp;
        callObject.callType = callType;
        callObject.mcpttUri = callerUri;
        callObject.sessionType = sessionType;
        callObject.isPTTCall = isPTTCall;
        callObject.isBroadcastCall = isBroadcastCall;
        callObject.isEmgCall = isEmgCall;
        callObject.isPTTCall = isPTTCall;

        console.log("sendReferRequest() 1 ");

        let newRequest = _this.callheader.getPreEstHeadersAndBody(callObject);
        console.log("sendReferRequest() 2 ", newRequest);
        let eventHandlers = {
          // "multipart/mixed; boundary=consort-boundary"
          contentType:
            "application/resource-lists+xml; boundary=consort-boundary",
          extraHeaders: newRequest.headers,
          // disableSessionExpireHeader: true,
          succeeded: function (data) {
            console.log("PreEst Refer sent ", data);
          },
          failed: function (data) {
            console.log("Refer FAILED............... 0", data);

            callObject.communicationType = "CALL_ACTION";
            callObject.callActionType = "DISCONNECTED";
            _this.preEstSessionCreationRunning = false;
            if (!data || data.status_code == 404) {
              _this.removePreEstSession(callObject);
            } else if (data && data.status_code == 486) {
            }
            _this.releasePreEstSession(callObject);
            _this.emit(callObject.communicationType, callObject);
          },
        };
        let message = newRequest.body;

        preEstSessionUri = preEstSessionUri
          ? preEstSessionUri.replace("<", "").replace(">", "")
          : preEstSessionUri;
        if (!preEstSessionUri.includes("sip:")) {
          preEstSessionUri = "sip:" + preEstSessionUri;
        }

        let currentTime = new Date().getTime();

        _this.timeDiffFromCallInitiatedAtApp =
          currentTime - _this.callInitiedTime;

        if (requestType == "SEND_REINVITE") {
          eventHandlers.isEmergency = isEmgCall;
          eventHandlers.isPreEstCall = true;
          console.log("SEND_REINVITE PreEst..........4");
          callObject.session._sendReinviteOnDemand(eventHandlers, message);
        } else {
          const msgHandle = _this.sendRefer(
            preEstSessionUri,
            message,
            eventHandlers
          );
          msgHandle.on("succeeded", (e) => {
            console.log("Refer succeeded........1");
            if (_this.getSessionStateForPreEstSessionURI(callObject.preEstSessionUri) == "setup") {
              console.log("preEst session used in refer is in setup mode");
              _this.updateSessionStateForPreEstSessionURI(callObject.preEstSessionUri, "inUse");
              let timerVal = answerMode == "Auto" ? 10 : 30;
              //Add timer for waiting mcpc connect event, if mcpc connect didn't come in 6 sec, then call should be disconnected
              let mcpcConnectTimer = setTimeout(() => {
                console.log("mcpcConnectTimer() timeout");
                //Check this refer call is present or not
                let availCall = _this.getAvailablePreEstSession(callObject);
                if (!availCall) {
                  console.log("mcpcConnectTimer() timeout session is not found");
                } else {
                  _this.sendReferRequest({ ...callObject }, "DISCONNECTED");
                  callObject.communicationType = "CALL_ACTION";
                  callObject.callActionType = "DISCONNECTED";
                  //_this.releasePreEstSession({ ...callObject });
                  callObject.callType = callObject1.callType;
                  //callObject.reason = "Didn't come answer for this call";
                  _this.emit(callObject.communicationType, callObject);
                }
              }, timerVal * 1000);
              _this.removeMcpcConnectTimersForRefer(callObject.preEstSessionUri);
              _this.mcpcconnectTimerMaps[callObject.preEstSessionUri] = mcpcConnectTimer;
            }
          });
          msgHandle.on("failed", (e) => {
            console.log("Refer FAILED...............1", e);
            _this.removeMcpcConnectTimersForRefer(callObject.preEstSessionUri);
            callObject.communicationType = "CALL_ACTION";
            callObject.callActionType = "DISCONNECTED";

            _this.preEstSessionCreationRunning = false;
            if (!e || (e.response && e.response.status_code == 404)) {
              _this.removePreEstSession({ ...callObject });
            } else if (e.response && e.response.status_code == 486) {
            } else if (e.response && e.response.status_code == 500) {
              console.log("refer 500 error");
              return;
            } else if (e.response && e.response.status_code == 487) {
              if (_this.getSessionStateForPreEstSessionURI(callObject.preEstSessionUri) !== "setup") {
                console.log("Already call is running for this presession URI ", callObject.preEstSessionUri);
                return;
              }
            }
            if (e && e.response && e.response.status_code) {
              callObject.status_code = e.response.status_code;
            }
            _this.releasePreEstSession({ ...callObject });
            callObject.callType = callObject1.callType;
            if (e && e.response && e.response.reason_phrase && e.response.status_code !== 487) {
              callObject.reason = e.response.reason_phrase;
            }
            _this.emit(callObject.communicationType, callObject);
          });
        }
      } else {
        callObject.preferredIdentity = callerUri;
        let headers = _this.callheader.getPreEstByeHeaders(callObject);

        let eventHandlers = {
          // "multipart/mixed; boundary=consort-boundary"
          // contentType:
          //   "application/resource-lists+xml; boundary=consort-boundary",
          extraHeaders: headers,
          // disableSessionExpireHeader: true,
          succeeded: function (data) {
            console.log("PreEst Refer sent ", data);
          },
          failed: function (data) {
            console.log("PreEst Refer Failed", data);
          },
        };
        let message = "";
        let preEstSessionUri = callObject.preEstSessionUri;
        if (preEstSessionUri) {
          console.log("sending bye for preEstSessionUri ", preEstSessionUri);
          preEstSessionUri = preEstSessionUri
            ? preEstSessionUri.replace("<", "").replace(">", "")
            : preEstSessionUri;
          if (preEstSessionUri && !preEstSessionUri.includes("sip:")) {
            preEstSessionUri = "sip:" + preEstSessionUri;
          }
          let currentTime = new Date().getTime();
          //let timeDifference = currentTime - _this.preEstCallInitiatedAt;

          _this.timeDiffFromCallInitiatedAtApp =
            currentTime - _this.callInitiedTime;
          // _this.emit(
          //   "PRE_EST_COUNT",
          //   _this.ongoingPreestablishedCallSessions.length +
          //   " * " +
          //   _this.ongoingCalls.length
          // );

          const msgHandle = _this.sendRefer(
            //sendMessage(
            preEstSessionUri, // "sip:mcdx@mcptt.org",
            message,
            eventHandlers
          );
        } else {
          console.log("preEstSessionUri issue for bye ", callObject);
        }

        console.log("sdp recvonl 1");

        if (callObject.session) {
          let res = callObject.session._updateDirTransForPeerConnection(
            "recvonly"
          );
          if (!res) {
            console.log("set transceiver direction with SDP --- recvonly");
            _this.sdptransform.updateLocalDescriptionStateRecieveOrSend(
              callObject.session,
              "recvonly",
              callObject
            );
          }
        }
      }
      return true;
    }

    _deInitializer() {
      this._NumRegistrationFailures = 0;
      this._currentToken = null;
      this.gmc = null;
      this.idmc = null;
      this.admin = null;
      this.task = null;
      this.cmc = null;
      this.faMc = null;
      this.publisher = null;
      this.nodeStatus = null;
      this.storageUEId = "0";
      this.delegates = null;
      // Below variable are used seperately for login step status ...
      this.userData = null;
      this._FATimer = null;
    }

    _resetVals() {
      this._NumRegistrationFailures = 0;
      this._currentToken = null;
      this.storageUEId = "0";
      this.pendingPTT = {};
      this.loginCompleteEventSent = false;
    }

    setPttUdpDgram(dgram) {
      this.udpdgram = dgram;
      this.pttMsgClient.setPttUdpDgram(dgram);
    }

    getStorageUEid() {
      return this.storageUEId;
    }

    setStorageUEid(resUETypes) {
      // console.log(
      //   "MCXClient : setStorageUEid resUETypes ============ ",
      //   resUETypes
      // );
      if (!resUETypes) {
        this._setStorageUEid("0");
        return;
      }
      let found = false;
      for (const iterator of resUETypes) {
        if (
          iterator.hasOwnProperty("ue-type") &&
          iterator["ue-type"] == this.config["user_agent"]
        ) {
          if (iterator["number-of-reg"] == 1) {
            found = true;
            this._setStorageUEid(this.config["user_agent"]);
          }
          break;
        }
      }

      if (!found) {
        this._setStorageUEid(this.config["IMPU"]);
      }
      // console.log(
      //   "MCXClient : setStorageUEid ============ ",
      //   found,
      //   resUETypes,
      //   this.storageUEId
      // );
      return this.storageUEId;
    }

    _setStorageUEid(id) {
      this.storageUEId = id;
    }

    _HandleNewReceiveRequest(request) {
      const _this = this;
      // If we've got a NOTIFY
      //remote.log("Incoming Req",request.method);
      // TypeError: undefined is not an object (evaluating 'request.headers.Event[0]')
      // console.log(
      //   "Incoming _HandleNewReceiveRequest... ",
      //   request.headers.Event[0].raw
      // );
      if (
        request.method === JsSIP.C.NOTIFY &&
        request.headers.Event &&
        request.headers.Event[0].raw === "xcap-diff" &&
        _this.cmc !== null
      ) {
        console.log("_HandleNewReceiveRequest xcap-diff response");
        _this.cmc.handleNotify(request);
      } else if (
        request.method === JsSIP.C.NOTIFY &&
        request.headers.Event &&
        request.headers.Event[0].raw === "presence" &&
        _this.faMc != null
      ) {
        //console.log('Calling FAMC handle',request);
        _this.faMc.handleNotify(request);
        _this.subscriptionCallId = request.headers["call-id"];
        //console.log("this.subscriptionCallId ", _this.subscriptionCallId);
        _this.publisher.callId = _this.subscriptionCallId;
      } else {
        if (
          request.method === JsSIP.C.NOTIFY &&
          request.headers.Event &&
          request.headers.Event[0].raw === "node-status"
        ) {
          console.log("Calling FAMC handle node-status", request);
          if (_this.nodeStatus != null) {
            _this.nodeStatus.handleNotify(request);
          } else {
            _this.nodeStatusReSubscribe();
            if (_this.nodeStatus != null) {
              _this.nodeStatus.handleNotify(request);
            }
          }
        } else {
          // Pass the request off to the built-in receiveRequest
          // eslint-disable-next-line consistent-return
          if (_this._receiveRequest) {
            return _this._receiveRequest(request);
          }
        }
      }
    }

    _HandleNewMessage(req) {
      const _this = this;
      let body = req.request.body;
      //console.log("SDS Msg messageId............. ", body);
      //remote.log("Message: ", req);

      if (req && req.originator === "remote" && body) {
        try {
          if (body.includes("group-sds") || body.includes("one-to-one-sds")) {
            let boundary = req.request.getHeader("content-type");
            boundary = boundary.substring(boundary.search("=") + 1);
            const multipartBody = body.split(`--${boundary}`);
            let mcDataInfo = null;
            let mcsignalInfo = null;
            let mcdatapayload = null;
            multipartBody.forEach((part) => {
              if (part.includes("application/vnd.3gpp.mcdata-info+xml")) {
                mcDataInfo = part;
              }
              if (part.includes("application/vnd.3gpp.mcdata-signalling")) {
                mcsignalInfo = part;
              }
              if (part.includes("application/vnd.3gpp.mcdata-payload")) {
                mcdatapayload = part;
              }
            });
            if (mcDataInfo && mcdatapayload) {
              mcDataInfo = mcDataInfo.substring(mcDataInfo.search("<"));
              let parsedXml = parser.parseFromString(mcDataInfo);
              console.log("got message parsedXml.. ", parsedXml);
              if (mcdatapayload) {
                mcdatapayload = mcdatapayload.substring(
                  mcdatapayload.search("4") + 1
                );
                console.log(
                  "got message mcdatapayload.. ",
                  mcdatapayload,
                  typeof mcdatapayload
                );
                if (mcdatapayload) {
                  let messagepayload = mcdatapayload.toString().trim();
                  messagepayload = Buffer.from(base64.decode(messagepayload));
                  if (messagepayload) {
                    console.log(
                      "got message messagepayload...",
                      messagepayload
                    );
                    let msg = sdsConfig.decode(
                      sdsConfig.config,
                      messagepayload
                    );
                    if (
                      msg &&
                      msg.decodedJson &&
                      msg.decodedJson.Payload &&
                      msg.decodedJson.Payload.value
                    ) {
                      console.log(
                        "got message final...",
                        msg,
                        msg.decodedJson.Payload.value
                      );
                      msg = msg.decodedJson.Payload.value;
                      let fromMcdataId = parsedXml
                        .getElementsByTagName("mcdata-calling-user-identity")
                        .item(0).textContent;

                      // Fetch FA Id
                      if (mcDataInfo.includes("functional-alias-URI")) {
                        let fromFaOg = parsedXml
                          .getElementsByTagName("functional-alias-URI")
                          .item(0).textContent;
                        if (fromFaOg) {
                          fromMcdataId = fromFaOg;
                        }
                        console.log(
                          "FA message FA Id ",
                          fromFaOg,
                          fromMcdataId
                        );
                      }

                      let isStatusMessage = parsedXml
                        .getElementsByTagName("status")
                        .item(0).textContent
                        ? JSON.parse(
                          parsedXml.getElementsByTagName("status").item(0)
                            .textContent
                        )
                        : false;

                      console.log(
                        "got message isStatusMessage",
                        isStatusMessage
                      );
                      const messageId = Math.random().toString(36).slice(2);

                      if (fromMcdataId && fromMcdataId.includes("sip:")) {
                        fromMcdataId = fromMcdataId.replace("sip:", "");
                        fromMcdataId = fromMcdataId.toString().trim();
                      }
                      if (isStatusMessage) {
                        let sdsType = "STATUS_MESSAGE";
                        let groupId = "";
                        if (body.includes("group-sds")) {
                          groupId = parsedXml
                            .getElementsByTagName("mcdata-calling-group-id")
                            .item(0).textContent;
                          if (groupId && groupId.includes("sip:")) {
                            groupId = groupId.replace("sip:", "");
                            groupId = groupId.toString().trim();
                          }
                          sdsType = "GROUP_STATUS_MESSAGE";
                        }
                        let statusMsg = {
                          tetraCode: msg,
                          message: msg,
                          groupId: groupId,
                          fromId: fromMcdataId,
                          toId: _this.mcptt_uri,
                          immediate: true,
                          consumedReportNeeded: false,
                          deliveryReportNeeded: false,
                          messageId: messageId,
                          creatorId: _this.mcptt_uri,
                          messageType: "STATUS",
                          fileId: "",
                          fileType: "",
                          view: false,
                          communicationType: "SDS",
                          sdsType: sdsType,
                        };
                        if (_this.task) {
                          _this.task.AlertModule.handleStatusMessage(statusMsg);
                        }
                      } else {
                        let fileId = parsedXml
                          .getElementsByTagName("fileId")
                          .item(0)
                          ? parsedXml.getElementsByTagName("fileId").item(0)
                            .textContent
                          : "";

                        let messageType = parsedXml
                          .getElementsByTagName("messageType")
                          .item(0).textContent
                          ? parsedXml
                            .getElementsByTagName("messageType")
                            .item(0).textContent
                          : "text";

                        let sdsType = "TEXT_MESSAGE";
                        let groupId = "";
                        if (body.includes("group-sds")) {
                          sdsType = "GROUP_TEXT_MESSAGE";
                          //mcdata-calling-group-id
                          groupId = parsedXml
                            .getElementsByTagName("mcdata-calling-group-id")
                            .item(0).textContent;
                          console.log(
                            "got message check group Id internal...",
                            groupId
                          );
                          if (groupId && groupId.includes("sip:")) {
                            groupId = groupId.replace("sip:", "");
                            groupId = groupId.toString().trim();
                          }
                          console.log(
                            "got message check group Id internal2...",
                            groupId
                          );
                        }
                        console.log(
                          "got message fromMcdataId.. ",
                          fromMcdataId,
                          groupId
                        );

                        let message = _this.delegates
                          .storeMessage(
                            msg,
                            fromMcdataId,
                            _this.mcptt_uri,
                            messageId,
                            sdsType,
                            groupId,
                            true,
                            fileId,
                            messageType
                          )
                        if (message) {
                          _this.emit(message.sdsType, message);
                        }
                      }
                    }
                  }
                }
              }

              /*
              let messageReportStatus = 1;
              if (mcsignalInfo) {
                let messageReportStatusPayload = mcsignalInfo.substring(
                  mcsignalInfo.search("4") + 1
                );
                if (messageReportStatusPayload) {
                  let messageReportStatusPayloadTrim = messageReportStatusPayload.toString().trim();
                  let messageReportStatusBuffer = Buffer.from(base64.decode(messageReportStatusPayloadTrim));
                  let messageReportStatusObj = sdsConfig.decode(
                    sdsConfig.config,
                    messageReportStatusBuffer
                  );
                  console.log(
                    "SDS_signaling notif obj",
                    messageReportStatusObj, mcsignalInfo, messageReportStatusPayload, messageReportStatusObj
                  );
                }
              }*/
            }
          } else if (body.includes("forward-private-call-request")) {
            let xml1 = parser.parseFromString(body);
            let calledPartyId = xml1
              .getElementsByTagName("mcptt-called-party-id")
              .item(0);
            let calledPartyUri = calledPartyId
              .getElementsByTagName("mcpttURI")
              .item(0).textContent;

            let callingUserId = xml1
              .getElementsByTagName("mcptt-calling-user-id")
              .item(0);
            let callingUserUri = callingUserId
              .getElementsByTagName("mcpttURI")
              .item(0).textContent;

            let requestUserId = xml1
              .getElementsByTagName("mcptt-request-uri")
              .item(0);
            let requestUserUri = requestUserId
              .getElementsByTagName("mcpttURI")
              .item(0).textContent;

            if (calledPartyUri.includes("sip:")) {
              calledPartyUri = calledPartyUri.replace("sip:", "");
              calledPartyUri = calledPartyUri.trim();
            }

            if (callingUserUri.includes("sip:")) {
              callingUserUri = callingUserUri.replace("sip:", "");
              callingUserUri = callingUserUri.trim();
            }

            if (requestUserUri.includes("sip:")) {
              requestUserUri = requestUserUri.replace("sip:", "");
              requestUserUri = requestUserUri.trim();
            }

            console.log(
              "calling and calledParty Id....",
              calledPartyUri,
              callingUserUri,
              requestUserUri
            );
            //fromId: "6002@mcx" A
            //toId: "6004@mcx" c
            //callType: "DUPLEX_INDIVIDUAL_CALL"

            if (_this.ongoingCalls) {
              let callForwardObj = {
                fromId: callingUserUri,
                toId: calledPartyUri,
                requestId: requestUserUri,
              };
              let callObj = null;
              let needToHold = false;
              for (const sesObj of _this.ongoingCalls) {
                console.log("sesObj...", sesObj);
                if (
                  sesObj.callData &&
                  sesObj.callData.fromId == _this.mcptt_uri &&
                  sesObj.callData.toId == requestUserUri
                ) {
                  if (sesObj.preEstCall) {
                    // for presession call
                    callObj = { ...sesObj.callData };
                    callForwardObj.initialCall = { ...sesObj };
                    needToHold = true;
                    break;
                  } else {
                    // For on-demand call
                    if (
                      sesObj.status == "initiated" ||
                      (sesObj.status == "confirmed" &&
                        sesObj.callData.callType ==
                        "SIMPLEX_INDIVIDUAL_DIRECT_CALL")
                    ) {
                      callObj = { ...sesObj.callData };
                      callForwardObj.initialCall = { ...sesObj };
                      needToHold = false;
                      break;
                    }
                  }
                }
              }
              if (callObj) {
                callObj.toId = calledPartyUri;
                console.log("callforward callObj....", callObj, callForwardObj);
                if (needToHold) {
                  let callFObj = { ...callForwardObj.initialCall };
                  callFObj.callData.callId1 = callObj.callId;
                  callFObj.callData.forwardedId = calledPartyUri;
                  callFObj.callData.toId = calledPartyUri;
                  callFObj.callData.needToHold = needToHold;
                  this.callforwardingArr.push(callFObj);
                } else {
                  this.initiateCall(callObj, false, callForwardObj);
                }
              }
            }
          } else {
            if (body.includes("SDS")) {
              const messageId = Math.random().toString(36).slice(2);
              console.log("SDS Msg messageId ", messageId);
              let message = _this.delegates
                .getSDS(body, messageId, _this.mcdata_uri);
              console.log("SDS Msg message ", message);
              if (message == null) {
                remote.log("getSDS() message object null or undefined!!");
                return;
              }
              message.messageId = messageId;
              if (
                message && (message.sdsType === "STATUS_MESSAGE" ||
                  message.sdsType === "GROUP_STATUS_MESSAGE")
              ) {
                if (_this.task) {
                  _this.task.AlertModule.handleStatusMessage(message);
                }
              } else {
                _this.emit(message.sdsType, message);
              }
            } else if (body.includes("STATE_UPDATE")) {
              console.log("SDS Msg STATE_UPDATE");
              let message = _this.delegates
                .getSDS(body, "", _this.mcptt_uri);
              if (message == null) {
                console.log("getSDS() STATE_UPDATE error");
                return;
              }
              console.log("getSDS() STATE_UPDATE ", message);
              const msg = _this.pendingSDS.find(
                (m) => m.indexId === parseInt(message.indexId)
              );
              if (msg) {
                if (msg.deliveryReportNeeded && msg.consumedReportNeeded) {
                  if (message.stateType === "READ") {
                    //_this.pendingSDS.splice(_this.pendingSDS.findIndex(msg), 1);
                  }
                } else if (
                  msg.deliveryReportNeeded &&
                  !msg.consumedReportNeeded
                ) {
                  if (message.stateType === "DELIVERED") {
                    //_this.pendingSDS.splice(_this.pendingSDS.findIndex(msg), 1);
                  }
                } else if (
                  !msg.deliveryReportNeeded &&
                  msg.consumedReportNeeded
                ) {
                  if (message.stateType === "READ") {
                    //_this.pendingSDS.splice(_this.pendingSDS.findIndex(msg), 1);
                  }
                }
                msg.stateType = message.stateType;
                message.messageId = msg.messageId;
              }
              _this.emit(message.communicationType, message);
              _this.delegates.updateSDS(message);

            } else if (body.includes("X-MCX-TETRA-ID")) {
              _this.emit("tetraRegistered", body);
            } else if (body.includes("CALL_STATE_UPDATE")) {
              let callObj = _this.delegates
                .getSDS(body, "", _this.mcptt_uri);
              if (callObj && callObj.callType === "CAD_CALL") {
                _this.emit("CAD_CALL_STATE_UPDATE", callObj);
              } else if (callObj && callObj.callType === "PATCH_CALL") {
                _this.emit("PATCH_CALL_STATE_UPDATE", callObj);
              } else if (callObj && callObj.callType === "MERGE_CALL") {
                _this.emit("MERGE_CALL_STATE_UPDATE", callObj);
              }
            } else if (
              body.includes("CALL") &&
              (body.includes("CAD_CALL") || body.includes("PATCH_CALL"))
            ) {
              let callObj = _this.delegates
                .getSDS(body, "", _this.mcptt_uri);
              console.log("getSDS() incoming CAD_CALL message ", callObj);
              if (
                callObj && callObj.communicationType === "CALL" &&
                callObj.callType === "CAD_CALL"
              ) {
                let cadCallObj = { ...callObj };
                cadCallObj.message = req.message;
                _this.emit("CAD_CALL", cadCallObj);
              } else if (
                callObj && callObj.communicationType === "CALL_ACTION" &&
                callObj.callType === "PATCH_CALL"
              ) {
                _this.emit("PATCH_CALL_STATE_UPDATE", callObj);
              } else if (
                callObj && callObj.communicationType === "CALL_ACTION" &&
                callObj.callType === "MERGE_CALL"
              ) {
                _this.emit("MERGE_CALL_STATE_UPDATE", callObj);
              } else if (
                callObj && callObj.communicationType === "CALL_ACTION" &&
                callObj.callType === "CAD_CALL"
              ) {
                callObj && callObj.stateType === "CALL_SUCCESS"
                  ? (callObj.stateType = "SUCCESS")
                  : (callObj.stateType = "FAILURE");
                _this.emit("CAD_CALL_STATE_UPDATE", callObj);
              }
            } else if (body.includes("CALL_ACTION")) {
              let action = _this.delegates.getCallAction(body);
              if (action == null) {
                console.log("CALL_ACTION() message parser error ", action);
                return
              }
              if (action.callType === "DGNA_CALL") {
                switch (action.callActionType) {
                  case "CREATED":
                  case "NOT_CREATED":
                    _this.emit("DGNA_CREATION_STATE", action);
                    break;
                  case "UPDATED":
                  case "NOT_UPDATED":
                    _this.emit("DGNA_UPDATION_STATE", action);
                    break;
                  case "DELETED":
                  case "NOT_DELETED":
                    _this.emit("DGNA_DELETION_STATE", action);
                    break;
                  default:
                    break;
                }
              } else {
                console.log("CALL_ACTION() parsed message ", action);

                if (action.callActionType === "MCPC_CONNECT") {
                  if (!action.toId || action.toId == "") {
                    // callee end
                    action.toId = this.mcptt_uri;
                  }

                  if (!action.callPriority) {
                    action.callPriority = 11;
                  }
                  this.removeMcpcConnectTimersForRefer(action.preEstSessionUri);
                  let currentCall = _this.getAvailablePreEstSession(action);
                  if (!currentCall || !currentCall.callData) {
                    console.log("didnot found current call on mcpc connect");
                    if (req.message) {
                      req.message.reject();
                    }
                    return;
                  }
                  if (req.message) {
                    req.message.accept();
                  }

                  let callState = currentCall.callState;
                  console.log(
                    "mcpcConnect callState..",
                    callState
                  );

                  if (callState == "disconnected") {
                    console.log("already got disconnect request for this call", currentCall.callData.callId);
                    return;
                  }

                  _this.muteUnmutePreEstSession(currentCall, false);
                  let callAnswerSent = _this.isPreEstCallAnswered(
                    currentCall.preEstSessionUri
                  );
                  const checkAlreadyInOngoingCall = _this.getRunningOngoingCall(
                    currentCall.callData.callId
                  );

                  if (callState != "ptt_recieved") {
                    currentCall.callState = "answered";
                  }
                  _this.addCallInOngoingCalls(currentCall);
                  _this.updateSessionStateForPreEstSessionURI(currentCall.preEstSessionUri, "matured");

                  if (
                    currentCall.callData.callType ===
                    "DUPLEX_INDIVIDUAL_CALL" &&
                    callState == "started"
                  ) {
                    console.log("mcpc_connect changing sdp to sendrecv");
                    if (currentCall.session) {
                      let res = currentCall.session._updateDirTransForPeerConnection(
                        "sendrecv"
                      );
                      if (!res) {
                        console.log(
                          "set transceiver direction with SDP --- sendrecv"
                        );
                        this.sdptransform.updateLocalDescriptionStateRecieveOrSend(
                          currentCall.session,
                          "sendrecv",
                          currentCall
                        );
                      }
                    }
                  }

                  if (!callAnswerSent && checkAlreadyInOngoingCall) {
                    _this.preEstCallAnswereEventSent(
                      currentCall.preEstSessionUri
                    );
                    let data = currentCall.callData;
                    data.callActionType = "ANSWER";
                    data.localAudio = currentCall.callData.localAudio;
                    data.stream = currentCall.callData.stream;
                    data.track = currentCall.callData.track;
                    console.log(
                      "sending answer to call after mcpc connect",
                      data.callId
                    );
                    _this.emit("ANSWER", data);
                  } else {
                    if (callAnswerSent) {
                      console.log(
                        "Answer is already sent",
                        currentCall.callData.callType,
                        currentCall.callData.callId
                      );
                    } else {
                      console.log(
                        "got new call through mcpc_connect ",
                        currentCall.callData.callType,
                        currentCall.callData.callId
                      );
                      if (
                        currentCall.callData.callType ===
                        "SIMPLEX_INDIVIDUAL_DIRECT_CALL"
                      ) {
                        //check call forward thing
                        let callForwardData = _this.checkCallForwardingForThecall();
                        console.log(
                          "callForward preestType call... ",
                          callForwardData,
                          currentCall.callData.callId
                        );
                        if (
                          callForwardData.forwardingMcptt &&
                          callForwardData.forwardingNoAnsTimer
                        ) {
                          let res = _this.sendMessageToCallForward(
                            currentCall,
                            callForwardData.forwardingMcptt,
                            callForwardData.forwardingNoAnsTimer
                          );
                          if (!res) {
                            console.log("call will not be forward");
                          }
                        } else if (callForwardData.forwardingMcptt) {
                          _this.sendMessageToCallForward(
                            currentCall,
                            callForwardData.forwardingMcptt,
                            null
                          );
                          return;
                        }
                        console.log("sending calltype after mcpc connect 1");
                        _this.emit(
                          currentCall.callData.callType,
                          currentCall.callData
                        );
                      } else {
                        console.log("sending calltype after mcpc connect 2");
                        _this.emit(
                          currentCall.callData.callType,
                          currentCall.callData
                        );
                      }
                    }
                  }
                  _this.getStats(currentCall);

                } else if (action.callActionType === "MCPC_DISCONNECT") {
                  this.removeMcpcConnectTimersForRefer(action.preEstSessionUri);
                  action.callActionType = "DISCONNECTED";
                  let currentCall = _this.getCurrentOnGoingPreEstCall(action);
                  if (!currentCall || Object.keys(currentCall).length === 0 || !currentCall.callData) {
                    console.log("no currentCall found");
                    return;
                  }
                  this.manageCallSessionOnDisconnect(currentCall, action);
                } else {
                  let matchedCall = null;
                  for (let call of _this.ongoingCalls) {
                    if (call.callData) {
                      if (call.callData.callId == action.callId) {
                        matchedCall = call;
                        call.talkingPartyId = action.talkingPartyId;
                        break;
                      }
                    }
                  }

                  if (matchedCall && matchedCall.callData) {
                    let callState = matchedCall.callState;
                    if (callState == "disconnected") {
                      console.log("already got disconnect request before push_to_talk status ", matchedCall.callData.callId);
                      return;
                    }
                    _this.updateSdpOnPTT(action.talkingPartyId, matchedCall);
                  }
                  let userHasPtt = _this.checkPTTStatus(action.talkingPartyId)

                  let needToDisconnect = false;
                  if (_this.releasePTTCurrentCall && _this.releasePTTCurrentCall.callId) {
                    if (_this.releasePTTCurrentCall.callId == action.callId && _this.releasePTTCurrentCall.status) {
                      needToDisconnect = true;
                    }
                  } else if (_this.releasePTTCurrentCall && !_this.releasePTTCurrentCall.callId && _this.releasePTTCurrentCall.status) {
                    needToDisconnect = true;
                  }
                  console.log("PTT call state", userHasPtt, _this.releasePTTCurrentCall, _this.ongoingCalls.length, needToDisconnect);
                  if (
                    _this.ongoingCalls.length <= 1 &&
                    needToDisconnect &&
                    userHasPtt
                  ) {
                    _this.releasePTTCurrentCall.status = false;
                    //release PTT
                    if (
                      !matchedCall ||
                      matchedCall.callState != "ptt_recieved"
                    ) {
                      let state = "DISCONNECTED";
                      _this.sendPTTRelease(action.callId, state);
                    } else {
                      let state = "RELEASE_PUSH_TO_TALK";
                      _this.sendPTTRelease(action.callId, state);
                    }
                  } else {
                    if (!matchedCall) {
                      return;
                    }
                    matchedCall.callData.communicationType =
                      action.communicationType;
                    matchedCall.callData.callActionType =
                      action.callActionType;
                    matchedCall.callData.talkingPartyId =
                      action.talkingPartyId;
                    matchedCall.callData.preEstSessionUri =
                      matchedCall.preEstSessionUri;
                    let currentCall = matchedCall.callData;

                    let callAnswerSent = _this.isPreEstCallAnswered(
                      currentCall.preEstSessionUri
                    );

                    let isPreEstCall = _this.isPreEstCall(
                      currentCall.preEstSessionUri
                    );

                    matchedCall.callState = "ptt_recieved";
                    _this.addCallInOngoingCalls(matchedCall);

                    let newForwardedCall = null;
                    if (_this.callforwardingArr) {
                      for (let callFObj of _this.callforwardingArr) {
                        if (callFObj.callData.callId1 == currentCall.callId) {
                          newForwardedCall = { ...callFObj.callData };
                          newForwardedCall.communicationType =
                            action.communicationType;
                          newForwardedCall.callActionType =
                            action.callActionType;
                          newForwardedCall.talkingPartyId =
                            action.talkingPartyId;
                          break;
                        }
                      }
                    }
                    if (newForwardedCall) {
                      console.log(
                        "push_to_talk call forward",
                        newForwardedCall
                      );
                      _this.emit(
                        currentCall.communicationType,
                        newForwardedCall
                      );
                    } else {
                      if (isPreEstCall) {
                        if (callAnswerSent) {
                          console.log("push_to_talk call orig preEst", currentCall.callId);
                          _this.emit(
                            currentCall.communicationType,
                            currentCall
                          );
                        } else {
                          console.log("push_to_talk before answer preEst", currentCall.callId);
                          //_this.pttQueueOnOngoingCall(currentCall.preEstSessionUri, currentCall.callActionType, true);
                        }
                      } else {
                        let callAnswerOnDemand = _this.isOnDemandCallAnswered(currentCall.callId);
                        if (callAnswerOnDemand) {
                          console.log("push_to_talk call orig onDemand", currentCall);
                          _this.emit(
                            currentCall.communicationType,
                            currentCall
                          );
                        } else {
                          console.log("push_to_talk before answer onDemand", currentCall);
                          _this.pttQueueOnOngoingCall(currentCall.callId, true);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } catch (error) {
          console.log("got message error...", error);
        }
      }
    }

    manageCallSessionOnDisconnect(currentCall, action = null) {
      console.log("manageCallSessionOnDisconnect()");
      this.muteUnmutePreEstSession(currentCall, true);
      if (currentCall.session) {
        let res = currentCall.session._updateDirTransForPeerConnection(
          "recvonly"
        );
        if (!res) {
          console.log(
            "set transceiver direction with SDP --- recvonly"
          );
          this.sdptransform.updateLocalDescriptionStateRecieveOrSend(
            currentCall.session,
            "recvonly",
            currentCall
          );
        }
      }

      let currentPreCall = currentCall.callData;
      currentPreCall.callActionType = "DISCONNECTED";
      if (this.pendingPTT[currentPreCall.callId]) {
        delete this.pendingPTT[currentPreCall.callId];
      }
      this.resetPTTCallStatus(currentPreCall.callId);
      this.rejectPreEstInviteCallIfNotAnswer(currentCall);

      let callState = currentCall.callState;
      console.log(
        "mcpcDisconnect callState..",
        callState
      );

      if (callState !== "disconnected") {
        if (action) {
          if (action.reasonCause == "6") {
            currentPreCall.reason = "No answer";
          }
          if (action.reasonCause == "13") {
            currentPreCall.reason = "User rejected the call";
          }
          if (action.reasonCause == "5") {
            currentPreCall.reason = "User is unreachable";
          }
          if (action.reasonCause == "8") {
            currentPreCall.reason = "Call is disconnected!";
          }
        }
      }
      this.releasePreEstSession(currentCall);
      //capture call log in activity log
      if (
        currentPreCall.callType === "DUPLEX_INDIVIDUAL_CALL" ||
        currentPreCall.callType === "SIMPLEX_INDIVIDUAL_HOOK_CALL"
      ) {
        if (
          currentPreCall.fromId !== this.mcptt_uri &&
          currentCall.callState == "started"
        ) {
          currentPreCall.stateType = "MISSED";
        } else {
          currentPreCall.stateType = "COMPLETED";
        }
      } else {
        currentPreCall.stateType = "COMPLETED";
      }
      currentPreCall.creatorId = this.mcptt_uri;
      this.delegates.createCallXml(currentPreCall, this);

      if (
        this.callforwardingArr &&
        this.foundInitialCallForwardWithNeedToHold(
          currentCall.callData.callId
        )
      ) {
        // make same presession call callData
        let foundNeedToHoldCallForwardData = this.updateInitialCallForwardWithNeedToHold(
          currentCall.callData.callId,
          false
        );
        if (foundNeedToHoldCallForwardData) {
          console.log("initiatePreEstCall for callForward");
          const _this = this;
          setTimeout(() => {
            _this.initiatePreEstCall(
              foundNeedToHoldCallForwardData,
              true
            );
          }, 30);
        } else {
          this.removeCallForwardingArrayCall(
            currentCall.callData.callId
          );
          if (action) {
            this.emit(action.communicationType, currentPreCall);
          }
        }
      } else {
        console.log("Initiate call ended found preEST..");
        this.removeCallForwardingArrayCall(
          currentCall.callData.callId
        );
        if (action) {
          this.emit(action.communicationType, currentPreCall);
        }
      }
    }

    isInOnGoingArrayCall(currentCall) {
      let _this = this;
      let indexOfCurrentCall = _this.ongoingCalls.findIndex(
        (cc) =>
          (cc.callData && cc.callData.callId === currentCall.callId) ||
          (cc.callData &&
            cc.callData.preEstSessionUri &&
            cc.callData.preEstSessionUri === currentCall.preEstSessionUri) ||
          (cc.preEstSessionUri &&
            cc.preEstSessionUri === currentCall.preEstSessionUri) ||
          (currentCall.callData &&
            cc.callData &&
            cc.callData.callId === currentCall.callData.callId)
      );
      if (indexOfCurrentCall >= 0) {
        return true;
      }
      return false;
    }

    getCurrentOngoingCall(currentCall) {
      let _this = this;
      let indexOfCurrentCall = _this.ongoingCalls.findIndex(
        (cc) =>
          (cc.callData && cc.callData.callId === currentCall.callId) ||
          (cc.callData &&
            cc.callData.preEstSessionUri &&
            cc.callData.preEstSessionUri === currentCall.preEstSessionUri) ||
          (cc.preEstSessionUri &&
            cc.preEstSessionUri === currentCall.preEstSessionUri) ||
          (currentCall.callData &&
            cc.callData &&
            cc.callData.callId === currentCall.callData.callId)
      );
      if (indexOfCurrentCall >= 0) {
        return _this.ongoingCalls[indexOfCurrentCall];
      }
      return {};
    }

    getRunningOngoingCall(callId) {
      let indexOfCurrentCall = this.ongoingCalls.findIndex(
        (cc) => cc.callData && cc.callData.callId === callId
      );
      if (indexOfCurrentCall >= 0) {
        return this.ongoingCalls[indexOfCurrentCall];
      }
      return null;
    }

    isSameGroupCallRunning(data) {
      let groupId = data && data.callType && data.callType.includes("GROUP") ? data.groupId ? data.groupId : data.toId : data.toId;
      if (groupId == null) {
        return false;
      }
      let indexOfCurrentCall = this.ongoingCalls.findIndex(
        (cc) => cc.callData && (cc.callData.groupId === groupId || cc.callData.toId === groupId)
      );
      if (indexOfCurrentCall >= 0) {
        //return true;
        let call = this.ongoingCalls[indexOfCurrentCall];
        return call.callData;
      }
      return false;
    }

    removeOnGoingArrayCall(currentCall) {
      console.log("removeOnGoingArrayCall()");
      let indexOfCurrentCall = this.ongoingCalls.findIndex(
        (cc) =>
          (cc.callData && cc.callData.callId === currentCall.callId) ||
          (cc.callData &&
            cc.callData.preEstSessionUri &&
            cc.callData.preEstSessionUri === currentCall.preEstSessionUri) ||
          (cc.preEstSessionUri &&
            cc.preEstSessionUri === currentCall.preEstSessionUri) ||
          (currentCall.callData &&
            cc.callData &&
            cc.callData.callId === currentCall.callData.callId)
      );
      if (indexOfCurrentCall >= 0) {
        this.ongoingCalls.splice(indexOfCurrentCall, 1);
      }
    }

    addCallInOngoingCalls(currentCall) {
      this.removeOnGoingArrayCall(currentCall);
      this.ongoingCalls.push(currentCall);
    }

    sendPTTRelease(callId, actionType) {
      let call = this.ongoingCalls.find(
        (ca) => ca.callData && ca.callData.callId == callId
      );
      // console.log("Current call.....12", call);
      const callAction = {
        toId: call.callData.toId,
        fromId: call.callData.fromId,
        callId: call.callData.callId,
        callType: call.callData.callType,
        communicationType: "CALL_ACTION",
        callPriority: call.callData.callPriority,
        callActionType: actionType,
        isVideoCamera: call.callData.isVideoCamera,
        isVideo: call.callData.isVideo,
        localAudio: call.callData.localAudio,
        stream: call.callData.stream,
      };
      // console.log("Current call.....12 22", callAction);
      this.sendCallAction(callAction);
    }

    sendDisconnected(callId, actionType) {
      let call = this.ongoingCalls.find(
        (ca) => ca.callData && ca.callData.callId == callId
      );
      // console.log("Current call.....12", call);
      const callAction = {
        toId: call.callData.toId,
        fromId: call.callData.fromId,
        callId: call.callData.callId,
        callType: call.callData.callType,
        communicationType: "CALL_ACTION",
        callPriority: call.callData.callPriority,
        callActionType: actionType,
        isVideoCamera: call.callData.isVideoCamera,
        isVideo: call.callData.isVideo,
        localAudio: call.callData.localAudio,
        stream: call.callData.stream,
      };
      // console.log("Current call.....12 22", callAction);
      this.sendCallAction(callAction);
    }

    _HandleNewRTCSession(data, fromReinvite) {
      console.log("_HandleNewRTCSession()", this.registerprocessSate.getRegistrationState(), fromReinvite);
      const session = data.session;

      if (!this.registerprocessSate.isRegistered()) {
        console.log("_HandleNewRTCSession() Mcxclient is not registered ");
        if (!fromReinvite) {
          if (session) {
            console.log("_HandleNewRTCSession() session going to terminate");
            session.terminate();
            return;
          }
        }
      }

      const _this = this;

      //call forwarding check for incoming call
      if (session.direction === "incoming" || fromReinvite) {
        let callForwardData = _this.checkCallForwardingForThecall();
        console.log("callForward callForwardData... ", callForwardData);
        if (
          callForwardData.forwardingMcptt &&
          callForwardData.forwardingNoAnsTimer
        ) {
          let res = _this.sendMessageToCallForward(
            data,
            callForwardData.forwardingMcptt,
            callForwardData.forwardingNoAnsTimer
          );
          if (!res) {
            return;
          }
        } else if (callForwardData.forwardingMcptt) {
          _this.sendMessageToCallForward(
            data,
            callForwardData.forwardingMcptt,
            null
          );
          return;
        }

        let callObj = {};
        let currentCall = {};
        let callId;
        let send200OkResp = true;
        let callIsRunning = false;
        if (
          data.request.getHeaders("X-MCX-Called-Party") &&
          data.request.getHeaders("X-MCX-Called-Party").length > 0
        ) {
          let sdpStr = data.request.body;
          callId = data.request.getHeaders("call-id")[0];
          let remotePort = this.sdptransform.isMLineExistsApplication(sdpStr);
          if (remotePort > 0) {
            // Ptt call
            let remoteIp = this.sdptransform.getCLineIp(sdpStr);
            if (remoteIp) {
              this.pttMsgClient.setRemote(callId, remoteIp, remotePort);
            }
          }

          callObj = {
            communicationType: "CALL",
            toId: data.request.getHeaders("X-MCX-Called-Party")[0], //MCX
            fromId: data.request.getHeaders("X-MCX-Calling-Party")[0],
            callType: data.request.getHeaders("X-Mcx-Request-Type")[0],
            indexId: data.request.getHeaders("X-MCX-Index")[0],
            callPriority: data.request.getHeaders("X-MCX-Priority")[0],
            callId: data.request.getHeaders("call-id")[0],
            isVideo:
              data.request.getHeaders("X-Mcx-is-video")[0] === "true"
                ? true
                : false,
          };
          currentCall = {
            session: session,
            callData: callObj,
            request: data.request,
          };
          if (data.request.getHeaders("X-MCX-Calling-Group")[0]) {
            callObj.groupId = data.request.getHeaders("X-MCX-Calling-Group")[0];
          }
        } else {
          let sdpStr = _this.mcpttBodyUtils.getLocalSdp(data.request);
          if (!sdpStr) {
            // it means reinvite call is coming through refer in case of call upgrade
            let parsedObj = _this.mcpttBodyUtils.getCallObjFromReferReq(data.request, _this.mcptt_uri, _this.isMcxUserAgent(), _this.ongoingCalls);
            callObj = parsedObj.callObj;
            sdpStr = parsedObj.sdp;
          } else {
            callObj = _this.mcpttBodyUtils.getCallObjFromRequest(
              data.request,
              _this.domainsConfig,
              _this.ongoingCalls,
              _this.mcptt_uri,
              _this.isMcxUserAgent()
            );
          }
          callId = data.request.getHeaders("call-id")[0];
          let newTransformedSdp = sdpStr;
          callIsRunning = _this.isInOnGoingArrayCall(callObj);
          console.log("callobj.......", callObj, callIsRunning, callId);

          if (_this.isMcxUserAgent()) {
            // let sdpStr = _this.mcpttBodyUtils.getLocalSdp(data.request);
            // newTransformedSdp = sdpStr;
            let remotePort = _this.sdptransform.isMLineExistsApplication(
              sdpStr
            );
            if (remotePort > 0) {
              // Ptt call
              let remoteIp = _this.sdptransform.getCLineIp(sdpStr);
              if (remoteIp) {
                const pttCallBack = (err, messageType, message) => {
                  console.log(
                    "outgoing callback...1 ",
                    err,
                    messageType,
                    callObj
                  );
                  let parsedMessage = _this.pttMsgClient.getMessage(
                    messageType,
                    message,
                    callObj
                  );
                  console.log("outgoing callback...parsed 1", parsedMessage);
                  _this.updateSdpOnPTT(parsedMessage.talkingPartyId, callObj);
                  _this.emit(parsedMessage.communicationType, parsedMessage);
                };
                console.log(
                  "allocate ------------------ 1 ",
                  callId,
                  remotePort,
                  remoteIp,
                  sdpStr
                );
                _this.pttMsgClient.allocate(
                  callId,
                  remotePort,
                  remoteIp,
                  pttCallBack
                );
              }
              // console.log("delete ------------------ 1 ", sdpStr);
              newTransformedSdp = _this.sdptransform.deleteMApplicationLine(
                sdpStr
              );
            }
          }
          if (!fromReinvite) {
            data.request.body = newTransformedSdp;
            data.request.setHeader("Content-Type", "application/sdp");
            currentCall = {
              session: session,
              callData: callObj,
              request: data.request,
            };
          } else {
            let needToHandleManualCall = false;
            if (!callIsRunning) {
              needToHandleManualCall = true;
            } else {
              currentCall = _this.getCurrentOngoingCall(callObj);
              if (currentCall) {
                if (currentCall.sessionState && currentCall.sessionState === "setup") {
                  needToHandleManualCall = true;
                  console.log("got reinvite for refer sent");
                } else {
                  console.log("call is running but sesion state is not setup mode");
                  if (currentCall.callData) {
                    currentCall.callData.callPriority = callObj.callPriority;
                  }
                }
              }
            }
            console.log("callobj needToHandleManualCall ", needToHandleManualCall);
            if (needToHandleManualCall) {
              //Create call object
              data.request.body = newTransformedSdp;
              data.request.setHeader("Content-Type", "application/sdp");
              callObj.preEstSessionUri = data.preEstSessionUri;
              currentCall = {
                session: session,
                preEstSessionUri: data.preEstSessionUri,
                callData: callObj,
                request: data.request,
                callState: "started",
              };
              _this.occupyPreEstCall(callObj);
              if (_this.isCallTypeManual(currentCall.callData.callType)) {
                send200OkResp = false;
                _this.muteUnmutePreEstSession(currentCall, true);
              }
            }
          }
        }

        // Store call info or just priority info
        //1: if ReinviteRequest without call esstablished and manual call
        // store all call info
        //
        //2: if ReinviteRequest without call esstablished and Non manual call
        // send 200ok and store call priority info

        //3: if ReinviteRequest with call esstablished and non manual call
        // update call priority
        //
        //4: if ReinviteRequest with call esstablished and manual call
        // update call priority
        //
        //For reinvite calltype prority change
        // 0: identify it is reinvite for priority change.
        // 1: send 200 ok response.
        // 2: call update event for priority change.
        //
        // console.log("incoming call xml..121 Call", callObj);

        if (!fromReinvite) {
          session.on("accepted", function () {
            // the call has answered
            session.preSessionAccepted = true;
            let data = callObj;
            let foundCall = _this.ongoingCalls.find(
              (ca) => ca.callData && ca.callData.callId == data.callId
            );
            if (foundCall) {
              console.log("accepted stream ", foundCall.callData);
              data.callActionType = "ANSWER";
              if (!data.track) {
                if (foundCall.callData.track) {
                  data.track = foundCall.callData.track;
                }
              }
              if (!data.localAudio) {
                data.localAudio = foundCall.callData.localAudio;
              }
              if (!data.remoteStream) {
                if (foundCall && foundCall.callData.remoteStream) {
                  data.remoteStream = foundCall.callData.remoteStream;
                }
              }
              console.log("Call Answered......", data);
              if (_this.callforwardingNoAnsTimer) {
                clearTimeout(_this.callforwardingNoAnsTimer);
                _this.callforwardingNoAnsTimer = null;
              }
              _this.onDemandCallAnswereEventSent(data.callId);
              _this.emit("ANSWER", data);
              let checkPttStatus = _this.isCallAlreadyGetPttEvent(data.callId);
              if (checkPttStatus) {
                _this.pttQueueOnOngoingCall(data.callId, false);
                setTimeout(() => {
                  let checkPttObj = { ...checkPttStatus, callActionType: 'PUSH_TO_TALK_STATUS' };
                  console.log("sending ptt after answered 2 ", checkPttObj);
                  _this.emit("CALL_ACTION", checkPttObj);
                }, 5);
              }
            }
          });

          session.on("sending", function (data) {
            console.log("sending....12121212 ", data);
          });

          session.on("sending_reinvite", (request) => {
            console.log("sending_reinvite() incoming");
            let isEmergency = request.request.isEmergency;
            //let callId = callId; //request.request.getHeader("Call-ID");
            //console.log("outgoing call request....", callObj, request, data);

            let callType = "private";
            let answerMode = "Manual";
            let contact = _this._registrator._contact;
            let callerUri = _this.mcptt_uri;
            let sessionType = "private";

            if (callObj.callType.toLowerCase().includes("group")) {
              callType = "prearranged";
              sessionType = "prearranged";
              answerMode = "Auto";
            }
            if (callObj.callType == "SIMPLEX_INDIVIDUAL_DIRECT_CALL") {
              answerMode = "Auto";
            }
            if (callObj.isFACall) {
              callType = "first-to-answer";
              sessionType = "first-to-answer";
            }
            //getHeadersAndBody(callObject, mangledSdp, callType, answerMode, contact, To, sessionType)
            let isPTTCall =
              callObj.callType == "DUPLEX_INDIVIDUAL_CALL" ? false : true;

            let isBroadcastCall =
              callObj.callType == "SIMPLEX_BROADCAST_GROUP_CALL" ? true : false;

            let isEmgCall = isEmergency
              ? isEmergency
              : callObj.callPriority && parseInt(callObj.callPriority) == 15
                ? true
                : false;

            if (callObj.pbxUser) {
              if (data.pbxDomain) {
                callObj.toId = callObj.toId + "@" + callObj.pbxDomain;
              } else {
                callObj.toId = callObj.toId + "@" + "pbx";
              }
            }
            let newRequest = _this.callheader.getHeadersAndBody(
              callObj,
              request.request.body,
              callType,
              answerMode,
              contact,
              callerUri,
              sessionType,
              isPTTCall,
              isBroadcastCall,
              isEmgCall
            );
            // headersBody["headers"] = headers;
            //   headersBody["extraHeaders"] = extraHeaders;
            //   headersBody["body"] = bodyMcptt;
            // request.request.headers = newRequest.headers;
            request.request.extraHeaders = newRequest.extraHeaders;
            request.request.body = newRequest.body;
            // console.log(
            //   "outgoing call request final....",
            //   request,
            //   request.request.body
            // );
          });

          session.on("sdp", function (event) {
            // data.callId
            console.log("sdp....1212121211 ", event);
            if (
              event.originator === "local" &&
              event.type.toLowerCase() === "answer" &&
              _this.isMcxUserAgent()
            ) {
              let sdp = event.sdp;
              let udpport = _this.pttMsgClient.getPort(callId);
              if (udpport) {
                let parsedSdp = _this.sdptransform.addApplicationMLineInSdp(
                  sdp,
                  udpport
                );
                event.sdp = parsedSdp;
              }
            }
            // console.log("sdp....1212121211 callObj", callObj);
            if (
              callObj &&
              callObj.callType !== "DUPLEX_INDIVIDUAL_CALL" &&
              event.originator === "local" &&
              event.type.toLowerCase() === "answer"
            ) {
              if (!_this.isTRCPRCPUserAgent()) {
                let sdp = event.sdp;
                console.log("sdp recvonl 2");
                _this.sdptransform.updateSDPStateRecieveOrSend(sdp, "recvonly");
                // event.sdp = parsedSdp; //TODO
              }
            }
          });

          session.on("progress", function (data1) {
            // the call has answered
            remote.log("on progress 1212121", data1, _this.ongoingCalls);
            // Call waiting
            if (!data1.response) {
              if (
                (callObj.callType === "DUPLEX_INDIVIDUAL_CALL" ||
                  callObj.callType === "SIMPLEX_INDIVIDUAL_HOOK_CALL") &&
                _this.ongoingCalls &&
                _this.ongoingCalls.length > 1
              ) {
                data.request.reply(182, null, [`Contact: ${session._contact}`]);
              }
            }
          });

          session.on("confirmed", function (e) {
            // this handler will be called for incoming calls too
            console.log("Call Confirmed 2");
            if (currentCall) {
              currentCall.status = "confirmed";
              //console.log("incoming session call confirmed..", currentCall);

              _this.removeOnGoingArrayCall(currentCall);
              _this.ongoingCalls.push(currentCall);
            }
          });

          session.on("ended", () => {
            callObj.communicationType = "CALL_ACTION";
            callObj.callActionType = "DISCONNECTED";
            callObj.creatorId = _this.mcptt_uri;
            callObj.UEid = "0000";
            console.log("HandleNewRTCSession() Call Ended...", callObj);
            //stateType
            if (!callObj.localAudio) {
              callObj.stateType = "MISSED";
            }
            _this.delegates.createCallXml(callObj, _this);

            _this.patchCallsAction(data);
            _this.mergeCallsAction(data);

            if (_this.pendingPTT[callObj.callId]) {
              delete _this.pendingPTT[callObj.callId];
            }
            _this.removeOnGoingArrayCall(callObj);

            if (_this.callforwardingNoAnsTimer) {
              clearTimeout(_this.callforwardingNoAnsTimer);
              _this.callforwardingNoAnsTimer = null;
            }
            _this.emit("CALL_ACTION", callObj);
          });

          session.on("failed", function (err) {
            callObj.communicationType = "CALL_ACTION";
            callObj.callActionType = "REJECTED";
            callObj.creatorId = _this.mcptt_uri;
            callObj.UEid = "0000";
            console.log(
              "HandleNewRTCSession Call failed..",
              callObj
            );
            if (!callObj.localAudio) {
              callObj.stateType = "MISSED";
            }
            _this.delegates.createCallXml(callObj, _this);
            if (_this.pendingPTT[callObj.callId]) {
              delete _this.pendingPTT[callObj.callId];
            }
            _this.removeOnGoingArrayCall(callObj);

            if (_this.callforwardingNoAnsTimer) {
              clearTimeout(_this.callforwardingNoAnsTimer);
              _this.callforwardingNoAnsTimer = null;
            }
            _this.emit("CALL_ACTION", callObj);
          });

          session.on("peerconnection:", function (conn) {
            remote.log("peerconnection");
            conn.peerconnection.onaddstream = function (ev3) {
              remote.log(" *** addstream", session.originator, ev3);
            };
          });
          session.on("peerconnection:setlocaldescriptionfailed", (err) => {
            remote.log("setlocaldescriptionfailed", err);
          });
          session.on("peerconnection:setremotedescriptionfailed", (err) => {
            remote.log("setremotedescriptionfailed", err);
          });
          session.on("getusermediafailed", () => {
            remote.log("getusermediafailed");
            data.callActionType = "MEDIA_FAILED";
            _this.emit("CALL_ACTION", data);
          });

          session.on("addstream", function (e) {
            callObj.callActionType = "ANSWER";
            console.log("ADDING STREAM", e.stream);
            callObj.stream = e.stream;
          });

          session.on("track", function (e) {
            console.log("ADDING TRACK", e.track);
            callObj.callActionType = "ANSWER";
            callObj.track = e.track;
          });

          session.on("reinvite", (reinviteData) => {
            console.log("reinvite.... 1");
            _this.handleReinvite(reinviteData, callObj, session);
          });

          session.on("hold", function (holdReq) {
            console.log("hold req incoming...", holdReq);
          });
        }

        _this.removeOnGoingArrayCall(currentCall);
        _this.ongoingCalls.push(currentCall);

        if (fromReinvite && send200OkResp) {
          //send response
          if (session) {
            session._sendReply200Ok(data.request);
          } else {
            data.request.reply(200, "OK");
          }
        }
        if (fromReinvite && callIsRunning) {
          callObj.communicationType = "CALL_ACTION";
          callObj.callActionType = "CALL_UPGRADE";
          console.log("CALL_UPGRADE sent ", callObj);
          _this.emit("CALL_ACTION", callObj);
        } else {
          _this.emit(callObj.callType, callObj);
        }
      }
    }

    checkCallForwardingForThecall() {
      let forwardingMcptt = null;
      let forwardingNoAnsTimer = null;
      if (
        this.userLoginXCAP &&
        this.userLoginXCAP.userProfile &&
        this.userLoginXCAP.userProfile.callForwardingData
      ) {
        if (
          this.userLoginXCAP.userProfile.callForwardingData.allowCallForwarding
        ) {
          if (
            this.userLoginXCAP.userProfile.callForwardingData.callForwardingOn
          ) {
            if (
              this.userLoginXCAP.userProfile.callForwardingData.callForwardingCondition.includes(
                "immediate"
              )
            ) {
              forwardingMcptt = this.userLoginXCAP.userProfile
                .callForwardingData.callForwardingTarget;
              console.log("forwardingMcptt target", forwardingMcptt);
              if (
                this.userLoginXCAP.userProfile.callForwardingData
                  .allowCallForwardManualInput
              ) {
                if (
                  this.userLoginXCAP.userProfile.callForwardingData
                    .allowCallForwardManualInputTarget &&
                  this.userLoginXCAP.userProfile.callForwardingData
                    .allowCallForwardManualInputTarget.length > 0
                ) {
                  forwardingMcptt = this.userLoginXCAP.userProfile
                    .callForwardingData.allowCallForwardManualInputTarget;
                  console.log("forwardingMcptt inside", forwardingMcptt);
                }
              }
            } else if (
              this.userLoginXCAP.userProfile.callForwardingData.callForwardingCondition.includes(
                "no-answer"
              )
            ) {
              //check already any ongoing call is going on
              if (
                this.userLoginXCAP.userProfile.callForwardingData
                  .callForwardingNoAnswerTimeout != "0"
              ) {
                forwardingNoAnsTimer = this.userLoginXCAP.userProfile
                  .callForwardingData.callForwardingNoAnswerTimeout;
                forwardingMcptt = this.userLoginXCAP.userProfile
                  .callForwardingData.callForwardingTarget;
                console.log("forwardingMcptt target noans", forwardingMcptt);
                if (
                  this.userLoginXCAP.userProfile.callForwardingData
                    .allowCallForwardManualInput
                ) {
                  if (
                    this.userLoginXCAP.userProfile.callForwardingData
                      .allowCallForwardManualInputTarget &&
                    this.userLoginXCAP.userProfile.callForwardingData
                      .allowCallForwardManualInputTarget.length > 0
                  ) {
                    forwardingMcptt = this.userLoginXCAP.userProfile
                      .callForwardingData.allowCallForwardManualInputTarget;
                    console.log(
                      "forwardingMcptt inside noans",
                      forwardingMcptt
                    );
                  }
                }
              }
            } else if (
              this.userLoginXCAP.userProfile.callForwardingData.callForwardingCondition.includes(
                "busy"
              )
            ) {
              //check already any ongoing call is going on
              if (this.ongoingCalls && this.ongoingCalls.length > 0) {
                forwardingMcptt = this.userLoginXCAP.userProfile
                  .callForwardingData.callForwardingTarget;
                if (
                  this.userLoginXCAP.userProfile.callForwardingData
                    .allowCallForwardManualInput
                ) {
                  if (
                    this.userLoginXCAP.userProfile.callForwardingData
                      .allowCallForwardManualInputTarget &&
                    this.userLoginXCAP.userProfile.callForwardingData
                      .allowCallForwardManualInputTarget.length > 0
                  ) {
                    forwardingMcptt = this.userLoginXCAP.userProfile
                      .callForwardingData.allowCallForwardManualInputTarget;
                  }
                }
              }
            }
          }
        }
      }
      if (forwardingMcptt && forwardingMcptt.includes(":")) {
        forwardingMcptt = forwardingMcptt.split(":")[1];
      }
      if (forwardingMcptt == this.mcptt_uri) {
        console.log("callForward to same mcpttId... ", forwardingMcptt);
        forwardingMcptt = null;
      }
      let callForwardData = {
        forwardingMcptt: forwardingMcptt,
        forwardingNoAnsTimer: forwardingNoAnsTimer,
      };
      return callForwardData;
    }

    sendMessageToCallForward(data, forwardingMcptt, forwardingNoAnsTimer) {
      const session = data.session;
      const preEstSessionUri = data.preEstSessionUri
        ? data.preEstSessionUri
        : null;
      const _this = this;
      let callObj = null;
      if (data.request) {
        callObj = this.mcpttBodyUtils.getCallObjFromRequest(
          data.request,
          _this.domainsConfig,
          _this.ongoingCalls,
          _this.mcptt_uri,
          _this.isMcxUserAgent()
        );
      } else {
        if (data.callData) {
          callObj = { ...data.callData };
        }
      }

      if (!callObj) {
        return false;
      }
      if (preEstSessionUri) {
        callObj.preEstSessionUri = preEstSessionUri;
      }
      if (callObj) {
        if (callObj.fromId == forwardingMcptt) {
          console.log(
            "from and forwarding mcptt id is same..",
            callObj.fromId,
            forwardingMcptt
          );
          if (this.isPreEstCall(callObj.callId)) {
            console.log("call_forward disconnect preest same", callObj);
            if (session && data.request) {
              try {
                console.log("foundCall forwrd _rejectForReinvite same");
                let sessionRejected = session._rejectForReinvite(data.request, {
                  cause: JsSIP.C.causes.CANCELED,
                });
                if (!sessionRejected) {
                  this.sendReferRequest(callObj, "DISCONNECTED");
                }
              } catch (err) {
                console.log("_rejectForReinvite error same", err);
                this.sendReferRequest(callObj, "DISCONNECTED");
              }
            } else {
              this.sendReferRequest(callObj, "DISCONNECTED");
            }
          } else {
            session.terminate({
              cause: JsSIP.C.causes.CANCELED,
              status_code: 487,
            });
          }
          return false;
        }
      }

      let callType = "private";
      let answerMode =
        callObj.callType == "SIMPLEX_INDIVIDUAL_DIRECT_CALL"
          ? "Auto"
          : "Manual";
      let msgObj = { ...callObj };
      console.log("callForward obj callObj", callObj);

      msgObj.contact = _this._registrator._contact;

      let sessionType = "private";

      if (!msgObj.callType.toLowerCase().includes("group")) {
        let shouldcallForward = true;
        if (forwardingNoAnsTimer) {
          // In this case direct call have no meaning
          if (msgObj.callType == "SIMPLEX_INDIVIDUAL_DIRECT_CALL") {
            shouldcallForward = false;
          }
        }
        if (shouldcallForward) {
          msgObj.callType = callType;
          msgObj.sessionType = sessionType;
          msgObj.answerMode = answerMode;
          msgObj.isVideo = false;
          msgObj.forwardMcpttURI = forwardingMcptt;
          msgObj.isPTTCall = false;

          let message = _this.callheader.getCallingForwardMessage(msgObj);
          console.log("callForward obj", msgObj, message);

          let eventHandlers = {
            contentType: "multipart/mixed; boundary=consort-boundary",
            extraHeaders: message.headers,
            succeeded: function (data1) {
              console.log("callForward msg sent ", data1);
            },
            failed: function (data1) {
              console.log("callForward msg Failed", data1);
            },
          };

          if (forwardingNoAnsTimer) {
            let addInforwardingNoAnsTimer = parseInt(forwardingNoAnsTimer) + 2;
            console.log(
              "call forward no ans timer..",
              forwardingNoAnsTimer,
              addInforwardingNoAnsTimer
            );
            _this.callforwardingNoAnsTimer = setTimeout(() => {
              console.log("call forward when no ans taken");
              _this.sendMessageAndDisconnectCallforward(
                message,
                eventHandlers,
                session,
                callObj,
                data.request
              );
            }, addInforwardingNoAnsTimer * 1000);
          } else {
            setTimeout(() => {
              _this.sendMessageAndDisconnectCallforward(
                message,
                eventHandlers,
                session,
                callObj,
                data.request
              );
            }, 1000);
          }
          return true;
        }
      }
      return false;
    }

    sendMessageAndDisconnectCallforward(
      message,
      eventHandlers,
      session,
      callObj,
      request
    ) {
      const msgHandle = this.sendMessage(
        this.participatingServer,
        message.body,
        eventHandlers
      );
      msgHandle.on("succeeded", () => {
        console.log(
          "callForward msg SUCCESS msgHandle now going to disconnect",
          callObj
        );
        if (this.isPreEstCall(callObj.callId)) {
          console.log("call_forward disconnect preest", callObj);
          if (session && request) {
            try {
              console.log("foundCall forwrd _rejectForReinvite");
              let sessionRejected = session._rejectForReinvite(request, {
                cause: JsSIP.C.causes.CANCELED,
              });
              if (!sessionRejected) {
                this.sendReferRequest(callObj, "DISCONNECTED");
              }
            } catch (err) {
              console.log("_rejectForReinvite error", err);
              this.sendReferRequest(callObj, "DISCONNECTED");
            }
          } else {
            this.sendReferRequest(callObj, "DISCONNECTED");
          }
        } else {
          session.terminate({
            cause: JsSIP.C.causes.CANCELED,
            status_code: 487,
          });
        }
      });
      msgHandle.on("failed", () => {
        console.log(
          "callForward msg FAILED msgHandle now going to disconnect",
          callObj
        );
        if (this.isPreEstCall(callObj.callId)) {
          console.log("call_forward disconnect preest1");
          if (session && request) {
            try {
              console.log("foundCall forwrd _rejectForReinvite");
              let sessionRejected = session._rejectForReinvite(request, {
                cause: JsSIP.C.causes.CANCELED,
              });
              if (!sessionRejected) {
                this.sendReferRequest(callObj, "DISCONNECTED");
              }
            } catch (err) {
              console.log("_rejectForReinvite error", err);
              this.sendReferRequest(callObj, "DISCONNECTED");
            }
          } else {
            this.sendReferRequest(callObj, "DISCONNECTED");
          }
        } else {
          session.terminate({
            cause: JsSIP.C.causes.CANCELED,
            status_code: 487,
          });
        }
      });
    }

    logs(message, key = "", error) {
      if (error) {
        console.error("MCX Error ", key, message);
      } else {
        console.log("MCX log ", key, message);
      }
    }

    decodeMessage(decodeMsg) {
      let decodedMsg = "";
      if (decodeMsg) {
        decodeMsg = decodeMsg.substring(decodeMsg.search("4") + 1);
        console.log("got message decodeMsg.. ", decodeMsg, typeof decodeMsg);
        if (decodeMsg) {
          let messagepayload = decodeMsg.toString().trim();
          messagepayload = Buffer.from(base64.decode(messagepayload));
          if (messagepayload) {
            console.log("got message messagepayload...", messagepayload);
            let msg = sdsConfig.decode(sdsConfig.config, messagepayload);
            if (
              msg &&
              msg.decodedJson &&
              msg.decodedJson.Payload &&
              msg.decodedJson.Payload.value
            ) {
              decodedMsg = msg.decodedJson.Payload.value;
              console.log("got message final...", decodedMsg);
            }
          }
        }
      }
      return decodedMsg;
    }

    affiliateGroups(data) {
      // let affdata = {
      //   mcpttId: "sip:mcx.TEST-1.MOTO@demo34.copsptt.com",
      //   groups: [
      //     "sip:100.669@demo34.copsptt.com"
      //   ]
      // }

      // let data = {
      //   mcpttId: "5016-consort",
      //   groups: ["Host@mcx.consort.org"],
      // };

      if (!this || !this.publisher) {
        return;
      }
      console.log("MCX affiliateGroups", data);
      this.publisher.requestType = "affiliate";
      this.publisher.expires = "4294967295";
      this.publisher.callId = this.subscriptionCallId;
      this.publisher.publish(data);
    }

    deaffiliateGroups(data) {
      /*
            data = {
              mcpttId : "mcpttId"
              groups : [
                "group1@consort.org",
                "group2@consort.org"
              ]
            }
          */
      // let affdata = {
      //   mcpttId: "sip:mcptt.mcuser-111@ims.mnc001.mcc001.3gppnetwork.org",
      //   groups: [
      //     "sip:group.prearranged@ims.mnc001.mcc001.3gppnetwork.org"
      //   ]
      // }
      if (!this || !this.publisher) {
        return;
      }
      this.publisher.requestType = "deaffiliate";
      this.publisher.callId = this.subscriptionCallId;
      this.publisher.expires = "0";
      console.log("deaffiliateGroups ", data);
      this.publisher.publish(data);
    }

    activateFAs(data, callback = null) {
      console.log("MCX activateFAs", data);
      if (!this || !this.publisher) {
        //return;
        this.publisher = new FAPublisher(this);
      }
      this.publisher.requestType = "activate";
      this.publisher.expires = 4294967295;
      this.publisher.callId = this.subscriptionCallId;
      this.publisher.publishFA(data, callback);
    }

    deactivateFAs(data, callback = null) {
      if (!this || !this.publisher) {
        //return;
        this.publisher = new FAPublisher(this);
      }
      this.publisher.requestType = "deactivate";
      this.publisher.callId = this.subscriptionCallId;
      this.publisher.expires = 0;
      console.log("MCX deactivateFAs ", data);
      this.publisher.publishFA(data, callback);
    }

    setCallForwardingInfo(data) {
      // let data = {
      // allowCallForwarding: true, //Not editable
      // allowCallForwardManualInput: true,//Not editable
      // callForwardingTarget: "",//Not editable
      // callForwardingOn: false,
      // callForwardingCondition: "No-Answer:Busy:Unreachable",
      // callForwardingNoAnswerTimeout: "",
      // allowCallForwardManualInputTarget: "",
      // };
      let _this = this
      if (this.cmc === null) {
        console.log("setCallForwardingInfo().....")
        return;
      }
      this.cmc
        .getProfileByMcpttId(this.mcpttId)
        .then((res) => {
          let profileUpdate = res;//JSON.parse(JSON.stringify(res));
          profileUpdate.callForwardingData = data;
          console.log("setCallForwardingInfo().....1", profileUpdate)
          _this.cmc
            .addUserProfile(profileUpdate)
            .then((res) => {
              console.log("setCallForwardingInfo().....2", res)
              if (res) {
                _this.emit("SET_CALL_FORWARD_SUCCESS", data);
              } else {
                _this.emit("SET_CALL_FORWARD_FAILURE", data);
              }
            })
            .catch((e) => {
              console.log("Error adding Userprofile : ", e);
              _this.emit("SET_CALL_FORWARD_FAILURE", data);
            });
        })
        .catch((e) => {
          console.log("Error fetching Userprofile : ", e);
          _this.emit("SET_CALL_FORWARD_FAILURE", data);
        });
    }

    setImplicitAffiliation(data) {
      /*
            data = {
              mcpttId : "mcpttId",
              groups : [
                {name:"4000001-mcptt-group",CallerDescr:""},
              ],
              requestId : 1
            }
      */
      const _this = this;
      console.log("setImplicitAffiliation() ", data);
      return new Promise(function (resolve, reject) {
        if (_this.cmc === null) {
          reject(data);
          return;
        }
        _this.cmc
          .getProfileByMcpttId(_this.mcpttId)
          .then((res) => {
            let profileUpdate = res;//JSON.parse(JSON.stringify(res));
            if (data.groups && data.groups.length) {
              data.groups.forEach((group) => {
                if (
                  profileUpdate.implicitGroupList.findIndex(
                    (entry) => entry.name === group.name
                  ) < 0
                ) {
                  profileUpdate.implicitGroupList.push(group);
                  if (
                    profileUpdate.groupList.findIndex(
                      (entry) => entry.name === group.name
                    ) >= 0
                  ) {
                    profileUpdate.groupList.splice(
                      profileUpdate.groupList.findIndex(
                        (entry) => entry.name === group.name
                      ),
                      1
                    );
                  }
                }
              });
              _this.cmc
                .addUserProfile(profileUpdate)
                .then((res) => {
                  if (res) {
                    _this.emit("SET_IMPLICIT_SUCCESS", data);
                    resolve(data);
                  } else {
                    _this.emit("SET_IMPLICIT_FAILURE", data);
                    reject(data);
                  }
                })
                .catch((e) => {
                  console.log("Error adding Userprofile : ", e);
                  _this.emit("SET_IMPLICIT_FAILURE", data);
                  reject(data);
                });
            } else {
              reject(data);
              return "No groups to affiliate";
            }
          })
          .catch((e) => {
            console.log("Error fetching Userprofile : ", e);
            _this.emit("SET_IMPLICIT_FAILURE", data);
            reject(data);
          });
      });
    }

    removeImplicitAffiliation(data) {
      /*
            data = {
              mcpttId : "mcpttId",
              groups : [
                {name:"4000001-mcptt-group",CallerDescr:""},
              ],
              requestId : 1
            }
        */
      console.log("removeImplicitAffiliation() ", data);
      const _this = this;
      return new Promise(function (resolve, reject) {
        if (_this.cmc === null) {
          reject(data);
          return;
        }
        _this.cmc
          .getProfileByMcpttId(_this.mcpttId)
          .then((res) => {
            let profileUpdate = res; //JSON.parse(JSON.stringify(res));
            if (data.groups && data.groups.length) {
              data.groups.forEach((group) => {
                if (
                  profileUpdate.implicitGroupList.findIndex(
                    (entry) => entry.name === group.name
                  ) >= 0
                ) {
                  profileUpdate.implicitGroupList.splice(
                    profileUpdate.implicitGroupList.findIndex(
                      (entry) => entry.name === group.name
                    ),
                    1
                  );
                  if (
                    profileUpdate.groupList.findIndex(
                      (entry) => entry.name === group.name
                    ) < 0
                  ) {
                    profileUpdate.groupList.push(group);
                  }
                }
              });
              /* profileUpdate.groupList = [];
                        profileUpdate.implicitGroupList= []; */
              _this.cmc
                .addUserProfile(profileUpdate)
                .then((res) => {
                  if (res) {
                    _this.emit("REMOVE_IMPLICIT_SUCCESS", data);
                    resolve(data);
                  } else {
                    _this.emit("REMOVE_IMPLICIT_FAILURE", data);
                    reject(data);
                  }
                })
                .catch((e) => {
                  console.log("Error adding Userprofile : ", e);
                  _this.emit("SET_IMPLICIT_FAILURE", data);
                  reject(data);
                });
            } else {
              reject(data);
              return "No groups to affiliate";
            }
          })
          .catch((e) => {
            console.log("Error fetching Userprofile : ", e);
            _this.emit("REMOVE_IMPLICIT_FAILURE", data);
            reject(data);
          });
      });
    }

    setDefaultGroup(data) {
      /*
            data = {
              mcpttId: "",
              groupId: "",
              requestId: int
            }
          */
      console.log("setDefaultGroup() ", data);
      const _this = this;
      return new Promise(function (resolve, reject) {
        if (_this.cmc === null) {
          reject(data.requestId);
          return;
        }
        _this.cmc
          .getProfileByMcpttId(_this.mcpttId)
          .then((resp) => {
            let profileUpdate = resp;
            if (data.groupId) {
              profileUpdate.defaultGroup = data.groupId;
              _this.cmc
                .addUserProfile(profileUpdate)
                .then((res) => {
                  if (res) {
                    _this.emit("SET_DEFAULT_SUCCESS", data.requestId);
                    resolve(data.requestId);
                  } else {
                    _this.emit("SET_DEFAULT_FAILURE", data.requestId);
                    reject(data.requestId);
                  }
                })
                .catch((e) => {
                  console.log("Error adding Userprofile : ", e);
                  _this.emit("SET_DEFAULT_FAILURE", data.requestId);
                  reject(data.requestId);
                });
            } else {
              console.log("group id is not found");
              _this.emit("SET_DEFAULT_FAILURE", data.requestId);
              reject(data.requestId);
            }
          })
          .catch((e) => {
            console.log("Error fetching Userprofile : ", e);
            _this.emit("SET_DEFAULT_FAILURE", data.requestId);
            reject(data.requestId);
          });
      });
    }

    removeDefaultGroup(data) {
      /*
            data = {
              mcpttId: "",
              requestId: int
            }
          */
      const _this = this;
      console.log("removeDefaultGroup() ", data);
      return new Promise(function (resolve, reject) {
        if (_this.cmc === null) {
          reject(data.requestId);
          return;
        }
        _this.cmc
          .getProfileByMcpttId(_this.mcpttId)
          .then((resp) => {
            let profileUpdate = resp;
            profileUpdate.defaultgroup = "";
            _this.cmc
              .addUserProfile(profileUpdate)
              .then((res) => {
                if (res) {
                  _this.emit("REMOVE_DEFAULT_SUCCESS", data.requestId);
                  resolve(data.requestId);
                } else {
                  _this.emit("REMOVE_DEFAULT_FAILURE", data.requestId);
                  reject(data.requestId);
                }
              })
              .catch((e) => {
                console.log("Error adding Userprofile : ", e);
                _this.emit("REMOVE_DEFAULT_FAILURE", data.requestId);
                reject(data.requestId);
              });
          })
          .catch((e) => {
            console.log("Error fetching Userprofile : ", e);
            _this.emit("REMOVE_DEFAULT_FAILURE", data.requestId);
            reject(data.requestId);
          });
      });
    }

    setFAMClistener() {
      const _this = this;

      this.faMc.subscriber.on("GROUPS_AFFILIATED", (groups) => {
        console.log("GROUP_AFFILIATED", groups);
        if (!this.userLoginXCAP) {
          this.affiliatedGroupList = [...groups];
          return;
        }
        this.affiliatedGroupList = null;
        let groupsDetail = _this.makeAffiliatedGroupList(groups);
        _this.emit("GROUPS_AFFILIATED", groupsDetail.affiliations);
        _this.emit("GROUPS_DEAFFILIATED", groupsDetail.deaffiliations);
      });

      this.faMc.subscriber.on("GROUPS_DEAFFILIATED", (groups) => {
        console.log("GROUP_DEAFFILIATED", groups);
      });

      this.faMc.subscriber.on("FA_ACTIVATION_STATUS", (fas) => {
        let isAll = true;
        console.log(
          "FA_ACTIVATION_STATUS..",
          fas,
          _this.loginCompleteEventSent
        );

        if (!_this.userLoginXCAP) {
          console.log("userProfile is still in process");
          _this.faActivationList = [...fas];
          _this._FATimer && clearTimeout(_this._FATimer);
          return;
        }
        _this.faActivationList = null;
        if (!_this.loginCompleteEventSent) {
          if (_this._autoActivateFAList && _this._autoActivateFAList.size) {
            for (const iterator of _this._autoActivateFAList) {
              let isPresent = fas.find((item) => item["name"] == iterator);
              //console.log('falistttt isPresent', isPresent, fas, _this._autoActivateFAList)
              if (!isPresent) {
                isAll = false;
                break;
              }
            }
          }
          if (!isAll) {
            console.log("LoginSuccssessfulActivationPending ");
            _this.sendLogsToApp("loginCompleteError", {
              "reason": "All autoActivated FA list still pending",
            });
          } else {
            _this._FATimer && clearTimeout(_this._FATimer);
            setTimeout(() => {
              _this.emit("FA_ACTIVATION_STATUS", _this.faStatusList(fas));
            }, 1000);
            console.log(
              "_loginComplete method called on FA_ACTIVATION_STATUS event"
            );
            _this.sendLogsToApp("loginComplete", {
              "reason": "got all fa activated list",
            });
            _this._loginComplete();
          }
        } else {
          _this._FATimer && clearTimeout(_this._FATimer);
          _this.emit("FA_ACTIVATION_STATUS", _this.faStatusList(fas));
        }
      });

      this.faMc.subscriber.on("USER_REG_STATUS", (userRegList) => {
        /*if (this.loginCompleteEventSent) {
          _this.globalUserRegList = userRegList.regStatus;
          _this.globalContactList = userRegList.contacts;
        }
        if (this.loginCompleteEventSent) {
          console.log("contactlist event sent");
          _this.emit("Contactlist", _this.globalContactList);
          //console.log("USER_REG_STATUS list...", userRegList);
          _this.fetchRegisteredMcpttIdWithUEId();
        }*/
        if (userRegList) {
          _this.globalUserRegList = userRegList.regStatus;
          _this.globalContactList = userRegList.contacts;
        }
        if (this.loginCompleteEventSent) {
          console.log("contactlist event sent");
          _this.emit("Contactlist", _this.globalContactList);
          _this.fetchRegisteredMcpttIdWithUEId();
        }
      });
    }

    setCMClistener() {
      const _this = this;
      this.cmc.on("userProfileXCAP", (respApi) => {
        console.log("userProfileXCAP() event called");

        if (!_this.registerprocessSate.isRegistered()) {
          console.log("userProfileXCAP() not registered ", _this.registerprocessSate.getRegistrationState());
          return;
        }

        if (_this.loginCompleteEventSent) {
          console.log(
            "userProfileXCAP event already got previously: ", _this.loginCompleteEventSent
          );
          if (respApi && respApi.data) {
            const resp = _this.cmc.makeContactList(respApi.data);
            if (resp && resp.contactList) {
              _this.globalContactList = resp.contactList;
              _this.updateContactsXCAPNotify(respApi);
            }
          }
          return;
        }

        if (respApi && respApi.data) {
          //_this.setStorageUEid(_this.cmc.getStorageUeID(respApi.data));
          const resp = _this.cmc.makeContactList(respApi.data);
          let respUserProfile = _this.cmc.userProfileToJson(respApi.data);
          if (resp && resp.contactList) {
            _this.globalContactList = resp.contactList;
          }
          if (respUserProfile && respUserProfile.mcpttId) {
            _this.mcptt_uri = respUserProfile.mcpttId;
            if (_this.userData && _this.userData.profile) {
              _this.userData.profile.mcptt_id = respUserProfile.mcpttId;
            }
            if (_this.mcptt_uri && _this.mcptt_uri.includes("@")) {
              let mcArr = _this.mcptt_uri.split("@");
              if (mcArr.length > 0) {
                _this.userDomain = mcArr[1];
              }
            }
          } else {
            if (_this.userData && _this.userData.profile) {
              _this.userData.profile.mcptt_id = _this.mcptt_uri;
            }
            if (_this.mcptt_uri && _this.mcptt_uri.includes("@")) {
              let mcArr = _this.mcptt_uri.split("@");
              if (mcArr.length > 0) {
                _this.userDomain = mcArr[1];
              }
            }
          }

          let userLogin = {};
          userLogin.faList =
            respUserProfile && respUserProfile.faList
              ? respUserProfile.faList
              : [];
          userLogin.userData = _this.userData;
          userLogin.contactlist = resp.contactList;
          userLogin.defaultGroup = resp.defaultgroup;

          let userProfile = {
            ...respUserProfile,
            domain: _this.userDomain,
            mcptt_uri: _this.mcptt_uri,
          };
          _this.emit("Profile", userProfile);

          userLogin.faConfig = [];
          userLogin.userProfile = userProfile;
          _this.mcxUserProfile = { ...userProfile };
          if (_this._autoActivateFAList && _this._autoActivateFAList.size > 0) {
            let activatedFA = _this._autoActivateFAList.values().next().value;
            userLogin.activatedFA = activatedFA;
          }
          _this.userLoginXCAP = userLogin;
          // console.log("userLogin...", userLogin);
          _this.formatedFAList();
          _this.formatedAffiliatedGroupList();
          if (_this.affiliatedGroupList) {
            _this.makeAffiliatedGroupList(_this.affiliatedGroupList);
            _this.affiliatedGroupList = null;
          }

          if (
            respUserProfile &&
            respUserProfile.faList &&
            respUserProfile.faList.length
          ) {
            console.log(
              "FA_ACTIVATION_STATUS FA_ASSIGNED.......",
              respUserProfile.faList,
              _this._autoActivateFAList
            );
            _this.emit("FA_ASSIGNED", respUserProfile.faList);

            if (
              _this._autoActivateFAList &&
              _this._autoActivateFAList.size > 0
            ) {
              if (_this.faActivationList && _this.faActivationList.length > 0) {
                let isAll = true;
                for (const iterator of _this._autoActivateFAList) {
                  let isPresent = _this.faActivationList.find((item) => item["name"] == iterator);
                  //console.log('falistttt isPresent', isPresent, fas, _this._autoActivateFAList)
                  if (!isPresent) {
                    isAll = false;
                    break;
                  }
                }
                _this.faActivationList = null;
                if (!isAll) {
                  console.log("All autoActivated FA list still pending after getting fa notify");
                  _this.sendLogsToApp("loginCompleteError", {
                    "reason": "All autoActivated FA list still pending",
                  });
                } else {
                  _this._FATimer && clearTimeout(_this._FATimer);
                  setTimeout(() => {
                    _this.emit("FA_ACTIVATION_STATUS", _this.faStatusList(_this.faActivationList));
                  }, 1000);
                  console.log(
                    "_loginComplete method called after profile"
                  );
                  _this.sendLogsToApp("loginComplete", {
                    "reason": "got all fa activated list in profile",
                  });
                  _this._loginComplete();
                }
              } else {
                console.log(
                  "FA_ACTIVATION_STATUS _autoActivateFAList list inside timer......."
                );
                _this._FATimer = setTimeout(() => {
                  _this.sendLogsToApp("loginFailed", {
                    "reason": "FA timer failed"
                  });
                  _this._loginFailed("Timer for fa auto activation failed");
                }, 30 * 1000);
              }
            } else {
              if (!_this.loginCompleteEventSent) {
                _this._loginComplete();
              }
            }
          } else {
            if (!_this.loginCompleteEventSent) {
              console.log("no falist found");
              _this._loginComplete();
            }
          }
        } else {
          _this.sendLogsToApp("loginCompleteError", {
            "reason": "could not fetched profile data",
          });
          console.log("could not fetched profile data", respApi);
        }
      });

      this.cmc.on("UserProfileFetched", (data) => {
        console.log("UserProfileFetched() ");
        const resp = _this.cmc.makeContactList(data);
        if (resp && resp.contactList) {
          _this.globalContactList = resp.contactList;
          _this.updateContactsXCAPNotify();
        }
      });
    }

    getAdminProfile(data) {
      if (this.admin) {
        this.admin.getAdminProfile(data).then(resp => {
          if (resp) {
            this.emit("GET_ADMIN_PROFILE", resp);
          }
          return resp;
        })
      }
    }

    getAllSubDomains(data) {
      if (this.admin) {
        this.admin.getAllSubDomains(data).then(resp => {
          if (resp && resp.domains) {
            this.domainsConfig = resp.domains;
            if (this.domainsConfig && this.domainsConfig.mcxDomain) {
              this.userDomain = this.domainsConfig.mcxDomain;
            }
            this.emit("DOMAIN_UPDATED", resp.domains);
          }
          return resp;
        })
      }
    }

    setTaskModuleListener() {
      const _this = this;
      this.task.AlertModule.on("ALERT_MESSAGE", (data) => {
        _this.emit("ALERT_MESSAGE", data);
        console.log("STATUS_MESSAGE ALERT_MESSAGE..", data);
        if (data) {
          if (data.tetraCode == "65500" || data.tetraCode == "65024") {
            console.log("STATUS_MESSAGE ALERT_MESSAGE to ignore...", data);
          } else {
            _this.store.addSDS(data).then((res) => {
              console.log("STATUS_MESSAGE ALERT_MESSAGE response..", res);
              _this.emit("STATUS_MESSAGE", data);
            });
          }
        }
      });
      this.task.AlertModule.on("REFRESH_TASK_LIST", (data) => {
        console.log("INCOMING TASK EVENT mcxclient ----------------", data);
        _this.emit("REFRESH_TASK_LIST", data);
      });
    }

    getUserProfileRole() {
      if (this.userData && this.userData.profile.Role == "Admin") {
        return "Admin";
      }
      return "user";
    }

    updateIdmsTokenBeforeExpiry() {
      const _this = this;
      console.log("updateIdmsTokenBeforeExpiry()");

      this.idmc
        .login(this.userName, this.password)
        .then((res) => {
          if (res) {
            console.log(
              "updateIdmsTokenBeforeExpiry() : login access_token mcx got token", res
            );
            let resp = _this.loginWithToken(res.access_token);
            console.log("updateIdmsTokenBeforeExpiry(): token parsed response ", resp);
          } else {
            console.log("updateIdmsTokenBeforeExpiry(): could not fetch token");
          }
        })
        .catch((isRetry) => {
          console.log("updateIdmsTokenBeforeExpiry() retry to update token", isRetry);
        });
    }

    relogin() {
      const _this = this;
      console.log("relogin()");

      //Registration/ subscription:- closed
      this._resetAllSubscriptionAndTimer();
      this.removeAllPreEstSession();
      this.updateRegistrationProcess("LOGIN", stateType.LOGIN_STATE.LoggingIn);
      this.updateRegistrationProcess("REGISTER", stateType.REGISTRATION_STATE.Deregistered);

      this.idmc
        .login(this.userName, this.password)
        .then((res) => {
          if (res) {
            console.log("relogin: login access_token mcx got token");
            _this.sendLogsToApp("relogin", {
              "reason": "relogin got access token"
            });
            let resp = _this.loginWithToken(res.access_token);
            console.log("relogin: parsed token response  ", resp);
            if (resp && resp.profile) {
              _this.userData = resp;
              _this.registrationUser(resp, null);
            } else {
              console.log("relogin: could not parsed token");
              _this.sendLogsToApp("relogin", {
                "reason": "retry relogin attempt after could not parsed token"
              });
              _this.reloginTimer = setTimeout(() => {
                _this.relogin();
              }, 10000);
            }
          } else {
            console.log("relogin: could not fetch token");
            _this.sendLogsToApp("relogin", {
              "reason": "retry relogin attempt after not getting idms token"
            });
            _this.reloginTimer = setTimeout(() => {
              console.log("relogin() timer callback");
              _this.relogin();
            }, 15000);
          }
        })
        .catch((isRetry) => {
          if (isRetry) {
            console.log("retry relogin");
            _this.sendLogsToApp("relogin", {
              "reason": "retry relogin"
            });
            _this.reloginTimer = setTimeout(() => {
              console.log("relogin() timer callback isRetry");
              _this.relogin();
            }, 10000);
          } else {
            this.sendLogsToApp("reloginFailed", {
              "reason": "retry relogin received error from idms"
            });
            console.log("could not fetch token 1111111 ", isRetry);
            this.updateRegistrationProcess("LOGIN", stateType.LOGIN_STATE.LoggedOut);
            this._loginFailed("Received error from idms");
            return;
          }
        });
    }

    getActiveToken() {
      return this._currentToken;
    }

    updateRegistrationProcess(type, val, response = null, causes = null) {
      this.registerprocessSate.updateRegisterStates(type, val, response, causes);
    }

    login(userName, password) {
      if (!this.isConnected()) {
        this.start();
      }
      this.userName = userName;
      this.password = password;
      console.log("login() requested..", this.registerprocessSate.getLoginState(), this.registerprocessSate.getRegistrationState());
      this.sendLogsToApp("login", {
        "loginStatus": this.registerprocessSate.getLoginState(),
        "regStatus": this.registerprocessSate.getRegistrationState()
      });
      if (this.registerprocessSate.getLoginState() == stateType.LOGIN_STATE.LoggedIn) {
        console.log("login state is in loggedIn state");
        this.updateRegistrationProcess("LOGIN", stateType.LOGIN_STATE.Requested);
      } else {
        this._continueLogin(userName, password);
      }
    }

    _continueLogin() {
      console.log("_continueLogin()");
      if (this.continueLogginTimer) {
        clearTimeout(this.continueLogginTimer);
        this.continueLogginTimer = null;
      }
      if (this.userName && this.password) {
        this.updateRegistrationProcess("LOGIN", stateType.LOGIN_STATE.LoggingIn);
        const _this = this;
        this.idmc
          .login(this.userName, this.password)
          .then((res) => {
            console.log("_continueLogin() got access token ", res);
            if (!res) {
              _this.updateRegistrationProcess("LOGIN", stateType.LOGIN_STATE.LoggedOut);
              _this.sendLogsToApp("loginFailed", {
                "reason": "Received error from idms",
              });
              _this._loginFailed("Invalid credentials. Please try again!");
              return;
            }
            //Token parsing step
            let resp = _this.loginWithToken(res.access_token);
            console.log("_continueLogin() parsed token resp ", resp);
            if (resp && resp.profile) {
              _this.userData = res;
              _this.registrationUser(resp);
            } else {
              _this.updateRegistrationProcess("LOGIN", stateType.LOGIN_STATE.LoggedOut);
              _this._loginFailed("Invalid credentials. Please try again!");
              _this.sendLogsToApp("loginFailed", {
                "reason": "Received error from idms",
              });
            }
          })
          .catch((isRetry) => {
            if (isRetry) {
              console.log("retry _continueLogin()", isRetry);
              _this.sendLogsToApp("retryLogin", {
                "reason": "retry _continueLogin()"
              });
              _this.continueLogginTimer = setTimeout(() => {
                console.log("_continueLogin() timer callback");
                _this._continueLogin();
              }, 10000);
            } else {
              _this.updateRegistrationProcess("LOGIN", stateType.LOGIN_STATE.LoggedOut);
              _this._loginFailed("Invalid credentials. Please try again!");
              _this.sendLogsToApp("retryLogin", {
                "reason": "Received error from idms",
              });
              return;
            }
          });
      } else {
        console.log("_continueLogin() username and password not found");
        this.updateRegistrationProcess("LOGIN", stateType.LOGIN_STATE.LoggedOut);
        this.sendLogsToApp("login", {
          "reason": "username and password not found",
        });
        this._loginFailed("username and password not found");
      }
    }

    loginWithToken(access_token) {
      this.updateRegisterBodyAndToken(access_token);
      const decoded = jwt_decode(access_token);
      const res = {
        access_token: access_token,
        expires_at: decoded ? decoded.exp : null,
        profile: decoded,
      };
      if (decoded && decoded.exp) {
        if (this.tokenRefresher) {
          clearTimeout(this.tokenRefresher);
          this.tokenRefresher = null;
        }
        if (decoded.nbf) {
          const expDiffTime = (decoded.exp * 1000 - decoded.nbf * 1000) * 0.85;
          console.log(
            "MCX refreshToken expDiffTime",
            decoded.exp,
            decoded.nbf,
            expDiffTime
          );
          this._refreshIdmcToken(expDiffTime);
        } else {
          const currentTime = new Date().getTime();
          const expDiffTime = (decoded.exp * 1000 - currentTime) * 0.85;
          this._refreshIdmcToken(expDiffTime);
        }
      }
      if (res.profile && res.profile.mcptt_id) {
        return res;
      }
      return null;
    }

    updateRegisterBodyAndToken(access_token) {
      this._currentToken = access_token;
      const registerBodyL = createMcpttInfoXml(access_token);
      this.registerBody = registerBodyL;
      if (this._registrator) {
        this._registrator.updateBody(this.registerBody);
      }
    }

    registrationUser(res, callback = null) {
      console.log("registrationUser()");
      if (res && res.profile) {
        this.userData = res;
        this.mcpttId = res.profile.mcptt_id;
        this.mcdata_id = res.profile.mcptt_id;
        this.mcdata_uri = `${this.mcdata_id}`;
        this.mcvideo_id = res.profile.mcptt_id;
        this.mcvideo_uri = `${this.mcvideo_id}`;
        this.mcptt_uri = `${this.mcpttId}`;
        this.userDomain = "";
        this.tenantDomain = "";

        this.setTaskModuleListener();

        // get init config for user
        if (this.userName && this.userName.includes('@')) {
          this.tenantDomain = this.userName.slice(this.userName.indexOf('@') + 1);
        }
        this.admin.getAllSubDomains(this.tenantDomain).then((resp) => {
          console.log("getAllSubDomains res ", resp);
          if (resp) {
            let totalPreEstSessionCount = this.getPreEstSessionCount(resp);
            this.configPreEstSessionCount =
              totalPreEstSessionCount || totalPreEstSessionCount == -1
                ? totalPreEstSessionCount
                : 0;
            console.log("totalPreEstSessionCount ", this.configPreEstSessionCount);
            this.preEstCallTypeAllowed = resp.preEstCallTypeAllowed ? resp.preEstCallTypeAllowed : {};
            if (resp.domains) {
              this.domainsConfig = resp.domains;
            }
            this.userData = { ...this.userData, config: resp, tenantDomain: this.tenantDomain };

            if (res.profile && res.profile.tetraUser) {
              if (JSON.parse(res.profile.tetraUser) === true) {
                if (this.domainsConfig && this.domainsConfig.iwfs) {
                  if (this.domainsConfig.iwfs.length > 0) {
                    let iwfDomain = this.domainsConfig.iwfs[0];
                    if (iwfDomain.mappedDomain) {
                      this.userDomain = iwfDomain.mappedDomain;
                    }
                  }
                }
              } else {
                if (this.domainsConfig && this.domainsConfig.mcxDomain) {
                  this.userDomain = this.domainsConfig.mcxDomain;
                }
              }
            } else {
              if (this.domainsConfig && this.domainsConfig.mcxDomain) {
                this.userDomain = this.domainsConfig.mcxDomain;
              }
            }
            if (this.userDomain.length > 0) {
              this.mcptt_uri = this.mcpttId.includes("@") ? this.mcpttId : `${this.mcpttId}@${this.userDomain}`;
              this.mcdata_uri = this.mcdata_id.includes("@") ? this.mcdata_id : `${this.mcdata_id}@${this.userDomain}`;
              this.mcvideo_uri = this.mcvideo_id.includes("@") ? this.mcvideo_id : `${this.mcvideo_id}@${this.userDomain}`;
            }

            console.log(
              "Login to MCXSERVER URI ==============",
              this.mcptt_uri,
              this.userDomain
            );

            const _this = this;
            if (res.profile.Role == "Admin") {
              this.admin.getAdminProfile(this.userName).then(resData => {
                if (resData) {
                  _this.userData = { ..._this.userData, config: resp, tenantDomain: _this.tenantDomain, adminProfile: resData };
                }
                _this.updateRegistrationProcess("LOGIN", stateType.LOGIN_STATE.LoggedIn);
              }).catch(err => {
                this._loginFailed("No able to fetch admin profile", err);
              })
            } else {
              this.updateRegistrationProcess("LOGIN", stateType.LOGIN_STATE.LoggedIn);
            }
            if (this.mcdata_id) {
              //this._registrator.mcdataRegistrationThroughPOC(callback);
            }
            if (this.mcvideo_id) {
              //this._registrator.mcVideoRegistrationThroughPOC(callback);
            }
          } else {
            console.log("getAllSubDomains res error");
            this.updateRegistrationProcess("LOGIN", stateType.LOGIN_STATE.LoggedOut);
          }
        }).catch((err) => {
          console.log("getAllSubDomains error ", err);
          this.updateRegistrationProcess("LOGIN", stateType.LOGIN_STATE.LoggedOut);
        })
      } else {
        this.updateRegistrationProcess("LOGIN", stateType.LOGIN_STATE.LoggedOut);
        this.sendLogsToApp("loginfailed", {
          "reason": "Error in processing idms access token in registration method",
        });
        this._loginFailed("Error in processing idms access token....");
      }
    }

    _refreshIdmcToken(exp) {
      try {
        let minimumTokenExpTime = 1296000000; // 15 days
        let expTime = Math.min(minimumTokenExpTime, exp); //7
        console.log("MCX refreshToken expTime.....", expTime, exp);

        const _this = this;
        if (this.tokenRefresher) {
          clearTimeout(this.tokenRefresher);
          this.tokenRefresher = null;
        }
        this.tokenRefresher = setTimeout(() => {
          if (exp > expTime) {
            let diffExp = exp - expTime;
            console.log("MCX refreshToken still remaining time..", diffExp);
            _this._refreshIdmcToken(diffExp);
          } else {
            console.log("MCX refreshToken started..");
            _this.updateIdmsTokenBeforeExpiry();
          }
        }, expTime);
      } catch (error) {
        console.log("_refreshIdmcToken error: ============ ", error);
      }
    }

    nodeStatusSubscribe() {
      const _this = this;
      this.nodeStatus = null;
      this.nodeStatus = new NodeStatus(this.mcpttId, this);
      this.nodeStatus.on("nodestatusres", (data) => {
        if (data) {
          _this._nodestatusStepEnum = true;
        } else {
          console.log(" ======= _nodestatus errr ");
        }
      });
      this.nodeStatus.on("NODE_SATUS", (data) => {
        _this.emit("NODE_STATUS", data);
      });
    }

    nodeStatusReSubscribe() {
      const _this = this;
      this.nodeStatus = null;
      this.nodeStatus = new NodeStatus(this.mcpttId, this);
      this.nodeStatus.on("NODE_SATUS", (data) => {
        _this.emit("NODE_STATUS", data);
      });
    }

    _loginComplete() {
      console.log("_loginComplete()");
      this.resetPTTCallStatus();
      if (!this.loginCompleteEventSent) {
        this.loginCompleteEventSent = true;
        console.log("LOGIN_COMPLETE Event Sent ", this.loginCompleteEventSent);
        this.emit("DIALER_OPTION", this.fetchDialerDomainType());
        this.emit("LOGIN_COMPLETE", this.userLoginXCAP);
      }
      setTimeout(() => {
        this.restoreFAS();
      }, 500);
      this.emit("onRegister");
      console.log("onRegister event sent...");
      this.checkPreEstSessionInRegInterval();
      this.faMc.mergeRegstatusWithContact(this.globalContactList);
    }

    _loginFailed(reason) {
      console.log("============= REASONS ============ ", reason);
      const _this = this;
      this.deRegisterUser().then(() => {
        console.log("_loginFailed() deRegisterUser res");
        _this.updateRegistrationProcess("LOGIN", stateType.LOGIN_STATE.LoggedOut);
        _this.updateRegistrationProcess("REGISTER", stateType.REGISTRATION_STATE.Deregistered);
        _this._resetVals();
        _this._logOffInternal("LOGIN_FAILED", reason, true);
      }).catch((err) => {
        console.log("_loginFailed() deRegisterUser err", err);
        _this.updateRegistrationProcess("LOGIN", stateType.LOGIN_STATE.LoggedOut);
        _this.updateRegistrationProcess("REGISTER", stateType.REGISTRATION_STATE.Deregistered);
        _this._resetVals();
        _this._logOffInternal("LOGIN_FAILED", reason, true);
      })
    }

    setAppLogObject(appContext) {
      this.appContext = appContext;
    }

    sendLogsToApp(eventName, info) {
      if (this.appContext) {
        this.appContext.sendLogs(eventName, info);
      }
    }

    _deRegisterMCX() {
      console.log("_deRegisterMCX()");
      this.terminateSessions();
      this._resetAllSubscriptionAndTimer();
      this.updateRegistrationProcess("REGISTER", stateType.REGISTRATION_STATE.Deregistered);
      const _this = this;
      return new Promise(function (resolve, reject) {
        if (_this._registrator) {
          _this._registrator._registered = false;
          _this._registrator
            .deRegister(false)
            .then((res) => {
              console.log("_deRegisterMCX() res..", res);
              _this._resetVals();

              if (_this.registerprocessSate.getLoginState() === stateType.LOGIN_STATE.Requested) {
                console.log("_deRegisterMCX() login requested started");
                _this._continueLogin();
              } else {
                _this.updateRegistrationProcess("LOGIN", stateType.LOGIN_STATE.LoggedOut);
              }
              resolve(res);
            })
            .catch((err) => {
              _this.updateRegistrationProcess("LOGIN", stateType.LOGIN_STATE.LoggedOut);
              _this.updateRegistrationProcess("REGISTER", stateType.REGISTRATION_STATE.Deregistered);
              _this._resetVals();
              console.log("_deRegisterMCX() error....", err);
              reject(err);
            });
        } else {
          _this.updateRegistrationProcess("LOGIN", stateType.LOGIN_STATE.LoggedOut);
          _this.updateRegistrationProcess("REGISTER", stateType.REGISTRATION_STATE.Deregistered);
          _this._resetVals();
          console.log("_deRegisterMCX() registrator object is not found");
          reject("registrator object is not found");
        }
      });
    }


    logoutAuth() {
      const _this = this;
      this.terminateSessions();
      this._resetAllSubscriptionAndTimer();
      this.updateRegistrationProcess("REGISTER", stateType.REGISTRATION_STATE.Deregistered);
      _this._registrator
        .deRegister(false)
        .then((res) => {
          console.log("logout response mcxclient..", res);
          _this.updateRegistrationProcess("LOGIN", stateType.LOGIN_STATE.LoggedOut);
          _this._deInitializer();
          _this.stop();
          _this.loginCompleteEventSent = false;
          _this.emit("LOGOUT", "");
        })
        .catch((err) => {
          _this.updateRegistrationProcess("LOGIN", stateType.LOGIN_STATE.LoggedOut);
          _this.updateRegistrationProcess("REGISTER", stateType.REGISTRATION_STATE.Deregistered);
          console.log("deregistration logout error....", err);
          _this._deInitializer();
          _this.stop();
          _this.loginCompleteEventSent = false;
          _this.emit("LOGOUT", "");
        });
    }

    deRegisterUser() {
      console.log("deRegisterUser()");
      this.terminateSessions();
      this._resetAllSubscriptionAndTimer();
      this.updateRegistrationProcess("REGISTER", stateType.REGISTRATION_STATE.Deregistered);
      const _this = this;
      return new Promise(function (resolve, reject) {
        if (_this._registrator) {
          _this._registrator._registered = false;
          _this._registrator
            .deRegister(false)
            .then((res) => {
              console.log("this._registering deRegisterUser res..", res);
              resolve(res);
            })
            .catch((err) => {
              console.log("deRegisterUser error....", err);
              reject(err);
            });
        } else {
          reject("deRegisterUser() _registrator object not found");
        }
      });
    }

    _logOffInternal(eventName = "", eventObj = "") {
      this.emit(eventName, eventObj);
    }

    fetchDialerDomainType() {
      let user = this.userLoginXCAP ? this.userLoginXCAP.userProfile : null;
      let mappedOption = [];
      let toIdDomain = "";
      console.log("fetchDialerDomainType...", user, this.domainsConfig);

      if (user && user.mappedType && user.mappedType.length > 0) {
        if (
          this.domainsConfig &&
          this.domainsConfig.iwfs &&
          this.domainsConfig.iwfs.length > 0
        ) {
          for (let index = 0; index < this.domainsConfig.iwfs.length; index++) {
            //'PBXIWF'
            const element = this.domainsConfig.iwfs[index];
            if (element && element.type == user.mappedType) {
              if (element.domain) {
                toIdDomain = element.mappedDomain;
              }
              break;
            }
          }
        }

        if (toIdDomain.length == 0) {
          if (user && user.domain) {
            toIdDomain = user.domain;
          } else {
            toIdDomain =
              this.domainsConfig && this.domainsConfig.mcx
                ? this.domainsConfig.mcx
                : "mcx";
          }
        }

        if (toIdDomain && toIdDomain.includes("pbx")) {
          let mappedType = {
            type: user.mappedType,
            domain: toIdDomain,
            individualCalls: ["DUPLEX_INDIVIDUAL_CALL"],
            groupCalls: [],
          };
          mappedOption = [...mappedOption, mappedType];
        } else {
          let mappedType = {
            type: user.mappedType,
            domain: toIdDomain,
            individualCalls: [
              "DUPLEX_INDIVIDUAL_CALL",
              "SIMPLEX_INDIVIDUAL_HOOK_CALL",
              "SIMPLEX_INDIVIDUAL_DIRECT_CALL",
            ],
            groupCalls: ["SIMPLEX_GROUP_CALL", "SIMPLEX_BROADCAST_GROUP_CALL"],
          };
          mappedOption = [...mappedOption, mappedType];
        }
      }

      if (user && user.tetra == false) {
        if (user && user.domain) {
          toIdDomain = user.domain;
        } else {
          toIdDomain =
            this.domainsConfig && this.domainsConfig.mcx
              ? this.domainsConfig.mcx
              : "mcx";
        }
        let mappedType = {
          type: "MCX",
          domain: toIdDomain,
          individualCalls: [
            "DUPLEX_INDIVIDUAL_CALL",
            "SIMPLEX_INDIVIDUAL_HOOK_CALL",
            "SIMPLEX_INDIVIDUAL_DIRECT_CALL",
          ],
          groupCalls: ["SIMPLEX_GROUP_CALL", "SIMPLEX_BROADCAST_GROUP_CALL"],
        };
        mappedOption = [...mappedOption, mappedType];
      }
      console.log("mappedOption......", mappedOption);
      return mappedOption;
    }

    _resetAllSubscriptionAndTimer() {
      if (this._registrator &&
        this._registrator._registrationTimer) {
        clearTimeout(this._registrator._registrationTimer);
        this._registrator._registrationTimer = null;
      }

      this.tokenRefresher && clearTimeout(this.tokenRefresher);
      this.tokenRefresher = null;

      this._FATimer && clearTimeout(this._FATimer);
      this._FATimer = null;

      this.continueLogginTimer && clearTimeout(this.continueLogginTimer);
      this.continueLogginTimer = null;

      this.reloginTimer && clearTimeout(this.reloginTimer);
      this.reloginTimer = null;

      this.releasePTTCurrentCall = null;
      this.removeAllMcpcConnectTimersForRefer();
    }

    getRegisterstationStatus() {
      return this.registerprocessSate.isRegistered();
    }

    updateContactsXCAPNotify(respApi) {
      //console.log('updateContactsXCAPNotify.....', this.globalContactList, this.globalUserRegList)
      for (let i = 0; i < this.globalContactList.length; i++) {
        let current_user_uri = this.globalContactList[i].mcptt_id;
        if (current_user_uri) {
          let subsriber = null;
          if (this.globalUserRegList.has(current_user_uri.trim())) {
            subsriber = current_user_uri.trim();
          }
          if (subsriber) {
            if (Number(this.globalContactList[i]['Reg_status']) === 2) {
              this.globalContactList[i]['Reg_status'] = "3";
            }
          } else if (Number(this.globalContactList[i]['Reg_status']) === 3) {
            this.globalContactList[i]['Reg_status'] = '2';
          }
        }
      }

      this.emit("Contactlist", this.globalContactList);

      if (respApi) {
        let respUserProfile = this.cmc.userProfileToJson(respApi.data);

        let userProfile = {
          ...respUserProfile,
          domain: this.userDomain,
          mcptt_uri: this.mcptt_uri,
        };
        this.emit("Profile", userProfile);
        this.mcxUserProfile = { ...userProfile };
        this.userLoginXCAP.userProfile = userProfile;
        this.userLoginXCAP.contactlist = this.globalContactList;
        this.formatedFAList();
        this.formatedAffiliatedGroupList()
        // console.log("updateContactsXCAPNotify profile update...", userProfile);
      }
    }

    fetchRegisteredMcpttIdWithUEId() {
      let onlineMcpttIDWithUEIds = [];
      for (let i = 0; i < this.globalContactList.length; i++) {
        let current_user_uri = this.globalContactList[i].mcptt_id;
        if (current_user_uri) {
          let subsriber = null;
          if (this.globalUserRegList.has(current_user_uri.trim())) {
            subsriber = current_user_uri.trim();
          }
          if (subsriber) {
            let data = {
              mcpttId: this.globalContactList[i].mcptt_id,
              ueId: this.globalContactList[i].mcptt_uri,
              ueType: this.globalContactList[i].subscriber_type,
            };
            onlineMcpttIDWithUEIds = [...onlineMcpttIDWithUEIds, data];
          }
        }
      }
      // console.log(
      //   "USER_REG_STATUS registeredUEIds...",
      //   onlineMcpttIDWithUEIds,
      //   this.globalUserRegList
      // );
      this.emit("registeredUEIds", onlineMcpttIDWithUEIds);
    }

    /*............... Affiliated/Deaffiliated Group List Methods ..............*/

    formatedAffiliatedGroupList() {
      if (this.userLoginXCAP && this.userLoginXCAP.contactlist) {
        // console.log("this.userLoginXCAP.contactlist...", this.userLoginXCAP.contactlist);
        let groupList = this.userLoginXCAP.contactlist.filter(
          (grp) => grp.subscriber_type == "GROUP"
        );
        if (groupList && groupList.length > 0) {
          for (const element of groupList) {
            let groupFound = false;

            for (let grpObj of this.assignGroupList) {
              if (grpObj.groupId == element.mcptt_id) {
                groupFound = true;
                grpObj.groupId = element.mcptt_id;
                grpObj.autoaffiliated = element.isImplicitAffiliated;
                grpObj.affiliated = grpObj.affiliated
                  ? true
                  : element.isImplicitAffiliated;
                grpObj.groupName = element.contactName;
                break;
              }
            }

            if (!groupFound) {
              let grpObj = {
                groupId: element.mcptt_id,
                autoaffiliated: element.isImplicitAffiliated,
                affiliated: element.isImplicitAffiliated,
                groupName: element.contactName,
              };
              this.assignGroupList = [...this.assignGroupList, grpObj];
            }
          }
        }
      }
      // console.log("affiliated_group formated list...", this.assignGroupList);
    }

    makeAffiliatedGroupList(grpList) {
      // console.log(
      //   "affiliated_group list before.....",
      //   grpList,
      //   this.assignGroupList
      // );
      //       GROUP_AFFILIATED ["partner@mcx.consort.org", "NMTM@mcx.consort.org", "rtrtrt@mcx.consort.org", "testing@mcx.consort.org", "sidDgna@mcx.consort.org"]
      //  LOG  affiliated_group list after..... ["NMTM@mcx.consort.org", "partner@mcx.consort.org", "rtrtrt@mcx.consort.org", "testing@mcx.consort.org"] [] [{"affiliated": false, "autoaffiliated": false, "groupId": "operation@mcx.consort.org", "groupName": "operation"}, {"affiliated": true, "autoaffiliated": true, "groupId": "NMTM@mcx.consort.org", "groupName": "NMTM"}, {"affiliated": true, "autoaffiliated": true, "groupId": "partner@mcx.consort.org", "groupName": "partner"}, {"affiliated": true, "autoaffiliated": true, "groupId": "rtrtrt@mcx.consort.org", "groupName": "rtrtrt"}, {"affiliated": true, "autoaffiliated": true, "groupId": "testing@mcx.consort.org", "groupName": "testing"}, {"affiliated": true, "autoaffiliated": true, "groupId": "sidDgna@mcx.consort.org", "groupName": "sidDgna"}]
      //  LOG  Current affiliated 121212121 []
      //  LOG  MCX GROUPS_AFFILIATED ["NMTM@mcx.consort.org", "partner@mcx.consort.org", "rtrtrt@mcx.consort.org", "testing@mcx.consort.org"] ["NMTM@mcx.consort.org", "partner@mcx.consort.org", "rtrtrt@mcx.consort.org", "testing@mcx.consort.org"]

      // GROUP_AFFILIATED ["partner@mcx.consort.org", "NMTM@mcx.consort.org", "rtrtrt@mcx.consort.org", "testing@mcx.consort.org", "sid3Dgna@mcx.consort.org"]

      // [{"affiliated": false, "autoaffiliated": false, "groupId": "operation@mcx.consort.org", "groupName": "operation"}, 
      // {"affiliated": true, "autoaffiliated": true, "groupId": "NMTM@mcx.consort.org", "groupName": "NMTM"}, 
      // {"affiliated": true, "autoaffiliated": true, "groupId": "partner@mcx.consort.org", "groupName": "partner"}, 
      // {"affiliated": true, "autoaffiliated": true, "groupId": "rtrtrt@mcx.consort.org", "groupName": "rtrtrt"}, 
      // {"affiliated": true, "autoaffiliated": true, "groupId": "testing@mcx.consort.org", "groupName": "testing"}]

      console.log("makeAffiliatedGroupList()2...", this.assignGroupList)
      let deaffiliatedGroups = [];
      let affiliatedGroups = [];

      if (this.assignGroupList) {
        for (let grpObj of this.assignGroupList) {
          let affiliated = false;
          for (const grpL of grpList) {
            if (grpL == grpObj.groupId) {
              affiliated = true;
              break;
            }
          }
          if (!affiliated) {
            // group is deaffiliated
            if (grpObj.autoaffiliated) {
              grpObj.autoaffiliated = false;
            }
            if (grpObj.affiliated) {
              deaffiliatedGroups = [...deaffiliatedGroups, grpObj.groupId];
            }
          } else {
            affiliatedGroups = [...affiliatedGroups, grpObj.groupId];
          }
          grpObj.affiliated = affiliated;
        }
      }
      this.formatedAffiliatedGroupList();
      console.log(
        "affiliated_group list after.....",
        affiliatedGroups,
        deaffiliatedGroups,
        this.assignGroupList
      );
      return {
        affiliations: affiliatedGroups,
        deaffiliations: deaffiliatedGroups,
      };
    }

    fetchAffiliateGroupList() {
      let affilatedGrpList = this.assignGroupList
        .filter((grp) => grp.affiliated == true || grp.autoaffiliated == true)
        .map((grpObj) => {
          return grpObj.groupId;
        });
      console.log("affiliated_group list final.....", affilatedGrpList);
      return affilatedGrpList;
    }

    fetchDeAffiliateGroupList() {
      let affilatedGrpList = this.assignGroupList
        .filter((grp) => grp.affiliated == false)
        .map((grpObj) => {
          return grpObj.groupId;
        });
      console.log(
        "affiliated_group deaffiliate list final.....",
        affilatedGrpList
      );
      return affilatedGrpList;
    }

    /*............... FA List Methods ..............*/
    faStatusList(fas) {
      console.log(
        "falistttt faStatus list before.....",
        fas,
        this.assignFaList
      );
      if (this.assignFaList) {
        if (fas && fas.length > 0) {
          for (let faObj of this.assignFaList) {
            let status = "deactivated";
            for (const fa of fas) {
              if (fa.name == faObj.name) {
                status = fa.status;
                break;
              }
            }
            faObj.status = status;
          }
        } else {
          this.assignFaList = this.assignFaList.map((faObj) => {
            let status = "deactivated";
            faObj.status = status;
            // this.assignFaList[faObj] = faObj;
            return faObj;
          });
        }
      }
      console.log("falistttt faStatus list after.....", this.assignFaList);
      return this.assignFaList;
    }

    formatedFAList() {
      let faListInfo = this.userLoginXCAP ? this.userLoginXCAP.userProfile && this.userLoginXCAP.userProfile.faList ?
        this.userLoginXCAP.userProfile.faList : this.userLoginXCAP.faList : []
      if (faListInfo) {
        faListInfo.forEach((element) => {
          let foundInFAList = this.assignFaList.filter(
            (fa) => fa.CallerDescr == element.CallerDescr
          );
          if (foundInFAList.length == 0) {
            let faobj = {
              name: element.name,
              status: element.status ? element.status : "deactivated",
              autoactivated: element.default
                ? JSON.parse(element.default)
                : false,
              ueType: element.ueType ? element.ueType : "",
              CallerDescr: element.CallerDescr ? element.CallerDescr : "",
              default: element.default ? JSON.parse(element.default) : false,
            };
            this.assignFaList = [...this.assignFaList, faobj];
          }
        });
      }
      console.log("falistttt formated faList...", this.assignFaList);
    }

    fetchFAs() {
      return this.assignFaList;
    }

    restoreFAS() {
      //console.log("restore fas method called...", this.assignFaList);
      let restoreFAList = [];
      for (let faObj of this.assignFaList) {
        if (faObj.status == "activated" && faObj.autoactivated == false) {
          restoreFAList = [...restoreFAList, faObj.CallerDescr];
        }
      }
      //console.log("restore fas val...", restoreFAList);
      if (restoreFAList.length > 0) {
        // store fa activation
        let data = {
          mcpttId: this.mcptt_uri,
          fas: restoreFAList,
        };
        //console.log("restore fas....", data);
        this.activateFAs(data);
      }
    }

    sendCADAction(callObj) {
      console.log("cad call action...", callObj);
      if (callObj && callObj.stateType === "GRANT_REQUEST") {
        if (callObj.message) {
          callObj.message.accept();
        }
      } else {
        if (callObj && callObj.message) {
          callObj.message.reject();
        }
      }
    }

    patchCalls(callObject) {
      const _this = this;
      callObject.requestId = Math.random().toString(36).slice(2);
      callObject.creatorId = _this.mcptt_uri;
      let patchCallState = {
        communicationType: "CALL_STATE_UPDATE",
        callType: "PATCH_CALL",
        stateType: "SUCCESS",
        requestId: callObject.requestId,
      };
      console.log("patchCalls data..", callObject);
      let message = this.delegates.createXml(callObject);
      let eventHandlers = {
        succeeded: function (data) {
          console.log("success sending patch call msg", data);
        },
        failed: function (data) {
          console.log("Failed sending patch call msg", data);
        },
      };
      let msgHandle = _this.sendMessage(
        "sip:mcdx@mcptt.org",
        message,
        eventHandlers
      );
      msgHandle.on("succeeded", () => {
        patchCallState.stateType = "SUCCESS";
        patchCallState.sdsType = "STATE_UPDATE";
        console.log("patchcall msg SUCCESS msgHandle", patchCallState);
        _this.emit("PATCH_CALL_STATE_UPDATE", patchCallState);
      });
      msgHandle.on("failed", () => {
        console.log("patchcall msg FAILED msgHandle");
        patchCallState.stateType = "FAILED";
        patchCallState.sdsType = "STATE_UPDATE";
        _this.emit("PATCH_CALL_STATE_UPDATE", patchCallState);
      });
      return callObject.requestId;
    }

    patchCallsAction(data) {
      console.log("patchCalls action..");
      const patchCall = this.ongoingCalls.find(
        (c) =>
          c.callData.callType === "PATCH_CALL" &&
          ((patchCall.callData && patchCall.callData.callId1 === data.callId) ||
            (patchCall.callData && patchCall.callData.callId2 === data.callId))
      );
      if (patchCall && patchCall.callData) {
        //console.log("Patch Call object found: ", patchCall);
        if (patchCall.callData.callId1 === data.callId) {
          console.log("Call ID ", data.callId, " Ended from patchcall");
          patchCall.callData.callOneEnded = true;
          const index = this.ongoingCalls.findIndex(
            (c) => c.callData.requestId === patchCall.callData.requestId
          );
          this.ongoingCalls[index] = patchCall;
        }
        if (patchCall.callData.callId2 === data.callId) {
          console.log("Call ID ", data.callId, " Ended from patchcall");
          patchCall.callData.callTwoEnded = true;
          const index = this.ongoingCalls.findIndex(
            (c) => c.callData.requestId === patchCall.callData.requestId
          );
          this.ongoingCalls[index] = patchCall;
        }

        if (
          patchCall.callData.callTwoEnded &&
          patchCall.callData.callTwoEnded
        ) {
          let success = {
            communicationType: "CALL_STATE_UPDATE",
            callType: "PATCH_CALL",
            stateType: "SUCCESS",
            requestId: patchCall.callData.requestId,
          };
          this.emit("PATCH_CALL_STATE_UPDATE", success);
        }
      }
    }

    mergeCalls(callObject) {
      const _this = this;
      callObject.requestId = Math.random().toString(36).slice(2);
      callObject.creatorId = _this.mcptt_uri;
      let mergeCallState = {
        communicationType: "CALL_STATE_UPDATE",
        callType: "MERGE_CALL",
        stateType: "SUCCESS",
        requestId: callObject.requestId,
      };
      console.log("mergeCalls data..", callObject);
      let message = this.delegates.createXml(callObject);
      let eventHandlers = {
        succeeded: function (data) {
          console.log("success sending merge call msg", data);
        },
        failed: function (data) {
          console.log("Failed sending merge call msg", data);
        },
      };
      let msgHandle = _this.sendMessage(
        "sip:mcdx@mcptt.org",
        message,
        eventHandlers
      );
      msgHandle.on("succeeded", () => {
        mergeCallState.stateType = "SUCCESS";
        mergeCallState.sdsType = "STATE_UPDATE";
        console.log("mergecall msg SUCCESS msgHandle", mergeCallState);
        _this.emit("MERGE_CALL_STATE_UPDATE", mergeCallState);
      });
      msgHandle.on("failed", () => {
        console.log("mergecall msg FAILED msgHandle");
        mergeCallState.stateType = "FAILED";
        mergeCallState.sdsType = "STATE_UPDATE";
        _this.emit("MERGE_CALL_STATE_UPDATE", mergeCallState);
      });
      return callObject.requestId;
    }

    mergeCallsAction(data) {
      console.log("mergeCallsAction ongoingCalls ");
      const mergeCall = this.ongoingCalls.find(
        (c) =>
          c.callData.callType === "MERGE_CALL" &&
          ((mergeCall.callData && mergeCall.callData.callId1 === data.callId) ||
            (mergeCall.callData && mergeCall.callData.callId2 === data.callId))
      );
      console.log("mergeCallsAction mergeCall found ", mergeCall);
      if (mergeCall && mergeCall.callData) {
        if (mergeCall.callData.callId1 === data.callId) {
          console.log("Call ID ", data.callId, " Ended from MERGE call");
          mergeCall.callData.callOneEnded = true;
          const index = this.ongoingCalls.findIndex(
            (c) => c.callData.requestId === mergeCall.callData.requestId
          );
          this.ongoingCalls[index] = mergeCall;
        }
        if (mergeCall.callData.callId2 === data.callId) {
          console.log("Call ID ", data.callId, " Ended from mergecall");
          mergeCall.callData.callTwoEnded = true;
          const index = this.ongoingCalls.findIndex(
            (c) => c.callData.requestId === mergeCall.callData.requestId
          );
          this.ongoingCalls[index] = mergeCall;
        }

        if (
          mergeCall.callData.callTwoEnded &&
          mergeCall.callData.callTwoEnded
        ) {
          let success = {
            communicationType: "CALL_STATE_UPDATE",
            callType: "MERGE_CALL",
            stateType: "SUCCESS",
            requestId: mergeCall.callData.requestId,
          };
          this.emit("MERGE_CALL_STATE_UPDATE", success);
        }
      }
    }

    getOngoingCalls() {
      let ongoingCall = [];
      this.ongoingCalls.forEach((call) => {
        ongoingCall.push(call.callObj);
      });
      return ongoingCall;
    }

    getCallObjFrommCallId(callId) {
      let callObj = null;
      for (const sesObj of this.ongoingCalls) {
        if (sesObj.callData && sesObj.callData.callId == callId) {
          callObj = sesObj.callData;
          break;
        }
      }
      return callObj;
    }

    updateSdpOnPTT(talkingPartyId, call) {
      let session;
      if (call && call.session) {
        session = call.session;
        //console.log("session..........", call, call.session);
      } else {
        console.log("session.......... null");
        return;
      }

      if (!this.checkPTTStatus(talkingPartyId)) {
        try {
          console.log("sdp recvonl 3");
          let res = session._updateDirTransForPeerConnection("recvonly");
          if (!res) {
            console.log("set transceiver direction with SDP --- recvonly");
            this.sdptransform.updateLocalDescriptionStateRecieveOrSend(
              session,
              "recvonly"
            );
          }
        } catch (err) {
          console.log("localDesc........... 3", err);
        }
      } else {
        try {
          console.log("sdp recvonl 4");
          let res = session._updateDirTransForPeerConnection("sendonly");
          if (!res) {
            console.log("set transceiver direction with SDP --- sendonly");
            this.sdptransform.updateLocalDescriptionStateRecieveOrSend(
              session,
              "sendonly" //"recvonly" //"sendonly"
            );
          }
        } catch (err) {
          console.log("localDesc........... 45", err);
        }
      }
    }
    getPreEstSessionURI(session) {
      console.log(
        "getPreEstSessionURI...........",
        session._contact,
        session.contact
      );
      // 1679387000961@consort.org
      let preEstSessionUri = session._contact
        ? session._contact.replace("<", "").replace(">", "").replace("sip:", "")
        : "";
      if (preEstSessionUri.includes(";")) {
        preEstSessionUri = preEstSessionUri.split(";")[0];
      }
      return preEstSessionUri;
    }

    resetPTTCallStatus(callId = null) {
      try {
        console.log("resetPTTCallStatus() ", callId, this.releasePTTCurrentCall);
        if (callId && this.releasePTTCurrentCall && this.releasePTTCurrentCall.callId == callId) {
          this.releasePTTCurrentCall = null;
          console.log("resetPTTCallStatus() reset");
        } else if (!callId || !this.releasePTTCurrentCall) {
          this.releasePTTCurrentCall = null;
        }
      } catch (err) {
        console.log("resetPTTCallStatus()", err)
        this.releasePTTCurrentCall = null;
      }
    }

    replaceTracksWhenDeviceChanges(track = null, callId = null) {
      console.log("replaceTracksWhenDeviceChanges()");
      const uniqueCallObj = new Map();
      if (this.ongoingPreestablishedCallSessions.length > 0) {
        for (
          let index = 0;
          index < this.ongoingPreestablishedCallSessions.length;
          index++
        ) {
          let callObj = this.ongoingPreestablishedCallSessions[index];
          //console.log("replaceTracksWhenDeviceChanges()", callObj);
          if (callObj.session && callObj.callId) {
            uniqueCallObj.set(callObj.callId, callObj.session);
          }
        }
      }

      if (this.ongoingCalls.length > 0) {
        let callObj = this.ongoingCalls[0];
        //console.log("replaceTracksWhenDeviceChanges() ongoingCalls.. ", callObj);
        if (callObj.session && callObj.callData && callObj.callData.callId) {
          uniqueCallObj.set(callObj.callData.callId, callObj.session);
        }
      }

      for (const [key, value] of uniqueCallObj) {
        console.log("replaceTracksWhenDeviceChanges() map", key);
        if (callId) {
          if (key == callId) {
            value._replaceTracksWhenInputDeviceChanges(track);
          }
        } else {
          value._replaceTracksWhenInputDeviceChanges(track);
        }
      }
      this.logDeviceChanges();
    }

    logDeviceChanges() {
      let insertionData = {
        id: Math.random().toString(36).slice(2),
        messageId: Math.random().toString(36).slice(2),
        message: "Input mic changes",
        toId: this.mcptt_uri,
        fromId: this.mcptt_uri,
        groupId: '',
        consumedReportNeeded: false,
        deliveryReportNeeded: false,
        immediate: false,
        sdsType: "DEVICE_CHANGES",
        communicationType: "SDS",
        stateType: 'PERSISTED',
        creatorId: this.mcptt_uri,
        messageType: 'text',
        fileId: '',
        view: true,
        UEid: '0000',
        sendReceive: "Send",
        contactId: this.mcptt_uri
      }
      console.log("DeviceChanges store msg ", insertionData);
      if (this.store) {
        this.store.addSDS(insertionData);
      }
    }

    checkAllTrack() {
      if (this.ongoingPreestablishedCallSessions.length > 0) {
        for (
          let index = 0;
          index < this.ongoingPreestablishedCallSessions.length;
          index++
        ) {
          let callObj = this.ongoingPreestablishedCallSessions[index];

          if (callObj.session && callObj.callId) {
            let localDesc = callObj.session.connection.localDescription;
            console.log("checkAllTrack()", localDesc.sdp);
          }
        }
      }
    }

    initiateCall(data, isCreatePreEstSession = false, callForwardObj = null) {
      console.log("initiateCall() ", isCreatePreEstSession, this.registerprocessSate.getRegistrationState());
      if (!this.registerprocessSate.isRegistered()) {
        console.log("initiateCall() Mcxclient is not registered ");
        return null;
      }
      let grpCall = this.isSameGroupCallRunning(data);
      if (!isCreatePreEstSession && grpCall) {
        console.log("initiateCall() Same call is already running ", data);
        if (data && data.callPriority && parseInt(data.callPriority) === 15) {
          if (data.callType && data.callType.includes("GROUP")) {
            if (parseInt(data.callPriority) > parseInt(grpCall.callPriority)) {
              return this.sendReinviteForPriorityChange(data, 15, grpCall);
            }
          }
        }
        return null;
      }
      const _this = this;

      if (!isCreatePreEstSession) {
        if (
          callForwardObj ||
          (this.preEstCallTypeAllowed &&
            !this.preEstCallTypeAllowed[data.callType]) || data.isVideo ||
          data.isFACall
        ) {
          console.log("initiateCall() onDemand call", data, this.preEstCallTypeAllowed);
        } else {
          let callObj = this.initiatePreEstCall(data, false); //Need to pass this Pre Est call object  to application level
          if (callObj && callObj.callId) {
            try {
              if (this.isAndroidOS()) {
                delete data["localAudio"];
                console.log("clear local audio 2")
              }
            } catch (err) {
              console.error("delete local audio", err)
            }
            if (
              data.callType == "DUPLEX_INDIVIDUAL_CALL" ||
              data.callType == "SIMPLEX_INDIVIDUAL_HOOK_CALL"
            ) {
              const _this = this;
              callObj.callActionType = "RINGING";
              setTimeout(() => {
                if (callObj && callObj.callId) {
                  if (_this.getRunningOngoingCall(callObj.callId)) {
                    _this.emit("CALL_ACTION", callObj);
                  }
                }
              }, 300);
            }
            return callObj.callId;
          }
        }
      }

      let eventHandlers = {
        progress: function (data1) {
          console.log("Call in progress handler", data);
        },
        failed: function (data1) {
          console.log("Call failed handler", data);
          if (isCreatePreEstSession) {
            _this.preEstSessionCreationRunning = false;
          }
        },
        confirmed: function (data) {
          console.log("Call Confirmed handler");
        },
        onaddstream: function (data) {
          console.log("adding stream handler");
        },
        ended: function (data1) {
          console.log("Call Ended handler");
          if (isCreatePreEstSession) {
            _this.preEstSessionCreationRunning = false;
          }
        },
      };
      let options = {
        mediaStream: data.localAudio,
        eventHandlers: eventHandlers,
        //extraHeaders: this.getExtraHeaders(data),
        mediaConstraints: { audio: true, video: data.isVideo ? true : false },
        /* 'pcConfig': {
                  'rtcpMuxPolicy': 'require',
                  'iceServers': [
                  ]
                }, */
        rtcOfferConstraints: { iceRestart: true }
      };

      const session = this.call(_this.participatingServer, options);
      if (session._request && session._request.call_id) {
        data.callId = session._request.call_id;
        console.log("MCX initiate call request id", session._request.call_id);
      } else {
        data.callId = session._id;
        console.log("MCX initiate call session id", session._id);
      }
      console.log("MCX initiate call ", data, "\n\n\n", options);
      const currentCall = {
        session: session,
        callData: data,
        status: "initiated",
      };
      if (callForwardObj && callForwardObj.initialCall) {
        callForwardObj.initialCall.callData.callId1 = data.callId;
        callForwardObj.initialCall.callData.forwardedId = data.toId;
      }

      if (
        data.callType !== "DUPLEX_INDIVIDUAL_CALL" &&
        _this.isMcxUserAgent()
      ) {
        console.log("MCX initiate call 2");
        session.on("sdp", (event) => {
          console.log("MCX initiate call sdp", event);
          if (
            event.originator === "remote" &&
            event.type.toLowerCase() === "answer"
          ) {
            let sdp = event.sdp;
            let remotePort = _this.sdptransform.isMLineExistsApplication(sdp);
            let remotePortIp = _this.sdptransform.getCLineIp(sdp);
            if (remotePort && remotePortIp) {
              console.log("sdp event ", remotePort, remotePortIp, event);
              let udpport = _this.pttMsgClient.setRemote(
                data.callId,
                remotePort,
                remotePortIp
              );
              //delete sdp
              console.log("delete ------------------ 2 ", sdp);
              sdp = _this.sdptransform.deleteMApplicationLine(sdp);
              // let parsedSdp = _this.sdptransform.getParsedSdp(sdp);
              // delete parsedSdp["media"][1];
              // sdp = _this.sdptransform.writeJsonToSdp(parsedSdp);
              console.log("delete ------------------ 2 after ", sdp);
              event.sdp = sdp;
            } else {
              // let sdp = event.sdp;
              console.log("sdp event err ", remotePort, remotePortIp, event);
              // sdp = _this.sdptransform.deleteMApplicationLine(sdp);
              // console.log("delete ------------------ 2 after ", sdp);
              // event.sdp = sdp;
            }
          } else {
          }
        });
      } else if (isCreatePreEstSession) {
        session.on("sdp", (event) => {
          console.log("MCX initiate call sdp isCreatePreEstSession");
          if (
            event.originator === "local" &&
            event.type.toLowerCase() === "offer"
          ) {
            event.sdp = _this.sdptransform.removeExtmapFromSDP(event.sdp);
          } else if (
            event.originator === "remote" &&
            event.type.toLowerCase() === "answer"
          ) {
            console.log(
              "MCX initiate call sdp isCreatePreEstSession answer"
            );
            let sdp = event.sdp;
            if (_this.sessionBandwidthInKbps > 5) {
              console.log(
                "MCX initiate call sdp.. isCreatePreEstSession bandwidth set"
              );
              event.sdp = _this.updateBandwidthRestriction(
                sdp,
                _this.sessionBandwidthInKbps
              );
              console.log("sdp bandwidth update", event.sdp);
            }
          }
        });
      }

      session.on("sending", (request) => {
        // make ptt message
        let sdpStr = request.request.body;
        if (
          data.callType !== "DUPLEX_INDIVIDUAL_CALL" &&
          _this.isMcxUserAgent()
        ) {
          // ptt call
          const pttCallBack = (err, messageType, message) => {
            console.log("outgoing callback....", err, messageType, message);

            let parsedMessage = _this.pttMsgClient.getMessage(
              messageType,
              message,
              data
            );
            console.log("outgoing callback...parsed 2 ", parsedMessage);
            if (!isCreatePreEstSession) {
              _this.updateSdpOnPTT(parsedMessage.talkingPartyId, data);
              _this.emit(parsedMessage.communicationType, parsedMessage);
            } else {
              this.handlePttCallPreEstCall(parsedMessage);
            }
          };
          let udpport = _this.pttMsgClient.allocate(
            data.callId,
            null,
            null,
            pttCallBack
          );
          let parsedSdp = _this.sdptransform.addApplicationMLineInSdp(
            sdpStr,
            udpport
          );
          console.log("MLine sdp.......", udpport, parsedSdp, sdpStr);
          request.request.body = parsedSdp;
        }
        if (!isCreatePreEstSession) {
          let callType = "private";
          let answerMode = "Manual";

          let contact = _this._registrator._contact;
          let callerUri = _this.mcptt_uri;
          let sessionType = "private";
          // console.log(
          //   "CallType data..........",
          //   data.callType,
          //   data.callType.toLowerCase().includes("group")
          // );
          if (data.callType.toLowerCase().includes("group")) {
            callType = "prearranged";
            sessionType = "prearranged";
            answerMode = "Auto";
          }
          if (
            data.callType == "SIMPLEX_INDIVIDUAL_DIRECT_CALL" ||
            data.callType == "AMBIENT_LISTENING_CALL"
          ) {
            answerMode = "Auto";
          }
          if (data.callType == "AMBIENT_LISTENING_CALL") {
            sessionType = "ambient-listening";
            callType = "ambient-listening";
            answerMode = "Auto";
          }

          if (data.isFACall) {
            callType = "first-to-answer";
            sessionType = "first-to-answer";
          }
          //getHeadersAndBody(callObject, mangledSdp, callType, answerMode, contact, To, sessionType)
          let isPTTCall =
            data.callType == "DUPLEX_INDIVIDUAL_CALL" ? false : true;

          let isBroadcastCall =
            data.callType == "SIMPLEX_BROADCAST_GROUP_CALL" ? true : false;

          let isEmgCall =
            data.callPriority && parseInt(data.callPriority) == 15
              ? true
              : false;

          /*if (data.pbxUser) {
            if (data.pbxDomain) {
              data.toId = data.toId + "@" + data.pbxDomain;
            } else {
              data.toId = data.toId + "@" + "pbx";
            }
          }*/
          let newRequest = _this.callheader.getHeadersAndBody(
            data,
            request.request.body,
            callType,
            answerMode,
            contact,
            callerUri,
            sessionType,
            isPTTCall,
            isBroadcastCall,
            isEmgCall
          );

          request.request.extraHeaders = newRequest.extraHeaders;
          request.request.body = newRequest.body;
        } else {
          console.log(
            "MCX initiate call sendning..... isCreatePreEstSession",
            true
          );
        }
      });

      session.on("sending_reinvite", (request) => {
        let isEmergency = request.request.isEmergency;
        let callId = data.callId; //request.request.getHeader("Call-ID");
        console.log(
          "sending_reinvite......",
          isEmergency,
          isCreatePreEstSession,
          data,
          callId
        );

        if (!isCreatePreEstSession) {
          let callType = "private";
          let answerMode = "Manual";

          let contact = _this._registrator._contact;
          let callerUri = _this.mcptt_uri;
          let sessionType = "private";

          if (data.callType.toLowerCase().includes("group")) {
            callType = "prearranged";
            sessionType = "prearranged";
            answerMode = "Auto";
          }
          if (data.callType == "SIMPLEX_INDIVIDUAL_DIRECT_CALL") {
            answerMode = "Auto";
          }
          if (data.isFACall) {
            callType = "first-to-answer";
            sessionType = "first-to-answer";
          }
          //getHeadersAndBody(callObject, mangledSdp, callType, answerMode, contact, To, sessionType)
          let isPTTCall =
            data.callType == "DUPLEX_INDIVIDUAL_CALL" ? false : true;

          let isBroadcastCall =
            data.callType == "SIMPLEX_BROADCAST_GROUP_CALL" ? true : false;

          let isEmgCall = isEmergency
            ? isEmergency
            : data.callPriority && parseInt(data.callPriority) == 15
              ? true
              : false;

          if (data.pbxUser) {
            if (data.pbxDomain) {
              data.toId = data.toId + "@" + data.pbxDomain;
            } else {
              data.toId = data.toId + "@" + "pbx";
            }
          }
          let newRequest = _this.callheader.getHeadersAndBody(
            data,
            request.request.body,
            callType,
            answerMode,
            contact,
            callerUri,
            sessionType,
            isPTTCall,
            isBroadcastCall,
            isEmgCall
          );
          // headersBody["headers"] = headers;
          //   headersBody["extraHeaders"] = extraHeaders;
          //   headersBody["body"] = bodyMcptt;
          // request.request.headers = newRequest.headers;
          request.request.extraHeaders = newRequest.extraHeaders;
          request.request.body = newRequest.body;
          console.log(
            "outgoing call request final....",
            request,
            request.request.body
          );
        } else {
          console.log(
            "MCX initiate call sendning..... isCreatePreEstSession",
            true
          );
        }
      });

      session.on("failed", (e) => {
        //remote.log("Failed :", e);
        console.log("Initiate call failed ..", e, data);

        if (isCreatePreEstSession) {
          const runningCall = _this.getRunningOngoingCall(data.callId);
          console.log("Initiate call failed presession...", runningCall);
          if (runningCall && runningCall.preEstSessionUri) {
            _this.removeMcpcConnectTimersForRefer(runningCall.preEstSessionUri);
          }
          if (runningCall && runningCall.callData) {
            let updatedRunningCall = { ...runningCall.callData };
            updatedRunningCall.communicationType = "CALL_ACTION";
            updatedRunningCall.callActionType = "DISCONNECTED";
            updatedRunningCall.creatorId = _this.mcptt_uri;
            updatedRunningCall.UEid = "0000";
            updatedRunningCall.stateType = "DISCONNECT";
            _this.emit("CALL_ACTION", updatedRunningCall);
          }
          _this.preEstSessionCreationRunning = false;
          _this.removePreEstSession(data, true);
          return;
        }

        if (
          _this.callforwardingArr &&
          _this.foundInitialCallForward(data.callId)
        ) {
          if (_this.pendingPTT[data.callId]) {
            delete _this.pendingPTT[data.callId];
          }
          _this.removeOnGoingArrayCall(data);
          console.log("Initiate call failed inside..");
          return;
        }
        if (_this.pendingPTT[data.callId]) {
          delete _this.pendingPTT[data.callId];
        }
        _this.removeOnGoingArrayCall(data);
        if (e && e.response && e.response.status_code) {
          data.status_code = e.response.status_code;
        }
        data.callActionType = "REJECTED";
        if (callForwardObj && callForwardObj.initialCall) {
          _this.removeCallForwardingArrayCall(data.callId);
          let modifiedData = { ...callForwardObj.initialCall.callData };
          modifiedData.callActionType = "REJECTED";
          modifiedData.communicationType = "CALL_ACTION";
          modifiedData.creatorId = _this.mcptt_uri;
          modifiedData.UEid = "0000";
          console.log(
            "CALL_ACTION call failed..",
            modifiedData,
            _this.callforwardingArr
          );
          _this.emit("CALL_ACTION", modifiedData);
        } else {
          _this.emit("CALL_ACTION", data);
        }
      });

      session.on("ended", (res) => {
        console.log("Call Ended: initiateCall()..", data.callId);
        if (isCreatePreEstSession) {
          const runningCall = _this.getRunningOngoingCall(data.callId);
          console.log("Call Ended: initiateCall() inside presession...");
          if (runningCall && runningCall.preEstSessionUri) {
            _this.removeMcpcConnectTimersForRefer(runningCall.preEstSessionUri);
          }
          _this.resetPTTCallStatus(data.callId);
          if (runningCall && runningCall.callData) {
            let updatedRunningCall = { ...runningCall.callData };
            updatedRunningCall.communicationType = "CALL_ACTION";
            updatedRunningCall.callActionType = "DISCONNECTED";
            updatedRunningCall.creatorId = _this.mcptt_uri;
            updatedRunningCall.UEid = "0000";
            updatedRunningCall.stateType = "DISCONNECT";
            _this.emit("CALL_ACTION", updatedRunningCall);
          }
          _this.preEstSessionCreationRunning = false;
          _this.removePreEstSession(data, true);
          return;
        }
        if (
          _this.callforwardingArr &&
          _this.foundInitialCallForward(data.callId)
        ) {
          if (_this.pendingPTT[data.callId]) {
            delete _this.pendingPTT[data.callId];
          }
          _this.removeOnGoingArrayCall(data);
          console.log("Initiate call ended callforwarded..");
          return;
        }
        if (
          res &&
          res.message &&
          res.message.headers &&
          res.message.headers.Disconnectcause &&
          res.message.headers.Disconnectcause.length
        ) {
          if (res.message.headers.Disconnectcause[0].raw) {
            if (res.message.headers.Disconnectcause[0].raw == "BUSY") {
              data.reason = "Called party busy";
            } else {
              data.reason =
                "Disconnected Cause: " +
                res.message.headers.Disconnectcause[0].raw;
            }
          }
        }
        data.communicationType = "CALL_ACTION";
        data.callActionType = "DISCONNECTED";
        data.creatorId = _this.mcptt_uri;
        data.UEid = "0000";
        console.log(
          "Call initiated ended newrtcsession.........",
          data
        );
        if (
          !data.localAudio &&
          data.callType &&
          !data.callType.includes("GROUP")
        ) {
          data.stateType = "MISSED";
        }
        _this.delegates.createCallXml(data, _this);

        _this.patchCallsAction(data);
        _this.mergeCallsAction(data);

        if (_this.pendingPTT[data.callId]) {
          delete _this.pendingPTT[data.callId];
        }

        _this.removeOnGoingArrayCall(data);

        if (callForwardObj && callForwardObj.initialCall) {
          _this.removeCallForwardingArrayCall(data.callId);
          let modifiedData = { ...callForwardObj.initialCall.callData };
          modifiedData.communicationType = "CALL_ACTION";
          modifiedData.callActionType = "DISCONNECTED";
          modifiedData.creatorId = _this.mcptt_uri;
          modifiedData.UEid = "0000";
          console.log(
            "CALL_ACTION call ended..",
            modifiedData,
            _this.callforwardingArr
          );
          _this.emit("CALL_ACTION", modifiedData);
        } else {
          _this.emit("CALL_ACTION", data);
        }
      });

      session.on("accepted", (e) => {
        //console.log("Incoming session: ", e);
        try {
          if (isCreatePreEstSession) {
            let callObj = _this.mcpttBodyUtils.getCallObjFromResponse(
              e.response
            );

            callObj.callId = data.callId;
            console.log("initiateCall() accepted: call_id..", callObj.callId);
            callObj.occupied = false;
            callObj.iceProgressState = false;
            callObj.localAudio = data.localAudio;
            if (data.stream) {
              callObj.stream = data.stream;
            }
            if (data.track) {
              callObj.track = data.track;
            }
            data.preEstSessionUri = callObj.preEstSessionUri;
            callObj.session = session;
            session.preEstSessionUri = callObj.preEstSessionUri;
            _this.ongoingPreestablishedCallSessions.push(callObj);
            _this.preEstSessionCreationRunning = false;

            session.on("reinvite", (data1) => {
              console.log("initiateCall() reinvite...");
              _this.handleReinvite(data1, data, session);
            });

            try {
              console.log("sdp recvonl 5");
              let res = session._updateDirTransForPeerConnection("recvonly");
              if (!res) {
                console.log("set transceiver direction with SDP --- recvonly");
                _this.sdptransform.updateLocalDescriptionStateRecieveOrSend(
                  session,
                  "recvonly"
                );
              }
            } catch (err) {
              console.log("localDesc........... 3", err);
            }
          }
        } catch (err) { }
        session.preSessionAccepted = true;
        if (!isCreatePreEstSession) {
          if (callForwardObj && callForwardObj.initialCall) {
            let callFData = { ...callForwardObj.initialCall.callData };
            callFData.callId1 = data.callId;
            callFData.forwardedId = data.toId;
            callFData.callActionType = data.callActionType;
            if (data.track) {
              callFData.track = data.track;
            }
            if (data.stream) {
              callFData.stream = data.stream;
            }
            if (data.remoteStream) {
              callFData.remoteStream = data.remoteStream;
            }
            console.log("modified call answer..", callFData);
            _this.emit("ANSWER", callFData);
          } else {
            console.log("initiateCall() orig call answer..", data);
            _this.onDemandCallAnswereEventSent(data.callId);
            _this.emit("ANSWER", data);
            let checkPttStatus = _this.isCallAlreadyGetPttEvent(data.callId);
            if (checkPttStatus) {
              _this.pttQueueOnOngoingCall(data.callId, false);
              setTimeout(() => {
                let checkPttObj = { ...checkPttStatus, callActionType: 'PUSH_TO_TALK_STATUS' };
                console.log("initiateCall() sending ptt after answered", checkPttObj);
                _this.emit("CALL_ACTION", checkPttObj);
              }, 5);
            }
          }
        }
      });

      session.on("destroy", () => {
        console.log("Initiate call destroy: ", session);
      });

      session.on("disconnected", () => {
        console.log("Initiate call disconnected: ", session);
      });

      session.on("connecting", () => {
        console.log("connecting");
      });

      session.on("progress", (data1) => {
        console.log("Call in progressss..", data);
        //RINGING EVENT EMIT
        if (data1.response.status_code === 182) {
          data.callActionType = "WAITING";
          _this.emit("CALL_ACTION", data);
        } else {
          data.callActionType = "RINGING";
          _this.emit("CALL_ACTION", data);
        }
      });

      session.on("connectionstate", (iceState) => {
        console.log("connectionstate ", iceState);
        if (isCreatePreEstSession) {
          if (iceState && (iceState.state === "connected" || iceState.state === "completed")) {
            _this.updateIceStateForPreEstSession(data, false);
          } else {
            _this.updateIceStateForPreEstSession(data, true);
          }
        }
      });

      session.on("peerconnection:setlocaldescriptionfailed", (err) => {
        remote.log("setlocaldescriptionfailed", err);
        if (isCreatePreEstSession && data && data.callId) {
          const runningCall = _this.getRunningOngoingCall(data.callId);
          console.log("setlocaldescriptionfailed: initiateCall() inside presession...");
          if (runningCall && runningCall.preEstSessionUri) {
            _this.removeMcpcConnectTimersForRefer(runningCall.preEstSessionUri);
          }
          _this.resetPTTCallStatus(data.callId);
          if (runningCall && runningCall.callData) {
            let updatedRunningCall = { ...runningCall.callData };
            updatedRunningCall.communicationType = "CALL_ACTION";
            updatedRunningCall.callActionType = "DISCONNECTED";
            updatedRunningCall.creatorId = _this.mcptt_uri;
            updatedRunningCall.UEid = "0000";
            updatedRunningCall.stateType = "DISCONNECT";
            _this.emit("CALL_ACTION", updatedRunningCall);
          }
          _this.preEstSessionCreationRunning = false;
          _this.removePreEstSession(data, true);
          return;
        }
      });

      session.on("peerconnection:setremotedescriptionfailed", (err) => {
        remote.log("setremotedescriptionfailed", err);
      });

      if (!isCreatePreEstSession) {
        session.on("reinvite", function (reinviteData) {
          console.log("reinvite..3");
          _this.handleReinvite(reinviteData, data, session);
        });
      }

      session.on("hold", function (holdReq) {
        console.log("hold req outgoing...", holdReq);
      });

      session.on("getusermediafailed", () => {
        remote.log("getusermediafailed");
        //RINGING EVENT EMIT
        data.callActionType = "MEDIA_FAILED";
        _this.emit("CALL_ACTION", data);
      });

      session.connection.addEventListener("addstream", function (e) {
        //remote.log("Adding stream ", e, e.stream, e.stream.getAudioTracks());
        console.log("Adding stream data..", data, e.stream);
        data.callActionType = "ANSWER";
        data.stream = e.stream.getAudioTracks();
        data.remoteStream = e.stream;
      });

      session.connection.addEventListener("track", function (e) {
        console.log("Adding track-initiate call:", e.track, e.stream);
        data.track = e.track;
        data.callActionType = "ANSWER";
        if (!callForwardObj) {
          _this.ongoingCalls = _this.ongoingCalls.map((ca) => {
            if (ca.callData && ca.callData.callId == data.callId) {
              ca.callData.track = e.track;
              ca.callData.remoteStream = e.stream;
              ca.callData.localAudio = data.localAudio;
            }
            return ca;
          });
        }
      });

      session.on("confirmed", () => {
        if (!isCreatePreEstSession) {
          if (currentCall) {
            currentCall.status = "confirmed";
          }
          //console.log("session call confirmed state.", currentCall);

          _this.removeOnGoingArrayCall(currentCall);
          _this.ongoingCalls.push(currentCall);
        }
      });

      if (session._id) {
        let sessionId = session._id;
        if (session._request && session._request.call_id) {
          sessionId = session._request.call_id;
        }
        if (!isCreatePreEstSession) this.ongoingCalls.push(currentCall);
        if (callForwardObj) {
          this.addCallforwardedData(currentCall, callForwardObj);
        }
        console.log("ongoingCalls.push........ 5", this.ongoingCalls.length);
        return sessionId;
      } else {
        return null;
      }
    }

    handleByeReq(req) {
      console.log("handleByeReq()", req, this.registerprocessSate.getRegistrationState());
      if (this.registerprocessSate.getRegistrationState() === stateType.REGISTRATION_STATE.Deregistered) {
        this.byeReqArr = [...this.byeReqArr, req];
      }
    }

    sendByeReq() {
      console.log("sendByeReq()", this.byeReqArr);
      if (this.byeReqArr && this.byeReqArr.length > 0) {
        for (const req of this.byeReqArr) {
          this.sendByeRequest(req);
        }
      }
      this.byeReqArr = [];
    }

    handleReinvite(reinviteData, data, session) {
      console.log("reinvite init came...", data);
      let _this = this;
      if (reinviteData.request.hasHeader("Contact")) {
        let headerValStr = reinviteData.request.getHeader("Contact");
        //<sip:1685014028768@confrence.mcx.org>;isfocus :- For Merge Call
        //<sip:mcx.cons-921.cons@consort.org>;+sip.rendering="no" :- For Hold call
        console.log("reinvite init headerValStr...", headerValStr);
        if (headerValStr) {
          let headerValArr = headerValStr.split(";");
          console.log("reinvite init headerValArr...", headerValArr);
          if (headerValArr && headerValArr.length > 0) {
            if (headerValArr[1] == "isfocus") {
              // Merge call condition
              console.log("CONFERENCE_CALL val", data);
              _this.emit("CONFERENCE_CALL", data);
              // if (headerValStr.includes("conference.mcx.org")) {
              //   console.log("CONFERENCE_CALL val", data);
              //   _this.emit("CONFERENCE_CALL", data);
              // }
            } else if (headerValStr.includes("rendering")) {
              // Hold call condition
              if (headerValStr.includes('rendering="no"')) {
                console.log("CALL_ON_HOLD rendering no");
                _this.emit("CALL_ON_HOLD", data);
              } else {
                console.log("CALL_ON_HOLD rendering yes");
                _this.emit("CALL_ON_UNHOLD", data);
              }
            } else {
              let data1 = reinviteData;
              // this.preEstCallInitiatedAt = new Date().getTime();
              // let currentTime = new Date().getTime();
              // let timeDifference = currentTime - this.preEstCallInitiatedAt;
              // console.log(
              //   "PreEst Call Time................ 1 call recieved ",
              //   currentTime,
              //   timeDifference
              // );

              let calldetail = {};
              calldetail.request = data1.request;
              //RINGING reinvite
              data1.request.reply(180, null, [`Contact: ${session._contact}`]);

              calldetail.session = session;
              calldetail.preEstSessionUri = session.preEstSessionUri;

              _this._HandleNewRTCSession(calldetail, true);
            }
          }
        }
      }
    }

    addCallforwardedData(currentCall, callforwardingObj) {
      /*
      let callForwardObj = {
                'fromId': callingUserUri,
                'toId': calledPartyUri,
                'requestId': requestUserUri,
                'initialCall': callObj
              }
      */
      if (callforwardingObj) {
        let callFound = false;
        for (const sesObj of this.callforwardingArr) {
          if (sesObj.callData.callId1 == currentCall.callData.callId) {
            callFound = true;
            break;
          }
        }
        if (!callFound) {
          let callFObj = { ...callforwardingObj.initialCall };
          callFObj.callData.callId1 = currentCall.callData.callId;
          callFObj.callData.forwardedId = callforwardingObj.toId;
          this.callforwardingArr.push(callFObj);
        }
      }
      console.log("callforwardingArr added", this.callforwardingArr);
    }

    foundInitialCallForward(callId) {
      let callFObj = null;
      for (const sesObj of this.callforwardingArr) {
        if (sesObj.callData.callId == callId) {
          callFObj = { ...sesObj };
          break;
        }
      }
      return callFObj;
    }

    foundInitialCallForwardWithNeedToHold(callId) {
      let callFObj = null;
      for (let sesObj of this.callforwardingArr) {
        if (sesObj.callData.callId == callId) {
          if (sesObj.callData.needToHold) {
            callFObj = { ...sesObj };
          }
          break;
        }
      }
      return callFObj;
    }

    updateInitialCallForwardWithNeedToHold(callId, needToHold) {
      let callFObj = null;
      for (let sesObj of this.callforwardingArr) {
        if (sesObj.callData.callId == callId) {
          if (sesObj.callData.needToHold) {
            sesObj.callData.needToHold = needToHold;
            callFObj = { ...sesObj.callData };
          }
          break;
        }
      }
      return callFObj;
    }

    removeCallForwardingArrayCall(callId) {
      if (this.callforwardingArr) {
        console.log("removeCallForwardingArrayCall..", this.callforwardingArr);
        let indexOfCurrentCall = this.callforwardingArr.findIndex(
          (cc) => cc.callData && cc.callData.callId1 == callId
        );
        if (indexOfCurrentCall >= 0) {
          this.callforwardingArr.splice(indexOfCurrentCall, 1);
        }
      }
      console.log("removeCallForwardingArrayCall..1", this.callforwardingArr);
    }

    isMcxUserAgent() {
      if (this.userAgent.toLowerCase().includes("Consort-Handset-MCX".toLowerCase())) {
        console.log("isMcxUserAgent ", true);
        return true;
      }
      console.log("isMcxUserAgent ", false);
      return false;
    }
    isDispatcherUserAgent() {
      if (this.userAgent.toLowerCase().includes("dispatcher")) {
        console.log("isDispatcher agent ", true);
        return true;
      }
      console.log("isDispatcher agent ", false);
      return false;
    }
    isAndroidOS() {
      return this.os == "android";
    }
    isTRCPRCPUserAgent() {
      if (
        this.userAgent.toLowerCase().includes("trcp") ||
        this.userAgent.toLowerCase().includes("rcp")
      ) {
        console.log("isTRCP RCP or dispatcher agent ", true);
        return true;
      }
      console.log("isTRCP RCP agent ", false);
      return false;
    }

    isCallTypeManual(callType) {
      if (
        callType == "DUPLEX_INDIVIDUAL_CALL" ||
        callType == "SIMPLEX_INDIVIDUAL_HOOK_CALL"
      ) {
        return true;
      }
      return false;
    }

    sendCallAction(data) {
      let callObj = {
        ...data,
        fromAction: true,
        creatorId: this.mcptt_uri,
        UEid: "0000",
      };
      const isConfCall = callObj.isConfCall ? callObj.isConfCall : false;
      const isVideo = callObj.isVideo ? callObj.isVideo : false;
      console.log("sendCallAction(): ", callObj.callActionType, callObj.callId, isVideo);
      if (
        !callObj.localAudio &&
        callObj.callType &&
        !callObj.callType.includes("GROUP")
      ) {
        callObj.stateType = "MISSED";
      }

      const _this = this;
      const options = {
        mediaStream: data.localAudio,
        /* mediaConstraints: {
                  audio: true, // only audio calls
                  video: false
                } */
      };

      let currentcallFoundThroughForwardId = false;

      let currentCall = _this.ongoingCalls.find(
        (ca) => ca.callData && ca.callData.callId == data.callId
      );
      if (!currentCall) {
        for (let callFObj of _this.callforwardingArr) {
          if (callFObj.callData.callId == data.callId) {
            let newForwardedCall = _this.ongoingCalls.find(
              (ca) =>
                ca.callData && ca.callData.callId == callFObj.callData.callId1
            );
            if (newForwardedCall) {
              currentCall = { ...newForwardedCall };
            }
            currentcallFoundThroughForwardId = true;
            break;
          }
        }
      }
      //console.log("sendCallAction() currentCall ", currentCall);
      switch (data.callActionType) {
        case "ANSWER":
          if (currentCall && _this.isPreEstCall(currentCall.preEstSessionUri)) {
            try {
              if (this.isAndroidOS()) {
                console.log("clear local audio")
                delete data["localAudio"];
              }
            } catch (err) {
              console.error("delete local audio", err)
            }
            console.log("Pre established going to answer call ");
            if (_this.callforwardingNoAnsTimer) {
              clearTimeout(_this.callforwardingNoAnsTimer);
              _this.callforwardingNoAnsTimer = null;
            }

            if (!currentCall) {
              console.log("currentCall not found for answer");
              return false;
            }
            if (currentCall.callState == "disconnected") {
              console.log("call is already in disconnected state for answer action");
              return false;
            }

            if (currentCall.isCallAnswerSent) {
              console.log("call is already in answer state");
              return false;
            }

            let isPTTCall =
              currentCall.callData.callType == "DUPLEX_INDIVIDUAL_CALL"
                ? false
                : true;
            if (!isPTTCall) {
              let res = currentCall.session._updateDirTransForPeerConnection(
                "sendrecv"
              );
              if (!res) {
                console.log("set transceiver direction with SDP --- sendrecv");
                _this.sdptransform.updateLocalDescriptionStateRecieveOrSend(
                  currentCall.session,
                  "sendrecv",
                  currentCall.callData
                );
              }
            }

            if (
              data.callType == "DUPLEX_INDIVIDUAL_CALL" ||
              data.callType == "SIMPLEX_INDIVIDUAL_HOOK_CALL"
            ) {
              let options = {};
              let sentAnswer = currentCall.session._sendAnswerForReinvite(
                currentCall.request,
                options
              );
              if (sentAnswer) {
                setTimeout(() => {
                  _this.handlePreEstSessionState(currentCall);
                }, 60 * 1000);
              } else {
                console.log("Beacuse of network disruption, answer is not happened");
                let action = {
                  "callActionType": "DISCONNECTED",
                  "communicationType": "CALL_ACTION",
                  "reasonCause": "6"
                }
                _this.removeMcpcConnectTimersForRefer(currentCall.preEstSessionUri);
                _this.manageCallSessionOnDisconnect(currentCall, action);
              }
            } else {
              // remote.log("accepted stream ", foundCall.callData.remoteStream);
              data.callActionType = "ANSWER";
              if (currentCall.talkingPartyId) {
                data.talkingPartyId = currentCall.talkingPartyId;
              }
              if (!data.localAudio) {
                if (currentCall.callData && currentCall.callData.localAudio) {
                  data.localAudio = currentCall.callData.localAudio;
                }
              }
              if (!data.stream) {
                if (currentCall.callData && currentCall.callData.stream) {
                  data.stream = currentCall.callData.stream;
                }
              }
              if (!data.track) {
                if (currentCall.callData && currentCall.callData.track) {
                  data.track = currentCall.callData.track;
                }
              }
              if (!data.remoteStream) {
                if (currentCall.callData && currentCall.callData.remoteStream) {
                  data.remoteStream = currentCall.callData.remoteStream;
                }
              }
              console.log("presession call answered..");
              // if (currentCall.callData && currentCall.callData.callPriority && parseInt(currentCall.callData.callPriority) == 15) {
              //   return true;
              // }
              _this.preEstCallAnswereEventSent(currentCall.preEstSessionUri);
              if (currentCall) {
                if (_this.isCallRunning(data)) {
                  _this.emit("ANSWER", data);
                }
              }
            }
            return true;
          }
          if (currentCall) {
            console.log("MCX: ANSWER", currentCall.callData);
            if (
              currentCall.session.status !== 9 ||
              currentCall.session.status !== 5
            ) {
              currentCall.session.answer(options);
            }
            currentCall.session.connection.addEventListener(
              "addstream",
              function (e) {
                remote.log("Adding stream ", e.stream);
                data.callActionType = "ANSWER";
                _this.ongoingCalls = _this.ongoingCalls.map((ca) => {
                  if (ca.callData && ca.callData.callId == data.callId) {
                    ca.callData.stream = e.stream.getAudioTracks();
                    ca.callData.remoteStream = e.stream;
                    ca.callData.localAudio = data.localAudio;
                  }
                  return ca;
                });
                console.log("Adding stream Call Answered.....", data);
              }
            );

            currentCall.session.connection.addEventListener("track", function (
              e
            ) {
              console.log("adding track-ANSWER: ", e.track);
              data.callActionType = "ANSWER";
              data.track = e.track;
              data.remoteStream = e.stream;
              _this.ongoingCalls = _this.ongoingCalls.map((ca) => {
                if (ca.callData && ca.callData.callId == data.callId) {
                  ca.callData.track = e.track;
                  ca.callData.remoteStream = e.stream;
                  ca.callData.localAudio = data.localAudio;
                }
                return ca;
              });
            });
            return true;
          } else {
            console.log("MCX: ANSWER", "no current call found");
          }
          return false;

        case "DISCONNECTED":
          // eslint-disable-next-line no-unused-expressions
          if (currentCall && _this.isPreEstCall(currentCall.preEstSessionUri)) {
            console.log("isPreEstCall disconnected()");

            if (!data.preEstSessionUri) {
              data.preEstSessionUri = currentCall.preEstSessionUri;
            }

            _this.muteUnmutePreEstSession(currentCall, false);

            let currentPreEstCallObj = currentCall.callData;
            if (!currentPreEstCallObj.preEstSessionUri) {
              currentPreEstCallObj.preEstSessionUri =
                currentCall.preEstSessionUri;
            }

            if (currentCall.callState == "disconnected") {
              console.log("call is already in disconnected state for disconnection action");
              return;
            }

            if (
              data.callType == "DUPLEX_INDIVIDUAL_CALL" ||
              data.callType == "SIMPLEX_INDIVIDUAL_HOOK_CALL"
            ) {
              console.log("disconnected action for manual call");
              if (
                currentCall.session &&
                (currentCall.session._status <
                  currentCall.session.C.STATUS_ANSWERED ||
                  currentCall.callState == "started")
              ) {
                try {
                  console.log("manual call rejected action ", currentCall.session._status, currentCall.callState);
                  let sessionRejected = currentCall.session._rejectForReinvite(currentCall.request, {
                    cause: JsSIP.C.causes.CANCELED,
                    // disableSessionExpireHeader: true,
                  });
                  if (!sessionRejected) {
                    console.log("Rejected for invite is not succeed");
                    _this.sendReferRequest(currentPreEstCallObj, "DISCONNECTED");
                  }
                } catch (err) {
                  console.log("foundCall disconnect..... 4");
                  console.log("_rejectForReinvite error", err);
                  _this.sendReferRequest(currentPreEstCallObj, "DISCONNECTED");
                }
              } else {
                console.log("foundCall disconnect..... 5");
                _this.sendReferRequest(currentPreEstCallObj, "DISCONNECTED");
              }
            } else {
              console.log("foundCall disconnect..... 6");
              _this.sendReferRequest(currentPreEstCallObj, "DISCONNECTED");
            }
            currentCall.callState = "disconnected";
            return;
          }

          if (currentCall) {
            console.log(
              "DISCONNECT call Action for callId:",
              data.callId,
              currentCall.session.C.STATUS_ANSWERED
            );
            if (
              currentCall.session._status <
              currentCall.session.C.STATUS_ANSWERED
            ) {
              currentCall.session.terminate({
                cause: JsSIP.C.causes.CANCELED,
                // status_code: 408,
                // reason_phrase: "Session Timer Expired",
              });
            } else {
              currentCall.session.terminate();
            }
          } else {
            console.log(
              "no current call found Ongoing calls"
            );
          }
          break;

        case "ACQUIRE_PUSH_TO_TALK":
          this.releasePTTCurrentCall = { callId: data.callId, status: false };
          if (
            !currentCall ||
            (currentCall.callState && currentCall.callState !== "ptt_recieved")
          ) {
            data.callActionType = "DISCONNECTED";
            if (currentCall && currentCall.callType) {
              this.sendCallAction(data);
            }
            return;
          }
          console.log("releasePTTCurrentCall 1");

          if (_this.isMcxUserAgent()) {
            _this.sendSDSPTT(data);
          } else {
            if (currentcallFoundThroughForwardId) {
              if (currentCall) {
                data.toId = currentCall.callData.toId;
                data.callId = currentCall.callData.callId;
              }
            }
            _this.sendSDS(data);
          }
          break;

        case "RELEASE_PUSH_TO_TALK":
          this.releasePTTCurrentCall = { callId: data.callId, status: true };

          if (
            !currentCall ||
            (currentCall.callState && currentCall.callState !== "ptt_recieved")
          ) {
            data.callActionType = "DISCONNECTED";
            if (currentCall && currentCall.callType) {
              this.sendCallAction(data);
            }
            return;
          }
          console.log("releasePTTCurrentCall 2");
          if (_this.isMcxUserAgent()) {
            _this.sendSDSPTT(data);
          } else {
            if (currentcallFoundThroughForwardId) {
              if (currentCall) {
                data.toId = currentCall.callData.toId;
                data.callId = currentCall.callData.callId;
              }
            }
            _this.sendSDS(data);
          }
          break;

        case "MUTE_SPEAKER":
          if (currentCall) {
            _this.speakerOnOrOffRemoteStream(currentCall, true);
            if (isVideo) {
              _this.enableOrDisableCameraRemoteStream(currentCall, true);
            }
          }
          break;

        case "UNMUTE_SPEAKER":
          if (currentCall) {
            _this.speakerOnOrOffRemoteStream(currentCall, false);
            if (isVideo) {
              _this.enableOrDisableCameraRemoteStream(currentCall, false);
            }
          }
          break;

        case "HOLD":
          if (currentCall) {
            _this.holdOrUnholdStream(currentCall, true);
          }
          break;

        case "UNHOLD":
          if (currentCall) {
            _this.holdOrUnholdStream(currentCall, false);
          }
          break;
        case "HOLD_MUTE":
          if (
            currentCall &&
            !isConfCall &&
            !isVideo &&
            _this.callOnHold &&
            callObj.callType == "DUPLEX_INDIVIDUAL_CALL"
          ) {
            currentCall.session.hold();
          } else if (currentCall) {
            _this.holdOrUnholdStream(currentCall, true);
          }
          break;

        case "UNHOLD_MUTE":
          if (
            currentCall &&
            !isConfCall &&
            !isVideo &&
            _this.callOnHold &&
            callObj.callType == "DUPLEX_INDIVIDUAL_CALL"
          ) {
            currentCall.session.unhold();
          } else if (currentCall) {
            _this.holdOrUnholdStream(currentCall, false);
          }
          break;

        case "UNMUTE_MIC":
          if (currentCall && _this.isManualCallHandle) {
            _this.muteOrUnmuteLocalStream(currentCall, false);
            if (isVideo) {
              _this.enableOrDisableCameraLocalStream(currentCall, false);
            }
          } else {
            if (currentCall) {
              let options = { audio: true, video: isVideo ? isVideo : false };
              currentCall.session.unmute(options);
            }
          }
          break;

        case "MUTE_MIC":
          if (currentCall && _this.isManualCallHandle) {
            _this.muteOrUnmuteLocalStream(currentCall, true);
            if (isVideo) {
              _this.enableOrDisableCameraLocalStream(currentCall, true);
            }
          } else {
            if (currentCall) {
              let options = { audio: true, video: isVideo ? isVideo : false };
              currentCall.session.mute(options);
            }
          }
          break;

        case "ENABLE_CAMERA":
          if (currentCall && _this.isManualCallHandle) {
            _this.enableOrDisableCameraLocalStream(currentCall, false);
          }
          break;

        case "DISABLE_CAMERA":
          if (currentCall && _this.isManualCallHandle) {
            _this.enableOrDisableCameraLocalStream(currentCall, true);
          }
          break;

        default:
          break;
      }
    }

    isCallRunning(data) {
      let foundCall = this.ongoingCalls.find(
        (ca) => ca.callData && ca.callData.callId == data.callId
      );
      return foundCall && foundCall.callData;
    }

    muteUnmutePreEstSession(callLocalOrStream, mute) {
      console.log("muteUnmutePreEstSession........", mute);
      if (this.isManualCallHandle) {
        this.holdOrUnholdStream(callLocalOrStream, mute);
      } else {
        if (mute) {
          callLocalOrStream.session.mute();
        } else {
          callLocalOrStream.session.unmute();
        }
      }
    }
    enableOrDisableCameraLocalStream(loaclCall, disable) {
      if (loaclCall && loaclCall.session && loaclCall.session.connection) {
        if (typeof loaclCall.session.connection.getLocalStreams === "function") {
          loaclCall.session.connection.getLocalStreams().forEach((stream) => {
            //console.log("enableOrDisableCameraLocalStream.......", stream);
            if (stream.getVideoTracks() && stream.getVideoTracks().length > 0) {
              stream.getVideoTracks().forEach((t) => {
                t.enabled = !disable;
              });
            }
          });
        } else {
          //Do Something
        }
      }
    }
    enableOrDisableCameraRemoteStream(loaclCall, disable) {
      if (loaclCall && loaclCall.session && loaclCall.session.connection) {
        if (typeof loaclCall.session.connection.getRemoteStreams === "function") {
          loaclCall.session.connection.getRemoteStreams().forEach((stream) => {
            console.log("enableOrDisableCameraRemoteStream.......", stream);
            if (stream.getVideoTracks() && stream.getVideoTracks().length > 0) {
              console.log("enableOrDisableCameraRemoteStream.......1", stream);
              stream.getVideoTracks().forEach((t) => {
                t.enabled = !disable;
              });
            }
          });
        } else {
          //Do Something
        }
      }
    }
    getConstraint(loaclCall) {
      console.log("getConstraint........ 1");
      loaclCall.localAudio.getAudioTracks().forEach((track) => {
        let constraints = track.getConstraints();
        console.log("getConstraint...... 2", constraints);
        // this.isFrontCamera = !this.isFrontCamera;
        // muted;
      });
    }
    muteOrUnmuteLocalStream(loaclCall, mute) {
      if (loaclCall && loaclCall.session && loaclCall.session.connection) {
        if (typeof loaclCall.session.connection.getLocalStreams === "function") {
          loaclCall.session.connection.getLocalStreams().forEach((stream) => {
            stream.getAudioTracks().forEach((track) => {
              const t = track;
              console.log("mute unmute ", t, stream);
              try {
                t.enabled = !mute;
              } catch (err) {
                console.log("mute unmute error ", err, stream);
              }
            });
          });
        } else {
          //Do Something
        }
      }
    }

    speakerOnOrOffRemoteStream(remoteCall, mute) {
      if (remoteCall && remoteCall.session && remoteCall.session.connection) {
        if (typeof remoteCall.session.connection.getRemoteStreams === "function") {
          remoteCall.session.connection.getRemoteStreams().forEach((stream) => {
            stream.getAudioTracks().forEach((t) => {
              t.enabled = !mute;
            });
          });
        } else {
          //Do Something
        }
      }
    }

    updateBandwidthRestriction(sdp, bandwidth) {
      console.log("updateBandwidthRestriction....", bandwidth);
      let modifier = "AS";
      // if (adapter.browserDetails.browser === "firefox") {
      //   bandwidth = (bandwidth >>> 0) * 1000;
      //   modifier = "TIAS";
      // }
      if (sdp.indexOf("b=" + modifier + ":") === -1) {
        // insert b= after c= line.
        sdp = sdp.replace(
          /c=IN (.*)\r\n/,
          "c=IN $1\r\nb=" + modifier + ":" + bandwidth + "\r\n"
        );
      } else {
        sdp = sdp.replace(
          new RegExp("b=" + modifier + ":.*\r\n"),
          "b=" + modifier + ":" + bandwidth + "\r\n"
        );
      }
      console.log("updateBandwidthRestriction....2");
      return sdp;
    }

    removeBandwidthRestriction(sdp) {
      return sdp.replace(/b=AS:.*\r\n/, "").replace(/b=TIAS:.*\r\n/, "");
    }
    setBandWidth(callObj, bandwidthInKbps = 75) {
      try {
        if (bandwidthInKbps <= 5) {
          return;
        }
        let currentCall = callObj; //this.getCurrentOnGoingPreEstCall(callObj);
        let _this = this;
        console.log("parameters...... 1 ", bandwidthInKbps);
        // const sender = currentCall.session.connection.getSenders()[0];
        // const parameters = sender.getParameters();
        // console.log("parameters...... 2", parameters);
        // if (!parameters.encodings) {
        //   parameters.encodings = [{}];
        // }
        // if (bandwidthInKbps === "unlimited") {
        //   delete parameters.encodings[0].maxBitrate;
        // } else {
        //   parameters.encodings[0].maxBitrate = bandwidthInKbps * 1000;
        // }
        // console.log("parameters...... 3", parameters);
        // sender.setParameters(parameters);
        // console.log("parameters..... 4", parameters);
        let pc1 = currentCall.session.connection;
        console.log("parameters...... 2");
        pc1
          .createOffer()
          .then((offer) => pc1.setLocalDescription(offer))
          .then(() => {
            const desc = {
              type: pc1.remoteDescription.type,
              sdp:
                bandwidthInKbps === "unlimited"
                  ? _this.removeBandwidthRestriction(pc1.remoteDescription.sdp)
                  : _this.updateBandwidthRestriction(
                    pc1.remoteDescription.sdp,
                    bandwidthInKbps
                  ),
            };
            desc.sdp = console.log(
              "Applying bandwidth restriction to setRemoteDescription:\n" +
              desc.sdp
            );
            return pc1.setRemoteDescription(desc);
          })
          .catch((err) => {
            console.log("setBandWidth Error 2", err);
          });
      } catch (err) {
        console.log("setBandWidth Error", err);
      }
    }
    getStats(callObj) {
      if (!this.isDebugModeEnable) {
        return;
      }
      let _this = this;
      let currentCall = this.getCurrentOnGoingPreEstCall(callObj);
      if (
        !currentCall ||
        !currentCall.session ||
        !currentCall.session.connection
      ) {
        if (this.callStats && this.callStats.length > 0) {
          // let currentCallStats = this.callStats.filter(
          //   (stats) => stats.callId == currentCall.callData.callId
          // );
          // this.callStats = this.callStats.filter(
          //   (stats) => stats.callId != currentCall.callData.callId
          // );
          let currentCallStats = this.callStats;
          this.callStats = [];
          console.log(
            "Current call stats........",
            currentCallStats,
            this.callStats
          );
          console.log("Call stats ", currentCallStats);
        }
        return "";
      }

      setTimeout(() => {
        if (!currentCall ||
          !currentCall.session ||
          !currentCall.session.connection) {
          return;
        }
        let stats = currentCall.session.connection.getStats();
        if (stats) {
          stats
            .then((data) => {
              console.log("getStats success", data);
              let infoString = "";
              let infoJson = {};
              for (const [key, value] of data.entries()) {
                // RTCInboundRTPAudioStream packetsLost packetsReceived
                // RTCOutboundRTPAudioStream packetsSent
                // RTCRemoteInboundRtpAudioStream packetsLost
                // RTCTransport_audio packetsSent packetsReceived
                if (key.includes("RTCInboundRTPAudioStream")) {
                  infoString +=
                    "\n " +
                    key +
                    " P_Lost " +
                    value.packetsLost +
                    " P_Recv " +
                    value.packetsReceived +
                    " Jitter " +
                    value.jitter +
                    " jitterBufferDelay " +
                    value.jitterBufferDelay +
                    " jitterBufferEmittedCount " +
                    value.jitterBufferEmittedCount;
                } else if (key.includes("RTCOutboundRTPAudioStream")) {
                  infoString += "\n " + key + " P_Sent " + value.packetsSent;
                } else if (key.includes("RTCRemoteInboundRtpAudioStream")) {
                  infoString +=
                    "\n " +
                    key +
                    " P_Lost " +
                    value.packetsLost +
                    " Jitter " +
                    value.jitter +
                    " fractionLost " +
                    value.fractionLost;
                } else if (key.includes("RTCRemoteOutboundRTP")) {
                  infoString += "\n " + key + " P_Sent " + value.packetsSent;
                } else if (key.includes("RTCTransport_audio")) {
                  infoString +=
                    "\n " +
                    key +
                    " P_Sent " +
                    value.packetsSent +
                    " P_Recv " +
                    value.packetsReceived;
                }

                if (value.type === "outbound-rtp") {
                  if (value.isRemote) {
                    return;
                  }
                  const now = value.timestamp;
                  let bytes = value.bytesSent;
                  let headerBytes = value.headerBytesSent;

                  // let packets = value.packetsSent;
                  console.log("_this.lastResult", _this.lastResult);
                  if (_this.lastResult && _this.lastResult.has(value.id)) {
                    // calculate bitrate
                    const bitrate =
                      (8 * (bytes - _this.lastResult.get(value.id).bytesSent)) /
                      (now - _this.lastResult.get(value.id).timestamp);
                    const headerrate =
                      (8 *
                        (headerBytes -
                          _this.lastResult.get(value.id).headerBytesSent)) /
                      (now - _this.lastResult.get(value.id).timestamp);
                    infoString +=
                      "\n Sending: bitrate " +
                      bitrate +
                      " headerrate " +
                      headerrate;
                    infoJson.send = {
                      bitrate: bitrate,
                      headerrate: headerrate,
                    };
                  }
                }
                if (value.type === "inbound-rtp") {
                  if (value.isRemote) {
                    return;
                  }
                  const now = value.timestamp;
                  let bytes = value.bytesReceived;
                  let headerBytes = value.headerBytesReceived;

                  // let packets = value.packetsSent;
                  console.log("_this.lastResult", _this.lastResult);
                  if (_this.lastResult && _this.lastResult.has(value.id)) {
                    // calculate bitrate
                    const bitrate =
                      (8 *
                        (bytes -
                          _this.lastResult.get(value.id).bytesReceived)) /
                      (now - _this.lastResult.get(value.id).timestamp);
                    const headerrate =
                      (8 *
                        (headerBytes -
                          _this.lastResult.get(value.id).headerBytesReceived)) /
                      (now - _this.lastResult.get(value.id).timestamp);
                    infoString +=
                      "\n Recieving: bitrate " +
                      bitrate +
                      " headerrate " +
                      headerrate;
                    infoJson.recv = {
                      bitrate: bitrate,
                      headerrate: headerrate,
                    };
                  }
                }
              }
              let stats = {
                callId: currentCall.callData.callId,
                processedStats: infoString,
                rawStats: "" + data,
                bandwidthSet: _this.sessionBandwidthInKbps,
                userMCPTTUri: _this.mcdata_uri,
                time: new Date(),
              };
              console.log("stats.......", stats);
              _this.callStats.push(stats);
              infoJson.infoString = infoString;
              console.log("infoString ", "infoString ", infoJson);
              let jsonInfo = JSON.stringify(infoJson);
              _this.emit("PRE_EST_COUNT", jsonInfo); //uncomment for stats
              _this.lastResult = data;
              _this.getStats(callObj);
            })
            .catch((err) => {
              console.log("getStats error", err);
              _this.getStats(callObj);
            });
        }
      }, 1000);

    }
    holdOrUnholdStream(callLocalOrStream, mute) {
      if (
        callLocalOrStream &&
        callLocalOrStream.session &&
        callLocalOrStream.session.connection
      ) {
        // console.log("callLocalOrStream holdOrUnholdStream()", callLocalOrStream);

        let _this = this;
        setTimeout(() => {
          _this.enableOrDisableCameraLocalStream(callLocalOrStream, mute);
        }, 2)
        setTimeout(() => {
          _this.enableOrDisableCameraRemoteStream(callLocalOrStream, mute);
        }, 2)

        setTimeout(() => {
          _this.muteOrUnmuteLocalStream(callLocalOrStream, mute);
        }, 2)

        setTimeout(() => {
          _this.speakerOnOrOffRemoteStream(callLocalOrStream, mute);
        }, 2)

      }
    }

    sendSDSPTT(data) {
      const FLOOR_REQUEST_MESSAGE = "FloorRequestMessage";
      const FLOOR_RELEASE_MESSAGE = "FloorReleaseMessage";
      const MCPC_MESSAGE = "MCPCAcknowledge";

      const _this = this;
      console.log(
        "sendSDSPTT .................",
        data.callId,
        data.callActionType,
        _this.mcpttId,
        data
      );

      _this.pttMsgClient.sendMessage(
        data.callId,
        data.callActionType === "RELEASE_PUSH_TO_TALK"
          ? FLOOR_RELEASE_MESSAGE
          : data.callActionType == MCPC_MESSAGE
            ? MCPC_MESSAGE
            : FLOOR_REQUEST_MESSAGE,
        _this.mcpttId
      );
    }

    sendSDS(data) {
      const _this = this;

      console.log("SDS msg sendSDS prev", data);
      if (
        data.communicationType === "CALL_ACTION" ||
        data.communicationType === "CALL"
      ) {
        /*let needToSendMsg = true;
        for (const sesObj of this.ongoingCalls) {
          //console.log("session call confirmed obj sds..", sesObj);
          if (sesObj.callData && sesObj.callData.callId == data.callId) {
            if (sesObj.status != "confirmed") { //initiated
              //console.log("session call confirmed obj sds found..", sesObj);
              needToSendMsg = false;
              break;
            }
          }
        }
        if (!needToSendMsg) {
          return;
        }*/

        data.messageId = data.messageId
          ? data.messageId
          : Math.random().toString(36).slice(2);
        data.fromId = _this.mcptt_uri;
        data.creatorId = _this.mcptt_uri;
        data.stateType = "STORED";
        data.impu = this.config.impu
          ? "sip:" + this.config.impu + "@" + this.config.realm
          : "";
        // if (data.deliveryReportNeeded || data.consumedReportNeeded) {
        //   this.pendingSDS.push(data);
        // }
        if (this.userLoginXCAP && this.userLoginXCAP.userProfile) {
          data.userType = this.userLoginXCAP.userProfile.userType
            ? this.userLoginXCAP.userProfile.userType
            : "";
          let ueType = this.userAgent;
          if (this.userAgent) {
            let uetypes = this.userAgent.split(";");
            if (uetypes && uetypes.length > 0) {
              ueType = uetypes[0];
            }
          }
          data.ueType = ueType;
        }
        if (data && data.activeFA) {
          data.activeFA = data.activeFA;
        }

        if (this.pendingPTT[data.callId]) {
          console.log(
            "SDS msg sendSDS pendingPTT found",
            data,
            this.pendingPTT
          );
          let pttstatus = this.pendingPTT[data.callId];
          if (data.callActionType == "ACQUIRE_PUSH_TO_TALK") {
            if (pttstatus.releaseState == PTT_STATUS.pending) {
              pttstatus.acquireState = PTT_STATUS.requested;
            } else if (pttstatus.acquireState == PTT_STATUS.pending) {
              pttstatus.releaseState = PTT_STATUS.none;
            } else if (pttstatus.acquireState == PTT_STATUS.none) {
              pttstatus.acquireState = PTT_STATUS.pending;
              this.pendingPTT[data.callId] = pttstatus;
              this.sendPTTMessage(this.delegates.createPTTMessage(data), data);
            }
          } else if (data.callActionType == "RELEASE_PUSH_TO_TALK") {
            if (pttstatus.acquireState == PTT_STATUS.pending) {
              pttstatus.releaseState = PTT_STATUS.requested;
            } else if (pttstatus.releaseState == PTT_STATUS.pending) {
              pttstatus.acquireState = PTT_STATUS.none;
            } else if (pttstatus.releaseState == PTT_STATUS.none) {
              pttstatus.releaseState = PTT_STATUS.pending;
              this.pendingPTT[data.callId] = pttstatus;
              this.sendPTTMessage(this.delegates.createPTTMessage(data), data);
            }
          }
        } else {
          console.log("SDS msg sendSDS pendingPTT not found", data);
          let pttstatus = {
            acquireState: PTT_STATUS.none,
            releaseState: PTT_STATUS.none,
          };
          if (data.callActionType == "ACQUIRE_PUSH_TO_TALK") {
            pttstatus.acquireState = PTT_STATUS.pending;
          } else if (data.callActionType == "RELEASE_PUSH_TO_TALK") {
            pttstatus.releaseState = PTT_STATUS.pending;
          }
          this.pendingPTT[data.callId] = pttstatus;
          this.sendPTTMessage(this.delegates.createPTTMessage(data), data);
        }
      } else {
        let status =
          data.sdsType &&
            (data.sdsType == "STATUS_MESSAGE" ||
              data.sdsType == "GROUP_STATUS_MESSAGE")
            ? true
            : false;
        let mcdata = {
          numberOfPayloads: 22,
          Payload: {
            value: data.message
              ? data.message
              : data.tetraCode
                ? data.tetraCode.toString()
                : "",
            ctype: 5,
          },
        };
        let signalingdData = {
          dateTime: 1666341630,
          conversationId: Math.random().toString(36).slice(2),
          messageId: data.messageId
            ? data.messageId
            : Math.random().toString(36).slice(2),
          SenderMCDataId: {
            value: data.fromId,
          },
          SDSDispositionRequest: {
            bitField: {
              value: 3,
            },
          },
        };
        let mcdataPayload = sdsConfig.encode(
          sdsConfig.config,
          "SdsDataPayload",
          mcdata
        );
        let signalingPayload = sdsConfig.encode(
          sdsConfig.config,
          "SdsSignallingPayload",
          signalingdData
        );

        // console.log(
        //   "TEXT_MESSAGE_STATE payload ",
        //   mcdataPayload,
        //   sdsConfig.decode(sdsConfig.config, mcdataPayload),
        //   base64.encode(mcdataPayload),
        //   Buffer.from(base64.decode(base64.encode(mcdataPayload))),
        //   sdsConfig.decode(sdsConfig.config, Buffer.from(base64.decode(base64.encode(mcdataPayload))))
        // );
        // console.log(
        //   "TEXT_MESSAGE_STATE payload ",
        //   mcdataPayload,
        //   signalingPayload,
        //   sdsConfig.decode(sdsConfig.config, mcdataPayload),
        //   sdsConfig.decode(sdsConfig.config, signalingPayload),
        //   base64.encode(mcdataPayload),

        // );

        let sdsType = "TEXT_MESSAGE";
        //let contact = _this._registrator._contact;
        let isGroupSDS = data.groupId
          ? data.groupId.length > 0
            ? true
            : false
          : false;
        if (isGroupSDS) {
          sdsType = "GROUP_TEXT_MESSAGE";
        }
        let userInfo = {
          fromId: data.fromId,
          toId: data.toId,
          callId: "abcdef",
          isStandardSDS: true,
          isGroupSds: isGroupSDS,
          groupId: data.groupId ? data.groupId : "",
          mcdataString: instance_id,
          status: status,
          isFAMessage: data.isFAMessage ? data.isFAMessage : false,
          fileId: data.fileId ? data.fileId : "",
          messageType: data.messageType ? data.messageType : "text",
        };

        let message = this.sdsformat.getHeadersAndBody(
          userInfo,
          mcdataPayload,
          signalingPayload
        );

        let eventHandlers = {
          contentType: "multipart/mixed; boundary=consort-boundary",
          extraHeaders: message.headers,
          succeeded: function (data) {
            console.log("TEXT_MESSAGE_STATE sent ", data);
          },
          failed: function (data) {
            console.log("TEXT_MESSAGE_STATE Failed", data);
          },
        };
        if (status) {
          // Status message

          let sdsType = "STATUS_MESSAGE";
          if (isGroupSDS) {
            sdsType = "GROUP_STATUS_MESSAGE";
          }
          let groupId = data.groupId ? data.groupId : "";
          let statusMsg = {
            tetraCode: data.tetraCode
              ? data.tetraCode
              : data.tetraCode.toString(),
            message: data.tetraCode
              ? data.tetraCode
              : data.tetraCode.toString(),
            groupId: groupId,
            fromId: _this.mcptt_uri,
            toId: data.toId,
            immediate: true,
            consumedReportNeeded: false,
            deliveryReportNeeded: false,
            messageId: data.messageId
              ? data.messageId
              : Math.random().toString(36).slice(2),
            creatorId: _this.mcptt_uri,
            messageType: "STATUS",
            fileId: "",
            fileType: "",
            view: false,
            communicationType: "SDS",
            sdsType: sdsType,
          };
          _this.store.addSDS(statusMsg).then((res) => {
            console.log("STATUS_MESSAGE ALERT_MESSAGE response..", res);
            const msgHandle = _this.sendMessage(
              _this.participatingServer,
              message.body,
              eventHandlers
            );
            msgHandle.on("succeeded", () => {
              console.log("TEXT_MESSAGE_STATUS SUCCESS msgHandle", res);
              data.stateType = "SUCCESS";
              data.showMsg = "STATUS is sent successfully!";
              _this.emit("STATE_UPDATE", data);
            });
            msgHandle.on("failed", () => {
              console.log("TEXT_MESSAGE_STATUS FAILED msgHandle", res);
              data.showMsg = "Failed to send status";
              data.stateType = "FAILED";
              _this.emit("STATE_UPDATE", data);
            });
          });
        } else {
          //Normal text message
          try {
            // const messageId = Math.random().toString(36).slice(2);
            const messageId = data.messageId
              ? data.messageId
              : Math.random().toString(36).slice(2)

            let grpId = data.groupId ? data.groupId : "";
            let msg = _this.delegates
              .storeMessage(
                data.message,
                _this.mcptt_uri,
                data.toId,
                messageId,
                sdsType,
                grpId,
                false,
                data.fileId,
                data.messageType
              );
            console.log("outgoing message stored..", msg);
            const msgHandle = _this.sendMessage(
              _this.participatingServer,
              message.body,
              eventHandlers
            );
            msgHandle.on("succeeded", () => {
              console.log("TEXT_MESSAGE_STATE SUCCESS msgHandle", data);
              data.stateType = "SUCCESS";
              data.showMsg = "SDS is sent successfully!";
              _this.emit("STATE_UPDATE", data);
            });
            msgHandle.on("failed", () => {
              console.log("TEXT_MESSAGE_STATE FAILED msgHandle", data);
              data.stateType = "FAILED";
              data.showMsg = "Failed to send sds";
              _this.emit("STATE_UPDATE", data);
            });
          } catch (error) {
            console.log("TEXT_MESSAGE_STATE ERROR msgHandle", data);
            data.stateType = "FAILED";
            data.showMsg = "Failed to send sds";
            _this.emit("STATE_UPDATE", data);
            return null;
          }
        }
      }
      return data; //data.messageId;
    }

    sendPTTMessage(message, data) {
      console.log("sendPTTMessage()", data);
      let _this = this;
      let eventHandlers = {
        succeeded: function (data) {
          console.log("SDS msg sent ", data);
        },
        failed: function (data) {
          console.log("Failed sending SDS ", data);
        },
      };
      try {
        const msgHandle = _this.sendMessage(
          "sip:mcdx@mcptt.org",
          message,
          eventHandlers
        );
        //console.log("SDS msg msgHandle", msgHandle, message);
        msgHandle.on("succeeded", () => {
          console.log(
            "TEXT_MESSAGE_STATE SUCCESS",
            data,
            JSON.stringify(_this.pendingPTT),
            _this.pendingPTT[data.callId]
          );
          if (data && data.callId) {
            if (!_this.getCallObjFrommCallId(data.callId)) {
              if (_this.pendingPTT[data.callId]) {
                delete _this.pendingPTT[data.callId];
              }
              console.log(
                "ongoing call is not found for callId..",
                data.callId,
                _this.pendingPTT
              );
              return;
            }
            if (_this.pendingPTT[data.callId]) {
              let pttStatus = _this.pendingPTT[data.callId];
              if (data.callActionType == "ACQUIRE_PUSH_TO_TALK") {
                // Handle On AcquireRequest successful response (200 OK)
                pttStatus.acquireState = PTT_STATUS.none;
                if (pttStatus.releaseState == PTT_STATUS.requested) {
                  pttStatus.releaseState = PTT_STATUS.pending;
                  _this.pendingPTT[data.callId] = pttStatus;
                  let updatedData = {
                    ...data,
                    callActionType: "RELEASE_PUSH_TO_TALK",
                  };
                  _this.sendPTTMessage(_this.delegates.createPTTMessage(updatedData), updatedData);
                } else {
                  console.log(
                    "no pending ptt requested..ACQUIRE_PUSH_TO_TALK.. ",
                    _this.pendingPTT[data.callId]
                  );
                  delete _this.pendingPTT[data.callId];
                }
              } else if (data.callActionType == "RELEASE_PUSH_TO_TALK") {
                // Handle On ReleaseRequest successful response (200 OK)
                pttStatus.releaseState = PTT_STATUS.none;
                if (pttStatus.acquireState == PTT_STATUS.requested) {
                  pttStatus.acquireState = PTT_STATUS.pending;
                  _this.pendingPTT[data.callId] = pttStatus;
                  let updatedData = {
                    ...data,
                    callActionType: "ACQUIRE_PUSH_TO_TALK",
                  };
                  _this.sendPTTMessage(_this.delegates.createPTTMessage(updatedData), updatedData);
                } else {
                  console.log(
                    "no pending ptt requested..RELEASE_PUSH_TO_TALK ..",
                    _this.pendingPTT[data.callId]
                  );
                  delete _this.pendingPTT[data.callId];
                }
              }
            }
          }
        });
        msgHandle.on("failed", () => {
          console.log(
            "TEXT_MESSAGE_STATE FAILED",
            data,
            _this.pendingPTT[data.callId]
          );
          if (data && data.callId) {
            //First check callID is already active or not.
            if (!_this.getCallObjFrommCallId(data.callId)) {
              if (_this.pendingPTT[data.callId]) {
                delete _this.pendingPTT[data.callId];
              }
              console.log(
                "ongoing call is not found for callId..",
                data.callId,
                _this.pendingPTT
              );
              return;
            }
            if (_this.pendingPTT[data.callId]) {
              let pttStatus = _this.pendingPTT[data.callId];
              if (pttStatus.acquireState == PTT_STATUS.pending) {
                let updatedData = {
                  ...data,
                  callActionType: "ACQUIRE_PUSH_TO_TALK",
                };
                _this.sendPTTMessage(_this.delegates.createPTTMessage(updatedData), updatedData);
              } else if (pttStatus.releaseState == PTT_STATUS.pending) {
                let updatedData = {
                  ...data,
                  callActionType: "RELEASE_PUSH_TO_TALK",
                };
                _this.sendPTTMessage(_this.delegates.createPTTMessage(updatedData), updatedData);
              }
            }
          }
        });
      } catch (error) {
        console.log("TEXT_MESSAGE_STATE ERROR", data);
        data.stateType = "FAILED";
        _this.emit("STATE_UPDATE", data);
      }
    }

    replySDS(data) {
      let sdsNotificationData = {
        dateTime: 1666341630,
        conversationId: data.conversationId
          ? data.conversationId
          : Math.random().toString(36).slice(2),
        messageId: data.messageId
          ? data.messageId
          : Math.random().toString(36).slice(2),
        SenderMCDataId: {
          value: data.fromId,
        },
        SdsNotificationType: {
          value: data.status,
        },
      };
      /* 
      status:- 
        UnDelivered:- 1
        Delivered:- 2
        Read:- 3
        Delivered & Read:- 4
      */
      let msgNotoficationPayload = sdsConfig.encode(
        sdsConfig.config,
        "SdsNotificationMsg",
        sdsNotificationData
      );
      console.log(
        "TEXT_MESSAGE_STATE notification decode payload ",
        msgNotoficationPayload,
        sdsConfig.decode(sdsConfig.config, msgNotoficationPayload)
      );

      let userInfo = {
        toId: data.toId,
      };

      let message = this.sdsformat.getSDSNotifHeadersAndBody(
        userInfo,
        msgNotoficationPayload,
        this.participatingServer
      );

      let eventHandlers = {
        contentType: "multipart/mixed; boundary=consort-boundary",
        extraHeaders: message.headers,
        succeeded: function (data) {
          console.log("SDS_NOTIF sent ", data);
        },
        failed: function (data) {
          console.log("SDS_NOTIF Failed", data);
        },
      };

      const msgHandle = this.sendMessage(
        this.participatingServer,
        message.body,
        eventHandlers
      );
      //console.log("SDS msg msgHandle", msgHandle);
      //console.log("SDS msg msgHandle 2", message);
      msgHandle.on("succeeded", () => {
        console.log("SDS_NOTIF SUCCESS");
      });
      msgHandle.on("failed", () => {
        console.log("SDS_NOTIF FAILED msgHandle");
      });
    }

    createDGNA(groupInfo) {
      //console.log("Creating DGNA for :", groupInfo);
      const userMember = {
        contactName: this.mcxUserProfile.CallerDescr,
        mcptt_id: this.mcxUserProfile.mcptt_uri,
        mcptt_uri: this.mcxUserProfile.mcptt_uri,
      };
      let updatedGrpMembers = [...groupInfo.grpMembers, userMember];
      let data = { ...groupInfo };
      data.domain = this.userDomain ? this.userDomain : "";
      data.grpMembers = updatedGrpMembers;
      console.log("Creating DGNA for final :", data);
      const _this = this;
      this.gmc.createGroup(data).then((grpData) => {
        //console.log("contact fetch mcxclient", contacts);
        setTimeout(() => {
          _this.emit("DGNA_CREATION_STATE", grpData);
        }, 500);
      });
    }

    deleteDGNA(groupInfo) {
      console.log("Deleting DGNA :", groupInfo);
      this.gmc
        .deleteGroup(groupInfo)
        .then((contacts) => {
          console.log("contact fetch mcxclient", contacts);
        })
        .catch((error) => {
          console.log("contact fetch error", error);
        });
    }

    getDgnaState(groupName) {
      console.log("Getting DGNA state : ", groupName);
      this.gmc.getGroup(groupName);
    }

    updateDGNA(groupInfo) {
      console.log("Updating DGNA :", groupInfo);
      groupInfo.fromId = this.mcxUserProfile.profileName;
      this.gmc.updateGroup(groupInfo);
    }

    editDGNA(groupInfo) {
      const userMember = {
        contactName: this.mcxUserProfile.CallerDescr,
        mcptt_id: this.mcxUserProfile.profileName,
        mcptt_uri: this.mcxUserProfile.mcptt_uri,
      };
      let updatedGrpMembers = [...groupInfo.grpMembers, userMember];
      let data = { ...groupInfo };
      data.domain = this.userDomain ? this.userDomain : "";
      data.grpMembers = updatedGrpMembers;
      data.fromId = this.mcxUserProfile.mcptt_uri;
      console.log("Updating DGNA params:", data);
      this.gmc.editDGNAGroup(data);
    }

    addDeleteDGNA(groupInfo) {
      let domain = this.userDomain ? this.userDomain : "";
      let fromId = this.mcxUserProfile.profileName;
      this.gmc.mergeDGNA(groupInfo, domain, fromId);
    }

    log(data1, data2) {
      remote.log(data1, data2);
    }

    getPriority(priority) {
      var p;
      switch (priority) {
        case priority > 0 && priority <= 4:
          p = 0;
          break;
        case priority > 4 && priority <= 9:
          p = 1;
          break;
        case priority > 9 && priority <= 13:
          p = 2;
          break;
        case priority === 14:
          p = 3;
          break;
        case priority === 15:
          p = 4;
          break;
        default:
          break;
      }
      return p;
    }

    getExtraHeaders(callObject) {
      //let headers = [];
      let header1 = [];
      //var header2;
      //var header3;
      header1.push("is-video: " + callObject.isVideo);
      switch (callObject.callType) {
        case "DUPLEX_INDIVIDUAL_CALL":
          header1.push("Answer-Mode: Manual");
          header1.push("Session-Type: private");
          if (!callObject.toId.includes("@")) {
            if (callObject.pbxUser) {
              if (callObject.pbxDomain) {
                header1.push(
                  "Request-Uri: " +
                  "sip:" +
                  callObject.toId +
                  "@" +
                  callObject.pbxDomain
                );
              } else {
                header1.push(
                  "Request-Uri: " + "sip:" + callObject.toId + "@" + "pbx"
                );
              }
            } else {
              header1.push(
                "Request-Uri: " +
                "sip:" +
                callObject.toId +
                "@" +
                this.config.domain
              );
            }
          } else {
            header1.push("Request-Uri: " + "sip:" + callObject.toId);
          }
          header1.push(
            "Request-TetraId: " + callObject.toId || callObject.tetraId
          );
          header1.push("Resource-Priority: " + callObject.callPriority);
          header1.push("Call-Index: " + callObject.indexId);
          break;
        case "SIMPLEX_INDIVIDUAL_HOOK_CALL":
          header1.push("Answer-Mode: Auto");
          header1.push("Session-Type: private");
          if (!callObject.toId.includes("@")) {
            header1.push(
              "Request-Uri: " +
              "sip:" +
              callObject.toId +
              "@" +
              this.config.domain
            );
          } else {
            header1.push("Request-Uri: " + "sip:" + callObject.toId);
          }
          header1.push(
            "Request-TetraId: " + callObject.toId || callObject.tetraId
          );
          header1.push("Resource-Priority: " + callObject.callPriority);
          header1.push(
            "X-MCX-Floor: UDP=" +
            "123456" +
            "; mc_priority = 15; mc_implicit; mc_queueing"
          );
          break;
        case "SIMPLEX_INDIVIDUAL_DIRECT_CALL":
          header1.push("Answer-Mode: Manual");
          header1.push("Session-Type: private");
          if (!callObject.toId.includes("@")) {
            header1.push(
              "Request-Uri: " +
              "sip:" +
              callObject.toId +
              "@" +
              this.config.domain
            );
          } else {
            header1.push("Request-Uri: " + "sip:" + callObject.toId);
          }
          header1.push(
            "Request-TetraId: " + callObject.toId || callObject.tetraId
          );
          header1.push("Resource-Priority: " + callObject.callPriority);
          header1.push(
            "X-MCX-Floor: UDP=" +
            "123456" +
            "; mc_priority = 15; mc_implicit; mc_queueing"
          );
          break;
        case "SIMPLEX_GROUP_CALL":
          header1.push("Answer-Mode: Auto");
          header1.push("Session-Type: prearranged");
          if (!callObject.toId.includes("@")) {
            header1.push(
              "Request-Uri: " +
              "sip:" +
              callObject.toId +
              "@" +
              this.config.domain
            );
          } else {
            header1.push("Request-Uri: " + "sip:" + callObject.toId);
          }
          header1.push(
            "Request-TetraId: " + callObject.toId || callObject.tetraId
          );
          header1.push("Resource-Priority: " + callObject.callPriority);
          header1.push(
            "X-MCX-Floor: UDP=" +
            "123456" +
            "; mc_priority = 15; mc_implicit; mc_queueing"
          );
          break;
        case "SIMPLEX_BROADCAST_GROUP_CALL":
          header1.push("Answer-Mode: Auto");
          header1.push("Session-Type: prearranged");
          if (!callObject.toId.includes("@")) {
            header1.push(
              "Request-Uri: " +
              "sip:" +
              callObject.toId +
              "@" +
              this.config.domain
            );
          } else {
            header1.push("Request-Uri: " + "sip:" + callObject.toId);
          }
          header1.push(
            "Request-TetraId: " + callObject.toId || callObject.tetraId
          );
          header1.push("Resource-Priority: " + callObject.callPriority);
          header1.push(
            "X-MCX-Floor: UDP=" +
            "123456" +
            "; mc_priority = 15; mc_implicit; mc_queueing"
          );
          header1.push(
            "X-MCX-Indication: Broadcast; No-Alert; resource_priority = P.8"
          );
          break;
        case "AMBIENT_LISTENING_CALL":
          header1.push("Answer-Mode: Auto");
          header1.push("Session-Type: ambient- listening");
          if (!callObject.toId.includes("@")) {
            header1.push(
              "Request-Uri: " +
              "sip:" +
              callObject.toId +
              "@" +
              this.config.domain
            );
          } else {
            header1.push("Request-Uri: " + "sip:" + callObject.toId);
          }
          header1.push(
            "Request-TetraId: " + callObject.toId || callObject.tetraId
          );
          header1.push("Resource-Priority: " + callObject.callPriority);
          header1.push("X-MCX-Floor: UDP; mc_granted");
          //header1.push('X-MCX-Indication: Broadcast; No-Alert; resource_priority = P.8');
          break;
        case "MERGE_CALL":
          break;
        default:
          break;
      }
      //return [header1,header2,header3];
      return header1;
    }

    getPagnData(data) {
      console.log("getPagnData...", data);
      const { apiType, id, per_page, current_page, search, type } = data;
      switch (apiType) {
        case "ALERTS":
          return this.store.getAlertsData(id, type, per_page, current_page);
        case "SDS":
          return this.store.getSDSData(id, type, per_page, current_page);
        case "LOGS":
          return this.store.getLogs(id, type, per_page, current_page, search);
        default:
          return [];
      }
    }

    getUserSDSMesage(data) {
      const { disp_id, toId, per_page, current_page } = data;
      return this.store.getUserSDSMesage(disp_id, toId, per_page, current_page);
    }

    getUnreadUserCountMsg(data) {
      const { disp_id } = data;
      return this.store.getUnreadUserCountMsg(disp_id);
    }
    updateSdsMessageViewed(messageIdArray) {
      // const { disp_id } = data;
      //   {
      //     "msgId":["pru9bvqaum","pdmx7rrrhq9"]
      // }
      return this.store.putSDSMsgView(messageIdArray);
    }

    uploadFile(data) {
      return this.store.uploadFile(data);
    }

    getData(type, id) {
      switch (type) {
        case "CON":
          return this.store.getCon();
        case "SDS":
          return this.store.getSDS(id);
        case "SDS_GROUP":
          return this.store.getGroupSDS(id);
        case "ALERTS":
          return this.store.getStatus(id);
        case "ALERTS_GROUP":
          return this.store.getGroupStatus(id);
        case "CALLS":
          return this.store.getCall(id);
        case "GROUP_CALLS":
          return this.store.getGroupCall(id);
        case "FAVS":
          return this.store.getfavCont(id);
        case "LISTS":
          return this.store.getUserLists(id);
        case "DGNA":
          return this.store.getDGNA(id);
        default:
          return [];
      }
    }

    updateData(type, data) {
      switch (type) {
        case "SDS":
          this.store.updateSDS(data);
          break;
        case "ALERTS":
          this.store.updateStatus(data);
          break;
        case "CALLS":
          this.store.updateCall(data);
          break;
        case "ADD_FAV":
          return this.store.addFav(data);
        case "REM_FAV":
          return this.store.remFav(data);
        case "PIN_ALERT":
          this.store.pinAlert(data);
          break;
        case "ACK_ALERT":
          this.store.ackAlert(data);
          break;
        case "LISTS":
          return this.store.updateLists(data);
        case "DGNA":
          return this.store.updateDGNA(data);
        default:
          break;
      }
    }

    deleteData(type, msgId) {
      switch (type) {
        case "SDS":
          this.store.deleteSDS(msgId);
          break;
        case "LISTS":
          return this.store.deleteList(msgId);
        case "DGNA":
          return this.store.deleteDGNA(msgId);
        default:
          break;
      }
    }

    addData(type, data) {
      switch (type) {
        case "LISTS":
          return this.store.exportList(data);
        case "DGNA":
          return this.store.createDGNA(data);
        case "ACK_CALL":
          let modifiedData = {
            ...data,
            creatorId: this.mcptt_uri,
            UEid: "0000",
          };
          return this.store.addCall(modifiedData);
        default:
          break;
      }
    }

    sendLocation(latitude, longitude) {
      console.log("location requested started.......");
      let locInfo = locationInfo;
      const extraHeaders = [`Contact: ${this._registrator._contact}`];
      locInfo["Report"]["CurrentLocation"][
        "CurrentCoordinate"
      ].latitude = latitude;
      locInfo["Report"]["CurrentLocation"][
        "CurrentCoordinate"
      ].longitude = longitude;

      var eventHandlers = {
        succeeded: function (e) {
          console.log("loc message sent successfully!!!");
        },
        failed: function (e) {
          console.log("loc message failed!! 1", e);
        },
      };

      var options = {
        eventHandlers: eventHandlers,
        extraHeaders: extraHeaders,
        contentType: "application/vnd.3gpp.mcptt-location-info+xml",
      };
      let locParser = js2xmlparser.parse("location-info", locInfo);
      this.sendMessage(this.participatingServer, locParser, options);
    }
    getTriggeringTimer() {
      return 5;
    }
    makeURIDomain(mcInfoId, withSip = false) {
      if (withSip) {
        return `sip:${mcInfoId}@${this.config.domain}`;
      } else {
        return `${mcInfoId}@${this.config.domain}`;
      }
    }
    sendAck(currentCall) {
      console.log("send Ack.....1");
      let callId = null;
      if (currentCall.callId) {
        callId = currentCall.callId;
      }
      if (!callId) {
        if (currentCall.callData && currentCall.callData.callId) {
          callId = currentCall.callData.callId;
        } else {
          return;
        }
      }
      const MCPC_MESSAGE = "MCPCAcknowledge";
      let data = {};
      data.callId = callId;
      data.callActionType = MCPC_MESSAGE;

      this.sendSDSPTT(data);
      console.log("send Ack.....2");
    }

    handlePttCallPreEstCall(action) {
      console.log("MCPC connect.............. 1", action);
      let _this = this;
      this.isPreEstCallAnswered(action.preEstSessionUri);

      if (action.callActionType === "MCPC_CONNECT") {
        if (!action.toId || action.toId == "") {
          // callee end
          action.toId = this.mcptt_uri;
        }
        if (!action.callPriority) {
          action.callPriority = 11;
        }
        let currentCall = _this.getAvailablePreEstSession(action);
        this.sendAck(currentCall);
        if (_this.isCallTypeManual(currentCall.callData.callType)) {
          _this.muteUnmutePreEstSession(currentCall, true);
        } else {
          _this.muteUnmutePreEstSession(currentCall, false);
        }

        let callState = currentCall.callState;

        if (callState != "ptt_recieved") {
          currentCall.callState = "answered";
        }
        _this.addCallInOngoingCalls(currentCall);

        if (
          currentCall.callData.callType === "DUPLEX_INDIVIDUAL_CALL" ||
          currentCall.callData.callType === "SIMPLEX_INDIVIDUAL_HOOK_CALL" ||
          callState == "started" ||
          callState == "ptt_recieved" // call initiated by user
        ) {
          _this.preEstCallAnswereEventSent(currentCall.preEstSessionUri);
          let data = currentCall.callData;

          data.callActionType = "ANSWER";
          data.localAudio = currentCall.callData.localAudio;
          _this.isPreEstCallAnswered(
            currentCall.preEstSessionUri
          );
          if (data) {
            _this.muteUnmutePreEstSession(currentCall, false);
            _this.emit("ANSWER", data);
          }
        } else {
          _this.emit(currentCall.callData.callType, currentCall.callData);
        }
        _this.getStats(currentCall);
      } else if (action.callActionType === "MCPC_DISCONNECT") {
        action.callActionType = "DISCONNECTED";
        let currentCall = _this.getCurrentOnGoingPreEstCall(action);
        if (!currentCall || Object.keys(currentCall).length === 0) {
          return;
        }

        this.sendAck(currentCall);
        _this.muteUnmutePreEstSession(currentCall, false);

        let currentPreCall = currentCall.callData;
        currentPreCall.callActionType = "DISCONNECTED";
        _this.releasePreEstSession(currentCall);
        if (_this.pendingPTT[currentPreCall.callId]) {
          delete _this.pendingPTT[currentPreCall.callId];
        }
        if (currentCall.callState == "started") {
          currentPreCall.stateType = "MISSED";
        } else {
          currentPreCall.stateType = "COMPLETED";
        }
        this.delegates.createCallXml(currentPreCall, this);

        _this.emit(action.communicationType, currentPreCall);
      } else {
        let matchedCall = null;
        for (let call of _this.ongoingCalls) {
          if (call.callData) {
            if (call.callData.callId == action.callId) {
              call.talkingPartyId = action.talkingPartyId;
              matchedCall = call;
              break;
            }
          }
        }
        if (matchedCall) {
          _this.updateSdpOnPTT(action.talkingPartyId, matchedCall);
        }
        let matchCallId = matchedCall ? matchedCall.callId : null;

        let needToDisconnect = false;
        if (_this.releasePTTCurrentCall && _this.releasePTTCurrentCall.callId && matchCallId) {
          if (_this.releasePTTCurrentCall.callId == matchCallId && _this.releasePTTCurrentCall.status) {
            needToDisconnect = true;
          }
        } else if (_this.releasePTTCurrentCall && !_this.releasePTTCurrentCall.callId && _this.releasePTTCurrentCall.status) {
          needToDisconnect = true;
        }

        if (
          _this.ongoingCalls.length <= 1 &&
          needToDisconnect &&
          _this.checkPTTStatus(action.talkingPartyId)
        ) {
          _this.releasePTTCurrentCall.status = false;
          //release PTT
          ///////////////////////////
          if (!matchedCall || matchedCall.callState != "ptt_recieved") {
            let state = "DISCONNECTED";
            _this.sendPTTRelease(
              matchedCall.callId,
              state
              // "RELEASE_PUSH_TO_TALK"
            );
          } else {
            let state = "RELEASE_PUSH_TO_TALK";
            _this.sendPTTRelease(matchedCall.callId, state);
          }

          /////////////////////////
          // let state = "DISCONNECTED";
          // _this.sendPTTRelease(
          //   action.callId,
          //   state
          //   // "RELEASE_PUSH_TO_TALK"
          // );
        } else {
          if (!matchedCall) {
            return;
          }
          matchedCall.callData.communicationType = action.communicationType;
          matchedCall.callData.callActionType = action.callActionType;
          matchedCall.callData.talkingPartyId = action.talkingPartyId;
          matchedCall.callData.preEstSessionUri = matchedCall.preEstSessionUri;
          let currentCall = matchedCall.callData;

          let callAnswerSent = _this.isPreEstCallAnswered(
            currentCall.preEstSessionUri
          );

          let isPreEstCall = _this.isPreEstCall(currentCall.preEstSessionUri);

          matchedCall.callState = "ptt_recieved";
          _this.addCallInOngoingCalls(matchedCall);

          let newForwardedCall = null;
          if (_this.callforwardingArr) {
            for (let callFObj of _this.callforwardingArr) {
              if (callFObj.callData.callId1 == currentCall.callId) {
                newForwardedCall = { ...callFObj.callData };
                newForwardedCall.communicationType = action.communicationType;
                newForwardedCall.callActionType = action.callActionType;
                newForwardedCall.talkingPartyId = action.talkingPartyId;
                break;
              }
            }
          }

          if ((isPreEstCall && callAnswerSent) || !isPreEstCall) {
            if (newForwardedCall) {
              console.log("push_to_talk call forward", newForwardedCall);
              _this.emit(currentCall.communicationType, newForwardedCall);
            } else {
              console.log("push_to_talk call orig", currentCall);
              _this.emit(currentCall.communicationType, currentCall);
            }
          }
        }
      }
    }
    checkPTTStatus(talkingId) {
      let talkingPartyId = talkingId;
      let loggedInId = this.mcptt_uri;
      if (talkingPartyId && talkingPartyId.includes("@")) {
        talkingPartyId = talkingPartyId.split("@")[0];
      }
      if (loggedInId && loggedInId.includes("@")) {
        loggedInId = loggedInId.split("@")[0];
      }
      console.log(
        "checkPTTStatus.....",
        talkingId,
        talkingPartyId,
        loggedInId,
        talkingId == loggedInId,
        talkingPartyId == loggedInId
      );
      if (talkingPartyId == loggedInId) {
        return true;
      }
      return false;
    }

    createPreEstSession(totalSessionCount) {
      console.log("createPreEstSession()");
      if (this.configPreEstSessionCount <= 0) {
        console.log("createPreEstSession() session count is zero");
        return;
      }

      console.log(
        "createPreEstSession stats..",
        this.configPreEstSessionCount,
        this.ongoingPreestablishedCallSessions.length,
        totalSessionCount,
        this.preEstSessionCreationRunning,
        this.registerprocessSate.getRegistrationState()
      );

      //Check Registration Status......
      if (!this.registerprocessSate.isRegistered()) {
        console.log("createPreEstSession() mcxclient is not registered ");
        return;
      }

      // this.emit(
      //   "PRE_EST_COUNT",
      //   this.ongoingPreestablishedCallSessions.length +
      //   " _ " +
      //   this.ongoingCalls.length +
      //   " " +
      //   this.timeDiffFromCallInitiatedAtApp
      // );

      if (
        (this.ongoingPreestablishedCallSessions &&
          this.ongoingPreestablishedCallSessions.length >= totalSessionCount) ||
        this.preEstSessionCreationRunning
      ) {
        if (
          this.ongoingPreestablishedCallSessions &&
          this.ongoingPreestablishedCallSessions.length > totalSessionCount
        ) {
          this.maintainPreEstSessionCount(totalSessionCount);
        }
        console.log(
          "Either all Sessions is established or session is in progress!!",
          this.preEstSessionCreationRunning
        );
        return;
      }
      this.preEstSessionCreationRunning = true;

      let isEmergencyCall = false;
      let isVideo = false;
      let pbxDomain = "";
      let navigation = null;
      const callInfo = {
        // toId: toId,
        fromId: this.mcptt_uri, //user.profile.mcptt_id,
        indexId: 1,
        // callType: callType,
        communicationType: "CALL",
        callPriority: isEmergencyCall ? 15 : 6,
        isVideo: isVideo ? true : false, //this.isCameraEnable,
        pbxUser: false,
        pbxDomain: pbxDomain,
      };
      const callbackRes = (result) => {
        // callback(result);
      };

      this.getLocalStream(
        callInfo,
        "INITIATE_CALL",
        callbackRes,
        navigation,
        isVideo,
        true
      );
    }

    getLocalStream(
      callData,
      actionType,
      callback = null,
      navigation,
      isVideo,
      isPreestablishedSession = false
    ) {
      console.log("IS VIDEO______________23__", isVideo);
      if (!global.mediaDevices) {
        console.log("set media device object mediaDevices");
        return;
      }

      let constraints = {
        video: false,
        audio: true,
        // audio: {
        //   echoCancellation: true,
        //   noiseSuppression: true,
        //   autoGainControl: true,
        //   googEchoCancellation: true,
        //   googAutoGainControl: true,
        //   googNoiseSuppression: true,
        //   googHighpassFilter: true,
        //   googTypingNoiseDetection: true,
        //   googNoiseReduction: true,
        //   volume: 1.0,
        // },
      };
      if (this.isNoiseSuppression) {
        constraints.audio = {
          echoCancellation: true,
          noiseSuppression: true,
        };
      }

      let _this = this;
      global.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          // Got stream!
          console.log("localMediaStream........");

          let callActiondata = { ...callData, localAudio: stream };

          if (actionType === "INITIATE_CALL") {
            // this.currentInitiatedCall = callActiondata;
            let res;
            if (isPreestablishedSession) {
              res = _this.initiateCall(callActiondata, true);
            } else {
              res = _this.initiateCall(callActiondata);
            }

            if (res) {
              let callWithId = {
                ...callActiondata,
                callId: res,
                // actionItem: initialCallState,
                stateType: "INITIATED",
              };
              callback(callWithId);
            } else {
              callback(null);
            }
          } else if (actionType === "ANSWER") {
            _this.sendCallAction(callActiondata);
          } else if (actionType === "RELEASE_PUSH_TO_TALK") {
            console.log("callAction data", callActiondata);
            _this.sendCallAction(callActiondata);
          }
        })
        .catch((error) => {
          console.log("stream error", error);
          _this.preEstSessionCreationRunning = false;
        });
    }

    getPreEstSessionCount(resp) {
      let totalPreEstSessionCount = -1;
      if (resp && resp.preEstablishConfig) {
        for (const preEstablishData of resp.preEstablishConfig) {
          if (preEstablishData.ueType && this.getMatchedUEConfig(preEstablishData.ueType) == this.userAgent.toLowerCase()) {
            totalPreEstSessionCount = preEstablishData.CountOfPreSessions ? preEstablishData.CountOfPreSessions : -1;
            break;
          }
        }
      } else if (resp && resp.totalPreEstSessionCount) {
        totalPreEstSessionCount = resp.totalPreEstSessionCount ? resp.totalPreEstSessionCount : -1;
      }
      return totalPreEstSessionCount;
    }

    getMatchedUEConfig(ueVal) {
      let matchedUEType = ueVal;
      if (this.admin && this.admin.msterUEConfig) {
        if (this.admin.msterUEConfig[ueVal]) {
          matchedUEType = this.admin.msterUEConfig[ueVal];
        }
      }
      return matchedUEType.toLowerCase();
    }
  }

  function createMcpttInfoXml(token) {
    var info = mcpttInfo;
    info["mcptt-Params"]["mcptt-access-token"]["@"].type = "Normal";
    info["mcptt-Params"]["mcptt-access-token"].mcpttString = token;
    info["mcptt-Params"]["mcptt-client-id"]["@"].type = "Normal";
    info["mcptt-Params"]["mcptt-client-id"].mcpttString = instance_id;
    return js2xmlparser.parse("mcpttinfo", info);

    /*var infoData = mcdataInfo;
    infoData["mcdata-Params"]["mcdata-access-token"]["@"].type = "Normal";
    infoData["mcdata-Params"]["mcdata-access-token"].mcdataString = token;
    infoData["mcdata-Params"]["mcdata-client-id"]["@"].type = "Normal";
    infoData["mcdata-Params"]["mcdata-client-id"].mcdataString = instance_id;
    const body = `--[boundary]\r\nContent-Type: application/vnd.3gpp.mcptt-info+xml\r\n\r\n${js2xmlparser.parse(
      "mcpttinfo",
      info
    )}\r\n\r\n--[boundary]\r\nContent-Type: application/vnd.3gpp.mcdata-info+xml\r\n\r\n${js2xmlparser.parse(
      "mcdatainfo",
      infoData
    )}\r\n--[boundary]`;
    return body;*/
  }

  const mcpttInfo = {
    "@": {
      xmlns: "urn:3gpp:ns:mcpttInfo:1.0",
    },
    "mcptt-Params": {
      "mcptt-access-token": {
        "@": {
          type: "",
        },
        mcpttString: "",
      },
      "mcptt-client-id": {
        "@": {
          type: "",
        },
        mcpttString: "",
      },
    },
  };

  const mcdataInfo = {
    "@": {
      xmlns: "urn:3gpp:ns:mcdataInfo:1.0",
    },
    "mcdata-Params": {
      "mcdata-access-token": {
        "@": {
          type: "",
        },
        mcdataString: "",
      },
      "mcdata-client-id": {
        "@": {
          type: "",
        },
        mcdataString: "",
      },
    },
  };

  const locationInfo = {
    Report: {
      "@": {
        ReportType: "NonEmergency",
      },
      CurrentLocation: {
        CurrentCoordinate: {
          latitude: "4032351",
          longitude: "16639587",
        },
        anyExt: "",
      },
      TriggerId: "Periodic",
    },
  };

  exports.MCXClient = MCXClient;
})(typeof exports === "undefined" ? (this["MCXClient"] = {}) : exports);
