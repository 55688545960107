(function (exports) {
  const mysqlPath = "service/base/mysql";
  const systemPath = "service/base/system";
  const wsPath = "client/dispatcher";
  const authPath = "service/signaling/utility";
  const EndPoints = {
    getConfig() {
      return {
        healthCheck: `https://${global.config.ipConfig.drachtioHost}:${global.config.ipConfig.wsPort}/${systemPath}/health`,
        sdsLocation: `http://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/${mysqlPath}/sdsLocation`,
        snailTrail: `http://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/${mysqlPath}/snailTrail`,
        fence: `http://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/${mysqlPath}/geoFence`,
        geoFenceActivity: `http://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/${mysqlPath}/geoFenceActivity`,
        cacheReload: `http://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/${mysqlPath}/reloadCache`,
        discreetCall: `http://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/${mysqlPath}/discreetCall`,
        authenticate: `http://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/${authPath}/authenticate`,
        user: `http://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/${mysqlPath}/user`,
        trainInfo: `http://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/${mysqlPath}/trainInfo`,
        role: `http://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/${mysqlPath}/role`,
        access: `http://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/${mysqlPath}/access`,
        subscribers: `http://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/${authPath}/getSubscribers`,
        subscriber: `http://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/${mysqlPath}`,
        property: `http://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/${mysqlPath}/property`,
        statusMessage: `http://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/${mysqlPath}/statusType`,
        globalCustomMessageText: `http://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/${mysqlPath}/globalCustomMessageText`,
        userCustomMessageText: `http://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/${mysqlPath}/userCustomMessageText`,
        //***RIS
        currentpositions: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/currentpositions`,
        trainmap: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/trainmap`,
        getAlertAllList: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/getAllAlertList`,
        getAlertTypes: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/getAlertTypes`,
        updateAlert: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/updateAlert`,
        addAlert: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/addAlert`,
        deleteAlertByCode: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/deleteAlertByCode`,
        //***Dispatcher Server
        favContacts: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/contactlists/`,
        addFav: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/addtofav/`,
        sdsTextMessage: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/sdsTextMessage/`,
        sdsGroupMessage: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/sdsGroupMessage/`,
        sdsAlerts: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/sdsAlerts/`,
        sdsGroupAlerts: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/getGroupAlerts/`,
        pinAlert: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/pinAlert/`,
        individualCall: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/individualCall/`,
        groupCall: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/groupCall/`,
        calls: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/calls/`,
        conn: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/connectionStatus/`,
        // PAGINATION
        logs: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/getAllLogs/`,
        alertLogs: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/getAlerts/`,
        sdsLogs: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/getSdsMessages/`,
        sdsUserMsg: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/sdsUserTextMessage/`,
        sdsUserMsgPrev: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/sdsUserPrevTextMessage/`,
        sdsUserList: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/sdsUserTextMessageList/`,
        remoteLog: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/log/`,
        //FILE UPLOAD AND DOWNLOAD
        fileUpload: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/sdsFile`,
        fileDownload: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/sdsFile/`,
        fileDownloadURL: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/sdsFileDownload/`,
        unreadUserMsg: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/sdsCountUnread/`,
        sdsMessageViewed: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/sdsMessageViewed/`,
        //LIST
        userLists: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/lists/`,
        //DGNA
        dgna: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/dgna/`,
        //MC service servers
        faHost: `consort.org`,

        //ADMIN
        idmsHost: `https://${global.config.ipConfig.idmsHost}:${global.config.ipConfig.idmsPort}/User`,
        idmsURI: `${global.config.ipConfig.idmsHost}:${global.config.ipConfig.idmsPort}`,
        host: global.config.ipConfig.host,
        cmcPort: global.config.ipConfig.cmcPort,
        wsPort: global.config.ipConfig.wsPort,
        gmcPort: global.config.ipConfig.gmcPort,
        iwfPort: global.config.ipConfig.iwfPort,
        wssPort: global.config.ipConfig.wssPort,
        CMSURI: `https://${global.config.ipConfig.host}:${global.config.ipConfig.cmcPort}`,
        GMSURI: `https://${global.config.ipConfig.host}:${global.config.ipConfig.gmcPort}`,
        IWFURI: `https://${global.config.ipConfig.host}:${global.config.ipConfig.iwfPort}`,
        activeGroupMembersAPI: `https://${global.config.ipConfig.host}:${global.config.ipConfig.userApiPort}/stats/consort/getActiveGroupMembers`,
        activeGroupsForUserAPI: `https://${global.config.ipConfig.host}:${global.config.ipConfig.userApiPort}/stats/consort/getActiveGroupsForUser`,
        getMcpttIdAndUri: `https://${global.config.ipConfig.host}:${global.config.ipConfig.cmcPort}/cms/consort/getMcxMcpttIdAndUri`,


        // MTM
        getAllSubDomains: `https://${global.config.ipConfig.host}:${global.config.ipConfig.cmcPort}/mtm/getAllSubDomains`,
        getAdminProfile: `https://${global.config.ipConfig.host}:${global.config.ipConfig.cmcPort}/mtm/getAdminProfile`,
        getAllSwitchProfiles: `https://${global.config.ipConfig.host}:${global.config.ipConfig.cmcPort}/mtm/getAllSwitchProfiles`,
        getPartnerList: `https://${global.config.ipConfig.host}:${global.config.ipConfig.cmcPort}/mtm/getPartnerList`,
        createPartner: `https://${global.config.ipConfig.host}:${global.config.ipConfig.cmcPort}/mtm/createPartner`,
        deletePartner: `https://${global.config.ipConfig.host}:${global.config.ipConfig.cmcPort}/mtm/deletePartner`,
        getTenantList: `https://${global.config.ipConfig.host}:${global.config.ipConfig.cmcPort}/mtm/getTenantList`,
        createTenant: `https://${global.config.ipConfig.host}:${global.config.ipConfig.cmcPort}/mtm/createTenant`,
        updateTenant: `https://${global.config.ipConfig.host}:${global.config.ipConfig.cmcPort}/mtm/updateTenant`,
        deleteTenant: `https://${global.config.ipConfig.host}:${global.config.ipConfig.cmcPort}/mtm/deleteTenant`,
        getUeTypeList: `https://${global.config.ipConfig.host}:${global.config.ipConfig.cmcPort}/mtm/getUeTypes`,
        getAvailableServices: `https://${global.config.ipConfig.host}:${global.config.ipConfig.cmcPort}/mtm/getAvailableServices`,
        getMasterConfigServiceConfig: `https://${global.config.ipConfig.host}:${global.config.ipConfig.cmcPort}/mtm/getMasterServiceConfig`,
        listPartnerSubscriptions: `https://${global.config.ipConfig.host}:${global.config.ipConfig.ecommercePort}/api/subscriptions/listPartnerSubscriptions`,
        listTenantSubscriptions: `https://${global.config.ipConfig.host}:${global.config.ipConfig.ecommercePort}/api/subscriptions/listTenantSubscriptions`,
        getCurrentActiveSubscriptions: `https://${global.config.ipConfig.host}:${global.config.ipConfig.ecommercePort}/api/subscriptions/getCurrentActiveSubscriptions`,
        getCurrentActiveSubscriptionsForUser: `https://${global.config.ipConfig.host}:${global.config.ipConfig.ecommercePort}/api/subscriptions/getCurrentActiveSubscriptionsForUser`,
        allocateUserFromTenantSubscription: `https://${global.config.ipConfig.host}:${global.config.ipConfig.ecommercePort}/api/subscriptions/allocateUserFromTenantSubscription`,
        deAllocateUserFromTenantSubscription: `https://${global.config.ipConfig.host}:${global.config.ipConfig.ecommercePort}/api/subscriptions/deAllocateUserFromTenantSubscription`,
        listOrders: `https://${global.config.ipConfig.host}:${global.config.ipConfig.ecommercePort}/api/orders/listOrders`,
        updateOrder: `https://${global.config.ipConfig.host}:${global.config.ipConfig.ecommercePort}/api/orders/updateOrder`,
        createOrder: `https://${global.config.ipConfig.host}:${global.config.ipConfig.ecommercePort}/api/orders/createOrder`,
        createProduct: `https://${global.config.ipConfig.host}:${global.config.ipConfig.ecommercePort}/api/products/createProduct`,
        updateProduct: `https://${global.config.ipConfig.host}:${global.config.ipConfig.ecommercePort}/api/products/updateProduct`,
        listProducts: `https://${global.config.ipConfig.host}:${global.config.ipConfig.ecommercePort}/api/products/listProducts`,
        listBuyProducts: `https://${global.config.ipConfig.host}:${global.config.ipConfig.ecommercePort}/api/products/listBuyProducts`,

        // TASK RELATED APIs   
        taskList: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/taskList`,
        taskType: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/taskType`,
        task: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/task`,
        getActiveTasksCount: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/getActiveTasksCount`,

        //GroupAPIs
        CMSGrp: `https://${global.config.ipConfig.host}:${global.config.ipConfig.cmcPort}/cms/consort/getUsersByGroup`,

        //Recoder related APIs
        getCallRecord: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/recordings/search`,
        getPTTEvents: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/recordings/pttEvent`,
        getmp4File: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/recordings/mp4File`,
        getSubtitleFile: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/recordings/subtitle`,
        archiveFile: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/recordings/archiveFile`,
        exportArchiveFile: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/recordings/archiveFileExport`,

        // Chatlist
        getChatUserList: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/getChatList`,
        // getChatUserList: `https://localhost:3001/getChatList`

        // constant endpoint 
        //*****RIS
        getAllLocs: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/GetLOCs`,
        currentLA: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/currentLA`,
        updateLA: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/updateLA`,
        getAllBaseStations: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/basestation`,
        getGrabbedLines: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/controllerInfo`,
        getInactiveMap: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/consort/getInactiveMap`,

        //POI

        // getPOI:                 `https://${host}:${defaultPort}/POI`,
        getObject: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/getObject`,

        getPOI: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/POI`,
        createPOI: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/createPOI`,
        updatePOI: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/updatePOI`,
        delPOI: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/delPOI`,

        getFence: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/fence`,
        createFence: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/createFence`,
        delFence: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/delFence`,
        updateFence: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/updateFence`,

        getLayers: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/getLayers`,
        createLayer: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/createLayer`,
        updateLayer: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/updateLayer`,
        delLayer: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/delLayer`,
        getLayerEntities: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/Layer`,

        createUser: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/createUser`,
        deleteUser: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/deleteUser`,
        getAllUser: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/getAllUser`,
        getuserCountInsideFence: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/userCountInsideFence`,
        quickFence: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/quickFence`,

        getSnailTrail:`https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/getSnailTrail`,
        getCurrenLocationMdm:`https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/getCurrentLocation`,
        // TASK RELATED APIs   
        updateTask: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/task`,
        acknowledgeAllTask: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/acknowledgeAll`,

        // websocket url
        websocketURL: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}`,

        // predefined messages dispatcher
        userMessages: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/usermessages`,
        globalmessages: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/globalmessages`,

        // dispatcher status messages
        status: `https://${global.config.ipConfig.dispatcherHost}:${global.config.ipConfig.dispatcherServerPort}/status`,

        //  MDM
        getDeviceList: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/devices`,
        getDeviceTypeList: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/deviceType`,
        getConfigList: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/configurations`,
        getAppList: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/applications`,
        deleteConfigurations: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/configurations`,
        deleteApplication: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/applications`,
        deleteDevice: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/devices`,
        analyzeApp: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/application/analyzeApp`,
        uploadApp: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/application/uploadApp`,
        updateConfig: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/configurations`,
        addConfig: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/configurations`,
        fetchConfigApp: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/configurations/appReln`,
        configAppReln: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/configurations/appReln`,
        addDevice: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/devices`,
        pullLogs: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/pullLogs`,
        appVersion: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/appVersion`,
        applicationConfigReln: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/applicationConfigReln`,
        applicationVersion: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/applications/versions`,
        getTenantListMDM: `https://${global.config.ipConfig.host}:${global.config.ipConfig.cmcPort}/mtm/getAllTenantIdsForPartnerId`,
        getDeviceIdList: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/deviceList`,
        getDeviceIdListId: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/deviceListByUeType`,
        updateDeviceByDeviceId: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/updateDevice`,
        updateDeviceByUser: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/updateDeviceByUser`,
        getDeviceId: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/deviceByUserId`,
        getDeviceInventory: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/checkInventory`,
        allocateDevice: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/confirmDeviceOrder`,
        getConfigDevice: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/configurationByDevice`,
        deviceDashboard: `https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/deviceDashboard`,
        getDeviceinfo:`https://${global.config.ipConfig.risHost}:${global.config.ipConfig.defaultPort}/mdm/getDeviceInfo`,
        // Master UE config
        masterUeTypeConfig: `https://${global.config.ipConfig.host}:${global.config.ipConfig.cmcPort}/mtm/getMasterUeTypeMapping`
      };
    },
  };
  exports.EndPoints = EndPoints;
})(typeof exports === "undefined" ? (this["EndPoints"] = {}) : exports);
