(function (exports) {
  const JsSIP = require("../jssip/JsSIP");
  const OutgoingRequest = require("../sipMethods/OutgoingRequest")
    .OutgoingRequest;
  const RequestSender = require("../jssip/RequestSender");
  const Transactions = require("../jssip/Transactions");
  const Events = require("events").EventEmitter;
  const DOMParser = require("xmldom").DOMParser;
  const GetRegStatus = require("./getRegData").GetRegStatus;
  const stateType = require("../StateType");

  class FASubscriber extends Events {
    constructor(UA) {
      super();
      this.UA = UA;

      this.GetRegStatus = new GetRegStatus(UA);
      this.contact = this.UA._registrator._contact;
      this.ruri = this.UA._registrator._registrar;
      this.expires = "4294967295";
      this.userRegStatusUrl = null;
      this.parser = new DOMParser();

      this.subscriptions = new Map();
      this.dialogStateMap = new Map([
        ["terminated", "AVAILABLE"],
        ["early", "RINGING"],
        ["confirmed", "BUSY"],
        ["trying", "BUSY"],
      ]);
    }

    subscribe() {
      console.log("FAMC subscribe()");
      this.UA.updateRegistrationProcess("PRESENCE", stateType.PRESENCE_STATE.Subscribing);
      const extraHeaders = [
        `Contact: ${this.contact}`,
        `Expires: ${this.expires}`,
        "Event: presence",
        "content-type: application/vnd.3gpp.mcptt-info+xml",
        `Accept: application/pidf+xml`,
        `P-Preferred-Service: urn:urn-7:3gpp-service.ims.icsi.mcptt`,
        `P-Preferred-Identity: <${this.UA._registrator._from_uri}>`,
      ];
      const fromURI = this.UA._registrator._from_uri;
      const body = `${this.getSubscribeBody()}`;

      const options = {
        to_uri: this.ruri,
        from_uri: fromURI,
        call_id: JsSIP.Utils.createRandomToken(22),
        cseq: 1,
        from_tag: "",
      };

      const request = new OutgoingRequest(
        JsSIP.C.SUBSCRIBE,
        this.ruri,
        this.UA,
        options,
        extraHeaders,
        body
      );
      //console.log("FAMC subscribe req", request);
      const eventHandlers = {
        onReceiveResponse: (response) => {
          console.log("FASubscribe Response :");
          if (response && response.status_code >= 400) {
            console.log("FASubscribe Response : ", response.status_code);
            this.UA.updateRegistrationProcess("PRESENCE", stateType.PRESENCE_STATE.None, {
              cause: JsSIP.C.causes.SIP_FAILURE_CODE,
              response: response.status_code
            });
          } else {
            this.UA.updateRegistrationProcess("PRESENCE", stateType.PRESENCE_STATE.Subscribed);
          }
        },
        onRequestTimeout: () => {
          console.log("FASubscribe onRequestTimeout");
          this.UA.updateRegistrationProcess("PRESENCE", stateType.PRESENCE_STATE.None, {
            cause: JsSIP.C.causes.REQUEST_TIMEOUT,
          });
        },
        onTransportError: () => {
          console.log("FASubscribe onTransportError");
          this.UA.updateRegistrationProcess("PRESENCE", stateType.PRESENCE_STATE.None, {
            cause: JsSIP.C.causes.CONNECTION_ERROR,
          });
        },
      };

      const requestSender = new RequestSender(this.UA, request, eventHandlers);
      requestSender.send();
    }

    getSubscribeBody() {
      let mcpttUrii = this.UA.mcptt_uri.includes("sip")
        ? `${this.UA.mcptt_uri}`
        : `sip:${this.UA.mcptt_uri}`;
      return `<mcpttinfo xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="urn:3gpp:ns:mcpttInfo:1.0">
			<mcptt-Params>
			  <mcptt-request-uri type="Normal">
				<mcpttURI>${mcpttUrii}</mcpttURI>
			  </mcptt-request-uri>
			</mcptt-Params>
		  </mcpttinfo>`;
    }

    handleNotify(request, globalContactList) {
      //console.log("Handling FAMC Subsriptions notification", globalContactList);
      if (Transactions.checkTransaction(this.UA, request)) {
        request.reply(200);
        return;
      }

      new Transactions.NonInviteServerTransaction(
        this.UA,
        this.UA.transport,
        request
      );

      try {
        const subscriptionStateHeader =
          request.headers["Subscription-State"][0].raw;
        if (subscriptionStateHeader) {
          const subscriptionState = subscriptionStateHeader.split(";")[0];

          this.updateSubscriptionState(request);

          if (subscriptionState === "terminated") {
            request.reply(200);
            return;
          }
        }
      } catch (err) {
        // console.debug('err: ', err);
      }

      if (!request.body) {
        request.reply(200);
        return;
      }
      const _this = this;
      if (request.body.includes("reg-status")) {
        try {
          const parsedUserXml = this.parser.parseFromString(request.body);
          const userElements = parsedUserXml
            .getElementsByTagName("reg-status")
            .item(0).textContent;
          this.GetRegStatus.contactList = globalContactList;
          this.GetRegStatus.regStatusData(userElements).then((isregStat) => {
            if (isregStat) {
              _this.emit("USER_REG_STATUS", isregStat);
            } else {
              _this.userRegStatusUrl = userElements;
            }
          });
          this.UA.updateRegistrationProcess("PRESENCE", stateType.PRESENCE_STATE.Notified);
          request.reply(200);
          return;
        } catch (error) {
          console.log("Error reg-status parsing xml: ", error);
          request.reply(200);
          return;
        }
      }

      if (request.body.includes("group")) {
        this.sendGroupAffiliationDeaffiliationEvent(request.body);
        this.UA.updateRegistrationProcess("PRESENCE", stateType.PRESENCE_STATE.Notified);
        request.reply(200);
        return;
      }

      try {
        // Grab the state and version from the parsed body
        //const state = parsedBody.root.children[0].children[0].content;
        //const version = parseInt(parsedBody.root.attributes.version, 10);
        // const version = 1;
        // const state = "active";

        const parsedBody = this.parser.parseFromString(request.body);
        let faList = [];
        const faElements = parsedBody.getElementsByTagName(
          "mcpttPIFA10:functionalAlias"
        );

        //console.log("Functional aliases received : ", parsedBody);
        //console.log("Functional aliases received2 : ", faElements);
        //const pid = parsedBody.getElementsByTagName("mcpttPIFA10:p-id-fa");
        //console.log("Functional aliases received2 : pid", pid.$$length);
        for (let i = 0; i < faElements.length; i++) {
          faList.push({
            name: faElements[i].getAttribute("functionalAliasID"),
            status: faElements[i].getAttribute("status"),
            expires: faElements[i].getAttribute("expires"),
          });
        }
        console.log("Functional aliases faList : ", faList);
        this.UA.updateRegistrationProcess("PRESENCE", stateType.PRESENCE_STATE.Notified);
        this.emit("FA_ACTIVATION_STATUS", faList);
        request.reply(200);
      } catch (err) {
        console.debug("Error parsing xml: ", request);
        request.reply(200);
      }
    }

    updateSubscriptionState(request) {
      if (!request.headers["Subscription-State"]) return;

      const subscriptionStateHeader =
        request.headers["Subscription-State"][0].raw;
      const splitHeaders = subscriptionStateHeader.split(";");

      const state = splitHeaders[0];
      const secToExpire = splitHeaders
        .find((x) => x.includes("expires"))
        .split("=")[1];

      const msToExpire = secToExpire * 1000;

      const subscription = this.getSubscription(request);
      if (subscription) {
        clearTimeout(subscription.expireTimeout);

        this.subscriptions.set(request.call_id, {
          ...subscription,
          subscriptionState: state,
          expires:
            secToExpire === undefined
              ? subscription.expires
              : Date.now() + msToExpire,
          expireTimeout: setTimeout(() => {
            this.expireSubscription(request);
          }, msToExpire),
        });
      }
    }

    sendGroupAffiliationDeaffiliationEvent(data) {
      let parsedXml = this.parser.parseFromString(data);
      let groupList = [];
      //let groupListDeaffiliate = [];
      const groupElements = parsedXml.getElementsByTagName("affiliation");
      //console.log("Emitting groups affiliated/deaffiliate :", data);
      for (let i = 0; i < groupElements.length; i++) {
        //console.log("Functional aliases received : ", groupElements[i]);
        if (groupElements[i].getAttribute("status") === "affiliated") {
          groupList.push(groupElements[i].getAttribute("group"));
        }

        // if (groupElements[i].getAttribute("status") === "deaffiliated") {
        //   groupListDeaffiliate.push(groupElements[i].getAttribute("group"));
        // }
      }
      this.UA.updateRegistrationProcess("PRESENCE", stateType.PRESENCE_STATE.Notified);
      this.emit("GROUPS_AFFILIATED", groupList);
      //this.emit("GROUPS_DEAFFILIATED", groupListDeaffiliate);

    }

    getSubscription(message) {
      return this.subscriptions.get(message.call_id);
    }

    expireSubscription(response) {
      const callId = response.call_id;
      const subscription = this.getSubscription(response);
      if (subscription) {
        this.subscriptions.set(callId, {
          ...subscription,
          subscriptionState: "expired",
        });
      }
    }

    updateVersion(request, version) {
      const subscription = this.getSubscription(request);
      if (subscription) {
        this.subscriptions.set(request.call_id, {
          ...subscription,
          version,
        });
      }
    }

    emitUserStateChanged(user, state) {
      const normalizedState = state.toLowerCase();

      // Get a more readable state
      const readableState =
        this.dialogStateMap.get(normalizedState) || "UNKNOWN";

      this.emit("userStateChanged", user, readableState);
    }

    mergeRegstatusWithContact(globalContactList) {
      console.log("mergeRegstatusWithContact Subscriber()");
      this.GetRegStatus.contactList = globalContactList;
      let res = this.GetRegStatus.mergeRegstatusData();
      if (res) {
        this.emit("USER_REG_STATUS", res);
      } else {
        if (this.userRegStatusUrl) {
          console.log("mergeRegstatusWithContact userRegStatusUrl()");
          this.GetRegStatus.regStatusData(this.userRegStatusUrl).then((isregStat) => {
            if (isregStat) {
              this.emit("USER_REG_STATUS", isregStat);
            }
          });
        }
      }
    }
  }
  
  exports.FASubscriber = FASubscriber;
}) (typeof exports === "undefined" ? (this["FASubscriber"] = {}) : exports);
