/*module.exports = global.config = {
    trainConfig: {
        minlteCount: 0,
        maxlteCount: 100,
        lteSize: 5, //LTE size
        lteGap: 5,  //LTE gap between 2 LTE in same trackcircuit
        singleLTE: 15,  // lteGap + lteSize + lteGap
        trackCtGap: 2,  //Gap between track circuit
        startGap: 80,  //initial gap before LTE plotting starts
        endGap: 80,  //end gap after LTE plotting ends
    },
    isTrainConfigSet: false,
};*/