
import TitleTab from "../Admin/components/TitleTab";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { fetchProductCatalog, fetchOrderList } from "../modules/productcatalogstate";
import { fetchDeviceTypeList } from "../modules/actions/mdmAction";
import AddProduct from "./addproduct";
import MyCatalog from "./catalogTab/mycatalog";
import MyBuyer from "./catalogTab/buyer";
import MyPurchases from "./catalogTab/mypurchases";
import MySales from "./catalogTab/mysales";
import FadeInOut from "../Admin/components/FadeInOut";

const ProductList = (props) => {
    const { productcataloglist, fetchProductCatalog, orderList, fetchOrderList, fetchDeviceTypeList } = props;
    const [filterName, setFilterName] = useState('Buy');
    const [openProduct, setOpenProduct] = useState(false);
    const [products, setProducts] = useState({});
    const [orders, setorders] = useState({});
    const [product, setProduct] = useState(null);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    })

    const useStyles = makeStyles((theme) => ({
        root: {
            //backgroundColor: theme.palette.background.paper,
            // paddingTop: 10,
            // paddingLeft: 20,
            // paddingRight: 20,
            width: "100%",
            //height: "70%",
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
        },
        productTypeClass: {
            width: "70%",
            height: 800,
            // display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            border: "solid",
            borderRadius: 10,
            borderWidth: "1px",
            borderColor: "#4B4A4A",
            marginLeft: 50,
            //marginTop: 5,
            marginBottom: 10,
            marginTop: 20,
            overflowY: "scroll",
        },
        divList: {
            width: '100%',
            marginBottom: '13px',
        },

    }));

    useEffect(() => {
        fetchDeviceTypeList();
        let ptId = getPartnerOrtenantSubTypeAndId();
        console.log("getPartnerOrtenantSubTypeAndId() ", ptId);
        let apiType = "catalog";
        if (ptId && (ptId.userType === "tn" || ptId.userType === "stn")) {
            apiType = "buy";
            setFilterName("Buy");
        } else {
            setFilterName("My Catalog");
        }
        if (ptId && ptId.pId) {
            let filterData = {
                "creatorPartnerId": ptId.pId,
                "apiType": apiType,
                "paginationModel": paginationModel
            }
            if (apiType === "buy" && (ptId.userType === "tn" || ptId.userType === "stn")) {
                let filter = {
                    "productSubType": "Partner",
                    "productType": "MobileDevice",
                    "filterType": "OR"
                }
                filterData = { ...filterData, filter };
            } else if(apiType === "buy") {
                let filter = {
                    "productSubType": "Operator",
                    "productType": "MobileDevice",
                    "filterType": "OR"
                }
                filterData = { ...filterData, filter };
            }
            fetchProductCatalog(filterData);
        }
    }, [])

    useEffect(() => {
        console.log("got productcataloglist.. ", productcataloglist);
        if (productcataloglist && productcataloglist.data) {
            let ptId = getPartnerOrtenantSubTypeAndId("buy");
            console.log("got ptId.. ", ptId);
            if (ptId && (ptId.userType === "tn" || ptId.userType === "stn")) {
                let filterProduct = productcataloglist.data.filter(prd => prd.productSubType === "Partner" || prd.productType === "MobileDevice");
                console.log("filterProduct", filterProduct);
                let productcatalog = { ...productcataloglist, data: filterProduct };
                setProducts(productcatalog);
            } else {
                setProducts(productcataloglist);
            }
        }
    }, [productcataloglist])

    useEffect(() => {
        console.log("got orderlist.. ", orderList);
        if (orderList && orderList.data) {
            setorders(orderList);
        }
    }, [orderList])

    const getPartnerOrtenantSubTypeAndId = (tab = null) => {
        let pId = null;
        let userType = "spr";
        if (global.config.userConfig.parentId) {
            pId = global.config.userConfig.tenantId;
            if (pId) {
                if (global.config.userConfig.systemTenant) {
                    userType = "stn";
                } else {
                    userType = "tn";
                }
                if (tab && tab === "purchase") {
                    pId = global.config.userConfig.tenantId;
                } else {
                    pId = global.config.userConfig.parentId;
                }
            } else {
                pId = global.config.userConfig.partnerId;
                userType = "pr";
                if (tab && tab === "buy") {
                    pId = global.config.userConfig.parentId;
                } else {
                    pId = global.config.userConfig.partnerId;
                }
            }
        } else {
            if (global.config.userConfig.systemPartner) {
                pId = global.config.userConfig.partnerId;
            }
        }
        return {
            "pId": pId,
            "userType": userType
        };
    }

    const filterData = (type) => {
        setProduct(null);
        if (type === "add") {
            setFilterName("Create Product");
            setOpenProduct(true);
            return;
        }
        setOpenProduct(false);
        let ptId = getPartnerOrtenantSubTypeAndId(type);
        console.log("getPartnerOrtenantSubTypeAndId() filter ", ptId);
        if (type === "catalog") {
            setFilterName("My Catalog");
            if (ptId && ptId.pId) {
                const filterData = {
                    "creatorPartnerId": ptId.pId,
                    "apiType": type,
                    "paginationModel": paginationModel
                }
                fetchProductCatalog(filterData);
            }
        } else if (type === "sale") {
            setFilterName("My Sales");
            if (ptId && ptId.pId) {
                const filterData = {
                    "sellerMTMId": ptId.pId,
                    "apiType": type,
                    "paginationModel": paginationModel
                }
                fetchOrderList(filterData);
            }
        } else if (type === "buy") {
            setFilterName("Buy");
            if (ptId && ptId.pId) {
                let filterData = {
                    "creatorPartnerId": ptId.pId,
                    "apiType": type,
                    "paginationModel": paginationModel
                }
                if (ptId.userType === "tn" || ptId.userType === "stn") {
                    let filter = {
                        "productSubType": "Partner",
                        "productType": "MobileDevice",
                        "filterType": "OR"
                    }
                    filterData = { ...filterData, filter }
                } else {
                    let filter = {
                        "productSubType": "Operator",
                        "productType": "MobileDevice",
                        "filterType": "OR"
                    }
                    filterData = { ...filterData, filter };
                }
                fetchProductCatalog(filterData);
            }
        } else if (type === "purchase") {
            setFilterName("My Purchases");
            if (ptId && ptId.pId) {
                const filterData = {
                    "buyerMTMId": ptId.pId,
                    "apiType": type,
                    "paginationModel": paginationModel
                }
                fetchOrderList(filterData);
            }
        }

    }

    const refreshSalesAfterConfirmingOrder = () => {
        setTimeout(() => {
            const type = "sale";
            let ptId = getPartnerOrtenantSubTypeAndId(type);
            if (ptId && ptId.pId) {
                const filterData = {
                    "sellerMTMId": ptId.pId,
                    "apiType": type,
                    "paginationModel": paginationModel
                }
                fetchOrderList(filterData);
            }
        }, 900);
    }

    const closeAfterCallApi = () => {
        setTimeout(() => {
            let ptId = getPartnerOrtenantSubTypeAndId();
            let apiType = "catalog";
            if (filterName === "Buy") {
                apiType = "buy";
            } if (filterName === "My Sales") {
                apiType = "sale";
            }
            if (ptId.pId) {
                let filterData = {
                    "creatorPartnerId": ptId.pId,
                    "apiType": apiType,
                    "paginationModel": paginationModel
                }
                if (apiType === "buy" && (ptId.userType === "tn" || ptId.userType === "stn")) {
                    let filter = {
                        "productSubType": "Partner",
                        "productType": "MobileDevice",
                        "filterType": "OR"
                    }
                    filterData = { ...filterData, filter };
                } else if(apiType === "buy") {
                    let filter = {
                        "productSubType": "Operator",
                        "productType": "MobileDevice",
                        "filterType": "OR"
                    }
                    filterData = { ...filterData, filter };
                }
                fetchProductCatalog(filterData);
            }
        }, 1000);
        setOpenProduct(false);
        setProduct(null);
        setFilterName("My Catalog");
    }

    const catalogCallBack = (productData, isCopy = false) => {
        console.log("catalogCallBack ", productData);
        if (isCopy) {
            const editProductData = { ...productData, openMode: "buy" }
            setProduct(editProductData);
        } else {
            const editProductData = { ...productData, openMode: "edit" }
            setProduct(editProductData);
        }
        setOpenProduct(true);
    }

    const openProductDetailPage = (productData) => {
        const editProductData = { ...productData, openMode: "view" }
        setProduct(editProductData);
        setOpenProduct(true);
    }

    const classes = useStyles();

    const paginationFunc = (data, type) => {
        setPaginationModel(data)
        let ptId = getPartnerOrtenantSubTypeAndId(type);
        if (ptId && ptId.pId) {
            if (type == "sale") {
                const filterData = {
                    "sellerMTMId": ptId.pId,
                    "apiType": type,
                    "paginationModel": data
                }
                fetchOrderList(filterData);
            } else if (type == "catalog") {
                const filterData = {
                    "creatorPartnerId": ptId.pId,
                    "apiType": type,
                    "paginationModel": data
                }
                fetchProductCatalog(filterData);
            } else if (type == "buy") {
                let filterData = {
                    "creatorPartnerId": ptId.pId,
                    "apiType": type,
                    "paginationModel": data
                }
                if (ptId.userType === "tn" || ptId.userType === "stn") {
                    let filter = {
                        "productSubType": "Partner",
                        "productType": "MobileDevice",
                        "filterType": "OR"
                    }
                    filterData = { ...filterData, filter };
                } else {
                    let filter = {
                        "productSubType": "Operator",
                        "productType": "MobileDevice",
                        "filterType": "OR"
                    }
                    filterData = { ...filterData, filter };
                }
                fetchProductCatalog(filterData);
            } else if (type == "purchase") {
                const filterData = {
                    "buyerMTMId": ptId.pId,
                    "apiType": type,
                    "paginationModel": data
                }
                fetchOrderList(filterData);
            }
        }

    }

    return (
        <div className={classes.root}>
            <TitleTab
                title={filterName}
                type={getPartnerOrtenantSubTypeAndId().userType}
                search={(txt) => {
                    //console.log("search text", txt);
                    //searchedData(txt)
                }}
                filtr={(x) => {
                    console.log("PM Clicked", x);
                    filterData(x)
                }}
            />
            {
                openProduct ?
                    <div className={classes.productTypeClass}>
                        <FadeInOut show={openProduct} duration={500}>
                            {
                                product ?
                                    <AddProduct productCatalog={product} closeAfterCallApi={closeAfterCallApi}></AddProduct>
                                    :
                                    <AddProduct closeAfterCallApi={closeAfterCallApi}></AddProduct>
                            }
                        </FadeInOut>
                    </div>
                    :
                    filterName === "My Catalog" ?
                        <MyCatalog products={products} catalogCallBack={catalogCallBack} closeAfterCallApi={closeAfterCallApi} paginationFunc={paginationFunc} ></MyCatalog>
                        : filterName === "Buy" ?
                            <MyBuyer products={products} openProductDetailPage={openProductDetailPage} paginationFunc={paginationFunc}></MyBuyer>
                            : filterName === "My Sales" ?
                                <MySales orders={orders} refreshSalesAfterConfirmingOrder={refreshSalesAfterConfirmingOrder} paginationFunc={paginationFunc}></MySales>
                                : filterName === "My Purchases" ?
                                    <MyPurchases orders={orders} paginationFunc={paginationFunc}></MyPurchases>
                                    : null
            }
        </div>
    )
}

const mapStateToProps = ({ productcatalogstate }) => {
    const { productcataloglist, orderList } = productcatalogstate;
    return {
        productcataloglist,
        orderList,
    };
};

export default connect(mapStateToProps, {
    fetchProductCatalog,
    fetchOrderList,
    fetchDeviceTypeList,
})(ProductList);
