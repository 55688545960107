import React from "react";
import { Modal } from 'react-bootstrap'
import { connect } from "react-redux";
import AddApp from "./Modal/AddApp";
import AddConfig from "./Modal/AddConfig";
import AddDevice from "./Modal/AddDevice";
import AddDeviceType from "./Modal/AddDeviceType";

const CustomModal = React.memo((props) => {
    const { handleClose, isModalOpen, currentTab, setModalOpen } = props;
    return (
        <Modal
            show={isModalOpen}
            onHide={handleClose}
            scrollable={true}
            size={"lg"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ backgroundColor: " rgba(0,0,0,0.5)" }}
        >
            <Modal.Header
                closeButton
                style={{ border: "0px", backgroundColor: "#282828" }}
            >
                <Modal.Title>Add {currentTab}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: "0.2px", backgroundColor: '#252524' }}>
                {currentTab == "Application" ?
                    <AddApp setModalOpen={setModalOpen} />
                    : currentTab == "Configuration" ?
                    <AddConfig setModalOpen={setModalOpen} purpose={"add"}/>
                    : currentTab == "Device" ?
                    <AddDevice setModalOpen={setModalOpen}/>
                    : currentTab == "Device Type" ?
                    <AddDeviceType setModalOpen={setModalOpen}/>
                    : null
                }
            </Modal.Body>
        </Modal>
    );
});

const mapStateToProps = ({ mdm }) => {
    return {
        currentTab: mdm.currentTab
    };
};

export default connect(mapStateToProps, {})(CustomModal);