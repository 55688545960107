import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tooltip, OverlayTrigger, Modal } from "react-bootstrap";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { setTenantId, fetchAppList, fetchDeviceList, fetchConfigList, setSearchedText } from "../../modules/actions/mdmAction";
import QRCode from "react-qr-code";
import AllocateDevice from "./Modal/AllocateDevice";

//Other files


const propTypes = {
  propData: PropTypes.array,
};


const SearchCollapse = (props) => {
  const [seen1, setSeen1] = useState(true);
  const [seen2, setSeen2] = useState(false);
  const [showAllocateDevices, setShowAllocateDevices] = useState(false)
  const [enableScreenInAllocateDevice, setEnableScreenInAllocateDevice] = useState(false)
  const { indvGrp, deviceMngr, currentTab, tenantList, setTenantId, fetchAppList, fetchDeviceList, fetchConfigList, tenantId, setSearchedText } = props;
  const collapse = () => {
    setSeen1(!seen1);
    setSeen2(!seen2);
    setSearchedText("")
  };

  const searchEntered = (text) => {
    setSearchedText(text)
  };

  const showAllocateDevicesHandler = () => {
    setShowAllocateDevices(true)
  }

  useEffect(() => {
    setTenantId(tenantList && tenantList.length > 0 && tenantList[0].value)

  }, [])

  const handleCloseAllocateDeviceModal = () => {
    setShowAllocateDevices(false)
    setEnableScreenInAllocateDevice(false)
  }

  const useStyles = makeStyles((theme) => ({
    formControlIwfType: {
      width: "100%",
      height: "10px",
    },
    iwfInfoList: {
      height: '30px',
      padding: "0px 5px 17.5px 0px",
      fontFamily: "Muli",
      color: '#000'
    },
  }));
  const classes = useStyles();

  const handleSelectedBtn = (e) => {
    setTenantId(e.target.value)
    fetchConfigList({ tenantId: e.target.value })
    fetchDeviceList({ tenantId: e.target.value,mtmId:global.config.userConfig.partnerId })
    fetchAppList({ tenantId: e.target.value })
  }
  
  useEffect(() => () => {
    setTenantId()
  }, [])

  return (
    <>
      <div class="flt-l">
        {seen1 ? (
          <div>
            {deviceMngr ? (
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                {(currentTab == "Device" && !(global.config.isEcommerce))   &&
                  <div onClick={showAllocateDevicesHandler} style={{ backgroundColor: '#f19c02', width: '180px', height: '35px', borderRadius: '5px', marginTop: '10px', marginRight: '20px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <text class="attribute-heading" style={{ color: '#000' }}>Allocate Devices</text>
                  </div>}
                {(currentTab === "Configuration" || currentTab == "Device" )?
                <div class="form-group" style={{ padding: "10px 0px 0px 0px", flexDirection: 'row', justifyContent: 'space-between', display: 'flex', width: '270px' }}>
                <label class="attribute-heading" style={{ paddingTop: '10px', color: '#fff' }}>Tenant Id</label>
                <div style={{ width: "180px", backgroundColor: '#383736' }}>
                  <FormControl
                    variant="filled"
                    className={classes.formControlIwfType}
                  >
                    <Select
                      className={classes.iwfInfoList}
                      id="ueType-select-filled"
                      value={tenantId}
                      onChange={handleSelectedBtn}
                      defaultValue={tenantId}
                      disableUnderline={true}
                    >
                      {
                        (tenantList &&
                          tenantList.map((id) => {
                            return (
                              <MenuItem value={id.value}>{id.name}</MenuItem>
                            );
                          })
                        )
                      }

                    </Select>
                  </FormControl>
                </div>
              </div>:""}
                <FilterButton
                  imgName={"addP_icon"}
                  filtr={indvGrp}
                  info={`Add ${currentTab}`}
                  type={"add"}
                />
                <FilterButton
                  iconclass={"feather icon-smartphone f-25"}
                  filtr={indvGrp}
                  info={"Devices"}
                  type={"Device"}
                />
                <FilterButton
                  iconclass={"feather icon-settings f-25"}
                  filtr={indvGrp}
                  info={"Configurations"}
                  type={"Configuration"}
                />
                {global.config.userConfig.systemPartner &&
                  <FilterButton
                    iconclass={"feather icon-airplay f-25"}
                    filtr={indvGrp}
                    info={"Applications"}
                    type={"Application"}
                  />}
                {global.config.userConfig.systemPartner &&
                  <FilterButton
                    iconclass={"feather icon-airplay f-25"}
                    filtr={indvGrp}
                    info={"Device Type"}
                    type={"Device Type"}
                  />}
                <button
                  class="sq-icon-btn in-blc m-r-5 wx32"
                  onClick={collapse}
                  type="button"
                  id=""
                >
                  <i class="feather icon-search"> </i>
                </button>
              </div>
            ) : null}
          </div>
        ) : null}
        {seen2 ? (
          <div class="input-group in-blc">
            <input
              type="text"
              class="textinput searchinput w80 in-blc"
              autoFocus
              placeholder="Search"
              aria-label="Search"
              aria-describedby="button-addon2"
              onChange={(e) => searchEntered(e.target.value)}
            />
            <button
              class="btnsearch in-blc"
              onClick={collapse}
              type="submit"
              id="button-addon2"
            >
              <i class="feather icon-chevron-right"> </i>
            </button>
          </div>
        ) : null}
      </div>
      <Modal
        show={showAllocateDevices}
        onHide={handleCloseAllocateDeviceModal}
        size={"lg"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ backgroundColor: ' rgba(0,0,0,0.5)' }}
      >
        <Modal.Header closeButton style={{ border: '0px', backgroundColor: '#282828' }}>
          <Modal.Title>ALLOCATE DEVICES</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '0.2px', backgroundColor: '#252524' }}>
          <AllocateDevice updateEnableScreen={enableScreenInAllocateDevice} closeModal={handleCloseAllocateDeviceModal} />
        </Modal.Body>
      </Modal>
    </>
  );
};

const FilterButton = (props) => {
  const { info, filtr, btnClass, iconclass, type, active, imgName } = props;
  const filtrType =
    info === "TETRA" ? "tetra" : info === "Individual" ? "indv" : "grp";
  let imgPath = type == "add" ? "/assets/images/" + imgName + ".png" : ""
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip({ info: info })}
    >
      <button
        class={
          btnClass
            ? btnClass
            : "sq-icon-btn in-blc wx32" + (active === type ? "active" : "")
        }
        onClick={() => filtr(type)}
        style={{ justifyContent: 'center' }}
      >
        {type !== "add" ? <i className={iconclass}></i>
          :
          <img className="imgfilterButton" src={`${imgPath}`} style={{ width: '20px' }} />
        }
      </button>
    </OverlayTrigger>
  );
};
const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    {props.info}
  </Tooltip>
);

const mapStateToProps = ({ mdm }) => {
  return {
    currentTab: mdm.currentTab,
    tenantList: mdm.tenantList,
    tenantId: mdm.tenantId
  };
};
SearchCollapse.propTypes = propTypes;

export default connect(mapStateToProps, { setTenantId, fetchAppList, fetchConfigList, fetchDeviceList, setSearchedText })(SearchCollapse);
