import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import TitleTab from "./TitleTab";
import DeviceList from "./DeviceList";
import "./style.css"
import { setCurrentTab, getTenantList,fetchAppList, fetchDeviceTypeList,fetchConfigList, setSearchedText, setFileteredData } from "../../modules/actions/mdmAction";
import AppList from "./AppList";
import ConfigList from "./ConfigList"
import CustomModal from "./CustomModal";
import DeviceTypeList from "./DeviceTypeList";

const DeviceIndex = (props) => {
    const [isModalOpen, setModalOpen] = useState(false);

    const { setCurrentTab,fetchConfigList, currentTab,tenantId,fetchAppList, getTenantList, fetchDeviceTypeList, searchedText, setFileteredData, deviceList, configList, appList, deviceTypeList } = props;
    const filterData = (tab) => {
        setModalOpen(true)
    }
    const handleClose = () => {
        setModalOpen(false)
    }
    useEffect(() => {
        fetchDeviceTypeList()
        fetchConfigList({ tenantId })
        fetchAppList()
    }, [])
    useEffect(() => () => {
        setCurrentTab("Device")
    }, [])
    useEffect(() => {
        if (searchedText) {
            if (currentTab === "Device") {
                let filteredData = deviceList.filter((device) => {
                    return device.deviceId.toLowerCase().search(searchedText.toLowerCase()) !== -1
                })
                setFileteredData(filteredData)
            }
            else if (currentTab === "Configuration") {
                let filteredData = configList.filter((config) => {
                    return config.name.toLowerCase().search(searchedText.toLowerCase()) !== -1
                })
                setFileteredData(filteredData)
            }
            else if (currentTab === "Application") {
                let filteredData = appList.filter((app) => {
                    return app.name.toLowerCase().search(searchedText.toLowerCase()) !== -1
                })
                setFileteredData(filteredData)
            }
            else if (currentTab === "Device Type") {
                let filteredData = deviceTypeList.filter((deviceType) => {
                    return (deviceType.brand.toLowerCase().search(searchedText.toLowerCase()) !== -1) || (deviceType.manufacturer.toLowerCase().search(searchedText.toLowerCase()) !== -1)
                })
                setFileteredData(filteredData)
            }
        } else {
            if (currentTab === "Device") {
                setFileteredData([...deviceList])
            }
            else if (currentTab === "Configuration") {
                setFileteredData([...configList])
            }
            else if (currentTab === "Application") {
                setFileteredData([...appList])
            }
            else if (currentTab === "Device Type") {
                setFileteredData([...deviceTypeList])
            }
        }
    }, [searchedText, deviceList, configList, appList, deviceTypeList, currentTab])
    return (
        <div className="wrap-1 l1">
            <TitleTab
                title={`${currentTab} List`}
                type={"deviceManager"}
                filtr={(x) => {
                    if (x == "add") {
                        filterData(x)
                    } else {
                        setCurrentTab(x)
                    }
                }}
            />
            <div class="tab-content" id="pills-tabContent">
                {currentTab == "Device" ?
                    <DeviceList />
                    : currentTab == "Application" ?
                        <AppList />
                        : currentTab == "Configuration" ?
                            <ConfigList />
                            : currentTab == "Device Type" ?
                                <DeviceTypeList />
                                : null}
            </div>
            <CustomModal isModalOpen={isModalOpen} handleClose={handleClose} setModalOpen={setModalOpen} />
        </div>
    )
}
const mapStateToProps = ({ mdm, auth }) => {
    return {
        currentTab: mdm.currentTab,
        searchedText: mdm.searchedText,
        deviceList: mdm.deviceList,
        configList: mdm.configList,
        appList: mdm.appList,
        deviceTypeList: mdm.deviceTypeList,
        tenantId: mdm.tenantId
    }
}
export default connect(mapStateToProps, { setCurrentTab,fetchAppList, fetchConfigList,getTenantList, fetchDeviceTypeList, setSearchedText, setFileteredData })(DeviceIndex)