import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, ThemeProvider } from "@material-ui/core/styles";
import { Typography, FormControl, InputLabel, Select, MenuItem, Box, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { fetchDeviceList, addDevice, updateDevice } from "../../../modules/actions/mdmAction";
import { config } from "process";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const AddUser = (props) => {
    const { configList, setModalOpen, data, purpose, addDevice, updateDevice, deviceTypeList } = props
    const [deviceData, setDeviceData] = useState({ ...data })
    const [configOption, setConfigOption] = useState([])
    const [formErrors, setFormErrors] = useState({
        deviceId: false,
        cmsUri: false,
        deviceType: false,
        configType: false,
    });
    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: "#252524",
            //backgroundColor: theme.palette.background.default,
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            width: "100%",
            //height: "70%",
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
        },
        formControlFA: {
            width: "94%",
            height: "65px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600,
            backgroundColor: "#383736",
            borderRadius: 4,
            border: 2,
            borderColor: "#383736"
        },
        listItemFA: {
            padding: "5px",
            //marginBottom: "20px",
            //marginLeft: "12px",
            //height: "65px",
            fontFamily: "Muli",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600,
        },
        lblFA: {
            // marginBottom: "10px",
            height: "55px",
            marginLeft: "15px",
            fontFamily: "Muli",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600,
            backgroundColor: "#383736"
        },
        productTypeClass: {
            width: "90%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            border: "solid",
            borderRadius: 10,
            borderWidth: "2px",
            borderColor: "#4B4A4A",
            marginTop: 5,
            marginBottom: 10
        },
        divList: {
            width: '100%',
            marginBottom: '13px',
        },
        lblStyle: {
            width: '100%',
            color: "#D0D3D4",
            fontSize: 18,
            fontWeight: 600
        },
        tetraSwitch: {
            fontFamily: "Muli",
            marginTop: "2px",
            marginRight: "1px",
            marginLeft: "0px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        datePickerStyle: {
            color: "black",
            padding: "10px",
            fontFamily: "Sans-Serif",
            width: '60%',
            border: "solid",
            borderRadius: 20,
            borderWidth: "2px",
            borderColor: "#D3D3D3",
        }
    }));
    const classes = useStyles();
    const theme = useTheme();
    const onSelectHandler = (e) => {
        let data = { ...deviceData }
        data[e.target.name] = e.target.value
        setDeviceData({ ...data })
        if (e.target.name == "deviceId") {
            e.target.value.trim() ? setFormErrors({ ...formErrors, deviceId: false }) : setFormErrors({ ...formErrors, deviceId: true });
        }
        if (e.target.name == 'cmsURI') {
            e.target.value.trim() ? setFormErrors({ ...formErrors, cmsUri: false }) : setFormErrors({ ...formErrors, cmsUri: true });

        }
        if (e.target.name == "deviceType") {
            setFormErrors({ ...formErrors, deviceType: false })
            const filteredList = configList.filter(item => item.deviceType === e.target.value)
            let option = filteredList.map(r => {
                return { text: r.name, value: r.id }
            })
            setConfigOption([...option])
        }

        if (e.target.name == "configurationId" && e.target.value) setFormErrors({ ...formErrors, configType: false });
    }

    const submitButtonHandler = () => {
        const requiredFields = ['configurationId', 'deviceType', 'cmsURI', 'deviceId'];
        let err = false;
        let errors = {
            deviceId: false,
            cmsUri: false,
            deviceType: false,
            configType: false,
        };
        for (let i = 0; i < 4; i++) {
            let key1 = requiredFields[i];
            if (key1 === 'cmsURI' && (!deviceData[key1] || deviceData[key1].trim() === '')) {
                err = true;
                errors.cmsUri = true;
            }
            if (key1 === 'deviceId' && (!deviceData[key1] || deviceData[key1].trim() === '')) {
                err = true;
                errors.deviceId = true;
            }
            if (key1 === 'configurationId' && !deviceData[key1]) {
                err = true;
                errors.configType = true;
            }
            if (key1 === 'deviceType' && !deviceData[key1]) {
                err = true;
                errors.deviceType = true;
            }
        }
        if (err) {
            setFormErrors(errors);
            return;
        }
        purpose === "edit" ?
            updateDevice(deviceData)
            : addDevice(deviceData)
        setModalOpen(false)
    }

    useEffect(() => {
        if (purpose === "edit") {
            const filteredList = configList.filter(item => item.deviceType === deviceData.deviceType)
            let option = filteredList.map(r => {
                return { text: r.name, value: r.id }
            })
            setConfigOption([...option])
        }
    }, [configList])

    return (
        <div class={classes.root}>
            <Grid container width="100%" spacing={2}>
                <Grid item xs={12}>
                    <Box className="tab1-account" width={'100%'} marginLeft={0}>
                        <Box className="form-group" paddingX={1} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={1} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className="attribute-heading" style={{ color: 'whitesmoke', width: "150px", alignItems: "center", justifyContent: "center" }}>Device Id (IMEI)*</label>
                            </Box>
                            <Box width={'60%'} mr="0.5em">
                                <input
                                    type="text"
                                    className="input-control-flat"
                                    id="deviceId"
                                    name="deviceId"
                                    value={deviceData.deviceId}
                                    onChange={onSelectHandler}
                                />
                                <label className="staus-barlabel">Don't use special characters</label>
                            </Box>
                        </Box>
                        {formErrors.deviceId ?
                            <Box style={{ display: "flex", flexDirection: "row-reverse" }} className="my-2">
                                <h3 style={{ color: "red" }}>Device ID is required*</h3>
                            </Box>
                            : null}
                        <Box className="form-group" paddingX={1} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={1} mr="0.5em" marginTop={2} marginBottom={1} >
                                <label className="attribute-heading" style={{ color: 'whitesmoke', width: "150px", alignItems: "center", justifyContent: "center" }}>Description</label>
                            </Box>
                            <Box width={'60%'} mr="0.5em">
                                <textarea
                                    className="input-control-flat"
                                    id="description"
                                    name="description"
                                    value={deviceData.description}
                                    onChange={onSelectHandler}
                                    draggable="false"
                                />
                            </Box>
                        </Box>

                        <Box className="form-group" paddingX={1} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={1} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className="attribute-heading" style={{ color: 'whitesmoke', width: "150px", alignItems: "center", justifyContent: "center" }}>CMS URI*</label>
                            </Box>
                            <Box width={'60%'} mr="0.5em">
                                <input
                                    className="input-control-flat"
                                    type="text"
                                    id="cmsURI"
                                    name="cmsURI"
                                    value={deviceData.cmsURI}
                                    onChange={onSelectHandler}
                                />
                            </Box>
                        </Box>
                        {formErrors.cmsUri ?
                            <Box style={{ display: "flex", flexDirection: "row-reverse" }} className="my-2">
                                <h3 style={{ color: "red" }}>CMS URI is required*</h3>
                            </Box>
                            : null}
                        <Box class="form-group" paddingX={1} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={1} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className="attribute-heading" style={{ color: 'whitesmoke', width: "150px", alignItems: "center", justifyContent: "center" }}>Device Type*</label>
                            </Box>
                            <Box width={'60%'} mr="0.5em">
                                <FormControl
                                    variant="filled"
                                    className={classes.formControlFA}
                                >
                                    <InputLabel id="config" className={classes.listItemFA} focused={false}>
                                        Device Type</InputLabel>
                                    <Select
                                        className={classes.listItemFA}
                                        id="deviceType"
                                        name="deviceType"
                                        // labelId="demo-simple-select-filled-label"
                                        // id="demo-simple-select-filled"
                                        value={deviceData.deviceType || deviceTypeList.id}
                                        onChange={onSelectHandler}
                                        disabled={purpose == "edit"}
                                        disableUnderline={true}
                                    >
                                        {deviceTypeList &&
                                            deviceTypeList.map((uetype) => {
                                                return (
                                                    <MenuItem value={uetype.id}>{uetype.manufacturer} {uetype.brand}</MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        {formErrors.deviceType ?
                            <Box style={{ display: "flex", flexDirection: "row-reverse" }} className="my-2">
                                <h3 style={{ color: "red" }}>Device Type is required*</h3>
                            </Box>
                            : null}
                        <Box class="form-group" paddingX={1} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={1} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className="attribute-heading" style={{ color: 'whitesmoke', width: "150px", alignItems: "center", justifyContent: "center" }}>Configurations*</label>
                            </Box>
                            <Box width={'60%'} mr="0.5em">
                                <FormControl
                                    variant="filled"
                                    className={classes.formControlFA}
                                >
                                    <InputLabel id="config" className={classes.listItemFA} focused={false} >
                                        Configurations</InputLabel>
                                    <Select
                                        className={classes.listItemFA}
                                        id="configurations"
                                        name="configurationId"
                                        value={deviceData.configurationId || ""}
                                        onChange={onSelectHandler}
                                        disableUnderline={true}
                                    >
                                        {configOption &&
                                            configOption.map((uetype) => {
                                                return (
                                                    <MenuItem value={uetype.value}>{uetype.text}</MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        {formErrors.configType ?
                            <Box style={{ display: "flex", flexDirection: "row-reverse" }} className="my-2">
                                <h3 style={{ color: "red" }}>Config Type is required*</h3>
                            </Box>
                            : null}
                        <Box className="form-group" paddingX={1} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={1} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className="attribute-heading" style={{ color: 'whitesmoke', width: "150px", alignItems: "center", justifyContent: "center" }}>Phone</label>
                            </Box>
                            <Box width={'60%'} mr="0.5em">
                                <input
                                    type="text"
                                    className="input-control-flat"
                                    id="phone"
                                    name="phone"
                                    value={deviceData.phone || ""}
                                    onChange={onSelectHandler}
                                />
                            </Box>
                        </Box>
                        <Box display={"flex"} flexDirection={"row-reverse"} width={"100%"}>
                            <div className="form-group">
                                <button
                                    className="update-btn-profile"
                                    type="button"
                                    onClick={submitButtonHandler}
                                >
                                    {purpose === "edit" ? "Update" : "Save"}
                                </button>
                            </div>
                        </Box>
                    </Box>
                </Grid>
            </Grid >
        </div >
    );
};
const mapStateToProps = ({ mdm, auth }) => {
    return {
        configList: mdm.configList,
        deviceTypeList: mdm.deviceTypeList
    }
}
export default connect(mapStateToProps, { addDevice, updateDevice })(AddUser);

