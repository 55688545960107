import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { Modal } from 'react-bootstrap';
import { connect } from "react-redux";
import BuyOrder from "../buyorder";
import { createOrderForProduct } from "../../modules/productcatalogstate";

const MyBuyer = (props) => {
    const { products, createOrderForProduct, deviceTypeList, openProductDetailPage, paginationFunc } = props;
    const [openOrder, setopenOrder] = useState(false);
    const [product, setProduct] = useState(null);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    })

    useEffect(() => {
        paginationFunc(paginationModel, "buy")
    }, [paginationModel])

    const columns = [
        {
            field: 'productSku', headerName: 'ProductSku', width: 300, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "left", headerAlign: "left"
        },
        {
            field: 'productName', headerName: 'Name', width: 450, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "left", headerAlign: "left",
            valueGetter: (value, row) => `${getProductName(row)}`,
        },
        {
            field: 'productType', headerName: 'Type', width: 150, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "left", headerAlign: "left",
        },
        {
            field: 'productSubType', headerName: 'SubType', width: 200, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "left", headerAlign: "left",
            valueGetter: (value, row) => `${getProductSubTypeName(row.productType, row.productSubType)}`,
        },
        {
            field: 'productBrand',
            headerName: 'Brand',
            width: 200,
            sortable: false,
            headerClassName: "list-head-Val",
            cellClassName: "list-row-Val",
            align: "left",
            headerAlign: "left",
        },
        {
            field: 'pr',
            headerName: 'Price',
            width: 250,
            sortable: false,
            headerClassName: "list-head-Val",
            cellClassName: "list-row-Val",
            align: "left",
            headerAlign: "left",
            valueGetter: (value, row) => `${getPriceVal(row)}`,
        },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 158,
            height: 50,
            sortable: false,
            headerClassName: "list-head-Val",
            align: "left",
            headerAlign: "left",
            renderCell: (cellValue) => {
                return (
                    <button
                        className="buy-btn-profile"
                        type="button"
                        onClick={() => {
                            buyButtonClick(cellValue.row)
                        }}
                    >
                        {"BUY"}
                    </button>
                )
            }
        },

        {
            field: 'detail',
            headerName: 'Detail',
            width: 158,
            height: 50,
            sortable: false,
            headerClassName: "list-head-Val",
            align: "left",
            headerAlign: "left",
            renderCell: (cellValue) => {
                return (
                    <button
                        className="buy-btn-profile"
                        type="button"
                        onClick={() => {
                            DetailButtonClick(cellValue.row)
                        }}
                    >
                        {"Detail"}
                    </button>
                )
            }
        },
    ];

    const DetailButtonClick = (order) => {
        console.log("DetailButtonClick ", order);
        if (openProductDetailPage) {
            openProductDetailPage(order);
        }
    }

    const buyButtonClick = (order) => {
        setProduct(order);
        setopenOrder(true);
    }

    const getPriceVal = (product) => {
        let price = product.price;
        let priceUnit = product.priceUnit;
        return price + " /" + priceUnit;
    }

    const getProductName = (product) => {
        let productName = "";
        if (product && product.productJson) {
            let productJson = product.productJson;
            if (productJson && productJson.name) {
                productName = productJson.name;
            } else {
                if (product.shortDescription) {
                    productName = product.shortDescription;
                }
            }
        }
        return productName;
    }

    const getProductSubTypeName = (productType, productSubType) => {
        if (productType === "MobileDevice") {
            if (deviceTypeList && deviceTypeList.length > 0) {
                let devices = deviceTypeList.filter(device => device.id == productSubType);
                if (devices && devices.length > 0) {
                    return devices[0].brand + " " + devices[0].manufacturer
                }
            }

        }
        return productSubType;
    }

    const buyOrderData = (orderData) => {
        createOrderForProduct(orderData);
        setopenOrder(false);
        setProduct(null);
    }

    const handleUpdateProductClose = () => {
        setopenOrder(false);
        setProduct(null);
    }

    return (
        <div style={{ height: 900 }}>
            <DataGrid
                rows={products.data ? products.data : []}
                columns={columns}
                rowHeight={68}
                disableColumnMenu
                rowCount={products.totalItems}
                // hideFooter
                pagination
                localeText={{ noRowsLabel: "" }}
                pageSizeOptions={[10, 20, 25]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                paginationMode="server"
                getRowSpacing={params => ({
                    top: params.isFirstVisible ? 0 : 0,
                    bottom: params.isLastVisible ? 0 : 0
                })}

                sx={{
                    boxShadow: 2,
                    border: "none",
                    borderBottom: "none",
                    '& .MuiDataGrid-cell:hover': {
                        color: '#ffb01f',
                    },
                    '& .MuiDataGrid': {
                        border: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        borderColor: "#404040",
                    },
                    '& .MuiDataGrid-columnHeader': {
                        borderColor: "gray",
                    },
                    "& .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel": {
                        color: "white"
                    },
                    "& svg:not(:root)": {
                        color: 'white'
                    },
                    "& .css-levciy-MuiTablePagination-displayedRows": {
                        color: 'white'
                    },
                    '& .MuiDataGrid-filler': {
                        backgroundColor: "#262626",
                    }
                }}

            />
            <Modal
                show={openOrder}
                onHide={handleUpdateProductClose}
                scrollable={false}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ backgroundColor: " rgba(0,0,0,0.5)" }}
            >
                <Modal.Header
                    closeButton
                    style={{ border: "0px", backgroundColor: "#252524" }}
                >
                    <Modal.Title>Product Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "0.2px", backgroundColor: "#252524" }}>
                    <BuyOrder productCatalog={product} buyOrderData={buyOrderData}></BuyOrder>
                </Modal.Body>
            </Modal>
        </div>
    )
}
const mapStateToProps = ({ mdm }) => {
    const { deviceTypeList } = mdm;
    return {
        deviceTypeList,
    };
};

export default connect(mapStateToProps, {
    createOrderForProduct,
})(MyBuyer);