// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings {
  font-size: 13px;
  padding: 30px 40px 75px;
}
.settings .ui.label, .settings input, .settings select, .settings .text {
  font-size: 13px !important;
}
.settings .search {
  width: 275px;
}
.settings button.right {
  float: right;
  margin-bottom: 20px;
}
.settings.discreet-subscribers {
  font-size: 16px !important;
}
.settings.discreet-subscribers table {
  width: 375px;
}

.static-field {
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/styles/settings.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,uBAAA;AADF;AAEE;EACE,0BAAA;AAAJ;AAEE;EACE,YAAA;AAAJ;AAEE;EACE,YAAA;EACA,mBAAA;AAAJ;AAEE;EACE,0BAAA;AAAJ;AACI;EACE,YAAA;AACN;;AAGA;EACE,mBAAA;AAAF","sourcesContent":["@import \"theme\";\n\n.settings{\n  font-size: 13px;\n  padding: 30px 40px 75px;\n  .ui.label, input, select, .text{\n    font-size: 13px !important;\n  }\n  .search{\n    width: 275px;\n  }\n  button.right{\n    float: right;\n    margin-bottom: 20px;\n  }\n  &.discreet-subscribers{\n    font-size: 16px !important;\n    table{\n      width: 375px;\n    }\n  }\n}\n.static-field{\n  margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
