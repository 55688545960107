import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AppListRow from "./AppListRow";
import "./style.css"
import { fetchAppList } from "../../modules/actions/mdmAction";
import { makeStyles } from "@material-ui/core/styles";
import { C } from "sip.js/lib/core";
import AlertDialog from "../../Admin/components/DeleteUserDialog";
import EditApp from "./Modal/EditApp";
import FadeInOut from "../../Admin/components/FadeInOut";
import AddApp from "./Modal/AddApp";
import { DataGrid } from "@mui/x-data-grid";
import { deleteApp } from "../../modules/actions/mdmAction";
const AppList = (props) => {
    const { fetchAppList, filteredData, tenantId, deleteApp } = props;
    const [show, setShow] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [userName, setUserName] = useState('')
    const [openUserEditMode, setOpenUserEditMode] = useState(false);
    const [selectedId, setSelectedId] = useState('')
    const [selectedData, setSelectedData] = useState([])

    const useStyles = makeStyles((theme) => ({
        root: {
            //backgroundColor: theme.palette.background.paper,
            // paddingTop: 10,
            // paddingLeft: 20,
            // paddingRight: 20,
            width: "100%",
            //height: "70%",
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
        },
        deviceManagementClass: {
            width: "70%",
            height: 800,
            // display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            border: "solid",
            borderRadius: 10,
            borderWidth: "1px",
            borderColor: "#4B4A4A",
            marginLeft: 50,
            //marginTop: 5,
            marginBottom: 10,
            marginTop: 20,
            overflowY: "scroll",
        },
        divList: {
            width: '100%',
            marginBottom: '13px',
        },

    }));

    const classes = useStyles();

    const columns = [
        {
            field: 'userIcon',
            headerName: 'Icon',
            width: 119.5,
            height: 50,
            sortable: false,
            headerClassName: "list-head-Val",
            align: "center",
            headerAlign: "center",
            renderCell: (cellValue) => {
                return (
                    <div class="tr-tb-icon">
                        <i className={"feather icon-airplay f-32"} style={{ lineHeight: "50%", color: 'whitesmoke' }} />
                    </div>
                )
            }
        },
        {
            field: 'packageId', headerName: 'Package Id', width: 270, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
        },
        {
            field: 'name', headerName: 'Application Name', width: 270, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
        },
        {
            field: 'version', headerName: 'Version', width: 260, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center",
        },
        {
            field: 'runAfterInstall', headerName: 'Run After Install', width: 270, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center",
        },
        {
            field: 'runAtBoot', headerName: 'Run At Boot', width: 270, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center",
        },
        {
            field: 'brand', headerName: 'Device Type', width: 270, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center",
        },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 71,
            height: 50,
            sortable: false,
            headerClassName: "list-head-Val",
            align: "center",
            headerAlign: "center",
            renderCell: (cellValue) => {
                return (
                    <button
                        class="editBtn"
                        onClick={() => { openUserEditPage(cellValue.row) }}
                        type="button"
                        name=""
                    >
                        <img
                            src="/assets/images/editimg.svg"
                            class="edit-user-img"
                            alt=""
                        />
                    </button>
                )
            }
        },
        {
            field: 'delete',
            headerName: 'Delete',
            width: 91,
            height: 50,
            sortable: false,
            headerClassName: "list-head-Val",
            align: "center",
            headerAlign: "center",
            renderCell: (cellValue) => {
                return (
                    <button
                        class="editBtn"
                        onClick={() => { deleteButtonClick(cellValue.row) }}
                        type="button"
                        name=""
                    >
                        <img
                            src="/assets/images/deleteimg.svg"
                            class="delete-user-img"
                            alt=""
                        />
                    </button>
                )
            },
        },
    ];

    const openUserEditPage = (val) => {
        setSelectedData(val)
        setOpenUserEditMode(true);
        setShow(true);
    }

    const deleteButtonClick = (val) => {
        if (val) {
            setDialogOpen(true)
            setUserName(val.name)
            setSelectedId(val.id)
        }
    }

    const deleteDailogHandler = (val) => {
        setDialogOpen(false)
        if (val) {
            deleteApp({ tenantId, id: selectedId })
        }

    }

    const deviceManagementHandleClose = () => {
        setShow(false)
    }

    useEffect(() => {
        fetchAppList()
    }, [])
    return (
        <div className={classes.root}>
            {show ?
                <div className={classes.mtmTypeClass}>
                    <FadeInOut show={show} duration={500}>
                        {openUserEditMode === false ?
                            <AddApp setModalOpen={deviceManagementHandleClose} />
                            :
                            <EditApp setModalOpen={deviceManagementHandleClose} purpose={"edit"} data={selectedData} />
                        }
                    </FadeInOut>
                </div>
                : <DataGrid
                    rows={filteredData}
                    columns={columns}
                    rowHeight={68}
                    hideFooter
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    disableColumnMenu
                    localeText={{ noRowsLabel: "" }}
                    getRowSpacing={params => ({
                        top: params.isFirstVisible ? 0 : 0,
                        bottom: params.isLastVisible ? 0 : 0
                    })}
                    sx={{
                        boxShadow: 2,
                        border: "none",
                        borderBottom: "none",
                        '& .MuiDataGrid-cell:hover': {
                            color: '#ffb01f',
                        },
                        '& .MuiDataGrid': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-cell': {
                            borderColor: "#404040",
                        },
                        '& .MuiDataGrid-columnHeader': {
                            borderColor: "gray",
                        },
                        '& .MuiDataGrid-filler': {
                            backgroundColor: "#262626",
                        },
                    }}

                />
            }
            <AlertDialog open={dialogOpen} deleteDailogHandler={deleteDailogHandler} userName={userName} />
        </div>
        // <>
        //     <div className="device-row-grid-head">
        //         <div className="tr-tb-icon"></div>
        //         <div className="tr-tb-deviceid">
        //             <span>Package Id</span>
        //         </div>
        //         <div className="tr-tb-status">
        //             <span>Application Name</span>
        //         </div>
        //         <div className="tr-tb-config">
        //             <span>Version</span>
        //         </div>
        //         <div className="tr-tb-qr">
        //             <span>Run After Install</span>
        //         </div>
        //         <div className="tr-tb-qr">
        //             <span>Run At Boot</span>
        //         </div>
        //         <div class="tr-tb-qr">
        //             <span>Device Type</span>
        //         </div>
        //         <div class="tr-tb-edit">
        //             <span></span>
        //         </div>
        //         <div class="tr-tb-delete">
        //             <span></span>
        //         </div>
        //     </div>


        //     <div style={{ height: '880px', marginTop: '10px', overflowY: 'scroll' }}>
        //         {filteredData &&
        //             filteredData.map((data, i) => {
        //                 return (
        //                     <AppListRow
        //                         key={i}
        //                         data={data}
        //                     // grouplist={grouplist}
        //                     // userData={data}
        //                     // userlist={userlist}
        //                     // orglist={orglist}
        //                     // tetraUser={props.tetraUser}
        //                     // SubscribeType={subscribeType}
        //                     />
        //                 );
        //             })}
        //     </div>
        // </>
    )
}
const mapStateToProps = ({ mdm }) => {
    return {
        filteredData: mdm.filteredData,
        tenantId: mdm.tenantId
    }
}
export default connect(mapStateToProps, { fetchAppList, deleteApp })(AppList)