(function (exports) {
  const Store = require("../Store").Store;
  // const xmlToString = require("xmltojson");
  // const XMLSerializer = require("xmldom").XMLSerializer;

  class CallHeader {
    constructor(mcpttBodyUtils) {
      this.store = new Store();
      this.mcpttBodyUtils = mcpttBodyUtils;
    }

    getResourceListJsonWithExtraBody(body, mcpttToUri, callObj) {
      //console.log("body........ 1");
      //console.log("body........", body);

      // let header =
      //   "&Answer-Mode=" +
      //   callObj.answerMode +
      //   "&Content-Type=multipart/mixed" +
      //   "&boundary=" +
      //   callObj.boundary;
      // let encodeInfo = mcpttToUri + "?body= " + body + header;
      let encodeInfo =
        mcpttToUri +
        "?body=" +
        encodeURIComponent(body) +
        "&Answer-Mode=" +
        encodeURIComponent(callObj.answerMode) +
        "&Content-Type=" +
        encodeURIComponent("multipart/mixed" + ";boundary=" + callObj.boundary);

      let resourceListJson = {
        //   "resource-lists": {
        "@": {
          xmlns: "urn:ietf:params:xml:ns:resource-lists",
          "xmlns:cc": "urn:ietf:params:xml:ns:copycontrol",
        },
        list: {
          entry: [
            {
              "@": {
                uri: encodeInfo, //covert xml to string encoding // "sip:" + callObject.toId +"@"+ ua.config.domain,
                "cc:copyControl": "to",
              },
            },
          ],
        },
        //   },
      };
      //console.log("resourceListJson............", encodeInfo);
      return resourceListJson;
    }
    getPreEstByeHeaders(callObject) {
      return this.mcpttBodyUtils.getPreEstByeHeaders(callObject);
    }
    getPreEstHeadersAndBody(callObject) {
      // callObject,

      // let contact = callObject.contact;
      // let preferredIdentity = callObject.preferredIdentity;
      // let callPriority = callObject.callPriority;
      // let from = callObject.from;
      // let to = callObject.to;
      //console.log('getPreEstHeadersAndBody..', callObject);

      let answerMode = callObject.answerMode;
      let mangledSdp = callObject.mangledSdp;
      let callType = callObject.callType;
      let contact = callObject.contact;
      let mcpttUri = callObject.mcpttUri;
      let sessionType = callObject.sessionType;
      let isPTTCall = callObject.isPTTCall;
      let isBroadcastCall = callObject.isBroadcastCall;
      let isEmgCall = callObject.isEmgCall;
      try {
        let headersBody = {};
        let headers = {};
        let bodyMcptt = {};
        let boundary = "consort-boundary"; //config.get("settings.sipBoundary");
        let extraHeaders = [];
        let mcpttToUri = callObject.toId;
        let isVideo = callObject.isVideo;
        callObject.boundary = boundary;
        // console.log(
        //   "get call headers and body b i p.. ",
        //   callObject,
        //   isBroadcastCall,
        //   isEmgCall,
        //   isPTTCall
        // );

        let callPriority = callObject.callPriority
          ? callObject.callPriority
          : "6";

        callObject.answerMode = answerMode;
        callObject.contact = contact;
        callObject.mcpttUri = mcpttUri;
        callObject.callPriority = callPriority;

        extraHeaders = this.mcpttBodyUtils.getPreEstCallHeaders(callObject);

        headers = {
          "Answer-Mode": answerMode,
          Contact: contact,
          "Accept-Contact": "g.3gpp.mcptt;require;explicit",
          "Accept-Contact":
            ';+g.3gpp.icsi-ref="urn%3Aurn-7%3A3gpp-service.ims.icsi.mcptt";+g.3gpp.mcptt;require;explicit',
          "P-Preferred-Service": "urn:urn-7:3gpp-service.ims.icsi.mcptt",
          "Resource-Priority": callPriority,
          "Content-Type": "multipart/mixed; boundary=consort-boundary",
        };

        //console.log("extraHeaders and headers..", extraHeaders, headers);

        //         <?xml version="1.0" encoding="UTF-8"?>
        // <resource-lists xmlns="urn:ietf:params:xml:ns:resource-lists" xmlns:cc=" urn:ietf:params:xml:ns:copycontrol">
        // <list>
        // <entry uri="mcptt_id_clientB@example.com" cc:copyControl="to"/> </list>
        // </resource-lists>
        // </xml>
        // let resourceListJson = {
        //   //   "resource-lists": {
        //   "@": {
        //     xmlns: "urn:ietf:params:xml:ns:resource-lists",
        //     "xmlns:cc": "urn:ietf:params:xml:ns:copycontrol",
        //   },
        //   list: {
        //     entry: [
        //       {
        //         "@": {
        //           uri: mcpttToUri, // "sip:" + callObject.toId +"@"+ ua.config.domain,
        //           "cc:copyControl": "to",
        //         },
        //       },
        //     ],
        //   },
        //   //   },
        // };

        let mcpttInfo = {};
        let mcvideoInfo = {};
        let mimeType = isVideo ? "mcvideo" : "mcptt";

        if (callType == "private") {
          mcpttInfo = {
            "@": {
              xmlns: `urn:3gpp:ns:${mimeType}Info:1.0`,
              "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
            },
            [`${mimeType}-Params`]: {
              //   "is-video": callObject.isVideo ? callObject.isVideo : false,
              "session-type": callType,

              [`${mimeType}-calling-user-id`]: {
                "@": {
                  type: "Normal",
                },
                [`${mimeType}URI`]: mcpttUri.includes("sip")
                  ? `${mcpttUri}`
                  : `sip:${mcpttUri}`,
              },
              //   "group-id": callObject.groupId ? callObject.groupId : "",
              anyExt: {
                "@": {
                  type: "Normal",
                },
                ptt: isPTTCall,
              },
            },
          };

          if (isEmgCall) {
            let emegency = {
              "@": {
                type: "Normal",
              },
              [`${mimeType}Boolean`]: true,
            };
            let rootKey = mimeType + "-Params";
            mcpttInfo[rootKey]["emergency-ind"] = emegency;
          }

          if (isVideo) mcvideoInfo = mcpttInfo;

          let contents = [
            {
              mime: `application/vnd.3gpp.${mimeType}-info+xml`,
              content: this.mcpttBodyUtils.mcpttinfoJsonToXml(
                isVideo ? mcvideoInfo : mcpttInfo,
                "",
                mimeType
              ),
            },
            // {
            //   content: this.mcpttBodyUtils.resourceListJsonToXml(
            //     resourceListJson
            //   ),
            //   mime: "application/resource-lists+xml",
            // },
            { content: mangledSdp, mime: "application/sdp" },
          ];
          bodyMcptt = this.mcpttBodyUtils.arrayBodyToMultipartBody(
            contents,
            boundary,
            true
          );
          // headersBody["headers"] = headers;
          headersBody["headers"] = extraHeaders;
          headersBody["body"] = this.mcpttBodyUtils.resourceListJsonToXml(
            this.getResourceListJsonWithExtraBody(
              bodyMcptt,
              mcpttToUri,
              callObject
            ),
            null
          ); //bodyMcptt;
          return headersBody;
        } else if (callType == "first-to-answer") {
          let callerUriFa = callObject.fromId;

          mcpttInfo = {
            "@": {
              xmlns: `urn:3gpp:ns:${mimeType}Info:1.0`,
              "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
            },
            [`${mimeType}-Params`]: {
              //   "is-video": callObject.isVideo ? callObject.isVideo : false,
              "session-type": callType,
              anyExt: {
                "@": {
                  type: "Normal",
                },
                ptt: isPTTCall,
                "call-to-functional-alias-ind": true,
                "functional-alias-URI": {
                  "@": {
                    type: "Normal",
                  },
                  [`${mimeType}URI`]: callerUriFa.includes("sip")
                    ? `${callerUriFa}`
                    : `sip:${callerUriFa}`,
                },
              },
            },
          };
          if (isEmgCall) {
            let emegency = {
              "@": {
                type: "Normal",
              },
              [`${mimeType}Boolean`]: true,
            };
            let rootKey = mimeType + "-Params";
            mcpttInfo[rootKey]["emergency-ind"] = emegency;
          }
          if (isVideo) mcvideoInfo = mcpttInfo;
          let contents = [
            {
              mime: `application/vnd.3gpp.${mimeType}-info+xml`,
              content: this.mcpttBodyUtils.mcpttinfoJsonToXml(
                isVideo ? mcvideoInfo : mcpttInfo,
                "",
                mimeType
              ),
            },
            // {
            //   content: this.mcpttBodyUtils.resourceListJsonToXml(
            //     resourceListJson
            //   ),
            //   mime: "application/resource-lists+xml",
            // },
            { content: mangledSdp, mime: "application/sdp" },
          ];
          bodyMcptt = this.mcpttBodyUtils.arrayBodyToMultipartBody(
            contents,
            boundary,
            true
          );
          // headersBody["headers"] = headers;
          headersBody["headers"] = extraHeaders;
          // headersBody["body"] = bodyMcptt;
          headersBody["body"] = this.mcpttBodyUtils.resourceListJsonToXml(
            this.getResourceListJsonWithExtraBody(
              bodyMcptt,
              mcpttToUri,
              callObject
            ),
            null
          ); //bodyMcptt;
          return headersBody;
        } else if (isBroadcastCall && callType == "prearranged") {
          mcpttInfo = {
            "@": {
              xmlns: `urn:3gpp:ns:${mimeType}Info:1.0`,
              "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
            },
            [`${mimeType}-Params`]: {
              "session-type": callType,
              [`${mimeType}-request-uri`]: {
                "@": {
                  type: "Normal",
                },
                [`${mimeType}URI`]: mcpttToUri,
              },
              [`${mimeType}-calling-user-id`]: {
                "@": {
                  type: "Normal",
                },
                [`${mimeType}URI`]: mcpttUri,
              },
              "broadcast-ind": isBroadcastCall,
              anyExt: {
                "@": {
                  type: "Normal",
                },
                ptt: isPTTCall,
              },
            },
          };
          if (isEmgCall) {
            let emegency = {
              "@": {
                type: "Normal",
              },
              [`${mimeType}Boolean`]: true,
            };
            let rootKey = mimeType + "-Params";
            mcpttInfo[rootKey]["emergency-ind"] = emegency;
          }
          if (isVideo) mcvideoInfo = mcpttInfo;
          let contents = [
            {
              mime: `application/vnd.3gpp.${mimeType}-info+xml`,
              content: this.mcpttBodyUtils.mcpttinfoJsonToXml(
                isVideo ? mcvideoInfo : mcpttInfo,
                "",
                mimeType
              ),
            },
            { content: mangledSdp, mime: "application/sdp" },
          ];
          bodyMcptt = this.mcpttBodyUtils.arrayBodyToMultipartBody(
            contents,
            boundary,
            true
          );
          // headersBody["headers"] = headers;
          // headersBody["body"] = bodyMcptt;
          headersBody["body"] = this.mcpttBodyUtils.resourceListJsonToXml(
            this.getResourceListJsonWithExtraBody(
              bodyMcptt,
              mcpttToUri,
              callObject
            )
          ); //bodyMcptt;
          headersBody["headers"] = extraHeaders;
        } else {
          mcpttInfo = {
            "@": {
              xmlns: `urn:3gpp:ns:${mimeType}Info:1.0`,
              "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
            },
            [`${mimeType}-Params`]: {
              //   "is-video": callObject.isVideo ? callObject.isVideo : false,
              "session-type": callType,
              [`${mimeType}-request-uri`]: {
                "@": {
                  type: "Normal",
                },
                [`${mimeType}URI`]: mcpttToUri, //"sip:" + callObject.toId,//groupid
              },
              [`${mimeType}-calling-user-id`]: {
                "@": {
                  type: "Normal",
                },
                [`${mimeType}URI`]: mcpttUri, //"sip:" + callObject.fromId, callerId
              },
              anyExt: {
                "@": {
                  type: "Normal",
                },
                ptt: isPTTCall,
              },
              //   "group-id": callObject.groupId ? callObject.groupId : "",
            },
          };
          if (isEmgCall) {
            let emegency = {
              "@": {
                type: "Normal",
              },
              [`${mimeType}Boolean`]: true,
            };
            let rootKey = mimeType + "-Params";
            mcpttInfo[rootKey]["emergency-ind"] = emegency;
          }
          if (isVideo) mcvideoInfo = mcpttInfo;
          let contents = [
            {
              mime: `application/vnd.3gpp.${mimeType}-info+xml`,
              content: this.mcpttBodyUtils.mcpttinfoJsonToXml(
                isVideo ? mcvideoInfo : mcpttInfo,
                "",
                mimeType
              ),
            },
            { content: mangledSdp, mime: "application/sdp" },
          ];
          //console.log("groupCalll_Parser contents ", contents);
          bodyMcptt = this.mcpttBodyUtils.arrayBodyToMultipartBody(
            contents,
            boundary,
            true
          );

          headersBody["body"] = this.mcpttBodyUtils.resourceListJsonToXml(
            this.getResourceListJsonWithExtraBody(
              bodyMcptt,
              mcpttToUri,
              callObject
            ),
            null
          ); 
          headersBody["headers"] = extraHeaders;
        }
        //console.log("outgoing call request final headerBody....", headersBody);
        return headersBody;
      } catch (e) {
        console.log("getHeadersAndBody getUACHeaders", e);
        // [TypeError: array.slice is not a function. (In 'array.slice()', 'array.slice' is undefined)]
      }
    }

    getHeadersAndBody(
      callObject,
      mangledSdp,
      callType,
      answerMode,
      contact,
      mcpttUri,
      sessionType,
      isPTTCall,
      isBroadcastCall,
      isEmgCall
    ) {
      try {
        let headersBody = {};
        let headers = {};
        let bodyMcptt = {};
        let boundary = "consort-boundary"; //config.get("settings.sipBoundary");
        let extraHeaders = [];
        let mcpttToUri = callObject.toId;
        let isVideo = callObject.isVideo;
        // console.log(
        //   "get call headers and body b i p.. ",
        //   callObject,
        //   isBroadcastCall,
        //   isEmgCall,
        //   isPTTCall
        // );

        let callPriority = callObject.callPriority
          ? callObject.callPriority
          : "6";

        extraHeaders = this.mcpttBodyUtils.getCallHeaders(
          callObject,
          answerMode,
          contact,
          mcpttUri,
          callPriority
        );

        headers = {
          "Answer-Mode": answerMode,
          Contact: contact,
          "Accept-Contact": "g.3gpp.mcptt;require;explicit",
          "Accept-Contact":
            ';+g.3gpp.icsi-ref="urn%3Aurn-7%3A3gpp-service.ims.icsi.mcptt";+g.3gpp.mcptt;require;explicit',
          "P-Preferred-Service": "urn:urn-7:3gpp-service.ims.icsi.mcptt",
          "Resource-Priority": callPriority,
          "Content-Type": "multipart/mixed; boundary=consort-boundary",
        }; 

        //console.log("extraHeaders and headers..", +extraHeaders, headers);

        //         <?xml version="1.0" encoding="UTF-8"?>
        // <resource-lists xmlns="urn:ietf:params:xml:ns:resource-lists" xmlns:cc=" urn:ietf:params:xml:ns:copycontrol">
        // <list>
        // <entry uri="mcptt_id_clientB@example.com" cc:copyControl="to"/> </list>
        // </resource-lists>
        // </xml>

        let resourceListJson = {
          //   "resource-lists": {
          "@": {
            xmlns: "urn:ietf:params:xml:ns:resource-lists",
            "xmlns:cc": "urn:ietf:params:xml:ns:copycontrol",
          },
          list: {
            entry: [
              {
                "@": {
                  uri: mcpttToUri, // "sip:" + callObject.toId +"@"+ ua.config.domain,
                  "cc:copyControl": "to",
                },
              },
            ],
          },
          //   },
        };

        let mcpttInfo = {};
        let mcvideoInfo = {};
        let mimeType = isVideo ? "mcvideo" : "mcptt";

        if (callType == "private") {
          mcpttInfo = {
            "@": {
              xmlns: `urn:3gpp:ns:${mimeType}Info:1.0`,
              "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
            },
            [`${mimeType}-Params`]: {
              //   "is-video": callObject.isVideo ? callObject.isVideo : false,
              "session-type": callType,

              [`${mimeType}-calling-user-id`]: {
                "@": {
                  type: "Normal",
                },
                [`${mimeType}URI`]: mcpttUri.includes("sip")
                  ? `${mcpttUri}`
                  : `sip:${mcpttUri}`,
              },
              //   "group-id": callObject.groupId ? callObject.groupId : "",
              anyExt: {
                "@": {
                  type: "Normal",
                },
                ptt: isPTTCall,
              },
            },
          };

          if (isEmgCall) {
            let emegency = {
              "@": {
                type: "Normal",
              },
              [`${mimeType}Boolean`]: true,
            };
            let rootKey = mimeType + "-Params";
            mcpttInfo[rootKey]["emergency-ind"] = emegency;
          }

          if (isVideo) mcvideoInfo = mcpttInfo;

          let contents = [
            {
              mime: `application/vnd.3gpp.${mimeType}-info+xml`,
              content: this.mcpttBodyUtils.mcpttinfoJsonToXml(
                isVideo ? mcvideoInfo : mcpttInfo,
                "",
                mimeType
              ),
            },
            {
              content: this.mcpttBodyUtils.resourceListJsonToXml(
                resourceListJson
              ),
              mime: "application/resource-lists+xml",
            },
            { content: mangledSdp, mime: "application/sdp" },
          ];
          bodyMcptt = this.mcpttBodyUtils.arrayBodyToMultipartBody(
            contents,
            boundary
          );
          headersBody["headers"] = headers;
          headersBody["extraHeaders"] = extraHeaders;
          headersBody["body"] = bodyMcptt;
          return headersBody;
        } else if (callType == "first-to-answer") {
          let callerUriFa = callObject.fromId;

          mcpttInfo = {
            "@": {
              xmlns: `urn:3gpp:ns:${mimeType}Info:1.0`,
              "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
            },
            [`${mimeType}-Params`]: {
              //   "is-video": callObject.isVideo ? callObject.isVideo : false,
              "session-type": callType,
              // "mcptt-calling-user-id": {
              //   "@": {
              //     type: "Normal",
              //   },
              //   mcpttURI: mcpttUri.includes("sip")
              //     ? `${mcpttUri}`
              //     : `sip:${mcpttUri}`,
              // },
              // //   "group-id": callObject.groupId ? callObject.groupId : "",
              anyExt: {
                "@": {
                  type: "Normal",
                },
                ptt: isPTTCall,
                "call-to-functional-alias-ind": true,
                "functional-alias-URI": {
                  "@": {
                    type: "Normal",
                  },
                  [`${mimeType}URI`]: callerUriFa.includes("sip")
                    ? `${callerUriFa}`
                    : `sip:${callerUriFa}`,
                },
              },
            },
          };
          if (isEmgCall) {
            let emegency = {
              "@": {
                type: "Normal",
              },
              [`${mimeType}Boolean`]: true,
            };
            let rootKey = mimeType + "-Params";
            mcpttInfo[rootKey]["emergency-ind"] = emegency;
          }
          if (isVideo) mcvideoInfo = mcpttInfo;
          let contents = [
            {
              mime: `application/vnd.3gpp.${mimeType}-info+xml`,
              content: this.mcpttBodyUtils.mcpttinfoJsonToXml(
                isVideo ? mcvideoInfo : mcpttInfo,
                "",
                mimeType
              ),
            },
            {
              content: this.mcpttBodyUtils.resourceListJsonToXml(
                resourceListJson
              ),
              mime: "application/resource-lists+xml",
            },
            { content: mangledSdp, mime: "application/sdp" },
          ];
          bodyMcptt = this.mcpttBodyUtils.arrayBodyToMultipartBody(
            contents,
            boundary
          );
          headersBody["headers"] = headers;
          headersBody["extraHeaders"] = extraHeaders;
          headersBody["body"] = bodyMcptt;
          return headersBody;
        } else if (isBroadcastCall && callType == "prearranged") {
          mcpttInfo = {
            "@": {
              xmlns: `urn:3gpp:ns:${mimeType}Info:1.0`,
              "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
            },
            [`${mimeType}-Params`]: {
              "session-type": callType,
              [`${mimeType}-request-uri`]: {
                "@": {
                  type: "Normal",
                },
                [`${mimeType}URI`]: mcpttToUri,
              },
              [`${mimeType}-calling-user-id`]: {
                "@": {
                  type: "Normal",
                },
                [`${mimeType}URI`]: mcpttUri,
              },
              "broadcast-ind": isBroadcastCall,
              anyExt: {
                "@": {
                  type: "Normal",
                },
                ptt: isPTTCall,
              },
            },
          };
          if (isEmgCall) {
            let emegency = {
              "@": {
                type: "Normal",
              },
              [`${mimeType}Boolean`]: true,
            };
            let rootKey = mimeType + "-Params";
            mcpttInfo[rootKey]["emergency-ind"] = emegency;
          }
          if (isVideo) mcvideoInfo = mcpttInfo;
          let contents = [
            {
              mime: `application/vnd.3gpp.${mimeType}-info+xml`,
              content: this.mcpttBodyUtils.mcpttinfoJsonToXml(
                isVideo ? mcvideoInfo : mcpttInfo,
                "",
                mimeType
              ),
            },
            { content: mangledSdp, mime: "application/sdp" },
          ];
          bodyMcptt = this.mcpttBodyUtils.arrayBodyToMultipartBody(
            contents,
            boundary
          );
          headersBody["headers"] = headers;
          headersBody["body"] = bodyMcptt;
          headersBody["extraHeaders"] = extraHeaders;
        } else if (callType == "ambient-listening") {
          mcpttInfo = {
            "@": {
              xmlns: `urn:3gpp:ns:${mimeType}Info:1.0`,
              "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
            },
            [`${mimeType}-Params`]: {
              //   "is-video": callObject.isVideo ? callObject.isVideo : false,
              "session-type": callType,
              "ambient-listening-type": "remote-init",

              [`${mimeType}-calling-user-id`]: {
                "@": {
                  type: "Normal",
                },
                [`${mimeType}URI`]: mcpttUri.includes("sip")
                  ? `${mcpttUri}`
                  : `sip:${mcpttUri}`,
              },
              //   "group-id": callObject.groupId ? callObject.groupId : "",
              anyExt: {
                "@": {
                  type: "Normal",
                },
                ptt: isPTTCall,
              },
            },
          };

          let contents = [
            {
              mime: `application/vnd.3gpp.${mimeType}-info+xml`,
              content: this.mcpttBodyUtils.mcpttinfoJsonToXml(
                mcpttInfo,
                "",
                mimeType
              ),
            },
            {
              content: this.mcpttBodyUtils.resourceListJsonToXml(
                resourceListJson
              ),
              mime: "application/resource-lists+xml",
            },
            { content: mangledSdp, mime: "application/sdp" },
          ];
          bodyMcptt = this.mcpttBodyUtils.arrayBodyToMultipartBody(
            contents,
            boundary
          );
          headersBody["headers"] = headers;
          headersBody["extraHeaders"] = extraHeaders;
          headersBody["body"] = bodyMcptt;
          return headersBody;
        } else {
          mcpttInfo = {
            "@": {
              xmlns: `urn:3gpp:ns:${mimeType}Info:1.0`,
              "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
            },
            [`${mimeType}-Params`]: {
              //   "is-video": callObject.isVideo ? callObject.isVideo : false,
              "session-type": callType,
              [`${mimeType}-request-uri`]: {
                "@": {
                  type: "Normal",
                },
                [`${mimeType}URI`]: mcpttToUri, //"sip:" + callObject.toId,//groupid
              },
              [`${mimeType}-calling-user-id`]: {
                "@": {
                  type: "Normal",
                },
                [`${mimeType}URI`]: mcpttUri, //"sip:" + callObject.fromId, callerId
              },
              anyExt: {
                "@": {
                  type: "Normal",
                },
                ptt: isPTTCall,
              },
              //   "group-id": callObject.groupId ? callObject.groupId : "",
            },
          };
          if (isEmgCall) {
            let emegency = {
              "@": {
                type: "Normal",
              },
              [`${mimeType}Boolean`]: true,
            };
            let rootKey = mimeType + "-Params";
            mcpttInfo[rootKey]["emergency-ind"] = emegency;
          }
          if (isVideo) mcvideoInfo = mcpttInfo;
          let contents = [
            {
              mime: `application/vnd.3gpp.${mimeType}-info+xml`,
              content: this.mcpttBodyUtils.mcpttinfoJsonToXml(
                isVideo ? mcvideoInfo : mcpttInfo,
                "",
                mimeType
              ),
            },
            { content: mangledSdp, mime: "application/sdp" },
          ];
          bodyMcptt = this.mcpttBodyUtils.arrayBodyToMultipartBody(
            contents,
            boundary
          );
          headersBody["headers"] = headers;
          headersBody["body"] = bodyMcptt;
          headersBody["extraHeaders"] = extraHeaders;
        }
        //console.log("outgoing call request final headerBody....", headersBody);
        return headersBody;
      } catch (e) {
        console.log("getHeadersAndBody getUACHeaders", e, e.stack);
        // [TypeError: array.slice is not a function. (In 'array.slice()', 'array.slice' is undefined)]
      }
    }

    getCallingForwardMessage(msgObj) {
      //A call-> B ->(forwarded To) C
      let callType = msgObj.callType;
      let contact = msgObj.contact;
      let mcpttUri = msgObj.fromId; //calling-user-id (itself) B
      let isPTTCall = msgObj.isPTTCall;

      let mcpttReqUri = msgObj.toId; //request-uri A
      let isVideo = msgObj.isVideo;
      let mcpttCalledPartyURI = msgObj.forwardMcpttURI; //called-party-id C

      let headersBody = {};
      let headers = {};
      let bodyMcptt = {};
      let boundary = "consort-boundary";

      // headers = {
      //   Contact: contact,
      //   "Accept-Contact": "g.3gpp.mcptt;require;explicit",
      //   "Accept-Contact":
      //     ';+g.3gpp.icsi-ref="urn%3Aurn-7%3A3gpp-service.ims.icsi.mcptt";+g.3gpp.mcptt;require;explicit',
      //   "P-Preferred-Service": "urn:urn-7:3gpp-service.ims.icsi.mcptt",
      //   "Content-Type": "multipart/mixed; boundary=consort-boundary",
      // };

      headers = [
        "Accept-Contact: " + "g.3gpp.mcdata.sds;require;explicit",
        "Accept-Contact: " +
        'g.3gpp.icsi-ref="urn:urn-7:3gpp-service.ims.icsi.mcdata.sds";require;explicit',
        "P-Preferred-Service: " +
        "urn:urn-7:3gpp-service.ims.icsi.mcdata.sds",
      ];

      let resourceListJson = {
        "resource-lists": {
          list: {
            entry: [
              {
                "@": {
                  type: "uri",
                },
                "#": `${mcpttUri}`,
              },
            ],
          },
        },
      };

      let mcpttInfo = {};
      let mcvideoInfo = {};
      let mimeType = isVideo ? "mcvideo" : "mcptt";

      if (callType == "private") {
        mcpttInfo = {
          "@": {
            xmlns: `urn:3gpp:ns:${mimeType}Info:1.0`,
            "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
          },
          [`${mimeType}-Params`]: {
            "session-type": callType,
            anyExt: {
              "request-type": "forward-private-call-request",
              [`${mimeType}-called-party-id`]: {
                "@": {
                  type: "Normal",
                },
                [`${mimeType}URI`]: mcpttCalledPartyURI.includes("sip")
                  ? `${mcpttCalledPartyURI}`
                  : `sip:${mcpttCalledPartyURI}`,
              },
              "forwarding-reason": "Manual-Input",
              [`${mimeType}-calling-user-id`]: {
                "@": {
                  type: "Normal",
                },
                [`${mimeType}URI`]: mcpttUri.includes("sip")
                  ? `${mcpttUri}`
                  : `sip:${mcpttUri}`,
              },
              [`${mimeType}-request-uri`]: {
                "@": {
                  type: "Normal",
                },
                [`${mimeType}URI`]: mcpttReqUri.includes("sip")
                  ? `${mcpttReqUri}`
                  : `sip:${mcpttReqUri}`,
              },
              ptt: isPTTCall,
            },
          },
        };

        /*
        <anyExt>...
<request-type>forward-private-call-request</request-type>
<mcptt-called-party-id Type="Normal"><mcpttURI>sip:mcptt_id_clientC@example.com</mcpttURI></mcptt-called-party-id> 
<forwarding-reason>Manual-Input</forwarding-reason>
MCPTT-INFO:called=B
<mcptt-calling-user-id Type="Normal"><mcpttURI>sip:mcptt_id_clientB@example.com</mcpttURI></mcptt-calling-user-id> 
<mcptt-request-uri Type="Normal"><mcpttURI>sip:mcptt_id_clientA@example.com</mcpttURI></mcptt-request-uri> ...</anyExt>..
        */

        let contents = [
          {
            mime: `application/vnd.3gpp.${mimeType}-info+xml`,
            content: this.mcpttBodyUtils.mcpttinfoJsonToXml(
              isVideo ? mcvideoInfo : mcpttInfo,
              "",
              mimeType
            ),
          },
          {
            content: this.mcpttBodyUtils.resourceListJsonToXml(
              resourceListJson["resource-lists"]
            ),
            mime: "application/resource-lists+xml",
          },
        ];
        bodyMcptt = this.mcpttBodyUtils.arrayBodyToMultipartBody(
          contents,
          boundary
        );
        headersBody["headers"] = headers;
        headersBody["body"] = bodyMcptt;
        return headersBody;
      }
    }
  }
  exports.CallHeader = CallHeader;
})(typeof exports === "undefined" ? (this["CallHeader"] = {}) : exports);
