import React, { Component, useState, useEffect, Fragment } from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Box, Grid, Typography } from '@mui/material';

const GrpBasicInfo = (props) => {
  const { basicInfohandler, grpBasicInfo, purpose, orglist, groupTypes } = props
  const [newOrUpdateProfile, setNewOrUpdateProfile] = useState(true)
  const [emptyGroupPriority, setGroupPriority] = useState(false)
  const [emptyGroupName, setEmptyGroupName] = useState(false)
  const [emptyGroupId, setEmptyGroupId] = useState(false)
  const [emptyGroupType, setEmptyGroupType] = useState(false)
  const [emptyOrgId, setEmptyOrgId] = useState(false)
  const [basicinfo, SetbasicInfo] = useState(grpBasicInfo)
  const [selectedGroupType, setSelectedGroupType] = useState("")

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#252524",
      //backgroundColor: theme.palette.background.default,
      paddingTop: 10,
      paddingLeft: 20,
      paddingRight: 20,
      width: "100%",
      //height: "70%",
      overflowY: "scroll",
      display: 'flex',
      flexDirection: 'column',
      // justifyContent: 'center',
    },
    formControlFA: {
      width: "60%",
      height: 65,
      marginTop: 2,
      color: "#D0D3D4",
      fontSize: 17,
      fontWeight: 600,
      backgroundColor: "#383736",
      marginBottom: 5
    },
    listItemFA: {
      padding: "5px",
      fontFamily: "Muli",
      color: "#D0D3D4",
      fontSize: 17,
      fontWeight: 600
    },
    productTypeClass: {
      width: "90%",
      display: 'flex',
      flexDirection: 'column',
      justifyContent: "center",
      alignItems: "center",
      border: "solid",
      borderRadius: 10,
      borderWidth: "2px",
      borderColor: "#4B4A4A",
      marginTop: 5,
      marginBottom: 10
    },
    divList: {
      width: '100%',
      marginBottom: '13px',
    },
    lblStyle: {
      width: '100%',
      color: "#D0D3D4",
      fontSize: 18,
      fontWeight: 600
    },
    tetraSwitch: {
      fontFamily: "Muli",
      marginTop: "2px",
      marginRight: "1px",
      marginLeft: "0px",
      color: "#D0D3D4",
      fontSize: 17,
      fontWeight: 600
    },
    datePickerStyle: {
      color: "black",
      padding: "10px",
      fontFamily: "Sans-Serif",
      width: '60%',
      border: "solid",
      borderRadius: 20,
      borderWidth: "2px",
      borderColor: "#D3D3D3",
    },
    paper: {
      position: 'relative',
      // background: '#e8e8e8',
      backgroundColor: "#383736",
      color: "#D0D3D4",
      overflow: 'hidden',
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#383736"
      },
    },
  }));
  const classes = useStyles();
  const handleOrgChange = (value) => {
    SetbasicInfo({
      ...basicinfo,
      orgId: value,
    });
    setEmptyOrgId(false);
  };

  useEffect(() => {
    console.log("GrpBasicInfo group data", grpBasicInfo, orglist)
    if (purpose === "edit") {
      SetbasicInfo(grpBasicInfo)
    }
  }, [])
  const UpdateBasicDetails = () => {
    if ((basicinfo.groupType.length === 0)) {
      setEmptyGroupType(true)

    }
    else if ((basicinfo.groupname.length === 0)) {
      setEmptyGroupName(true)

    }
    else if (basicinfo.groupId.length === 0) {
      setEmptyGroupId(true)
    }
    else if (basicinfo.priority.length === 0) {
      setGroupPriority(true)
    }
    else if (basicinfo.groupType === "ORG" && basicinfo.orgId.length === 0) {
      setEmptyOrgId(true)
    }
    else {
      basicInfohandler(basicinfo)
    }


  }
  const groupNameChangeHandler = (e) => {
    SetbasicInfo({
      ...basicinfo,
      groupname: e.target.value,
    });
    if (e.target.value.length !== 0) {
      setEmptyGroupName(false)
    } else {
      setEmptyGroupName(true)
    }
  };

  const groupIdChangeHandler = (e) => {
    SetbasicInfo({
      ...basicinfo,
      groupId: e.target.value.trim(),
    });
    if (e.target.value.length !== 0) {
      setEmptyGroupId(false)
    } else {
      setEmptyGroupId(true)
    }
  };

  const priorityChangeHandler = (e) => {
    SetbasicInfo({
      ...basicinfo,
      priority: e.target.value,
    });
    if (e.target.value.length !== 0) {
      setGroupPriority(false)
    } else {
      setGroupPriority(true)
    }
    console.log("update priority", basicinfo);

  };
  const handleGroupTypeChange = (e) => {
    SetbasicInfo({
      ...basicinfo,
      groupType: e.target.value,
    });
    if (e.target.value.length !== 0) {
      setEmptyGroupType(false)
    } else {
      setEmptyGroupType(true)
    }
  }
  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Box class="tab1-account">
          <Box class="form-group">
            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
              <label class="attribute-heading" style={{ color: 'whitesmoke' }} >Group Id</label>
            </Box>
            <input type="text" style={{ borderColor: '#4B4A4A' }} class="input-control-flat" value={basicinfo.groupId} id="name" onChange={purpose === "edit" ? () => { } : groupIdChangeHandler} />
            {
              emptyGroupId === true ?
                (
                  <label class="error-handling-lbl">Please enter the group Id</label>
                )
                :
                null
            }
          </Box>
          <Box class="form-group">
            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
              <label class="attribute-heading" style={{ color: 'whitesmoke' }} >Group Name</label>
            </Box>
            <input type="text" style={{ borderColor: '#4B4A4A' }} class="input-control-flat" value={basicinfo.groupname} id="name" onChange={groupNameChangeHandler} />
            {
              emptyGroupName === true ?
                (
                  <label class="error-handling-lbl">Please enter the group name</label>
                )
                :
                null
            }
          </Box>

          <Box class="form-group">
            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
              <label class="attribute-heading" style={{ color: 'whitesmoke' }} for="priority">Priority</label>
            </Box>
            <input type="text" style={{ borderColor: '#4B4A4A' }} class="input-control-flat" value={basicinfo.priority} id="priority" onChange={priorityChangeHandler} />
            {
              emptyGroupPriority === true ?
                (
                  <label class="error-handling-lbl">Please enter the group priority </label>
                )
                :
                null
            }
          </Box>

          {purpose !== "edit" ?
            (
              <>
                <FormControl variant="filled" className={classes.formControlFA}>
                  <InputLabel id="demo-simple-select-filled-label" style={{ color: 'whitesmoke' }} className={classes.listItemFA} focused={false}>GROUP TYPE</InputLabel>
                  <Select
                    className={classes.listItemFA}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={basicinfo.groupType}
                    onChange={handleGroupTypeChange}
                    disableUnderline={true}
                  >
                    {groupTypes && groupTypes.map((groupType) => {
                      return (
                        <MenuItem value={groupType.value}>{groupType.text}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
                {
                  emptyGroupType === true ?
                    (
                      <label class="error-handling-lbl">Please select the group type</label>
                    )
                    :
                    null
                }
              </>
            )
            :
            (
              <Box class="form-group">
                <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                  <label class="attribute-heading" style={{ color: 'whitesmoke' }} >Group Type</label>
                </Box>
                <input type="text" style={{ borderColor: '#4B4A4A' }} class="input-control-flat" value={basicinfo.groupType} id="name" onChange={() => { }} />
              </Box>
            )

          }
          {
            basicinfo.groupType === "ORG" ?
              <Box className="form-group">
                {/* <label class="tab2-heading">Org</label> */}
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  className={classes.paper}
                  options={orglist ? orglist : []}
                  value={orglist ? orglist.find(v => v.orgId === basicinfo.orgId) || '' : ''}
                  style={{ marginBottom: 25, paddingLeft: 5, backgroundColor: '#383736' }}
                  renderOption={option => <Fragment>{option.hasOwnProperty('orgName') ? option.orgName : ''}</Fragment>}
                  getOptionLabel={option => option.hasOwnProperty('orgName') ? option.orgName : ''}
                  renderInput={(params) => <TextField  {...params} label="Search Org" variant="filled" InputLabelProps={{ className: "textFieldAutoComplete" }} />}
                  onChange={(e, v) => {

                    if (v && v.hasOwnProperty('orgId') && v.orgId) {
                      handleOrgChange(v.orgId)
                    }
                    else {
                      SetbasicInfo({
                        ...basicinfo,
                        orgId: "",
                      });
                      setEmptyOrgId(true)
                    }
                  }
                  }
                />
              </Box> : null
          }
          {
            emptyOrgId === true && basicinfo.groupType === "ORG" ?
              (
                <label class="error-handling-lbl">Please select the organisation</label>
              )
              :
              null
          }

        </Box>

        <button
          className="update-btn-profile"
          type="button"
          onClick={UpdateBasicDetails}
        >NEXT
          {/* {newOrUpdateProfile ? 'SUBMIT' : 'UPDATE'} */}
        </button>
      </Grid>
    </div>
  );
};
export default GrpBasicInfo;
