import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { Modal } from 'react-bootstrap';
import { updateOrderForProduct, resetOrderRejectedCauses } from "../../modules/productcatalogstate";
import { restConfigDevices } from "../../modules/actions/mdmAction"
import { connect } from "react-redux";
import ExecuteOrder from "./executeOrder";
import RejectOrderCauses from "./rejectOrderCauses";
import PostpaidOrder from "./postpaidOrder";

const MySales = (props) => {
    const { orders, refreshSalesAfterConfirmingOrder, deviceTypeList, updateOrderForProduct, restConfigDevices, errorTuples, resetOrderRejectedCauses, paginationFunc } = props;
    const [deviceId, SetDeviceId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openPostpaidModal, setOpenPostpaidModal] = useState(false);
    const [selectedOrder, setOrder] = useState(null);
    const [openErrorOrder, setOpenErrorOrder] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    })

    useEffect(() => {
        if (errorTuples && errorTuples.length > 0) {
            setOpenErrorOrder(true);
        }
    }, [errorTuples])

    useEffect(() => {
        paginationFunc(paginationModel, "sale")
    }, [paginationModel])


    const columns = [
        {
            field: 'productName', headerName: 'Product Name', width: 400, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "left", headerAlign: "left",
            valueGetter: (value, row) => `${row.products ? row.products.shortDescription : ""}`,
        },
        {
            field: 'buyer', headerName: 'Buyer Name', width: 270, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "left", headerAlign: "left",
            valueGetter: (value, row) => `${row.buyerMTMId ? row.buyerMTMId.name : ""}`,
        },
        {
            field: 'productType', headerName: 'Type', width: 200, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "left", headerAlign: "left",
            valueGetter: (value, row) => `${row.products ? row.products.productType : ""}`,
        },
        {
            field: 'productSubType', headerName: 'SubType', width: 200, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "left", headerAlign: "left",
            valueGetter: (value, row) => `${row.products ? getProductSubTypeName(row.products.productType, row.products.productSubType) : ""}`,
        },
        {
            field: 'orderValue',
            headerName: 'Order Value',
            width: 200,
            sortable: false,
            headerClassName: "list-head-Val",
            cellClassName: "list-row-Val",
            align: "left",
            headerAlign: "left",
        },
        {
            field: 'orderInfo',
            headerName: 'Order Info',
            width: 300,
            sortable: false,
            headerClassName: "list-head-Val",
            cellClassName: "list-row-Val",
            align: "left",
            headerAlign: "left",
            valueGetter: (value, row) => `${getOrderInfo(row)}`,
        },
        {
            field: 'or',
            headerName: 'order Status',
            width: 320,
            sortable: false,
            headerClassName: "list-head-Val",
            cellClassName: "list-row-Val",
            align: "left",
            headerAlign: "left",
            renderCell: (cellValue) => {
                return (
                    getOrderActions(cellValue.row) === 0 ?
                        (
                            <div className="or-action">
                                {getorderState(cellValue.row)}
                            </div>
                        )
                        : getOrderActions(cellValue.row) === 1 ?
                            (
                                <div className="or-action">
                                    <button
                                        className="buy-btn-profile"
                                        type="button"
                                        onClick={() => {
                                            confirmButtonClick(cellValue.row)
                                        }}
                                    >
                                        {"Confirm"}
                                    </button>
                                </div>
                            )
                            :
                            (
                                <div className="or-action">
                                    <div style={{ display: "flex", flexDirection: "row", marginTop: 20, marginLeft: 80 }}>
                                        <button
                                            className="buy-btn-profile"
                                            type="button"
                                            onClick={() => {
                                                confirmButtonClick(cellValue.row)
                                            }}
                                        >
                                            {"Confirm"}
                                        </button>
                                        <button
                                            className="buy-btn-profile"
                                            style={{ marginLeft: 10 }}
                                            type="button"
                                            onClick={() => {
                                                declineButtonClick(cellValue.row)
                                            }}
                                        >
                                            {"Decline"}
                                        </button>
                                    </div>
                                </div>
                            )
                )
            }
        },
    ];

    const getProductSubTypeName = (productType, productSubType) => {
        if (productType === "MobileDevice") {
            if (deviceTypeList && deviceTypeList.length > 0) {
                let devices = deviceTypeList.filter(device => device.id == productSubType);
                if (devices && devices.length > 0) {
                    return devices[0].brand + " " + devices[0].manufacturer
                }
            }

        }
        return productSubType;
    }

    const getorderState = (order) => {
        let orderState = "";
        if (order && order.orderStatus) {
            orderState = order.orderStatus;
        }
        return orderState;
    }

    const getOrderInfo = (order) => {
        let orderInfo = "";
        if (order && order.orderingInfo) {
            let orderInfoJson = order.orderingInfo;
            if (orderInfoJson && orderInfoJson.noOfSubscribers) {
                if (order.products && order.products.productJson && order.products.productJson.numDays) {
                    orderInfo = "Subscribers: " + orderInfoJson.noOfSubscribers + "  Days: " + order.products.productJson.numDays;
                } else {
                    orderInfo = "Subscribers: " + orderInfoJson.noOfSubscribers;
                }
            } else if (orderInfoJson && orderInfoJson.noOfDays) {
                orderInfo = "Days: " + orderInfoJson.noOfDays;
            } else if (orderInfoJson && orderInfoJson.noOfUnits) {
                orderInfo = "Quantity: " + orderInfoJson.noOfUnits;
            }
        }
        return orderInfo;
    }

    const getOrderActions = (order) => {
        let orderAction = 0;
        if (order && order.orderStatus) {
            if (order.orderStatus === "Confirmed" ||
                order.orderStatus === "Partially_Executed" || order.orderStatus === "Execution_failed") {
                orderAction = 1;
            } else if (order.orderStatus === "Created") {
                orderAction = 2;
            }
        }
        return orderAction;
    }

    const confirmButtonClick = (order) => {
        console.log("order to confirm ", order);
        if (order.products && order.products.productType === "MobileDevice") {
            setOrder(order);
            SetDeviceId(order.products.productSubType);
            setOpenModal(true);
        } else {
            if (order.products && order.products.priceType !== "Prepaid") {
                //order execution for postpaid plan // numBillingCycle
                setOrder(order);
                setOpenPostpaidModal(true);
            } else {
                let finalOrderStatus = order.orderStatus === "Created" ? "Confirmed" : "Executed";
                let orderInfo = {
                    "orderId": order.id,
                    "newOrderStatus": finalOrderStatus,
                    "orderExecutionInfo": {
                    },
                    "sellerMTMId": order.sellerMTMId ? order.sellerMTMId.mtmId ? order.sellerMTMId.mtmId : order.sellerMTMId : null
                }
                console.log("orderInfo prepaid", JSON.stringify(orderInfo));
                updateOrderForProduct(orderInfo);
                if (refreshSalesAfterConfirmingOrder && !order.sellerMTMId) {
                    refreshSalesAfterConfirmingOrder();
                }
            }
        }

        //"ReasonForDeclining"
        //"numBillingCycle" "Executed" "Confirmed"
    }

    const declineButtonClick = (order) => {
        let orderInfo = {
            "orderId": order.id,
            "newOrderStatus": "Declined",
            "orderExecutionInfo": {
            },
            "sellerMTMId": order.sellerMTMId ? order.sellerMTMId.mtmId ? order.sellerMTMId.mtmId : order.sellerMTMId : null
        }
        console.log("orderInfo decline", JSON.stringify(orderInfo));
        updateOrderForProduct(orderInfo);
        if (refreshSalesAfterConfirmingOrder && !order.sellerMTMId) {
            refreshSalesAfterConfirmingOrder();
        }
    }

    const getSelectedConfigDevice = (selectedDevice) => {
        if (selectedOrder && selectedDevice) {
            let finalOrderStatus = selectedOrder.orderStatus === "Created" ? "Confirmed" : "Executed";
            let orderInfo = {
                "orderId": selectedOrder.id,
                "newOrderStatus": finalOrderStatus,
                "orderExecutionInfo": {
                    configurationId: selectedDevice
                },
                "sellerMTMId": selectedOrder.sellerMTMId ? selectedOrder.sellerMTMId.mtmId ? selectedOrder.sellerMTMId.mtmId : selectedOrder.sellerMTMId : null
            }
            console.log("orderInfo", JSON.stringify(orderInfo));
            updateOrderForProduct(orderInfo);
            if (refreshSalesAfterConfirmingOrder && !selectedOrder.sellerMTMId) {
                refreshSalesAfterConfirmingOrder();
            }
        }
        setOrder(null);
        SetDeviceId(null);
        setOpenModal(false);
        restConfigDevices([]);
    }

    const getBillingCycle = (billingCycle) => {
        if (selectedOrder) {
            let finalOrderStatus = selectedOrder.orderStatus === "Created" ? "Confirmed" : "Executed";
            let orderInfo = {
                "orderId": selectedOrder.id,
                "newOrderStatus": finalOrderStatus,
                "orderExecutionInfo": {
                    "numBillingCycle": billingCycle
                },
                "sellerMTMId": selectedOrder.sellerMTMId ? selectedOrder.sellerMTMId.mtmId ? selectedOrder.sellerMTMId.mtmId : selectedOrder.sellerMTMId : null
            }
            console.log("orderInfo postpaid", JSON.stringify(orderInfo));
            updateOrderForProduct(orderInfo);
            if (refreshSalesAfterConfirmingOrder && !selectedOrder.sellerMTMId) {
                refreshSalesAfterConfirmingOrder();
            }
        }
        setOrder(null);
        setOpenPostpaidModal(false);
    }

    const hideOrCloseModal = () => {
        setOrder(null);
        SetDeviceId(null);
        setOpenModal(false);
        restConfigDevices([]);
        setOpenErrorOrder(false);
        resetOrderRejectedCauses([]);
        setOpenPostpaidModal(false);
    }

    return (
        <div style={{ height: 900 }}>
            <DataGrid
                rows={orders.data ? orders.data : []}
                columns={columns}
                rowHeight={68}
                rowCount={orders.totalItems}
                // hideFooter
                pagination
                pageSizeOptions={[10, 20, 25]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                disableColumnMenu
                paginationMode="server"
                localeText={{ noRowsLabel: "" }}
                getRowSpacing={params => ({
                    top: params.isFirstVisible ? 0 : 0,
                    bottom: params.isLastVisible ? 0 : 0
                })}

                sx={{
                    boxShadow: 2,
                    // border: 2,
                    // borderColor: 'primary.light',
                    border: "none",
                    borderBottom: "none",
                    '& .MuiDataGrid-cell:hover': {
                        color: '#ffb01f',
                    },
                    '& .MuiDataGrid': {
                        border: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        borderColor: "#404040",
                    },
                    '& .MuiDataGrid-columnHeader': {
                        borderColor: "gray",
                    },
                    "& .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel": {
                        color: "white"
                    },
                    "& svg:not(:root)": {
                        color: 'white'
                    },
                    "& .css-levciy-MuiTablePagination-displayedRows": {
                        color: 'white'
                    },
                    '& .MuiDataGrid-filler': {
                        backgroundColor: "#262626",
                    },
                }}

            />
            <Modal
                show={openModal}
                onHide={hideOrCloseModal}
                scrollable={false}
                size={"lg"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ backgroundColor: " rgba(0,0,0,0.5)" }}
            >
                <Modal.Header
                    closeButton
                    style={{ border: "0px", backgroundColor: "#282828" }}
                >
                    <Modal.Title>Oder Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "0.2px", backgroundColor: "#252524" }}>
                    <ExecuteOrder id={deviceId} getSelectedConfigDevice={getSelectedConfigDevice}></ExecuteOrder>
                </Modal.Body>
            </Modal>

            <Modal
                show={openPostpaidModal}
                onHide={hideOrCloseModal}
                scrollable={false}
                size={"lg"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ backgroundColor: " rgba(0,0,0,0.5)" }}
            >
                <Modal.Header
                    closeButton
                    style={{ border: "0px", backgroundColor: "#282828" }}
                >
                    <Modal.Title>Oder Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "0.2px", backgroundColor: "#252524" }}>
                    <PostpaidOrder order={selectedOrder} getBillingCycle={getBillingCycle}></PostpaidOrder>
                </Modal.Body>
            </Modal>

            <Modal
                show={openErrorOrder}
                onHide={hideOrCloseModal}
                scrollable={false}
                size={"lg"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ backgroundColor: " rgba(0,0,0,0.5)" }}
            >
                <Modal.Header
                    closeButton
                    style={{ border: "0px", backgroundColor: "#282828" }}
                >
                    <Modal.Title>Rejection Order Causes</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "0.2px", backgroundColor: "#252524" }}>
                    <RejectOrderCauses errorTuples={errorTuples}></RejectOrderCauses>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const mapStateToProps = ({ mdm, productcatalogstate }) => {
    const { deviceTypeList } = mdm;
    const { errorTuples } = productcatalogstate;
    return {
        deviceTypeList,
        errorTuples
    };
};

export default connect(mapStateToProps, {
    updateOrderForProduct,
    restConfigDevices,
    resetOrderRejectedCauses
})(MySales);
