import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ConfigListRow from "./ConfigListRow";
import "./style.css"
import { fetchConfigList } from "../../modules/actions/mdmAction";
import { makeStyles } from "@material-ui/core/styles";
import FadeInOut from "../../Admin/components/FadeInOut";
import AlertDialog from "../../Admin/components/DeleteUserDialog";
import { DataGrid } from "@mui/x-data-grid";
import AddConfig from "./Modal/AddConfig";
import { deleteConfig } from "../../modules/actions/mdmAction";
const ConfigList = (props) => {
    const { fetchConfigList, tenantId, filteredData, deleteConfig } = props;
    const [show, setShow] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [userName, setUserName] = useState('')
    const [openUserEditMode, setOpenUserEditMode] = useState(false);
    const [selectedId, setSelectedId] = useState('')
    const [selectedData, setSelectedData] = useState([])
    const useStyles = makeStyles((theme) => ({
        root: {
            //backgroundColor: theme.palette.background.paper,
            // paddingTop: 10,
            // paddingLeft: 20,
            // paddingRight: 20,
            width: "100%",
            //height: "70%",
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
        },
        deviceManagementClass: {
            width: "94%",
            height: 800,
            // display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            border: "solid",
            borderRadius: 10,
            borderWidth: "1px",
            borderColor: "#4B4A4A",
            marginLeft: 50,
            //marginTop: 5,
            marginBottom: 10,
            marginTop: 20,
            overflowY: "scroll",
        },
        divList: {
            width: '100%',
            marginBottom: '13px',
        },

    }));

    const classes = useStyles();

    useEffect(() => {
        fetchConfigList({ tenantId })
    }, [])

    const columns = [
        {
            field: 'userIcon',
            headerName: 'Icon',
            width: 119.5,
            height: 50,
            sortable: false,
            headerClassName: "list-head-Val",
            align: "center",
            headerAlign: "center",
            renderCell: (cellValue) => {
                return (
                    <div class="tr-tb-icon">
                        <i className={"feather icon-settings f-32"} style={{ lineHeight: "50%", color: 'whitesmoke' }} />
                    </div>
                )
            }
        },
        {
            field: 'name', headerName: 'Name', width: 350, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
        },
        {
            field: 'description', headerName: 'Description', width: 405, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
        },
        {
            field: 'kioskmode', headerName: 'Kiosk Mode', width: 210, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center",
        },
        {
            field: 'pushoptions', headerName: 'Push Notification', width: 310, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center",
        },
        {
            field: 'brand', headerName: 'Device Type', width: 310, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center",
        },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 71,
            height: 50,
            sortable: false,
            headerClassName: "list-head-Val",
            align: "center",
            headerAlign: "center",
            renderCell: (cellValue) => {
                return (
                    <button
                        class="editBtn"
                        onClick={() => { openUserEditPage(cellValue.row) }}
                        type="button"
                        name=""
                    >
                        <img
                            src="/assets/images/editimg.svg"
                            class="edit-user-img"
                            alt=""
                        />
                    </button>
                )
            }
        },
        {
            field: 'delete',
            headerName: 'Delete',
            width: 91,
            height: 50,
            sortable: false,
            headerClassName: "list-head-Val",
            align: "center",
            headerAlign: "center",
            renderCell: (cellValue) => {
                return (
                    <button
                        class="editBtn"
                        onClick={() => { deleteButtonClick(cellValue.row) }}
                        type="button"
                        name=""
                    >
                        <img
                            src="/assets/images/deleteimg.svg"
                            class="delete-user-img"
                            alt=""
                        />
                    </button>
                )
            },
        },
    ];

    const openUserEditPage = (val) => {
        setSelectedData(val)
        setOpenUserEditMode(true);
        setShow(true);
    }

    const deleteButtonClick = (val) => {
        if (val) {
            setDialogOpen(true)
            setUserName(val.name)
            setSelectedId(val.id)
        }
    }

    const deleteDailogHandler = (val) => {
        setDialogOpen(false)
        if (val) {
            deleteConfig({ tenantId, configId: selectedId })
        }

    }

    const deviceManagementHandleClose = () => {
        setShow(false)
    }

    const pushNotification = (pushoptions) => {
        if (pushoptions === "mqttWorker")
            return "MQTT (battery saver)"
        if (pushoptions === "polling")
            return "HTTP Polling"
        if (pushoptions === "mqttAlarm")
            return "MQTT (instant delivery)"
        return ""
    }

    const kioskmode = (kiosk) => {
        return kiosk ? "Enabled" : "Disabled"
    }

    return (
        <div className={classes.root}>
            {show ?
                <div className={classes.deviceManagementClass}>
                    <FadeInOut show={show} duration={500}>
                        {openUserEditMode === false ?
                            <AddConfig setModalOpen={deviceManagementHandleClose} purpose={"add"} />
                            :
                            <AddConfig purpose="edit" data={selectedData} setModalOpen={deviceManagementHandleClose}></AddConfig>
                        }
                    </FadeInOut>
                </div>
                : <DataGrid
                    rows={filteredData}
                    columns={columns}
                    rowHeight={68}
                    hideFooter
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    disableColumnMenu
                    localeText={{ noRowsLabel: "" }}
                    getRowSpacing={params => ({
                        top: params.isFirstVisible ? 0 : 0,
                        bottom: params.isLastVisible ? 0 : 0
                    })}
                    sx={{
                        boxShadow: 2,
                        border: "none",
                        borderBottom: "none",
                        '& .MuiDataGrid-cell:hover': {
                            color: '#ffb01f',
                        },
                        '& .MuiDataGrid': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-cell': {
                            borderColor: "#404040",
                        },
                        '& .MuiDataGrid-columnHeader': {
                            borderColor: "gray",
                        },
                        '& .MuiDataGrid-filler': {
                            backgroundColor: "#262626",
                        },
                    }}

                />
            }
            <AlertDialog open={dialogOpen} deleteDailogHandler={deleteDailogHandler} userName={userName} />
        </div>
        // <>
        //     <div className="config-row-grid-head">
        //         <div className="tr-tb-icon"></div>
        //         <div className="tr-tb-name">
        //             <span>Name</span>
        //         </div>
        //         <div className="tr-tb-desc">
        //             <span>Description</span>
        //         </div>
        //         <div className="tr-tb-kiosk">
        //             <span>Kiosk Mode</span>
        //         </div>
        //         <div className="tr-tb-pushnot">
        //             <span>Push Notification</span>
        //         </div>
        //         <div className="tr-tb-deviceType">
        //             <span>Device Type</span>
        //         </div>
        //         <div class="tr-tb-edit">
        //             <span></span>
        //         </div>
        //         <div class="tr-tb-delete">
        //             <span></span>
        //         </div>
        //     </div>


        //     <div style={{ height: '880px', marginTop: '10px', overflowY: 'scroll' }}>
        //         {filteredData &&
        //             filteredData.map((data, i) => {
        //                 return (
        //                     <ConfigListRow
        //                         key={i}
        //                         data={data}
        //                     // grouplist={grouplist}
        //                     // userData={data}
        //                     // userlist={userlist}
        //                     // orglist={orglist}
        //                     // tetraUser={props.tetraUser}
        //                     // SubscribeType={subscribeType}
        //                     />
        //                 );
        //             })}
        //     </div>
        // </>
    )
}
const mapStateToProps = ({ mdm }) => {
    return {
        tenantId: mdm.tenantId,
        filteredData: mdm.filteredData,
    }
}
export default connect(mapStateToProps, { fetchConfigList, deleteConfig })(ConfigList)