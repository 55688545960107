import {
    FETCH_PARTNERLIST_MTM, UPDATE_PARTNERLIST_MTM, FETCH_TENANTLIST_MTM, UPDATE_TENANTLIST_MTM, CREATE_PARTNERLIST_MTM, DELETE_PARTNERLIST_MTM, CREATE_TENANTLIST_MTM, DELETE_TENANTLIST_MTM, UPDATE_PARTNER_MTM,
    UPDATE_TENANT_MTM, CREATE_USERONIDMSANDSERVER_MTM, UPDATE_USER_ON_IDMS_AND_SERVER,
    DELETE_USERONIDMSANDSERVER_MTM, UPDATE_USER_ON_IDMS_AND_SERVER_PARTNER,
    FETCH_UETYPELIST_MTM, UPDATE_UETYPE_MTM, FETCH_AVAILABLESERVICES_MTM,
    UPDATE_AVAILABLE_SERVICES_MTM, FETCH_MASTERSERVICESCONFIG_MTM,
    UPDATE_MASTERSERVICESCONFIG_MTM, RESET_AVAILABLE_SERVICE
} from "./actions/type";


const initialState = {
    partnerList: [],
    tenantList: [],
    tenantAdmin: false,
    partnerAdmin: false,
    ueTypeList: [],
    availableServices: [],
    serviceTreeConfig: {},
    serviceUeTypeConfig: {},
    iwfMappedDomainConfig: []
}

export default (state = initialState, action) => {
    const fetchedData = action.data;
    switch (action.type) {
        case UPDATE_PARTNER_MTM:
            return {
                ...state,
                partnerList: fetchedData,
            }
        case UPDATE_MASTERSERVICESCONFIG_MTM:
            return {
                ...state,
                serviceTreeConfig: fetchedData["serviceTreeConfig"],
                serviceUeTypeConfig: fetchedData["serviceUeTypeConfig"],
                iwfMappedDomainConfig: fetchedData["iwfConfig"]
            }
        case UPDATE_UETYPE_MTM:
            return {
                ...state,
                ueTypeList: fetchedData,
            }
        case UPDATE_AVAILABLE_SERVICES_MTM:
            return {
                ...state,
                availableServices: fetchedData,
            }
        case UPDATE_TENANT_MTM:
            return {
                ...state,
                tenantList: fetchedData,
            }
        case UPDATE_USER_ON_IDMS_AND_SERVER:
            return {
                ...state,
                tenantAdmin: fetchedData
            }
        case UPDATE_USER_ON_IDMS_AND_SERVER_PARTNER:
            return {
                ...state,
                partnerAdmin: fetchedData
            }
        case RESET_AVAILABLE_SERVICE:
            return {
                ...state,
                availableServices: []
            }
        default:
            return state
    }

}

const actions = {
    fetchPartnerListMtm: () => ({ type: FETCH_PARTNERLIST_MTM }),
    fetchMasterConfigServiceConfig: () => ({ type: FETCH_MASTERSERVICESCONFIG_MTM }),
    fetchUeTypeListMtm: (data) => ({ type: FETCH_UETYPELIST_MTM, data }),
    getAvailableServices: (data) => ({ type: FETCH_AVAILABLESERVICES_MTM, data }),
    createPartnerListMtm: (data) => ({ type: CREATE_PARTNERLIST_MTM, data }),
    updatePartnerListMtm: (data) => ({ type: UPDATE_PARTNERLIST_MTM, data }),
    deletePartnerListMtm: (data, userType) => ({ type: DELETE_PARTNERLIST_MTM, data, userType }),
    fetchTenantListMtm: () => ({ type: FETCH_TENANTLIST_MTM }),
    createTenantListMtm: (data) => ({ type: CREATE_TENANTLIST_MTM, data }),
    createUserOnIdmsAndServer: (data, userType) => ({ type: CREATE_USERONIDMSANDSERVER_MTM, data, userType }),
    updateTenantListMtm: (data) => ({ type: UPDATE_TENANTLIST_MTM, data }),
    resetAvailService: () => ({ type: RESET_AVAILABLE_SERVICE }),
    deleteTenantListMtm: (data, userType) => ({ type: DELETE_TENANTLIST_MTM, data, userType }),

}

export const fetchPartnerListMtm = () => {
    return dispatch => {
        dispatch(actions.fetchPartnerListMtm())
    }
}
export const fetchMasterConfigServiceConfig = () => {
    return dispatch => {
        dispatch(actions.fetchMasterConfigServiceConfig())
    }
}
export const fetchUeTypeListMtm = (data) => {
    return dispatch => {
        dispatch(actions.fetchUeTypeListMtm(data))
    }
}
export const getAvailableServices = (data) => {
    return dispatch => {
        dispatch(actions.getAvailableServices(data))
    }
}
export const createPartnerListMtm = (data) => {
    return dispatch => {
        dispatch(actions.createPartnerListMtm(data))
    }
}
export const updatePartnerListMtm = (data) => {
    return dispatch => {
        dispatch(actions.updatePartnerListMtm(data))
    }
}
export const deletePartnerListMtm = (data, userType) => {
    return dispatch => {
        dispatch(actions.deletePartnerListMtm(data, userType))
    }
}

export const fetchTenantListMtm = () => {
    return dispatch => {
        dispatch(actions.fetchTenantListMtm())
    }
}
export const createTenantListMtm = (data) => {
    return dispatch => {
        dispatch(actions.createTenantListMtm(data))
    }
}
export const createUserOnIdmsAndServer = (data, userType) => {
    return dispatch => {
        dispatch(actions.createUserOnIdmsAndServer(data, userType))
    }
}
export const updateTenantListMtm = (data) => {
    return dispatch => {
        dispatch(actions.updateTenantListMtm(data))
    }
}
export const deleteTenantListMtm = (data, userType) => {
    return dispatch => {
        dispatch(actions.deleteTenantListMtm(data, userType))
    }
}

export const resetAvlService = () => {
    return dispatch => {
        dispatch(actions.resetAvailService());
    }
}
