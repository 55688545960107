(function (exports) {
  const DOMParser = require("xmldom").DOMParser;
  const js2xmlparser = require("js2xmlparser");
  const parser = new DOMParser();
  const defaultPriority = 5; //to be added in config
  let domain = "";
  const GroupXMLProcessor = {
    createXML(data) {
      //var xml;
      if (data.domain.mcxDomain !== "") {
        domain = data.domain.mcxDomain
      } 
      var docData = groupDoc;
      docData.group["list-service"][
        "@"
      ].uri = data.basicinfo.groupId.includes("@")
          ? data.basicinfo.groupId
          : `${data.basicinfo.groupId}@${domain}`;
      docData.group["list-service"]["display-name"]["#"] =
        data.basicinfo.groupname;
      docData.group["list-service"]["mcpttgi:on-network-group-priority"] =
        data.basicinfo.priority;
      docData.group["list-service"]["organization-id"]["#"] =
        data.basicinfo.orgId;
      docData.group["list-service"].list.entry = getMembersList(data.members);
      docData.group["list-service"]["on-network-hang-timer"] =
        data.mcpttConfig.hangTimer;
      docData.group["list-service"]["group-type"] = data.mcpttConfig.groupType;
      docData.group["list-service"]["members-type"] = data.basicinfo.groupType;
      docData.group["list-service"]["source"] = data.basicinfo.source;
      docData.group["list-service"]["supervisorId"] = data.supervisorId;
      const res = js2xmlparser.parse("group", docData["group"]);
      return res;
    },

    updateXML(data) {
      if (data.domain.mcxDomain !== "") {
        domain = data.domain.mcxDomain
      } 
      var docData = groupDoc;
      docData.group["list-service"][
        "@"
      ].uri = data.basicinfo.groupId.includes("@")
          ? data.basicinfo.groupId
          : `${data.basicinfo.groupId}@${domain}`;
      docData.group["list-service"]["display-name"]["#"] =
        data.basicinfo.groupname;
      docData.group["list-service"]["mcpttgi:on-network-group-priority"] =
        data.basicinfo.priority;
      docData.group["list-service"]["organization-id"]["#"] =
        data.basicinfo.orgId;
      docData.group["list-service"].list.entry = getMembersList(data.members);
      docData.group["list-service"]["on-network-hang-timer"] =
        data.mcpttConfig.hangTimer;
      docData.group["list-service"]["group-type"] = data.mcpttConfig.groupType;
      docData.group["list-service"]["members-type"] = data.basicinfo.groupType;
      docData.group["list-service"]["source"] = data.basicinfo.source;
      docData.group["list-service"]["supervisorId"] = data.supervisorId;
      let updateData = updateDoc;
      updateData["gmop:request"].group = docData.group;
      console.log("updateGroup api " + JSON.stringify(updateData));
      const res = js2xmlparser.parse(
        "gmop:request",
        updateData["gmop:request"]
      );
      return res;
    },

    processXML(data) {
      var xml = parser.parseFromString(data.toString());
      const list = xml.getElementsByTagName("entry");
      //console.log('List is: ', list);
      var cont = [];
      try {
        for (let i = 0; i < list.length; i++) {
          let element = list.item(i);
          //console.log("Element in list is: ",element);
          let uri = element.getAttribute("uri");
          let name = element.getElementsByTagName("rl:display-name");
          cont = [...cont, { userName: name, mcptt_id: uri }];
        }
      } catch (e) {
        console.log("Error creating map user group list ", e);
      }

      return {
        basicinfo: {
          groupname: xml.getElementsByTagName("display-name").item(0)
            .textContent,
          priority: xml
            .getElementsByTagName("mcpttgi:on-network-group-priority")
            .item(0).textContent,
          orgId: xml.getElementsByTagName("organization-id").item(0)
            .textContent,
        },
        members: cont,
        mcpttConfig: {
          groupType: xml.getElementsByTagName("group-type").item(0).textContent,
          hangTimer: {
            hour: xml.getElementsByTagName("hour").item(0).textContent,
            min: xml.getElementsByTagName("min").item(0).textContent,
            sec: xml.getElementsByTagName("sec").item(0).textContent,
          },
        },
      };
    },
  };

  const updateDoc = {
    "gmop:request": {
      group: {},
    },
  };

  const groupDoc = {
    group: {
      "@": {
        xmlns: "urn:oma:xml:poc:list-service",
        "xmlns:rl": "urn:ietf:params:xml:ns:resource-lists",
        "xmlns:cp": "urn:ietf:params:xml:ns:common-policy",
        "xmlns:ocp": "urn:oma:xml:xdm:common-policy",
        "xmlns:oxe": "urn:oma:xml:xdm:extensions",
        "xmlns:mcpttgi": "urn:3gpp:ns:mcpttGroupInfo:1.0",
      },
      "list-service": {
        "@": {
          uri: "",
        },
        "display-name": {
          "@": {
            "xml:lang": "en-us",
          },
          "#": "",
        },
        source: "MCX",
        "organization-id": {
          "@": {
            "xml:lang": "en-us",
          },
          "#": "",
        },
        list: {
          entry: {
            "@": {
              uri: "",
            },
            "rl:display-name": "",
            "mcpttgi:on-network-required": "",
            "mcpttgi:user-priority": "",
          },
        },
        "mcpttgi:on-network-invite-members": true,
        "mcpttgi:on-network-max-participant-count": 1,
        "cp:ruleset": {
          "cp:rule": {
            "@": {
              id: "",
            },
            "cp:conditions": {
              "is-list-member": "",
            },
            "cp:actions": {
              "allow-initiate-conference": true,
              "join-handling": true,
              "mcpttgi:allow-MCPTT-emergency-call": true,
              "mcpttgi:allow-imminent-peril-call": true,
              "mcpttgi:allow-MCPTT-emergency-alert": true,
            },
          },
        },
        "oxe:supported-services": {
          "oxe:service": {
            "@": {
              enabler: "urn:urn-7:3gpp-service.ims.icsi.mcptt",
            },
            "oxe:group-media": {
              "mcpttgi:mcptt-speech": "",
            },
          },
        },
        "mcpttgi:on-network-disabled": "",
        "mcpttgi:on-network-group-priority": 1,
        "on-network-hang-timer": "",
      },
    },
  };

  function getMembersList(list) {
    var mList = [];
    list.forEach((element, i) => {
      mList[i] = {
        "@": {
          uri: `${element.mcptt_id}-mcx@consort.org`,
        },
        "rl:display-name": `${element.userName}`,
        "mcpttgi:on-network-required": true,
        "mcpttgi:user-priority": defaultPriority,
        "mcpttgi:tetra-id": element.mcptt_id,
      };
    });

    if (list.length == 0) {
      mList[0] = {}
    }

    return mList;
  }

  exports.GroupXMLProcessor = GroupXMLProcessor;
})(typeof exports === "undefined" ? (this["GroupXMLProcessor"] = {}) : exports);
