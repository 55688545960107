import * as React from 'react';
import { TextField, Button, Box, FormLabel,FormControl, FormControlLabel, Radio, RadioGroup,Lin } from '@material-ui/core'
import { propTypes } from 'react-bootstrap/esm/Image';


import dayjs from 'dayjs';
import DateTimePicker from 'react-datetime-picker'
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
export default function AskForCustomTime(props) {
    

    const [startvalue, setstartValue] = React.useState(new Date());
    const [endvalue, setendValue] = React.useState();

    const onStartClicked = () =>{
        const timeobj={
            start:startvalue.getTime(),
            end:endvalue.getTime()
        }
        props.setendstarttime(timeobj)

    }
 

  return (
    <Box sx={style}>
        <div>
  
  <FormLabel component="legend">Select Time Range</FormLabel>
  <div className='flex justify-content-center my-3'> 
  <h2 className=' my-3'>Start</h2>
  <DateTimePicker calendarClassName="react-calendar" className="date-picker-style ml-25"  onChange={setstartValue} value={startvalue} />

  </div>

  <div className='flex justify-content-center my-3'> 
  <h2 className=' my-3'>End</h2>
  <DateTimePicker calendarClassName="react-calendar" className="date-picker-style ml-25"  onChange={setendValue} value={endvalue} />

  </div>

  <button
                class="update-btn-profile"
                type="button"
                onClick={onStartClicked}
            >
                Show
            </button>
  </div>

      </Box>
  );
}
