import React, { useState, useEffect } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { createTheme } from "@material-ui/core/styles";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import { addFAAdmin, updateFAAdmin, fetchFAAdminDetail, fetchFAListAdmin, getIWFMapByFa, resetIWFMapByFa } from '../../modules/adminstate';
import { connect } from "react-redux";
import IwFMapView from "./AdminWidget/AddIwfMap/IwFMapView";
import { Box, Grid, Typography } from '@mui/material';

const FATemplates = (props) => {
    const DefaultInfoData = {
        id: "",
        type: "",
        fa: "",
        mcpttId: "",
        fa_mcpttid: "FA",
        errors: {}
    };
    const { addFAAdmin, fetchFAAdminDetail, faProfileDetail, fetchFAListAdmin, configDomain, getIWFMapByFa, faIWFMap, updateFAAdmin, iwfMaplist, resetIWFMapByFa, propsTetra } = props
    const [faDetails, setFADetails] = useState({
        uri: '',
        description: '',
        tetra: false,
        iwf: DefaultInfoData
    })

    const [btnDisabled, setBtnDisabled] = useState(true)
    const [btnText, setBtnText] = useState('SUBMIT')
    const [profileName, setProfileName] = useState(null)
    const [emptyURI, setEmptyURI] = useState(false)
    const [emptyDescription, setEmptyDescription] = useState(false)
    const [tetraDisable, settetraDisable] = useState(false)

    useEffect(() => {
        resetIWFMapByFa()
        if (props.faName) {
            fetchFAAdminDetail(props.faName)
            setTimeout(() => {
                getIWFMapByFa(props.faName)
            }, 50);
        }
        else {
            let ttUser = false
            if (configDomain && configDomain.iwfs && configDomain.iwfs.length > 0 && configDomain.mcxDomain && configDomain.mcxDomain !== "") {
                // Enable tetra in this case
                console.log("tetra domain check running ", " COND 1")
            }
            else {
                console.log("tetra domain check running ", " else")
                settetraDisable(true)
                if (configDomain && configDomain.iwfs && configDomain.iwfs.length > 0) {
                    ttUser = true
                    console.log("tetra domain check running ", " COND 2")
                }
                else if (configDomain.mcxDomain && configDomain.mcxDomain !== "") {
                    console.log("tetra domain check running ", " COND 3")
                    ttUser = false
                }
            }

            let faupdate = {
                ...faDetails,
                tetra: ttUser
            }
            console.log("tetra domain setting ", faupdate)
            setFADetails(faupdate)
        }
    }, [])
    useEffect(() => {
        console.log("FA faIWFMap", faIWFMap)
        if (props.faName && faIWFMap && faIWFMap.id) {
            // iwfMapHandler(faIWFMap)
            if (faProfileDetail && faProfileDetail.faList) {
                let fa = { ...faProfileDetail.faList, iwf: faIWFMap }
                setFADetails(fa)
            }
        }
    }, [faIWFMap])

    useEffect(() => {
        console.log('faProfileDetail', faProfileDetail)
        if (faProfileDetail && faProfileDetail.faList) {
            setProfileName(faProfileDetail.profileName)
            //let fa = { ...faProfileDetail.faList, iwf: faIWFMap }
            //setFADetails(fa)
            if (faIWFMap && faIWFMap.id) {
                let fa = { ...faProfileDetail.faList, iwf: faIWFMap }
                setFADetails(fa)
            }
            setBtnDisabled(false)
            setBtnText('Update')
        }
    }, [faProfileDetail])

    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: "#252524",
            //backgroundColor: theme.palette.background.default,
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            width: "100%",
            //height: "70%",
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
        },
        formControlFA: {
            width: "60%",
            height: "65px",
            marginTop: "2px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600,
            backgroundColor: "#383736"
        },
        listItemFA: {
            padding: "5px",
            fontFamily: "Muli",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        productTypeClass: {
            width: "90%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            border: "solid",
            borderRadius: 10,
            borderWidth: "2px",
            borderColor: "#4B4A4A",
            marginTop: 5,
            marginBottom: 10
        },
        divList: {
            width: '100%',
            marginBottom: '13px',
        },
        lblStyle: {
            width: '100%',
            color: "#D0D3D4",
            fontSize: 18,
            fontWeight: 600,
            fontFamily: "Muli"
        },
        tetraSwitch: {
            fontFamily: "Muli",
            marginTop: "2px",
            marginRight: "1px",
            marginLeft: "0px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        datePickerStyle: {
            color: "black",
            padding: "10px",
            fontFamily: "Sans-Serif",
            width: '60%',
            border: "solid",
            borderRadius: 20,
            borderWidth: "2px",
            borderColor: "#D3D3D3",
        }
    }));

    const theme = createTheme({
        // palette: {
        //   primary: {
        //     main: "#006400",
        //   },
        //   secondary: {
        //     main: "#fdce2a",
        //   },
        // },
        overrides: {
            MuiSwitch: {
                switchBase: {
                    "&.Mui-disabled + .MuiSwitch-track": {
                        opacity: 0.65
                    },
                    "&.Mui-checked + .MuiSwitch-track": {
                        opacity: 0.65
                    },
                },
                track: {
                    backgroundColor: "gray",
                },
                colorPrimary: {
                    "&.Mui-disabled + .MuiSwitch-track": {
                        backgroundColor: "#4CAF50",
                    },
                    "&.Mui-checked + .MuiSwitch-track": {
                        backgroundColor: "#4CAF50",
                    }
                }
            },
        }
    });

    const tetraTrueFalse = (e) => {
        let faupdate = {
            ...faDetails,
            tetra: e.target.checked
        }
        setFADetails(faupdate)
    };

    const iwfMapHandler = (value) => {
        let faupdate = {
            ...faDetails,
            iwf: value
        }
        setFADetails(faupdate)
    };

    const descChangeHandler = (e) => {
        let faupdate = {
            ...faDetails,
            description: e.target.value
        }
        setFADetails(faupdate)
        if (e.target.value.length > 0) {
            setBtnDisabled(false)
            setEmptyDescription(false)
        } else {
            setBtnDisabled(true)
            setEmptyDescription(true)
        }
    };

    const updateFA = () => {
        let errors = {}
        console.log('iwfMaplist........', iwfMaplist)
        if (faDetails.tetra && !faDetails.iwf.id) {
            errors["id"] = "Please enter iwf id"
            setFADetails({
                ...faDetails,
                iwf: { ...faDetails.iwf, errors: errors, }
            })
        }
        else if (faDetails.tetra && !(props.faName) && iwfMaplist && iwfMaplist.some((obj) => obj.id === faDetails.iwf.id)) {
            errors["id"] = "SSI already exits"
            setFADetails({
                ...faDetails,
                iwf: { ...faDetails.iwf, errors: errors, }
            })
        }
        else if (faDetails.tetra && (!faDetails.iwf.type || (faDetails.iwf.type && faDetails.iwf.type.length === 0))) {
            // e.preventDefault()
            errors["type"] = "Please select type"
            setFADetails({
                ...faDetails,
                iwf: { ...faDetails.iwf, errors: errors, }
            })
        }
        else if (faDetails.description.length === 0) {
            setEmptyDescription(true)
        }
        else {
            let profile = {}
            if (profileName) {
                profile['profileName'] = profileName
            } else {
                let profileNameL = faDetails.description.replace(/\s+/g, '').toLocaleUpperCase()
                profile['profileName'] = profileNameL
            }
            profile['CallerDescr'] = faDetails.description
            //mcpttID
            profile['faList'] = { ...faDetails }
            let mappedDomain = null
            if (configDomain && configDomain.iwfs && configDomain.iwfs.length > 0) {
                for (let index = 0; index < configDomain.iwfs.length; index++) {
                    const element = configDomain.iwfs[index];
                    if (faDetails && faDetails.iwf && faDetails.iwf.type) {
                        if (element.type && element.type.toLocaleUpperCase() == faDetails.iwf.type.toLocaleUpperCase()) {
                            mappedDomain = element.mappedDomain
                        }
                    }
                }
                if (mappedDomain) {
                    profile = { ...profile, domain: mappedDomain }
                } else {
                    profile = { ...profile, domain: configDomain.mcxDomain ? configDomain.mcxDomain : '' }
                }
            } else {
                profile = { ...profile, domain: configDomain.mcxDomain ? configDomain.mcxDomain : '' }
            }
            profile['tetra'] = faDetails.tetra
            profile['faList'] = { ...faDetails, uri: profile['profileName'] + "@" + profile.domain }
            if (global.config.userConfig.multiTenantFeature) {
                !profile['profileName'].includes("@") && (profile["profileName"] = profile['profileName'] + "@" + profile.domain)
            }
            if (faDetails.tetra === true) {
                profile['faList'] = {
                    ...faDetails
                    , uri: profile['profileName'] + "@" + profile.domain,
                    iwf: { ...faDetails.iwf, fa: global.config.userConfig.multiTenantFeature ? (!profile['profileName'].includes("@") && profile['profileName'] + "@" + profile.domain) : profile['profileName'], edit: propsTetra === faDetails.tetra }
                }
            }
            console.log("SENDING FA REQ", JSON.stringify(profile))
            addFAAdmin(profile)
            setTimeout(() => {
                fetchFAListAdmin()
            }, 1000);
            resetIWFMapByFa()
            props.hideModal()
        }
    }

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <ThemeProvider theme={theme}>
                <Grid container width="100%" spacing={2}>
                    <Grid item xs={12}>
                        <Box className="fa-template-uri">
                            {/* <FormControlLabel className={classes.tetraSwitch}
                            value="start"
                            control={<Switch color='primary' checked={faDetails.tetra} onChange={tetraTrueFalse}/>}
                            label="Iwf"
                            labelPlacement='start'
                            disabled={props.faName ? props.faName : tetraDisable}
                            onChange={tetraTrueFalse}
                        /> */}
                            <Box display={"flex"} width={"20%"} marginLeft={1} marginBottom={1} marginTop={1}>
                                <Box flex={0.15} mr="0.25em" marginTop={2.1} marginBottom={1}>
                                    <label className={classes.lblStyle}>{"Iwf"}</label>
                                </Box>
                                <Box flex={1} mr="0.25em" marginTop={1} marginBottom={1}>
                                    <FormControlLabel
                                        className={classes.tetraSwitch}
                                        value="start"
                                        control={
                                            <Switch
                                                color="primary"
                                                checked={faDetails.tetra}
                                                onChange={tetraTrueFalse}
                                            />
                                        }
                                        labelPlacement="start"
                                        onChange={tetraTrueFalse}
                                        disabled={props.faName ? props.faName : tetraDisable}
                                    />
                                </Box>
                            </Box>
                            {
                                faDetails.tetra === true ?
                                    (
                                        <IwFMapView class="input-control" fromFA={true} iwfMapHandler={iwfMapHandler} infoData={faDetails.iwf} purpose={(propsTetra === faDetails.tetra) ? 'edit' : 'create'} mappedFilter={true} hideIwfIdView={false}></IwFMapView>
                                    )
                                    :
                                    null
                            }
                            <Box flex={0.15} mr="0.5em" marginTop={0.5} marginBottom={1}>
                                <label class="attribute-heading-padding" style={{ color: 'whitesmoke' }}>FA Name*</label>
                            </Box>
                            <input
                                type="text"
                                class="input-control-flat"
                                id="description"
                                value={faDetails.description}
                                onChange={descChangeHandler}
                            />
                            {
                                emptyDescription === true ?
                                    (
                                        <label class="error-handling-lbl">Please enter FA name</label>
                                    )
                                    :
                                    null
                            }
                        </Box>
                        <Box display={"flex"} flexDirection={"row-reverse"} marginTop={2} width={"100%"}>
                            <div className="form-group">
                                <button
                                    class="update-btn-profile"
                                    type="button"
                                    onClick={updateFA}
                                >
                                    {btnText}
                                </button>
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </div>
    )
}

const mapStateToProps = ({ adminstate, domains }) => {
    const { faProfileDetail, faIWFMap, iwfMaplist } = adminstate;
    //console.log('userlist reducer', userlist)
    const { configDomain } = domains
    return {
        faProfileDetail,
        configDomain,
        faIWFMap,
        iwfMaplist
    };
};

export default connect(mapStateToProps, {
    addFAAdmin,
    updateFAAdmin,
    fetchFAAdminDetail,
    fetchFAListAdmin,
    getIWFMapByFa,
    resetIWFMapByFa
})(FATemplates);
