(function (exports) {
  const Buffer = require("buffer").Buffer;
  const CONSTANTS = {
    encoding_type: "utf8",
    BIT_MAX: 8,
    SIZE: "__size",
    OFFSET_START: "__offset_start",
    OFFSET: "__offset",
    TYPE: "__type",
    FIELDS: "__fields",
    SIZE_FIELD: "__sizeField",
    PADDING: "__padding",
    POST_ENCODE_HANDLER: "__postEncodeHandler",
    POST_DECODE_HANDLER: "__postDecodeHandler",
    PRE_ENCODE_HANDLER: "__preEncodeHandler",
    ALL_PRIMIIVE_TYPES: [
      "bool",
      "uint8",
      "uint16",
      "uint32",
      "int32",
      "string",
      "vstring",
      "vstringx",
      "array_uint8",
      "bit_field",
      "utc",
    ],
    ALL_TYPES_WITH_SIZE: ["string", "array_uint8", "bit_field"],
    DISCRIMINATOR: "__discriminator",
    UNORDERED_SET: "__unorderedSet",
  };

  function readFromBuff(config, readBuffer, offset, fieldSize) {
    let read_data = "";
    let size = 0;
    switch (config[CONSTANTS.TYPE].toLowerCase()) {
      case "bool":
        read_data = readBuffer.readUInt8(offset);
        if (read_data) read_data = true;
        else read_data = false;
        size = 1;
        break;

      case "utc":
        let msb = readBuffer.readUInt8(offset);
        let rem = readBuffer.readUInt32BE(offset + 1);
        read_data = (msb << 32) + rem;
        size = 5;
        break;

      case "uint8":
        read_data = readBuffer.readUInt8(offset);
        // console.log("UInt8", read_data);
        size = 1;
        break;

      case "int8":
        read_data = readBuffer.readInt8(offset);
        // console.log("UInt8", read_data);
        size = 1;
        break;

      case "uint16":
        read_data = readBuffer.readUInt16BE(offset);
        // console.log("UInt16", read_data);
        size = 2;
        break;

      case "int16":
        read_data = readBuffer.readInt16BE(offset);
        // console.log("UInt16", read_data);
        size = 2;
        break;

      case "int32":
        read_data = readBuffer.readInt32BE(offset);
        // console.log("UInt32", read_data);
        size = 4;
        break;

      case "uint32":
        read_data = readBuffer.readUInt32BE(offset);
        // console.log("UInt32", read_data);
        size = 4;
        break;

      case "vstring":
      case "vstringx":
      case "string":
        if (fieldSize) {
          let splitBuffer = readBuffer.slice(offset, offset + fieldSize);
          read_data = splitBuffer.toString();
          size = fieldSize;
        } else {
          read_data = "";
          size = 0;
        }
        break;
      case "bit_field":
        if (!config.hasOwnProperty(CONSTANTS.FIELDS))
          throw new Error("error: " + " doesn't have __fields property");
        let bit_field_data = null;
        if (fieldSize === 1) {
          bit_field_data = readBuffer.readUInt8(offset);
        } else if (fieldSize === 2) {
          bit_field_data = readBuffer.readUInt16BE(offset);
        } else if (fieldSize === 4) {
          bit_field_data = readBuffer.readInt32BE(offset);
        } else {
          throw "Invalid field Size";
        }
        let decodedJson = {};
        for (const [sub_key, sub_value] of Object.entries(
          config[CONSTANTS.FIELDS]
        )) {
          let decodeBit = (value, offsetDecode, sizeDecode) => {
            // let mask = (2 ** sizeDecode - 1) << offsetDecode;
            // return (value & mask) >> offsetDecode;
            let mask =
              (2 ** sizeDecode - 1) <<
              (fieldSize * 8 - offsetDecode - sizeDecode);
            console.log("value mask offsetDecode", value, mask, offsetDecode);
            return (
              (value & mask) >> (fieldSize * 8 - offsetDecode - sizeDecode)
            );
          };
          decodedJson[sub_key] = decodeBit(
            bit_field_data,
            sub_value[CONSTANTS.OFFSET],
            sub_value[CONSTANTS.SIZE]
          );
        }
        read_data = decodedJson;
        size = fieldSize;
        break;
      default:
        //console.log("==== 123")
        break;
    }
    return { data: read_data, size: size };
  }

  function writeToBuff(config, value, buffer, offset, fieldSize) {
    let typeName = config[CONSTANTS.TYPE];
    var write_data = value;
    var stream_buffer = buffer;
    var size = 0;
    console.log("writeToBuff inside .... ", typeName);
    switch (typeName.toLowerCase()) {
      case "bool":
        if (write_data) write_data = 1;
        else write_data = 0;

        stream_buffer.writeUInt8(write_data, offset);
        size = 1;
        break;
      case "uint8":
        //console.log("---------uint8", write_data, offset)
        stream_buffer.writeUInt8(write_data, offset);
        size = 1;
        break;

      case "uint16":
        stream_buffer.writeUInt16BE(write_data, offset);
        size = 2;
        break;

      case "utc":
        let msb = write_data >> 32;
        msb = msb & 0xff;
        let rem = write_data & 0xffffffff;
        stream_buffer.writeUInt8(msb, offset);
        stream_buffer.writeUInt32BE(rem, offset + 1);
        size = 5;
        break;

      case "int32":
      case "uint32":
        //console.log("-----------uint32", write_data, offset);
        stream_buffer.writeUInt32BE(write_data, offset);
        size = 4;
        break;

      case "string":
      case "vstring":
      case "vstringx":
        // Append ox01 for string (Requirement)
        let bufferFromString = Buffer.from(
          write_data,
          CONSTANTS.encoding_type,
          fieldSize
        );
        // console.log(" ---------- *(&*(&*( bufferFromString", append_data, bufferFromString, bufferRequired);
        bufferFromString.copy(stream_buffer, offset);
        size = fieldSize;
        break;

      case "array_uint8":
        let buf1 = Buffer.alloc(fieldSize);
        for (let i = 0; i < fieldSize; i++) {
          buf1.writeUInt8(write_data[i], i);
        }
        buf1.copy(stream_buffer, offset);
        size = fieldSize;
        break;

      case "array_uint16":
        let buf = Buffer.alloc(fieldSize * 2);
        for (let i = 0; i < fieldSize; i++) {
          buf.writeUInt16BE(write_data[i], i * 2);
        }
        buf.copy(stream_buffer, offset);
        size = fieldSize * 2;
        break;

      case "bit_field":
        let write_bit_data = 0;
        if (!config.hasOwnProperty(CONSTANTS.FIELDS))
          throw new Error(
            "error: " + typeName + " doesn't have __fields property"
          );
        for (const [key2, value] of Object.entries(config[CONSTANTS.FIELDS])) {
          console.log(key2, write_data);
          if (!write_data.hasOwnProperty(key2)) {
            continue;
          }
          let data_to_write = write_data[key2];
          if (data_to_write > 2 ** value[CONSTANTS.SIZE]) {
            console.log(`=== value for ${key2} is more than MAX_VALUE_ALLOWED`);
            break;
          }

          write_bit_data =
            write_bit_data |
            (data_to_write <<
              (fieldSize * 8 -
                value[CONSTANTS.OFFSET] -
                value[CONSTANTS.SIZE]));
        }
        let write_bit_array = new Uint8Array(fieldSize).fill(0);
        for (let index = 0; index < write_bit_array.length; index++) {
          let temp = write_bit_data & (0xff << ((fieldSize - index - 1) * 8));
          write_bit_array[index] =
            (temp & write_bit_data) >> ((fieldSize - index - 1) * 8);
        }
        //console.log("==== write_bit_data", write_bit_data);

        //console.log("==== write_bit_data  2", write_bit_data, parseInt(write_bit_data, 2) );
        for (let i = 0; i < fieldSize; i++) {
          stream_buffer.writeUInt8(write_bit_array[i], offset + i);
        }
        size = fieldSize;
        break;
      // case "bit_field":
      //   let write_bit_data = new Array(CONSTANTS.BIT_MAX).fill("0");
      //   if (!config.hasOwnProperty(CONSTANTS.FIELDS))
      //     throw new Error(
      //       "error: " + typeName + " doesn't have __fields property"
      //     );
      //   for (const [key2, value] of Object.entries(config[CONSTANTS.FIELDS])) {
      //     console.log(key2, write_data);
      //     if (!write_data.hasOwnProperty(key2)) {
      //       continue;
      //     }
      //     let data_to_write = write_data[key2];
      //     if (data_to_write > 2 ** value[CONSTANTS.SIZE]) {
      //       console.log(`=== value for ${key2} is more than MAX_VALUE_ALLOWED`);
      //       break;
      //     }
      //     // check if bit size is equal to 1 then the given data value for field should be either 0 or 1
      //     if (value[CONSTANTS.SIZE] == 1) {
      //       write_bit_data[value[CONSTANTS.OFFSET]] = data_to_write.toString();
      //     } else {
      //       //console.log(data_to_write);
      //       let temp_array = data_to_write.toString(2).split("").reverse();
      //       //console.log("==== temp_array", temp_array);
      //       for (let i = 0; i < temp_array.length; i++) {
      //         write_bit_data[value[CONSTANTS.OFFSET] + i] = temp_array[i];
      //       }
      //     }
      //   }
      //   //console.log("==== write_bit_data", write_bit_data);

      //   write_bit_data = write_bit_data.reverse().join("");
      //   //console.log("==== write_bit_data  2", write_bit_data, parseInt(write_bit_data, 2) );

      //   stream_buffer.writeUInt8(
      //     parseInt(write_bit_data, 2),
      //     offset,
      //     fieldSize
      //   );
      //   size = fieldSize;
      //   break;

      default:
        break;
    }
    return size;
  }

  function encode(masterConfig, messageType, data) {
    try {
      /* look up messageType in masterConfig */
      if (
        masterConfig &&
        typeof masterConfig == "object" &&
        !masterConfig.hasOwnProperty(messageType)
      ) {
        console.log(messageType + " is not set for encoding ");
        throw new Error(
          "error: " + messageType + " doesn't exist in masterConfig"
        );
      }

      let config = masterConfig[messageType];
      let stream_buffer = Buffer.alloc(1000).fill(
        0
      ); /* lets start with this and see if we need more */
      var sizeObj = {};
      var offsetObj = {};
      let retval = encodeInternal(
        masterConfig,
        config,
        data,
        stream_buffer,
        0,
        sizeObj,
        offsetObj
      );
      //console.log(retval);
      //console.log(stream_buffer.subarray(0, retval.currOffset));
      //decode(masterConfig, stream_buffer.subarray(0, retval.currOffset));
      return stream_buffer.subarray(0, retval.currOffset);
    } catch (err) {
      console.log("=== error while ecoding buffer", err);
    }
  }

  function encodeInternal(
    masterConfig,
    configCopy,
    data,
    buffer,
    offset,
    sizeObj,
    offsetObj
  ) {
    var size = 0;
    var currOffset = offset;
    var fieldSize = 0;
    if (configCopy.hasOwnProperty(CONSTANTS.PRE_ENCODE_HANDLER)) {
      configCopy[CONSTANTS.PRE_ENCODE_HANDLER](masterConfig, configCopy, data);
    }
    //console.log("======", JSON.stringify(data), offset);
    for (const [key, value] of Object.entries(configCopy)) {
      //console.log(value, isConfig(value))
      if (isConfig(value, key)) {
        if (isLeafType(masterConfig, value[CONSTANTS.TYPE])) {
          if (
            value[CONSTANTS.TYPE] == "vstring" ||
            value[CONSTANTS.TYPE] == "vstringx"
          ) {
            if (data[key]) {
              fieldSize = data[key].length;
            }
          } else if (value.hasOwnProperty(CONSTANTS.SIZE)) {
            fieldSize = value[CONSTANTS.SIZE];
          } else if (
            CONSTANTS.ALL_TYPES_WITH_SIZE.includes(value[CONSTANTS.TYPE])
          ) {
            throw new Error(
              "error: " +
                key +
                " has no size field which is required for the type " +
                value[CONSTANTS.TYPE]
            );
          }
          if (data[key]) {
            //config, value, buffer, offset, fieldSize
            //console.log("-----------internal", currOffset, value, data[key])
            fieldSize = writeToBuff(
              value,
              data[key],
              buffer,
              currOffset,
              fieldSize
            );
          }
          sizeObj[key] = fieldSize;
          offsetObj[key] = currOffset;
          currOffset += fieldSize;
          size += fieldSize;
        } else if (isNonLeafType(masterConfig, value[CONSTANTS.TYPE])) {
          /* get the new config */
          let childConfig = masterConfig[value[CONSTANTS.TYPE]];
          var childSizeObj = {};
          var childOffsetObj = {};
          sizeObj[key] = childSizeObj;
          offsetObj[key] = childOffsetObj;
          //(masterConfig, configCopy, data, buffer, offset, sizeObj, offsetObj
          let retval = encodeInternal(
            masterConfig,
            childConfig,
            data[key],
            buffer,
            currOffset,
            childSizeObj,
            childOffsetObj
          );
          currOffset = retval["currOffset"];
          size += retval["size"];
        } else if (isUnorderedSet(value, key)) {
          for (let [unorderedSetKey, unorderedSetValue] of Object.entries(
            value
          )) {
            if (
              isNonLeafType(masterConfig, unorderedSetValue[CONSTANTS.TYPE]) &&
              data.hasOwnProperty(unorderedSetKey)
            ) {
              let childConfig = masterConfig[unorderedSetValue[CONSTANTS.TYPE]];
              var childSizeObj = {};
              var childOffsetObj = {};
              sizeObj[unorderedSetKey] = childSizeObj;
              offsetObj[unorderedSetKey] = childOffsetObj;
              let retval = encodeInternal(
                masterConfig,
                childConfig,
                data[unorderedSetKey],
                buffer,
                currOffset,
                childSizeObj,
                childOffsetObj
              );
              currOffset = retval["currOffset"];
              size += retval["size"];
            }
          }
        } else {
          /* a config element is neither Leaftype nor NonLeafType*/
          throw new Error(
            "error: " + key + " is neither LeafType or NonLeafType config"
          );
        }
      }
    }
    if (configCopy.hasOwnProperty(CONSTANTS.PADDING)) {
      /* adjust size */
      let padding = configCopy[CONSTANTS.PADDING];
      //console.log("-------", currOffset)
      currOffset += size % padding == 0 ? 0 : padding - (size % padding);
    }

    if (configCopy.hasOwnProperty(CONSTANTS.POST_ENCODE_HANDLER)) {
      configCopy[CONSTANTS.POST_ENCODE_HANDLER](
        masterConfig,
        configCopy,
        data,
        buffer,
        currOffset,
        sizeObj,
        offsetObj
      );
    }
    console.log(currOffset, size);
    return { currOffset: currOffset, size: size };
  }

  function isConfig(config, key) {
    return (
      config &&
      typeof config == "object" &&
      (key == CONSTANTS.UNORDERED_SET || config.hasOwnProperty(CONSTANTS.TYPE))
    );
  }

  function isLeafType(masterConfig, typeName) {
    return (
      masterConfig &&
      typeName &&
      typeof masterConfig == "object" &&
      !masterConfig.hasOwnProperty(typeName) &&
      CONSTANTS.ALL_PRIMIIVE_TYPES.includes(typeName.toLowerCase())
    );
  }

  function isNonLeafType(masterConfig, typeName) {
    return (
      masterConfig &&
      typeName &&
      typeof masterConfig == "object" &&
      masterConfig.hasOwnProperty(typeName) &&
      !CONSTANTS.ALL_PRIMIIVE_TYPES.includes(typeName.toLowerCase())
    );
  }

  function isUnorderedSet(config, typeName) {
    let temp =
      config &&
      typeName &&
      typeof config == "object" &&
      config.hasOwnProperty(CONSTANTS.DISCRIMINATOR) &&
      typeName === CONSTANTS.UNORDERED_SET;
    console.log("INSIDE isUnorderedSet----------->", temp);
    return temp;
  }

  function decodeWithType(masterConfig, messageType, buffer, offset) {
    let returnValue = {};
    let currOffset = offset;
    let size = 0;
    if (
      masterConfig &&
      typeof masterConfig == "object" &&
      !masterConfig.hasOwnProperty(messageType)
    ) {
      console.log(messageType + " is not set for encoding ");
      throw new Error(
        "error: " + messageType + " doesn't exist in masterConfig"
      );
    }
    let configCopy = masterConfig[messageType];
    let returnTyep = false;
    for (const [key, value] of Object.entries(configCopy)) {
      let fieldSize = null;
      if (isConfig(value, key)) {
        if (isLeafType(masterConfig, value[CONSTANTS.TYPE])) {
          if (value[CONSTANTS.TYPE] == "vstring") {
            if (value[CONSTANTS.SIZE_FIELD]) {
              //console.log("return value", returnValue, currOffset);
              fieldSize = returnValue[value[CONSTANTS.SIZE_FIELD]];
            } else {
              throw new Error(
                "error: " +
                  key +
                  " vstring has no size field which is required for the type "
              );
            }
          } else if (value[CONSTANTS.TYPE] == "vstringx") {
            if (value[CONSTANTS.SIZE_FIELD]) {
              //console.log("return value", returnValue, currOffset);
              fieldSize = returnValue[value[CONSTANTS.SIZE_FIELD]] - 1;
            } else {
              throw new Error(
                "error: " +
                  key +
                  " vstring has no size field which is required for the type "
              );
            }
          } else if (value.hasOwnProperty(CONSTANTS.SIZE)) {
            fieldSize = value[CONSTANTS.SIZE];
          } else if (
            CONSTANTS.ALL_TYPES_WITH_SIZE.includes(value[CONSTANTS.TYPE])
          ) {
            throw new Error(
              "error: " +
                key +
                " has no size field which is required for the type " +
                value[CONSTANTS.TYPE]
            );
          }
          let readValue = readFromBuff(value, buffer, currOffset, fieldSize);
          if (configCopy.hasOwnProperty("__getId")) {
            console.log(
              "value to check ",
              configCopy.__getId(),
              configCopy,
              readValue
            );
          } else {
            returnTyep = true;
          }
          if (
            configCopy.hasOwnProperty("__getId") &&
            readValue.data === configCopy.__getId()
          ) {
            if (!returnTyep) {
              returnTyep = true;
            }
          }
          if (returnTyep) {
            returnValue[key] = readValue.data;
            currOffset += readValue.size;
            size += readValue.size;
          }
          // returnValue[key] = readValue.data;
          // currOffset += readValue.size;
          // size += readValue.size;
        } else if (isNonLeafType(masterConfig, value[CONSTANTS.TYPE])) {
          /* get the new config */
          let retval = decodeWithType(
            masterConfig,
            value[CONSTANTS.TYPE],
            buffer,
            currOffset
          );
          currOffset = retval["currOffset"];
          size += retval["size"];
          returnValue[key] = retval["data"];
        } else if (isUnorderedSet(value, key)) {
          let discriminatorFunction = value[CONSTANTS.DISCRIMINATOR];
          while (currOffset < buffer.length) {
            let typeName = discriminatorFunction(
              masterConfig,
              buffer.slice(currOffset)
            );
            console.log("Discriminatore of undordered " + typeName);
            let retval = decodeWithType(
              masterConfig,
              typeName,
              buffer,
              currOffset
            );
            currOffset = retval["currOffset"];
            size += retval["size"];
            let unodererdSetKey = getKeyGivenType(value, typeName);
            if (!unodererdSetKey) {
              throw "Invalid Type Name";
            }
            returnValue[unodererdSetKey] = retval["data"];
          }
        } else {
          /* a config element is neither Leaftype nor NonLeafType*/
          throw new Error(
            "error: " + key + " is neither LeafType or NonLeafType config"
          );
        }
      }
    }

    if (configCopy.hasOwnProperty(CONSTANTS.PADDING)) {
      /* adjust size */
      let padding = configCopy[CONSTANTS.PADDING];
      console.log("-------", currOffset);
      currOffset += size % padding == 0 ? 0 : padding - (size % padding);
    }
    console.log("===============Check===============", {
      data: returnValue,
      currOffset,
      size,
    });
    return { data: returnValue, currOffset, size };
  }

  function decode(masterConfig, buffer) {
    try {
      if (
        masterConfig &&
        typeof masterConfig == "object" &&
        !masterConfig.hasOwnProperty(CONSTANTS.DISCRIMINATOR)
      ) {
        throw new Error(
          "error: " + CONSTANTS.DISCRIMINATOR + " is not set in masterConfig "
        );
      }
      let msgType = masterConfig[CONSTANTS.DISCRIMINATOR](
        masterConfig,
        buffer,
        this
      );
      console.log("message from distriminator", msgType);
      if (!msgType)
        throw new Error("error: discriminator returned empty messageType");

      let decodedObj = decodeWithType(masterConfig, msgType, buffer, 0);
      return { decodedJson: decodedObj.data, messageType: msgType };
    } catch (err) {
      console.log("=== error while decoding buffer", err);
    }
  }

  function getKeyGivenType(config, typeName) {
    try {
      for (let [key, value] of Object.entries(config)) {
        if (
          value.hasOwnProperty(CONSTANTS.TYPE) &&
          value[CONSTANTS.TYPE] === typeName
        ) {
          return key;
        }
      }
      return null;
    } catch (e) {
      throw e;
    }
  }

  exports.encode = encode;
  exports.decode = decode;
  exports.CONSTANTS = CONSTANTS;
  exports.writeToBuff = writeToBuff;
  exports.decodeWithType = decodeWithType;
})(typeof exports === "undefined" ? (this["EncodeDecode"] = {}) : exports);
