//*******************Train
export const ATS_MESSAGE_RECEIVED = 'TRAIN/ATS_MESSAGE_RECEIVED'
export const FETCH_TRAINS_INFO_SUCCESS = 'TRAIN/FETCH_TRAINS_INFO_SUCCESS'
export const FETCH_TRAINS_INFO_ERROR = 'TRAIN/FETCH_TRAINS_INFO_ERROR'
export const FETCH_TRAINS_TRACKCIRCUIT = 'TRAIN/FETCH_TRAINS_TRACKCIRCUIT'
export const FETCH_ALL_LINES = 'TRAIN/FETCH_ALL_LINES'
export const FETCH_TABS_DATA = 'TRAIN/FETCH_TABS_DATA'
export const FETCH_ACTIVE_TRAINS = 'TRAIN/FETCH_ACTIVE_TRAINS'
export const TRAIN_UPDATE_RECIEVED = 'TRAIN/TRAIN_UPDATE_RECIEVED'
export const GET_DEPOT_DATA = 'TRAIN/GET_DEPOT_DATA'
export const GET_RADIO_DATA = 'TRAIN/GET_RADIO_DATA'
export const GET_STATION_RADIOS = 'TRAIN/GET_STATION_RADIOS'
export const UPDATE_TRAIN_DETAILS = 'TRAIN/UPDATE_TRAIN_DETAILS'

//**********************Train Locations and LA */
export const GET_ALL_LOCATIONS = 'TRAIN/GET_ALL_LOCATIONS'
export const GET_CURRENT_LOCATIONS = 'TRAIN/GET_CURRENT_LOCATIONS'
export const UPDATE_CURRENT_LOCATIONS = 'TRAIN/UPDATE_CURRENT_LOCATIONS'

export const GET_BASESTATIONS = 'TRAIN/GET_BASESTATIONS'
export const UPDATE_BASESTATION_STATUS = 'TRAIN/UPDATE_BASESTATION_STATUS'
export const UPDATE_TRAIN_LAST_LOCATION = 'TRAIN/UPDATE_TRAIN_LAST_LOCATION'

export const GET_LINES_GROUPSSI = 'TRAIN/GET_LINES_GROUPSSI'
export const GRABBED_LINES = 'TRAIN/GRABBED_LINES'
export const GET_NONFA_TRAIN_LOC = 'TRAIN/GET_NONFA_TRAIN_LOC'
export const UPDATE_NONFA_TRAINS_LOC = 'TRAIN/UPDATE_NONFA_TRAINS_LOC'

//*******************Communication
export const TEXT_MESSAGE_RECEIVED = 'COMMUNICATION/TEXT_MESSAGE_RECEIVED'
export const GROUP_TEXT_MESSAGE_RECEIVED = 'COMMUNICATION/GROUP_TEXT_MESSAGE_RECEIVED'
export const UPDATE_TEXT_MESSAGE_STATE = 'COMMUNICATION/UPDATE_TEXT_MESSAGE_STATE'
export const UPDATE_GROUP_TEXT_MESSAGE_STATE = 'COMMUNICATION/UPDATE_GROUP_TEXT_MESSAGE_STATE'
export const FETCH_TEXT_MESSAGES_SUCCESS = 'COMMUNICATION/FETCH_TEXT_MESSAGES_SUCCESS'
export const FETCH_TEXT_MESSAGES_ERROR = 'COMMUNICATION/FETCH_TEXT_MESSAGES_ERROR'
export const FETCH_GROUP_TEXT_MESSAGES_SUCCESS = 'COMMUNICATION/FETCH_GROUP_TEXT_MESSAGES_SUCCESS'
export const FETCH_GROUP_TEXT_MESSAGES_ERROR = 'COMMUNICATION/FETCH_GROUP_TEXT_MESSAGES_ERROR'
export const SEND_TEXT_MESSAGE = 'COMMUNICATION/SEND_TEXT_MESSAGE'
export const SEND_TEXT_MESSAGE_STATE = 'COMMUNICATION/SEND_TEXT_MESSAGE_STATE'

export const INDIVIDUAL_CALL_RECEIVED = 'COMMUNICATION/INDIVIDUAL_CALL_RECEIVED'
export const GROUP_CALL_RECEIVED = 'COMMUNICATION/GROUP_CALL_RECEIVED'
export const CONFERENCE_CALL_RECEIVED = 'COMMUNICATION/CONFERENCE_CALL_RECEIVED'
export const CALL_ON_HOLD_UNHOLD_RECEIVED = 'COMMUNICATION/CALL_ON_HOLD_UNHOLD_RECEIVED'
export const UPDATE_INDIVIDUAL_CALL_ACTION = 'COMMUNICATION/UPDATE_INDIVIDUAL_CALL_ACTION'
export const UPDATE_GROUP_CALL_ACTION = 'COMMUNICATION/UPDATE_GROUP_CALL_ACTION'
export const FETCH_INDIVIDUAL_CALLS_SUCCESS = 'COMMUNICATION/FETCH_INDIVIDUAL_CALLS_SUCCESS'
export const FETCH_GROUP_CALLS_SUCCESS = 'COMMUNICATION/FETCH_GROUP_CALLS_SUCCESS'
export const FETCH_INDIVIDUAL_CALLS_ERROR = 'COMMUNICATION/FETCH_INDIVIDUAL_CALLS_ERROR'
export const FETCH_GROUP_CALLS_ERROR = 'COMMUNICATION/FETCH_GROUP_CALLS_ERROR'
export const SEND_INDIVIDUAL_CALL = 'COMMUNICATION/SEND_INDIVIDUAL_CALL'
export const SEND_GROUP_CALL = 'COMMUNICATION/SEND_GROUP_CALL'
export const SEND_INDIVIDUAL_CALL_ACTION = 'COMMUNICATION/SEND_INDIVIDUAL_CALL_ACTION'
export const INDIVIDUAL_CALL_COUNT = 'COMMUNICATION/INDIVIDUAL_CALL_COUNT'
export const SEND_GROUP_CALL_ACTION = 'COMMUNICATION/SEND_GROUP_CALL_ACTION'
export const SEND_ACK_EMERGENCY_CALL = 'COMMUNICATION/SEND_ACK_EMERGENCY_CALL'
export const UPDATE_ACK_EMERGENCY_CALL = 'COMMUNICATION/UPDATE_ACK_EMERGENCY_CALL'
export const ADD_ACK_EMERGENCY_CALL = 'COMMUNICATION/ADD_ACK_EMERGENCY_CALL'
export const ADD_ACK_EMERGENCY_CALL_STATUS = 'COMMUNICATION/ADD_ACK_EMERGENCY_CALL_STATUS'
export const UPDATE_ACK_EMERGENCY_CALL_STATUS = 'COMMUNICATION/UPDATE_ACK_EMERGENCY_CALL_STATUS'

export const SENT_CALL = 'COMMUNICATION/SENT_CALL'

export const ADD_DISCREET_SUBSCRIBER = 'COMMUNICATION/ADD_DISCREET_SUBSCRIBER'
export const REMOVE_DISCREET_SUBSCRIBER = 'COMMUNICATION/REMOVE_DISCREET_SUBSCRIBER'

export const TOGGLE_SILENT = 'COMMUNICATION/TOGGLE_SILENT'

export const FETCH_SDS_TEXT = 'SDS/FETCH_SDS_TEXT'
export const UPDATE_SDS_TEXT = 'SDS/UPDATE_SDS_TEXT'
export const FETCH_USER_SDS_TEXT = 'SDS/FETCH_USER_SDS_TEXT'
export const UPDATE_USER_SDS_TEXT = 'SDS/UPDATE_USER_SDS_TEXT'
export const UPLOAD_SDS_FILE = 'SDS/UPLOAD_SDS_FILE'
//export const UPDATE_TEXT_MESSAGE_RESPONSE       = 'SDS/UPDATE_TEXT_MESSAGE_RESPONSE'

//Master PTT
export const ENABLE_MPTT = 'COMMUNICATION/ENABLE_MPTT'
export const ADD_MPTT = 'COMMUNICATION/ADD_MPTT'
export const REMOVE_MPTT = 'COMMUNICATION/REMOVE_MPTT'
export const END_MPTT = 'COMMUNICATION/END_MPTT'

//Contact List
export const FETCH_ALL_CONTACTS = 'COMMUNICATION/FETCH_ALL_CONTACTS'
export const UPDATE_CONTACT_TRAIN_DATA = 'COMMUNICATION/UPDATE_CONTACT_TRAIN_DATA'
export const ADD_FAV_CONTACT = 'COMMUNICATION/ADD_FAV_CONTACT'
export const REMOVE_FAV_CONTACT = 'COMMUNICATION/REMOVE_FAV_CONTACT'
export const UPDATE_CALL_CONTACTS = 'COMMUNICATION/UPDATE_CALL_CONTACTS'
export const UPDATE_DEFAULT_GROUP = 'COMMUNICATION/UPDATE_DEFAULT_GROUP'

export const UPDATE_TASK_CALL = 'COMMUNICATION/UPDATE_TASK_CALL'

//UPDATE MCX API
export const MCX_ADD_FAV = 'COMMUNICATION/MCX_ADD_FAV'
export const MCX_REMOVE_FAV = 'COMMUNICATION/MCX_REMOVE_FAV'

//Autherization
export const LOGIN_SUCCESS = 'AUTH/LOGIN_SUCCESS'
export const USER_PROFILE_UPDATE = 'AUTH/USER_PROFILE_UPDATE'
export const LOGIN_FAILED = 'AUTH/LOGIN_FAILED'
export const LOGOUT = 'AUTH/LOGOUT'
export const GETSUBSCRIBERDETAIL = 'AUTH/GETSUBSCRIBERDETAIL'
export const UPDATE_SUBSCRIBERDETAIL = 'AUTH/UPDATE_SUBSCRIBERDETAIL'
export const GET_TETRAID = 'USER/GET_TETRAID'

//Dialer Option
export const DIALER_OPTION = 'USER/DIALER_OPTION'

//Location
export const UPDATE_SEARCH_TEXT = 'LOCATION/UPDATE_SEARCH_TEXT'
export const LOCATION_RECEIVED = 'LOCATION/LOCATION_RECEIVED'
export const FETCH_LOCATIONS_SUCCESS = 'LOCATION/FETCH_LOCATIONS_SUCCESS'
export const FETCH_LOCATIONS_ERROR = 'LOCATION/FETCH_LOCATIONS_ERROR'
export const FETCH_SNAIL_TRAILS_SUCCESS = 'LOCATION/FETCH_SNAIL_TRAILS_SUCCESS'
export const FETCH_SNAIL_TRAILS_ERROR = 'LOCATION/FETCH_SNAIL_TRAILS_ERROR'
export const FETCH_FENCES_SUCCESS = 'LOCATION/FETCH_FENCES_SUCCESS'
export const FETCH_FENCES_ERROR = 'LOCATION/FETCH_FENCES_ERROR'
export const REMOVE_SNAIL_TRAIL_SUCCESS = 'LOCATION/REMOVE_SNAIL_TRAIL_SUCCESS'
export const REMOVE_FENCE_SUCCESS = 'LOCATION/REMOVE_FENCE_SUCCESS'
export const REMOVE_ACTIVITY_SUCCESS = 'LOCATION/REMOVE_ACTIVITY_SUCCESS'
export const ZOOM_LEVEL = 'LOCATION/ZOOM_LEVEL'
export const MAP_CENTER_POSITION = 'LOCATION/MAP_CENTER_POSITION'
export const FENCE_HIGHLIGHT = 'LOCATION/FENCE_HIGHLIGHT'
export const POI_HIGHLIGHT = 'LOCATION/POI_HIGHLIGHT'

//LOG
export const LOG = 'LOGGING/LOG'

//CURRENT CALL
export const SET_CURRENT_CALL = 'CALL_SETTING/SET_CURRENT_CALL'
export const REM_CURRENT_CALL = 'CALL_SETTING/REM_CURRENT_CALL'
export const SET_AS_CURRENT_CALL = 'CALL_SETTING/SET_AS_CURRENT_CALL'

//SETTINGS
export const UPDATE_MPTT_KEY = 'SETTINGS/UPDATE_MPTT_KEY'

//CAD
export const CAD_CALL_RECEIVED = 'CAD/CAD_CALL_RECEIVED'
export const UPDATE_CAD_CALL_ACTION = 'CAD/UPDATE_CAD_CALL_ACTION'
export const SEND_CAD_UPDATE = 'CAD/SEND_CAD_UPDATE'

//PATCH
export const INITIATE_PATCH_CALL = 'PATCH/INITIATE_PATCH_CALL'
export const PATCH_CALL_SELN = 'PATCH/PATCH_CALL_SELN'
export const SEND_PATCH_CALLS = 'PATCH/SEND_PATCH_CALLS'
export const MCX_PATCH_CALLS = 'PATCH/MCX_PATCH_CALLS'
export const PATCH_RESPONSE = 'PATCH/PATCH_RESPONSE'

//MERGE
export const INITIATE_MERGE_CALL = 'MERGE/INITIATE_MERGE_CALL'
export const MERGE_CALL_SELN = 'MERGE/MERGE_CALL_SELN'
export const SEND_MERGE_CALLS = 'MERGE/SEND_MERGE_CALLS'
export const MCX_MERGE_CALLS = 'MERGE/MCX_MERGE_CALLS'
export const MERGE_RESPONSE = 'MERGE/MERGE_RESPONSE'

//DGNA
export const ADD_DGNA = 'DGNA/ADD_DGNA';
export const EDIT_DGNA = 'DGNA/EDIT_DGNA';
export const FETCH_DGNA = 'DGNA/FETCH_DGNA';
export const UPDATE_DGNA = 'DGNA/UPDATE_DGNA';
export const FREE_DGNA = 'DGNA/FREE_DGNA';
export const ATTACH_DGNA = 'DGNA/ATTACH_DGNA';
//USERLIST
export const FETCH_USERLIST = 'USERLIST/FETCH_USERLIST';
export const EXPORT_DGNA = 'USERLIST/EXPORT_DGNA';
export const UPDATE_USERLIST = 'USERLIST/UPDATE_USERLIST';
export const DELETE_USERLIST = 'USERLIST/DELETE_USERLIST';
export const EDIT_USERLIST = 'USERLIST/EDIT_USERLIST';

//*******************Admin*****************************//
export const GET_ALL_SUBDOMAINS='ADMIN/GET_ALL_SUBDOMAINS'
export const UPDATE_ALL_SUBDOMAINS='ADMIN/UPDATE_ALL_SUBDOMAINS'
export const FETCH_USERLIST_ADMIN = 'ADMIN/FETCH_USERLIST_ADMIN'
export const UPDATE_USERLIST_ADMIN = 'ADMIN/UPDATE_USERLIST_ADMIN'
export const FETCH_FALIST_ADMIN = 'ADMIN/FETCH_FALIST_ADMIN'
export const UPDATE_FALIST_ADMIN = 'ADMIN/UPDATE_FALIST_ADMIN'
export const CREATE_USER_ADMIN = 'ADMIN/CREATE_USER_ADMIN'
export const DELETE_USER_ADMIN = 'ADMIN/DELETE_USER_ADMIN'
export const UPDATE_USER_ADMIN = 'ADMIN/UPDATE_USER_ADMIN'
export const UPDATE_FA_ADMIN = 'ADMIN/UPDATE_FA_ADMIN'
export const ADD_FA_ADMIN = 'ADMIN/ADD_FA_ADMIN'
export const FA_DETAIL_ADMIN = 'ADMIN/FA_DETAIL_ADMIN'
export const UPDATE_FADETAIL_ADMIN = 'ADMIN/UPDATE_FADETAIL_ADMIN'
export const FETCH_IWFBYFA_ADMIN = 'ADMIN/FETCH_IWFBYFA_ADMIN'
export const UPDATE_IWFBYFA_ADMIN = 'ADMIN/UPDATE_IWFBYFA_ADMIN'
export const RESET_IWFBYFA_ADMIN = 'ADMIN/RESET_IWFBYFA_ADMIN'
export const FETCH_IWFBYMCPTTID_ADMIN = 'ADMIN/FETCH_IWFBYMCPTTID_ADMIN'
export const UPDATE_IWFBYMCPTTID_ADMIN = 'ADMIN/UPDATE_IWFBYMCPTTID_ADMIN'
export const RESET_IWFBYMCPTTID_ADMIN = 'ADMIN/RESET_IWFBYMCPTTID_ADMIN'
export const RESET_FADETAIL_ADMIN = 'ADMIN/RESET_FADETAIL_ADMIN'
export const DELETE_FA_ADMIN = 'ADMIN/DELETE_FA_ADMIN'
export const FETCH_PROFILE_FA_ADMIN = 'ADMIN/FETCH_PROFILE_FA_ADMIN'
export const UPDATE_PROFILE_FA_ADMIN = 'ADMIN/UPDATE_PROFILE_FA_ADMIN'
export const RESET_IWFFA_ADMIN = 'ADMIN/RESET_IWFFA_ADMIN'

export const UPDATE_USERPROFILE_ATTACHED_FA_ADMIN = 'ADMIN/UPDATE_USERPROFILE_ATTACHED_FA_ADMIN'
export const RESET_PROFILE_FA_ADMIN = 'ADMIN/RESET_PROFILE_FA_ADMIN'
export const CREATE_GROUP_ADMIN = 'ADMIN/CREATE_GROUP_ADMIN'
export const FETCH_GROUP_ADMIN = 'ADMIN/FETCH_GROUP_ADMIN'
export const UPDATE_GROUP_ADMIN = 'ADMIN/UPDATE_GROUP_ADMIN'
export const UPDATE_GROUP_TYPE_ADMIN = 'ADMIN/UPDATE_GROUP_TYPE_ADMIN'
export const FETCH_GROUP_TYPE_ADMIN = 'ADMIN/FETCH_GROUP_TYPE_ADMIN'
export const UPDATE_GROUP_DETAIL_ADMIN = 'ADMIN/UPDATE_GROUP_DEATAIL_ADMIN'
export const FETCH_GROUP_DETAIL_ADMIN = 'ADMIN/FETCH_GROUP_DEATAIL_ADMIN'
export const UPDATE_ORG_GROUP_ADMIN = 'ADMIN/UPDATE_ORG_GROUP_ADMIN'
export const FETCH_ORG_GROUP_ADMIN = 'ADMIN/FETCH_ORG_GROUP_ADMIN'
export const RESET_ORG_GROUP_ADMIN = 'ADMIN/RESET_ORG_GROUP_ADMIN'
export const CREATE_ORG_ADMIN = 'ADMIN/CREATE_ORG_ADMIN'
export const FETCH_ORG_ADMIN = 'ADMIN/FETCH_ORG_ADMIN'
export const UPDATE_ORG_ADMIN = 'ADMIN/UPDATE_ORG_ADMIN'
export const DELETE_ORG_ADMIN = 'ADMIN/DELETE_ORG_ADMIN'
export const UPDATE_EXISTING_ORG_ADMIN = 'ADMIN/UPDATE_EXISTING_ORG_ADMIN'
export const DELETE_GROUP_ADMIN = 'ADMIN/DELETE_GROUP_ADMIN'
export const UPDATE_EXISTING_GROUP_ADMIN = 'ADMIN/UPDATE_EXISTING_GROUP_ADMIN'
export const FETCH_IWF_MAP_ADMIN = 'ADMIN/FETCH_IWF_MAP_ADMIN'
export const FETCH_IWF_TYPE_ADMIN = 'ADMIN/FETCH_IWF_TYPE_ADMIN'
export const CREATE_IWF_MAP_ADMIN = 'ADMIN/CREATE_IWF_MAP_ADMIN'
export const UPDATE_IWF_MAP_ADMIN = 'ADMIN/UPDATE_IWF_MAP_ADMIN'
export const UPDATE_IWF_TYPE_ADMIN = 'ADMIN/UPDATE_IWF_TYPE_ADMIN'
export const DELETE_IWF_MAP_ADMIN = 'ADMIN/DELETE_IWF_MAP_ADMIN'
export const UPDATE_EXISTING_IWF_MAP_ADMIN = 'ADMIN/UPDATE_EXISTING_IWF_MAP_ADMIN'
export const FETCH_MCPTTID_URI = 'ADMIN/FETCH_MCPTTID_URI'
export const UPDATE_MCPTTID_URI = 'ADMIN/UPDATE_MCPTTID_URI'

export const AFFILIATE_GROUP_TO_USER = 'ADMIN/AFFILIATE_GROUP_TO_USER'
export const USER_DEFAULT_GROUP = 'ADMIN/USER_DEFAULT_GROUP'

export const UPDATE_DOMAINS = 'DOMAIN/UPDATE_DOMAINS'
export const UPDATE_SYSTEM_DGNA_SSI = 'DOMAIN/UPDATE_SYSTEM_DGNA_SSI'
export const GET_ALL_SWITCH_PROFILES = 'DOMAIN/GET_ALL_SWITCH_PROFILES'
export const UPDATE_ALL_SWITCH_PROFILES = 'DOMAIN/UPDATE_ALL_SWITCH_PROFILES'
export const GET_ALL_ADMIN_PROFILE = 'DOMAIN/GET_ALL_ADMIN_PROFILE'
export const UPDATE_ALL_ADMIN_PROFILE = 'DOMAIN/UPDATE_ALL_ADMIN_PROFILE'
export const CREATED_ADMIN_PROFILE = "ADMIN/CREATED_ADMIN_PROFILE"
export const RESET_ADMIN_PROFILE = "ADMIN/RESET_ADMIN_PROFILE"

//*******************Mtm*****************************//
export const FETCH_PARTNERLIST_MTM = 'MTM/FETCH_PARTNERLIST_MTM'
export const FETCH_MASTERSERVICESCONFIG_MTM = 'MTM/FETCH_MASTERSERVICESCONFIG_MTM'
export const FETCH_UETYPELIST_MTM = 'MTM/FETCH_UETYPELIST_MTM'
export const FETCH_AVAILABLESERVICES_MTM = 'MTM/FETCH_AVAILABLESERVICES_MTM'
export const UPDATE_PARTNERLIST_MTM = 'MTM/UPDATE_PARTNERLIST_MTM'
export const UPDATE_UETYPE_MTM = 'MTM/UPDATE_UETYPE_MTM'
export const UPDATE_AVAILABLE_SERVICES_MTM = 'MTM/UPDATE_AVAILABLE_SERVICES_MTM'
export const UPDATE_PARTNER_MTM = 'MTM/UPDATE_PARTNER_MTM'
export const UPDATE_MASTERSERVICESCONFIG_MTM = 'MTM/UPDATE_MASTERSERVICESCONFIG_MTM'
export const FETCH_TENANTLIST_MTM = 'MTM/FETCH_TENANTLIST_MTM'
export const UPDATE_TENANTLIST_MTM = 'MTM/UPDATE_TENANTLIST_MTM'
export const UPDATE_TENANT_MTM = 'MTM/UPDATE_TENANT_MTM'
export const UPDATE_USER_ON_IDMS_AND_SERVER = 'MTM/UPDATE_USER_ON_IDMS_AND_SERVER'
export const UPDATE_USER_ON_IDMS_AND_SERVER_PARTNER = 'MTM/UPDATE_USER_ON_IDMS_AND_SERVER_PARTNER'
export const CREATE_PARTNERLIST_MTM = 'MTM/CREATE_PARTNERLIST_MTM'
export const DELETE_PARTNERLIST_MTM = 'MTM/DELETE_PARTNERLIST_MTM'
export const CREATE_TENANTLIST_MTM = 'MTM/CREATE_TENANTLIST_MTM'
export const CREATE_USERONIDMSANDSERVER_MTM = 'MTM/CREATE_USERONIDMSANDSERVER_MTM'
export const DELETE_TENANTLIST_MTM = 'MTM/DELETE_TENANTLIST_MTM'

// Mute Unmute Speaker On Off all call
export const MUTE_UNMUTE_ALL_CALL = 'COMMUNICATION/MUTE_UNMUTE_ALL_CALL'
export const SPEAKER_ONOFF_ALL_CALL = 'COMMUNICATION/SPEAKER_ONOFF_ALL_CALL'

//location tab 
export const USER_LOCATION_UPDATE = 'USER_LOCATION_UPDATE'
export const USER_INSIDE_FENCE = 'USER_INSIDE_FENCE'
export const SET_ACTIVE_UE_REG = "LOCATION/SET_ACTIVE_UE_REG"

export const RESET_AVAILABLE_SERVICE = "MTM/RESET_AVAILABLE_SERVICE"

//*******************Alerts
export const GET_ALL_ALERT_LIST = "GET_ALL_ALERT_LIST"
export const GET_ALL_ALERT_LIST_TYPES = "GET_ALL_ALERT_LIST_TYPES"
export const ADD_ALERT_LIST = "ADD_ALL_ALERT_LIST"
export const EDIT_ALERT_LIST = "EDIT_ALERT_LIST_TYPES"
export const DELETE_ALERT_LIST = "DELETE_ALERT_LIST_TYPES"
export const UPDATE_ALL_ALERT_LIST = "UPDATE_ALL_ALERT_LIST"
export const UPDATE_ALL_ALERT_LIST_TYPES = "UPDATE_ALL_ALERT_LIST_TYPES"

//******************************** */
export const UPDATE_GROUP_ACTIVE_MEMBERS = "COMMUNICATION/UPDATE_GROUP_ACTIVE_MEMBERS"
export const FETCH_GROUP_ACTIVE_MEMBERS = "COMMUNICATION/FETCH_GROUP_ACTIVE_MEMBERS"
export const UPDATE_USERS_GROUPS = "COMMUNICATION/UPDATE_USERS_GROUPS"
export const FETCH_USERS_GROUPS = "COMMUNICATION/FETCH_USERS_GROUPS"

//TASK
export const UPDATE_TASK_LIST = "TASK/UPDATE_TASK_LIST"
export const FETCH_TASK_LIST = "TASK/FETCH_TASK_LIST"
export const UPDATE_ACTIVE_TASK_COUNT = "TASK/UPDATE_ACTIVE_TASK_COUNT"
export const FETCH_ACTIVE_TASK_COUNT = "TASK/FETCH_ACTIVE_TASK_COUNT"
export const UPDATE_BEEP_TASK = "TASK/UPDATE_BEEP_TASK"

// Registered User List
export const FETCH_REG_USERS_LIST = "FETCH_REG_USERS_LIST"

// Player
export const FETCH_CALL_RECORD = "RECORDINGS/FETCH_CALL_RECORD_TABLE"
export const UPDATE_CALL_RECORD = "RECORDINGS/UPDATE_CALL_RECORD"

export const INITIATE_DEFAULT_GROUP_CALL = "INITIATE_DEFAULT_GROUP_CALL"
export const SET_CALL_RECORD_OBJ = "RECORDINGS/SET_CALL_RECORD_OBJ"
export const SET_PAGE = "RECORDINGS/SET_PAGE"
export const SET_TOTAL_PAGE = "RECORDINGS/SET_TOTAL_PAGE"
export const SET_LOADER = "RECORDINGS/SET_LOADER"
export const SET_HAS_MORE = "RECORDINGS/SET_HAS_MORE"
export const SET_SHOWING_NEXT_DATA = "RECORDINGS/SET_SHOWING_NEXT_DATA"

// ChatList
export const GET_CHAT_USERLIST = "COMMUNICATION/GET_CHAT_USERLIST"
export const SET_CHAT_USERLIST = "COMMUNICATION/SET_CHAT_USERLIST"

// update DGNA feature
export const UPDATE_DGNA_FEATURE = "UPDATE_DGNA_FEATURE"

// update ambienceListeningFeature feature
export const UPDATE_AMBIENCE_LISTENING_FEATURE = "UPDATE_AMBIENCE_LISTENING_FEATURE"

// update DGNA feature
export const UPDATE_CAD_CALL_FEATURE = "UPDATE_CAD_CALL_FEATURE"

// update PATCH CALL feature
export const UPDATE_PATCH_CALL_FEATURE = "UPDATE_PATCH_CALL_FEATURE"

// update Merge CALL feature
export const UPDATE_MERGE_CALL_FEATURE = "UPDATE_MERGE_CALL_FEATURE"

//update Call Forwarding Feature
export const UPDATE_CALL_FORWARDING_FEATURE = " UPDATE_CALL_FORWARDING_FEATURE"

export const CREATE_DGNA = "CREATE_DGNA"
export const UPDATED_DGNA = "UPDATED_DGNA"

//callForwarding
export const SET_CALL_FORWARD = "SET_CALL_FORWARD"

export const UPDATE_DEVICE_CHANGE = "UPDATE_DEVICE_CHANGE"

// MDM
export const SET_CURRENT_TAB = "MDM/SET_CURRENT_TAB"
export const FECTH_DEVICE_LIST = "MDM/FETCH_DEVICE_LIST"
export const SET_DEVICE_LIST = "MDM/SET_DEVICE_LIST"
export const FECTH_DEVICE_TYPE_LIST = "MDM/FETCH_DEVICE_TYPE_LIST"
export const SET_DEVICE_TYPE_LIST = "MDM/SET_DEVICE_TYPE_LIST"
export const FETCH_APP_LIST = "MDM/FETCH_APP_LIST"
export const FETCH_APP_CONFIG_LIST = "MDM/FETCH_APP_CONFIG_LIST"
export const SET_APP_LIST = "MDM/SET_APP_LIST"
export const SET_APP_CONFIG_LIST = "MDM/SET_APP_CONFIG_LIST"
export const FETCH_CONFIG_LIST = "MDM/FETCH_CONFIG_LIST"
export const FETCH_DEVICE_INVENTORY = "MDM/FETCH_DEVICE_INVENTORY"
export const ALLOCATE_DEVICE = "MDM/ALLOCATE_DEVICE"
export const SET_CONFIG_LIST = "MDM/SET_CONFIG_LIST"
export const SET_DEVICE_INVENTORY = "MDM/SET_DEVICE_INVENTORY"
export const GET_TENANT_ID = "MDM/GET_TENANT_ID"
export const SET_TENANT_ID = "MDM/SET_TENANT_ID"
export const DELETE_DEVICE = "MDM/DELETE_DEVICE"
export const DELETE_DEVICE_TYPE = "MDM/DELETE_DEVICE_TYPE"
export const DELETE_CONFIG = "MDM/DELETE_CONFIG"
export const DELETE_APP = "MDM/DELETE_APP"
export const ANALYZE_APP = "MDM/ANALYZE_APP"
export const FETCH_AUTH_TOKEN = "MDM/FETCH_AUTH_TOKEN"
export const REFRESH_AUTH_TOKEN = "MDM/REFRESH_AUTH_TOKEN"
export const UPDATE_CONFIG = "MDM/UPDATE_CONFIG"
export const FETCH_CONFIG_APPLICATION = "MDM/FETCH_CONFIG_APPLICATION"
export const SET_CONFIG_APPLICATION = "MDM/SET_CONFIG_APPLICATION"
export const ADD_CONFIG_APP_RELN = "MDM/ADD_CONFIG_APP_RELN"
export const ADD_DEVICE = "MDM/ADD_DEVICE"
export const UPDATE_DEVICE = "MDM/UPDATE_DEVICE"
export const UPLOAD_APP = "MDM/UPLOAD_APP"
export const ADD_DEVICE_TYPE = "MDM/ADD_DEVICE_TYPE"
export const UPDATE_DEVICE_TYPE = "MDM/UPDATE_DEVICE_TYPE"
export const PULL_LOGS = "MDM/PULL_LOGS"
export const SET_APP_RELN = "MDM/SET_APP_RELN"
export const GET_TENANT_LIST = "MDM/GET_TENANT_LIST"
export const SET_TENANT_LIST = "MDM/SET_TENANT_LIST"
export const GET_DEVICE_ID_LIST = "MDM/GET_DEVICE_ID_LIST"
export const SET_DEVICE_ID_LIST = "MDM/SET_DEVICE_ID_LIST"
export const UPDATE_DEVICE_BY_DEVICE = "MDM/UPDATE_DEVICE_BY_DEVICE"
export const UPDATE_DEVICE_BY_USER = "MDM/UPDATE_DEVICE_BY_USER"
export const GET_DEVICE_ID = "MDM/GET_DEVICE_ID"
export const SET_DEVICE_ID = "MDM/SET_DEVICE_ID"
export const SET_SEARCHED_TEXT="MDM/SET_SEARCHED_TEXT"
export const SET_FILTERED_DATA = "MDM/SET_FILTERED_DATA"
export const GET_CONFIG_DEVICE = "MDM/GET_CONFIG_DEVICE"
export const UPDATE_CONFIG_DEVICE = "MDM/UPDATE_CONFIG_DEVICE"
export const FETCH_DEVICE_DASHBOARD="MDM/FETCH_DEVICE_DASHBOARD"
export const SET_DEVICE_DASHBOARD="MDM/SET_DEVICE_DASHBOARD"
export const FETCH_DEVICE_INFO="MDM/FETCH_DEVICE_INFO"
export const SET_DEVICE_INFO="MDM/SET_DEVICE_INFO"

//Plan Management or product catalog
export const GET_PRODUCT_CATALOG = "PM/GET_PRODUCT_CATALOG"
export const ADD_PRODUCT_CATALOG = "PM/ADD_PRODUCT_CATALOG"
export const UPDATE_PRODUCT_CATALOG = "PM/UPDATE_PRODUCT_CATALOG"
export const EDIT_PRODUCT_CATALOG = "PM/EDIT_PRODUCT_CATALOG"
export const BUY_PRODUCT_CATALOG = "PM/BUY_PRODUCT_CATALOG"
export const CONFIRM_ORDER = "PM/CONFIRM_ORDER"
export const REJECTED_ORDER = "PM/REJECTED_ORDER"
export const GET_ORDER_LIST = "PM/GET_ORDER_LIST"
export const UPDATE_ORDER_LIST = "PM/UPDATE_ORDER_LIST"

// Subscription Management
export const GET_SUBSCRIPTION_LIST = "SM/GET_SUBSCRIPTION_LIST"
export const UPDATE_SUBSCRIPTION_LIST = "SM/UPDATE_SUBSCRIPTION_LIST"
export const GET_CURRENT_ACTIVE_SUBSCRIPTION_FOR_USER = "SM/GET_CURRENT_ACTIVE_SUBSCRIPTION_FOR_USER"
export const UPDATE_CURRENT_ACTIVE_SUBSCRIPTION_FOR_USER = "SM/UPDATE_CURRENT_ACTIVE_SUBSCRIPTION_FOR_USER"
export const GET_CURRENT_ACTIVE_SUBSCRIPTION = "SM/GET_CURRENT_ACTIVE_SUBSCRIPTION"
export const UPDATE_CURRENT_ACTIVE_SUBSCRIPTION = "SM/UPDATE_CURRENT_ACTIVE_SUBSCRIPTION"
export const GET_ALLOCATE_USER_FROM_TENANT_SUBSCRIPTION = "SM/GET_ALLOCATE_USER_FROM_TENANT_SUBSCRIPTION"
export const UPDATE_ALLOCATE_USER_FROM_TENANT_SUBSCRIPTION = "SM/UPDATE_ALLOCATE_USER_FROM_TENANT_SUBSCRIPTION"
export const GET_DEALLOCATE_USER_FROM_TENANT_SUBSCRIPTION = "SM/GET_DEALLOCATE_USER_FROM_TENANT_SUBSCRIPTION"
export const UPDATE_DEALLOCATE_USER_FROM_TENANT_SUBSCRIPTION = "SM/UPDATE_DEALLOCATE_USER_FROM_TENANT_SUBSCRIPTION"
export const RESET_SUBSCRIPTIONS = "PM/RESET_SUBSCRIPTIONS"