// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Search Collapse button */
/* .sq-icon-btn{
    font-size: 16px;
    width: 32px;
    margin-left: 8px;
  } */
.wx32{
    width: 32px!important;
    margin-left: 8px;
}

.wx64{
  width: 48px!important;
  height: 64px!important;
  margin-left: 8px;
}

.wx47{
  width: 48px!important;
  height: 48px!important;
  margin-left: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/commom/QuickLinks/styles.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;;;;KAIK;AACL;IACI,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,gBAAgB;AAClB","sourcesContent":["/* Search Collapse button */\n/* .sq-icon-btn{\n    font-size: 16px;\n    width: 32px;\n    margin-left: 8px;\n  } */\n.wx32{\n    width: 32px!important;\n    margin-left: 8px;\n}\n\n.wx64{\n  width: 48px!important;\n  height: 64px!important;\n  margin-left: 8px;\n}\n\n.wx47{\n  width: 48px!important;\n  height: 48px!important;\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
