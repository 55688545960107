module.exports.showDuration = (startTime,endTime)=>{
    let duration =  Math.abs(Date.parse(startTime)-Date.parse(endTime))
    return duration ?  new Date(duration).toGMTString().substr(17,8) : "00:00:00"
}

module.exports.durationInSeconds= (startTime,endTime)=>{
    return Math.abs(Date.parse(startTime)-Date.parse(endTime))/1000;
}
module.exports.showTime = (time)=>{
    if(time){
        // let timestamp = Date.parse(time);
        return new Date(time).toLocaleString();
    }
    return "";
}
module.exports.formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);
    return [h, m > 9 ? m : (h ? '0' + m : m || '0'), s > 9 ? s : '0' + s]
        .filter(Boolean)
        .join(':');
};
module.exports.isoToSeconds = (isoString) => {
    return new Date(isoString).getTime() / 1000;
};

module.exports.changeBoolValue = (value)=>{
    if(value=='1')
    return "Yes";
    return "No";
}
module.exports.showCallType = (callType)=>{
    if(callType.includes("DUPLEX_INDIVIDUAL_CALL"))
        return "DUPLEX CALL"
    if(callType.includes("SIMPLEX_INDIVIDUAL_DIRECT_CALL"))
        return "DIRECT CALL"
    if(callType.includes("SIMPLEX_INDIVIDUAL_HOOK_CALL"))
        return "HOOK CALL"
    if(callType.includes("BROADCAST_CALL"))
        return "BROADCAST CALL"
    if(callType.includes("SIMPLEX_GROUP_CALL"))
        return "GROUP CALL"
    if(callType.includes("CONFERENCE_CALL"))
        return "CONFERENCE CALL"
    return callType
}