(function (exports) {
    const stateType = require("./StateType");
    const JsSIP_C = require("./jssip/JsSIP").C;

    class RegisterProcessStates {
        constructor(ua) {
            this.ua = ua;
            this.loginState = stateType.LOGIN_STATE.LoggedOut;
            this.transportState = stateType.TRANSPORT_STATE.Disconnected;
            this.registerState = stateType.REGISTRATION_STATE.Deregistered;
            this.xcapState = stateType.XCAP_STATE.None;
            this.presenceState = stateType.PRESENCE_STATE.None;
            this.registrationProcessTimer = null;
            this.xcapProcessTimer = null;
            this.presenceProcessTimer = null;
            this.profilefetchTimer = null;
            this.regConfigTimerVal = this.ua.config ? this.ua.config.register_expires ? this.ua.config.register_expires : 30000 : 30000;
        }

        updateRegisterStates(type, val, reason) {
            console.log("updateRegister process state..", type, val, reason);
            let causes = reason ? reason.cause ? reason.cause : null : null;
            //let response = reason ? reason.response ? reason.response : null : null;
            let regNextTimerVal = reason ? reason.regTimerVal ? reason.regTimerVal : this.regConfigTimerVal : this.regConfigTimerVal;
            switch (type) {
                case "LOGIN":
                    console.log("LOGIN_STATES", this.loginState, val);
                    if (this.loginState !== stateType.LOGIN_STATE.LoggedIn &&
                        val === stateType.LOGIN_STATE.LoggedIn) {
                        console.log("either relogin or first time logging ", this.transportState);
                        if (this.transportState === stateType.TRANSPORT_STATE.Connected) {
                            this.startRegProcessTimer(5);
                        }
                    } else {
                        if (val === stateType.LOGIN_STATE.LoggedOut) {
                            console.log("clear all timer on loggedOut");
                            this.clearRegProcessTimer();
                            this.clearXcapProcessTimer();
                            this.clearPresenceProcessTimer();
                        }
                    }
                    this.loginState = val;
                    break;
                case "REGISTER":
                    console.log("REGISTER_STATES", this.registerState, val, this.loginState);
                    if (val === stateType.REGISTRATION_STATE.Registered) {
                        if (this.loginState === stateType.LOGIN_STATE.LoggedIn) {
                            this.startRegProcessTimer(regNextTimerVal);
                            // Get user type role (either should be admin or dispatcher)
                            if (this.ua.getUserProfileRole() === "Admin") {
                                return;
                            }
                            if (this.registerState !== stateType.REGISTRATION_STATE.Registered) {
                                console.log("either re-registering or first time registered ", this.transportState);
                                if (this.transportState === stateType.TRANSPORT_STATE.Connected) {
                                    this.startXcapProcessTimer(10000);
                                    this.startPresenceProcessTimer(10000);
                                }
                            }
                        }
                    } else if (val === stateType.REGISTRATION_STATE.Deregistered) {
                        console.log("Registration got deregistered ", this.loginState);
                        this.clearXcapProcessTimer();
                        this.clearPresenceProcessTimer();
                        if (this.loginState === stateType.LOGIN_STATE.LoggedIn) {
                            if (causes === JsSIP_C.causes.CONNECTION_ERROR) {
                                console.log("trying re-register after getting connection error");
                                this.startRegProcessTimer(1000);
                            } else if (causes === JsSIP_C.causes.REQUEST_TIMEOUT) {
                                console.log("trying re-register after getting req timeout error");
                                this.startRegProcessTimer(5);
                            }
                        }
                    }
                    this.registerState = val;
                    break;
                case "TRANSPORT":
                    console.log("TRANSPORT_STATES", this.transportState, val);
                    if (this.transportState !== stateType.TRANSPORT_STATE.Connected &&
                        val === stateType.TRANSPORT_STATE.Connected) {
                        console.log("Transport is reconnected ", this.loginState);
                        if (this.loginState === stateType.LOGIN_STATE.LoggedIn) {
                            this.startRegProcessTimer(5);
                        }
                    }
                    this.transportState = val;
                    break;
                case "XCAP":
                    console.log("XCAP_STATES", this.xcapState, val);
                    if (val === stateType.XCAP_STATE.Notified) {
                        console.log("xacp state is notified ", this.registerState);
                        this.clearXcapProcessTimer();
                        if (this.registerState === stateType.REGISTRATION_STATE.Registered) {
                            this.startProfileFetchTimer(5);
                        }
                    } else if (this.xcapState !== stateType.XCAP_STATE.None &&
                        val === stateType.XCAP_STATE.None) {
                        console.log("xacp state from not none state to none state ", this.registerState);
                        this.clearXcapProcessTimer();
                        if (this.registerState === stateType.REGISTRATION_STATE.Registered) {
                            this.startXcapProcessTimer(10000);
                        }
                    }
                    this.xcapState = val;
                    break;
                case "PRESENCE":
                    if (this.presenceState !== stateType.PRESENCE_STATE.None &&
                        val === stateType.PRESENCE_STATE.None) {
                        console.log("presence state from not none state to none state ", this.registerState);
                        if (this.registerState === stateType.REGISTRATION_STATE.Registered) {
                            this.startPresenceProcessTimer(10000);
                        }
                    } else if (val === stateType.PRESENCE_STATE.Notified) {
                        this.clearPresenceProcessTimer();
                    }
                    this.presenceState = val;
                    break;
                default:
                    break;
            }
        }

        startRegProcessTimer(timeout) {
            console.log("startRegProcessTimer()");
            this.clearRegProcessTimer();
            const _this = this;
            this.registrationProcessTimer = setTimeout(() => {
                _this.ua.startRegistrationProcess();
            }, timeout);
        }

        clearRegProcessTimer() {
            console.log("clearRegProcessTimer()");
            this.registrationProcessTimer && clearTimeout(this.registrationProcessTimer);
            this.registrationProcessTimer = null;
        }

        startXcapProcessTimer(timeout) {
            console.log("startXcapProcessTimer()");
            this.clearXcapProcessTimer();
            const _this = this;
            this.xcapProcessTimer = setTimeout(() => {
                _this.ua.startXcapProcess();
            }, timeout);
        }

        clearXcapProcessTimer() {
            console.log("clearXcapProcessTimer()");
            this.xcapProcessTimer && clearTimeout(this.xcapProcessTimer);
            this.xcapProcessTimer = null;
        }

        startPresenceProcessTimer(timeout) {
            console.log("startPresenceProcessTimer()");
            this.clearPresenceProcessTimer();
            const _this = this;
            this.presenceProcessTimer = setTimeout(() => {
                _this.ua.startPresenceProcess();
            }, timeout);
        }

        clearPresenceProcessTimer() {
            console.log("clearPresenceProcessTimer()");
            this.presenceProcessTimer && clearTimeout(this.presenceProcessTimer);
            this.presenceProcessTimer = null;
        }

        startProfileFetchTimer(timeout) {
            console.log("startProfileFetchTimer()");
            this.clearProfileFetchTimer();
            const _this = this;
            this.profilefetchTimer = setTimeout(() => {
                _this.ua.startFetchingProfile();
            }, timeout);
        }

        clearProfileFetchTimer() {
            console.log("clearProfileFetchTimer()");
            this.profilefetchTimer && clearTimeout(this.profilefetchTimer);
            this.profilefetchTimer = null;
        }

        getLoginState() {
            return this.loginState;
        }
        getTransportState() {
            return this.transportState;
        }
        getRegistrationState() {
            return this.registerState;
        }
        getXacpState() {
            return this.xcapState;
        }
        getPresenceState() {
            return this.presenceState;
        }

        isRegistered() {
            return this.registerState === stateType.REGISTRATION_STATE.Registered;
        }

        isLoggedIn() {
            return this.loginState === stateType.LOGIN_STATE.LoggedIn;
        }

        isTransportConnected() {
            return this.transportState === stateType.TRANSPORT_STATE.Connected;
        }

    }

    exports.RegisterProcessStates = RegisterProcessStates;
})(typeof exports === "undefined" ? (this["RegisterStates"] = {}) : exports);
