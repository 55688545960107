import React, { useEffect, useState } from 'react'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from "@material-ui/styles";

const UserLocation = (props) => {
  const { uplodatedLoc, locData } = props
  const initialState = {
    hour: '0',
    min: '0',
    sec: '10'
  }
  const [state, setstate] = useState(initialState)
  const [emptyHour, setEmptyHour] = useState(false);

  useEffect(() => {
    setstate({
      ...state,
      hour: locData.locationHour,
      min: locData.locationMin,
      sec: locData.locationSec,
    })
  }, [])

  const tetraUserChangeHandler = (e) => {
    setstate({ ...state, timerOnOFF: e.target.checked });
    if (uplodatedLoc) {
      uplodatedLoc(e.target.checked, state.hour, state.min, state.sec);
    }
  }
  const hourChangeHandler = (e) => {
    setstate({ ...state, hour: e.target.value });
    if (uplodatedLoc) {
      uplodatedLoc(true, e.target.value, state.min, state.sec);
    }
  }

  const minChangeHandler = (e) => {
    setstate({ ...state, min: e.target.value });
    if (uplodatedLoc) {
      uplodatedLoc(true, state.hour, e.target.value, state.sec);
    }
  }

  const secChangeHandler = (e) => {
    setstate({ ...state, sec: e.target.value })
    if (uplodatedLoc) {
      uplodatedLoc(true, state.hour, state.min, e.target.value);
    }
  }

  
  const useStyle = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
    },
    formControl: {
      width: '100%',
      marginTop: '5px',
    },
    formControlFA: {
      width: '80%',
      height: '60px',
      marginTop: '5px',
    },
    listMember_all: {
      // width: 250,
      maxHeight: 200
    },
    listItemFA: {
      padding: '5px',
      fontFamily: 'Muli'
    },
    tetraSwitch: {
      fontFamily: 'Muli',
      marginTop: '2px',
      marginRight: '1px'
    },
    lblStyle: {
      width: '100%',
      color: "#D0D3D4",
      fontSize: 18,
      fontWeight: 600,
      marginLeft: 10,
      marginTop: 12,
      justifyContent: "center",
      alignItems: "center"
    },
    lblHeader: {
      width: '100%',
      color: "#D0D3D4",
      fontSize: 20,
      fontWeight: 700
    },
  }));
  const classes = useStyle()
  return (
    <div>
      {/* <FormControlLabel className={classes.tetraSwitch}
        value="start"
        control={<Switch color="primary" checked={state && state.timerOnOFF} onChange={tetraUserChangeHandler} />}
        label="Location ON/OFF"
        labelPlacement='start'
        onChange={tetraUserChangeHandler}

      /> */}

      <div class="form-group">
        <label className={classes.lblHeader}>Location</label>
        <div className="hang-time-conatiner">
          {/* <input
            type="text"
            class="hang-time-input"
            id="_hrs"
            maxLength="2"
            pattern="[0-9]*"
            value={state.hour}
            onChange={hourChangeHandler}
          />
          <label class="hang-time-label">Hr</label>
          <input
            type="text"
            class="hang-time-input"
            id="hangtimer_min"
            maxLength="2"
            pattern="[0-9]*"
            value={state.min}
            onChange={minChangeHandler}
          />
          <label class="hang-time-label">Min</label> */}
          <input
            type="text"
            className="input-control-flat"
            id="hangtimer_sec"
            maxLength="2"
            pattern="[0-9]*"
            value={state.sec}
            onChange={secChangeHandler}
          />
          <label className={classes.lblStyle}>Sec</label>
        </div>
        {
          emptyHour === true ?
            (
              <label class="error-handling-lbl">Please enter timer</label>
            )
            :
            null
        }
      </div>
    </div>
  )
}

export default UserLocation;
