// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#device-testing hr {
  margin: 25px 0;
}
#device-testing .center {
  text-align: center;
}
#device-testing audio {
  display: block;
  margin: 10px auto;
}
#device-testing .sound-wave {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/deviceTesting.scss"],"names":[],"mappings":"AAGE;EACE,cAAA;AAFJ;AAIE;EACE,kBAAA;AAFJ;AAIE;EACE,cAAA;EACA,iBAAA;AAFJ;AAIE;EACE,WAAA;AAFJ","sourcesContent":["@import \"theme\";\n\n#device-testing{\n  hr{\n    margin: 25px 0;\n  }\n  .center{\n    text-align: center;\n  }\n  audio{\n    display: block;\n    margin: 10px auto;\n  }\n  .sound-wave{\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
