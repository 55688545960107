import React, { useEffect, useState } from 'react'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/styles";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const UserPermissions = (props) => {
  const { permissions, updateUserPermissions } = props
  const grabTypes = [
    {
      type: "All LA",
      value: "all"
    },
    {
      type: "Default",
      value: "default"
    },
    {
      type: "None",
      value: "none"
    }
  ]
  const trainMovementsTypes = [
    {
      type: "No movement visible",
      value: "none"
    },
    {
      type: "All train movement visible",
      value: "all"
    },
    {
      type: "Base station only",
      value: "default"
    }
  ]
  const [userPermission, setUserPermission] = useState(permissions)

  useEffect(() => {
    console.log('basicdetails update permissions useeffect..', permissions)
    setUserPermission(permissions)
  }, [permissions])

  const trainTabChangeHandler = (e) => {
    setUserPermission({ ...userPermission, trainTab: e.target.checked })
    updateUserPermissions({ ...userPermission, trainTab: e.target.checked })
  };

  const communicationTabChangeHandler = (e) => {
    setUserPermission({ ...userPermission, communicationTab: e.target.checked })
    updateUserPermissions({ ...userPermission, communicationTab: e.target.checked })
  };

  const alertsTabrChangeHandler = (e) => {
    setUserPermission({ ...userPermission, alertTab: e.target.checked })
    updateUserPermissions({ ...userPermission, alertTab: e.target.checked })
  };

  const radioBaseChangeHandler = (e) => {
    setUserPermission({ ...userPermission, baseStation: e.target.checked })
    updateUserPermissions({ ...userPermission, baseStation: e.target.checked })
  };

  const locationTabChangeHandler = (e) => {
    setUserPermission({ ...userPermission, locationTab: e.target.checked })
    updateUserPermissions({ ...userPermission, locationTab: e.target.checked })
  };

  const recordingsTabChangeHandler = (e) => {
    setUserPermission({ ...userPermission, recordingsTab: e.target.checked })
    updateUserPermissions({ ...userPermission, recordingsTab: e.target.checked })
  }

  const handleGrabAllowedTypeChange = (e) => {
    setUserPermission({ ...userPermission, grabAllowed: e.target.value })
    updateUserPermissions({ ...userPermission, grabAllowed: e.target.value })
  }

  const handleTrainMovementsTypeChange = (e) => {
    setUserPermission({ ...userPermission, trainMovement: e.target.value })
    updateUserPermissions({ ...userPermission, trainMovement: e.target.value })
  }

  const useStyle = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
    },
    formControl: {
      width: "96%",
      marginTop: 1,
      fontSize: 17,
      fontWeight: 600,
      backgroundColor: "#383736"
    },
    formControlFA: {
      width: "80%",
      height: "65px",
      color: "#D0D3D4",
      fontSize: 17,
      fontWeight: 600,
      backgroundColor: "#383736",
    },
    listItemFA: {
      padding: "5px",
      fontFamily: "Muli",
      color: "#D0D3D4",
      fontSize: 17,
      fontWeight: 600
    },
    listMember_all: {
      // width: 250,
      maxHeight: 200
    },
    tetraSwitch: {
      fontFamily: 'Muli',
      marginTop: '2px',
      marginRight: '1px'
    }
  }));
  const classes = useStyle()

  return (
    <div style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: "10px" }}>
      <FormControl style={{ marginRight: "8px" }} variant="filled" className={classes.formControl}>
        <InputLabel id="demo-simple-select-filled-label" className={classes.listItemFA} focused={false}>TRAIN MOVEMENTS</InputLabel>
        <Select
          className={classes.listItemFA}
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={userPermission && userPermission.trainMovement}
          onChange={handleTrainMovementsTypeChange}
          disableUnderline={true}
        >
          {trainMovementsTypes && trainMovementsTypes.map((trainMovement) => {
            return (
              <MenuItem value={trainMovement.value}>{trainMovement.type}</MenuItem>
            )
          })}
        </Select>
      </FormControl>

      <FormControl style={{ marginLeft: "8px" }} variant="filled" className={classes.formControl}>
        <InputLabel id="demo-simple-select-filled-label" className={classes.listItemFA} focused={false}>GRAB ALLOWED</InputLabel>
        <Select
          className={classes.listItemFA}
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={userPermission && userPermission.grabAllowed}
          onChange={handleGrabAllowedTypeChange}
          disableUnderline={true}
        >
          {grabTypes && grabTypes.map((grabType) => {
            return (
              <MenuItem value={grabType.value}>{grabType.type}</MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </div>
  )
}

export default UserPermissions
