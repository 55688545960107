import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import TitleTab from "./TitleTab";
import TemplateRow from "./TemplateRow.js";
import { fetchFAListAdmin, resetFADetails, deleteFAAdmin } from '../../modules/adminstate';
import { Modal } from 'react-bootstrap'
import FATemplates from './fatemplates';
import { DataGrid } from "@mui/x-data-grid";
import FadeInOut from "./FadeInOut.js"
import { makeStyles } from "@material-ui/core/styles";
import AlertDialog from "./DeleteUserDialog.js";

const Templates = (props) => {
  const { falist, fetchFAListAdmin, resetFADetails, deleteFAAdmin } = props
  const [faTemplates, setFaTemplates] = useState([])
  const [showFA, setShowFA] = useState(false);
  const [openUserEditMode, setOpenUserEditMode] = useState(false);
  const [show, setShow] = useState(false)
  const [selectedFa, setSelectedFa] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false);
  const [faName, setFaName] = useState('')
  const [filterName, setFilterName] = useState('FA List')
  useEffect(() => {
    fetchFAListAdmin()
  }, [])

  useEffect(() => {
    if (falist) {
      setFaTemplates(falist)
      // setFaTemplates(falist.filter(item => item.tenantId == global.config.userConfig.tenantId))
    }
  }, [falist])

  const useStyles = makeStyles((theme) => ({
    root: {
      //backgroundColor: theme.palette.background.paper,
      // paddingTop: 10,
      // paddingLeft: 20,
      // paddingRight: 20,
      width: "100%",
      //height: "70%",
      overflowY: "scroll",
      display: 'flex',
      flexDirection: 'column',
      // justifyContent: 'center',
    },
    faTypeClass: {
      width: "92.5%",
      // height: 800,
      // display: 'flex',
      flexDirection: 'column',
      justifyContent: "center",
      alignItems: "center",
      border: "solid",
      borderRadius: 10,
      borderWidth: "1px",
      borderColor: "#4B4A4A",
      marginLeft: 20,
      //marginTop: 5,
      marginBottom: 10,
      marginTop: 20,
      overflowY: "scroll",
    },
    divList: {
      width: '100%',
      marginBottom: '13px',
    },
  }));

  const classes = useStyles();

  const userhandleClose = () => {
    resetFADetails()
    setShowFA(false);
    setShow(false);
    setOpenUserEditMode(false);
  };

  const filterData = (type) => {
    setFilterName('FA LIST')
    resetFADetails()
    if (type === "fa") {
      setShow(false)
      setShowFA(false)
    } else {
      setShow(true)
      setShowFA(true);
    }
    setOpenUserEditMode(false);
  }

  const hideModal = () => {
    resetFADetails()
    setShowFA(false);
    setShow(false);
    setOpenUserEditMode(false);
    //fetchFAListAdmin()
  }

  const columns = [
    {
      field: 'userIcon',
      headerName: '',
      width: 60,
      height: 50,
      sortable: false,
      headerClassName: "list-head-Val",
      align: "center",
      headerAlign: "center",
      renderCell: (cellValue) => {
        return (
          <div class="tr-tb-icon">
            <img
              src='/assets/images/Vector-7.svg'
              class="single-user-img"
              alt=""
            />
          </div>
        )
      }
    },
    {
      field: 'CallerDescr', headerName: 'Fa Name', width: 306, sortable: false,
      headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
      // valueGetter: (value, row) => `${getProductName(row)}`,
    },
    {
      field: 'edit',
      headerName: '',
      width: 40,
      height: 50,
      sortable: false,
      headerClassName: "list-head-Val",
      align: "center",
      headerAlign: "center",
      renderCell: (cellValue) => {
        return (
          <button
            class="editBtn"
            onClick={() => { openUserEditPage(cellValue.row) }}
            type="button"
            name=""
          >
            <img
              src="/assets/images/editimg.svg"
              class="edit-user-img"
              alt=""
            />
          </button>
        )
      }
    },
    {
      field: 'delete',
      headerName: '',
      width: 60,
      height: 50,
      sortable: false,
      headerClassName: "list-head-Val",
      align: "center",
      headerAlign: "center",
      renderCell: (cellValue) => {
        return (
          <>
            <button
              class="editBtn"
              onClick={() => { deleteButtonClick(cellValue.row) }}
              type="button"
              name=""
            >
              <img
                src="/assets/images/deleteimg.svg"
                class="delete-user-img"
                alt=""
              />
            </button>
          </>
        )
      },
    },
  ];

  const openUserEditPage = (val) => {
    setSelectedFa(val)
    setOpenUserEditMode(true);
    setShowFA(true)
    setShow(true);
  }

  const deleteButtonClick = (val) => {
    if (val.name && val.CallerDescr) {
      setFaName(val.CallerDescr)
      setDialogOpen(true)
      setSelectedFa(val)
    }
  }
  const deleteDailogHandler = (val) => {
    setDialogOpen(false)
    if (val) {
      deleteFAAdmin(selectedFa.name)
    }
  }
  useEffect(() => {
    showFA == true && (!openUserEditMode ? setFilterName('Add FA') : setFilterName('Edit FA'))
  }, [openUserEditMode, showFA])
  return (
    <div className={classes.root}>
      <TitleTab
        title={filterName}
        type={"template"}
        search={() => { }}
        filtr={(x) => {
          //console.log("TYPE", x);
          filterData(x)
        }}
        userOpen={showFA}
      />
      {show ?
        <div className={classes.faTypeClass}>
          <FadeInOut show={show} duration={500}>
            {openUserEditMode === false ?
              <FATemplates hideModal={hideModal} />
              :
              <FATemplates hideModal={hideModal} faName={selectedFa.name} propsTetra={selectedFa.tetra} />
            }
          </FadeInOut>
        </div>
        :
        <DataGrid
          rows={faTemplates}
          getRowId={(row) => row.name}
          columns={columns}
          rowHeight={68}
          hideFooter
          hideFooterPagination
          hideFooterSelectedRowCount
          disableColumnMenu
          localeText={{ noRowsLabel: "" }}
          getRowSpacing={params => ({
            top: params.isFirstVisible ? 0 : 0,
            bottom: params.isLastVisible ? 0 : 0
          })}
          sx={{
            boxShadow: 2,
            border: "none",
            borderBottom: "none",
            '& .MuiDataGrid-cell:hover': {
              color: '#ffb01f',
            },
            '& .MuiDataGrid': {
              border: 'none',
            },
            '& .MuiDataGrid-cell': {
              borderColor: "#404040",
            },
            '& .MuiDataGrid-columnHeader': {
              borderColor: "gray",
            },
            '& .MuiDataGrid-filler': {
              backgroundColor: "#262626",
            },
          }}
        />
      }
      <AlertDialog open={dialogOpen} deleteDailogHandler={deleteDailogHandler} userName={faName} />
      {/* <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <div style={{ height: '900px', marginTop: '5px', overflowY: 'scroll' }}>
            {faTemplates &&
              faTemplates.map((data, i) => {
                return <TemplateRow key={i} faUser={data} tetraUser={props.tetraUser} />;
              })}
          </div>
        </div>
      </div>
      <Modal
        show={showFA}
        onHide={userhandleClose}
        scrollable={false}
        size={"lg"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ backgroundColor: ' rgba(0,0,0,0.5)' }}
      >
        <Modal.Header closeButton style={{ border: '0px', backgroundColor: '#282828' }}>
          <Modal.Title>ADD FA Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '5px' }}>
          <FATemplates hideModal={hideModal} />
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

const mapStateToProps = ({ adminstate }) => {
  const { falist } = adminstate;
  //console.log('userlist reducer', userlist)
  return {
    falist
  };
};

export default connect(mapStateToProps, {
  fetchFAListAdmin,
  resetFADetails,
  deleteFAAdmin
})(Templates);

