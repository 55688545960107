import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddBoxIcon from '@material-ui/icons/AddBox';

const MCXServices = (props) => {
    const { selectedUeTypes, updatedServices, allowedServices, selectedServices, serviceTreeConfig, serviceUeTypeConfig, userMode, updatedTabAndServiceConfig, viewMode } = props;
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);

    useEffect(() => {
        setChecked(selectedServices);
    }, [selectedServices])

    //console.log("mcxservice selectedServices", selectedUeTypes);

    const useStyles = makeStyles((theme) => ({
        root: {
            // backgroundColor: theme.palette.background.paper,
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            width: "98%",
            height: userMode || viewMode ? 300 : 500,
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
            border: "solid",
            borderRadius: 16,
            borderWidth: "1px",
            borderColor: "#4B4A4A",
        }
    }));

    const updatedChangesServices = (services) => {
        console.log("servicesss", services);
        const servicess = new Set(services);
        if (servicess.has("TIS") || servicess.has("TERM") || servicess.has("EX_TERM")) {
            if (!servicess.has("MCPTT-HubReg") || !servicess.has("MCPTT-EdgeReg")) {
                let newServices = [...services, "MCPTT-HubReg"];
                console.log("servicesssN", newServices);
                setChecked(newServices);
                if (updatedServices) {
                    updatedServices(newServices);
                }
            }
        } else {
            if (updatedServices) {
                updatedServices(services);
            }
        }
    }

    const checkTabAndServiceConfig = (services) => {
        if (updatedTabAndServiceConfig && userMode) {
            let updateServiceConfig = {};
            for (let service of services) {
                if (service.toLowerCase() === "forwardingcallfeature") {
                    updateServiceConfig = { ...updateServiceConfig, enableCallForwarding: true };
                }
                if (service.toLowerCase() === "cadcallfeature") {
                    updateServiceConfig = { ...updateServiceConfig, enableCadCall: true };
                }
                if (service.toLowerCase() === "mcptt-fa") {
                    updateServiceConfig = { ...updateServiceConfig, enableFA: true };
                }
                if (service.toLowerCase() === "mcdata-location") {
                    updateServiceConfig = { ...updateServiceConfig, locService: true };
                    for (let ueType of selectedUeTypes) {
                        if (ueType.toLowerCase() === "dispatcher") {
                            updateServiceConfig = { ...updateServiceConfig, locationTab: true };
                            break;
                        }
                    }
                }
                if (service.toLowerCase() === "train-tab") {
                    updateServiceConfig = { ...updateServiceConfig, trainTab: true };
                }
                if (service.toLowerCase() === "alerts") {
                    updateServiceConfig = { ...updateServiceConfig, alertTab: true };
                }
                if (service.toLowerCase() === "radio-base-station") {
                    updateServiceConfig = { ...updateServiceConfig, baseStation: true };
                }
                if (service.toLowerCase() === "recording-tab") {
                    updateServiceConfig = { ...updateServiceConfig, recordingsTab: true };
                }
                
            }

            for (let service of allowedServices) {
                if (service.toLowerCase() === "mcptt-hubreg" || service.toLowerCase() === "mcdata-hubreg" || service.toLowerCase() === "mcvideo-hubreg" 
                || service.toLowerCase() === "mcptt-edgereg" || service.toLowerCase() === "mcdata-edgereg" || service.toLowerCase() === "mcvideo-edgereg") {
                    updateServiceConfig = { ...updateServiceConfig, communicationTab: true };
                }
                if (service.toLowerCase() === "mcvideo-hubreg" || service.toLowerCase() === "mcvideo-edgereg") {
                    updateServiceConfig = { ...updateServiceConfig, VideoEnable: true };
                }
                if (service.toLowerCase().includes("broadcast")) {
                    updateServiceConfig = { ...updateServiceConfig, broadCastCall: true };
                }
            }
            
            updatedTabAndServiceConfig(updateServiceConfig);
        }
    }


    const classes = useStyles();

    const SuperHeaderLabel = ({ children }) => <span style={{
        fontSize: 22,
        fontWeight: 600,
        color: "#D0D3D4", fontFamily: "Muli"
    }}>{children}</span>;
    const HeaderLabel = ({ children }) => <span style={{
        fontSize: 21,
        fontWeight: 600,
        color: "#D0D3D4", fontFamily: "Muli"
    }}>{children}</span>;
    const SubHeaderLabel = ({ children }) => <span style={{
        fontSize: 20,
        fontWeight: 580,
        color: "#D0D3D4", margin: "5px", fontFamily: "Muli"
    }}>{children}</span>;
    const NodeLabel = ({ children }) => <span style={{
        fontSize: 19,
        color: "#D0D3D4", margin: "5px", fontFamily: "Muli"
    }}>{children}</span>;


    const calculateNodeFinalWay = () => {
        let treeNode = [];
        let parentNodes = serviceTreeConfig["parentNodes"];
        let leafNodes = null;
        //console.log("selectedServices ", selectedServices);
        if (allowedServices && allowedServices.length > 0) {
            leafNodes = new Set(allowedServices);
        } else {
            leafNodes = new Set(serviceTreeConfig["leafNodes"]);
        }
        if (leafNodes === null) {
            return treeNode;
        }
        for (let parentNode of parentNodes) {
            // "MCX"
            let pNode = {
                value: parentNode,
                label: <SuperHeaderLabel>{parentNode}</SuperHeaderLabel>
            }
            let pchilds = [];
            if (isNodeAvailableForUE(selectedUeTypes, parentNode)) {
                let childsForNode = serviceTreeConfig["subNodesWithLeaf"][parentNode]; //MCX
                //console.log("childsForNode ", childsForNode);
                // "MCPTT", "MCDATA", "MCVIDEO"
                if (childsForNode && childsForNode.length > 0) {
                    for (let childNode of childsForNode) {
                        if (leafNodes.has(childNode)) {
                            // This is leaf node, no child node for this node
                            let leafNode = {
                                value: childNode,
                                label: <NodeLabel>{childNode}</NodeLabel>,
                            }
                            pchilds = [...pchilds, leafNode];
                        } else {
                            if (isNodeAvailableForUE(selectedUeTypes, childNode)) {
                                let childSubNode = {
                                    value: childNode,
                                    label: <HeaderLabel>{childNode}</HeaderLabel>
                                }
                                let children = [];
                                //check reg for node if available
                                let isRegNodeAvailForThisNode = false;
                                let selectedRegVal = null;
                                if (serviceTreeConfig["nodeReg"][childNode]) {
                                    isRegNodeAvailForThisNode = true;
                                    let regSet = new Set(serviceTreeConfig["nodeReg"][childNode]);
                                    let filterRegData = checked.filter(data => regSet.has(data));
                                    if (filterRegData && filterRegData.length > 0) {
                                        selectedRegVal = filterRegData[0];
                                    }
                                    for (let regVal of serviceTreeConfig["nodeReg"][childNode]) {
                                        let disabled = false;
                                        if (selectedRegVal) {
                                            if (selectedRegVal !== regVal) {
                                                disabled = true;
                                            }
                                        }
                                        let regNode = {
                                            value: regVal,
                                            label: <SubHeaderLabel>{regVal}</SubHeaderLabel>,
                                            disabled: viewMode ? true : disabled
                                        }
                                        children = [...children, regNode];
                                    }
                                }
                                if (isRegNodeAvailForThisNode) {
                                    let regServiceName = childNode + "-Services";
                                    let disabledNode = selectedRegVal ? false : true;
                                    let regServiceNode = {
                                        value: regServiceName,
                                        label: <SubHeaderLabel>{regServiceName}</SubHeaderLabel>,
                                        disabled: disabledNode
                                    }
                                    let cchilds = traverseToLeafNode(childNode, leafNodes, disabledNode);
                                    if (cchilds && cchilds.length > 0) {
                                        regServiceNode["children"] = cchilds;
                                        children = [...children, regServiceNode];
                                        childSubNode["children"] = children;
                                    }
                                } else {
                                    // console.log("childeNodeee ", childNode);
                                    let cchilds = traverseToLeafNode(childNode, leafNodes, false);
                                    //console.log("cchilds pNode", cchilds);
                                    if (cchilds && cchilds.length > 0) {
                                        childSubNode["children"] = cchilds;
                                    }
                                }
                                if (childSubNode["children"]) {
                                    pchilds = [...pchilds, childSubNode];
                                }
                            }
                        }
                    }
                    if (pchilds && pchilds.length > 0) {
                        pNode["children"] = pchilds;
                        treeNode = [...treeNode, pNode];
                    }
                }
            }
        }
        //console.log("treeNode ", treeNode);
        return treeNode;
    }

    const traverseToLeafNode = (pNode, leafNodes, disabledNode = false) => {
        let childsForNode = serviceTreeConfig["subNodesWithLeaf"][pNode];
        let children = [];
        if (childsForNode && childsForNode.length > 0) {
            for (let childNode of childsForNode) {
                if (isNodeAvailableForUE(selectedUeTypes, childNode)) {
                    if (leafNodes.has(childNode)) {
                        // This is leaf node, no child node for this node
                        if (userMode) {
                            if (isLeafNodeIsAvailInUserLeaf(childNode)) {
                                let leafNode = {
                                    value: childNode,
                                    label: <NodeLabel>{childNode}</NodeLabel>,
                                    disabled: disabledNode
                                }
                                children = [...children, leafNode];
                            }
                        } else {
                            if (viewMode) {
                                if (isLeafNodeIsSelectedServiceList(childNode)) {
                                    let leafNode = {
                                        value: childNode,
                                        label: <NodeLabel>{childNode}</NodeLabel>,
                                        disabled: true
                                    }
                                    children = [...children, leafNode];
                                }
                            } else {
                                let leafNode = {
                                    value: childNode,
                                    label: <NodeLabel>{childNode}</NodeLabel>,
                                    disabled: disabledNode
                                }
                                children = [...children, leafNode];
                            }
                        }
                        //console.log("cchilds leafNode", children);
                    } else {
                        // get child nodes for this node if any
                        let childSubNode = {
                            value: childNode,
                            label: <NodeLabel>{childNode}</NodeLabel>,
                            disabled: disabledNode
                        }
                        let cchilds = traverseToLeafNode(childNode, leafNodes, disabledNode);
                        if (cchilds && cchilds.length > 0) {
                            childSubNode["children"] = cchilds;
                            children = [...children, childSubNode];
                            //console.log("cchilds cNode", childSubNode);
                        }
                    }
                }
            }
        }
        return children;
    }

    const isNodeAvailableForUE = (ueTypes, selectedNode) => {
        let found = false;
        let allowedNodesForUEs = serviceTreeConfig && serviceTreeConfig["allowedNodesForUE"] ? new Set(Object.keys(serviceTreeConfig["allowedNodesForUE"])) : [];
        for (let ueType of ueTypes) {
            let ueTypeVal = ueType;
            if (serviceUeTypeConfig) {
                ueTypeVal = serviceUeTypeConfig[ueType];
            }
            if (allowedNodesForUEs.has(ueTypeVal)) {
                for (let node of serviceTreeConfig["allowedNodesForUE"][ueTypeVal]) {
                    if (selectedNode === node) {
                        found = true;
                        break;
                    }
                }
            }
        }
        return found;
    }

    const getSelectedNodes = (ueTypes, selectedNodes) => {
        let selectedNodeSet = new Set(selectedNodes);
        let selectedLeaf = new Set([]);
        let allowedNodesForUEs = serviceTreeConfig && serviceTreeConfig["allowedNodesForUE"] ? new Set(Object.keys(serviceTreeConfig["allowedNodesForUE"])) : [];
        for (let ueType of ueTypes) {
            if (allowedNodesForUEs.has(ueType)) {
                for (let node of serviceTreeConfig["allowedNodesForUE"][ueType]) {
                    if (selectedNodeSet.has(node) && !selectedLeaf.has(node)) {
                        selectedLeaf.add(node);
                    }
                }
            }
        }
        return selectedLeaf;
    }

    const isLeafNodeIsAvailInUserLeaf = (leaf) => {
        let userLeafs = serviceTreeConfig && serviceTreeConfig["userLeafNodes"] ? new Set(serviceTreeConfig["userLeafNodes"]) : new Set();
        if (userLeafs && userLeafs.has(leaf)) {
            return true;
        }
        return false;
    }

    const isLeafNodeIsSelectedServiceList = (leaf) => {
        let userLeafs = selectedServices && selectedServices.length > 0 ? new Set(selectedServices) : new Set();
        if (userLeafs && userLeafs.has(leaf)) {
            return true;
        }
        return false;
    }

    const makenodeSubnodes = () => {
        if (serviceTreeConfig) {
            return calculateNodeFinalWay();
        } else {
            console.log("No service tree config found");
            return [];
        }
    }


    return (
        <div className={classes.root}>
            <CheckboxTree
                nodes={makenodeSubnodes()}
                checked={checked}
                expanded={expanded}
                onCheck={(nodess) => {
                    updatedChangesServices(nodess);
                    setChecked(nodess);
                    if (userMode) {
                        checkTabAndServiceConfig(nodess);
                    }
                }}
                onExpand={(expanded) => {
                    setExpanded(expanded)
                }}
                showNodeIcon={false}
                //checkModel="all" //"#ffb01f" "#008000"
                showExpandAll={true}
                onlyLeafCheckboxes={true}
                icons={{
                    check: <CheckBoxIcon fontSize="medium" style={{
                        color: "#ffb01f",
                    }} />,
                    uncheck: <CheckBoxOutlineBlankIcon fontSize="medium" style={{
                        color: "#ffb01f"
                    }} />,
                    halfCheck: <IndeterminateCheckBoxIcon fontSize="medium" style={{
                        color: "#ffb01f"
                    }} />,
                    expandClose: <NavigateNextIcon style={{color: "white"}} fontSize="medium" />,
                    expandOpen: <ExpandMoreIcon style={{color: "white"}} fontSize="medium" />,
                    expandAll: <AddBoxIcon fontSize="large" style={{
                        color: "#ffb01f",
                    }} />,
                    collapseAll: <IndeterminateCheckBoxIcon fontSize="large" style={{
                        color: "#ffb01f",
                    }} />,
                }}
            />
        </div>
    )
}

const mapStateToProps = ({ mtmstate }) => {
    const { serviceTreeConfig, serviceUeTypeConfig } = mtmstate;
    return {
        serviceTreeConfig,
        serviceUeTypeConfig
    };
};


export default connect(mapStateToProps, null)(MCXServices);
