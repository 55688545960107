import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles, useTheme, ThemeProvider } from "@material-ui/core/styles";
import { Typography, FormControl, InputLabel, Select, MenuItem, Box, Checkbox, Grid } from "@material-ui/core";
import { fetchDeviceInventory, allocateDevice, updateDeviceInventory } from "../../../modules/actions/mdmAction";

const AllocateDevice = (props) => {
    const { deviceTypeList, fetchDeviceTypeLis, device, deviceInventoryList, fetchDeviceInventory, tenantList, configList, allocateDevice, updateEnableScreen, updateDeviceInventory, closeModal } = props
    const [deviceData, setDeviceData] = useState()
    const [enableScreen, setEnableScreen] = useState(updateEnableScreen)
    const [listType, setListType] = useState()
    const [createConfig, setCreateConfig] = useState([])
    const [enableCreateConfig, setEnableCreateConfig] = useState()
    const [selectedCheckedBox, setSelectedCheckbox] = useState([])
    const [configOption, setConfigOption] = useState([])
    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: "#252524",
            //backgroundColor: theme.palette.background.default,
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            width: "100%",
            //height: "70%",
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
        },
        formControlFA: {
            width: "60%",
            height: "65px",
            marginTop: "2px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600,
            backgroundColor: "#383736"
        },
        listItemFA: {
            padding: "5px",
            fontFamily: "Muli",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        productTypeClass: {
            width: "90%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            border: "solid",
            borderRadius: 10,
            borderWidth: "2px",
            borderColor: "#4B4A4A",
            marginTop: 5,
            marginBottom: 10
        },
        divList: {
            width: '100%',
            marginBottom: '13px',
        },
        lblStyle: {
            width: '100%',
            color: "#D0D3D4",
            fontSize: 18,
            fontWeight: 600
        },
        tetraSwitch: {
            fontFamily: "Muli",
            marginTop: "2px",
            marginRight: "1px",
            marginLeft: "0px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        datePickerStyle: {
            color: "black",
            padding: "10px",
            fontFamily: "Sans-Serif",
            width: '60%',
            border: "solid",
            borderRadius: 20,
            borderWidth: "2px",
            borderColor: "#D3D3D3",
        }
    }));
    const classes = useStyles();
    const theme = useTheme();

    const onSelectHandler = (e) => {
        let data = { ...deviceData }
        data[e.target.name] = e.target.value
        setDeviceData({ ...data })
        if (e.target.name == "deviceType") {
            const filteredList = configList.filter(item => item.deviceType === e.target.value)
            let option = filteredList.map(r => {
                return { text: r.name, value: r.id }
            })
            setConfigOption([...option])
        }
    }

    const listTypeHandler = (e) => {
        let data = { ...listType }
        data[e.target.name] = e.target.value
        setListType({ ...data })
    }

    const createConfigHandler = (e) => {
        let data = { ...createConfig }
        if (e.target.name == "createConfiguration") {
            setEnableCreateConfig(e.target.checked)
            data[e.target.name] = e.target.checked
        } else {
            setEnableCreateConfig(true)
            data[e.target.name] = e.target.value
        }
        setCreateConfig({ ...data })
    }

    const onSubmitHandler = () => {
        fetchDeviceInventory(deviceData)
    }
    const onClickHandler = () => {
        if (createConfig && Object.keys(createConfig).includes('createConfiguration')) {
            allocateDevice({ deviceData, listType, selectedCheckedBox: Object.keys(selectedCheckedBox), createConfig })
            closeModal()

        } else {
            createConfig['createConfiguration'] = false
            allocateDevice({ deviceData, listType, selectedCheckedBox: Object.keys(selectedCheckedBox), createConfig })
            closeModal()
        }
    }

    const deviceCheckHandler = (e, id) => {
        let data = { ...selectedCheckedBox }
        if (e.target.name && e.target.name === "selectAll") {
            for (let key in deviceInventoryList) {
                data[deviceInventoryList[key].deviceId] = e.target.checked
            }
        } else {
            data[id] = e.target.checked
        }
        for (let key in data) {
            if (data[key] === false) {
                delete data[key];
            }
        }
        setSelectedCheckbox(data)
    }

    useEffect(() => {
        deviceInventoryList && deviceInventoryList.length > 0 && setEnableScreen(true)
    }, [deviceInventoryList])

    useEffect(() => {
        updateDeviceInventory([])
        setEnableScreen(false)
    }, [])

    return (
        <div class={classes.root}>
            <Grid container width="100%" spacing={2}>
                <Grid item xs={12}>
                    <Box className="tab1-account" width={'100%'} marginLeft={0}>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={0.8} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Device Type*</label>
                            </Box>
                            <FormControl
                                // variant="filled"
                                className={classes.formControlFA}
                            >
                                <InputLabel id="config" className={classes.listItemFA} focused={false} />
                                <Select
                                    className={classes.listItemFA}
                                    id="deviceType"
                                    name="deviceType"
                                    value={deviceTypeList.id}
                                    onChange={onSelectHandler}
                                    disableUnderline={true}
                                >
                                    {deviceTypeList &&
                                        deviceTypeList.map((uetype) => {
                                            return (
                                                <MenuItem value={uetype.id}>{uetype.manufacturer} {uetype.brand}</MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box class="form-group" style={{ flexDirection: 'row', display: 'flex' }} >
                            <Box flex={0.8} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Device Quantity*</label>
                            </Box>
                            <Box style={{ width: '500px' }}>
                                <input
                                    class="input-control-flat"
                                    type="number"
                                    id="deviceQuantity"
                                    name="deviceQuantity"
                                    onChange={onSelectHandler}
                                />
                            </Box>
                        </Box>
                        <Box style={{ marginRight: '25px', backgroundColor: 'red' }}>
                            <button
                                disabled={deviceData !== undefined && deviceData.deviceType && deviceData.deviceQuantity ? false : true}
                                class="update-btn-profile-big"
                                type="button"
                                onClick={onSubmitHandler}
                            >
                                {"CHECK INVENTORY"}
                            </button>
                        </Box>
                        {enableScreen &&
                            <Box style={{ marginTop: '80px' }}>
                                <Box className="form-group" style={{ flexDirection: 'row', display: 'flex' }}>
                                    <Box flex={0.8} mr="0.5em" marginTop={2} marginBottom={1}>
                                        <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Tenant List*</label>
                                    </Box>
                                    <FormControl
                                        // variant="filled"
                                        className={classes.formControlFA}
                                    >
                                        <InputLabel id="config" className={classes.listItemFA} focused={false} />
                                        <Select
                                            className={classes.listItemFA}
                                            id="tenantType"
                                            name="tenantType"
                                            // value={listType}
                                            onChange={listTypeHandler}
                                            disableUnderline={true}
                                        >
                                            {tenantList &&
                                                tenantList.map((uetype) => {
                                                    return (
                                                        <MenuItem value={uetype.value}>{uetype.name}</MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box className="form-group" style={{ flexDirection: 'row', display: 'flex' }}>
                                    <Box flex={0.8} mr="0.5em" marginTop={2} marginBottom={1}>
                                        <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Config List*</label>
                                    </Box>
                                    <FormControl
                                        // variant="filled"
                                        className={classes.formControlFA}
                                    >
                                        <InputLabel id="config" className={classes.listItemFA} focused={false} />
                                        <Select
                                            className={classes.listItemFA}
                                            id="configType"
                                            name="configType"
                                            // value={deviceData.deviceType || deviceTypeList.id}
                                            onChange={listTypeHandler}
                                            disableUnderline={true}
                                        >
                                            {configOption &&
                                                configOption.map((uetype) => {
                                                    return (
                                                        <MenuItem value={uetype.value}>{uetype.text}</MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box class="form-group" style={{ flexDirection: 'row', display: 'flex' }} >
                                    <Box flex={0.8} mr="0.5em" marginTop={2} marginBottom={1}>
                                        <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Create Config</label>
                                    </Box>
                                    <Checkbox
                                        color="primary"
                                        name={"createConfiguration"}
                                        onChange={createConfigHandler}
                                    />
                                    <Box style={{ width: '550px' }}>
                                        {enableCreateConfig &&
                                            <input
                                                class="input-control-flat"
                                                type="text"
                                                id="configurationName"
                                                name="configurationName"
                                                placeholder="Configuration Name"
                                                onChange={createConfigHandler}
                                            />}
                                    </Box>
                                </Box>
                                <Box style={{ marginLeft: "20px", marginTop: '10px' }}>
                                    <Box style={{ paddingBottom: "0px", color: 'whitesmoke', fontSize: '18px', fontWeight: '600' }}>
                                        Device List
                                    </Box>
                                    <Box className="config-table">
                                        <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                            <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Select All</label>
                                            <Checkbox
                                                checked={Object.keys(selectedCheckedBox).length == deviceInventoryList.length || false}
                                                color="primary"
                                                name={"selectAll"}
                                                onChange={deviceCheckHandler}
                                            />
                                        </Box>
                                        {deviceInventoryList && deviceInventoryList.map((item, i) => {
                                            return (
                                                <Box key={i} className="config-row" style={{ width: '50%' }}>
                                                    <label class="attribute-heading" style={{ color: 'whitesmoke' }}>{item.deviceId}</label>
                                                    <Checkbox
                                                        checked={selectedCheckedBox[item.deviceId] || false}
                                                        color="primary"
                                                        name={"checkbox"}
                                                        onChange={(e) => deviceCheckHandler(e, item.deviceId)}
                                                    />
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                </Box>
                                <Box style={{ marginRight: '25px', backgroundColor: 'red' }}>
                                    <button
                                        disabled={deviceData !== undefined && deviceData.deviceType && deviceData.deviceQuantity ? false : true}
                                        class="update-btn-profile-big"
                                        type="button"
                                        onClick={onClickHandler}
                                    >
                                        {"SUBMIT"}
                                    </button>
                                </Box>
                            </Box>

                        }
                    </Box >
                </Grid >
            </Grid >
        </div>
    )
}

const mapStateToProps = ({ mdm }) => {
    return {
        deviceTypeList: mdm.deviceTypeList,
        deviceInventoryList: mdm.deviceInventoryList,
        tenantList: mdm.tenantList,
        configList: mdm.configList
    }
}
export default connect(mapStateToProps, { fetchDeviceInventory, allocateDevice, updateDeviceInventory })(AllocateDevice);