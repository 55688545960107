import React, { Component, useState, useEffect } from "react";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { Box, Grid, Typography } from '@mui/material';
const DefaultmcpttConfigData = {
  groupType: "Prearranged",
  hangTimer: {
    hour: 0,
    min: 0,
    sec: 10
  }
}
const GrpMCPTTConfig = (props) => {
  const { mcpttconfihHandler, grpMcpttConfigData, purpose } = props
  const [newOrUpdateProfile, setNewOrUpdateProfile] = useState(true)
  const [emptySecond, setEmptySecond] = useState(false)

  const [selectedGroupType, setSelectedGroupType] = useState("Prearranged")
  const [mcpttConfigData, SetmcpttConfigData] = useState(DefaultmcpttConfigData)


  useEffect(() => {
    if (purpose === "edit") {
      SetmcpttConfigData(grpMcpttConfigData)
      setSelectedGroupType(grpMcpttConfigData.groupType)
      setEmptySecond(false)
    }
    else {
      SetmcpttConfigData(DefaultmcpttConfigData)
    }
  }, [])
  const UpdateBasicDetails = () => {
    //console.log(mcpttConfigData.hangTimer.hour===0 && mcpttConfigData.hangTimer.min===0 && mcpttConfigData.hangTimer.sec===0,mcpttConfigData.hangTimer)
    if (parseInt(mcpttConfigData.hangTimer.hour) === 0 && parseInt(mcpttConfigData.hangTimer.min) === 0 && parseInt(mcpttConfigData.hangTimer.sec) === 0) {
      setEmptySecond(true)
    }
    else {
      console.log("mcptt config data", mcpttConfigData);
      setEmptySecond(false)
      mcpttconfihHandler(mcpttConfigData)
    }


  }
  const GroupTypeOptions = [
    { text: "PREARRANGED", value: "Prearranged" },
    { text: "TEMPORARY", value: "TEMPORARY" },
  ];
  const handleUserTypeChange = (e) => {
    setSelectedGroupType(e.target.value)
    SetmcpttConfigData({
      ...mcpttConfigData,
      groupType: e.target.value
    })
    console.log("Group Type change", mcpttConfigData)

  };

  const secondChangeHandler = (e) => {
    SetmcpttConfigData({
      ...mcpttConfigData,
      hangTimer: {
        ...mcpttConfigData.hangTimer,
        sec: e.target.value ? parseInt(e.target.value) : 0
      }

    });
    if (e.target.value.length === 0) {
      setEmptySecond(false)
    }
    console.log("update hang hour", mcpttConfigData);


  };


  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#252524",
      //backgroundColor: theme.palette.background.default,
      paddingTop: 10,
      paddingLeft: 20,
      paddingRight: 20,
      width: "100%",
      //height: "70%",
      overflowY: "scroll",
      display: 'flex',
      flexDirection: 'column',
      // justifyContent: 'center',
    },
    formControlFA: {
      width: "60%",
      height: "65px",
      marginTop: "2px",
      color: "#D0D3D4",
      fontSize: 17,
      fontWeight: 600,
      backgroundColor: "#383736"
    },
    listItemFA: {
      padding: "5px",
      fontFamily: "Muli",
      color: "#D0D3D4",
      fontSize: 17,
      fontWeight: 600
    },
    productTypeClass: {
      width: "90%",
      display: 'flex',
      flexDirection: 'column',
      justifyContent: "center",
      alignItems: "center",
      border: "solid",
      borderRadius: 10,
      borderWidth: "2px",
      borderColor: "#4B4A4A",
      marginTop: 5,
      marginBottom: 10
    },
    divList: {
      width: '100%',
      marginBottom: '13px',
    },
    lblStyle: {
      width: '100%',
      color: "#D0D3D4",
      fontSize: 18,
      fontWeight: 600
    },
    tetraSwitch: {
      fontFamily: "Muli",
      marginTop: "2px",
      marginRight: "1px",
      marginLeft: "0px",
      color: "#D0D3D4",
      fontSize: 17,
      fontWeight: 600
    },
    datePickerStyle: {
      color: "black",
      padding: "10px",
      fontFamily: "Sans-Serif",
      width: '60%',
      border: "solid",
      borderRadius: 20,
      borderWidth: "2px",
      borderColor: "#D3D3D3",
    }
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <label class="tab1-heading"> Tx Rx Control</label>

      <br></br>
      <FormControlLabel
        value="start"
        control={<Switch color="primary" />}
        label="Protect Media"
        labelPlacement="start"
      />
      <br></br>
      <FormControlLabel
        value="start"
        control={<Switch color="primary" />}
        label="Protect Floor Control Signaling"
        labelPlacement="start"
      />
      <br></br>
      <FormControlLabel
        value="start"
        control={<Switch color="primary" />}
        label="Req. Multicast Floor Control Signaling"
        labelPlacement="start"
      />
      <br></br>
      <label class="tab1-heading">On Network</label> */}

      {/* <div class="main-control-grid ctl-bottom-gap">
        <div>
          <label for="grptype">Group Type</label>
        </div>
        <div>
          <input type="text" class="form-control" id="grptype" />
        </div>
      </div> */}
      <Box class="form-group">
        <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
          <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Group Type</label>
        </Box>
        {/* <input
                type="text"
                class="input-control"
                id="grptype"
                // value={basicdetails && basicdetails.userName}
                // onChange={userNameChangeHandler}
              /> */}
        <FormControl variant="filled" className={classes.formControlFA}>
          <InputLabel id="demo-simple-select-filled-label" style={{ color: 'whitesmoke' }} className={classes.listItemFA} focused={false}>Group Type</InputLabel>
          <Select
            className={classes.listItemFA}
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={selectedGroupType}
            onChange={handleUserTypeChange}
            disableUnderline={true}
          >
            {GroupTypeOptions && GroupTypeOptions.map((grouptype) => {
              return (
                <MenuItem value={grouptype.value}>{grouptype.text}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Box>
      {/* <div class="main-control-grid ctl-bottom-gap">
        <div>
          <label for="maxcount">Max Participant Count</label>
        </div>
        <div>
          <input type="text" class="form-control" id="maxcount" />
        </div>
      </div> */}
      {/* <div class="main-control-grid ctl-bottom-gap">
        <div>
          <label for="hangtimer">Hang Timer</label>
        </div>
        <div>
          <input type="text" class="form-control" id="hangtimer" />
        </div>
      </div> */}
      <Box class="form-group">
        <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
          <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Hang Timer</label>
        </Box>
        <div class="hang-time-conatiner">
          {/* <input
            type="text"
            class="hang-time-input"
            id="hangtimer_hrs"
            maxLength="2"
            pattern="[0-9]*"
            value={mcpttConfigData.hangTimer.hour}
            onChange={hourChangeHandler}
          />
          <label class="hang-time-label">Hr</label>
          <input
            type="text"
            class="hang-time-input"
            id="hangtimer_min"
            maxLength="2"
            pattern="[0-9]*"
            value={mcpttConfigData.hangTimer.min}
            onChange={minuteChangeHandler}
          />
          <label class="hang-time-label">Min</label> */}
          <input
            type="text"
            class="hang-time-input"
            id="hangtimer_sec"
            maxLength="2"
            pattern="[0-9]*"
            value={mcpttConfigData.hangTimer.sec}
            onChange={secondChangeHandler}
          />
          <label class="hang-time-label" style={{ color: 'whitesmoke' }}>Sec</label>
        </div>
        {
          emptySecond === true ?
            (
              <label class="error-handling-lbl" >Hang Timer can not be 0</label>
            )
            :
            null
        }
      </Box>
      {/* <div class="main-control-grid ctl-bottom-gap">
        <div>
          <label for="maxduration">Max Duration</label>
        </div>
        <div>
          <input type="text" class="form-control" id="maxduration" />
        </div>
      </div>
      <div class="main-control-grid ctl-bottom-gap">
        <div>
          <label for="minstart">Min number to start</label>
        </div>
        <div>
          <input type="text" class="form-control" id="minstart" />
        </div>
      </div>
      <br></br>
      <label class="tab1-heading">Timeout For ACK of required members</label>
      <div class="main-control-grid ctl-bottom-gap">
        <div>
          <label for="duration">Duration</label>
        </div>
        <div>
          <input type="text" class="form-control" id="duration" />
        </div>
      </div>
      <div class="main-control-grid ctl-bottom-gap">
        <div>
          <label for="action">Action</label>
        </div>
        <div>
          <input type="text" class="form-control" id="action" />
        </div>
      </div> */}

      <div>
        <button
          class="update-btn-profile"
          type="button"
          onClick={UpdateBasicDetails}
        >
          {newOrUpdateProfile ? 'SUBMIT' : 'UPDATE'}
        </button>
      </div>
    </div>
  );
};
export default GrpMCPTTConfig;
