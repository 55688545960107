
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import { Box, Grid } from '@mui/material';
import "react-datepicker/dist/react-datepicker.css";

const BuyOrder = (props) => {
    const { productCatalog, buyOrderData } = props;
    const [numSubscribers, setNumSubscribers] = useState(0);
    const [orderValue, setOrderValue] = useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [errorMessage, setErrorMessage] = useState('');

    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: "#252524",
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            width: "100%",
            height: 600,
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
        },
        lblStyle: {
            width: '100%',
            color: "#D0D3D4",
            fontSize: 18,
            fontWeight: 600
        },
        errorLbl: {
            width: '100%',
            color: "red",
            fontSize: 18,
            fontWeight: 600,
        }
    }));

    const getProductName = () => {
        let productName = "";
        if (productCatalog && productCatalog.productJson) {
            let productJson = productCatalog.productJson;
            console.log("productJson to buy ", productJson);
            if (productJson && productJson.name) {
                productName = productJson.name;
            } else {
                if (productCatalog.shortDescription) {
                    productName = productCatalog.shortDescription;
                }
            }
        }
        return productName;
    }

    const getMinSubscribers = () => {
        let subscribers = 0;
        if (productCatalog && productCatalog.productJson) {
            let productJson = productCatalog.productJson;
            if (productJson && productJson.minNumSubscribers) {
                subscribers = Number(productJson.minNumSubscribers);
            }
        }
        return subscribers;
    }

    const getMaxSubscribers = () => {
        let subscribers = 0;
        if (productCatalog && productCatalog.productJson) {
            let productJson = productCatalog.productJson;
            if (productJson && productJson.maxNumSubscribers) {
                subscribers = Number(productJson.maxNumSubscribers);
            }
        }
        return subscribers;
    }

    const getMinUnits = () => {
        let subscribers = 0;
        if (productCatalog && productCatalog.productJson) {
            let productJson = productCatalog.productJson;
            if (productJson && productJson.minNumUnits) {
                subscribers = Number(productJson.minNumUnits);
            }
        }
        return subscribers;
    }

    const getMaxUnits = () => {
        let subscribers = 0;
        if (productCatalog && productCatalog.productJson) {
            let productJson = productCatalog.productJson;
            if (productJson && productJson.maxNumUnits) {
                subscribers = Number(productJson.maxNumUnits);
            }
        }
        return subscribers;
    }

    const getFromDays = () => {
        let days = 0;
        if (productCatalog && productCatalog.productJson) {
            let productJson = productCatalog.productJson;
            if (productJson && productJson.fromDays) {
                days = Number(productJson.fromDays);
            }
        }
        return days;
    }

    const getToDays = () => {
        let days = 0;
        if (productCatalog && productCatalog.productJson) {
            let productJson = productCatalog.productJson;
            if (productJson && productJson.toDays) {
                days = Number(productJson.toDays);
            }
        }
        return days;
    }

    const getNumDays = () => {
        let days = 1;
        if (productCatalog && productCatalog.productJson) {
            let productJson = productCatalog.productJson;
            if (productJson && productJson.numDays) {
                days = Number(productJson.numDays);
            }
        }
        return days;
    }

    const numSubscribersChangeHandler = (e) => {
        let numberOfSubscibers = e.target.value;
        setNumSubscribers(numberOfSubscibers);

        let minSubscribers = getMinSubscribers();
        let maxSubscribers = getMaxSubscribers();
        let numDays = getNumDays();
        if (numberOfSubscibers >= minSubscribers && numberOfSubscibers <= maxSubscribers) {
            let price = productCatalog && productCatalog.price ? Number(productCatalog.price) : 0;
            let orderValue = numberOfSubscibers * price * numDays;
            setOrderValue(orderValue);
            setErrorMessage("");
        } else {
            setOrderValue(0);
            setErrorMessage("No. of Subscribers should be in range");
        }
    }

    const numDaysChangeHandler = (e) => {
        let numberOfDays = e.target.value;
        setNumSubscribers(numberOfDays);

        let minDays = getFromDays();
        let maxDays = getToDays();
        if (numberOfDays >= minDays && numberOfDays <= maxDays) {
            let price = productCatalog && productCatalog.price ? Number(productCatalog.price) : 0;
            let orderValue = numberOfDays * price;
            setOrderValue(orderValue);
            setErrorMessage("");
        } else {
            setOrderValue(0);
            setErrorMessage("No. of Days should be in range");
        }
    }

    const deviceUnitChangeHandler = (e) => {
        let numberOfUnits = e.target.value
        setNumSubscribers(numberOfUnits);

        let maxUnits = getMaxUnits();
        let minUnits = getMinUnits();
        if (numberOfUnits >= minUnits && numberOfUnits <= maxUnits) {
            let price = productCatalog && productCatalog.price ? Number(productCatalog.price) : 0;
            let orderValue = numberOfUnits * price;
            setOrderValue(orderValue);
            setErrorMessage("");
        } else {
            setOrderValue(0);
            setErrorMessage("No. of Unit should be in range");
        }
    }

    const submitBtnClick = () => {
        let buyerId = global.config.userConfig.tenantId ? global.config.userConfig.tenantId : global.config.userConfig.partnerId;
        if (productCatalog && buyerId && numSubscribers >= 0 && checkNumOfSubscriberOrDays()) {
            setErrorMessage("");
            let orderingInfo = {};
            if (productCatalog.productType === "MobileDevice") {
                orderingInfo = {
                    "noOfUnits": numSubscribers,
                }
            } else {
                if (selectedDate) {
                    const startDate = selectedDate.toISOString();
                    if (productCatalog.productSubType === "Partner") {
                        orderingInfo = {
                            "noOfSubscribers": numSubscribers,
                            "startDate": startDate
                        }
                    } else {
                        orderingInfo = {
                            "noOfDays": numSubscribers,
                            "startDate": startDate
                        }
                    }
                }
            }
            let orderData = {
                "buyerMTMId": buyerId,
                "sellerMTMId": productCatalog.creatorPartnerId,
                "productId": productCatalog.id,
                "priceType": productCatalog.priceType,
                "frequency": productCatalog.frequency,
                "priceUnit": productCatalog.priceUnit,
                "numInstallments": productCatalog.numInstallments,
                "price": productCatalog.price,
                "orderingInfo": orderingInfo,
                "orderValue": orderValue
            }
            console.log("orderDataaa ", productCatalog, JSON.stringify(orderData));
            if (buyOrderData) {
                buyOrderData(orderData);
            }
        } else {
            console.log("subscription or days or units is not validated");
            if (productCatalog && productCatalog.productSubType === "Operator") {
                setErrorMessage("No. of Days should be in range");
            } else if (productCatalog && productCatalog.productSubType === "Partner") {
                setErrorMessage("No. of Subscribers should be in range");
            } else {
                setErrorMessage("No. of Unit should be in range");
            }
        }
    }

    const checkNumOfSubscriberOrDays = () => {
        let validated = false;
        if (productCatalog && productCatalog.productSubType === "Operator") {
            let minDays = getFromDays();
            let maxDays = getToDays();
            if (numSubscribers >= minDays && numSubscribers <= maxDays) {
                validated = true;
            }
        } else if (productCatalog && productCatalog.productSubType === "Partner") {
            let minSubscribers = getMinSubscribers();
            let maxSubscribers = getMaxSubscribers();
            if (numSubscribers >= minSubscribers && numSubscribers <= maxSubscribers) {
                validated = true;
            }
        } else {
            let maxUnits = getMaxUnits();
            let minUnits = getMinUnits();
            if (numSubscribers >= minUnits && numSubscribers <= maxUnits) {
                validated = true;
            }
        }
        return validated;
    }

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container width="100%" spacing={2}>
                <Grid item xs={12}>
                    <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4}>
                        <Box flex={0.25} mr="0.5em" marginTop={2} >
                            <label className={classes.lblStyle}>Name: </label>
                        </Box>
                        <Box flex={1} mr="0.5em">
                            <input
                                type="text"
                                className="input-control-flat-read"
                                id="productBrand"
                                placeholder="Product Brand"
                                defaultValue={getProductName()}
                                readOnly={true}
                            />
                        </Box>
                    </Box>

                    <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4}>
                        <Box flex={0.25} mr="0.5em" marginTop={2} >
                            <label className={classes.lblStyle}>Type: </label>
                        </Box>
                        <Box flex={1} mr="0.5em">
                            <input
                                type="text"
                                className="input-control-flat-read"
                                id="productBrand"
                                placeholder="Product Brand"
                                defaultValue={productCatalog && productCatalog.productType}
                                readOnly={true}
                            />
                        </Box>
                    </Box>

                    <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4}>
                        <Box flex={0.25} mr="0.5em" marginTop={2} >
                            <label className={classes.lblStyle}>SubType: </label>
                        </Box>
                        <Box flex={1} mr="0.5em">
                            <input
                                type="text"
                                className="input-control-flat-read"
                                id="productBrand"
                                placeholder="Product Brand"
                                defaultValue={productCatalog && productCatalog.productSubType}
                                readOnly={true}
                            />
                        </Box>
                    </Box>

                    {productCatalog && productCatalog.productSubType === "Partner" ?
                        <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4}>
                            <Box flex={0.25} mr="0.5em" marginTop={2} >
                                <label className={classes.lblStyle}>Min Subscribers: </label>
                            </Box>
                            <Box flex={1} mr="0.5em">
                                <input
                                    type="text"
                                    className="input-control-flat-read"
                                    id="productBrand"
                                    placeholder="Product Brand"
                                    defaultValue={getMinSubscribers()}
                                    readOnly={true}
                                />
                            </Box>
                        </Box>
                        :
                        productCatalog && productCatalog.productSubType === "Operator" ?
                            <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4}>
                                <Box flex={0.25} mr="0.5em" marginTop={2} >
                                    <label className={classes.lblStyle}>From Days: </label>
                                </Box>
                                <Box flex={1} mr="0.5em">
                                    <input
                                        type="text"
                                        className="input-control-flat-read"
                                        id="productBrand"
                                        placeholder="Product Brand"
                                        defaultValue={getFromDays()}
                                        readOnly={true}
                                    />
                                </Box>
                            </Box>
                            :
                            <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4}>
                                <Box flex={0.25} mr="0.5em" marginTop={2} >
                                    <label className={classes.lblStyle}>Min Units: </label>
                                </Box>
                                <Box flex={1} mr="0.5em">
                                    <input
                                        type="text"
                                        className="input-control-flat-read"
                                        id="productBrand"
                                        placeholder="Product Brand"
                                        defaultValue={getMinUnits()}
                                        readOnly={true}
                                    />
                                </Box>
                            </Box>
                    }

                    {productCatalog && productCatalog.productSubType === "Partner" ?
                        <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4}>
                            <Box flex={0.25} mr="0.5em" marginTop={2} >
                                <label className={classes.lblStyle}>Max Subscribers: </label>
                            </Box>
                            <Box flex={1} mr="0.5em">
                                <input
                                    type="text"
                                    className="input-control-flat-read"
                                    id="productBrand"
                                    placeholder="Product Brand"
                                    defaultValue={getMaxSubscribers()}
                                    readOnly={true}
                                />
                            </Box>
                        </Box>
                        :
                        productCatalog && productCatalog.productSubType === "Operator" ?
                            <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4}>
                                <Box flex={0.25} mr="0.5em" marginTop={2} >
                                    <label className={classes.lblStyle}>To Days: </label>
                                </Box>
                                <Box flex={1} mr="0.5em">
                                    <input
                                        type="text"
                                        className="input-control-flat-read"
                                        id="productBrand"
                                        placeholder="Product Brand"
                                        defaultValue={getToDays()}
                                        readOnly={true}
                                    />
                                </Box>
                            </Box>
                            :
                            <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4}>
                                <Box flex={0.25} mr="0.5em" marginTop={2} >
                                    <label className={classes.lblStyle}>Max Units: </label>
                                </Box>
                                <Box flex={1} mr="0.5em">
                                    <input
                                        type="text"
                                        className="input-control-flat-read"
                                        id="productBrand"
                                        placeholder="Product Brand"
                                        defaultValue={getMaxUnits()}
                                        readOnly={true}
                                    />
                                </Box>
                            </Box>
                    }

                    {productCatalog && productCatalog.productSubType === "Partner" ?
                        <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4}>
                            <Box flex={0.25} mr="0.5em" marginTop={2} >
                                <label className={classes.lblStyle}>No. Of Days: </label>
                            </Box>
                            <Box flex={1} mr="0.5em">
                                <input
                                    type="text"
                                    className="input-control-flat-read"
                                    id="productBrand"
                                    placeholder="Product Brand"
                                    defaultValue={getNumDays()}
                                    readOnly={true}
                                />
                            </Box>
                        </Box>
                        :
                        null
                    }

                    <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4}>
                        <Box flex={0.25} mr="0.5em" marginTop={2} >
                            <label className={classes.lblStyle}>Price: </label>
                        </Box>
                        <Box flex={1} mr="0.5em">
                            <input
                                type="text"
                                className="input-control-flat-read"
                                id="productBrand"
                                placeholder="Product Brand"
                                defaultValue={productCatalog && productCatalog.price}
                                readOnly={true}
                            />
                        </Box>
                    </Box>

                    <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4}>
                        <Box flex={0.25} mr="0.5em" marginTop={2} >
                            <label className={classes.lblStyle}>Price Unit: </label>
                        </Box>
                        <Box flex={1} mr="0.5em">
                            <input
                                type="text"
                                className="input-control-flat-read"
                                id="productBrand"
                                placeholder="Product Brand"
                                defaultValue={productCatalog && productCatalog.priceUnit}
                                readOnly={true}
                            />
                        </Box>
                    </Box>

                    {productCatalog && productCatalog.productSubType === "Partner" ?
                        <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4}>
                            <Box flex={0.25} mr="0.5em" marginTop={2} >
                                <label className={classes.lblStyle}>No. of Subscriber: </label>
                            </Box>
                            <Box flex={1} mr="0.5em">
                                <input
                                    type="number"
                                    className="input-control-flat"
                                    id="maxConnectionsPerSubscriber"
                                    value={numSubscribers}
                                    onChange={numSubscribersChangeHandler}
                                />
                            </Box>
                        </Box>
                        :
                        productCatalog && productCatalog.productSubType === "Operator" ?
                            <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4}>
                                <Box flex={0.25} mr="0.5em" marginTop={2} >
                                    <label className={classes.lblStyle}>No. of Days: </label>
                                </Box>
                                <Box flex={1} mr="0.5em">
                                    <input
                                        type="number"
                                        className="input-control-flat"
                                        id="maxConnectionsPerSubscriber"
                                        value={numSubscribers}
                                        onChange={numDaysChangeHandler}
                                    />
                                </Box>
                            </Box>
                            :
                            <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4}>
                                <Box flex={0.25} mr="0.5em" marginTop={2} >
                                    <label className={classes.lblStyle}>No. of Devices: </label>
                                </Box>
                                <Box flex={1} mr="0.5em">
                                    <input
                                        type="number"
                                        className="input-control-flat"
                                        id="maxConnectionsPerSubscriber"
                                        value={numSubscribers}
                                        onChange={deviceUnitChangeHandler}
                                    />
                                </Box>
                            </Box>
                    }

                    <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4}>
                        <Box flex={0.25} mr="0.5em" marginTop={2} >
                            <label className={classes.lblStyle}>Start Date: </label>
                        </Box>
                        <Box flex={1} mr="0.5em">
                            <DatePicker className='input-control' selected={selectedDate} onChange={(date) => setSelectedDate(date)} />
                        </Box>
                    </Box>

                    <Box display={"flex"} width={"100%"} marginLeft={1} marginBottom={4}>
                        <Box flex={0.25} mr="0.5em" marginTop={2} >
                            <label className={classes.lblStyle}>Order Value: </label>
                        </Box>
                        <Box flex={1} mr="0.5em">
                            <input
                                type="text"
                                className="input-control-flat-read"
                                id="productBrand"
                                placeholder="Product Brand"
                                value={orderValue}
                                readOnly={true}
                            />
                        </Box>
                    </Box>

                    <Box display={"flex"} flexDirection={"row-reverse"} width={"100%"}>
                        <box display={"flex"} flexDirection={"column"}>
                            <box display={"flex"} marginTop={1} marginBottom={1}>
                                {errorMessage.length > 0 ?
                                    <div className={classes.errorLbl}>
                                        {errorMessage}
                                    </div>
                                    : null
                                }
                            </box>
                            <box display={"flex"} marginBottom={2}>
                                <div className="form-group">
                                    <button
                                        className="update-btn-profile-big"
                                        type="button"
                                        onClick={submitBtnClick}
                                    >
                                        {"Proceed to Buy"}
                                    </button>
                                </div>
                            </box>
                        </box>

                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}

export default BuyOrder;