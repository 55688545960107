// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert {
  position: fixed;
  right: 0;
  bottom: 0px;
  width: auto;
  padding: 20px;
  z-index: 9999999;
}
.alert.left {
  left: 0;
  right: auto;
  height: 100vh;
  overflow-y: auto;
  top: 115px;
  padding-bottom: 135px;
}
.alert.left .ui.message {
  color: white;
}
.alert .ui.message {
  display: block !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/alert.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,QAAA;EACA,WAAA;EACA,WAAA;EAEA,aAAA;EACA,gBAAA;AAFF;AAIE;EACE,OAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;EACA,UAAA;EACA,qBAAA;AAFJ;AAGI;EACE,YAAA;AADN;AAIE;EACE,yBAAA;AAFJ","sourcesContent":["@import \"theme\";\n\n.alert{\n  position: fixed;\n  right: 0;\n  bottom: 0px;\n  width: auto;\n  // background: white;\n  padding: 20px;\n  z-index: 9999999;\n  // font-size: 13px;\n  &.left{\n    left: 0;\n    right: auto;\n    height: 100vh;\n    overflow-y: auto;\n    top: 115px;\n    padding-bottom: 135px;\n    .ui.message{\n      color: white;\n    }\n  }\n  .ui.message{\n    display: block !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
