import * as React from 'react';
import { Box } from '@material-ui/core'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
export default function Errormesspopup(props) {
    
  return (
    <Box sx={style}>
        <div style={{display:"flex",flexDirection:"row-reverse"}}>
  
  <span style={{fontWeight:"600",position:"relative",top:"-20px",right:"-20px",cursor:"pointer"}} onClick={()=>{props.handleErrorModel()}}>X</span>
  
  </div>
        <div>
  
  <h2 style={{marginTop:"10px",fontWeight:"600",color:"red"}}>{props.message}!!</h2>

  </div>
  <button style={{marginTop:"5px",position:"relative",left:"40%"}} onClick={()=>{props.handleofffindmydevice()}}>Exit</button>

      </Box>
  );
}
