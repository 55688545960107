(function (exports) {
  const EndPoints = require("../endpoints").EndPoints;
  const Emmiter = require("events").EventEmitter;
  const DOMParser = require("xmldom").DOMParser;
  const subscriber = require("./subscriber").CMCsubscriber;
  const UserProfile = require("./userProfile").UserProfile;
  const FaProfile = require("./FaProfile").FaProfile;
  const js2xmlparser = require("js2xmlparser");
  const axios = require("axios").default;

  class CMC extends Emmiter {
    constructor(userAgent) {
      super();
      this.parser = new DOMParser();
      this.userAgent = userAgent;
      this.token = userAgent._currentToken;

      this.root = "/cms/consort/mcptt/";
      this.CMSURI = EndPoints.getConfig().CMSURI;
      //console.log("config host CMSURI ", this.CMSURI);
      this.headers = {
        "Content-Type":
          'application/org.3gpp.mcptt.user-profile+xml; charset="utf-8" ',
      };
      this.subscriber = new subscriber(userAgent);
      this.subscriber.on("userProfileXCAP", (profile) => {
        this.emit("userProfileXCAP", profile);
      });
      this.subscriber.on("XCAPNOTIFY", (data) => {
        this.emit("XCAPNOTIFY", data);
      });
    }

    handleNotify(request) {
      this.subscriber.handleNotify(request);
    }

    unsubscribe() {
      this.subscriber.expires = "0";
      this.subscriber.subscribe();
    }

    fetchContactsList() {
      let baseUrl = `${this.CMSURI}/cms/consort/mcptt/${this.userAgent.mcpttId}.xml`;
      //console.log("contact fetch base url..", baseUrl);
      axios
        .get(baseUrl, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
          timeout: 30000
        })
        .then((res) => {
          //console.log("contact fetch Emmiting contactt", res.data);
          if (res.data) {
            this.emit("UserProfileFetched", res.data);
          }
        });
    }

    getGroupList(list) {
      let groupList = [];
      try {
        //console.log(list[0].getElementsByTagName("GroupSSI").item(0).textContent);
        if (list.length) {
          for (let i = 0; i < list.length; i++) {
            let element = list[i];
            groupList = [
              ...groupList,
              {
                Always_scanned: element
                  .getElementsByTagName("Always_scanned")
                  .item(0).textContent,
                Attached: element.getElementsByTagName("Attached").item(0)
                  .textContent,
                GroupSSI: element.getElementsByTagName("GroupSSI").item(0)
                  .textContent,
              },
            ];
          }
        }
      } catch (err) {
        //console.log(err)
      }
      return groupList;
    }

    getSubscriber() {
      return this.subscriber.subscriber;
    }

    getStorageUeID(userProfile) {
      let xml = this.parser.parseFromString(userProfile.toString());
      let contacts = xml.getElementsByTagName("ueInfo");
      //console.log('getStorageUeID....', xml, contacts)
      if (!contacts || !contacts.entry || !contacts.entry.length) {
        //console.log("CMC: getStorageUeID =========== ", contacts);
        return false;
      }
      return contacts.entry;
    }

    makeContactList(userProfile) {
      let xml = this.parser.parseFromString(userProfile);
      let contacts = xml.getElementsByTagName("PrivateCallURI");
      let groups = xml
        .getElementsByTagName("MCPTTGroupList")
        .item(0)
        .getElementsByTagName("entry");

      let implGroups = xml
        .getElementsByTagName("ImplicitAffiliations")
        .item(0)
        .getElementsByTagName("entry");

      let faXml = xml.getElementsByTagName("functionalAliasList").item(0);
      //console.log('groups on contact list..', groups, implGroups);
      let cont = [];
      for (let i = 0; i < contacts.length; i++) {
        try {
          let element = contacts.item(i);
          let subscriberType = element
            .getElementsByTagName("subscriberType")
            .item(0).textContent
            ? element.getElementsByTagName("subscriberType").item(0).textContent
            : "Dispatcher";

          let privateCallParticipation = element
            .getElementsByTagName("privateCallParticipation")
            .item(0)
            ? JSON.parse(element.getElementsByTagName("privateCallParticipation").item(0).textContent)
            : true;
          //console.log('subscriberType contact...', subscriberType)
          if (subscriberType !== "FA") {
            cont = [
              ...cont,
              {
                mcptt_uri: element.getElementsByTagName("uri-entry").item(0)
                  .textContent
                  ? element
                    .getElementsByTagName("uri-entry")
                    .item(0)
                    .textContent.trim()
                  : element
                    .getElementsByTagName("mcpttId")
                    .item(0)
                    .textContent.trim(),
                contactName: element.getElementsByTagName("description").item(0)
                  .textContent,
                subscriber_type: subscriberType,

                // Reg_status: element.getElementsByTagName("Reg_status").item(0).textContent,
                Organization: element.getElementsByTagName("org").item(0)
                  .textContent,
                mcptt_id: element.getElementsByTagName("uri-entry").item(0)
                  .textContent
                  ? element
                    .getElementsByTagName("uri-entry")
                    .item(0)
                    .textContent.trim()
                  : element
                    .getElementsByTagName("mcpttId")
                    .item(0)
                    .textContent.trim(),
                // mcptt_id: element.getElementsByTagName("mcpttId").item(0)
                //   .textContent,
                tetra_id: element.getElementsByTagName("mcpttId").item(0)
                  .textContent,
                // Domain: element.getElementsByTagName("Domain").item(0)
                //     .textContent,
                Groups: [],
                Reg_status: "2",
                RegNode: "",
                privateCallParticipation: privateCallParticipation
              },
            ];
          }
        } catch (error) {
          console.log("error making contact list", error);
        }
      }

      for (let i = 0; i < groups.length; i++) {
        try {
          let element = groups.item(i);
          const mcptt_id = element.getElementsByTagName("uri-entry").item(0)
            .textContent;
          const contactName = element
            .getElementsByTagName("display-name")
            .item(0).textContent;
          const subscriber_type = "GROUP";
          const tetra_id = element.getElementsByTagName("uri-entry").item(0)
            .textContent;
          const isImplicitAffiliated = false;
          let temporary = false;
          let supervisor = "";
          try {
            temporary = element.getElementsByTagName("temporary").item(0)
              .textContent;
            supervisor = element.getElementsByTagName("supervisor").item(0)
              .textContent;
          } catch (err) {
            console.log("error making temporary group", err);
          }
          cont = [
            ...cont,
            {
              mcptt_id: mcptt_id,
              contactName: contactName,
              subscriber_type: subscriber_type,
              tetra_id: tetra_id,
              isImplicitAffiliated: isImplicitAffiliated,
              temporary: temporary,
              supervisor: supervisor,
              privateCallParticipation: true
            },
          ];
          //console.log('groups on contact list wise..', contactName);
        } catch (error) {
          console.log("error making contact list group", error);
        }
      }

      for (let i = 0; i < implGroups.length; i++) {
        try {
          let element = implGroups.item(i);
          const mcptt_id = element.getElementsByTagName("uri-entry").item(0)
            .textContent;
          const contactName = element
            .getElementsByTagName("display-name")
            .item(0).textContent;
          const subscriber_type = "GROUP";
          const tetra_id = element.getElementsByTagName("uri-entry").item(0)
            .textContent;
          const isImplicitAffiliated = true;
          let temporary = false;
          let supervisor = "";
          try {
            temporary = element.getElementsByTagName("temporary").item(0)
              .textContent;
            supervisor = element.getElementsByTagName("supervisor").item(0)
              .textContent;
          } catch (err) {
            console.log("error making temporary group", err);
          }
          cont = [
            ...cont,
            {
              mcptt_id: mcptt_id,
              contactName: contactName,
              subscriber_type: subscriber_type,
              tetra_id: tetra_id,
              isImplicitAffiliated: isImplicitAffiliated,
              temporary: temporary,
              supervisor: supervisor,
              privateCallParticipation: true
            },
          ];
          //console.log('groups on contact list implGroups..', contactName);
        } catch (error) {
          console.log("error making contact list implgroup", error);
        }
      }

      let entries = faXml.getElementsByTagName("entry");
      let fas = [];
      for (let i = 0; i < entries.length; i++) {
        let element = entries.item(i);
        let anyExt = element.getElementsByTagName("anyExt")[0];
        let ue_type = "";
        let auto_activate = "";
        let name = element.getElementsByTagName("uri-entry").item(0)
          .textContent;
        try {
          if (anyExt) {
            ue_type = anyExt.getElementsByTagName("ue-type").item(0)
              .textContent;
            auto_activate = anyExt.getElementsByTagName("auto-activate").item(0)
              .textContent;
          }
        } catch (err) {
          console.log('ue_type contactList error while fetching fas...', err)
        }
        console.log('ue_type contactList.....', ue_type, this.userAgent.userAgent)
        if (this.userAgent.userAgent == ue_type || ue_type == "") {
          if (auto_activate == "true") {
            this.userAgent._autoActivateFAList.add(name);
          }
          fas = [
            ...fas,
            {
              name: name,
              CallerDescr: element.getElementsByTagName("display-name").item(0)
                .textContent,
              ue_type: ue_type,
              auto_activate: auto_activate,
            },
          ];
        }
      }
      //console.log("ue_type contactList fas...", fas, this.userAgent._autoActivateFAList);
      //console.log("final contact list.....", cont, fas);

      return {
        contactList: cont,
        faList: fas,
      };
    }

    getMcpttIds(xml) {
      let mcpttIds = xml.getElementsByTagName("MCPTTUserID");

      let list = [];

      try {
        for (let i = 0; i < mcpttIds.length; i++) {
          let element = mcpttIds.item(i);

          list = [
            ...list,
            {
              uri: element.getElementsByTagName("uri-entry").item(0)
                .textContent,
              description: element.getElementsByTagName("display-name").item(0)
                .textContent,
            },
          ];
        }
      } catch (err) {
        console.log("Could not read MCPTT ID list", err);
      }
      return list;
    }

    faXmltoJson(profile) {
      //console.log("faXmltoJson", profile);
      let xml = this.parser.parseFromString(profile);
      let jsonObj = {};
      try {
        jsonObj.profileName = xml
          .getElementsByTagName("Name")
          .item(0).textContent;
        jsonObj.CallerDescr = xml
          .getElementsByTagName("display-name")
          .item(0).textContent;
        var faXml = xml.getElementsByTagName("functionalAliasList").item(0);
        jsonObj.mcpttId = this.getMcpttIds(xml);
        jsonObj.faList = {
          tetra: xml.getElementsByTagName("tetra").item(0)
            ? xml.getElementsByTagName("tetra").item(0).textContent === "true"
              ? true
              : false
            : false,
          uri: faXml.getElementsByTagName("uri-entry").item(0).textContent,
          description: faXml.getElementsByTagName("display-name").item(0)
            .textContent,

        };
      } catch (err) {
        console.log("Error processing profile ", err);
      }
      //console.log("returning json for fa ", jsonObj);
      return jsonObj;
    }

    addUserProfile(profile) {
      //console.log("Creating profile 1", profile);
      /*let defaultGrp = profile["defaultGroup"]
        ? profile["defaultGroup"]
        : profile["defaultgroup"]
          ? profile["defaultgroup"]
          : "";
      if (profile["groupList"] && profile["groupList"].length) {
        profile["groupList"].forEach((group) => {
          if (
            group.name === defaultGrp &&
            !profile["implicitGroupList"].some((obj) => obj.name === defaultGrp)
          ) {
            profile["implicitGroupList"].push(group);
          }
        });
        profile["groupList"] = profile["groupList"].filter(
          (obj) => obj.name !== defaultGrp
        );
      }*/
      //console.log("addUserProfile Creating profile", profile);
      if (global.config.userConfig.multiTenantFeature) {
        profile.profileName = profile.profileName.includes("@") ? profile.profileName : profile.domain && profile.domain !== "" ? `${profile.profileName}@${profile.domain}` : profile.profileName
      }
      const data = new UserProfile(profile);
      const xml = js2xmlparser.parse("mcptt-user-profile", data.userProfile);
      console.log("addUserProfile Created xml is", xml.toString(), data);
      const _this = this;
      return new Promise(function (resolve, reject) {
        axios
          .put(`${EndPoints.getConfig().CMSURI}${_this.root}`, xml.toString(), {
            headers: _this.headers,
          })
          .then(function (resp) {
            if (resp.status === 200) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(function (err) {
            //logger.info.log("info",'Error in IWF CMS PUT: ',err);
            console.log("Error in IWF CMS PUT: ", err);
            resolve(false);
          });
      });
    }

    addFaProfile(profile) {
      const data = new FaProfile(profile);
      const xml = js2xmlparser.parse("mcptt-user-profile", data.FaProfile);
      const _this = this;
      return new Promise(function (resolve, reject) {
        axios
          .put(`${EndPoints.getConfig().CMSURI}${_this.root}`, xml.toString(), {
            headers: _this.headers,
          })
          .then(function (resp) {
            if (resp.status === 200) resolve(true);
            else resolve(false);
          })
          .catch(function (err) {
            resolve(false);
          });
      });
    }

    getAllUserProfiles(token) {
      // console.log(
      //   "Getting user profiles",
      //   EndPoints.getConfig().CMSURI + this.root + "all"
      // );
      const _this = this;
      return new Promise(function (resolve, reject) {
        axios
          .get(EndPoints.getConfig().CMSURI + _this.root + "all" + `?tenantId=${global.config.userConfig.tenantId}`, { headers: { authorization: `Bearer ${token}` }, timeout: 30000 })
          .then(function (resp) {
            //console.log("Resp for get all profiles", resp.data);
            if (resp.status === 200) {
              resolve(resp.data);
            } else resolve([]);
          })
          .catch(function (err) {
            //console.log("Error getting profile Names", err);
            resolve([]);
          });
      });
    }

    getProfileByName(name) {
      const _this = this;
      return new Promise(function (resolve, reject) {
        axios
          .get(`${EndPoints.getConfig().CMSURI}${_this.root}${name}.xml`, { timeout: 30000 })
          .then(function (res) {
            //console.log("Userprofiles xml", res);
            if (res.status === 200) {
              resolve(_this.faXmltoJson(res.data));
            } else resolve(null);
          })
          .catch(function (err) {
            //console.log("Userprofiles xml err", err);
            resolve(null);
          });
      });
    }

    getProfileNameOfAllFA(faList) {
      let promises = [];
      for (const fa of faList) {
        const promise = this.getProfileByName(fa.name);
        promises = [...promises, promise];
      }
      return Promise.all(promises);
    }

    userProfileToJson(profile) {
      let xml = this.parser.parseFromString(profile);
      let jsonObj = {};
      try {
        jsonObj.profileName = xml
          .getElementsByTagName("Name")
          .item(0).textContent;

        let userXml = xml.getElementsByTagName("MCPTTUserID").item(0);
        jsonObj.mcpttId = userXml
          .getElementsByTagName("uri-entry")
          .item(0).textContent;
        jsonObj.CallerDescr = userXml
          .getElementsByTagName("display-name")
          .item(0).textContent;

        let faXml = xml.getElementsByTagName("functionalAliasList").item(0);
        let entries = faXml.getElementsByTagName("entry");
        //console.log("FAXML is", faXml, entries);
        let list = [];

        for (let i = 0; i < entries.length; i++) {
          let element = entries.item(i);
          let anyExt = element.getElementsByTagName("anyExt")[0];
          let ue_type = "";
          let auto_activate = "";
          try {
            if (anyExt) {
              ue_type = anyExt.getElementsByTagName("ue-type").item(0)
                .textContent;
              auto_activate = anyExt
                .getElementsByTagName("auto-activate")
                .item(0).textContent;
            }
          } catch (err) { }
          //console.log("userAgent : userAgent=================== ", this.userAgent.userAgent, ue_type)
          /*if (this.userAgent.userAgent === ue_type || ue_type == '') {
            list = [
              ...list,
              {
                name: element.getElementsByTagName("uri-entry").item(0)
                  .textContent,
                CallerDescr: element.getElementsByTagName("display-name").item(0)
                  .textContent,
                ue_type: ue_type,
                auto_activate: auto_activate,
              },
            ];
          }*/
          list = [
            ...list,
            {
              name: element.getElementsByTagName("uri-entry").item(0)
                .textContent,
              CallerDescr: element.getElementsByTagName("display-name").item(0)
                .textContent,
              ueType: ue_type,
              default: auto_activate,
            },
          ];
        }
        jsonObj.faList = list;
        //console.log('falisttttt.....',list)
        if (xml.getElementsByTagName("MCPTTGroupList").length > 0) {
          let groupXml = xml.getElementsByTagName("MCPTTGroupList").item(0);
          let groupEntries = groupXml.getElementsByTagName("entry");
          //console.log("GROUPXML is", groupEntries);
          let groupList = [];
          for (let i = 0; i < groupEntries.length; i++) {
            let element = groupEntries.item(i);
            groupList = [
              ...groupList,
              {
                name: element.getElementsByTagName("uri-entry").item(0)
                  .textContent,
                CallerDescr: element
                  .getElementsByTagName("display-name")
                  .item(0).textContent,
              },
            ];
          }
          jsonObj.groupList = groupList;
        }

        if (xml.getElementsByTagName("ImplicitAffiliations").length > 0) {
          let implicitGroupXml = xml
            .getElementsByTagName("ImplicitAffiliations")
            .item(0);
          let implicitGroupEntries = implicitGroupXml.getElementsByTagName(
            "entry"
          );
          //console.log("implicitGroupXml is", implicitGroupEntries);
          let implicitGroupList = [];
          for (let i = 0; i < implicitGroupEntries.length; i++) {
            let element = implicitGroupEntries.item(i);
            implicitGroupList = [
              ...implicitGroupList,
              {
                name: element.getElementsByTagName("uri-entry").item(0)
                  .textContent,
                CallerDescr: element
                  .getElementsByTagName("display-name")
                  .item(0).textContent,
              },
            ];
          }
          jsonObj.implicitGroupList = implicitGroupList;
        }
        if (xml.getElementsByTagName("orgId").length > 0) {
          let orgId = xml.getElementsByTagName("orgId").item(0).textContent;
          //console.log("userProfileToJson orgId", orgId);
          jsonObj.orgId = orgId;
        }

        let mappedType = xml.getElementsByTagName('mappedType').item(0) ? xml.getElementsByTagName("mappedType").item(0).textContent : '';
        console.log("userProfileToJson mappedType", mappedType);
        jsonObj.mappedType = mappedType;

        if (xml.getElementsByTagName("tetra").length > 0) {
          let tetra = xml.getElementsByTagName("tetra").item(0)
            ? xml.getElementsByTagName("tetra").item(0).textContent
            : "0";
          //console.log("userProfileToJson orgId", orgId);
          if (tetra && tetra === "0") {
            jsonObj.tetra = false;
          }
          if (tetra && tetra !== "0") {
            jsonObj.tetra = true;
          }
        }

        if (xml.getElementsByTagName("VideoEnable").length > 0) {
          let VideoEnable = xml.getElementsByTagName("VideoEnable").item(0)
            ? xml.getElementsByTagName("VideoEnable").item(0).textContent
            : "0";
          //console.log("userProfileToJson orgId", orgId);
          if (VideoEnable && VideoEnable === "0") {
            jsonObj.VideoEnable = false;
          }
          if (VideoEnable && VideoEnable !== "0") {
            jsonObj.VideoEnable = true;
          }
        }

        if (xml.getElementsByTagName("location").length > 0) {
          let locationEnable = xml
            .getElementsByTagName("locationEnable")
            .item(0)
            ? xml.getElementsByTagName("locationEnable").item(0).textContent
            : "0";
          //console.log("userProfileToJson orgId", orgId);
          if (locationEnable && locationEnable === "0") {
            jsonObj.locationEnable = false;
          }

          if (locationEnable && locationEnable !== "0") {
            jsonObj.locationEnable = true;
          }

          let locationHour = xml.getElementsByTagName("locationHour").item(0)
            ? xml.getElementsByTagName("locationHour").item(0).textContent
            : "0";
          jsonObj.locationHour = locationHour;
          let locationMin = xml.getElementsByTagName("locationMin").item(0)
            ? xml.getElementsByTagName("locationMin").item(0).textContent
            : "0";
          jsonObj.locationMin = locationMin;
          let locationSec = xml.getElementsByTagName("locationSec").item(0)
            ? xml.getElementsByTagName("locationSec").item(0).textContent
            : "0";
          jsonObj.locationSec = locationSec;
        }

        if (xml.getElementsByTagName("default-group").length > 0) {
          var defaultgroup = xml.getElementsByTagName("default-group").item(0)
            .textContent;
          //console.log("userProfileToJson",defaultgroup)
          jsonObj.defaultgroup = defaultgroup;
        }
        //console.log("userProfileToJson",jsonObj)

        if (xml.getElementsByTagName("mcDataId").length > 0) {
          let mcDataId = xml.getElementsByTagName("mcDataId").item(0)
            .textContent;
          jsonObj.mcDataId = mcDataId;
        }

        if (xml.getElementsByTagName("mcVideoId").length > 0) {
          let mcVideoId = xml.getElementsByTagName("mcVideoId").item(0)
            .textContent;
          jsonObj.mcVideoId = mcVideoId;
        }
        let userType = xml.getElementsByTagName("userType").item(0).textContent;
        jsonObj.userType = userType;
        let uEInfoXml = xml.getElementsByTagName("ueInfo").item(0);
        let uEentries = uEInfoXml.getElementsByTagName("entry");
        //console.log("FAXML is", faXml);
        let uEInfolist = [];

        for (let i = 0; i < uEentries.length; i++) {
          let element = uEentries.item(i);
          let ueType = element.getElementsByTagName("ue-type").item(0)
            .textContent;
          let noOfreg = element.getElementsByTagName("number-of-reg").item(0)
            .textContent;
          let primary = element.getElementsByTagName("primary").item(0)
            .textContent;
          uEInfolist = [
            ...uEInfolist,
            {
              value: ueType,
              noOfReg: noOfreg,
              primary: primary,
            },
          ];
        }
        jsonObj.UEInfo = uEInfolist;

        if (xml.getElementsByTagName("permissions").length > 0) {
          //let permissions = xml.getElementsByTagName("permissions").item(0).textContent;
          let trainTab = xml.getElementsByTagName("trainTab").item(0)
            ? xml.getElementsByTagName("trainTab").item(0).textContent
            : true;
          let communicationTab = xml
            .getElementsByTagName("communicationTab")
            .item(0)
            ? xml.getElementsByTagName("communicationTab").item(0).textContent
            : true;
          let alertTab = xml.getElementsByTagName("alertTab").item(0)
            ? xml.getElementsByTagName("alertTab").item(0).textContent
            : true;
          let baseStation = xml.getElementsByTagName("baseStation").item(0)
            ? xml.getElementsByTagName("baseStation").item(0).textContent
            : true;
          let locationTab = xml.getElementsByTagName("locationTab").item(0)
            ? xml.getElementsByTagName("locationTab").item(0).textContent
            : true;
          let grabAllowed = xml.getElementsByTagName("grabAllowed").item(0)
            ? xml.getElementsByTagName("grabAllowed").item(0).textContent
            : "default";
          let trainMovement = xml.getElementsByTagName("trainMovement").item(0)
            ? xml.getElementsByTagName("trainMovement").item(0).textContent
            : "default";
          let recordingsTab = xml.getElementsByTagName("recordingsTab").item(0)
            ? xml.getElementsByTagName("recordingsTab").item(0).textContent
            : true;

          let permissions = {
            trainTab: JSON.parse(trainTab),
            communicationTab: JSON.parse(communicationTab),
            alertTab: JSON.parse(alertTab),
            baseStation: JSON.parse(baseStation),
            locationTab: JSON.parse(locationTab),
            grabAllowed: grabAllowed,
            trainMovement: trainMovement,
            recordingsTab: JSON.parse(recordingsTab),
          };
          // console.log("permissionsssss.....", permissions);
          jsonObj.permissions = permissions;

          //Call Forwarding Variables
          let allowCallForwarding = (xml.getElementsByTagName('allow-call-forwarding').item(0) ? (xml.getElementsByTagName('allow-call-forwarding').item(0).textContent == '1' ? true : false) : false);
          let allowCallForwardManualInput = (xml.getElementsByTagName('allow-call-forward-manual-input').item(0) ? (xml.getElementsByTagName('allow-call-forward-manual-input').item(0).textContent == '1' ? true : false) : false);
          let callForwardingTarget = xml.getElementsByTagName('call-forwarding-target').item(0) ? xml.getElementsByTagName('call-forwarding-target').item(0).textContent : '';
          let callForwardingOn = (xml.getElementsByTagName('call-forwarding-on').item(0) ? (xml.getElementsByTagName('call-forwarding-on').item(0).textContent == '1' ? true : false) : false);
          let callForwardingCondition = xml.getElementsByTagName('call-forwarding-condition').item(0) ? xml.getElementsByTagName('call-forwarding-condition').item(0).textContent : '';
          let callForwardingNoAnswerTimeout = (xml.getElementsByTagName('call-forwarding-no-answer-timeout').item(0) ? (xml.getElementsByTagName('call-forwarding-no-answer-timeout').item(0).textContent ? xml.getElementsByTagName('call-forwarding-no-answer-timeout').item(0).textContent : '0') : '0');
          let allowCallForwardManualInputTarget = xml.getElementsByTagName('allow-call-forward-manual-input-target').item(0) ? xml.getElementsByTagName('allow-call-forward-manual-input-target').item(0).textContent : '';

          let allowPrivateCallParticipation = (xml.getElementsByTagName('allow-private-call-participation').item(0) ? (xml.getElementsByTagName('allow-private-call-participation').item(0).textContent === '1' ? true : false) : true);
          let incomingAuthorizationRequired = (xml.getElementsByTagName('incoming-authorization-required').item(0) ? (xml.getElementsByTagName('incoming-authorization-required').item(0).textContent === '1' ? true : false) : false);
          let allowPrivateCallToAnyUser = (xml.getElementsByTagName('allow-private-call-to-any-user').item(0) ? (xml.getElementsByTagName('allow-private-call-to-any-user').item(0).textContent === '1' ? true : false) : false);
          let outgoingAuthorizationRequired = (xml.getElementsByTagName('outgoing-authorization-required').item(0) ? (xml.getElementsByTagName('outgoing-authorization-required').item(0).textContent === '1' ? true : false) : false);
          let defaultAuthorizer = xml.getElementsByTagName('default-authorizer').item(0) ? xml.getElementsByTagName('default-authorizer').item(0).textContent : '';


          if (allowCallForwardManualInputTarget == 'null') {
            allowCallForwardManualInputTarget = null;
          }
          let callForwardingObj = {
            allowCallForwarding: allowCallForwarding,
            allowCallForwardManualInput: allowCallForwardManualInput,
            callForwardingTarget: callForwardingTarget,
            callForwardingOn: callForwardingOn,
            callForwardingCondition: callForwardingCondition,
            callForwardingNoAnswerTimeout: callForwardingNoAnswerTimeout,
            allowCallForwardManualInputTarget: allowCallForwardManualInputTarget,
          }
          jsonObj.callForwardingData = callForwardingObj;
          //console.log('callForwardingObj....', callForwardingObj);


          let cadCallObj = {
            allowPrivateCallParticipation,
            incomingAuthorizationRequired,
            allowPrivateCallToAnyUser,
            outgoingAuthorizationRequired,
            defaultAuthorizer

          }
          jsonObj.cadCallData = cadCallObj;
          // console.log('cadCallData....', cadCallObj);

          if (xml.getElementsByTagName("userServiceTree")) {
            let userService = xml.getElementsByTagName("userServiceTree");
            let serviceList = [];

            for (let i = 0; i < userService.length; i++) {
              let element = userService[i];
              let leafServices = element.textContent;
              serviceList = [...serviceList, leafServices];
            }
            jsonObj.selectedServiceList = serviceList;
          }

        }
      } catch (err) {
        console.log("Error processing profile ", err);
      }
      return jsonObj;
    }

    getProfileByMcpttId(mcpttId) {
      console.log("getProfileByMcpttId()");
      const _this = this;
      return new Promise(function (resolve, reject) {
        if (_this.userAgent && _this.userAgent.getMcxUserProfile()) {
          console.log("getProfileByMcpttId() got userProfile ");
          resolve(_this.userAgent.getMcxUserProfile());
        } else {
          console.log("getProfileByMcpttId() fetching mcxUserProfile from server ");
          let configUrl = EndPoints.getConfig().CMSURI + _this.root + mcpttId + ".xml";
          axios
            .get(configUrl, { timeout: 30000 })
            .then(function (res) {
              //console.log("Userprofiles1 xml", res);
              if (res.status === 200) {
                let userData = _this.userProfileToJson(res.data);
                userData.xml = res.data;
                resolve(userData);
              } else reject(true);
            })
            .catch(function (error) {
              //console.log("Userprofiles1 xml err", error);
              if (error.response) {
                // Request made and server responded
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              reject(error);
            });
        }
      })
    }

    getMcpttIdAndUri() {
      console.log(" Inside getMcpttIdAndUri", EndPoints.getConfig().getMcpttIdAndUri);
      return axios
        .get(`${EndPoints.getConfig().getMcpttIdAndUri}?tenantId=${global.config.userConfig.tenantId}`)
        .then((res) => {
          if (res.data) {
            console.log("getCallForwardingConfig response--", res.data);
            return res.data
          }
          else
            return null;
        })
        .catch((err) => {
          console.log("🥴 FAILED getMcpttIdAndUri", err);
          return null;
        })
    }

    deleteProfile(profileName) {
      const _this = this;
      return new Promise(function (resolve, reject) {
        axios
          .delete(
            `${EndPoints.getConfig().CMSURI}${_this.root}${profileName}.xml`
          )
          .then(function (res) {
            if (res.status === 200) {
              //console.log("profile Deleted", res.data);
              resolve(true); //this.faXmltoJson(res.data);
            } else resolve(false);
          })
          .catch(function (err) {
            resolve(false);
          });
      });
    }

    getAffiliations(mcpttId) {
      //console.log("getAffiliations 0", mcpttId);
      const _this = this;
      return new Promise(function (resolve, reject) {
        axios
          .get(`${_this.CMSURI}/stats/affiliations/${mcpttId}.xml`, { timeout: 30000 })
          .then(function (resp) {
            //console.log("getAffiliations 1 : ", resp);
            if (resp.status === 200) {
              resolve(resp);
            } else resolve(null);
          })
          .catch(function (err) {
            //console.log("getAffiliations xml err", err);
            resolve(null);
          });
      });
    }

    getActiveUsers(groupId) {
      //console.log("activeUsers 0", groupId);
      const _this = this;
      return new Promise(function (resolve, reject) {
        axios
          .get(`${_this.CMSURI}/stats/activeUsers/${groupId}.xml`, { timeout: 30000 })
          .then(function (resp) {
            //console.log("activeUsers 1 : ", resp);
            if (resp.status === 200) {
              resolve(resp);
            } else resolve(null);
          })
          .catch(function (err) {
            console.log("activeUsers xml err", err);
            resolve(null);
          });
      });
    }

    getUsersOfGroups(groupId) {
      const _this = this;
      // console.log(
      //   "user dgna members saga data url..",
      //   `${_this.CMSURI}/consort/getUsersByGroup/${groupId}`
      // );
      return new Promise(function (resolve, reject) {
        axios
          .get(`${_this.CMSURI}/cms/consort/getUsersByGroup/${groupId}`, { timeout: 30000 })
          .then(function (resp) {
            //console.log("group dgna users : ", resp);
            if (resp.status === 200) {
              resolve(resp);
            } else resolve(null);
          })
          .catch(function (err) {
            console.log("groups dgna err", err);
            resolve(null);
          });
      });
    }
  }
  exports.CMC = CMC;
})(typeof exports === "undefined" ? (this["CMC"] = {}) : exports);
