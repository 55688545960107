import React, { useEffect, useState } from "react";
import { Input } from "semantic-ui-react";
import { makeStyles, useTheme, ThemeProvider } from "@material-ui/core/styles";
import { TextField, Checkbox, CircularProgress, FormControl, InputLabel, Select, MenuItem, Grid, Box } from '@material-ui/core'
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { fetchAppList, fetchAppConfigList, fetchAuthToken } from "../../../modules/actions/mdmAction";
import { getMethod, postMethod, putMethod } from "../utils";
import axios from "axios";
import { EndPoints } from "../../../MCXclient/endpoints";
import CircularWithValueLabel from "../../circularLoader";


const EditApp = (props) => {
    const { user, setModalOpen, fetchAppList, data, tenantId, fetchAuthToken, token, deviceTypeList, appConfigList, fetchAppConfigList } = props;
    const [appFile, setAppFile] = useState("");
    const [apkData, setApkData] = useState({ ...data });
    const [disableInput, setDisableInput] = useState(false);
    const [progress, setProgress] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");
    const [successupload,setSuccesupload]=useState("");
    const [configData, setConfigData] = useState([])
    const [selectedCheckedBox, setSelectedCheckbox] = useState([])
    const [showLoader, setShowLoader] = useState(false)
    const [percentageCompleted, setPercentageCompleted] = useState(0)
    const [selectedAction, setSelectedAction] = useState()
    const [configList, setConfigList] = useState([])
    const [error1, setError1] = useState('');
    const appActionOption = [
        { text: "Install", value: "install" },
        { text: "Do not Install", value: "notInstall" },
        { text: "Delete", value: "delete" }
    ]
    const [error, seterror] = useState('');
    const handleLoader = (data) => {
        setShowLoader(data)
    }
    const [fileUploaded, setFileUploaded] = useState(false);

    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: "#252524",
            //backgroundColor: theme.palette.background.default,
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            width: "100%",
            //height: "70%",
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
        },
        formControlFA: {
            width: "95%",
            height: "65px",
            marginTop: "2px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600,
            backgroundColor: "#383736"
        },
        listItemFA: {
            padding: "5px",
            fontFamily: "Muli",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        productTypeClass: {
            width: "90%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            border: "solid",
            borderRadius: 10,
            borderWidth: "2px",
            borderColor: "#4B4A4A",
            marginTop: 5,
            marginBottom: 10
        },
        divList: {
            width: '100%',
            marginBottom: '13px',
        },
        lblStyle: {
            width: '100%',
            color: "#D0D3D4",
            fontSize: 18,
            fontWeight: 600
        },
        tetraSwitch: {
            fontFamily: "Muli",
            marginTop: "2px",
            marginRight: "1px",
            marginLeft: "0px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        datePickerStyle: {
            color: "black",
            padding: "10px",
            fontFamily: "Sans-Serif",
            width: '60%',
            border: "solid",
            borderRadius: 20,
            borderWidth: "2px",
            borderColor: "#D3D3D3",
        }
    }));
    const classes = useStyles();
    const theme = useTheme();
    const uploadHandler = () => {

        if (!appFile) {
            setError1("Please Choose file");
            return;
        }
        else {
            setError1('');
        }
        handleLoader(true)
        const token = user ? user.access_token ? user.access_token : "" : ""
        let formData = new FormData();
        formData.append("application", appFile);
        formData.append("applicationId", data.id)
        let headers = {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${token}`
        }
        const config = {
            onUploadProgress: function (progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setPercentageCompleted(percentCompleted)
            }
        };
        // axios.put("https://localhost:8090/mdm/applications/versions", formData, { headers }).then((respData) => {
        putMethod(EndPoints.getConfig().applicationVersion, formData, token, config).then((respData) => {
            if (respData) {
                console.log("jfhjdh", respData);
                if (respData.status == "success" && respData.data) {
                    handleLoader(false)
                    setDisableInput(true)
                    setFileUploaded(true);
                    setApkData({ ...apkData, latestVersion: respData.data.id, updatedVersion: respData.data.version })
                    // getConfigAppReln(data.id)
                    fetchAppConfigList({ id: data.id })
                    setErrorMessage("")
                    setSuccesupload("App Successfully Uploaded")
                }
                else {
                    setErrorMessage(respData.message)
                    handleLoader(false)
                    setSuccesupload("")
                }
            }
        }).catch((err) => {
            console.log("Error in analyzing apk", err);
            setErrorMessage(err)
            setSuccesupload("");
        })

    }
    useEffect(() => {
        console.log("edgdf", errorMessage);
    }, [errorMessage])

    const fileChangeHandler = (e) => {
        if (e.target.files.length) {
            setError1('');
            setAppFile(e.target.files[0])
        }
    }
    const changeHandler = (e) => {
        let data = { ...apkData }
        data[e.target.name] = e.target.checked
        setApkData({ ...data })
    }
    const configCheckHandler = (e, config) => {
        console.log("ygdshdv", e, config);
        let configData = apkData.configurations;
        if (!configData)
            configData = [];
        if (e.target.checked) {
            configData.push(config.id)
        } else {
            let tempConfig = configData.filter((cnf) => {
                return cnf !== config.id
            })
            configData = [...tempConfig];
        }
        setApkData({ ...apkData, configurations: configData })
    }
    const configDropDownHandler = (e, name) => {
        let data = { ...configData }
        data[name] = e.target.value
        setConfigData({ ...data })
    }
    const appNameChangeHandler = (e) => {

        setApkData({ ...apkData, name: e.target.value })

        if (e.target.value.trim()) {
            seterror('');
        }
        else {
            seterror("Application Name is required");
        }

    }
    const submitHandler = async () => {
        if (!apkData["name"] || apkData["name"].trim() == '') {
            seterror("Application Name is required");
            return;
        }
        handleLoader(true)
        let formData = new FormData();
        const token = user ? user.access_token ? user.access_token : "" : ""
        formData.append("application", appFile);
        const config = {
            onUploadProgress: function (progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setPercentageCompleted(percentCompleted)
            }
        };
        let headers = {
            authorization: `Bearer ${token}`
        }
        formData.append("document", JSON.stringify(apkData))
        putMethod(`${EndPoints.getConfig().getAppList}`, apkData, token, config)
            .then((data) => {
                if (data) {
                    console.log(data);
                    fetchAppList({ token: token, tenantId })
                    handleLoader(false)
                    setModalOpen(false)
                }
            }).catch((err) => {
                console.log("Error in analyzing apk", err);
            })
        setModalOpen(false)
    }

    const getEqualValues = (obj1, obj2) => {
        return new Promise((resolve, reject) => {
            const equalValues = {};

            for (let key in obj1) {
                if (obj2.hasOwnProperty(key) && obj1[key] === obj2[key]) {
                    equalValues[key] = obj1[key];
                }
            }
            resolve(equalValues);
        })
    }

    useEffect(() => {
        fetchAuthToken()
    }, [])

    useEffect(() => {
        setConfigList(appConfigList)
    }, [appConfigList])
    const dataList = [
        { name: 'Hello1', action: 'Install' },
        { name: 'Hello2', action: 'Install' },
        { name: 'Hello3', action: 'Install' },
    ]

    return (
        <div class={classes.root}>
            <Grid container width="100%" spacing={2}>
                <Grid item xs={12}>
                    <Box className="tab1-account" width={'100%'}>
                        {showLoader && <Box style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', position: 'absolute', width: '95%', height: '62.5%', zIndex: 999, backgroundColor: `rgba(0,0,0,0.85)`, borderRadius: '5px' }}>
                            <CircularWithValueLabel progress={percentageCompleted} />
                        </Box>}
                        <Box className="tab1-account" width={'100%'} marginLeft={0}>
                            <Box class="form-group" paddingX={10} paddingY={0}>
                                <Box style={{ paddingBottom: "0px", color: 'whitesmoke', fontSize: '18px', fontWeight: '500' }}>
                                    Update App Version
                                </Box>
                                <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', marginTop: '2.5%' }}>
                                    <Input type="file" onChange={fileChangeHandler} /* style={{backgroundColor:'#383736'}} */ />
                                    {progress ? <CircularProgress /> : null}
                                    <Box style={{ flexDirection: 'row', display: 'flex' }}>
                                        <h3 className="m-2" style={{ color: "red" }}>{error1}</h3>
                                        <button className={fileUploaded ? "upload-btn-faded" : "upload-btn"} type="button" disabled={fileUploaded} onClick={uploadHandler}>Upload</button>
                                    </Box>
                                </Box>
                                {errorMessage ? <label class="error-label">{errorMessage}</label> : null}
                                {successupload ? <label class="error-label" style={{color:"green"}}>{successupload}</label> : null}

                                <Box style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
                                    {disableInput ?
                                        <TextField
                                            required
                                            id="outlined-multiline-flexible"
                                            label="Updated Version"
                                            style={{ marginTop: '15px', marginRight: "25px" }}
                                            variant="filled"
                                            value={apkData.updatedVersion}
                                        // onChange={appNameChangeHandler}

                                        /> : null}
                                    <TextField
                                        required
                                        id="outlined-multiline-flexible"
                                        label="Application Name"
                                        style={{ marginTop: '15px', marginRight: "25px" }}
                                        variant="filled"
                                        value={apkData.name}
                                        onChange={appNameChangeHandler}

                                    />
                                    <TextField
                                        required
                                        id="standard-basic"
                                        label="Pkg Id"
                                        variant="filled"
                                        name="pkgId"
                                        style={{ marginTop: '15px', marginRight: "25px" }}
                                        // onChange={changeHandler}
                                        value={apkData.packageId}
                                    />
                                    <TextField
                                        required
                                        id="outlined-multiline-flexible"
                                        label="Current Version"
                                        style={{ marginTop: '15px' }}
                                        variant="filled"
                                        value={apkData.version}

                                    />
                                </Box>
                                {error ?
                                    <Box style={{ display: "flex", flexDirection: "row" }} className="my-2">
                                        {disableInput ? <h3 style={{ color: "red", position: "absolute", left: "300px" }}>{error}**</h3> : <h3 style={{ color: "red" }}>{error}**</h3>}
                                    </Box>
                                    : null}
                                <Box className="form-group" style={{ flexDirection: 'row', display: 'flex', marginTop: '5%' }}>
                                    {/* <Box flex={.8} mr="0.5em" marginTop={2} marginBottom={1}>
                                        <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Device Type</label>
                                    </Box> */}
                                    <FormControl
                                        variant="filled"
                                        className={classes.formControlFA}
                                    >
                                        <InputLabel id="config" className={classes.listItemFA} focused={false}>
                                            Device Type
                                        </InputLabel>
                                        <Select
                                            className={classes.listItemFA}
                                            id="deviceType"
                                            name="deviceType"
                                            value={apkData.deviceType || deviceTypeList.id}
                                            onChange={appNameChangeHandler}
                                            disabled={true}
                                            disableUnderline={true}
                                        >
                                            {deviceTypeList &&
                                                deviceTypeList.map((uetype) => {
                                                    return (
                                                        <MenuItem value={uetype.id}>{uetype.manufacturer} {uetype.brand}</MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box style={{ flexDirection: 'row', display: 'flex' }}>
                                    <Box flex={.4} mr="0.5em" marginTop={2} marginBottom={1}>
                                        <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Run After Install</label>
                                    </Box>
                                    <Checkbox
                                        color="primary"
                                        name="runAfterInstall"
                                        onChange={changeHandler}
                                        checked={apkData.runAfterInstall ? true : false}
                                    />
                                </Box>
                                <Box style={{ flexDirection: 'row', display: 'flex' }}>
                                    <Box flex={.4} mr="0.5em" marginTop={2} marginBottom={1}>
                                        <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Run At Boot</label>
                                    </Box>
                                    <Checkbox
                                        color="primary"
                                        name="runAtBoot"
                                        onChange={changeHandler}
                                        checked={apkData.runAtBoot ? true : false}
                                    />
                                </Box>
                                {disableInput ? <Box>
                                    <Typography variant="h6" component="h6" style={{ paddingBottom: "0px", marginTop: "10px", color: 'whitesmoke' }}>
                                        Configurations
                                    </Typography>
                                    <Box className="config-table">
                                        {appConfigList && appConfigList.map((config, i) => {
                                            return (
                                                <Box key={i} className="config-row">
                                                    <Box flex={.4} mr="0.5em" marginTop={2} marginBottom={1}>
                                                        <label class="attribute-heading" style={{ color: 'whitesmoke' }}>{config.name}</label>
                                                    </Box>
                                                    <Checkbox
                                                        color="primary"
                                                        name={"checkbox"}
                                                        onChange={(e) => configCheckHandler(e, config)}
                                                    />
                                                    {/* <FormControl
                                            // variant="filled"
                                            className={classes.formControlUEType}
                                            style={{
                                                width: "150px",
                                                height: "40px",
                                                marginTop: "5px",
                                            }}
                                        >
                                            {
                                                selectedCheckedBox[config.name] == true &&
                                                (
                                                    <Select
                                                        className={classes.ueInfoList}
                                                        id="action"
                                                        name="action"
                                                        value={configData[config.name] || ""}
                                                        onChange={(e) => configDropDownHandler(e, config.name)}
                                                    >
                                                        {appActionOption &&
                                                            appActionOption.map((uetype) => {
                                                                return (
                                                                    <MenuItem value={uetype.value}>{uetype.text}</MenuItem>
                                                                );
                                                            })}
                                                    </Select>

                                                )
                                            }
                                        </FormControl> */}
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                </Box> : null}
                            </Box>
                        </Box>
                        <Box className="submitbtn">
                            <button
                                class="submit-btn"
                                type="button"
                                onClick={submitHandler}
                            >
                                Update
                            </button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};
const mapStateToProps = ({ auth, mdm }) => {
    return {
        user: auth.user,
        tenantId: mdm.tenantId,
        deviceTypeList: mdm.deviceTypeList,
        appConfigList: mdm.appConfigList
    }
}
export default connect(mapStateToProps, { fetchAppList, fetchAppConfigList, fetchAuthToken })(EditApp);

