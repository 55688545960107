import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import { DataGrid } from '@mui/x-data-grid';
import { getCurrentActiveSubscriptions } from "../../modules/productcatalogstate";
import dayjs from "dayjs";

const columns = [
    {
        field: 'ProductSku', headerName: 'ProductSku', width: 300, sortable: false,
        headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
        valueGetter: (value, row) => `${row.products ? row.products.productSku : ""}`,
    },
    {
        field: 'productName', headerName: 'Product Name', width: 300, sortable: false,
        headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
        valueGetter: (value, row) => `${row.products ? row.products.shortDescription : ""}`,
    },
    {
        field: 'numDays', headerName: 'NumDays', width: 150, sortable: false,
        headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
    },
    {
        field: 'subscriptionType', headerName: 'SubscriptionType', width: 200, sortable: false,
        headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
    },
    {
        field: 'purchaseDate',
        headerName: 'Purchase Date',
        width: 200,
        valueGetter: (value, row) => `${dayjs(row.purchaseDate).format("DD-MM-YYYY")}`,
        sortable: false,
        headerClassName: "list-head-Val",
        cellClassName: "list-row-Val",
        align: "center",
        headerAlign: "center",
    },
    {
        field: 'startDate',
        headerName: 'Start Date',
        width: 200,
        valueGetter: (value, row) => `${dayjs(row.startDate).format("DD-MM-YYYY")}`,
        sortable: false,
        headerClassName: "list-head-Val",
        cellClassName: "list-row-Val",
        align: "center",
        headerAlign: "center",
    },
    {
        field: 'expiryDate',
        headerName: 'Expiry',
        width: 200,
        valueGetter: (value, row) => `${dayjs(row.expiryDate).format("DD-MM-YYYY")}`,
        sortable: false,
        headerClassName: "list-head-Val",
        cellClassName: "list-row-Val",
        align: "center",
        headerAlign: "center",
    },
];

const AllocateSubscriptionToUser = (props) => {
    const { currentActiveSubscriptions, getCurrentActiveSubscriptions, allocateSubscriptionsForUser, allocatedSubscriptions } = props;
    const [allocatedDeallocatedData, setAllocatedDeallocatedData] = useState([]);
    const [remainingAllocatedSub, setRemainingAllocatedSub] = useState([]);
    const [selectedAllSubs, setSelectedAllSubs] = useState(false);

    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: "#252524",
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            width: "100%",
            height: 300,
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
    }));

    useEffect(() => {
        let data = {
            "tenantId": global.config.userConfig.tenantId
        }
        getCurrentActiveSubscriptions(data);
    }, [])

    useEffect(() => {
        if (currentActiveSubscriptions && currentActiveSubscriptions.length > 0) {
            let allocatedSubs = [];
            if (allocatedSubscriptions && allocatedSubscriptions.length > 0) {
                for (let sub of currentActiveSubscriptions) {
                    let found = false;
                    for (let allocatedSub of allocatedSubscriptions) {
                        if (sub.id === allocatedSub.id) {
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        allocatedSubs = [...allocatedSubs, sub];
                    }
                }
            } else {
                allocatedSubs = [...currentActiveSubscriptions];
            }
            setRemainingAllocatedSub(allocatedSubs);
        }
    }, [currentActiveSubscriptions])

    const addedSubsForAllocation = (data) => {
        console.log("addedSubForAllocation ", data);
        let allocate = [];
        if (allocatedDeallocatedData.length === 0) {
            allocate = [...allocate, data];
        } else {
            let found = false;
            for (let sub of allocatedDeallocatedData) {
                if (data && data.id == sub.id) {
                    found = true;
                } else {
                    allocate = [...allocate, sub];
                }
            }
            if (!found) {
                allocate = [...allocate, data];
            }
        }
        setAllocatedDeallocatedData(allocate);
    }

    const allocateAllSubs = (data) => {
        if (!selectedAllSubs) {
            //Add all subscriptions
            setAllocatedDeallocatedData(remainingAllocatedSub);
        } else {
            setAllocatedDeallocatedData([]);
        }
        setSelectedAllSubs(!selectedAllSubs);
    }

    const updateAllocateData = () => {
        console.log("allocateSubscription 1", allocatedDeallocatedData);
        if (allocatedDeallocatedData && allocatedDeallocatedData.length > 0) {
            if (allocateSubscriptionsForUser) {
                allocateSubscriptionsForUser(allocatedDeallocatedData);
            }
        }
    }

    const classes = useStyles();
    return (
        <div className={classes.root}>
            {
                remainingAllocatedSub && remainingAllocatedSub.length > 0 ?
                    <DataGrid
                        rows={remainingAllocatedSub}
                        columns={columns}
                        checkboxSelection
                        onCellClick={(data) => addedSubsForAllocation(data.row)}
                        onColumnHeaderClick={(data) => allocateAllSubs(data)}
                        hideFooter
                        hideFooterPagination
                        hideFooterSelectedRowCount
                        disableColumnMenu
                        localeText={{ noRowsLabel: "" }}
                        getRowSpacing={params => ({
                            top: params.isFirstVisible ? 0 : 0,
                            bottom: params.isLastVisible ? 0 : 0
                        })}

                        sx={{
                            boxShadow: 2,
                            border: "none",
                            borderBottom: "none",
                            // display: "block",
                            '& .MuiDataGrid-cell:hover': {
                                color: '#ffb01f',
                            },
                            '& .MuiDataGrid': {
                                border: 'none',
                            },
                            '& .MuiDataGrid-cell': {
                                borderColor: "#404040",
                            },
                            '& .MuiDataGrid-columnHeader': {
                                borderColor: "gray",
                            },
                            "& .MuiDataGrid-sortIcon": {
                                opacity: 1,
                                color: "white",
                            },
                            "& .MuiDataGrid-menuIconButton": {
                                opacity: 1,
                                color: "white"
                            },
                            '& .MuiDataGrid-filler': {
                                backgroundColor: "#262626",
                            },
                        }}
                    /> : null
            }
            {
                remainingAllocatedSub && remainingAllocatedSub.length > 0 ?
                    <button
                        className="update-btn-profile"
                        type="button"
                        onClick={updateAllocateData}
                        disabled={allocatedDeallocatedData.length === 0 ? true : false}
                    >
                        {"Proceed"}
                    </button> : null
            }
        </div>
    )
}

const mapStateToProps = ({ productcatalogstate }) => {
    const { currentActiveSubscriptions } = productcatalogstate;
    return {
        currentActiveSubscriptions
    };
};

export default connect(mapStateToProps, {
    getCurrentActiveSubscriptions
})(AllocateSubscriptionToUser);
