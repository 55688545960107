import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { fetchConfigurationByDeviceId } from "../../modules/actions/mdmAction"
import { connect } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const ExecuteOrder = (props) => {
    const { id, fetchConfigurationByDeviceId, configDevices, getSelectedConfigDevice} = props;
    const [configDeviceList, setConfigDeviceList] = useState([]);
    const [selectedConfigDeviceId, setSelectedConfigDeviceId] = useState(null);
        
    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: "#252524",
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            width: "100%",
            height: 200,
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
        },
        divList: {
            width: '100%',
            marginBottom: '1px',
        },
        formControlFA: {
            width: "80%",
            height: "50px",
        },
        listItemFA: {
            padding: "2px",
            color: "#D0D3D4",
            fontFamily: "Muli",
        },
    }));

    useEffect(() => {
        if (id) {
            let pId = global.config.userConfig.tenantId;
            if (!pId) {
                pId = global.config.userConfig.partnerId;
            }
           let data = {
            "tenantId": pId,
            "deviceType": id
           } 
           console.log("deviceid data ", data, pId);
           fetchConfigurationByDeviceId(data);
        }
    }, [])

    useEffect(() => {
        if (configDevices && configDevices.length > 0) {
            setConfigDeviceList(configDevices);
        }
    }, [configDevices])

    const deviceChangeHandler = (e) => {
        setSelectedConfigDeviceId(e.target.value);
    }

    const OnAddDeviceType = () => {
        if (selectedConfigDeviceId && getSelectedConfigDevice) {
            getSelectedConfigDevice(selectedConfigDeviceId);
        }
    }


    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.divList}>
                <FormControl variant="filled" className={classes.formControlFA}>
                    <InputLabel
                        id="demo-simple-select-filled-label"
                        className={classes.listItemFA}
                        focused={false}
                    >
                        Devices
                    </InputLabel>
                    <Select
                        className={classes.listItemFA}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={selectedConfigDeviceId}
                        onChange={deviceChangeHandler}
                        disableUnderline={true}
                    >
                        {configDeviceList &&
                            configDeviceList.map((device) => {
                                return (
                                    <MenuItem value={device.id}>{device.name}</MenuItem>
                                  );
                            })}
                    </Select>
                </FormControl>
                <button
                    className="add-btn-device"
                    type="button"
                    onClick={OnAddDeviceType}
                >
                    Confirm Device
                </button>
            </div>
        </div>
    )
}

const mapStateToProps = ({ mdm }) => {
    const { configDevices } = mdm;
    return {
        configDevices
    };
};

export default connect(mapStateToProps, {
    fetchConfigurationByDeviceId,
})(ExecuteOrder);