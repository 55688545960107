import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import TitleTab from "./TitleTab";
import { fetchUserListAdmin, fetchGroupListAdmin, fetchOrgListAdmin, resetFADetails, resetUserProfileWithAttachedFAS, fetchIwfMapListAdmin, /* getAllAlerts, */ getAlertTypes, deleteUserAdmin, deleteIwfMapAdmin, deleteOrgAdmin, deleteGroupAdmin, } from '../../modules/adminstate';
import { BasicInfoData } from '../basicinfodata';
import AddModal from "./AddModal";
import { updateDomain } from "../../modules/domains";
import { fetchMasterConfigServiceConfig, fetchUeTypeListMtm, resetAvlService } from "../../modules/mtmstate";
import { updateDeviceByUser } from "../../modules/actions/mdmAction";
import { resetSubscriptionsData } from "../../modules/productcatalogstate";
import {
  resetIWFMapByMCPTTID,
  resetAdminProfile
} from "../../modules/adminstate";

import FadeInOut from "./FadeInOut";
import AddUser from './AdminWidget/Screens/AddUsers';
import { DataGrid } from "@mui/x-data-grid";
import AlertDialog from "../../Admin/components/DeleteUserDialog";
import AddIwfMap from "./AdminWidget/AddIwfMap/AddIwfMap";
import AddOrg from "./AdminWidget/AddOrg/AddOrg";
import AddGroup from "./AdminWidget/AddGroup/AddGroup";

const UsersList = (props) => {
  const { allAlertList, allAlertListType, userlist, grouplist, orglist,
    fetchUserListAdmin, fetchGroupListAdmin, fetchOrgListAdmin, resetFADetails,
    resetUserProfileWithAttachedFAS, iwfMaplist,
    fetchIwfMapListAdmin,/*  getAllAlerts, */ getAlertTypes, fetchMasterConfigServiceConfig,
    fetchUeTypeListMtm, resetAvlService, resetIWFMapByMCPTTID,
    resetAdminProfile, resetSubscriptionsData, deleteUserAdmin, updateDeviceByUser, deleteOrgAdmin, deleteGroupAdmin, deleteIwfMapAdmin, } = props;
  const [indvlOrGrp, setIndvlOrGrp] = useState(true)
  const [users, setUsers] = useState([])
  const [filterName, setFilterName] = useState('Users List')
  const [subscribeType, setSubscribeType] = useState('single')
  const [userOpen, setUserOpen] = React.useState(false);
  const [userData, setUserData] = useState([]);
  const [show, setShow] = useState(false);
  const [basicUserInfoData, setBasicUserInfoData] = useState(null);
  const [openUserEditMode, setOpenUserEditMode] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userName, setUserName] = useState('')
  const [selectedUserData, setSelectedUserData] = useState([])

  useEffect(() => {
    fetchUserListAdmin();
    fetchGroupListAdmin();
    fetchOrgListAdmin();
    fetchIwfMapListAdmin();
    // getAllAlerts();
    //getAlertTypes();
    fetchUeTypeListMtm(global.config.userConfig.tenantId ? global.config.userConfig.tenantId : null);
    fetchMasterConfigServiceConfig();
    if (global.config.userConfig.domains && global.config.userConfig.tenantId && global.config.userConfig.domains !== "" && global.config.userConfig.tenantId !== "") {
      if (users) {
        setUserData(users)
        // setUserData(users.filter(item => item.tenantId == global.config.userConfig.tenantId))
      }
      updateDomain(global.config.userConfig.domains)
    } else {
      console.log("Domain and tenant id is not available")
    }
  }, [])


  useEffect(() => {
    if (global.config.userConfig.tenantId && users) {
      setUserData(users)
      // setUserData(users.filter(item => item.tenantId == global.config.userConfig.tenantId))
    }
  }, [users])

  useEffect(() => {
    if (userOpen == true) {
      if (openUserEditMode == false) {
        if (subscribeType === "single") {
          setFilterName('Add User')
        } else if (subscribeType === "org") {
          setFilterName('Add Org')
        } else if (subscribeType === "iwf_map") {
          setFilterName('Add IWF')
        } else {
          setFilterName('Add Group')
        }
      } else {
        if (subscribeType === "single") {
          setFilterName('Edit User')
        } else if (subscribeType === "org") {
          setFilterName('Edit Org')
        } else if (subscribeType === "iwf_map") {
          setFilterName('Edit IWF')
        } else {
          setFilterName('Edit Group')
        }
      }
    }
    //  (!openUserEditMode ? (subscribeType === "single" ? setFilterName('Add User') : setFilterName('Add Org')) : subscribeType === "single" ? setFilterName('Edit User') : setFilterName('Edit Org'))
  }, [openUserEditMode, userOpen, subscribeType])

  const setUsersByType = (type) => {
    switch (type) {
      case "single":
        setUsers(userlist);
        break;
      case "grp":
        setUsers(grouplist);
        break;
      case "org":
        setUsers(orglist);
        break;
      case "iwf_map":
        setUsers(iwfMaplist);
        break;
      // case "alerts":
      //   setUsers(allAlertList);
      //   break;
      default:
        setUsers(allAlertListType);
        break;
    }
  };

  // Update users when data is fetched
  useEffect(() => {
    console.log("-------------------------USER LIST-----------------------------");
    switch (subscribeType) {
      case "single":
        setUsersByType("single");
        break;
      case "grp":
        setUsersByType("grp");
        break;
      case "org":
        setUsersByType("org");
        break;
      case "iwf_map":
        setUsersByType("iwf_map");
        break;
      // case "alerts":
      //   setUsersByType("alerts");
      //   break;
      default:
        setUsersByType("allAlertListType")
        break;
    }
  }, [userlist, grouplist, orglist, iwfMaplist, allAlertList, allAlertListType]);

  const userhandleClose = () => {
    console.log("hideModal userlist userhandleClose");
    setUserOpen(false);
    setShow(false);
    setBasicUserInfoData(null);
    setOpenUserEditMode(false);
    resetDataOnClose();
    fetchUserListAdmin();
  }

  const filterData = (type) => {
    setBasicUserInfoData(null);
    setOpenUserEditMode(false);
    setUserOpen(false)
    resetDataOnClose();
    if (type === 'indv') {
      setFilterName('User List')
      setSubscribeType('single')
      setIndvlOrGrp(true)
      setUsers(userlist)
      fetchUserListAdmin()
      setShow(false);
    } else if (type === 'grp') {
      setFilterName('Group List')
      setSubscribeType('grp')
      setIndvlOrGrp(false)
      setUsers(grouplist)
      fetchGroupListAdmin()
      setShow(false);
    }
    else if (type === 'org') {
      setFilterName('Org List')
      setSubscribeType('org')
      setIndvlOrGrp(false)
      setUsers(orglist)
      fetchOrgListAdmin()
      setShow(false);
    }
    else if (type === 'iwf_map') {
      setFilterName('Iwf Map List')
      setSubscribeType('iwf_map')
      setIndvlOrGrp(false)
      setUsers(iwfMaplist)
      fetchIwfMapListAdmin()
      setShow(false);
    }
    // else if (type === 'alerts') {
    //   setFilterName('Alerts')
    //   setSubscribeType('alerts')
    //   setIndvlOrGrp(false)
    //   setUsers(allAlertList)
    //   getAllAlerts()
    // }
    else {
      // if ('iwf_map' !== subscribeType) {
      //   setUserOpen(true);
      // }
      setUserOpen(true);
      setShow((prev) => !prev);
    }
  }

  const searchedData = (searchCont) => {
    let filterCont;
    if (searchCont.length > 0) {
      if (subscribeType === "single") {
        filterCont = userlist.filter(cont =>
          cont.userid && cont.userid.toLowerCase().includes(searchCont.toLowerCase()) ||
          cont.userName && cont.userName.includes(searchCont) ||
          cont.mcptt_id && cont.mcptt_id.toLowerCase().includes(searchCont.toLowerCase()))
      }
      else if (subscribeType === "iwf_map") {
        filterCont = iwfMaplist.filter(cont =>
          cont.id && cont.id.toLowerCase().includes(searchCont.toLowerCase()) ||
          cont.fa && cont.fa.includes(searchCont) ||
          cont.mcpttId && cont.mcpttId.toLowerCase().includes(searchCont.toLowerCase()))
      }
      else if (subscribeType === "org") {
        filterCont = orglist.filter(cont =>
          cont.orgName && cont.orgName.toLowerCase().includes(searchCont.toLowerCase()) ||
          cont.orgId && cont.orgId.includes(searchCont))
      }
      else if (subscribeType === "grp") {
        filterCont = grouplist.filter(cont =>
          cont.groupName && cont.groupName.toLowerCase().includes(searchCont.toLowerCase()) ||
          cont.groupId && cont.groupId.includes(searchCont))
      }
      else {
        filterCont = grouplist.filter(cont =>
          cont.basicinfo.groupname && cont.basicinfo.groupname.toLowerCase().includes(searchCont.toLowerCase())
          // ||
          // cont.members && cont.members.includes(searchCont) ||
          // cont.gid && cont.gid.toLowerCase().includes(searchCont.toLowerCase())
        )
      }
      setUsers(filterCont)
    } else {
      if (subscribeType === "single") {
        setUsers(userlist)
      }
      else if (subscribeType === "iwf_map") {
        setUsers(iwfMaplist)
      }
      else if (subscribeType === "org") {
        setUsers(orglist)
      }
      else if (subscribeType === "grp") {
        setUsers(grouplist)
      }
      else {
        setUsers(grouplist)
      }
    }
  }

  const checkUserIsAlreadyExist = (mcpttId) => {
    if (userlist) {
      let user = userlist.filter(usr => removeDomainFromMcpttId(usr.mcptt_id).toLowerCase() == mcpttId.toLowerCase());
      return user.length > 0
    }
    return false;
  }

  const removeDomainFromMcpttId = (mcpttId) => {
    if (mcpttId && mcpttId.length > 0) {
      if (mcpttId.includes('@')) {
        let arr = mcpttId.split('@')
        if (arr.length > 0) {
          return arr[0];
        }
      }
    }
    return mcpttId;
  }

  const resetDataOnClose = () => {
    resetFADetails();
    resetUserProfileWithAttachedFAS();
    resetIWFMapByMCPTTID();
    resetAdminProfile();
    resetSubscriptionsData();
    resetAvlService();
  }

  const hideModal = () => {
    console.log("hideModal userlist called");
    setUserOpen(false);
    setShow(false);
    setBasicUserInfoData(null);
    setOpenUserEditMode(false);
    resetDataOnClose();
    fetchUserListAdmin();
  }

  const openUserEditPage = (basicInfoData) => {
    setBasicUserInfoData(basicInfoData);
    setSelectedUserData(basicInfoData);
    setOpenUserEditMode(true);
    setShow(true);
    setUserOpen(true)
  }

  const columns = [
    {
      field: 'userIcon',
      headerName: 'Icon',
      width: 140,
      height: 50,
      sortable: false,
      headerClassName: "list-head-Val",
      align: "center",
      headerAlign: "center",
      renderCell: (cellValue) => {
        return (
          <div class="tr-tb-icon">
            <img
              src='/assets/images/Vector-7.svg'
              class="single-user-img"
              alt=""
            />
          </div>
        )
      }
    },
    {
      field: subscribeType === 'single' ? 'userName' : subscribeType === "org" ? 'orgName' : subscribeType === "iwf_map" ? 'id' : 'groupName', headerName: subscribeType === 'single' ? 'User Name' : subscribeType === "org" ? 'Org Name' : subscribeType === "iwf_map" ? 'Ssi' : 'Group Name', width: subscribeType === "iwf_map" ? global.config.isEcommerce === false ? 290 : 390 : global.config.isEcommerce === false ? 342 : 514, sortable: false,
      headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
    },
    {
      field: subscribeType === 'single' ? 'userRoles' : subscribeType === "org" ? 'orgId' : subscribeType === "iwf_map" ? 'type' : 'groupId', headerName: subscribeType === 'single' ? 'User Type' : subscribeType === "org" ? 'Org Id' : subscribeType === "iwf_map" ? 'Connection Mode' : 'Group Id', width: subscribeType === "iwf_map" ? global.config.isEcommerce === false ? 235 : 350 : global.config.isEcommerce === false ? 250 : 350, sortable: false,
      headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
    },
    {
      field: subscribeType === 'single' ? 'mcptt_id' : subscribeType === "org" ? 'orgProfile' : subscribeType === "iwf_map" ? 'fa' : 'membersType', headerName: subscribeType === 'single' ? 'Mcx Id' : subscribeType === "org" ? 'Description' : subscribeType === "iwf_map" ? 'Type' : 'Type', width: subscribeType === "iwf_map" ? global.config.isEcommerce === false ? 290 : 390 : global.config.isEcommerce === false ? 342 : 542, sortable: false,
      headerClassName: "list-head-Val", cellClassName: "list-row-Val",
      align: "center", headerAlign: "center",
    },
    {
      field: 'Id',
      headerName: subscribeType === "iwf_map" ? "Id" : '', width: subscribeType === "iwf_map" ? 218 : 0, sortable: false,
      headerClassName: "list-head-Val", cellClassName: "list-row-Val",
      align: "center", headerAlign: "center",
      valueGetter: (value, row) => {
        return subscribeType === "iwf_map" ? row.fa ? row.fa : row.mcpttId ? row.mcpttId : "" : ""
      }
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 90,
      height: 50,
      sortable: false,
      headerClassName: "list-head-Val",
      align: "center",
      headerAlign: "center",
      renderCell: (cellValue) => {
        return (
          <button
            class="editBtn"
            onClick={() => {
              if (subscribeType === 'single') {
                let basicInfo = BasicInfoData(cellValue.row);
                openUserEditPage(basicInfo);
              } else {
                openUserEditPage(cellValue.row);
              }
            }}
            type="button"
            name=""
          >
            <img
              src="/assets/images/editimg.svg"
              class="edit-user-img"
              alt=""
            />
          </button>
        )
      }
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 110,
      height: 50,
      sortable: false,
      headerClassName: "list-head-Val",
      align: "center",
      headerAlign: "center",
      renderCell: (cellValue) => {
        return (
          <>
            <button
              class="editBtn"
              onClick={() => { deleteButtonClick(cellValue.row) }}
              type="button"
              name=""
            >
              <img
                src="/assets/images/deleteimg.svg"
                class="delete-user-img"
                alt=""
              />
            </button>
          </>
        )
      },
    },
  ];

  const deleteButtonClick = (val) => {
    console.log('deleting group', subscribeType, val);

    if (subscribeType === "single") {
      if (val && val.id) {
        console.log('deleted userId ', val.id);
        setDialogOpen(true);
        setUserName(val.userName)
        setSelectedUserData(val)
      }
    }
    else if (subscribeType === "org") {
      if (val && val.orgId) {
        setDialogOpen(true);
        setUserName(val.orgName)
        setSelectedUserData(val)
      }
    }
    else if (subscribeType === "iwf_map") {
      if (val.userData) {
        setDialogOpen(true);
        setUserName(val.id)
        setSelectedUserData(val)
      }
    }
    else {
      if (val && val.groupId && val.groupName) {
        setDialogOpen(true);
        setUserName(val.groupName);
        setSelectedUserData(val);
      }
    }

  }

  const deleteDailogHandler = (val) => {
    setDialogOpen(false)
    if (selectedUserData && val) {
      console.log("deleteDailogHandler ", selectedUserData);
      if (subscribeType === "single") {
        deleteUserAdmin({ "id": selectedUserData.id, "mcptt_id": selectedUserData.mcptt_id, "userId": selectedUserData.userName })
        updateDeviceByUser({ userId: selectedUserData.userName, devices: [{ "userId": selectedUserData.userName }] })
      }
      else if (subscribeType === "org") {
        deleteOrgAdmin(selectedUserData)
      }
      else if (subscribeType === "iwf_map") {
        deleteIwfMapAdmin(selectedUserData)
      }
      else {
        deleteGroupAdmin(selectedUserData.groupId)
      }
    }
  }

  return (
    <div style={{ width: "100%", heightL: '100%' }}>
      <TitleTab
        title={filterName}
        type={"userListTab"}
        search={(txt) => {
          //console.log("search text", txt);
          searchedData(txt)
        }}
        filtr={(x) => {
          console.log("TYPE", x);
          filterData(x)
        }}
        userOpen={userOpen}
      />

      {show === true ?
        <FadeInOut show={show} duration={500}>
          {openUserEditMode === false ?
            <AddModal userhandleClose={userhandleClose} hideModal={hideModal} checkUserIsAlreadyExist={checkUserIsAlreadyExist} userOpen={userOpen} subscribeType={subscribeType}></AddModal>
            :
            (subscribeType === "single" ?
              <AddUser purpose="edit" tetraUser={props.tetraUser} basicInfoData={basicUserInfoData} hideModal={hideModal} grouplist={grouplist} checkUserIsAlreadyExist={checkUserIsAlreadyExist} ></AddUser>
              : subscribeType === "org" ?
                <AddOrg purpose="edit" infoData={selectedUserData} hideModal={hideModal}></AddOrg>
                : subscribeType === "iwf_map" ?
                  <AddIwfMap purpose="edit" infoData={selectedUserData} userlist={userlist} hideModal={hideModal}></AddIwfMap>
                  :
                  <AddGroup purpose="edit" infoData={selectedUserData} userlist={userlist} orglist={orglist} hideModal={hideModal}></AddGroup>
            )
          }
        </FadeInOut>
        :
        <DataGrid
          rows={userData}
          columns={columns}
          rowHeight={68}
          hideFooter
          hideFooterPagination
          hideFooterSelectedRowCount
          disableColumnMenu
          localeText={{ noRowsLabel: "" }}
          getRowSpacing={params => ({
            top: params.isFirstVisible ? 0 : 0,
            bottom: params.isLastVisible ? 0 : 0
          })}
          sx={{
            boxShadow: 2,
            border: "none",
            borderBottom: "none",
            '& .MuiDataGrid-cell:hover': {
              color: '#ffb01f',
            },
            '& .MuiDataGrid': {
              border: 'none',
            },
            '& .MuiDataGrid-cell': {
              borderColor: "#404040",
            },
            '& .MuiDataGrid-columnHeader': {
              borderColor: "gray",
            },
            '& .MuiDataGrid-filler': {
              backgroundColor: "#262626",
            },
          }}
        />
      }
      <AlertDialog open={dialogOpen} deleteDailogHandler={deleteDailogHandler} userName={userName} />
    </div>
  )
};


const mapStateToProps = ({ adminstate }) => {
  const { userlist, grouplist, orglist, iwfMaplist } = adminstate;
  const { allAlertList, allAlertListType } = adminstate
  //console.log('userlist reducer', userlist)
  return {
    userlist, grouplist, orglist, iwfMaplist, allAlertList, allAlertListType
  };
};

export default connect(mapStateToProps, {
  fetchUserListAdmin,
  fetchGroupListAdmin,
  fetchOrgListAdmin,
  fetchIwfMapListAdmin,
  resetFADetails,
  resetUserProfileWithAttachedFAS,
  getAlertTypes,
  updateDomain,
  fetchMasterConfigServiceConfig,
  fetchUeTypeListMtm,
  resetAvlService,
  resetIWFMapByMCPTTID,
  resetAdminProfile,
  resetSubscriptionsData,
  deleteUserAdmin,
  updateDeviceByUser,
  deleteOrgAdmin,
  deleteGroupAdmin,
  deleteIwfMapAdmin,
})(UsersList);