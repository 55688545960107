(function (exports) {
  const {
    writeToBuff,
    decodeWithType,
    encode,
    decode,
  } = require("../SDS/encodedecode");

  const config = {
    RTCPHeader: {
      __size: 12,
      firstByte: {
        __offset_start: 0,
        __size: 1,
        __type: "bit_field",
        __fields: {
          version: {
            __offset: 0,
            __size: 2,
          },
          subtype: {
            __offset: 3,
            __size: 5,
          },
        },
      },
      type: {
        __offset_start: 1,
        __size: 1,
        __type: "UInt8",
      },
      length: {
        __offset_start: 2,
        __size: 2,
        __type: "UInt16",
      },
      ssrc: {
        __offset_start: 4,
        __size: 4,
        __type: "UInt32",
      },
      name: {
        __offset_start: 8,
        __size: 4,
        __type: "String",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        //console.log("Rtp header pre", data)
        if (!data.hasOwnProperty("firstByte")) data.firstByte = {};
        data.firstByte.version = 2;
        data.type = 204;
        if (!data.hasOwnProperty("name")) {
          data.name = "MCPT";
        }
        //console.log("Rtp header pre", data)
      },
    },
    FloorPriorityField: {
      __size: 4,
      __padding: 4,
      id: {
        __offset_start: 0,
        __size: 1,
        __type: "UInt8",
      },
      length: {
        __offset_start: 1,
        __size: 1,
        __type: "UInt8",
      },
      value: {
        __offset_start: 2,
        __size: 1,
        __type: "UInt8",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        data.id = 0;
        data.length = 1;
        if (!data.hasOwnProperty("value")) data.value = 5;
      },
      __getId: function () {
        return 0;
      },
    },
    UserIdField: {
      __size: 0,
      __padding: 4,
      id: {
        __offset_start: 0,
        __size: 1,
        __type: "UInt8",
      },
      length: {
        __offset_start: 1,
        __size: 1,
        __type: "UInt8",
      },
      value: {
        __offset_start: 2,
        __type: "vstring",
        __sizeField: "length",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        data.id = 6;
        if (data.hasOwnProperty("value")) data.length = data.value.length;
        else data.length = 0;
      },
      __getId: function () {
        return 6;
      },
    },
    MessageSequenceNumberField: {
      __size: 4,
      id: {
        __offset_start: 0,
        __size: 1,
        __type: "UInt8",
      },
      length: {
        __offset_start: 1,
        __size: 1,
        __type: "UInt8",
      },
      value: {
        __offset_start: 2,
        __type: "UInt16",
        __sizeField: "length",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        data.id = 8;
        data.length = 2;
      },
      __getId: function () {
        return 8;
      },
    },
    TrackInfoField: {
      __size: 4,
      __padding: 4,
      id: {
        __offset_start: 0,
        __size: 1,
        __type: "UInt8",
      },
      length: {
        __offset_start: 1,
        __size: 1,
        __type: "UInt8",
      },
      queueingCapability: {
        __offset_start: 2,
        __size: 1,
        __type: "Uint8",
      },
      participantTypeLength: {
        __offset_start: 3,
        __size: 1,
        __type: "Uint8",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        data.id = 11;
        data.length = 2;
        if (!data.queueingCapability) {
          data.queueingCapability = 0;
        }
        data.participantTypeLength = 0;
      },
      __getId: function () {
        return 11;
      },
    },
    FloorIndicatorField: {
      __size: 4,
      __padding: 4,
      id: {
        __offset_start: 0,
        __size: 1,
        __type: "UInt8",
      },
      length: {
        __offset_start: 1,
        __size: 1,
        __type: "UInt8",
      },
      value: {
        __offset_start: 2,
        __size: 2,
        __type: "bit_field",
        __fields: {
          normalCall: {
            __offset: 0,
            __size: 1,
          },
          broadcastGroupCall: {
            __offset: 1,
            __size: 1,
          },
          systemCall: {
            __offset: 2,
            __size: 1,
          },
          emergencyCall: {
            __offset: 3,
            __size: 1,
          },
          imminentPerilCall: {
            __offset: 4,
            __size: 1,
          },
        },
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        data.id = 13;
        data.length = 2;
      },
      __getId: function () {
        return 13;
      },
    },
    MCPTTSessionIdentityField: {
      __size: 0,
      __padding: 4,
      id: {
        __offset_start: 0,
        __size: 1,
        __type: "UInt8",
      },
      length: {
        __offset_start: 1,
        __size: 1,
        __type: "UInt8",
      },
      sessionType: {
        __offset_start: 2,
        __size: 1,
        __type: "UInt8",
      },
      sessionIdentity: {
        __offset_start: 3,
        __type: "vstringx",
        __sizeField: "length",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        data.id = 1;
        if (data.hasOwnProperty("sessionIdentity"))
          data.length = data.sessionIdentity.length + 1;
        else data.length = 1;
      },
      __getId: function () {
        return 1;
      },
    },
    MediaStreamField: {
      __size: 4,
      __padding: 4,
      id: {
        __offset_start: 0,
        __size: 1,
        __type: "UInt8",
      },
      length: {
        __offset_start: 1,
        __size: 1,
        __type: "UInt8",
      },
      value: {
        __offset_start: 2,
        __size: 1,
        __type: "UInt8",
      },
      controlChannel: {
        __offset_start: 3,
        __size: 1,
        __type: "UInt8",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        data.id = 0;
        data.length = 2;
      },
      __getId: function () {
        return 0;
      },
    },
    WarningTextField: {
      __size: 0,
      __padding: 4,
      id: {
        __offset_start: 0,
        __size: 1,
        __type: "UInt8",
      },
      length: {
        __offset_start: 1,
        __size: 1,
        __type: "UInt8",
      },
      warningText: {
        __offset_start: 2,
        __type: "vstring",
        __sizeField: "length",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        data.id = 2;
        if (data.hasOwnProperty("warningText"))
          data.length = data.warningText.length;
        else data.length = 0;
      },
      __getId: function () {
        return 2;
      },
    },
    MCPTTGroupIdentityField: {
      __size: 0,
      __padding: 4,
      id: {
        __offset_start: 0,
        __size: 1,
        __type: "UInt8",
      },
      length: {
        __offset_start: 1,
        __size: 1,
        __type: "UInt8",
      },
      groupIdentity: {
        __offset_start: 2,
        __type: "vstring",
        __sizeField: "length",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        data.id = 3;
        if (data.hasOwnProperty("groupIdentity"))
          data.length = data.groupIdentity.length;
        else data.length = 0;
      },
      __getId: function () {
        return 3;
      },
    },
    InvitingMCPTTUserIdentityField: {
      __size: 0,
      __padding: 4,
      id: {
        __offset_start: 0,
        __size: 1,
        __type: "UInt8",
      },
      length: {
        __offset_start: 1,
        __size: 1,
        __type: "UInt8",
      },
      invitingMCPTTUserIdentity: {
        __offset_start: 2,
        __type: "vstring",
        __sizeField: "length",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        data.id = 5;
        if (data.hasOwnProperty("invitingMCPTTUserIdentity"))
          data.length = data.invitingMCPTTUserIdentity.length;
        else data.length = 0;
      },
      __getId: function () {
        return 5;
      },
    },
    SSrcGrantedField: {
      __size: 8,
      __padding: 4,
      id: {
        __offset_start: 0,
        __size: 1,
        __type: "UInt8",
      },
      length: {
        __offset_start: 1,
        __size: 1,
        __type: "UInt8",
      },
      value: {
        __offset_start: 2,
        __size: 4,
        __type: "UInt32",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        data.id = 14;
        data.length = 6;
      },
      __getId: function () {
        return 14;
      },
    },
    AnswerStateField: {
      __size: 4,
      __padding: 4,
      id: {
        __offset_start: 0,
        __size: 1,
        __type: "UInt8",
      },
      length: {
        __offset_start: 1,
        __size: 1,
        __type: "UInt8",
      },
      value: {
        __offset_start: 2,
        __size: 2,
        __type: "UInt8",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        data.id = 4;
        data.length = 2;
      },
      __getId: function () {
        return 4;
      },
    },
    ReasonCodeField: {
      __size: 4,
      __padding: 4,
      id: {
        __offset_start: 0,
        __size: 1,
        __type: "UInt8",
      },
      length: {
        __offset_start: 1,
        __size: 1,
        __type: "UInt8",
      },
      value: {
        __offset_start: 2,
        __size: 2,
        __type: "UInt8",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        data.id = 6;
        data.length = 2;
      },
      __getId: function () {
        return 6;
      },
    },
    DurationField: {
      __size: 4,
      id: {
        __offset_start: 0,
        __size: 1,
        __type: "UInt8",
      },
      length: {
        __offset_start: 1,
        __size: 1,
        __type: "UInt8",
      },
      value: {
        __offset_start: 2,
        __type: "UInt16",
        __sizeField: "length",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        data.id = 1;
        data.length = 2;
        if (!data.value) {
          data.value = 65535;
        }
      },
      __getId: function () {
        return 1;
      },
    },
    GrantedPartyIdentityField: {
      __size: 0,
      __padding: 4,
      id: {
        __offset_start: 0,
        __size: 1,
        __type: "UInt8",
      },
      length: {
        __offset_start: 1,
        __size: 1,
        __type: "UInt8",
      },
      value: {
        __offset_start: 2,
        __type: "vstring",
        __sizeField: "length",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        data.id = 4;
        if (data.hasOwnProperty("value")) data.length = data.value.length;
        else data.length = 0;
      },
      __getId: function () {
        return 4;
      },
    },
    PermissionToRequestFloorField: {
      __size: 4,
      id: {
        __offset_start: 0,
        __size: 1,
        __type: "UInt8",
      },
      length: {
        __offset_start: 1,
        __size: 1,
        __type: "UInt8",
      },
      value: {
        __offset_start: 2,
        __type: "UInt16",
        __sizeField: "length",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        data.id = 5;
        data.length = 2;
      },
      __getId: function () {
        return 5;
      },
    },
    FloorRequestMessage: {
      __size: 0,
      __sizeField: "header.length",
      header: {
        __offset_start: 0,
        __size: 12,
        __type: "RTCPHeader",
      },
      priority: {
        __offset_start: 12,
        __size: 4,
        __type: "FloorPriorityField",
      },
      userId: {
        __offset_start: 16,
        __size: 0,
        __type: "UserIdField",
      },
      trackInfo: {
        __offset_start: 0,
        __size: 4,
        __type: "TrackInfoField",
      },
      floorIndicator: {
        __offset_start: 0,
        __size: 4,
        __type: "FloorIndicatorField",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        if (!data.hasOwnProperty("header"))
          data.header = { firstByte: { subtype: 0 } };
        if (!data.header.hasOwnProperty("firstByte"))
          data.header.firstByte = { subtype: 0 };
        data.header.firstByte.subtype = 0;
        if (!data.hasOwnProperty("priority")) data.priority = {};
        if (!data.hasOwnProperty("userId")) data.userId = {};
        if (!data.hasOwnProperty("trackInfo")) data.trackInfo = {};
        if (!data.hasOwnProperty("floorIndicator")) data.floorIndicator = {};
      },
      __postEncodeHandler: function (
        masterConfig,
        configCopy,
        data,
        buffer,
        offset,
        sizeObj,
        offsetObj
      ) {
        var rtcpLength = offset / 4 - 1;
        data.header.length = rtcpLength;
        //config, value, buffer, offset, fieldSize
        writeToBuff(
          config.RTCPHeader.length,
          data.header.length,
          buffer,
          offsetObj.header.length,
          sizeObj.header.length
        );
      },
      __postDecodeHandler: function () {},
    },
    FloorIdleMessage: {
      __size: 0,
      __sizeField: "header.length",
      header: {
        __offset_start: 0,
        __size: 12,
        __type: "RTCPHeader",
      },
      messageSequenceNumber: {
        __offset_start: 12,
        __size: 0,
        __type: "MessageSequenceNumberField",
      },
      trackInfo: {
        __offset_start: 0,
        __size: 4,
        __type: "TrackInfoField",
      },
      floorIndicator: {
        __offset_start: 0,
        __size: 4,
        __type: "FloorIndicatorField",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        if (!data.hasOwnProperty("header"))
          data.header = { firstByte: { subtype: 5 } };
        if (!data.header.hasOwnProperty("firstByte"))
          data.header.firstByte = { subtype: 5 };
        data.header.firstByte.subtype = 5;
        if (!data.hasOwnProperty("messageSequenceNumber"))
          data.messageSequenceNumber = {};
        if (!data.hasOwnProperty("trackInfo")) data.trackInfo = {};
        if (!data.hasOwnProperty("floorIndicator")) data.floorIndicator = {};
      },
      __postEncodeHandler: function (
        masterConfig,
        configCopy,
        data,
        buffer,
        offset,
        sizeObj,
        offsetObj
      ) {
        var rtcpLength = offset / 4 - 1;
        data.header.length = rtcpLength;
        //config, value, buffer, offset, fieldSize
        writeToBuff(
          config.RTCPHeader.length,
          data.header.length,
          buffer,
          offsetObj.header.length,
          sizeObj.header.length
        );
      },
    },
    FloorGrantedMessage: {
      __size: 0,
      __sizeField: "header.length",
      header: {
        __offset_start: 0,
        __size: 12,
        __type: "RTCPHeader",
      },
      duration: {
        __offset_start: 12,
        __size: 4,
        __type: "DurationField",
      },
      ssrcGranted: {
        __offset_start: 16,
        __size: 8,
        __type: "SSrcGrantedField",
      },
      priority: {
        __offset_start: 24,
        __size: 4,
        __type: "FloorPriorityField",
      },
      userId: {
        __offset_start: 28,
        __size: 0,
        __type: "UserIdField",
      },
      trackInfo: {
        __offset_start: 0,
        __size: 4,
        __type: "TrackInfoField",
      },
      floorIndicator: {
        __offset_start: 0,
        __size: 4,
        __type: "FloorIndicatorField",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        if (!data.hasOwnProperty("header"))
          data.header = { firstByte: { subtype: 1 } };
        if (!data.header.hasOwnProperty("firstByte"))
          data.header.firstByte = { subtype: 1 };
        data.header.firstByte.subtype = 1;
        if (!data.hasOwnProperty("priority")) data.priority = {};
        if (!data.hasOwnProperty("ssrcGranted")) data.ssrcGranted = {};
        if (!data.hasOwnProperty("duration")) data.duration = {};
        if (!data.hasOwnProperty("userId")) data.userId = {};
        if (!data.hasOwnProperty("trackInfo")) data.trackInfo = {};
        if (!data.hasOwnProperty("floorIndicator")) data.floorIndicator = {};
      },
      __postEncodeHandler: function (
        masterConfig,
        configCopy,
        data,
        buffer,
        offset,
        sizeObj,
        offsetObj
      ) {
        var rtcpLength = offset / 4 - 1;
        console.log(
          "===post encode handler",
          rtcpLength,
          config.RTCPHeader.length,
          offset
        );
        data.header.length = rtcpLength;
        //config, value, buffer, offset, fieldSize
        writeToBuff(
          config.RTCPHeader.length,
          data.header.length,
          buffer,
          offsetObj.header.length,
          sizeObj.header.length
        );
      },
    },
    FloorReleaseMessage: {
      __size: 0,
      __sizeField: "header.length",
      header: {
        __offset_start: 0,
        __size: 12,
        __type: "RTCPHeader",
      },
      userId: {
        __offset_start: 12,
        __size: 0,
        __type: "UserIdField",
      },
      trackInfo: {
        __offset_start: 0,
        __size: 4,
        __type: "TrackInfoField",
      },
      floorIndicator: {
        __offset_start: 0,
        __size: 4,
        __type: "FloorIndicatorField",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        if (!data.hasOwnProperty("header"))
          data.header = { firstByte: { subtype: 4 } };
        if (!data.header.hasOwnProperty("firstByte"))
          data.header.firstByte = { subtype: 4 };
        data.header.firstByte.subtype = 4;
        if (!data.hasOwnProperty("userId")) data.userId = {};
        if (!data.hasOwnProperty("trackInfo")) data.trackInfo = {};
        if (!data.hasOwnProperty("floorIndicator")) data.floorIndicator = {};
      },
      __postEncodeHandler: function (
        masterConfig,
        configCopy,
        data,
        buffer,
        offset,
        sizeObj,
        offsetObj
      ) {
        var rtcpLength = offset / 4 - 1;
        data.header.length = rtcpLength;
        //config, value, buffer, offset, fieldSize
        writeToBuff(
          config.RTCPHeader.length,
          data.header.length,
          buffer,
          offsetObj.header.length,
          sizeObj.header.length
        );
      },
    },
    FloorTakenMessage: {
      __size: 0,
      __sizeField: "header.length",
      header: {
        __offset_start: 0,
        __size: 12,
        __type: "RTCPHeader",
      },
      grantedPartyId: {
        __offset_start: 12,
        __size: 0,
        __type: "GrantedPartyIdentityField",
      },
      permissionToRequestFloor: {
        __offset_start: 0,
        __size: 4,
        __type: "PermissionToRequestFloorField",
      },
      userId: {
        __offset_start: 0,
        __size: 0,
        __type: "UserIdField",
      },
      trackInfo: {
        __offset_start: 0,
        __size: 4,
        __type: "TrackInfoField",
      },
      floorIndicator: {
        __offset_start: 0,
        __size: 4,
        __type: "FloorIndicatorField",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        if (!data.hasOwnProperty("header"))
          data.header = { firstByte: { subtype: 2 } };
        if (!data.header.hasOwnProperty("firstByte"))
          data.header.firstByte = { subtype: 2 };
        data.header.firstByte.subtype = 2;
        if (!data.hasOwnProperty("grantedPartyId")) data.grantedPartyId = {};
        if (!data.hasOwnProperty("permissionToRequestFloor"))
          data.permissionToRequestFloor = {};
        if (!data.hasOwnProperty("userId")) data.userId = {};
        if (!data.hasOwnProperty("trackInfo")) data.trackInfo = {};
        if (!data.hasOwnProperty("floorIndicator")) data.floorIndicator = {};
      },
      __postEncodeHandler: function (
        masterConfig,
        configCopy,
        data,
        buffer,
        offset,
        sizeObj,
        offsetObj
      ) {
        var rtcpLength = offset / 4 - 1;
        data.header.length = rtcpLength;
        //config, value, buffer, offset, fieldSize
        writeToBuff(
          config.RTCPHeader.length,
          data.header.length,
          buffer,
          offsetObj.header.length,
          sizeObj.header.length
        );
      },
    },
    MCPCConnectMessage: {
      __size: 0,
      __sizeField: "header.length",
      header: {
        __offset_start: 0,
        __size: 12,
        __type: "RTCPHeader",
      },
      sessionId: {
        __offset_start: 12,
        __size: 0,
        __type: "MCPTTSessionIdentityField",
      },
      groupId: {
        __offset_start: 0,
        __size: 0,
        __type: "MCPTTGroupIdentityField",
      },
      mediaStream: {
        __offset_start: 0,
        __size: 4,
        __type: "MediaStreamField",
      },
      warningText: {
        __offset_start: 0,
        __size: 0,
        __type: "WarningTextField",
      },
      answerState: {
        __offset_start: 0,
        __size: 4,
        __type: "AnswerStateField",
      },
      invitingUser: {
        __offset_start: 0,
        __size: 0,
        __type: "InvitingMCPTTUserIdentityField",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        if (!data.hasOwnProperty("header"))
          data.header = { firstByte: { subtype: 0 }, name: "MCPC" };
        if (!data.header.hasOwnProperty("firstByte"))
          data.header.firstByte = { subtype: 0 };
        data.header.firstByte.subtype = 0;
        data.header.name = "MCPC";
        if (!data.hasOwnProperty("sessionId")) data.sessionId = {};
        if (!data.hasOwnProperty("groupId")) data.groupId = {};
        if (!data.hasOwnProperty("mediaStream")) data.mediaStream = {};
        if (!data.hasOwnProperty("warningText")) data.warningText = {};
        if (!data.hasOwnProperty("answerState")) data.answerState = {};
        if (!data.hasOwnProperty("invitingUser")) data.invitingUser = {};
      },
      __postEncodeHandler: function (
        masterConfig,
        configCopy,
        data,
        buffer,
        offset,
        sizeObj,
        offsetObj
      ) {
        var rtcpLength = offset / 4 - 1;
        data.header.length = rtcpLength;
        //config, value, buffer, offset, fieldSize
        writeToBuff(
          config.RTCPHeader.length,
          data.header.length,
          buffer,
          offsetObj.header.length,
          sizeObj.header.length
        );
      },
      __postDecodeHandler: function () {},
    },
    MCPCDisconnectMessage: {
      __size: 0,
      __sizeField: "header.length",
      header: {
        __offset_start: 0,
        __size: 12,
        __type: "RTCPHeader",
      },
      sessionId: {
        __offset_start: 12,
        __size: 4,
        __type: "MCPTTSessionIdentityField",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        if (!data.hasOwnProperty("header"))
          data.header = { firstByte: { subtype: 1 }, name: "MCPC" };
        if (!data.header.hasOwnProperty("firstByte"))
          data.header.firstByte = { subtype: 1 };
        data.header.firstByte.subtype = 1;
        data.header.name = "MCPC";
        if (!data.hasOwnProperty("sessionId")) data.sessionId = {};
      },
      __postEncodeHandler: function (
        masterConfig,
        configCopy,
        data,
        buffer,
        offset,
        sizeObj,
        offsetObj
      ) {
        var rtcpLength = offset / 4 - 1;
        data.header.length = rtcpLength;
        //config, value, buffer, offset, fieldSize
        writeToBuff(
          config.RTCPHeader.length,
          data.header.length,
          buffer,
          offsetObj.header.length,
          sizeObj.header.length
        );
      },
      __postDecodeHandler: function () {},
    },
    MCPCAcknowledge: {
      __size: 0,
      __sizeField: "header.length",
      header: {
        __offset_start: 0,
        __size: 12,
        __type: "RTCPHeader",
      },
      reasonCode: {
        __offset_start: 12,
        __size: 4,
        __type: "ReasonCodeField",
      },
      __preEncodeHandler: function (masterConfig, config, data) {
        if (!data.hasOwnProperty("header"))
          data.header = { firstByte: { subtype: 1 }, name: "MCPC" };
        if (!data.header.hasOwnProperty("firstByte"))
          data.header.firstByte = { subtype: 1 };
        data.header.firstByte.subtype = 1;
        data.header.name = "MCPC";
        if (!data.hasOwnProperty("reasonCode")) data.reasonCode = {};
      },
      __postEncodeHandler: function (
        masterConfig,
        configCopy,
        data,
        buffer,
        offset,
        sizeObj,
        offsetObj
      ) {
        var rtcpLength = offset / 4 - 1;
        data.header.length = rtcpLength;
        //config, value, buffer, offset, fieldSize
        writeToBuff(
          config.RTCPHeader.length,
          data.header.length,
          buffer,
          offsetObj.header.length,
          sizeObj.header.length
        );
      },
      __postDecodeHandler: function () {},
    },

    __discriminator: function (masterConfig, buffer) {
      let headerObj = decodeWithType(masterConfig, "RTCPHeader", buffer, 0);
      let msgType = headerObj.data.firstByte.subtype;
      console.log("check name", headerObj);
      if (headerObj.data.name === "MCPT") {
        switch (msgType) {
          case 0:
            return "FloorRequestMessage";
          case 1:
            return "FloorGrantedMessage";
          case 4:
            return "FloorReleaseMessage";
          case 5:
            return "FloorIdleMessage";
          case 2:
            return "FloorTakenMessage";
          default:
        }
      } else {
        switch (msgType) {
          case 0:
            return "MCPCConnectMessage";
          case 1:
            return "MCPCDisconnectMessage";
          default:
        }
      }
    },
  };
  //Older
  // const config = {
  //   RTCPHeader: {
  //     __size: 12,
  //     firstByte: {
  //       __offset_start: 0,
  //       __size: 1,
  //       __type: "bit_field",
  //       __fields: {
  //         version: {
  //           __offset: 0,
  //           __size: 2,
  //         },
  //         subtype: {
  //           __offset: 3,
  //           __size: 5,
  //         },
  //       },
  //     },
  //     type: {
  //       __offset_start: 1,
  //       __size: 1,
  //       __type: "UInt8",
  //     },
  //     length: {
  //       __offset_start: 2,
  //       __size: 2,
  //       __type: "UInt16",
  //     },
  //     ssrc: {
  //       __offset_start: 4,
  //       __size: 4,
  //       __type: "UInt32",
  //     },
  //     name: {
  //       __offset_start: 8,
  //       __size: 4,
  //       __type: "String",
  //     },
  //     __preEncodeHandler: function (masterConfig, config, data) {
  //       //console.log("Rtp header pre", data)
  //       if (!data.hasOwnProperty("firstByte")) data.firstByte = {};
  //       data.firstByte.version = 2;
  //       data.type = 204;
  //       data.name = "MCPT";
  //       //console.log("Rtp header pre", data)
  //     },
  //   },
  //   FloorPriorityField: {
  //     __size: 4,
  //     __padding: 4,
  //     id: {
  //       __offset_start: 0,
  //       __size: 1,
  //       __type: "UInt8",
  //     },
  //     length: {
  //       __offset_start: 1,
  //       __size: 1,
  //       __type: "UInt8",
  //     },
  //     value: {
  //       __offset_start: 2,
  //       __size: 1,
  //       __type: "UInt8",
  //     },
  //     __preEncodeHandler: function (masterConfig, config, data) {
  //       data.id = 102;
  //       data.length = 1;
  //       if (!data.hasOwnProperty("value")) data.value = 5;
  //     },
  //   },
  //   UserIdField: {
  //     __size: 0,
  //     __padding: 4,
  //     id: {
  //       __offset_start: 0,
  //       __size: 1,
  //       __type: "UInt8",
  //     },
  //     length: {
  //       __offset_start: 1,
  //       __size: 1,
  //       __type: "UInt8",
  //     },
  //     value: {
  //       __offset_start: 2,
  //       __type: "vstring",
  //       __sizeField: "length",
  //     },
  //     __preEncodeHandler: function (masterConfig, config, data) {
  //       data.id = 109;
  //       if (data.hasOwnProperty("value")) data.length = data.value.length;
  //       else data.length = 0;
  //     },
  //   },
  //   MessageSequenceNumberField: {
  //     __size: 4,
  //     id: {
  //       __offset_start: 0,
  //       __size: 1,
  //       __type: "UInt8",
  //     },
  //     length: {
  //       __offset_start: 1,
  //       __size: 1,
  //       __type: "UInt8",
  //     },
  //     value: {
  //       __offset_start: 2,
  //       __type: "UInt16",
  //       __sizeField: "length",
  //     },
  //     __preEncodeHandler: function (masterConfig, config, data) {
  //       data.id = 111;
  //       data.length = 2;
  //     },
  //   },

  //   TrackInfoField: {
  //     __size: 4,
  //     __padding: 4,
  //     id: {
  //       __offset_start: 0,
  //       __size: 1,
  //       __type: "UInt8",
  //     },
  //     length: {
  //       __offset_start: 1,
  //       __size: 1,
  //       __type: "UInt8",
  //     },
  //     queueingCapability: {
  //       __offset_start: 2,
  //       __size: 1,
  //       __type: "Uint8",
  //     },
  //     participantTypeLength: {
  //       __offset_start: 3,
  //       __size: 1,
  //       __type: "Uint8",
  //     },
  //     __preEncodeHandler: function (masterConfig, config, data) {
  //       data.id = 114;
  //       data.length = 2;
  //       data.queueingCapability = 0;
  //       data.participantTypeLength = 0;
  //     },
  //   },
  //   FloorIndicatorField: {
  //     __size: 4,
  //     __padding: 4,
  //     id: {
  //       __offset_start: 0,
  //       __size: 1,
  //       __type: "UInt8",
  //     },
  //     length: {
  //       __offset_start: 1,
  //       __size: 1,
  //       __type: "UInt8",
  //     },
  //     value: {
  //       __offset_start: 2,
  //       __size: 2,
  //       __type: "bit_field",
  //       __fields: {
  //         normalCall: {
  //           __offset: 0,
  //           __size: 1,
  //         },
  //         broadcastGroupCall: {
  //           __offset: 1,
  //           __size: 1,
  //         },
  //         systemCall: {
  //           __offset: 2,
  //           __size: 1,
  //         },
  //         emergencyCall: {
  //           __offset: 3,
  //           __size: 1,
  //         },
  //         imminentPerilCall: {
  //           __offset: 4,
  //           __size: 1,
  //         },
  //       },
  //     },
  //     __preEncodeHandler: function (masterConfig, config, data) {
  //       data.id = 116;
  //       data.length = 2;
  //     },
  //   },
  //   DurationField: {
  //     __size: 4,
  //     id: {
  //       __offset_start: 0,
  //       __size: 1,
  //       __type: "UInt8",
  //     },
  //     length: {
  //       __offset_start: 1,
  //       __size: 1,
  //       __type: "UInt8",
  //     },
  //     value: {
  //       __offset_start: 2,
  //       __type: "UInt16",
  //       __sizeField: "length",
  //     },
  //     __preEncodeHandler: function (masterConfig, config, data) {
  //       data.id = 103;
  //       data.length = 2;
  //       data.value = 65535;
  //     },
  //   },
  //   FloorRequestMessage: {
  //     __size: 0,
  //     __sizeField: "header.length",
  //     header: {
  //       __offset_start: 0,
  //       __size: 12,
  //       __type: "RTCPHeader",
  //     },
  //     priority: {
  //       __offset_start: 12,
  //       __size: 4,
  //       __type: "FloorPriorityField",
  //     },
  //     userId: {
  //       __offset_start: 16,
  //       __size: 0,
  //       __type: "UserIdField",
  //     },
  //     trackInfo: {
  //       __offset_start: 0,
  //       __size: 4,
  //       __type: "TrackInfoField",
  //     },
  //     floorIndicator: {
  //       __offset_start: 0,
  //       __size: 4,
  //       __type: "FloorIndicatorField",
  //     },
  //     __preEncodeHandler: function (masterConfig, config, data) {
  //       if (!data.hasOwnProperty("header"))
  //         data.header = { firstByte: { subtype: 0 } };
  //       if (!data.header.hasOwnProperty("firstByte"))
  //         data.header.firstByte = { subtype: 0 };
  //       data.header.firstByte.subtype = 0;
  //       if (!data.hasOwnProperty("priority")) data.priority = {};
  //       if (!data.hasOwnProperty("userId")) data.userId = {};
  //       if (!data.hasOwnProperty("trackInfo")) data.trackInfo = {};
  //       if (!data.hasOwnProperty("floorIndicator")) data.floorIndicator = {};
  //     },
  //     __postEncodeHandler: function (
  //       masterConfig,
  //       configCopy,
  //       data,
  //       buffer,
  //       offset,
  //       sizeObj,
  //       offsetObj
  //     ) {
  //       var rtcpLength = offset / 4 - 1;
  //       data.header.length = rtcpLength;
  //       //config, value, buffer, offset, fieldSize
  //       writeToBuff(
  //         config.RTCPHeader.length,
  //         data.header.length,
  //         buffer,
  //         offsetObj.header.length,
  //         sizeObj.header.length
  //       );
  //     },
  //     __postDecodeHandler: function () {},
  //   },
  //   FloorIdleMessage: {
  //     __size: 0,
  //     __sizeField: "header.length",
  //     header: {
  //       __offset_start: 0,
  //       __size: 12,
  //       __type: "RTCPHeader",
  //     },
  //     messageSequenceNumber: {
  //       __offset_start: 12,
  //       __size: 0,
  //       __type: "MessageSequenceNumberField",
  //     },
  //     trackInfo: {
  //       __offset_start: 0,
  //       __size: 4,
  //       __type: "TrackInfoField",
  //     },
  //     floorIndicator: {
  //       __offset_start: 0,
  //       __size: 4,
  //       __type: "FloorIndicatorField",
  //     },
  //     __preEncodeHandler: function (masterConfig, config, data) {
  //       if (!data.hasOwnProperty("header"))
  //         data.header = { firstByte: { subtype: 4 } };
  //       if (!data.header.hasOwnProperty("firstByte"))
  //         data.header.firstByte = { subtype: 4 };
  //       data.header.firstByte.subtype = 4;
  //       if (!data.hasOwnProperty("messageSequenceNumber"))
  //         data.messageSequenceNumber = {};
  //       if (!data.hasOwnProperty("trackInfo")) data.trackInfo = {};
  //       if (!data.hasOwnProperty("floorIndicator")) data.floorIndicator = {};
  //     },
  //     __postEncodeHandler: function (
  //       masterConfig,
  //       configCopy,
  //       data,
  //       buffer,
  //       offset,
  //       sizeObj,
  //       offsetObj
  //     ) {
  //       var rtcpLength = offset / 4 - 1;
  //       data.header.length = rtcpLength;
  //       //config, value, buffer, offset, fieldSize
  //       writeToBuff(
  //         config.RTCPHeader.length,
  //         data.header.length,
  //         buffer,
  //         offsetObj.header.length,
  //         sizeObj.header.length
  //       );
  //     },
  //   },
  //   FloorGrantedMessage: {
  //     __size: 0,
  //     __sizeField: "header.length",
  //     header: {
  //       __offset_start: 0,
  //       __size: 12,
  //       __type: "RTCPHeader",
  //     },
  //     duration: {
  //       __offset_start: 12,
  //       __size: 4,
  //       __type: "DurationField",
  //     },
  //     priority: {
  //       __offset_start: 20,
  //       __size: 4,
  //       __type: "FloorPriorityField",
  //     },
  //     userId: {
  //       __offset_start: 24,
  //       __size: 0,
  //       __type: "UserIdField",
  //     },
  //     trackInfo: {
  //       __offset_start: 0,
  //       __size: 4,
  //       __type: "TrackInfoField",
  //     },
  //     floorIndicator: {
  //       __offset_start: 0,
  //       __size: 4,
  //       __type: "FloorIndicatorField",
  //     },
  //     __preEncodeHandler: function (masterConfig, config, data) {
  //       if (!data.hasOwnProperty("header"))
  //         data.header = { firstByte: { subtype: 1 } };
  //       if (!data.header.hasOwnProperty("firstByte"))
  //         data.header.firstByte = { subtype: 1 };
  //       data.header.firstByte.subtype = 1;
  //       if (!data.hasOwnProperty("priority")) data.priority = {};
  //       if (!data.hasOwnProperty("duration")) data.duration = {};
  //       if (!data.hasOwnProperty("userId")) data.userId = {};
  //       if (!data.hasOwnProperty("trackInfo")) data.trackInfo = {};
  //       if (!data.hasOwnProperty("floorIndicator")) data.floorIndicator = {};
  //     },
  //     __postEncodeHandler: function (
  //       masterConfig,
  //       configCopy,
  //       data,
  //       buffer,
  //       offset,
  //       sizeObj,
  //       offsetObj
  //     ) {
  //       var rtcpLength = offset / 4 - 1;
  //       data.header.length = rtcpLength;
  //       //config, value, buffer, offset, fieldSize
  //       writeToBuff(
  //         config.RTCPHeader.length,
  //         data.header.length,
  //         buffer,
  //         offsetObj.header.length,
  //         sizeObj.header.length
  //       );
  //     },
  //   },
  //   FloorReleaseMessage: {
  //     __size: 0,
  //     __sizeField: "header.length",
  //     header: {
  //       __offset_start: 0,
  //       __size: 12,
  //       __type: "RTCPHeader",
  //     },
  //     userId: {
  //       __offset_start: 12,
  //       __size: 0,
  //       __type: "UserIdField",
  //     },
  //     trackInfo: {
  //       __offset_start: 0,
  //       __size: 4,
  //       __type: "TrackInfoField",
  //     },
  //     floorIndicator: {
  //       __offset_start: 0,
  //       __size: 4,
  //       __type: "FloorIndicatorField",
  //     },
  //     __preEncodeHandler: function (masterConfig, config, data) {
  //       if (!data.hasOwnProperty("header"))
  //         data.header = { firstByte: { subtype: 3 } };
  //       if (!data.header.hasOwnProperty("firstByte"))
  //         data.header.firstByte = { subtype: 3 };
  //       data.header.firstByte.subtype = 3;
  //       if (!data.hasOwnProperty("userId")) data.userId = {};
  //       if (!data.hasOwnProperty("trackInfo")) data.trackInfo = {};
  //       if (!data.hasOwnProperty("floorIndicator")) data.floorIndicator = {};
  //     },
  //     __postEncodeHandler: function (
  //       masterConfig,
  //       configCopy,
  //       data,
  //       buffer,
  //       offset,
  //       sizeObj,
  //       offsetObj
  //     ) {
  //       var rtcpLength = offset / 4 - 1;
  //       data.header.length = rtcpLength;
  //       //config, value, buffer, offset, fieldSize
  //       writeToBuff(
  //         config.RTCPHeader.length,
  //         data.header.length,
  //         buffer,
  //         offsetObj.header.length,
  //         sizeObj.header.length
  //       );
  //     },
  //   },

  //   __discriminator: function (masterConfig, buffer) {
  //     let headerObj = decodeWithType(masterConfig, "RTCPHeader", buffer, 0);
  //     let msgType = headerObj.data.firstByte.subtype;
  //     switch (msgType) {
  //       case 0:
  //         return "FloorRequestMessage";
  //       case 1:
  //         return "FloorGrantedMessage";
  //       case 3:
  //         return "FloorReleaseMessage";
  //       case 4:
  //         return "FloorIdleMessage";
  //       default:
  //     }
  //   },
  // };

  exports.config = config;
  exports.encode = encode;
  exports.decode = decode;
})(typeof exports === "undefined" ? (this["PttConfig"] = {}) : exports);
