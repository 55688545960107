import React, { useState } from "react";
import { Modal } from 'react-bootstrap'
import { connect } from "react-redux";
import { deleteDeviceType, pullLogs } from "../../modules/actions/mdmAction";
import AlertDialog from "../../Admin/components/DeleteUserDialog";
import AddDeviceType from "./Modal/AddDeviceType";
const DeviceTypeRow = (props) => {
    const { data,deleteDeviceType,} = props;
    const [userOpen, setUserOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const userhandleClose = () => {
        setUserOpen(false)
    }
    const deleteButtonClick = () => {
        setDialogOpen(true)
    }
    const deleteDailogHandler = (val) => {
        setDialogOpen(false)
        if (val) {
            deleteDeviceType({ id: data.id })
        }

    }
    return (
        /* This div returns the jsx to create single user list row elements*/
        <div style={{ width: "100%", margin: "5px" }}>
            <div class="device-row-grid">
                <div class="tr-tb-icon">
                    <i className={"feather icon-smartphone f-32"} style={{ lineHeight: "50%" }} />
                </div>
                <div class="tr-tb-username">
                    <span>{data.manufacturer}</span>
                </div>
                <div class="tr-tb-deviceid">
                    <span>{data.brand} </span>
                </div>
                <div class="tr-tb-status">
                    <span></span>
                </div>
                <div class="tr-tb-config">
                    <span></span>
                </div>
                <div className="tr-tb-qr">
                    <span></span>
                </div>
                <div class="tr-tb-logs">
                    <span></span>
                </div>
                <div class="tr-tb-edit">
                    <span></span>
                </div>
                <div class="tr-tb-delete">
                    <button
                        class="editBtn"
                        onClick={deleteButtonClick}
                        type="button"
                        name=""
                    >
                        <img
                            src="/assets/images/deleteimg.svg"
                            class="delete-user-img"
                            alt=""
                        />
                    </button>
                </div>
            </div>
            <Modal
                show={userOpen}
                onHide={userhandleClose}
                size={"lg"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ backgroundColor: ' rgba(0,0,0,0.5)' }}
            >
                <Modal.Header closeButton style={{ border: '0px', backgroundColor: '#282828' }}>
                    <Modal.Title>Update Device Type</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: '0.2px' }}>
                    {
                        userOpen ?
                            <AddDeviceType data={data} setModalOpen={setUserOpen} />
                            : null
                    }
                </Modal.Body>
            </Modal>
            <AlertDialog open={dialogOpen} deleteDailogHandler={deleteDailogHandler} userName={props.data.brand} />
        </div>
    );
}
const mapStateToProps = ({ mdm }) => {
    return {
        tenantId: mdm.tenantId
    }
}
export default connect(mapStateToProps, { deleteDeviceType, pullLogs })(DeviceTypeRow);
