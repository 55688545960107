import React, { useEffect, useState, Fragment } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const CADCallConfig = (props) => {
  const {
    cadCall,
    updateUserCadCallData,
    faList,
    mcpttidAndUri,
    updateDefaultAuthorizerErr,
    ueType
  } = props;
  const [cadCallData, setCadCallData] = useState(cadCall);
  const [UETYPE, setUETYPE] = useState('');

  useEffect(() => {
    console.log("basicdetails update CadCall useeffect..", cadCall);
    setCadCallData(cadCall);
  }, [cadCall]);

  useEffect(() => {
    if (ueType) {
      setUETYPE(ueType);
    } else {
      setUETYPE('');
    }

    setCadCallData({
      ...cadCallData,
      allowPrivateCallParticipation: true,
      allowPrivateCallToAnyUser: false,
      defaultAuthorizer: "",
      incomingAuthorizationRequired: false,
      outgoingAuthorizationRequired: false
    });

    updateUserCadCallData({
      ...cadCallData,
      allowPrivateCallParticipation: true,
      allowPrivateCallToAnyUser: false,
      defaultAuthorizer: "",
      incomingAuthorizationRequired: false,
      outgoingAuthorizationRequired: false
    });

  }, [ueType]);

  const allowPrivateCallChangeHandler = (e) => {
    //console.log('allowPrivateCallChangeHandler..', e.target.checked, cadCallData);
    let allowPrivateCallParticipation = e.target.checked;
    let incomingAuthorizationRequired = cadCallData && cadCallData.incomingAuthorizationRequired;
    let defaultAuthorizer = cadCallData && cadCallData.defaultAuthorizer;
    let outgoingAuthorizationRequired = cadCallData && cadCallData.outgoingAuthorizationRequired;

    if (e.target.checked == false) {
      incomingAuthorizationRequired = false;
      if (!outgoingAuthorizationRequired) {
        defaultAuthorizer = "";
      }
    }
    setCadCallData({
      ...cadCallData,
      allowPrivateCallParticipation: allowPrivateCallParticipation,
      incomingAuthorizationRequired: incomingAuthorizationRequired,
      defaultAuthorizer: defaultAuthorizer,
      outgoingAuthorizationRequired: outgoingAuthorizationRequired
    });

    updateUserCadCallData({
      ...cadCallData,
      allowPrivateCallParticipation: allowPrivateCallParticipation,
      incomingAuthorizationRequired: incomingAuthorizationRequired,
      defaultAuthorizer: defaultAuthorizer,
      outgoingAuthorizationRequired: outgoingAuthorizationRequired
    });

  };

  const incomingAuthorizationChangeHandler = (e) => {
    let allowPrivateCallParticipation = cadCallData && cadCallData.allowPrivateCallParticipation;
    let incomingAuthorizationRequired = e.target.checked;
    let defaultAuthorizer = cadCallData && cadCallData.defaultAuthorizer;
    let outgoingAuthorizationRequired = cadCallData && cadCallData.outgoingAuthorizationRequired;

    if (e.target.checked == false) {
      incomingAuthorizationRequired = false;
      if (!outgoingAuthorizationRequired) {
        defaultAuthorizer = "";
      }
    }
    setCadCallData({
      ...cadCallData,
      allowPrivateCallParticipation: allowPrivateCallParticipation,
      incomingAuthorizationRequired: incomingAuthorizationRequired,
      defaultAuthorizer: defaultAuthorizer,
      outgoingAuthorizationRequired: outgoingAuthorizationRequired
    });

    updateUserCadCallData({
      ...cadCallData,
      allowPrivateCallParticipation: allowPrivateCallParticipation,
      incomingAuthorizationRequired: incomingAuthorizationRequired,
      defaultAuthorizer: defaultAuthorizer,
      outgoingAuthorizationRequired: outgoingAuthorizationRequired
    });

    if (e.target.checked && defaultAuthorizer.length === 0) {
      updateDefaultAuthorizerErr(true);
    } else {
      updateDefaultAuthorizerErr(false);
    }

  };

  const allowPrivateCallToAnyUserChangeHandler = (e) => {
    setCadCallData({
      ...cadCallData,
      allowPrivateCallToAnyUser: e.target.checked,
    });
    updateUserCadCallData({
      ...cadCallData,
      allowPrivateCallToAnyUser: e.target.checked,
    });
  };

  const outgoingAuthorizationChangeHandler = (e) => {

    let allowPrivateCallParticipation = cadCallData && cadCallData.allowPrivateCallParticipation;
    let incomingAuthorizationRequired = cadCallData && cadCallData.incomingAuthorizationRequired;
    let defaultAuthorizer = cadCallData && cadCallData.defaultAuthorizer;
    let outgoingAuthorizationRequired = e.target.checked;

    if (e.target.checked == false) {
      if (incomingAuthorizationRequired || allowPrivateCallParticipation) {
        defaultAuthorizer = cadCallData && cadCallData.defaultAuthorizer;
      } else {
        defaultAuthorizer = "";
      }
    }
    setCadCallData({
      ...cadCallData,
      allowPrivateCallParticipation: allowPrivateCallParticipation,
      incomingAuthorizationRequired: incomingAuthorizationRequired,
      defaultAuthorizer: defaultAuthorizer,
      outgoingAuthorizationRequired: outgoingAuthorizationRequired
    });

    updateUserCadCallData({
      ...cadCallData,
      allowPrivateCallParticipation: allowPrivateCallParticipation,
      incomingAuthorizationRequired: incomingAuthorizationRequired,
      defaultAuthorizer: defaultAuthorizer,
      outgoingAuthorizationRequired: outgoingAuthorizationRequired
    });

    if (e.target.checked && defaultAuthorizer.length === 0) {
      updateDefaultAuthorizerErr(true);
    } else {
      updateDefaultAuthorizerErr(false);
    }
  };

  const getOptionsArray = (e) => {
    //console.log('mcpttidAndUri....', mcpttidAndUri);
    /*
    displayName: "user1"
    subscriberID: "user1@mcx.consort.org"
    */
    let defaultAuthorizerArr = [];
    // if (faList) {
    //   faList.forEach((e) => defaultAuthorizerArr.push(e.CallerDescr));
    // }
    // if (mcpttidAndUri) {
    //   mcpttidAndUri.forEach((e) => defaultAuthorizerArr.push(e.displayName));
    // }
    if (mcpttidAndUri && mcpttidAndUri.length > 0) {
      //defaultAuthorizerArr = mcpttidAndUri.filter(mcpttUser => mcpttUser.ueType == "Dispatcher").map(usr => usr.displayName);
      defaultAuthorizerArr = mcpttidAndUri.map(usr => usr.displayName)
    }
    return defaultAuthorizerArr;
  };

  const useStyle = makeStyles((theme) => ({
    root: {
      width: "100%",
      maxWidth: 360,
    },
    formControl: {
      width: "100%",
      marginTop: "5px",
    },
    mleft0: {
      marginLeft: "0px",
      color: "#D0D3D4",
      fontSize: 18,
      fontWeight: 600,
      fontFamily: "Muli"
    },
    incomingAuthContainier: {
      backgroundColor: "#f6f5f5",
      borderRadius: "8px",
      padding: "5px 10px",
    },
    errorIndicator: {
      background: "#ffeded"
    },
    paper: {
      position: 'relative',
      // background: '#e8e8e8',
      backgroundColor: "#383736",
      color: "#D0D3D4",
      overflow: 'hidden',
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#383736"
      },
      fontFamily: "Muli",
    },
  }));
  const classes = useStyle();

  return (
    <>
      {UETYPE !== 'DispatcherM' && cadCallData && cadCallData.allowPrivateCallParticipation ? (
        <div>
          <FormControlLabel
            className={classes.mleft0}
            value="start"
            control={
              <Switch
                color="primary"
                checked={cadCallData.incomingAuthorizationRequired}
                onChange={incomingAuthorizationChangeHandler}
              />
            }
            label="Incoming Private call Authorization Required"
            labelPlacement="start"
            onChange={incomingAuthorizationChangeHandler}
          />
        </div>
      ) : null}
      <div>
        <FormControlLabel
          className={classes.mleft0}
          value="start"
          control={
            <Switch
              color="primary"
              checked={cadCallData.allowPrivateCallToAnyUser}
              onChange={allowPrivateCallToAnyUserChangeHandler}
            />
          }
          label="Allow private calls to any user"
          labelPlacement="start"
          onChange={allowPrivateCallToAnyUserChangeHandler}
        />
      </div>
      {UETYPE !== 'DispatcherM' ?
        <div>
          <FormControlLabel
            className={classes.mleft0}
            value="start"
            control={
              <Switch
                color="primary"
                checked={cadCallData.outgoingAuthorizationRequired}
                onChange={outgoingAuthorizationChangeHandler}
              />
            }
            label="Outgoing private call Authorization required"
            labelPlacement="start"
            onChange={outgoingAuthorizationChangeHandler}
          />
        </div>
        : null}

      {(UETYPE !== 'DispatcherM' && (cadCallData && cadCallData.allowPrivateCallParticipation && cadCallData.incomingAuthorizationRequired) || (cadCallData && cadCallData.outgoingAuthorizationRequired)) ?
        <div>
          <Autocomplete
            id="auto-highlight"
            className={cadCallData.defaultAuthorizer.length === 0 && (cadCallData.outgoingAuthorizationRequired || (cadCallData.incomingAuthorizationRequired && cadCallData.allowPrivateCallParticipation)) ? classes.errorIndicator : classes.paper}
            autoHighlight
            style={{ marginBottom: 5,backgroundColor:'#383736' }}
            options={getOptionsArray()}
            value={cadCallData.defaultAuthorizer}
            getOptionLabel={(option) => option}
            renderOption={(option) => <Fragment>{option}</Fragment>}
            getOptionSelected={(option, value) => option.value === value.value}
            renderInput={(params) => (
              // <TextField
              //   {...params}
              //   label="Default Authorizer"
              //   variant="standard"
              //   InputLabelProps={{ className: "textFieldAutoComplete" }}
              // />
              <TextField {...params} label="Default Authorizer" variant="filled" InputLabelProps={{ className: "textFieldAutoComplete" }} style={{ fontFamily: "Muli" }} />
            )}
            onChange={(e, v) => {
              if (v) {
                setCadCallData({
                  ...cadCallData,
                  defaultAuthorizer: v,
                });
                updateUserCadCallData({
                  ...cadCallData,
                  defaultAuthorizer: v,
                });
                updateDefaultAuthorizerErr(false);
              }
              else {
                setCadCallData({
                  ...cadCallData,
                  defaultAuthorizer: "",
                });
                updateUserCadCallData({
                  ...cadCallData,
                  defaultAuthorizer: "",
                });

                if (cadCallData.outgoingAuthorizationRequired || (cadCallData.incomingAuthorizationRequired && cadCallData.allowPrivateCallParticipation)) {
                  updateDefaultAuthorizerErr(true);
                }

              }
            }}
          />

          {cadCallData.defaultAuthorizer.length === 0 && (cadCallData.outgoingAuthorizationRequired || (cadCallData.incomingAuthorizationRequired && cadCallData.allowPrivateCallParticipation)) ? (
            <label class="error-handling-lbl" style={{ width: "auto" }}>
              Default authorizer cannot be empty
            </label>
          ) : null}
        </div>
        : null}
    </>
  );
};

export default CADCallConfig;
