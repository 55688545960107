import React, { useEffect, useRef, useState ,useCallback} from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Box } from '@mui/material'
import { FaPlay, FaPause, FaVolumeUp, FaVolumeMute } from 'react-icons/fa';
import './player.css'
import GroupCallPttTable from './GroupCallPttTable';
import { showTime, changeBoolValue, showDuration, showCallType } from './definedFunction';
import { EndPoints } from '../../MCXclient/endpoints';
import { formatTime,isoToSeconds } from './definedFunction';
const useStyles = makeStyles({
    checkBoxColor: {
        '&.MuiCheckbox-root': {
            color: "#ffb01f",
        }
    }
});
const CallRecordInfoTab = (props) => {
    const { data } = props;
    console.log("---Callrecordinfo data---", data)
    const classes = useStyles();
    const player = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [volume, setVolume] = useState(1);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const startTime = isoToSeconds(data.recordStartTime);
    const endTime = isoToSeconds(data.recordEndTime);       
  const handlePlayPause = () => {
    if (!isLoaded) return; // Don't allow play/pause until video is loaded

    if (isPlaying && !player.current.paused) {
      player.current.pause();
    } else {
      player.current.play().catch((error) => {
        console.error('Play interrupted:', error);
      });
    }
    setIsPlaying((prev) => !prev);
  };
    const playerSeek = (duration_gp) => {
        console.log("seekto --", duration_gp);
        player.current.currentTime = duration_gp;
         
    }
    const handleSeekChange = (e) => {
        if (!isLoaded) return;
        const newTime = parseFloat(e.target.value);
         player.current.currentTime = newTime;
        
    };
    const handleMute = () => {
        const video = player.current;
    video.muted = !video.muted;
    setIsMuted(video.muted);
    if(video.muted){
        video.volume = 0;  
        setVolume(0)
    }
    else{
        video.volume = 1;  
        setVolume(1)
    }
      };
      const handleVolumeChange = (e) => {
        const video = player.current;
    video.volume = e.target.value;
    setVolume(e.target.value);
    setIsMuted(video.volume === 0);
      };
    const handleProgress = useCallback(() => {
        if (!player.current.seeking) {
            const video = player.current;
             const current = player.current.currentTime;
           
             if(video.ended){
                setCurrentTime(duration);       
             }
             else{
                setCurrentTime(current);    
            }       
        }
      }, [duration]);
    
      const handleLoadedMetadata = () => {
        setIsLoaded(true);
        setDuration(endTime - startTime);
        player.current.play().catch((error) => {
          console.error('Play interrupted on load:', error);
        });
        setIsPlaying(true);
      };
    
    
        const getSubtitleTrack = () => {
            if (data.sessionType) {
              const sessionType = data.sessionType.toLowerCase();
              if ((sessionType === "private" && data.floorControl == "1") || sessionType !== "private") {
                return (
                  <track
                    kind="subtitles"
                    src={`${EndPoints.getConfig().getSubtitleFile}?callId=${data.callId}&startTime=${data.startTime}`}
                    srcLang="en"
                    default
                    mode="showing"
                  />
                );
              }
            }
            return null;
          };

    
   
    return (
        <div >
            {data ? (
                <div>

                    <Box
                        sx={{
                            // boxShadow: '0 3px 10px rgb(0 0 0 / 20%)',
                            borderRadius: '12px',
                            padding: '16px',
                            overflowWrap: "break-word",
                            paddingLeft: '2%',
                            backgroundColor: 'rgba(255,255,255,0.07)',
                            marginTop:'5px',
                            color:'white'
                        }}
                    >
                        <Grid container direction="row"
                            spacing={2}
                            columns={8}
                        >
                            <Grid item xs={12}>
                                <Typography variant="h6" className='color-white'>{data.sessionType == "private" ? "Individual" : "Group"} Call Information</Typography>
                            </Grid>
                            
                            <Grid item xs={4}>
                                <Grid container item spacing={2}>
                                    <Grid item xs={4}>
                                        <span className='header-name'>Call Start Time </span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span className='content-style'>{showTime(data.recordStartTime)}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} classes={classes.dateLabel}>
                                <Grid container item spacing={2}>
                                    <Grid item xs={4}>
                                        <span className='header-name'>Call End Time </span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span className='content-style'>{showTime(data.recordEndTime)}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container item spacing={2}>
                                    <Grid item xs={4}>
                                        <span className='header-name'>Call Duration </span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span className='content-style'>{showDuration(data.recordStartTime, data.recordEndTime)}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} >
                                <Grid container item spacing={2}>
                                    <Grid item xs={4}>
                                        <span className='header-name'>Call Type </span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span className='content-style'>{showCallType(data.callType)}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} >
                                <Grid container item spacing={2}>
                                    <Grid item xs={4}>
                                        <span className='header-name'>Answer Mode </span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span className='content-style'>{data.answerMode}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} >
                                <Grid container item spacing={2}>
                                    <Grid item xs={4}>
                                        <span className='header-name'>Floor Control </span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span className='content-style'>{changeBoolValue(data.floorControl)}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} >
                                <Grid container item spacing={2}>
                                    <Grid item xs={4}>
                                        <span className='header-name'>Caller</span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span className='content-style'>{data.callerDisplayName}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} >
                                <Grid container item spacing={2}>
                                    <Grid item xs={4}>
                                        <span className='header-name'>Caller-McpttId </span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span className='content-style'>{data.callerMcpttId ?data.callerMcpttId.split('@')[0]:"" }</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} >
                                <Grid container item spacing={2}>
                                    <Grid item xs={4}>
                                        <span className='header-name'>Callee</span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span className='content-style'>{data.calleeDisplayName}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} >
                                <Grid container item spacing={2}>
                                    <Grid item xs={4}>
                                        <span className='header-name'>Callee-McpttId </span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span className='content-style'>{data.calleeMcpttIdURI ? data.calleeMcpttIdURI.split('@')[0]:""}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} >
                                <Grid container item spacing={2}>
                                    <Grid item xs={4}>
                                        <span className='header-name'>Group </span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span className='content-style'>{data.displayGroupName}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} >
                                <Grid container item spacing={2}>
                                    <Grid item xs={4}>
                                        <span className='header-name'>Group Id </span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span className='content-style'>{data.groupIdUri ? data.groupIdUri.split('@')[0]:""}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                           <Grid item xs={4} >
                                <Grid container item spacing={2}>
                                    <Grid item xs={4}>
                                        <span className='header-name'>Emergency </span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span className='content-style'>{changeBoolValue(data.emergency)}</span>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={4} >
                                <Grid container item spacing={2}>
                                    <Grid item xs={4}>
                                        <span className='header-name'>Broadcast </span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span className='content-style'>{changeBoolValue(data.broadcast)}</span>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Box>
                    <Box
                        className='video-container'
                        sx={{
                            // boxShadow: '0 3px 10px rgb(0 0 0 / 20%)',
                            borderRadius: '12px',
                            padding: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(255,255,255,0.07)',
                            marginTop:'5px',
                            color:'white',
                            position:'relative'
                        }}
                        
                    >
                        
                      
                      
                       <video
       ref={player}
        src={`${EndPoints.getConfig().getmp4File}?callId=${data.callId}&startTime=${data.startTime}`}
        width="100%"
        height="360px"
        muted={isMuted}
        crossOrigin="anonymous"
        controlsList="nodownload"
        onTimeUpdate={handleProgress}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onLoadedMetadata={handleLoadedMetadata}
     
      >
        {getSubtitleTrack()}
       
      </video>
                    
      
                        
                        <div className="controls">  
                      <button onClick={handlePlayPause} >
                       {isPlaying ? <FaPause /> : <FaPlay />}
                     </button>  
                     <span style={{marginLeft:"5px"}}>{currentTime>duration?formatTime(duration):formatTime(currentTime)} </span>
                     <span>/{formatTime(duration)} </span>
                <input
                    type="range"
                    min={0}
                    max={duration}
                    step="any"
                    value={currentTime}
                    onChange={handleSeekChange}
                    style={{marginLeft:"10px",background:`linear-gradient(to right, #FFD78D ${(currentTime/duration)*100}%, grey ${(currentTime/duration)*100}%)`,cursor:"pointer"}}
                />
                <div className='volume-control' style={{display:"flex",alignItems:"center"}}>
                <button  onClick={handleMute}>
                  {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
         
                 </button>
       
                <div className="volume-slider-container"  >
                <input
                    type="range"
                     min={0}
                    max={1}
                    step="any"
                    value={volume}
                    onChange={handleVolumeChange}
                    className="volume-slider"
                   style={{width:"75%",background:`linear-gradient(to right, #FFD78D ${volume*100}%, grey ${volume*100}%)`}}
                  /> 
                </div>
                </div> 
                     
               </div>
                    </Box>
                </div>
            ) : ""}
            {data.sessionType && (data.sessionType.toLowerCase() == "private" && data.floorControl == "1") || data.sessionType.toLowerCase() != "private" ?
                (<Box
                    id="video-container-table"
                    sx={{
                        // boxShadow: '0 3px 10px rgb(0 0 0 / 20%)',
                        borderRadius: '12px',
                        padding: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(255,255,255,0.07)',
                        marginTop:'5px',
                        color:'white',
                        
                    }}
                >
                    <GroupCallPttTable callStartTime={data.recordStartTime} seekHandler={playerSeek} callId={data.callId} />
                </Box>) : ""}
        </div >
    )
}

export default CallRecordInfoTab