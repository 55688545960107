import React, { useState } from "react";
import { connect } from "react-redux";
import "./style.css"
import DeviceTypeRow from "./DeviceTypeRow";
import { makeStyles } from "@material-ui/core/styles";
import FadeInOut from "../../Admin/components/FadeInOut";
import AlertDialog from "../../Admin/components/DeleteUserDialog";
import { DataGrid } from "@mui/x-data-grid";
import AddDeviceType from "./Modal/AddDeviceType";
import { deleteDeviceType } from "../../modules/actions/mdmAction";
const DeviceTypeList = (props) => {
    const { filteredData, deleteDeviceType } = props;
    const [show, setShow] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [userName, setUserName] = useState('')
    const [selectedId, setSelectedId] = useState('')

    const useStyles = makeStyles((theme) => ({
        root: {
            //backgroundColor: theme.palette.background.paper,
            // paddingTop: 10,
            // paddingLeft: 20,
            // paddingRight: 20,
            width: "100%",
            //height: "70%",
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
        },
        deviceManagementClass: {
            width: "70%",
            height: 800,
            // display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            border: "solid",
            borderRadius: 10,
            borderWidth: "1px",
            borderColor: "#4B4A4A",
            marginLeft: 50,
            //marginTop: 5,
            marginBottom: 10,
            marginTop: 20,
            overflowY: "scroll",
        },
        divList: {
            width: '100%',
            marginBottom: '13px',
        },

    }));

    const classes = useStyles();

    const columns = [
        {
            field: 'userIcon',
            headerName: 'Icon',
            width: 141.5,
            height: 50,
            sortable: false,
            headerClassName: "list-head-Val",
            align: "center",
            headerAlign: "center",
            renderCell: (cellValue) => {
                return (
                    <div class="tr-tb-icon">
                        <i className={"feather icon-smartphone f-32"} style={{ lineHeight: "50%", color: 'whitesmoke' }} />
                    </div>
                )
            }
        },
        {
            field: "manufacturer", headerName: 'Manufacturer', width: 651, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
            // valueGetter: (value, row) => `${getProductName(row)}`,
        },
        {
            field: 'brand', headerName: 'Brand', width: 981, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
        },
        {
            field: 'delete',
            headerName: 'Delete',
            width: 91,
            height: 50,
            sortable: false,
            headerClassName: "list-head-Val",
            align: "center",
            headerAlign: "center",
            renderCell: (cellValue) => {
                return (
                    <button
                        class="editBtn"
                        onClick={() => { deleteButtonClick(cellValue.row) }}
                        type="button"
                        name=""
                    >
                        <img
                            src="/assets/images/deleteimg.svg"
                            class="delete-user-img"
                            alt=""
                        />
                    </button>
                )
            },
        },
    ];


    const deleteButtonClick = (val) => {
        if (val) {
            setDialogOpen(true)
            setUserName(val.manufacturer)
            setSelectedId(val.id)
        }
    }

    const deleteDailogHandler = (val) => {
        setDialogOpen(false)
        if (val) {
            deleteDeviceType({ id: selectedId })
        }
    }

    const deviceManagementHandleClose = () => {
        setShow(false)
    }

    return (
        <div className={classes.root}>
            {show ?
                <div className={classes.deviceManagementClass}>
                    <FadeInOut show={show} duration={500}>
                        <AddDeviceType data={filteredData} setModalOpen={deviceManagementHandleClose} />
                    </FadeInOut>
                </div>
                : <DataGrid
                    rows={filteredData}
                    columns={columns}
                    rowHeight={68}
                    hideFooter
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    disableColumnMenu
                    localeText={{ noRowsLabel: "" }}
                    getRowSpacing={params => ({
                        top: params.isFirstVisible ? 0 : 0,
                        bottom: params.isLastVisible ? 0 : 0
                    })}
                    sx={{
                        boxShadow: 2,
                        border: "none",
                        borderBottom: "none",
                        '& .MuiDataGrid-cell:hover': {
                            color: '#ffb01f',
                        },
                        '& .MuiDataGrid': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-cell': {
                            borderColor: "#404040",
                        },
                        '& .MuiDataGrid-columnHeader': {
                            borderColor: "gray",
                        },
                        '& .MuiDataGrid-filler': {
                            backgroundColor: "#262626",
                        },
                    }}

                />
            }
            <AlertDialog open={dialogOpen} deleteDailogHandler={deleteDailogHandler} userName={userName} />
        </div>
    )
}
const mapStateToProps = ({ mdm }) => {
    return {
        deviceTypeList: mdm.deviceTypeList,
        tenantId: mdm.tenantId,
        filteredData: mdm.filteredData
    }
}
export default connect(mapStateToProps, { deleteDeviceType })(DeviceTypeList)