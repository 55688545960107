import React from "react";
import SearchCollapseTab from "./SearchCollapseTab";

export const TitleTab = (props) => {


  return (
    <div className={props.className ? props.className : ""}>
      {props.type === "none" ? (
        <div className="title-grid-1">
          <div className="title">
            <div className="pt-6 pb-6 pr-6 pl-6 f-18 f-bold white muli">
              {props.title}
            </div>
          </div>
        </div>
      ) : null}

      {props.type !== "none" ? (
        <div className="title-grid-2">
          <div className="title">
            {/* <div className="pt-6 pb-6 pr-6 pl-6 f-18 f-reg white">
              {props.title}
            </div> */}
            <div className="headerTitle">
              {props.title}
            </div>
          </div>
          <div className="search">
            {props.type === "userListTab" ? (
              <SearchCollapseTab
                search={props.search}
                indvGrp={props.filtr}
                userList={true}
                userOpen={props.userOpen}
              />
            ) : null}
            {props.type === "orgTab" ? (
              <SearchCollapseTab
                search={props.search}
                indvGrp={props.filtr}
                userList={true}
                userOpen={props.userOpen}
              />
            ) : null}
            {props.type === "template" ? (
              <SearchCollapseTab
                search={props.search}
                indvGrp={props.filtr}
                template={true}
                userOpen={props.userOpen}
              />
            ) : null}
            {props.type === "spr" ? (
              <SearchCollapseTab
                search={props.search}
                indvGrp={props.filtr}
                sprTemplate={true}
              />
            ) : null}
            {props.type === "pr" ? (
              <SearchCollapseTab
                search={props.search}
                indvGrp={props.filtr}
                prTemplate={true}
              />
            ) : null}
            {props.type === "stn" || props.type === "tn" ? (
              <SearchCollapseTab
                search={props.search}
                indvGrp={props.filtr}
                tnTemplate={true}
              />
            ) : null}
            {props.type === "subscrTemplate" ? (
              <SearchCollapseTab
                search={props.search}
                indvGrp={props.filtr}
                subscrTemplate={true}
              />
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TitleTab;
