import React, { Component } from 'react'
import { connect } from 'react-redux'
import TrainIconUpdate from './TrainIconUpdate';

class Train_icon extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
            activeItem: '',
        }
        this.target = React.createRef();
    }

    gettrackPosForNonFATrain = (tracks, nonFATrains) => {
        // let tracks = this.props.track;
        // let nonFATrains = this.props.nonFaTrainLocs;
        //console.log('nonFATrainsLoc init....', tracks, nonFATrains);
        let nonFATrainsLoc = [];
        for (const trainData of nonFATrains) {
            if (trainData.trackCircuit && trainData.isFaActive == false && global.config.userConfig.Grab == "all" && (trainData.RadioID_A.length > 0 || trainData.RadioID_B.length > 0)) {
                let tc = trainData.trackCircuit.toString().toUpperCase() + 'UP';
                const postn = 'up';
                let foundLTE = false;
                for (let i = 0; i < tracks.length; i++) {
                    const line = tracks[i][trainData.line];
                    //console.log('nonFATrainsLoc line....', line);
                    if (line) {
                        if (line[postn]) {
                            const lte = line[postn].filter(LTE => LTE.name.toString().toUpperCase() === tc || LTE.divergeName === tc);
                            //console.log('nonFATrainsLoc lte....', lte);
                            if (lte.length > 0) {
                                foundLTE = true;
                                let data = { ...trainData };
                                data.moveTo = lte[0].dist;
                                data.coordY = global.config.trainConfig.upTrackY;
                                data.trackPosn = 'none';
                                nonFATrainsLoc = [...nonFATrainsLoc, data];
                                break;
                            }
                        }
                        if (foundLTE) {
                            break;
                        }
                    }
                }
            }
        }
        console.log('nonFATrainsLoc final....', nonFATrainsLoc);
        return nonFATrainsLoc;
    }


    render() {
        const { trains, selectedline, nonFaTrainLocs, track } = this.props;
        const nonFATrainsList = this.gettrackPosForNonFATrain(track, nonFaTrainLocs);
        return (
            <div>
                {trains && trains.map((train, id) => {
                    //console.log("TrainIconTrainSelectedLine :", trains, " ", selectedline)
                    if (train.line.toString() === selectedline.toString()) {
                        return (
                            <TrainIconUpdate
                                selectedline={selectedline.toString()}
                                train={train}
                                dirn={train.trackPosn}
                                moveTo={train.moveTo}
                                coordY={train.coordY}
                            />
                        )
                    }
                })}

                {nonFATrainsList && nonFATrainsList.map((train, id) => {
                    console.log("nonFATrainsList :", train, " ", selectedline);
                    if (train.line && train.line.toString() === selectedline.toString()) {
                        return (
                            <TrainIconUpdate
                                selectedline={selectedline.toString()}
                                train={train}
                                dirn={train.trackPosn}
                                moveTo={train.moveTo}
                                coordY={train.coordY}
                            />
                        )
                    }
                })}
            </div>
        )
    }
}

const mapStateToProps = ({ train }) => {
    const { trains, track, nonFaTrainLocs } = train;
    return {
        trains, track, nonFaTrainLocs
    };
};

export default connect(mapStateToProps, {})(Train_icon)